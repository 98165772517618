import * as types from "../actions/actionTypes";

const initialState = {
  fullName: "",
  corporateTitle: "",
  companyName: "",
  address: "",
  directoryStatus: null,
  phoneInfo: [],
  emailInfo: [],
  faxInfo: [],
  loading: false,
  cities: [],
  unsubscribe: {
    message: "",
    success: false,
  },
  toast: {
    show: false,
    message: "",
    success: true,
  },
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_PROFILE_SUCCESS:
      return Object.assign({}, state, { ...action.data, loading: false });
    case types.TOGGLE_DIRECTORY_HIDE:
      return Object.assign({}, state, {
        directoryStatus: state.directoryStatus === 0 ? 1 : 0,
      });
    case types.LOADING_PROFILE:
      return Object.assign({}, state, { loading: true });
    case types.UPDATE_PROFILE:
      return Object.assign({}, state, {
        toast: { show: true, message: action.message, success: action.success },
      });
    case types.UNSUBSCRIBE:
      return Object.assign({}, state, {
        unsubscribe: { message: action.message, success: action.success },
      });
    case types.CLOSE_PROFILE_TOAST:
      return Object.assign({}, state, {
        toast: { show: false, message: "", success: true },
      });
    default:
      return state;
  }
}
