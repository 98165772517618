import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 710,
  },
  layout: {
    paddingTop: 25,
    fontFamily: "Karla",
  },
  closeButton: {
    position: "absolute",
    top: 5,
    right: 10,
    color: "#00112B",
  },
  titleContainer: {
    paddingLeft: 93,
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 30,
    },
  },
  euLogo: {
    color: "#00112B",
    width: 52.33,
    height: 57.94,
    [theme.breakpoints.down("xs")]: {
      width: 40.33,
      height: 40.94,
    },
  },
  title: {
    fontSize: 24.3896,
    color: "#00112B",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12.5,
    },
  },
  bodyContainer: {
    width: "90%",
    background: "#00112B",
    borderRadius: "0px 10px 10px 0px",
    color: "#EFEFEF",
    padding: "30px 84px 30px 161px",
    marginTop: 20,
    marginBottom: 25,
    [theme.breakpoints.down("xs")]: {
      padding: "25px 5px 15px 19px",
      marginTop: 15,
      marginBottom: 20,
    },
  },
  bodyTitle: {
    fontWeight: "bold",
    fontSize: 50.3156,
    letterSpacing: "-0.045em",
    lineHeight: 1,
    [theme.breakpoints.down("xs")]: {
      fontSize: 25.8408,
    },
  },
  bodySubTitle: {
    fontStyle: "italic",
    fontSize: 24.2995,
    textTransform: "uppercase",
    letterSpacing: "-0.045em",
    marginBottom: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: 14.2342,
      marginBottom: 15,
    },
  },
  bodyMsg: {
    fontSize: 19.8052,
    letterSpacing: "-0.045em",
    lineHeight: 1.3,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  bottomText: {
    fontSize: 26,
    lineHeight: 1.3,
    color: "#000000",
    paddingLeft: 93,
    marginBottom: 30,
    letterSpacing: "-0.045em",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12.5,
      paddingLeft: 30,
    },
  },
  linkText: {
    color: "#10B8C7",
  },
}));
