import * as types from "../../actions/actionTypes";

const initialState = {
  purchasePrice: "",
  appraisedValue: "",
  closingCosts: "",
  tiLcCosts: "",
  interestReserve: "",
  totalCosts: "",
  totalCapitalization: "",
  totalLoan: "",
  tlPercentOfAppraisedVal: "",
  tlPercentOfPurchasePrice: "",
  tlPercentOfTtlCapitalization: "",
  aLoan: "",
  aPercentOfAppraisedVal: "",
  aPercentOfTotalLoan: "",
  bLoan: "",
  bPercentOfAppraisedVal: "",
  bPercentOfRemainingLoan: "",
  term: "",
  tlInterestRate: "",
  tlPoints: "",
  aInterestRate: "",
  aPoints: "",
  bInterestRate: "",
  bPoints: "",
  from: "",
  to: "",
  cc: "",
  syndicatorLoan: "",
  aReturn: "",
  aEffectiveInterest: "",
  bReturn: "",
  bEffectiveInterest: "",
  syndicatorReturn: "",
  syndicatorEffectiveInterest: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_LOAN_SYNDICATION:
      return action.values;
    default:
      return state;
  }
}
