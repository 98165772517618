import * as types from "../actions/actionTypes";
import moment from "moment";

const initialState = {
  violations: [],
  bankQuotes: [],
  propertyDetails: [],
  documents: [],
  contacts: [],
  statusInfo: [],
  contact: {},
  writeUp: {},
  note: {},
  deal: {},
  fundQuotes: {
    data: [],
    nextPageUrl: null,
  },
  equityQuoteStatusList: [],
  waterfallTypesList: [],
  reminderDealNotes: [],
  dealNotes: [],
  doneDealNotes: [],
  brokers: [],
  noViolations: false,
  loadingViolations: true,
  loadingContacts: true,
  loadingDocuments: true,
  loadingContact: false,
  loadingStatus: true,
  errorMQBToast: false,
  loading: false,
  noBankQuotes: false,
  noDocuments: false,
  noContacts: false,
  noContact: false,
  noWriteUp: false,
  noStatus: false,
  noDealNotes: false,
  noNote: false,
  noDeal: false,
  quoteActionSuccessToast: "",
  message: "",
  showSuccessToast: false,
  showErrorToast: false,
  toast: {
    show: false,
    success: true,
    message: "",
  },
};

export default function dealDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_VIOLATIONS:
      return Object.assign({}, state, {
        noViolations: false,
        loading: true,
        violations: [],
      });
    case types.LOAD_VIOLATIONS:
      return Object.assign({}, state, {
        violations: action.violations,
        loadingViolations: false,
        loading: false,
      });
    case types.NO_VIOLATIONS:
      return Object.assign({}, state, {
        violations: [],
        noViolations: true,
        loadingViolations: false,
        loading: false,
      });

    case types.LOADING_STATUS_SUMMARY:
      return Object.assign({}, state, {
        noStatus: false,
        loading: true,
        statusInfo: [],
      });
    case types.LOAD_STATUS_SUMMARY:
      return Object.assign({}, state, {
        statusInfo: action.status,
        loadingStatus: false,
        loading: false,
      });
    case types.NO_STATUS_SUMMARY:
      return Object.assign({}, state, {
        statusInfo: [],
        noViolations: true,
        loading: false,
      });

    case types.LOADING_BANK_QUOTES:
      return Object.assign({}, state, { noBankQuotes: false, loading: true });
    case types.LOAD_BANK_QUOTES:
      return Object.assign({}, state, {
        bankQuotes: action.bankQuotes,
        loading: false,
        noBankQuotes: false,
      });
    case types.NO_BANK_QUOTES:
      return Object.assign({}, state, {
        bankQuotes: [],
        noBankQuotes: true,
        loading: false,
      });

    case types.LOADING_PROPERTY_DETAILS:
      return Object.assign({}, state, { loading: true, propertyDetails: [] });
    case types.LOAD_PROPERTY_DETAILS:
      return Object.assign({}, state, {
        propertyDetails: action.data,
        loading: false,
      });

    case types.LOADING_DEAL_CONTACTS:
      return Object.assign({}, state, { noContacts: false, loading: true });
    case types.LOAD_DEAL_CONTACTS:
      return Object.assign({}, state, {
        contacts: action.contacts,
        loadingContacts: false,
        loading: false,
      });
    case types.NO_DEAL_CONTACTS:
      return Object.assign({}, state, {
        contacts: [],
        noContacts: true,
        loadingContacts: false,
        loading: false,
      });

    case types.LOADING_FULL_CONTACT:
      return Object.assign({}, state, {
        noContact: false,
        loadingContact: true,
      });
    case types.LOAD_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: action.contact,
        loadingContact: false,
      });
    case types.NO_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: {},
        noContact: true,
        loadingContact: false,
      });

    case types.LOADING_DEAL_DOCUMENTS:
      return Object.assign({}, state, { noDocuments: false, loading: true });
    case types.LOAD_DEAL_DOCUMENTS:
      return Object.assign({}, state, {
        documents: action.documents,
        loadingDocuments: false,
        loading: false,
      });
    case types.NO_DEAL_DOCUMENTS:
      return Object.assign({}, state, {
        documents: [],
        noDocuments: true,
        loadingDocuments: false,
        loading: false,
      });

    case types.LOADING_WRITE_UP:
      return Object.assign({}, state, { noWriteUp: false, loading: true });
    case types.LOAD_WRITE_UP:
      return Object.assign({}, state, { writeUp: action.data, loading: false });
    case types.NO_WRITE_UP:
      return Object.assign({}, state, {
        writeUp: {},
        noWriteUp: true,
        loading: false,
      });

    case types.LOADING_DEAL_NOTES:
      return Object.assign({}, state, { noDealNotes: false, loading: true });
    case types.LOAD_DEAL_NOTES:
      return Object.assign({}, state, {
        reminderDealNotes: action.dealNotes.reminders,
        dealNotes: action.dealNotes.notes,
        doneDealNotes: action.dealNotes.done,
        loading: false,
        noDealNotes: false,
      });
    case types.NO_DEAL_NOTES:
      return Object.assign({}, state, {
        reminderDealNotes: [],
        dealNotes: [],
        doneDealNotes: [],
        noDealNotes: true,
        loading: false,
      });

    case types.LOADING_FUND_QUOTES:
      return Object.assign({}, state, { loading: true });
    case types.GET_FUND_QUOTES:
      return Object.assign({}, state, {
        fundQuotes: { data: action.data, nextPageUrl: action.nextPageUrl },
        loading: false,
      });
    case types.GET_FUND_QUOTES_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
        loading: false,
      });
    case types.GET_EQUITY_QUOTE_STATUS_LIST:
      return Object.assign({}, state, { equityQuoteStatusList: action.data });
    case types.GET_WATERFALL_TYPE_LIST:
      return Object.assign({}, state, { waterfallTypesList: action.data });

    case types.LOADING_NOTE_BY_ID:
      return Object.assign({}, state, {
        note: {},
        noNote: false,
        loading: true,
      });
    case types.LOAD_NOTE_BY_ID:
      return Object.assign({}, state, {
        note: action.note,
        noNote: false,
        loading: false,
      });
    case types.NO_NOTE_BY_ID:
      return Object.assign({}, state, {
        note: {},
        noNote: true,
        loading: false,
      });

    case types.LOADING_DEAL_BY_ID:
      return Object.assign({}, state, {
        deal: {},
        noDeal: false,
        loading: true,
      });
    case types.LOAD_DEAL_BY_ID:
      return Object.assign({}, state, {
        deal: action.deal,
        noDeal: false,
        loading: false,
      });
    case types.NO_DEAL_BY_ID:
      return Object.assign({}, state, {
        deal: {},
        noDeal: true,
        loading: false,
      });

    case types.GET_BROKERS_BY_DEAL:
      return Object.assign({}, state, {
        loading: false,
        brokers: action.brokers,
      });
    case types.ASSIGN_NOTE:
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
      });

    case types.ERROR_IP_DD_QUOTE:
      return Object.assign({}, state, {
        errorMQBToast: true,
        message: action.message,
      });
    case types.CLOSE_IP_DD_QUOTE:
      return Object.assign({}, state, { errorMBQToast: false, message: "" });

    case types.PASS_IP_DD_QUOTE_SUCCESS:
      let bankQuotesList1 = state.bankQuotes;
      let bankQuote = bankQuotesList1.find(
        (quote) => quote.quoteId === action.quote_id
      );
      bankQuote.quoteStatus = 3;
      bankQuote.notes = action.note;
      return Object.assign({}, state, {
        bankQuotes: bankQuotesList1,
        quoteActionSuccessToast: true,
        message: action.message,
      });

    case types.SNOOZE_IP_DD_QUOTE:
      let bankQuotesList2 = state.bankQuotes;
      let quoteIndex2 = bankQuotesList2.findIndex(
        (quote) => quote.quoteId === action.quote_id
      );
      var today = new Date();
      if (action.snooze_date > moment(new Date(today)).format("MM/DD/YYYY")) {
        bankQuotesList2[quoteIndex2].missingQuotesSnooze = 1;
      } else {
        bankQuotesList2[quoteIndex2].missingQuotesSnooze = 0;
      }
      return Object.assign({}, state, {
        bankQuotes: bankQuotesList2,
        quoteActionSuccessToast: true,
        message: action.message,
      });

    case types.IRRELEVANT_IP_DD_QUOTE:
      let bankQuotesList3 = state.bankQuotes;
      let bankQuoteById = bankQuotesList3.find(
        (quote) => quote.quoteId === action.quote_id
      );
      bankQuoteById.quoteStatus = 4;
      bankQuoteById.notes = action.note;
      return Object.assign({}, state, {
        bankQuotes: bankQuotesList3,
        quoteActionSuccessToast: true,
        message: action.message,
      });

    case types.CLEAR_NOTE:
      return Object.assign({}, state, { note: {} });
    case types.ADD_UPDATE_DEAL_NOTE_SUCCESS:
      return Object.assign({}, state, {
        showSuccessToast: true,
        message: action.message,
      });
    case types.ADD_UPDATE_DEAL_NOTE_FAILED:
      return Object.assign({}, state, {
        showErrorToast: true,
        message: action.message,
      });
    case types.CLOSE_DEAL_NOTE_SUCCESS_TOAST:
      return Object.assign({}, state, { showSuccessToast: false, message: "" });
    case types.CLOSE_DEAL_NOTE_FAILED_TOAST:
      return Object.assign({}, state, { showErrorToast: false, message: "" });
    case types.SHOW_DEAL_DETAILS_TOAST:
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.CLOSE_DEAL_DETAILS_TOAST:
      return Object.assign({}, state, {
        toast: { show: false, success: true, message: "" },
      });
    default:
      return state;
  }
}
