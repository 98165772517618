import React from "react";
import FormattedInput from "./FormattedInput";

class DollarInput extends React.Component {
  formatValue(value) {
    value = Math.round(value); //CANNOT ROUND VALUE WITH 2 DECIMAL PLACES.  SPEC SHOULD BE THAT IF CANNOT ENTER DECIMAL, WE CANNOT DISPLAY A DECIMAL VALUE.
    value = value.toString();
    value = value.replace(/[^0-9.]/g, ""); //Strips of non-numeric characters besides decimal
    return value ? "$" + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""; //quick hack to enter commas in  number
  }

  stripFormat(value) {
    return value.replace(/\D/g, ""); //stripping of all non-numeric characters, including decimal
  }

  render() {
    return (
      <FormattedInput
        {...this.props}
        formatValue={this.formatValue.bind(this)}
        stripFormat={this.stripFormat.bind(this)}
      />
    );
  }
}

export default DollarInput;
