import React from "react";
import Row from "muicss/lib/react/row";
import Form from "muicss/lib/react/form";
import Select from "muicss/lib/react/select";
import Option from "muicss/lib/react/option";
import { Navigation } from "../../lib";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as contactActions from "../../actions/contactsActions";
import * as navigationTitleActions from "../../actions/navigationTitleActions";
import Expire from "../common/Expire";
import Button from "muicss/lib/react/button";
import encodeQueryData from "../common/encodeQueryData";
import getParameterByName from "../common/getParameterByName";

class ContactsContainer2 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInputValue: "",
      searchString: "",
      searchResults: [],
      searchType: "name",
      mobileDropdownShowing: false,
    };

    this.changeDate = this.changeDate.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.refresh = this.refresh.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.changeToSearchPage = this.changeToSearchPage.bind(this);
    this.closeToast = this.closeToast.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  changeDate(contactId, date) {
    this.props.contactActions.updateDate(contactId, date);
  }

  updateNote(contactId, note) {
    this.props.contactActions.updateNote(
      contactId,
      note,
      localStorage.getItem("auth_token")
    );
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.titleActions.updateNavTitle("Contacts");
    this.props.contactActions.predictiveContactsSearch();
    this.setState({ searchType: getParameterByName("type") || "name" });
  }

  refresh() {
    this.componentDidMount();
  }

  changeSearchType(e) {
    this.setState({ searchType: e.target.value });
  }

  handleSearchChange(e) {
    this.props.contactActions.updateSearch(e.target.value);
  }

  changeToSearchPage() {
    Navigation.go(
      `/contacts/search?${encodeQueryData({ type: this.state.searchType })}`
    );
  }

  handleEnter(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.refs.searchBox.blur();
      let searchWords = e.target.value.match(/\w+/g);
      let URLname = "";
      searchWords.forEach((elem, i) => {
        if (searchWords.length - 1 === i) {
          URLname += elem;
        } else {
          URLname += elem + "+";
        }
      });
      Navigation.go(
        `/contacts/search/${URLname}?${encodeQueryData({
          type: this.state.searchType,
        })}`
      );
    }
  }

  closeToast() {
    this.props.contactActions.closeToast();
  }

  toggleDropdown() {
    this.setState({ mobileDropdownShowing: !this.state.mobileDropdownShowing });
  }

  render() {
    let successMessage = null;
    if (this.props.contacts.toast && this.props.contacts.toast.show) {
      successMessage = (
        <Expire closeToast={this.closeToast} delay={6000}>
          <div className="success-message">
            <p>{this.props.contacts.toast.message}</p>
            <Button variant="flat" onClick={this.closeToast}>
              {this.props.contacts.toast.success ? "Done" : "Dismiss"}
            </Button>
          </div>
        </Expire>
      );
    }

    return (
      <div className="contacts-page">
        <Row className="sub-header nav-search-sub-header">
          <div>
            <div className="nav-search-sub-header-menu">
              <ul>
                <li>
                  <NavLink
                    to={`/contacts/contacts/today`}
                    activeClassName="header-active-link"
                  >
                    All Contacts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/contacts/starred-contacts`}
                    activeClassName="header-active-link"
                  >
                    Starred Contacts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/contacts/pw-ip-contacts`}
                    activeClassName="header-active-link"
                  >
                    PW/IP Contacts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/contacts/closing-clients-contacts`}
                    activeClassName="header-active-link"
                  >
                    CL Contacts (12 Months)
                  </NavLink>
                </li>
              </ul>
            </div>
            <div>
              <div className="search-container search-dropdown-container">
                <Select
                  onChange={(e) => this.changeSearchType(e)}
                  value={this.state.searchType}
                >
                  <Option label="Name/Company" value="name" />
                  <Option label="Phone" value="phone" />
                </Select>
                <Form
                  className="search-form"
                  inline={true}
                  onSubmit={() =>
                    this.handleSearchSubmit(this.state.searchType, this)
                  }
                >
                  <input
                    className="input"
                    type="text"
                    placeholder="Search for..."
                    ref="searchBox"
                    onChange={this.handleSearchChange}
                    onKeyDown={this.handleEnter}
                    onBlur={this.handleBlur}
                    onFocus={this.changeToSearchPage}
                  />
                  <i className="material-icons directory-search-icon">search</i>
                </Form>
              </div>
            </div>
            <div className="mobile-dropdown-container">
              <div className="mobile-dropdown">
                <i
                  className="material-icons pointer"
                  onClick={() => this.toggleDropdown()}
                >
                  more_vert
                </i>
                <div
                  className={
                    this.state.mobileDropdownShowing
                      ? "mobile-dropdown-content"
                      : "mobile-dropdown-content mobile-dropdown-content-hidden"
                  }
                >
                  <Link to={`/contacts/contacts/today`}>All Contacts</Link>
                  <Link to={`/contacts/starred-contacts`}>
                    Starred Contacts
                  </Link>
                  <Link to={`/contacts/pw-ip-contacts`}>PW/IP Contacts</Link>
                  <Link to={`/contacts/closing-clients-contacts`}>
                    CL Contacts (12 Months)
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Row>
        <div className="contacts-container">
          {this.props.children}
          {successMessage}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    title: state.navTitle,
    contacts: state.contacts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    titleActions: bindActionCreators(navigationTitleActions, dispatch),
    contactActions: bindActionCreators(contactActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsContainer2);
