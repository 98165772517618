import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import debounce from "lodash.debounce";

import * as dealReviewActions from "../../actions/dealReviewActions";
import config from "../../config";
import { ajax, Navigation } from "../../lib";

import Expire from "../common/Expire";
import getParameterByName from "../common/getParameterByName";
import PageNavigation from "../common/PageNavigation";

import DealReviewCard from "./DealReviewCard";
import DealReviewSidebar from "./DealReviewSidebar";

class DealReviewList extends React.PureComponent {
  state = {
    detailsSidebarDeal: {},
    detailsSidebarOpen: false,
    searchParams: {},
    users: "",
  };

  componentDidMount() {
    this.setState(
      {
        searchParams: {
          member_id: getParameterByName("member_id"),
          member_group: getParameterByName("member_group"),
          deal_status: getParameterByName("deal_status"),
          deal_rating: getParameterByName("deal_rating"),
          deal_id: getParameterByName("deal_id"),
          last_reviewed_before: getParameterByName("last_reviewed_before"),
          expected_ip_date_before: getParameterByName(
            "expected_ip_date_before"
          ),
          expected_ip_date_after: getParameterByName("expected_ip_date_after"),
          deal_type: getParameterByName("deal_type"),
          loan_type: getParameterByName("loan_type"),
          property_type: getParameterByName("property_type"),
          property_city: getParameterByName("property_city"),
          property_state: getParameterByName("property_state"),
          status_change_before: getParameterByName("status_change_before"),
          status_change_after: getParameterByName("status_change_after"),
          loan_amount_min: getParameterByName("loan_amount_min"),
          loan_amount_max: getParameterByName("loan_amount_max"),
          in_contract: getParameterByName("in_contract"),
          inprocess_line: getParameterByName("inprocess_line"),
          expected_cl_date_before: getParameterByName(
            "expected_cl_date_before"
          ),
          expected_cl_date_after: getParameterByName("expected_cl_date_after"),
          page: getParameterByName("page"),
          originator: getParameterByName("originator"),
        },
        users: getParameterByName("member_name"),
      },
      () => {
        let searchParams = this.state.searchParams;
        //convert necessary parameters to arrays
        searchParams.member_id =
          searchParams.member_id && searchParams.member_id.split(",");
        searchParams.member_group =
          searchParams.member_group && searchParams.member_group.split(",");
        searchParams.deal_status =
          searchParams.deal_status && searchParams.deal_status.split(",");
        searchParams.deal_rating =
          searchParams.deal_rating && searchParams.deal_rating.split(",");
        searchParams.deal_type =
          searchParams.deal_type && searchParams.deal_type.split(",");
        searchParams.deal_id =
          searchParams.deal_id && searchParams.deal_id.split(",");
        searchParams.loan_type =
          searchParams.loan_type && searchParams.loan_type.split(",");
        searchParams.property_type =
          searchParams.property_type && searchParams.property_type.split(",");
        searchParams.in_contract =
          searchParams.in_contract && searchParams.in_contract.split(",");
        searchParams.inprocess_line =
          searchParams.inprocess_line && searchParams.inprocess_line.split(",");
        Object.keys(searchParams).forEach(
          (key) =>
            (searchParams[key] === null || searchParams[key] === "") &&
            delete searchParams[key]
        );
        this.props.dealReviewActions.getDealReviewResults(searchParams);
      }
    );
    const {
      getDealStatuses,
      getDealTypes,
      getLoanTypes,
      getPropertyTypes,
      getStates,
    } = this.props.dealReviewActions;
    getDealStatuses();
    getDealTypes();
    getLoanTypes();
    getPropertyTypes();
    getStates();
  }

  componentDidUpdate(prevProps) {
    //load new deals based on page result
    if (prevProps.location.search !== this.props.location.search) {
      let page = getParameterByName("page");
      if (page !== this.state.searchParams.page) {
        let searchParams = {
          ...this.state.searchParams,
          page: parseInt(page),
        };
        this.props.dealReviewActions.getDealReviewResults(searchParams);
        this.setState({ searchParams: { ...this.state.searchParams, page } });
      }
    }
    //update sidebar deal details
    const { detailsSidebarOpen, detailsSidebarDeal } = this.state;
    if (
      prevProps.dealReview.deals !== this.props.dealReview.deals &&
      detailsSidebarOpen
    ) {
      this.setState({
        detailsSidebarDeal: this.props.dealReview.deals.results.filter(
          (deal) => deal.deal_id === detailsSidebarDeal.deal_id
        )[0],
      });
    }
  }

  getOriginatingBroker = (brokers) => {
    let originatingBroker = brokers
      .filter((broker) => broker.is_originator === 1)
      .map((broker) => {
        return (
          <p key={broker.broker_id}>
            {broker.first_name} {broker.last_name}
          </p>
        );
      });
    if (originatingBroker.length > 0) {
      return originatingBroker;
    } else {
      return "--";
    }
  };

  openDetailsSidebar = (deal) => {
    if (deal.deal_status === "1") {
      this.props.dealReviewActions.getDealBankQuotes(deal.deal_id);
    }
    this.setState({ detailsSidebarDeal: deal, detailsSidebarOpen: true });
  };

  closeDetailsSidebar = () => {
    this.setState({ detailsSidebarOpen: false });
  };

  updateDealReview = async (dealID, updateProperty, updateValue) => {
    const { updateDealReview } = this.props.dealReviewActions;
    let update = { [updateProperty]: updateValue };
    await updateDealReview(dealID, update);
  };

  updateDealReviewDebounce = debounce((dealID, updateProperty, updateValue) => {
    this.updateDealReview(dealID, updateProperty, updateValue);
  }, 750);

  getCityState = async (zip) => {
    let cityStateArray = ajax({
      url: `${config.BASE_URL}get-cities?city_or_zip=${zip}`,
      success: function (response) {
        return response && response.data && response.data.data;
      },
    });
    return cityStateArray;
  };

  updateDealReviewProperty = async (
    dealID,
    property,
    updateProperty,
    updateValue
  ) => {
    let update = {};
    let address = {
      property_street_number: property.property_street_number,
      property_street_name: property.property_street_name,
      property_city: property.property_city,
      property_state: property.property_state,
      property_zip: property.property_zip,
    };
    if (updateProperty === "property_zip") {
      let updatedAddressFields = await this.getCityState(updateValue);
      if (updatedAddressFields.isResponse === true) {
        address.property_city =
          updatedAddressFields.data && updatedAddressFields.data[0].city;
        address.property_state =
          updatedAddressFields.data && updatedAddressFields.data[0].state;
      }
    }
    if (updateProperty === "property_type_id") {
      update = { [updateProperty]: updateValue };
    } else {
      update = address;
      update[updateProperty] = updateValue;
    }
    let index = this.state.detailsSidebarDeal.properties.indexOf(property);
    if (index >= 0) {
      let properties = [...this.state.detailsSidebarDeal.properties];
      properties[index] = {
        ...properties[index],
        ...update,
      };
      let updatedDetailsSidebarDeal = {
        ...this.state.detailsSidebarDeal,
        properties,
      };
      this.setState({
        detailsSidebarDeal: updatedDetailsSidebarDeal,
      });
    }
    this.props.dealReviewActions.updateDealReviewProperty(
      dealID,
      property.property_id,
      update
    );
  };

  updateDealReviewPropertyDebounce = debounce(
    (dealID, property, updateProperty, updateValue) => {
      this.updateDealReviewProperty(
        dealID,
        property,
        updateProperty,
        updateValue
      );
    },
    750
  );

  render() {
    const {
      deals: { currentPage, nextPage, prevPage, perPage, results, total } = {},
      dealDetails,
      dealStatuses,
      dealTypes,
      loading,
      loanTypes,
      propertyTypes,
      states,
      updateToast,
    } = this.props.dealReview;
    const { detailsSidebarDeal, detailsSidebarOpen, users } = this.state;
    return (
      <React.Fragment>
        <div className="deal-review-subheader flex flex-center flex-space-justify">
          <div className="flex flex-center">
            <i
              className="material-icons white link-text"
              onClick={() => Navigation.goBack()}
            >
              arrow_back
            </i>

            <p className="medium-text ml-16">{users.replace(/,/g, ", ")}</p>
          </div>
          {!loading && <p>{total} Deals</p>}
        </div>
        {loading ? (
          <div className="centered-spinner">
            <img
              src="/images/icons/spin.svg"
              className="loading-spinner"
              alt="loading spinner"
            />
          </div>
        ) : results && results.length > 0 ? (
          <div className="flex">
            <div className="deal-review-results">
              {results.map((deal, i) => (
                <DealReviewCard
                  deal={deal}
                  dealStatuses={dealStatuses}
                  dealTypes={dealTypes}
                  getOriginatingBroker={this.getOriginatingBroker}
                  handleFilterChange={this.handleFilterChange}
                  index={i}
                  key={deal.deal_id}
                  loanTypes={loanTypes}
                  openDetailsSidebar={this.openDetailsSidebar}
                  page={this.state.searchParams.page}
                  updateDealReview={this.updateDealReview}
                  updateDealReviewDebounce={this.updateDealReviewDebounce}
                />
              ))}
              <PageNavigation
                totalPages={total / perPage}
                currentPage={currentPage}
                prevPage={prevPage}
                nextPage={nextPage}
              />
            </div>
            {detailsSidebarOpen && (
              <DealReviewSidebar
                addDealReviewNote={
                  this.props.dealReviewActions.addDealReviewNote
                }
                client={this.props.dealReview.client}
                deal={detailsSidebarDeal}
                dealDetails={dealDetails}
                getFullClientInfo={
                  this.props.dealReviewActions.getFullClientInfo
                }
                onClose={this.closeDetailsSidebar}
                open={detailsSidebarOpen}
                propertyTypes={propertyTypes}
                states={states}
                updateDealReview={this.updateDealReview}
                updateDealReviewDebounce={this.updateDealReviewDebounce}
                updateDealReviewProperty={this.updateDealReviewProperty}
                updateDealReviewPropertyDebounce={
                  this.updateDealReviewPropertyDebounce
                }
              />
            )}
          </div>
        ) : (
          <div>
            <div className="grey-circle">
              <i className="material-icons">warning</i>
            </div>
            <h2 className="no-results">No Deals</h2>
          </div>
        )}
        {updateToast && updateToast.show && (
          <Expire
            className={`update-toast ${
              updateToast.success ? "success" : "error"
            }`}
            closeToast={this.props.dealReviewActions.closeUpdateDealReviewToast}
            delay={1500}
          >
            <div className="message">
              <p className="flex flex-center">
                <i className="material-icons mr-8">
                  {updateToast.success
                    ? "check_circle_outline"
                    : "error_outline"}
                </i>
                {updateToast.message}
              </p>
            </div>
          </Expire>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    dealReview: state.dealReview,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dealReviewActions: bindActionCreators(dealReviewActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DealReviewList);
