import React from "react";
import Input from "muicss/lib/react/input";

class FormattedInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      actualValue: "",
      formattedValue: "",
      errorMessage: "",
    };

    this.onChange = this.onChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  onChange(e) {
    let element = e.target;
    let value = e.target.value;
    let actualValue = this.stripFormat(value);
    let formattedValue = "";
    if (actualValue) {
      if (parseFloat(actualValue) > Number.MAX_SAFE_INTEGER) {
        actualValue = actualValue.slice(0, -1);
      } else if (actualValue === ".") {
        actualValue = "0.";
      }
      formattedValue = this.formatValue(actualValue);
    } else {
      actualValue = "";
      formattedValue = "";
    }
    let endPosition = actualValue
      ? actualValue.length - element.selectionStart
      : 0;
    this.setState(
      {
        actualValue,
        formattedValue,
      },
      () => {
        this.props.update(this.state.actualValue, this.props.name);
        if (formattedValue.slice(-1) === "%") {
          setTimeout(() => {
            element.focus();
            let position = this.state.formattedValue.length - endPosition;
            if (endPosition === 0) {
              position = position - 1;
            }
            element.setSelectionRange(position, position);
          }, 0);
        }
      }
    );
  }

  validate(input_value, min_value, max_value, required) {
    let minValue = parseFloat(min_value);
    let maxValue = parseFloat(max_value);
    let inputValue = parseFloat(input_value);

    if (required && !inputValue) {
      this.setState({ errorMessage: "this field is required" });
    } else if (minValue && inputValue < minValue) {
      this.setState({
        errorMessage:
          "Value must be greater than " + this.formatValue(minValue),
      });
    } else if (maxValue && inputValue > maxValue) {
      this.setState({
        errorMessage: "Value must be less than " + this.formatValue(maxValue),
      });
    } else {
      this.setState({ errorMessage: "" });
    }
  }

  onBlur() {
    if (this.props.onBlur && this.state.actualValue) {
      this.props.onBlur(this.state.actualValue);
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <Input
          label={this.props.label}
          floatingLabel={this.props.floatingLabel || false}
          disabled={this.props.disabled || false}
          value={this.state.formattedValue}
          onChange={this.onChange}
          onBlur={this.onBlur.bind(this)}
          placeholder={this.props.placeholder}
        />
        <p className="error-block">{this.state.errorMessage}</p>
      </div>
    );
  }
}

export default FormattedInput;
