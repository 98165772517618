import * as types from "../actionTypes";
import utils from "./IRRdependencies/utils";
import error from "./IRRdependencies/error";
import { updateCentralValues } from "../../actions/calculatorActions/centralActions";
const Finance = require("financejs");
const finance = new Finance();

function updateIRR(values) {
  return { type: types.UPDATE_IRR, values };
}

export function calculateIRR(values, trigger) {
  return function (dispatch) {
    dispatch(calculate(values, trigger));
    dispatch(updateIRR(values));
  };
}

function calculate(values, trigger) {
  return function (dispatch) {
    if (trigger.name === "closingCostAmount") {
      dispatch(updateCentralValues(trigger.name, trigger.value));
      values.closingCostAmount = trigger.value;
      if (values.purchasePrice && values.closingCostAmount) {
        values.closingCostPercent = parseFloat(
          (values.closingCostAmount / values.purchasePrice) * 100
        ).toFixed(3);
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      } else {
        values.closingCostPercent = 0;
        dispatch(
          updateCentralValues("closingCostAmount", values.closingCostAmount)
        );
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      }
    } else if (trigger.name === "closingCostPercent") {
      values.closingCostPercent = trigger.value;
      dispatch(updateCentralValues(trigger.name, trigger.value));
      if (values.purchasePrice.length && values.closingCostPercent) {
        values.closingCostAmount =
          (values.closingCostPercent * values.purchasePrice) / 100;
        dispatch(
          updateCentralValues("closingCostAmount", values.closingCostAmount)
        );
      } else {
        values.closingCostAmount = 0;
        dispatch(
          updateCentralValues("closingCostAmount", values.closingCostAmount)
        );
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      }
    } else if (trigger.name === "purchasePrice") {
      dispatch(updateCentralValues(trigger.name, trigger.value));
      if (values.purchasePrice && values.closingCostAmount) {
        values.closingCostPercent = parseFloat(
          (values.closingCostAmount / values.purchasePrice) * 100
        ).toFixed(3);
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      } else {
        values.closingCostPercent = 0;
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      }
    } else if (trigger.name === "IOToggle") {
      if (trigger.value === "Blended IO" && !values.amortizationStartsAfter) {
        values.annualPayment = 0;
      }
    } else if (
      values.IOToggle === "Blended IO" &&
      values.amortizationStartsAfter &&
      values.projectedSaleYear
    ) {
      let todaysYear = new Date().getFullYear();
      let diffYears =
        parseFloat(todaysYear) + 1 + parseFloat(values.amortizationStartsAfter);
      if (diffYears >= values.projectedSaleYear) {
        values.amortizationError = "The Amortization Starts After is too high";
      } else if (diffYears < values.projectedSaleYear) {
        values.amortizationError = "";
      }
    }

    //NEED TO BE IN THIS PARTICULAR ORDER:
    calculateAllInCosts(values);
    calculateLoanAmount(values);
    calculateCashRequired(values);
    calculateYearlyPaymentsORIOPaymentss(values);
    calculateCUMPRINCProjectedLoanBalanceAtEndSaleDate(values);
    generateNOIGrid(values);
    calculateFreeCashFlow(values);
    calculateCashOnCashReturn(values);
    calculateSalePrice(values);
    calculateClosingCost(values);
    calculateFinalArrayValues(values);
    calculateAvailableToBeDistributed(values);
    calculateIrr(values);
  };
}

function calculateCashOnCashReturn(values) {
  if (values.freeCashFlow && values.allInCosts && values.loanAmount) {
    values.cashOnCashReturn =
      values.freeCashFlow / (values.allInCosts - values.loanToValueAmount);
  } else {
    values.cashOnCashReturn = 0;
  }
}

function calculateCashRequired(values) {
  if (values.allInCosts && values.loanAmount) {
    values.cashOnCashRequired = values.allInCosts - values.loanAmount;
  } else {
    values.cashOnCashRequired = 0;
  }
}

function calculateAllInCosts(values) {
  if (values.purchasePrice) {
    if (values.closingCostAmount) {
      values.allInCosts =
        parseFloat(values.purchasePrice) + parseFloat(values.closingCostAmount); //work on way to parse all properties and avoid this.
      if (values.capex) {
        values.allInCosts =
          parseFloat(values.allInCosts) + parseFloat(values.capex);
      }
    } else {
      values.allInCosts = values.purchasePrice;
      if (values.capex) {
        values.allInCosts =
          parseFloat(values.allInCosts) + parseFloat(values.capex);
      }
    }
  } else {
    values.allInCosts = 0;
  }
}

function calculateFreeCashFlow(values) {
  if (
    values.year1NOIBeforeDebtService &&
    (values.annualPayment || values.IOPayment)
  ) {
    if (values.IOToggle === "No IO") {
      values.freeCashFlow =
        values.year1NOIBeforeDebtService - values.annualPayment;
    } else if (values.IOToggle === "Full IO") {
      values.freeCashFlow = values.year1NOIBeforeDebtService - values.IOPayment;
    } else if (values.IOToggle === "Blended IO") {
      values.freeCashFlow = values.year1NOIBeforeDebtService - values.IOPayment;
    }
  } else {
    values.freeCashFlow = 0;
  }
}

function calculateSalePrice(values) {
  if (values.arrayForTable.length > 0 && values.projectedCapRateAtSale) {
    const todaysYear = new Date().getFullYear();
    let index = values.projectedSaleYear - todaysYear;
    values.salePrice =
      values.arrayForTable[index].NOI / (values.projectedCapRateAtSale / 100);
  } else {
    values.salePrice = 0;
  }
}

function calculateClosingCost(values) {
  if (values.salePrice && values.projectedClosingCostsAtSale) {
    values.closingCost =
      values.salePrice * (values.projectedClosingCostsAtSale / 100);
  } else {
    values.closingCost = 0;
  }
}

function calculateLoanAmount(values) {
  if (
    values.percentageOf &&
    values.loanToValuePercent &&
    values.purchasePrice
  ) {
    if (values.percentageOf === "All in Costs") {
      if (values.closingCostAmount) {
        if (values.capex) {
          values.loanAmount =
            (values.loanToValuePercent / 100) *
            (parseFloat(values.purchasePrice) +
              parseFloat(values.closingCostAmount) +
              parseFloat(values.capex));
        } else {
          values.loanAmount =
            (values.loanToValuePercent / 100) *
            (parseFloat(values.purchasePrice) +
              parseFloat(values.closingCostAmount));
        }
      } else {
        values.loanAmount = 0;
      }
    }
    if (values.percentageOf === "Purchase Price") {
      values.loanAmount =
        (values.loanToValuePercent / 100) * values.purchasePrice;
    }
  } else {
    values.loanAmount = 0;
  }
}

function calculateYearlyPaymentsORIOPaymentss(values) {
  if (values.interestRate && values.loanAmount) {
    if (values.IOToggle === "No IO") {
      if (values.amortizationYears) {
        const intDiv12 = values.interestRate / 100 / 12;
        const amortMult12 = values.amortizationYears * 12;
        values.annualPayment =
          ((intDiv12 *
            values.loanAmount *
            Math.pow(1 + intDiv12, amortMult12)) /
            (Math.pow(1 + intDiv12, amortMult12) - 1)) *
          12;
      } else {
        values.annualPayment = 0;
      }
    } else if (values.IOToggle === "Full IO") {
      const intDiv12 = values.interestRate / 100 / 12;
      const amortMult12 = values.amortizationYears * 12;
      values.annualPayment =
        ((intDiv12 * values.loanAmount * Math.pow(1 + intDiv12, amortMult12)) /
          (Math.pow(1 + intDiv12, amortMult12) - 1)) *
        12;
      values.IOPayment = values.loanAmount * (values.interestRate / 100);
      values.annualPayment = 0;
    } else if (values.IOToggle === "Blended IO") {
      const intDiv12 = values.interestRate / 100 / 12;
      const amortMult12 = values.amortizationYears * 12;
      values.annualPayment =
        ((intDiv12 * values.loanAmount * Math.pow(1 + intDiv12, amortMult12)) /
          (Math.pow(1 + intDiv12, amortMult12) - 1)) *
        12;
      values.IOPayment = values.loanAmount * (values.interestRate / 100);
    }
  } else {
    values.annualPayment = 0;
    values.IOPayment = 0;
  }
}

function calculateCUMPRINCProjectedLoanBalanceAtEndSaleDate(values) {
  if (values.interestRate && values.loanAmount && values.projectedSaleYear) {
    if (values.IOToggle === "Full IO") {
      values.amortizationYears = 0;
      values.amortizationStartsAfter = 0;
    } else if (values.IOToggle === "No IO") {
      values.amortizationStartsAfter = 0;
    }
    if (values.IOToggle === "No IO" && !values.amortizationYears) {
      values.projectedLoanBalanceAtSaleDate = 0;
      return;
    } else if (
      values.IOToggle === "Blended IO" &&
      (!values.amortizationStartsAfter || !values.amortizationYears)
    ) {
      values.projectedLoanBalanceAtSaleDate = 0;
      return;
    }
    const interestRate = values.interestRate / 100;
    if (values.IOToggle === "Full IO") {
      values.projectedLoanBalanceAtSaleDate = values.loanAmount;
    } else {
      const todaysYear = new Date().getFullYear();
      let years =
        values.projectedSaleYear - todaysYear - values.amortizationStartsAfter;
      let cumprincResult = CUMPRINC(
        interestRate / 12,
        values.amortizationYears * 12,
        values.loanAmount,
        1,
        years * 12,
        0
      );
      values.projectedLoanBalanceAtSaleDate =
        cumprincResult + values.loanAmount;
    }
  } else {
    values.projectedLoanBalanceAtSaleDate = 0;
  }
}

function CUMPRINC(rate, periods, value, start, end, type) {
  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  value = utils.parseNumber(value);
  if (utils.anyIsError(rate, periods, value)) {
    return error.value;
  }

  // Return error if either rate, periods, or value are lower than or equal to zero
  if (rate <= 0 || periods <= 0 || value <= 0) {
    return error.num;
  }

  // Return error if start < 1, end < 1, or start > end
  if (start < 1 || end < 1 || start > end) {
    return error.num;
  }

  // Return error if type is neither 0 nor 1
  if (type !== 0 && type !== 1) {
    return error.num;
  }

  // Compute cumulative principal
  var payment = PMT(rate, periods, value, 0, type);
  var principal = 0;
  if (start === 1) {
    if (type === 0) {
      principal = payment + value * rate;
    } else {
      principal = payment;
    }
    start++;
  }
  for (var i = start; i <= end; i++) {
    if (type > 0) {
      principal +=
        payment - (FV(rate, i - 2, payment, value, 1) - payment) * rate;
    } else {
      principal += payment - FV(rate, i - 1, payment, value, 0) * rate;
    }
  }

  // Return cumulative principal
  return principal;
}

function FV(rate, periods, payment, value, type) {
  value = value || 0;
  type = type || 0;

  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  payment = utils.parseNumber(payment);
  value = utils.parseNumber(value);
  type = utils.parseNumber(type);
  if (utils.anyIsError(rate, periods, payment, value, type)) {
    return error.value;
  }

  // Return future value
  var result;
  if (rate === 0) {
    result = value + payment * periods;
  } else {
    var term = Math.pow(1 + rate, periods);
    if (type === 1) {
      result = value * term + (payment * (1 + rate) * (term - 1)) / rate;
    } else {
      result = value * term + (payment * (term - 1)) / rate;
    }
  }
  result = -result;
  return result;
}

function PMT(rate, periods, present, future, type) {
  future = future || 0;
  type = type || 0;

  rate = utils.parseNumber(rate);
  periods = utils.parseNumber(periods);
  present = utils.parseNumber(present);
  future = utils.parseNumber(future);
  type = utils.parseNumber(type);
  if (utils.anyIsError(rate, periods, present, future, type)) {
    return error.value;
  }

  // Return payment
  var result;
  if (rate === 0) {
    result = (present + future) / periods;
  } else {
    var term = Math.pow(1 + rate, periods);
    if (type === 1) {
      result =
        ((future * rate) / (term - 1) + (present * rate) / (1 - 1 / term)) /
        (1 + rate);
    } else {
      result = (future * rate) / (term - 1) + (present * rate) / (1 - 1 / term);
    }
  }
  result = -result;
  return result;
}

function calculateAvailableToBeDistributed(values) {
  if (
    values.salePrice &&
    values.closingCost &&
    values.projectedLoanBalanceAtSaleDate
  ) {
    values.availableToBeDistributed =
      values.salePrice -
      values.closingCost -
      values.projectedLoanBalanceAtSaleDate;
  } else {
    values.availableToBeDistributed = 0;
  }
}

function generateNOIGrid(values) {
  if (
    values.projectedSaleYear &&
    values.year1NOIBeforeDebtService &&
    values.annualizedNOIGrowthRate &&
    (values.annualPayment || values.IOPayment)
  ) {
    const todaysYear = new Date().getFullYear();
    let years = values.projectedSaleYear - todaysYear + 1;
    values.arrayForTable = [];
    for (let i = 0; i < years; i++) {
      values.arrayForTable[i] = {};
      if (i === 0) {
        values.arrayForTable[0].NOI = values.year1NOIBeforeDebtService;
      } else {
        const growthRate = values.annualizedNOIGrowthRate / 100;
        values.arrayForTable[i].NOI =
          parseFloat(values.arrayForTable[i - 1].NOI * growthRate) +
          parseFloat(values.arrayForTable[i - 1].NOI);
      }
      if (values.IOToggle === "No IO") {
        values.arrayForTable[i].debtService = values.annualPayment;
      } else if (values.IOToggle === "Full IO") {
        values.arrayForTable[i].debtService = values.IOPayment;
      } else if (values.IOToggle === "Blended IO") {
        // const todaysYear = new Date().getFullYear();
        // let years = (values.projectedSaleYear - todaysYear);
        if (i < values.amortizationStartsAfter) {
          values.arrayForTable[i].debtService = values.IOPayment;
        } else {
          values.arrayForTable[i].debtService = values.annualPayment;
        }
      }

      values.arrayForTable[i].freeCashFlow =
        values.arrayForTable[i].NOI - values.arrayForTable[i].debtService;
      if (i === 0) {
        values.arrayForTable[0].runningTotal =
          values.arrayForTable[0].freeCashFlow;
      } else {
        values.arrayForTable[i].runningTotal =
          parseFloat(values.arrayForTable[i].freeCashFlow) +
          parseFloat(values.arrayForTable[i - 1].runningTotal);
      }
    }
  } else {
    values.arrayForTable = [];
  }
}

function calculateFinalArrayValues(values) {
  if (
    values.arrayForTable.length > 0 &&
    values.salePrice &&
    values.closingCost &&
    values.projectedLoanBalanceAtSaleDate
  ) {
    const todaysYear = new Date().getFullYear();
    let diffYears = values.projectedSaleYear - todaysYear;
    let totalcashflow = 0;
    for (let i = 0; i < diffYears; i++) {
      totalcashflow =
        parseFloat(totalcashflow) +
        parseFloat(values.arrayForTable[i].freeCashFlow);
    }
    values.finalArrayValues.totalCashFlow = totalcashflow;
    values.finalArrayValues.totalSalesProceeds =
      values.salePrice -
      values.closingCost -
      values.projectedLoanBalanceAtSaleDate;
    values.finalArrayValues.grandTotalCashFlow =
      parseFloat(values.finalArrayValues.totalCashFlow) +
      parseFloat(values.finalArrayValues.totalSalesProceeds);
    values.totalDistributedPriorToSale = values.finalArrayValues.totalCashFlow;
    values.totalDistributedLifeOfInvestment =
      values.finalArrayValues.grandTotalCashFlow;
  } else {
    values.finalArrayValues = {};
    values.totalDistributedLifeOfInvestment = 0;

    values.totalDistributedPriorToSale = 0;
  }
}

function calculateIrr(values) {
  try {
    if (
      values.projectedSaleYear &&
      values.cashOnCashRequired &&
      values.arrayForTable &&
      values.availableToBeDistributed
    ) {
      const todaysYear = new Date().getFullYear();
      let years = values.projectedSaleYear - todaysYear;
      //values to pass into irr function:
      let valuesToPassToIRR = [];
      const cashRequired = -values.cashOnCashRequired;
      valuesToPassToIRR.push(cashRequired);
      for (let i = 0; i < years - 1; i++) {
        valuesToPassToIRR.push(values.arrayForTable[i].freeCashFlow);
      }
      valuesToPassToIRR.push(
        values.arrayForTable[values.arrayForTable.length - 2].freeCashFlow +
          values.availableToBeDistributed
      );
      let IrrResult = finance.IRR(...valuesToPassToIRR);
      values.IRRPercentage = IrrResult;
    } else {
      values.IRRPercentage = 0;
      values.error = "";
    }
  } catch (e) {
    if (e.message) {
      values.error = "IRR cannot be calculated with the inputted values.";
    } else {
      values.error = "";
    }
    values.IRRPercentage = 0;
  }
  if (values.IRRPercentage !== 0 || values.IRRPercentage !== "") {
    values.error = "";
  }
}
