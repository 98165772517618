import * as types from "./actionTypes";
import config from "../config";
import { tokenExpired } from "./authenticationActions";
import { ajax } from "../lib";
var _ = require("lodash");

export function loadPWDeals() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_PW_DEALS });
    ajax({
      url: `${config.BASE_URL}deals/pw`,
      applyAuthHeader: true,
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          case "204":
            dispatch({ type: types.NO_PW_DEALS });
            break;
          default:
            dispatch({
              type: types.LOAD_PW_DEALS_SUCCESS,
              pwDeals: response.data,
            });
            break;
        }
      },
    });
  };
}


export function loadFPWDeals() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_FPW_DEALS });
    ajax({
      url: `${config.BASE_URL}deals/fpw`,
      applyAuthHeader: true,
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          case "204":
            dispatch({ type: types.NO_FPW_DEALS });
            break;
          default:
            dispatch({
              type: types.LOAD_FPW_DEALS_SUCCESS,
              fpwDeals: response.data,
            });
            break;
        }
      },
    });
  };
}

export function loadUWDeals() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_UW_DEALS });
    ajax({
      url: `${config.BASE_URL}deals/uw`,
      applyAuthHeader: true,
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          case "204":
            dispatch({ type: types.LOAD_UW_DEALS, uwDeals: [] });
            break;
          default:
            dispatch({ type: types.LOAD_UW_DEALS, uwDeals: response.data });
            break;
        }
      },
    });
  };
}

export function loadIPDeals() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_IP_DEALS });
    ajax({
      url: `${config.BASE_URL}deals/ip`,
      applyAuthHeader: true,
      success: function (response) {
        if (!response.isResponse) {
          dispatch(tokenExpired());
        }
        if (!response.data) {
          dispatch({ type: types.NO_IP_DEALS });
        } else {
          dispatch({
            type: types.LOAD_IP_DEALS_SUCCESS,
            ipDeals: response.data,
          });
        }
      },
    });
  };
}

export function updateStar(deal_id, broker_starred, dealType) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_UPDATE_STAR });
    ajax({
      url: `${config.BASE_URL}paper-work`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        deal_id,
        broker_starred,
      },
      success: function () {
        dispatch({
          type: types[`${dealType}_STAR_CHANGE_COMPLETED`],
          deal_id,
          broker_starred: !broker_starred ? 1 : 0,
        });
      },
    });
  };
}

export function updateRating(deal_id, deal_rating, dealType) {
  return async (dispatch) => {
    await ajax({
      url: `${config.BASE_URL}update-rating`,
      applyAuthHeader: true,
      type: "GET",
      data: {
        deal_id: deal_id,
        deal_rating: deal_rating,
      },
      success: function (response) {
        dispatch({
          type: types[`UPDATE_${dealType}_RATING_SUCCESS`],
          deal_id,
          deal_rating,
          success: response.isResponse,
          message: response.message,
        });
      },
    }).catch((err) => {});
  };
}

export function getFullContactInfo(Id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_FULL_CONTACT });
    ajax({
      url: `${config.BASE_URL}contact/${Id}`,
      applyAuthHeader: true,
      success: function (response) {
        if (response.data && response.data.length >= 1) {
          if (_.isEmpty(response.data[0].contacts[0].contact_info)) {
            dispatch({ type: types.LOAD_FULL_CONTACT, contact: {} });
          } else {
            dispatch({
              type: types.LOAD_FULL_CONTACT,
              contact: response.data[0].contacts[0],
            });
          }
        } else {
          dispatch({ type: types.LOAD_FULL_CONTACT, contact: {} });
        }
      },
    });
  };
}

export function updateSearch(suggestions, dealType) {
  return {
    type: types[`UPDATE_SEARCH_${dealType}_DEALS`],
    suggestions: suggestions,
  };
}

export function updateDealReviewed(
  deal_id,
  forecast = "",
  ip_date = "",
  notes = ""
) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}update-deal-reviewed`,
      applyAuthHeader: true,
      type: "POST",
      data: { deal_id, forecast, ip_date, notes },
      success: function (response) {
        dispatch({
          type: types.DEAL_REVIEWED_COMPLETED,
          deal_id,
          forecast_reviewed: forecast,
          ip_date,
          notes,
          message: response.message,
          success: response.isResponse,
        });
      },
    });
  };
}

// function updateDealReviewedCompleted(deal_id, updateDealReviewedResponse) {
//   return dispatch => {
//     ajax({
//       url: `${config.BASE_URL}deals/pw`,
//       applyAuthHeader: true,
//       success: function (response) {
//         dispatch({
//           type: types.DEAL_REVIEWED_COMPLETED, deal: response.data.find(i => i.deal_id === deal_id),
//           message: updateDealReviewedResponse.message, success: updateDealReviewedResponse.isResponse
//         });
//       }
//     });
//   };
// }

export function updateExpectedIPDate(deal_id, date, dealType) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}ip-date`,
      applyAuthHeader: true,
      type: "POST",
      data: { deal_id, date },
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types[`UPDATE_${dealType}_DEAL_IP_DATE`],
              success: response.isResponse,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function unconfirmedDealsOverdue() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}unconfirmed-deals/overdue`,
      applyAuthHeader: true,
      success: function (response) {
        dispatch({
          type: types.GET_UNCONFIRMED_DEALS_OVERDUE,
          unconfirmed_deal_overdue: response.data.unconfirmed_deal_overdue,
        });
      },
    });
  };
}

export function unconfirmedDealsCount() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}unconfirmed-deals/count`,
      applyAuthHeader: true,
      success: function (response) {
        dispatch({
          type: types.GET_UNCONFIRMED_DEALS_COUNT,
          unconfirmed_deal_count: response.data.unconfirmed_deal_count,
        });
      },
    });
  };
}

export function unconfirmedDeals() {
  return (dispatch) => {
    dispatch({
      type: types.GET_UNCONFIRMED_DEALS,
      unconfirmedDeals: [],
      loading: true,
    });
    ajax({
      url: `${config.BASE_URL}unconfirmed-deals/with-money`,
      applyAuthHeader: true,
      success: function (response) {
        dispatch({
          type: types.GET_UNCONFIRMED_DEALS,
          unconfirmedDeals: response.data,
          loading: false,
        });
      },
      error: function () {
        dispatch({
          type: types.GET_UNCONFIRMED_DEALS,
          unconfirmedDeals: [],
          loading: false,
        });
      },
    });
  };
}

export function unconfDealsNoMoney() {
  return (dispatch) => {
    dispatch({
      type: types.GET_UNCONF_DEALS_NO_MONEY,
      unconfDealsNoMoney: [],
      loading: true,
    });
    ajax({
      url: `${config.BASE_URL}unconfirmed-deals/no-money`,
      applyAuthHeader: true,
      success: function (response) {
        dispatch({
          type: types.GET_UNCONF_DEALS_NO_MONEY,
          unconfDealsNoMoney: response.data,
          loading: false,
        });
      },
      error: function () {
        dispatch({
          type: types.GET_UNCONF_DEALS_NO_MONEY,
          unconfDealsNoMoney: [],
          loading: false,
        });
      },
    });
  };
}

export function commentOnCommission(commissionId, data, dealType) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}deals-broker/${commissionId}/comment`,
      applyAuthHeader: true,
      method: "POST",
      data,
      success: function (response) {
        dispatch({
          type: types.COMMENT_ON_COMMISSION,
          message: response.message,
          success: response.success,
          dealType: dealType === "noMoney" ? "noMoney" : "withMoney",
          commissionId,
        });
        dispatch(unconfirmedDealsOverdue());
        dispatch(unconfirmedDealsCount());
      },
    });
  };
}

export function confirmCommission(commissionId, dealType) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}deals-broker/${commissionId}/confirm`,
      applyAuthHeader: true,
      method: "PUT",
      success: function (response) {
        dispatch({
          type:
            dealType === "noMoney"
              ? types.CONFIRM_COMMISSION_NO_MONEY
              : types.CONFIRM_COMMISSION,
          message: response.message,
          success: response.success,
          commissionId,
        });
        dispatch(unconfirmedDealsOverdue());
        dispatch(unconfirmedDealsCount());
      },
    });
  };
}

export function closeToast() {
  return { type: types.CLOSE_PW_DEAL_TOAST };
}
