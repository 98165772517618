export const TypesList = {
  ActivityStatus: 1,
  ActivityType: 2,
  ContactCategory: 3,
  PropertyStatus: 4,
  PropertyType: 5,
  PropertyCategory: 6,
  ContactInfo: 7,
  BankRating: 8,
  CityStateRegion: 9,
  PeopleOnDeals: 10,
  NewIPCLEmailOption: 11,
  LenderType: 12,
  LoanType1: 13, //Please identify, these were both in list_types table as Loan Type
  PropertyDocumentType: 14,
  PortalMaintenance: 15,
  ClientRating: 16,
  ContactJobDescription: 17,
  RentType: 18,
  RateSpread: 19,
  Recourse: 20,
  AffiliateBuyer: 21,
  LoanType2: 22, //Please identify, these were both in list_types table as Loan Type
  BankType: 23,
  Holiday: 24,
  GaveGift: 25,
  Prefix: 26,
  AppStatus: 27,
  InvestmentType: 28,
  ContactServices: 29,
  QuoteStatus: 30,
  InvestingCriteriaReturnType: 31,
  InvestingCriteriaFundType: 32,
  ConstructionType: 33,
  OfficeInvestmentType: 35,
  InvestorRating: 36,
  EmailUnsubscribeCategory: 37,
  EquityQuoteStatus: 38,
  QuoteWaterfallType: 39,
  InterestedEvents: 40,
  AttendedEvents: 41,
};

export const DealStatusType = {
  PW: 1,
  IP: 2,
  CL: 5,
  UW: 21,
  PFPW: 22,
};

export const eCalcForms = {
  CAP_RATE: "capRate",
  EQUITY_NEEDED: "equityNeeded",
  MORTGAGE_AND_DSCR: "mortgageDscr",
  CASH_ON_CASH: "cashOnCash",
  IRR: "irr",
  EQUITY_WATERFALL: "equityWaterfall",
  IRR_CAPITAL_EVENT: "irrCapitalEvent",
  POTENTIAL_GROSS_ITEMIZATION: "potential_gross_income",
  VACANCY: "vacancy",
  ANNUAL_EXPENSES: "annual_expenses",
  CLOSING_COSTS: "closing_costs",
  CLOSING_COST_CAPITAL_EVENT: "closing_costs_capital_event",
};

export const ECALC_LARGE_SCREEN = 1600;
export const ECALC_SHOW_SIDE_RESULTS = "(min-width:1070px)";
export const ECALC_SHOW_SIDE_RATES = "(min-width:1280px)";
export const ECALC_TABLET_VIEW = "(max-width:959px)";

export const fileFormat = { vCalc: "vCalc", pdf: "pdf" };
