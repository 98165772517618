import { createMuiTheme } from "@material-ui/core";

/**
 * Default theme for the auth area for mobile screens, based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 */
export const authMobileTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: { main: "#122035" },
    secondary: { main: "#10B8C7" },
    error: { main: "#FF5630" },
    text: {
      default: "#00112B !important",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "Karla",
    fontWeightRegular: "normal",
  },
  props: {
    MuiInputAdornment: {
      disableTypography: true,
    },
  },
  overrides: {
    MuiInputAdornment: {
      root: {
        fontSize: 19,
        lineHeight: 22,
      },
      positionEnd: {
        "& svg": {
          color: "#10B8C7",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "label + &": {
          marginTop: 20,
        },
        boxSizing: "border-box",
        borderRadius: 4,
        border: "1px solid #00112B",
        width: "100%",
        "&$focused": {
          border: "1px solid #10B8C7",
        },
        "&$error": {
          borderColor: "#FF5630",
        },
        "&$disabled": {
          backgroundColor: "#EFEFEF",
          border: "none",
          color: "#00112B",
        },
      },
      input: {
        fontFamily: "Roboto Condensed",
        fontSize: 19,
        // lineHeight: 22,
      },
      focused: {},
      notchedOutline: { border: "none", opacity: "0.5" },
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: "#00112B",
        fontSize: 14,
        lineHeight: 16,
        "&$focused": {
          color: "#10B8C7",
        },
        "&$error": {
          color: "#FF5630",
        },
        "&$disabled": {
          color: "#00112B",
        },
      },
      focused: {},
    },
    MuiTableCell: {
      root: {
        borderBottom: "unset",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "unset",
        },
      },
    },
  },
  // shadows,
});
