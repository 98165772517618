import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Button from "muicss/lib/react/button";
import { bindActionCreators } from "redux";
import { Navigation } from "../../lib";
import * as authActions from "../../actions/authenticationActions";

class VerificationRequiredPage extends React.Component {
  constructor(props) {
    super(props);

    this.resendActivationLink = this.resendActivationLink.bind(this);
  }

  resendActivationLink() {
    Navigation.go(`/resend-activation`);
  }

  render() {
    return (
      <div className="account-activation-page">
        <div className="account-activation-logo">
          <Link to="/">
            <img
              id="logo"
              src={"/images/eastern-union-white-small.png"}
              alt="Eastern Union Logo"
            />
          </Link>
        </div>

        <div className="account-activation-panel small panel">
          <p className="account-activation-header">Verify your email address</p>
          <p>You're almost done!</p>
          {this.props.location.state ? (
            <p>
              A verification email has been sent to: <br />
              <span className="bold-text">
                {this.props.location.state.email}
              </span>
            </p>
          ) : (
            <p>A verification email has been sent to you.</p>
          )}
          <p>
            Please follow the link in the email to complete the setup of your
            account.
          </p>
          <div className="account-activation-panel-footer account-activation-panel-footer-margin-top">
            <p>For support please contact</p>
            <p>
              <a
                href="mailto:app@easternunion.com"
                className="orange-text orange-text-hover pointer"
              >
                app@easternunion.com
              </a>
            </p>
            <Button
              variant="flat"
              className="button btn-accent btn-fullWidth mui--z1 margin-top"
              onClick={this.resendActivationLink}
            >
              Resend Activation Link
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerificationRequiredPage);
