import React, { forwardRef, Fragment } from "react";
import clsx from "clsx";
import {
  useMediaQuery,
  Dialog,
  DialogActions,
  IconButton,
  Slide,
  Button,
} from "@material-ui/core";
import { ArrowBackIcon, AddIcon } from "../../../themes";
import { useStyles } from "./itemization.styles";
import { formatCurrency } from "../../../lib";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ItemizationLayout({
  open,
  handleClose,
  handleAdd,
  handleReset,
  title,
  children,
  totals,
}) {
  const classes = useStyles();
  const showFullPageModal = useMediaQuery("(max-width:815px)");

  return (
    <Dialog
      fullScreen={showFullPageModal}
      fullWidth={!showFullPageModal ? true : undefined}
      maxWidth={!showFullPageModal ? "md" : undefined}
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
      TransitionComponent={showFullPageModal ? Transition : undefined}
    >
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <ArrowBackIcon />
      </IconButton>
      <div className={classes.title}>{title}</div>
      <div className={classes.scrollableContent}>
        <div className={classes.content}>{children}</div>
      </div>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <div className={classes.fabWrapper}>
          <Button classes={{ root: classes.cancelBtn }} onClick={handleReset}>
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="primary"
            aria-label="add"
            className={classes.addButton}
            onClick={handleAdd}
            startIcon={<AddIcon className={classes.addIcon} />}
          >
            Add
          </Button>
        </div>
      </DialogActions>
      {Array.isArray(totals) ? (
        <div
          className={clsx(classes.totalContainer, classes.multiTotalContainer)}
        >
          {totals.map((total, index) => {
            const { label, value, display } = total;
            return (
              <Fragment>
                <div
                  key={index}
                  className={clsx(
                    classes.singleTotalContainer,
                    classes.shortTotal
                  )}
                >
                  <span className={classes.totalLabel}>{label}</span>
                  <div className={classes.total}>
                    {display === "percent"
                      ? `${value}%`
                      : formatCurrency(value, 2, 0)}
                  </div>
                </div>
                {index === 0 && <div className={classes.totalDivider} />}
              </Fragment>
            );
          })}
        </div>
      ) : (
        <div
          className={clsx(classes.totalContainer, classes.singleTotalContainer)}
        >
          <span className={classes.totalLabel}>{totals?.label}</span>
          <div className={classes.total}>
            {formatCurrency(totals?.value, 2, 0)}
          </div>
        </div>
      )}
    </Dialog>
  );
}
