import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { TableCell, TextField, InputAdornment } from "@material-ui/core";
import { CreateOutlinedIcon } from "../../../themes";
import { useStyles } from "./irrSchedule.style";
import { useOutsideClick, formatCurrency } from "../../../lib";

const EditableTableCell = ({
  index,
  amount,
  value,
  name,
  save,
  updateValue,
  updateAmount
}) => {
  const classes = useStyles();

  const ref = useRef(null);
  const [isEditing, setEditing] = useState(false);
  const [amnt, setAmount] = useState(amount);
  const [val, setValue] = useState(value);

  useEffect(() => {
    setAmount(amount);
    setValue(value);
  }, [amount, value]);

  useOutsideClick(ref, () => {
    setEditing(false);
    if (val !== value) {
      save(name, index, +parseFloat(val || 0).toFixed(3) / 100, amnt);
    }
  });

  function handleAmountChange(event) {
    const parsedValue = event.target.value.replace(/[^0-9]/g, "");
    setAmount(parsedValue);
    setValue(updateValue(parsedValue));
  }

  function handleValueChange(event) {
    let value = event.target.value;
    let regexExp = new RegExp("^\\d*(\\.\\d{0,3})?$");
    if (!value.match(regexExp)) {
      value = value.slice(0, -1);
    }
    setValue(value);
    setAmount(updateAmount(value));
  }

  return (
    <TableCell ref={ref} align="center">
      {isEditing ? (
        <div className={classes.cellEditor}>
          <TextField
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            variant="outlined"
            value={amnt}
            onChange={handleAmountChange}
            name={name}
            className="irrPopup"
            InputProps={{
              classes: {
                root: classes.editInput,
                input: clsx(classes.smallText, "irrPopup")
              },
              startAdornment: (
                <InputAdornment className={classes.smallText} position="start">
                  $
                </InputAdornment>
              )
            }}
          />
          <TextField
            inputProps={{ inputMode: "decimal", pattern: "[0-9].*" }}
            variant="outlined"
            value={val}
            onChange={handleValueChange}
            name={name}
            InputProps={{
              classes: {
                root: classes.editInput,
                input: clsx(classes.smallText, "irrPopup")
              },
              endAdornment: (
                <InputAdornment className={classes.smallText} position="end">
                  %
                </InputAdornment>
              )
            }}
          />
        </div>
      ) : (
        <div
          onClick={() => setEditing(true)}
          style={{ fontFamily: "Roboto Condensed" }}
        >
          <span className={classes.smallText}>{formatCurrency(amnt, 0)}</span>
          <CreateOutlinedIcon className={classes.editIcon} />
          <br />
          <span style={{ marginRight: 17 }}>{val + "%"}</span>
        </div>
      )}
    </TableCell>
  );
};

export default EditableTableCell;
