import * as types from "../actions/actionTypes";

const initialState = {
  ipDeals: [],
  loading: true,
  noIPDeals: false,
  contact: {},
  loadingContact: false,
  suggestions: [],
};

export default function ipDealReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_UPDATE_STAR:
      return Object.assign({}, state, { loading: true });
    case types.LOADING_IP_DEALS:
      return Object.assign({}, state, { noIPDeals: false });
    case types.LOAD_IP_DEALS_SUCCESS:
      return Object.assign({}, state, {
        ipDeals: action.ipDeals,
        suggestions: action.ipDeals,
        loading: false,
      });
    case types.NO_IP_DEALS:
      return Object.assign({}, state, {
        ipDeals: [],
        noIPDeals: true,
        loading: false,
      });
    case types.LOADING_FULL_CONTACT:
      return Object.assign({}, state, { loadingContact: true, contact: {} });
    case types.LOAD_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: action.contact,
        loadingContact: false,
      });
    case types.IP_STAR_CHANGE_COMPLETED:
      let suggestions = [...state.suggestions];
      suggestions.find(
        (i) => i.deal_id === action.deal_id
      ).broker_starred = parseInt(action.broker_starred, 10);
      return Object.assign({}, state, { suggestions, loading: false });
    case types.UPDATE_SEARCH_IP_DEALS:
      return Object.assign({}, state, { suggestions: action.suggestions });
    default:
      return state;
  }
}
