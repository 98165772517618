import React, { useEffect, useState, useRef, Fragment } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Drawer,
  IconButton,
  SwipeableDrawer,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";
import {
  useMobile,
  CloseIcon,
  LinkOutlinedIcon,
  CopyLinkIcon,
} from "../../../themes";
import { useStyles } from "./ExportEcalcModal.styles";
import {
  createPublicEcalc,
  getPublicEcalcPDF,
  clearToolbarData,
} from "../../../actions/eCalcForms";
import { Notification } from "../components";

export function ExportEcalcModal({ storeSliceName, handleOpen, handleClose }) {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const [windowMessage, setWindowMessage] = useState(null);
  const ecalcData = useSelector((state) => state[storeSliceName], shallowEqual);

  useEffect(() => {
    dispatch(createPublicEcalc({ data: ecalcData, action: "export" }));
    window.addEventListener("message", handleWindowMessage, false);
    return () => {
      window.removeEventListener("message", handleWindowMessage);
    };
  }, [dispatch, ecalcData]);

  const handleWindowMessage = (e) => {
    const data = e.data;
    if (!data || data === "undefined") {
      // This happens for messages sent by the OS, SDK, React-Native, Expo or
      // some other code outside our control. No need to log it.
      return;
    }
    setWindowMessage(data);
  };

  const close = () => {
    dispatch(clearToolbarData());
    handleClose();
  };

  return (
    <div>
      {!isMobile ? (
        <ExportEcalcDesktopModal
          open={true}
          handleClose={close}
          isMobile={isMobile}
          windowMessage={windowMessage}
        />
      ) : (
        <ExportEcalcMobileDrawer
          open={true}
          handleOpen={handleOpen}
          handleClose={close}
          isMobile={isMobile}
          windowMessage={windowMessage}
        />
      )}
    </div>
  );
}

function RenderContent({ isMobile, handleClose, windowMessage }) {
  const initialNotificationState = {
    show: false,
    message: "",
    buttonTxt: "",
    onClick: () => {},
  };
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(initialNotificationState);
  const linkRef = useRef(null);
  const loading = useSelector((state) => state.eCalcFormsNoPersist.loading);
  const publicEcalc = useSelector(
    (state) => state.eCalcFormsNoPersist.publicEcalc,
    shallowEqual
  );

  const { action, success, message, link, hash_id } = publicEcalc || {};

  useEffect(() => {
    const { action, message } = windowMessage || {};
    if (action === "copyToClipboard") {
      if (message && message.length > 0) {
        setNotification({ show: true, message });
      }
    }
  }, [windowMessage]);

  useEffect(() => {
    if (action === "export" && success === false && message.length > 0) {
      setNotification({ show: true, message });
    }
  }, [action, success, message]);

  function copyToClipboard() {
    if (window && window.isNativeApp) {
      window.canCopyUrl
        ? window.ReactNativeWebView.postMessage(
            JSON.stringify({
              action: "copyToClipboard",
              data: { link },
            })
          )
        : setNotification({
            show: true,
            message:
              "You need to download the latest version to use this feature",
          });
    } else {
      if (navigator.userAgent.match(/ipad|iphone/i)) {
        let textArea = document.createElement("textArea");
        textArea.readOnly = true;
        textArea.contentEditable = true;
        textArea.value = link;
        document.body.appendChild(textArea);
        var range = document.createRange();
        range.selectNodeContents(textArea);
        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
        document.execCommand("copy");
        document.body.removeChild(textArea);
      } else {
        linkRef.current.select();
        document.execCommand("copy");
      }
      setNotification({ show: true, message: "Copied!" });
    }
  }

  return (
    <div>
      {!loading && (
        <Fragment>
          {notification && notification.show && (
            <Notification
              open={true}
              initialNotificationState={initialNotificationState}
              message={notification.message}
              buttonTxt={notification.buttonTxt}
              isMobile={isMobile}
              maxMsgWidth={isMobile ? 228 : 158}
              style={{ position: "absolute" }}
              horizontalAnchor="center"
              onClick={notification.onClick}
              hideNotification={setNotification}
              allowAutoHide={true}
            />
          )}
          <div className={classes.copyLink}>
            <span className={classes.copyLinkMsg}>
              Copy link to a snapshot with your current calculations
            </span>
            {document.queryCommandSupported("copy") && (
              <div className={classes.copyLinkInput}>
                <LinkOutlinedIcon className={classes.linkIcon} />
                <TextField
                  inputRef={linkRef}
                  defaultValue={link}
                  className={classes.link}
                  InputProps={{
                    readOnly: true,
                    contentEditable: true,
                    disableUnderline: true,
                  }}
                />
                <IconButton
                  className={classes.copyBtn}
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  onClick={copyToClipboard}
                >
                  <CopyLinkIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div className={classes.downloadPdf}>
            <span className={classes.downloadPdfMsg}>
              Or, download a PDF file
            </span>
            <Button
              className={classes.downloadPdfBtn}
              onClick={() =>
                dispatch(getPublicEcalcPDF({ hash_id, action: "export" }))
              }
            >
              Download PDF
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
}

function ExportEcalcDesktopModal({
  open,
  handleClose,
  isMobile,
  windowMessage,
}) {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.desktopHeader}>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <div className={classes.title}>Export</div>
      </div>
      <div className={classes.scrollableContent}>
        {RenderContent({ isMobile, handleClose, windowMessage })}
      </div>
    </Drawer>
  );
}

function ExportEcalcMobileDrawer({
  open,
  handleOpen,
  handleClose,
  isMobile,
  windowMessage,
}) {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      disableSwipeToOpen
      disableDiscovery
      classes={{ paperAnchorBottom: classes.drawer }}
    >
      <div className={classes.mobileClose} />
      <div className={classes.mobileHeader}>
        <div className={classes.title}>Export</div>
      </div>
      <Divider />
      <div className={classes.scrollableContent}>
        {RenderContent({ isMobile, handleClose, windowMessage })}
      </div>
    </SwipeableDrawer>
  );
}
