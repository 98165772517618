import React, { useState } from "react";
import Row from "muicss/lib/react/row";
import { useStyles } from "./ErrorPage.style";
import { Button } from "@material-ui/core";
import ClearConfirmationModal from "./ClearConfirmationModal";
import { Navigation } from "../../lib";
import { useDispatch } from "react-redux";
import { clearEcalc } from "../../actions/eCalcForms";

export default function ErrorPage(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showClearConfirmationModal, setShowClearConfirmationModal] = useState(
    false
  );

  const confirm = () => {
    dispatch(clearEcalc());
    setShowClearConfirmationModal(false);
    Navigation.redirect(`/`);
  };
  return (
    <div>
      <Row className="not-found-first-row">
        <h3>Oops! Something went wrong please try again later.</h3>
      </Row>
      <Row className="not-found-btn-row">
        <a href="/">
          <Button className={classes.errorPageButton}>Back to Home</Button>
        </a>
        {props.showClearData !== false && (
          <Button
            className={classes.errorPageButton}
            onClick={() => setShowClearConfirmationModal(true)}
          >
            Clear Data
          </Button>
        )}
        {props.children}
      </Row>
      {showClearConfirmationModal && (
        <ClearConfirmationModal
          handleClose={() => setShowClearConfirmationModal(false)}
          confirm={confirm}
        />
      )}
    </div>
  );
}
