import { makeStyles } from "@material-ui/core";
import { overlayStyles } from "../eCalc/sharedStyles";
export const useStyles = makeStyles(theme => ({
  ...overlayStyles(theme),
  errorPageButton: {
    color: "#f5f5f5",
    backgroundColor: "#ce7e07",
    padding: "8px 15px",
    height: "auto",
    fontSize: 14,
    fontWeight: "bold",
    margin: "auto 10px"
  }
}));
