import { useSelector, shallowEqual } from "react-redux";
import { eCalcForms } from "../../../lib";

export function useEcalcResults(storeSliceName) {
  const results = useSelector(
    (state) => state[storeSliceName].resultsValues,
    shallowEqual
  );
  const purchase_price = useSelector(
    (state) => state[storeSliceName][eCalcForms.CAP_RATE].purchase_price
  );
  const noi = useSelector(
    (state) => state[storeSliceName][eCalcForms.CAP_RATE].first_year_noi
  );
  const activeCalculator = useSelector(
    (state) => state[storeSliceName].activeCalculator
  );

  const io_years = useSelector(
    (state) => state[storeSliceName][eCalcForms.MORTGAGE_AND_DSCR].io_years
  );

  const { capitalEventType } = useSelector(
    (state) => state[storeSliceName][eCalcForms.IRR_CAPITAL_EVENT]
  );

  const {
    cap_rate = {},
    equity_needed = {},
    mortgage_post_io = {},
    mortgage_during_io = {},
    balance_at_maturity = {},
    dscr_post_io = {},
    dscr_during_post_io = {},
    first_year_cash_flow = {},
    cash_on_cash_return = {},
    irr_capital_event_loan = {},
    irr = {},
    equity_multiple = {},
    debt_yield = {},
    gp_stats = {}, // from waterfall calc
    lp_stats = {}, // from waterfall calc
  } = results;

  return {
    resultsCount: Object.keys(results).length,
    activeCalculator,
    resultsValues: {
      purchase_price: {
        label: "Purchase Price",
        results: purchase_price,
        displayFormat: "dollar",
      },
      cap_rate,
      equity_needed,
      mortgage_post_io,
      mortgage_during_io,
      balance_at_maturity,
      dscr_post_io,
      dscr_during_post_io,
      first_year_cash_flow,
      cash_on_cash_return,
      irr_capital_event_loan,
      irr,
      equity_multiple,
      debt_yield,
      gp_stats,
      lp_stats,
    },
    resultsSummary: [
      {
        purchase_price: {
          summary: `Your purchase price is`,
          results: purchase_price,
          displayFormat: "dollar",
        },
        cap_rate: { summary: `Your building is at a cap rate of`, ...cap_rate },
        equity_needed: {
          summary: `The equity needed to close is`,
          ...equity_needed,
        },
        noi: {
          summary: `Your first year NOI comes out to`,
          results: noi,
          displayFormat: "dollar",
        },
        first_year_cash_flow: {
          summary: "Your first year Net Cash Flow is",
          ...first_year_cash_flow,
        },
        mortgage_during_io: {
          summary: `${
            io_years ? "During I/O, y" : "Y"
          }our monthly payment will be`,
          results:
            mortgage_during_io?.results &&
            mortgage_during_io.results.find(
              (r) => r.name.toLowerCase() === "monthly"
            ).value,
          displayFormat: "dollar",
        },
        mortgage_post_io: {
          summary: `${
            mortgage_during_io && mortgage_during_io.results
              ? "After I/O, y"
              : "Y"
          }our monthly payment will be`,
          results:
            mortgage_post_io?.results &&
            mortgage_post_io.results.find(
              (r) => r.name.toLowerCase() === "monthly"
            ).value,
          displayFormat: "dollar",
        },
        mortgage_annual_amnt: {
          summary: "Your annual payment total is",
          results:
            mortgage_post_io?.results &&
            mortgage_post_io.results.find((r) =>
              r.name.toLowerCase().includes("annual")
            ).value,
          displayFormat: "dollar",
        },
        balance_at_maturity: {
          summary: "Your balance at maturity is",
          ...balance_at_maturity,
        },
        dscr_during_post_io:
          dscr_during_post_io.results && dscr_during_post_io.results.length
            ? [
                {
                  summary: "Before I/O, the DSCR is",
                  results: dscr_during_post_io.results.find((r) =>
                    r.name.toLowerCase().includes("during")
                  ).value.toFixed(2),
                },
                {
                  summary: "After I/O, the DSCR is",
                  results: dscr_during_post_io.results.find((r) =>
                    r.name.toLowerCase().includes("post")
                  ).value.toFixed(2),
                },
              ]
            : {},
        dscr_post_io: {
          summary: "The DSCR is",
          ...dscr_post_io,
        },
        irr_capital_event_loan: {
          summary:
            capitalEventType === "refi"
              ? "Your net proceeds (shortfall) from refi is"
              : "Your net supplemental loan amount is",
          ...irr_capital_event_loan,
        },
      },
      {
        cash_on_cash_return: {
          summary: "Your first year Cash on Cash return is",
          ...cash_on_cash_return,
        },
        irr: formatIrrSummary(irr),
        debt_yield: {
          summary: "Your bank's debt yield is",
          ...debt_yield,
        },
        equity_multiple: {
          summary: "Your Equity Multiple will come out to",
          ...equity_multiple,
        },
        gp_total_contribution: {
          summary: "The sponsor (GP)'s total contribution is",
          results:
            gp_stats.results &&
            gp_stats.results.find((r) =>
              r.name.toLowerCase().includes("contribution")
            ).value,
          displayFormat: "dollar",
        },
        gp_irr: {
          summary: "Sponsor's IRR will be",
          results:
            gp_stats.results &&
            gp_stats.results.find((r) => r.name.toLowerCase().includes("irr"))
              .value,
          displayFormat: "percent",
        },
        gp_total_distribution: {
          summary: " and total distribution will be",
          results:
            gp_stats.results &&
            gp_stats.results.find((r) =>
              r.name.toLowerCase().includes("distribution")
            ).value,
          displayFormat: "dollar",
        },
        lp_total_contribution: {
          summary: "The investor (LP)'s total contribution is",
          results:
            lp_stats.results &&
            lp_stats.results.find((r) =>
              r.name.toLowerCase().includes("contribution")
            ).value,
          displayFormat: "dollar",
        },
        lp_irr: {
          summary: "Investor's IRR will be",
          results:
            lp_stats.results &&
            lp_stats.results.find((r) => r.name.toLowerCase().includes("irr"))
              .value,
          displayFormat: "percent",
        },
        lp_total_distribution: {
          summary: " and total distribution will be",
          results:
            lp_stats.results &&
            lp_stats.results.find((r) =>
              r.name.toLowerCase().includes("distribution")
            ).value,
          displayFormat: "dollar",
        },
      },
    ],
  };
}

function formatIrrSummary(irr) {
  if (!Array.isArray(irr.results)) {
    return { summary: "The estimated IRR on this deal is", ...irr };
  }
  const summaryArr = [];
  irr.results.forEach((r) => {
    const { name, value: results, displayFormat } = r;
    const isRegIRR = name.toLowerCase().includes("without");
    const summary = isRegIRR
      ? "The estimated IRR on this deal is"
      : "IRR with an additional capital event will be";
    summaryArr[isRegIRR ? 0 : 1] = { summary, results, displayFormat };
  });
  return summaryArr;
}
