import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { authMobileTheme } from "../../themes";
import Button from "muicss/lib/react/button";
import { connect } from "react-redux";
import { Notification } from "./Notification";
import * as navigationTitleActions from "../../actions/navigationTitleActions";
import * as dealsActions from "../../actions/dealsActions";
import { bindActionCreators } from "redux";
import GetQuoteForm from "../comps/GetQuoteForm";
import { getStateList } from "../../actions/contactsActions";
import * as compsActions from "../../actions/compsActions";
import Expire from "./Expire";
import ResponsiveDrawer from "./ResponsiveDrawer";
import _isEqual from "lodash.isequal";
import { MobileToolbar } from "./MobileToolbar";
import { light } from "@material-ui/core/styles/createPalette";
import UnderwritingModal from "../common/UnderwritingModal";
import {
  MyPropertiesActions,
  MyDealsActions,
  EcalcActions,
} from "./headerActions";

class Layout extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      accountShowing: false,
      feedback: false,
      scheduleofRE: false,
      mobileOpen: false,
      showVersion: false,
      tapCount: 0,
      isLoggedIn: false,
      showUnderwritingModal: false,
    };
    this.toggleAccount = this.toggleAccount.bind(this);
    this.toggleFeedback = this.toggleFeedback.bind(this);
    this.toggleScheduleOfRE = this.toggleScheduleOfRE.bind(this);
    this.navItemHover = this.navItemHover.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  componentDidMount() {
    const user = this.props.userData;
    if (user && user.is_broker) {
      this.props.dealsActions.unconfirmedDealsOverdue();
      this.props.dealsActions.unconfirmedDealsCount();
    }
    this.props.compsActions.getPropertyTypes();
    this.props.getStateList();
    this.setState({ isLoggedIn: !!this.props.userData });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_isEqual(this.props.userData, prevProps.userData)) {
      this.setState({ isLoggedIn: !!this.props.userData });
    }
  }

  toggleMobileMenu = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  closeMobileMenu = () => {
    this.setState({ mobileOpen: false });
  };

  toggleAccount() {
    this.setState({ accountShowing: !this.state.accountShowing });
  }

  toggleFeedback() {
    this.setState({ feedback: !this.state.feedback });
  }

  toggleScheduleOfRE() {
    this.setState({ scheduleofRE: !this.state.scheduleofRE });
  }

  showVersionTap = () => {
    const tapCount = this.state.tapCount + 1;
    let showVersion = false;
    if (this.state.tapCount > 7) {
      showVersion = true;
      setTimeout(() => {
        this.setState({ showVersion: false, tapCount: 0 });
      }, 20000);
    }
    this.setState({ showVersion, tapCount });
  };

  navItemHover(e) {
    let navItemTop = e.target.getBoundingClientRect().top;
    let tooltip = e.currentTarget.lastChild;
    tooltip.style.top = navItemTop + 4 + "px";
  }

  openQuoteForm(e) {
    e.preventDefault();
    this.setState({ showQuoteForm: true, showMobileMenu: false });
  }

  closeQuoteForm() {
    this.setState({ showQuoteForm: false });
  }

  openLink = (url) => {
    if (window && window.isNativeApp) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "openUrl",
          data: { url },
        })
      );
    } else {
      window.open(url);
    }
  };

  onShowUnderwritingModalClose = () => {
    this.setState({ showUnderwritingModal: false });
  };

  render() {
    const { isLoggedIn } = this.state;
    const isMobile =
      window.isNativeApp ||
      window.matchMedia("(display-mode: standalone)").matches ||
      window.innerWidth < 960
        ? true
        : false;
    const userData = this.props.userData;
    const noSidebar =
      process.env.REACT_APP_HIDE_SIDEBAR === "true" ? true : false;
    let RootComponent = React.Fragment;
    let rootProps;

    let classNames = [];
    if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i))
      classNames.push("device-ios");
    if (navigator.userAgent.match(/android/i))
      classNames.push("device-android");
    if (isMobile) {
      RootComponent = ThemeProvider;
      rootProps = { theme: authMobileTheme };
    }
    return (
      <RootComponent {...rootProps}>
        <div
          className={
            this.props.pathname.indexOf("/directory")
              ? "wrapper"
              : "wrapper directory-background-image"
          }
        >
          {this.state.showQuoteForm ? (
            <GetQuoteForm
              isOpen={this.state.showQuoteForm}
              propertyTypes={this.props.comps.propertyTypes}
              states={this.props.states}
              closeQuoteForm={this.closeQuoteForm.bind(this)}
              submitQuoteForm={this.props.compsActions.requestQuote}
            />
          ) : (
            ""
          )}

          {this.props.comps.requestQuoteToast &&
          this.props.comps.requestQuoteToast.show ? (
            <Expire
              closeToast={this.props.compsActions.closeRequestQuoteToast}
              delay={6000}
            >
              <div className="success-message">
                <p>{this.props.comps.requestQuoteToast.message}</p>
                <Button
                  variant="flat"
                  onClick={this.props.compsActions.closeRequestQuoteToast}
                >
                  {this.props.comps.requestQuoteToast.success
                    ? "Done"
                    : "Dismiss"}
                </Button>
              </div>
            </Expire>
          ) : (
            ""
          )}

          {!noSidebar && (
            <ResponsiveDrawer
              userData={userData}
              unconfirmed_deal_count={this.props.unconfirmed_deal_count}
              handleDrawerToggle={this.toggleMobileMenu}
              mobileOpen={this.state.mobileOpen}
              openLink={this.openLink}
              logout={this.props.logout}
            />
          )}
          <div className={"mui-col-xl-12 full-height"}>
            <div
              id="outer-page"
              style={
                this.props.pathname.indexOf("/qts-search")
                  ? {}
                  : {
                      marginLeft: isMobile
                        ? "auto"
                        : window.innerWidth < 1700
                        ? 224
                        : 320,
                    }
              }
              className={
                this.props.pathname.indexOf("/qts-search")
                  ? ""
                  : "qts-background-image"
              }
            >
              <div
                id={
                  localStorage.getItem("auth_token") ||
                  !this.state.topNavShowing
                    ? "user-page"
                    : "page"
                }
                className={`${noSidebar ? "no-side-bar-page" : ""}`}
              >
                {isMobile && (
                  <header className="desktop-960-hidden">
                    <div className="mobile-header-content">
                      <i
                        className="material-icons mobile-menu-toogle"
                        onClick={this.toggleMobileMenu}
                        style={{ color: "#fff" }}
                      >
                        menu
                      </i>
                      <PageTitle pageTitle={this.props.navTitle} />
                    </div>
                    <HeaderActions location={this.props.location} />
                  </header>
                )}
                <div
                  style={{
                    marginBottom: 75,
                    height: window.location.pathname.includes(
                      `/reports/view-report`
                    )
                      ? "100%"
                      : "auto",
                  }}
                >
                  {this.props.children}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMobile && <MobileToolbar theme={light} isLoggedIn={isLoggedIn} />}
        <UnderwritingModal
          open={this.state.showUnderwritingModal}
          onClose={this.onShowUnderwritingModalClose}
        />
        <Notification />
      </RootComponent>
    );
  }
}

function mapStateToProps(state) {
  const { pwDeals: { unconfirmed_deal_count } = {} } = state;
  return {
    navTitle: state.navTitle,
    states: state.contacts.stateList,
    comps: state.comps,
    unconfirmed_deal_count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(navigationTitleActions, dispatch),
    getStateList: bindActionCreators(getStateList, dispatch),
    compsActions: bindActionCreators(compsActions, dispatch),
    dealsActions: bindActionCreators(dealsActions, dispatch),
  };
}

function PageTitle({ pageTitle }) {
  let { title } = pageTitle;
  let subTitle;
  if (typeof title === "object") {
    title = pageTitle.title?.title;
    subTitle = pageTitle.title?.subTitle;
  }
  return subTitle ? (
    <div className="mobile-header-title-container">
      <span className="mobile-header-title mobile-header-title-small">
        {title}
      </span>
      <span className="mobile-header-subtitle">{subTitle}</span>
    </div>
  ) : (
    <span className="mobile-header-title">{title}</span>
  );
}

function HeaderActions({ location = {} }) {
  const { pathname /*, search */ } = location;
  if (!pathname) return null;
  const pathParts = pathname.split("/");
  switch (true) {
    case pathParts.includes("properties") && pathParts.includes("ecalc"):
      return <MyPropertiesActions pathname={pathname} isDealDetails />;
    case pathParts.includes("broker-deal") && pathParts.includes("ecalc"):
      return <MyDealsActions pathname={pathname} isDealDetails />;
    case pathParts.includes("e-calc") && pathParts.includes("calculator"):
      return <EcalcActions pathname={pathname} />;
    default:
      return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
