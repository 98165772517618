import { makeStyles } from "@material-ui/core";
import { overlayStyles } from "../sharedStyles";
export const useStyles = makeStyles((theme) => ({
  ...overlayStyles(theme),
  drawer: {
    maxHeight: "calc(100% - 64px)",
    paddingTop: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    overflowY: "hidden",
  },
  amortizationDialog: {
    borderRadius: "20px 20px 0px 0px",
  },
  mobileClose: {
    minHeight: 4,
    maxWidth: 60,
    background: "#C1C1C1",
    textAlign: "center",
    width: 60,
    margin: "0 auto",
  },
  amortizationContent: {
    paddingLeft: 27,
    paddingRight: 27,
    maxHeight: 700,
    overflowY: "auto",
  },
  amortizationTitle: {
    fontFamily: "Karla",
    fontWeight: "bold",
    fontSize: 18,
    paddingLeft: 27,
    textAlign: "left",
    paddingTop: 0,
    marginTop: 10,
    paddingBottom: 0,
    height: 38,
    lineHeight: "211.4%",
  },
  amortizationTblBody: {
    overflowY: "visible",
  },
  header: {
    "& th": {
      fontFamily: "Karla",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 13,
      color: "#00112B",
      lineHeight: "211.4%",
      paddingLeft: 2,
      paddingTop: 4,
      paddingBottom: 4,
      letterSpacing: "-0.01em",
    },
  },
  centerHeader: {
    paddingRight: 9,
    paddingLeft: 9,
    textAlign: "center",
  },
  row: {
    height: 33,
    borderBottom: "1px solid rgb(0, 17, 43, 0.1)",
    "& td": {
      fontFamily: "Roboto Condensed",
      // textAlign: "right",
      whiteSpace: "nowrap",
      color: "#00112B",
      fontSize: 12,
      lineHeight: "211.4%",
      paddingLeft: 2,
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
}));
