import { makeStyles } from "@material-ui/core";
import { overlayStyles, toolbarActionsStyles } from "../sharedStyles";

export const useStyles = makeStyles((theme) => ({
  ...overlayStyles(theme),
  ...toolbarActionsStyles(theme),
  shareInstructions: {
    fontFamily: "Karla",
    fontSize: 12,
    lineHeight: "14px",
    color: "#525252",
    opacity: 0.9,
    marginTop: 8,
  },
  chooseFileFormat: {
    display: "flex",
    flexDirection: "column",
    color: "#000000",
    fontSize: 14,
    textAlign: "center",
    alignItems: "center",
    margin: "18px auto",
  },
  fileFormatBtn: {
    display: "flex",
    flexDirection: "column",
    margin: "auto 14px",
  },
  iconButton: {
    background: "#F4F4F4",
    borderRadius: 5,
    padding: "24px 18px",
    color: "#00112B",
    marginBottom: 8,
  },
}));
