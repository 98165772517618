import {
  ECALC_LARGE_SCREEN,
  ECALC_SHOW_SIDE_RATES,
  ECALC_SHOW_SIDE_RESULTS,
} from "../../lib";
const flex = {
  display: "flex",
  alignItems: "center",
};

export const calculatorInputs = (theme) => {
  const tabletScreenSize = ECALC_SHOW_SIDE_RATES?.match(/\d+/)[0];
  const sideResultsScreenSize = ECALC_SHOW_SIDE_RESULTS?.match(/\d+/)[0];
  const maxSmallScreenForLargeInputs = 1150;
  return {
    margin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    calcContainer: {
      paddingLeft: 19,
      paddingRight: 19,
    },
    inputOverride: {
      lineHeight: 1,
    },
    input: {
      [theme.breakpoints.between(tabletScreenSize, ECALC_LARGE_SCREEN)]: {
        padding: "12.5px 0px",
      },
      [theme.breakpoints.between(
        sideResultsScreenSize,
        maxSmallScreenForLargeInputs
      )]: {
        padding: "12.5px 0px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "12.5px 0px",
      },
    },
    inputWithPadding: {
      [theme.breakpoints.between(tabletScreenSize, ECALC_LARGE_SCREEN)]: {
        padding: "12.5px 14px",
      },
      [theme.breakpoints.between(
        sideResultsScreenSize,
        maxSmallScreenForLargeInputs
      )]: {
        padding: "12.5px 14px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "12.5px 14px",
      },
    },
    noBorder: {
      $notchedOutline: {
        borderColor: "yellow",
      },
    },
    shrink: {
      transform: "translate(0px, -6px) scale(0.75)",
    },
    inputLabel: {
      fontSize: 20,
      color: "#00112B",
      lineHeight: 1,
      width: 230,
      "&.shrink": {
        transform: "translate(0px, -6px) scale(0.75)",
      },
      "&.fullInputShrink": {
        transform: "translate(-8px, -6px) scale(0.75)",
      },
    },
    inputAdornment: {
      fontFamily: "Roboto Condensed",
    },
    longLabel: {
      width: 300,
    },
    extraLongLabel: {
      width: 515,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    wrappedLabelMargin: {
      marginTop: "25px !important",
    },
    multilineLabelInput: {
      marginTop: "32px !important",
    },
    mobileMultilineLabelInput: {
      [theme.breakpoints.down("xs")]: {
        marginTop: "32px !important",
      },
    },
    oneLinerLongLabel: {
      whiteSpace: "nowrap",
    },
    lineInputsContainer: {
      ...flex,
      marginTop: 15,
      marginBottom: 15,
    },
    fullWidthInput: {
      marginTop: 15,
      marginBottom: 15,
      "&::-webkit-appearance:": "textfield",
    },
    fullWidthLabel: {
      paddingLeft: 10,
    },
    smallInput: {
      flex: 1,
      marginRight: 0,
      marginLeft: 0,
    },
    shadedValueContainer: {
      padding: "18.5px 14px",
      fontSize: 19,
      fontFamily: "Roboto Condensed",
      background: " #EFEFEF",
      borderRadius: 4,
      marginTop: 7,
      [theme.breakpoints.down("xs")]: {
        padding: "12.5px 14px",
      },
    },
    shadedFieldsLabel: {
      fontFamily: "Karla",
      fontWeight: "normal",
      fontSize: 14,
      letterSpacing: 1,
      color: "#00112B",
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
      },
    },
    shadedStartAdornment: {
      marginRight: 8,
    },
    shadedEndAdornmentContainer: {
      ...flex,
      justifyContent: "space-between",
    },
    inputsDivider: {
      flexBasis: 45,
      height: 1,
      background: "#99A0AA",
      marginTop: 20,
      [theme.breakpoints.between(tabletScreenSize, ECALC_LARGE_SCREEN)]: {
        flexBasis: 36,
      },
      [theme.breakpoints.between(
        sideResultsScreenSize,
        maxSmallScreenForLargeInputs
      )]: {
        flexBasis: 36,
      },
      [theme.breakpoints.down("xs")]: {
        flexBasis: 36,
      },
    },
    multilineLabelInputsDivider: {
      marginTop: "30px !important",
    },
    mobileMultilineLabelInputsDivider: {
      [theme.breakpoints.down("xs")]: {
        marginTop: "30px !important",
      },
    },
    shadedFieldsDivider: {
      flexBasis: 45,
      height: 1,
      background: "#EFEFEF",
      marginTop: 20,
    },
    listIcon: {
      color: "#10B8C7",
      fontSize: 25,
      cursor: "pointer",
    },
    addIcon: {
      fontSize: 25,
      color: "#10B8C7",
      cursor: "pointer",
    },
    rateIcon: {
      fontWeight: 300,
      fontSize: 20,
      color: "#10B8C7",
      marginLeft: 5,
      cursor: "pointer",
      pointerEvents: "all",
    },
    toggleLink: {
      fontWeight: "bold",
      fontSize: 15,
      textDecorationLine: "underline",
      color: "#10B8C7",
      cursor: "pointer",
      marginBottom: 10,
    },
    hurdleTitle: {
      fontWeight: "bold",
      fontSize: 13.8901,
      color: "#000000",
      borderBottom: "1px solid #000000",
    },
    toggleContainer: {
      ...flex,
      fontSize: 13,
      color: "#898989",
      textAlign: "right",
      justifyContent: "flex-end",
      paddingRight: 10,
      [theme.breakpoints.down("xs")]: {
        fontSize: 9.86741,
      },
    },
    toggle: {
      marginLeft: 5,
    },
    toggleActive: {
      color: "#10B8C7",
    },
    pointer: {
      cursor: "pointer",
    },
    helperTextWarning: {
      color: "red !important",
      marginLeft: "0 !important",
      marginRight: "0 !important",
    },
  };
};

export const overlayStyles = (theme) => ({
  dialogContent: {
    padding: "8px 16px",
    overflowY: "visible",
  },
  dialogTitle: {
    fontFamily: "Karla",
    fontWeight: "bold",
    fontSize: 18,
    color: "#00112B",
    textAlign: "center",
  },
  dialogActions: {
    padding: "35px 16px 0px 16px",
  },
  primaryButtonLabel: {
    color: "white",
    textAlign: "center",
    textTransform: "uppercase",
    letterSpacing: "0.085em",
    fontSize: 16,
    fontWeight: "bold",
  },
  primaryButtonRoot: {
    borderRadius: 5,
    width: "100%",
    height: 49,
    border: "1px solid #122035",
    backgroundColor: "#122035",
    "&:hover, &:focus": {
      backgroundColor: "#00112B",
      opacity: "0.75",
      "& span": {
        color: "#FFFFFF",
      },
    },
  },
  whiteButtonLabel: {
    color: "#00112B",
    textAlign: "center",
    textTransform: "uppercase",
    letterSpacing: "0.085em",
    fontSize: 16,
    fontWeight: "bold",
  },
  whiteButtonRoot: {
    borderRadius: 5,
    width: "100%",
    height: 49,
    border: "1px solid #122035",
    backgroundColor: "#FFFFFF",
    "&:hover, &:focus": {
      backgroundColor: "#00112B",
      opacity: "0.75",
      "& span": {
        color: "#FFFFFF",
      },
    },
  },
  closeBtn: {
    position: "absolute",
    width: 18,
    height: 18,
    left: 0,
    margin: "2px 16px",
  },
  innerDialogCloseBtn: {
    position: "absolute",
    width: 18,
    height: 18,
    right: 0,
    margin: "2px 16px",
    color: "#7B7B7B",
  },
  innerDialogTitle: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#00112B",
    // textAlign: "center"
  },
  cancelLinkBtn: {
    fontFamily: "Karla",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 16,
    textAlign: "center",
    color: "#A0A0A0",
  },
  saveLinkBtn: {
    fontFamily: "Karla",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
    color: "#10B8C7",
  },
  blueEditIcon: {
    fontSize: 19,
    color: "#10B8C7",
    margin: "0px 0px -1px 8px",
  },
  errorDialogTitle: {
    fontFamily: "Karla",
    fontStyle: "normal",
    color: "#F45252",
  },
});

export const toolbarActionsStyles = (theme) => ({
  dialog: {
    maxWidth: 315,
    minWidth: 300,
    padding: "25px 0px",
    overflowY: "hidden",
  },
  drawer: {
    maxHeight: "calc(100% - 64px)",
    padding: "20px",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    overflowY: "hidden",
  },
  scrollableContent: {
    fontFamily: "Karla",
    maxHeight: "calc(100% - 10px)",
    overflowY: "hidden",
    padding: "0px 15px",
    "&:hover": {
      overflowY: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      overflowY: "auto",
      paddingRight: 15,
      paddingLeft: 15,
      // hide scroll bar https://stackoverflow.com/a/49278385
      scrollbarWidth: "none" /* Firefox */,
      msOverflowStyle: "none" /* Internet Explorer 10+ */,
      /* WebKit (Chrome) */
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  desktopHeader: {
    marginLeft: 15,
    top: 5,
  },
  mobileHeader: {
    marginLeft: 25,
    marginBottom: 10,
    top: 5,
  },
  mobileHeaderXsText: {
    fontFamily: "Karla",
    fontSize: 12,
    color: "#525252",
  },
  closeButton: {
    position: "absolute",
    right: 10,
    color: "#00112B",
  },
  title: {
    fontFamily: "Karla",
    fontWeight: "bold",
    fontSize: 20,
    color: "#000000",
    marginTop: 12,
    [theme.breakpoints.down("xs")]: {
      marginTop: 5,
    },
  },
  mobileClose: {
    minHeight: 4,
    maxWidth: 60,
    background: "#C1C1C1",
    textAlign: "center",
    width: 60,
    margin: "0 auto",
  },
});
