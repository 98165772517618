import React from "react";
import { useStyles } from "./formComponents.style";
import { InfoOutlinedIcon } from "../../../themes";

export function MissingNoiMsg() {
  const classes = useStyles();
  return (
    <div className={classes.missingInputsMsg}>
      <InfoOutlinedIcon className={classes.missingInputsIcon} />
      <span>
        If you’d like to figure out the max bank will lend you, you can
        calculate the NOI in the first section of Eastern Union Underwriting
      </span>
    </div>
  );
}
