import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { useStyles } from "./MobileToolbar.style";
import { CalculatorIcon, TrendingUpIcon, HomeIcon, EcalcIcon } from "../../themes";
import clsx from "clsx";
import mixpanel from "mixpanel-browser";


export function MobileToolbar({ theme, isLoggedIn }) {
  const classes = useStyles({ type: theme });

  const trackNavClick = () => {
    mixpanel.track("bottom navigation clicked");
  };
  return (
    <div>
      {window.location.pathname !== "/" && (
        <Fragment>
          {isLoggedIn ? (
            <div className={classes.toolbar}>
              <NavLink
                exact
                to="/"
                className={classes.toolbarItem}
                activeClassName={classes.toolbarItemActive}
                name="home"
                onClick={() => trackNavClick()}
              >
                <HomeIcon
                  className={clsx(
                    classes.toolbarIcon,
                    window.location.pathname === "/"
                      ? classes.toolbarItemActive
                      : ""
                  )}
                />
                Home
              </NavLink>
              <NavLink
                exact
                className={classes.toolbarItem}
                activeClassName={classes.toolbarItemActive}
                to="/e-calc/calculator"
                name="eCALC"
                onClick={() => trackNavClick()}
              >
                {window.location.pathname.includes("/e-calc/") ? (
                  <EcalcIcon
                    className={clsx(
                      classes.toolbarECalcImg,
                      classes.toolbarIcon,
                      classes.activeIcon
                    )}
                  />
                ) : (
                  <EcalcIcon
                    className={clsx(
                      classes.toolbarECalcImg,
                      classes.toolbarIcon
                    )}
                  />
                )}
                eCALC
              </NavLink>
              <NavLink
                exact
                to="/rate-sheet"
                activeClassName={classes.toolbarItemActive}
                className={classes.toolbarItem}
                name="rate-sheet"
                onClick={() => trackNavClick()}
              >
                <TrendingUpIcon
                  className={clsx(
                    classes.toolbarIcon,
                    window.location.pathname.includes("/rate-sheet")
                      ? classes.toolbarItemActive
                      : ""
                  )}
                />
                Rates
              </NavLink>
            </div>
          ) : (
            <div className={classes.toolbar}>
              <NavLink
                exact
                className={classes.toolbarItem}
                activeClassName={classes.toolbarItemActive}
                to="/e-calc/calculator"
                name="eCALC"
                onClick={() => trackNavClick()}
              >
                {window.location.pathname.includes("/e-calc/") ? (
                 <EcalcIcon
                    className={ clsx(
                      classes.toolbarECalcImg,
                      classes.toolbarIcon,
                      classes.activeIcon
                    )}
                  />
                ) : (
                  <EcalcIcon
                    className={clsx(
                      classes.toolbarECalcImg,
                      classes.toolbarIcon
                    )}
                  />
                )}
                eCALC
              </NavLink>
              <NavLink
                exact
                to="/calculators/DSCRandBalance"
                className={classes.toolbarItem}
                activeClassName={classes.toolbarItemActive}
                name="mortgage-calculators"
                onClick={() => trackNavClick()}
              >
                <CalculatorIcon
                  className={clsx(
                    classes.toolbarIcon,
                    window.location.pathname.includes("/calculators/")
                      ? classes.toolbarItemActive
                      : ""
                  )}
                />
                Other Calculators
              </NavLink>
              <NavLink
                exact
                to="/rate-sheet"
                activeClassName={classes.toolbarItemActive}
                className={classes.toolbarItem}
                name="rate-sheet"
                onClick={() => trackNavClick()}
              >
                <TrendingUpIcon
                  className={clsx(
                    classes.toolbarIcon,
                    window.location.pathname.includes("/rate-sheet")
                      ? classes.toolbarItemActive
                      : ""
                  )}
                />
                Rates
              </NavLink>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}
