import React from "react";
import { Link } from "react-router-dom";
import getParameterByName from "../common/getParameterByName";

export default class ResetPasswordSuccess extends React.Component {
  render() {
    return (
      <div className="account-activation-page">
        <div className="account-activation-logo">
          <Link to="/">
            <img
              id="logo"
              src={"/images/eastern-union-white-small.png"}
              alt="Eastern Union Logo"
            />
          </Link>
        </div>
        <div className="account-activation-panel panel" id="l-login">
          <div className="success-circle-container">
            <i className="material-icons success-circle">check_circle</i>
          </div>
          <div className="text-center">
            <h3 className="congratulations">
              Congratulations, {getParameterByName("name")}
            </h3>
            <p className="reset-successful">
              your password has been reset successfully
            </p>
          </div>
          <div className="account-activation-panel-footer text-center">
            <Link
              to="/login"
              className="xs-text orange-hover accent-text-light"
            >
              Login via our online portal
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
