import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles(theme => ({
  container: {
    fontFamily: "Karla",
    fontStyle: "normal"
  },
  numbersFontFamily: {
    fontFamily: "Roboto Condensed"
  },
  table: {
    border: "2px solid #00112B",
    boxSizing: "border-box",
    width: "auto"
  },
  dialogContent: {
    marginRight: 40
  },
  dialogTitle: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#00112B"
  },
  header: {
    borderBottom: "1px solid #000000",
    "& th": {
      fontWeight: "bold",
      fontSize: 14,
      minWidth: 140,
      color: "#000000"
    }
  },
  row: {
    height: 70,
    borderBottom: "1px solid #EFEFEF",
    "& td": {
      textAlign: "right",
      whiteSpace: "nowrap",
      color: "#00112B",
      fontSize: 18,
      "&.small-text": {
        fontSize: 13
      }
    }
  },
  headerText: {
    fontWeight: "bold",
    fontSize: 14,
    color: "#000000 !important",
    lineHeight: ".8",
    textAlign: "left !important"
  },
  subHeaderText: {
    fontWeight: "normal",
    fontSize: 10
  },
  totalsRow: {
    height: 50,
    "& td": {
      // maxWidth: 140,
      color: "#00112B"
    }
  },
  totalsRowValue: {
    fontFamily: "Roboto Condensed",
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: "30px !important",
    textAlign: "left"
  },
  currentUnderwritingCell: {
    fontFamily: "Roboto Condensed",
    background: "#EFEFEF",
    fontSize: "16px !important",
    fontWeight: "bold"
  },
  disabledRow: {
    background: "rgba(239, 239, 239, 0.4)"
  },
  cellEditor: {
    fontFamily: "Roboto Condensed",
    display: "flex",
    flexDirection: "column"
  },
  editInput: {
    height: 25,
    marginTop: 5,
    input: {
      fontSize: "13px !important"
    }
  },
  closeBtn: {
    position: "absolute",
    width: 21,
    height: 21,
    right: 16,
    background: "rgba(118, 118, 128, 0.12)",
    borderRadius: 20,
    padding: 5,
    cursor: "pointer"
  },
  smallText: {
    fontSize: 13,
    fontFamily: "Roboto Condensed"
  },
  editIcon: {
    fontSize: 14,
    marginLeft: 3
  },
  metrics: {
    paddingTop: 0,
    paddingBottom: 0
  },
  metricsTitle: {
    fontFamily: 'Karla',
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'uppercase',
  },
  metricsSubTitle: {
    fontFamily: 'Karla',
    fontWeight: 'normal',
    fontSize: 11,
  },
}));
