import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "none",
  },
  valuesKey: {
    width: "100%",
    textAlign: "left",
    fontSize: 20.3037,
    color: "#00112B",
    [theme.breakpoints.down(500)]: {
      fontSize: 12,
    },
  },
  values: {
    fontFamily: "Roboto Condensed",
    fontSize: 21.9957,
    color: "#00112B",
    width: "100%",
    textAlign: "right",
    [theme.breakpoints.down(500)]: {
      fontSize: 13,
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  input: {
    border: "none",
    caretColor: "#10B8C7",
    width: "100%",
    color: "#10B8C7",
  },
  valuesInput: {
    fontFamily: "Roboto Condensed",
    fontSize: 21.9957,
    [theme.breakpoints.down(500)]: {
      fontSize: 13,
    },
  },
  keyInput: {
    fontSize: 20.3037,
    [theme.breakpoints.down(500)]: {
      fontSize: 12,
    },
  },
  valuesAdornment: {
    fontSize: 21.9957,
    marginLeft: 7,
    [theme.breakpoints.down(500)]: {
      fontSize: 13,
    },
  },
  deleteIcon: {
    cursor: "pointer",
    color: "#10B8C7",
  },
  paddingLeft: {
    paddingLeft: 10,
  },
  capitalize: {
    textTransform: "capitalize",
  },
}));
