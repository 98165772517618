import * as types from "../../actions/actionTypes";

const initialState = {
  errorToast: false,
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.SHARED_CALCULATOR_SUCCESS:
      return Object.assign({}, state, {
        errorToast: true,
        errorMessage: action.data,
      });
    case types.SHARED_CALCULATOR_ERROR:
      return Object.assign({}, state, {
        errorToast: true,
        errorMessage: action.data,
      });
    case types.CLOSE_SHARED_CALCULATOR_TOAST:
      return Object.assign({}, state, { errorToast: false, errorMessage: "" });
    default:
      return state;
  }
}
