import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { IconButton, SwipeableDrawer, Divider } from "@material-ui/core";
import { LinkOutlinedIcon, PDFIcon } from "../../../themes";
import { useStyles } from "./ShareEcalcModal.styles";
import {
  getPublicEcalcPDF,
  createPublicEcalc,
} from "../../../actions/eCalcForms";
import { Notification } from "./Notification";
import { fileFormat } from "../../../lib";

export function ShareEcalcModal({ storeSliceName, handleOpen, handleClose }) {
  const dispatch = useDispatch();
  const ecalcData = useSelector((state) => state[storeSliceName], shallowEqual);

  useEffect(() => {
    dispatch(createPublicEcalc({ data: ecalcData, action: "share" }));
  }, [dispatch, ecalcData]);

  return (
    <div>
      <ShareEcalcMobileDrawer
        open={true}
        handleOpen={handleOpen}
        handleClose={handleClose}
        isMobile={true}
      />
    </div>
  );
}

function RenderContent({ isMobile, handleClose }) {
  const initialNotificationState = {
    show: false,
    message: "",
    buttonTxt: "",
    onClick: () => {},
  };
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const [notification, setNotification] = useState(initialNotificationState);
  const loading = useSelector((state) => state.eCalcFormsNoPersist.loading);
  const publicEcalc = useSelector(
    (state) => state.eCalcFormsNoPersist.publicEcalc,
    shallowEqual
  );

  const { action, success, message, link, hash_id } = publicEcalc || {};

  useEffect(() => {
    if (action === "share" && success === false && message.length > 0) {
      setNotification({ show: true, message });
    }
  }, [action, success, message]);

  return (
    <div>
      {!loading && (
        <Fragment>
          {notification && notification.show && (
            <Notification
              open={true}
              initialNotificationState={initialNotificationState}
              message={notification.message}
              buttonTxt={notification.buttonTxt}
              isMobile={isMobile}
              maxMsgWidth={isMobile ? 228 : 158}
              style={{ position: "absolute" }}
              horizontalAnchor="center"
              onClick={notification.onClick}
              hideNotification={setNotification}
              allowAutoHide={true}
            />
          )}
          <p className={classes.shareInstructions}>
            Send a snapshot of your current calculations.
          </p>
          <div className={classes.chooseFileFormat}>
            <span>Choose File Format:</span>
            <div style={{ display: "flex", marginTop: 15 }}>
              <div
                className={classes.fileFormatBtn}
                onClick={() => {
                  dispatch({ type: "DOWNLOAD_PDF_SUCCESS" });
                  if (window && window.isNativeApp) {
                    return window.ReactNativeWebView.postMessage(
                      JSON.stringify({
                        action: "shareUrl",
                        data: {
                          url: link,
                          fileName: "",
                          format: fileFormat.vCalc,
                        },
                      })
                    );
                  } else {
                    return window.open(link, "_blank");
                  }
                }}
              >
                <IconButton className={classes.iconButton}>
                  <LinkOutlinedIcon />
                </IconButton>
                <span>vCalc</span>
              </div>
              <div
                className={classes.fileFormatBtn}
                onClick={() =>
                  dispatch(
                    getPublicEcalcPDF({
                      hash_id,
                      action: "share",
                      format: fileFormat.pdf,
                    })
                  )
                }
              >
                <IconButton className={classes.iconButton}>
                  <PDFIcon />
                </IconButton>
                <span>PDF</span>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
}

function ShareEcalcMobileDrawer({
  open,
  handleOpen,
  handleClose,
  isMobile,
  windowMessage,
}) {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      disableSwipeToOpen
      disableDiscovery
      classes={{ paperAnchorBottom: classes.drawer }}
    >
      <div className={classes.mobileClose} />
      <div className={classes.mobileHeader}>
        <div className={classes.title}>Share</div>
      </div>
      <Divider />
      <div className={classes.scrollableContent}>
        {RenderContent({ isMobile, handleClose, windowMessage })}
      </div>
    </SwipeableDrawer>
  );
}
