import React, { useEffect, Fragment } from "react";
import clsx from "clsx";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { loadNewRates } from "../../actions/rateSheetActions";
import { updateNavTitle } from "../../actions/navigationTitleActions";
import {
  Paper,
  Grid,
  Divider,
  List,
  ListItem,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useMobile } from "../../themes";
import { useStyles } from "./RatesPage.styles";

export function RatesPage() {
  const classes = useStyles();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const ecalcRates = useSelector(
    (state) => state.rates.ratesList,
    shallowEqual
  );

  useEffect(() => {
    dispatch(loadNewRates());
    dispatch(updateNavTitle("Rates"));
  }, [dispatch]);

  if (!Object.keys(ecalcRates).length) return null;

  return (
    <Paper className={classes.container} elevation={isMobile ? 0 : 1}>
      <Grid container spacing={isMobile ? 0 : 10} justify="center">
        <Grid item xs={12} sm={5}>
          <GraphedIndex resource={ecalcRates.graphed_index} />
          <PrimaryIndex resource={ecalcRates.primary_index} />
          <div className={classes.moreRates}>More Rates</div>
          <OtherIndex resource={ecalcRates.other_index} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <div
            className={clsx(classes.sectionHeader, classes.noBottomRadius)}
            style={{ marginTop: !isMobile ? 0 : undefined }}
          >
            10 yr Program
          </div>
          <Spread resource={ecalcRates.spread} />
          <div className={clsx(classes.sectionHeader, classes.noBottomRadius)}>
            FHA Indicative Rates (+mip)
          </div>
          <Fha resource={ecalcRates["FHA Indicative Rates (+mip)"]} />
          <div className={clsx(classes.sectionHeader, classes.noBottomRadius)}>
            bank rates
          </div>
          <BankRates resource={ecalcRates.bank_rate} />
          <div className={clsx(classes.sectionHeader, classes.noBottomRadius)}>
            freddie SbL
          </div>
          <Sbl resource={ecalcRates.sbl} />
        </Grid>
      </Grid>
    </Paper>
  );
}

export function GraphedIndex({ resource, isRatesModal }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.flex, classes.mobileGraphed)}>
      {Array.isArray(resource) &&
        resource.map((r, index) => {
          const { name, rate, change } = r;
          return (
            <div className={classes.flex} key={index}>
              <Divider
                orientation="vertical"
                classes={{
                  root: clsx(
                    classes.graphedBorder,
                    { [classes.graphedBorderModal]: isRatesModal },
                    classes[`${name}-border`],
                    {
                      [classes["S&P500-border"]]: name === "S&P 500",
                    }
                  ),
                }}
              />
              <div className={classes.graphedIndexContainer}>
                <span
                  className={clsx(classes.graphedIndexName, {
                    [classes.graphedIndexNameModal]: isRatesModal,
                  })}
                >
                  {name}
                </span>
                <span
                  className={clsx(classes.graphedIndexRate, {
                    [classes.graphedIndexRateModal]: isRatesModal,
                  })}
                >
                  {rate}
                </span>
                <span
                  className={clsx(
                    classes.graphedIndexChange,
                    { [classes.graphedIndexChangeModal]: isRatesModal },
                    classes[`${name}-change`],
                    { [classes["S&P500-change"]]: name === "S&P 500" }
                  )}
                >
                  {change}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function PrimaryIndex({ resource, isRatesModal }) {
  const classes = useStyles();
  return (
    <List style={{ paddingBottom: 6 }}>
      {Array.isArray(resource) &&
        resource.map((r, index) => {
          const { name, rate, change } = r;
          return (
            <Fragment key={`${index}primary`}>
              <ListItem
                disableGutters
                style={{ justifyContent: "space-between" }}
              >
                <span
                  className={clsx(classes.primaryIndexName, {
                    [classes.primaryIndexNameModal]: isRatesModal,
                  })}
                >
                  {name}
                </span>
                <div className={classes.primaryIndexChangeContainer}>
                  <span
                    className={clsx(classes.primaryIndexRate, {
                      [classes.primaryIndexRateModal]: isRatesModal,
                    })}
                  >
                    {rate}
                  </span>
                  <span
                    className={clsx(
                      classes.primaryIndexChange,
                      { [classes.primaryIndexChangeModal]: isRatesModal },
                      classes[getRateChangeClass(change)]
                    )}
                  >
                    {change}
                  </span>
                </div>
              </ListItem>
              {index !== resource.length - 1 && <Divider component="li" />}
            </Fragment>
          );
        })}
    </List>
  );
}

export function OtherIndex({ resource, isRatesModal }) {
  const classes = useStyles();
  return (
    <List style={{ paddingTop: 6 }}>
      {Array.isArray(resource) &&
        resource.map((r, index) => {
          const { name, rate, change } = r;
          return (
            <Fragment key={`${index}primary`}>
              <ListItem
                disableGutters
                style={{ justifyContent: "space-between" }}
              >
                <span
                  className={clsx(classes.primaryIndexName, {
                    [classes.primaryIndexNameModal]: isRatesModal,
                  })}
                >
                  {name}
                </span>
                <div className={classes.primaryIndexChangeContainer}>
                  <span
                    className={clsx(classes.primaryIndexRate, {
                      [classes.primaryIndexRateModal]: isRatesModal,
                    })}
                  >
                    {rate}
                  </span>
                  <span
                    className={clsx(
                      classes.primaryIndexChange,
                      { [classes.primaryIndexChangeModal]: isRatesModal },
                      classes[getRateChangeClass(change)]
                    )}
                  >
                    {change}
                  </span>
                </div>
              </ListItem>
              {index !== resource.length - 1 && <Divider component="li" />}
            </Fragment>
          );
        })}
    </List>
  );
}

function BankRates({ resource }) {
  const classes = useStyles();
  return (
    <Fragment>
      {Array.isArray(resource) &&
        resource.map((r, index) => {
          const { name, ...rates } = r;
          return (
            <Fragment key={`${index}bank`}>
              <div className={classes.name}>{name}</div>
              <List>
                {Object.keys(rates).map((rateName, i) => {
                  return (
                    <Fragment key={rateName}>
                      <ListItem
                        disableGutters
                        style={{ justifyContent: "space-between" }}
                        classes={{ root: classes.listWithPadding }}
                      >
                        <span className={classes.otherIndexName}>
                          {rateName}
                        </span>
                        <span className={classes.otherIndexRate}>
                          {rates[rateName]}
                        </span>
                      </ListItem>
                      {i !== Object.keys(rates).length - 1 && (
                        <Divider component="li" />
                      )}
                    </Fragment>
                  );
                })}
              </List>
              {index === 0 && (
                <Divider classes={{ root: classes.sectionDivider }} />
              )}
            </Fragment>
          );
        })}
    </Fragment>
  );
}

function Sbl({ resource }) {
  const classes = useStyles();
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow className={classes.tableHeadBorder}>
          <TableCell
            className={clsx(classes.multiHeaderTitle, classes.firstTableCell)}
          >
            Term
          </TableCell>
          <TableCell align="center" className={classes.multiHeaderTitle}>
            Top markets <br /> 60-80% LTV
          </TableCell>
          <TableCell
            align="center"
            className={clsx(classes.multiHeaderTitle, classes.lastTableCell)}
          >
            Average Markets <br /> 60-70% LTV
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(resource) &&
          resource.map((r, index) => {
            const { name, top, avg } = r;
            return (
              <TableRow key={`${index}sbl`} className={classes.tableBorder}>
                <TableCell
                  component="th"
                  scope="row"
                  className={clsx(
                    classes.otherIndexName,
                    classes.tableBorder,
                    classes.firstTableCell
                  )}
                >
                  {name}
                </TableCell>
                <TableCell
                  // align="center"
                  className={clsx(
                    classes.otherIndexName,
                    classes.otherIndexRate,
                    classes.textCenter
                  )}
                >
                  {top}
                </TableCell>
                <TableCell
                  align="right"
                  className={clsx(
                    classes.otherIndexName,
                    classes.otherIndexRate,
                    classes.lastTableCell
                  )}
                >
                  {avg}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
}

function Fha({ resource }) {
  const classes = useStyles();
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow className={classes.tableHeadBorder}>
          <TableCell
            className={clsx(classes.multiHeaderTitle, classes.firstTableCell)}
          >
            Multi-Family <br /> and Healthcare
          </TableCell>
          <TableCell align="center" className={classes.multiHeaderTitle}>
            Spread
          </TableCell>
          <TableCell
            align="right"
            className={clsx(classes.multiHeaderTitle, classes.lastTableCell)}
          >
            Coupon
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(resource) &&
          resource.map((r, index) => {
            const { name = "", spread, coupon } = r;
            const split = name.split("[");
            return (
              <TableRow key={`${index}sbl`} className={classes.tableBorder}>
                <TableCell
                  component="th"
                  scope="row"
                  className={clsx(
                    classes.otherIndexName,
                    classes.tableBorder,
                    classes.firstTableCell
                  )}
                >
                  <div className={classes.fhaNameContainer}>
                    <span className={classes.otherIndexName}>{split[0]}</span>
                    <span className={classes.fhaNameSubName}>
                      {`[${split[1]}`}
                    </span>
                  </div>
                </TableCell>
                <TableCell
                  align="center"
                  className={clsx(classes.otherIndexRate, classes.textCenter)}
                >
                  {spread}
                </TableCell>
                <TableCell
                  // align="right"
                  className={clsx(
                    classes.otherIndexRate,
                    classes.lastTableCell
                  )}
                >
                  {coupon}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
}

function Spread({ resource }) {
  const classes = useStyles();
  return (
    <Table aria-label="simple table">
      <TableHead>
        <TableRow className={classes.tableHeadBorder}>
          <TableCell
            className={clsx(classes.multiHeaderTitle, classes.firstTableCell)}
          >
            10 Year Program
          </TableCell>
          <TableCell align="center" className={classes.multiHeaderTitle}>
            Spread
          </TableCell>
          <TableCell
            align="right"
            className={clsx(classes.multiHeaderTitle, classes.lastTableCell)}
          >
            Coupon
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Array.isArray(resource) &&
          resource.map((r, index) => {
            const {
              name,
              spread,
              coupon,
              floor,
              // floorApplied,
              baseRateName,
            } = r;
            return (
              <Fragment key={`${index}sbl`}>
                <TableRow
                // className={clsx({ [classes.tableBorder]: !floorApplied })}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={clsx(
                      classes.otherIndexName,
                      classes.firstTableCell
                    )}
                  >
                    {name}
                  </TableCell>
                  <TableCell
                    align="center"
                    className={clsx(classes.otherIndexRate, classes.textCenter)}
                  >
                    {spread}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={clsx(
                      classes.otherIndexRate,
                      classes.lastTableCell
                    )}
                  >
                    {coupon}
                  </TableCell>
                </TableRow>
                {/* {!!floorApplied && ( */}
                <TableRow className={classes.tableBorder}>
                  <TableCell
                    size="small"
                    colSpan="3"
                    className={classes.floorMsg}
                  >
                    {`Important Note: The Coupon rate is based on the
                      ${baseRateName} + the Spread with a Floor of ${floor}. Please
                      factor this equation into account when calculating your
                      specific loan requirements.`}
                  </TableCell>
                </TableRow>
                {/* )} */}
              </Fragment>
            );
          })}
      </TableBody>
    </Table>
  );
}

function getRateChangeClass(rateChange) {
  const isPositive = Math.sign(parseFloat(rateChange)) > -1;
  return isPositive ? "rateChangePositive" : "rateChangeNegative";
}
