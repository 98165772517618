import React from "react";
import Modal from "react-modal";
import Form from "muicss/lib/react/form";
import Textarea from "muicss/lib/react/textarea";
import Button from "muicss/lib/react/button";

class NoteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      note: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ note: this.props.note || "" });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.note !== nextProps.note) {
      this.setState({ note: nextProps.note || "" });
    }
  }

  onChange(e) {
    this.setState({ note: e.target.value });
  }

  clearNote() {
    this.setState({ note: "" });
  }

  cancelClick(e) {
    this.props.onRequestClose(e);
    this.clearNote();
  }

  submitClick(e) {
    this.props.onSubmit(e, this.state.note);
    this.clearNote();
  }

  render() {
    const customStyles = {
      content: {
        margin: "auto",
      },
    };

    return (
      <Modal
        className="note-modal"
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.onAfterOpen}
        onRequestClose={this.props.onRequestClose}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Modal"
        shouldCloseOnOverlayClick={true}
      >
        <Form className="modal-form">
          <p className="add-note-modal">
            {this.props.text.title || "Add Note"}
          </p>
          <Textarea
            rows={2}
            value={this.state.note}
            onChange={(e) => this.onChange(e)}
            className="add-note-textarea"
          />
          <div className="add-note-buttons">
            <Button variant="flat" onClick={(e) => this.cancelClick(e)}>
              cancel
            </Button>
            <Button
              variant="flat"
              onClick={(e) => this.submitClick(e)}
              disabled={!this.state.note.length && this.props.require}
              type="submit"
              value="submit"
            >
              {this.props.text.button || "Save"}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}

export default NoteModal;
