import React from "react";
import Row from "muicss/lib/react/row";
import Button from "muicss/lib/react/button";

class PageNotFound404 extends React.Component {
  render() {
    return (
      <div>
        <Row className="not-found-first-row">
          <h1>404</h1>
          <p>Page was not found. Please continue to our home page.</p>
        </Row>
        <Row className="not-found-btn-row">
          <a href="/">
            <Button variant="flat" className="home-button">
              Back to Home
            </Button>
          </a>
        </Row>
      </div>
    );
  }
}
export default PageNotFound404;
