import * as types from "../actionTypes";

export function updateSimpleUnderwriting(values) {
  return { type: types.UPDATE_SIMPLE_UNDERWRITING, values };
}

export function exportSimpleUnderwriting() {
  return { type: types.EXPORT_SIMPLE_UNDERWRITING };
}

export function calculateSimpleUnderwriting(values, trigger) {
  return function (dispatch) {
    calculate(values, trigger);
    dispatch(updateSimpleUnderwriting(values));
  };
}

function calculate(state, trigger) {
  trigger.value.length > 0
    ? (trigger.value = parseFloat(trigger.value))
    : (trigger.value = 0);
  if (trigger.name === "annualGrossRent") {
    state.annualGrossRent = trigger.value;
    state.monthlyGrossRent = trigger.value / 12;
    performCalculationGrossRentChange(state);
  } else if (trigger.name === "monthlyGrossRent") {
    state.monthlyGrossRent = trigger.value;
    state.annualGrossRent = trigger.value * 12;
    performCalculationGrossRentChange(state);
  } else if (trigger.name === "vacancyFactorPercent") {
    if (state.annualGrossRent) {
      state.vacancyFactorPercent = trigger.value;
      state.vacancyFactorAmount =
        state.annualGrossRent * (state.vacancyFactorPercent / 100);
      performCalculationVacancyFactorChange(state);
    }
  } else if (trigger.name === "vacancyFactorAmount") {
    if (state.annualGrossRent) {
      state.vacancyFactorAmount = trigger.value;
      state.vacancyFactorPercent = parseFloat(
        (state.vacancyFactorAmount * 100) / state.annualGrossRent
      ).toFixed(3);
      performCalculationVacancyFactorChange(state);
    }
  } else if (trigger.name === "managementFeesPercent") {
    if (state.annualGrossRent) {
      state.managementFeesPercent = trigger.value;
      state.managementFeesAmount =
        (state.annualGrossRent - state.vacancyFactorAmount) *
        (state.managementFeesPercent / 100);
      performCalculationManagementFeesChange(state);
    }
  } else if (trigger.name === "managementFeesAmount") {
    if (state.annualGrossRent) {
      state.managementFeesAmount = trigger.value;
      state.managementFeesPercent = parseFloat(
        (state.managementFeesAmount * 100) /
          (state.annualGrossRent - state.vacancyFactorAmount)
      ).toFixed(3);
      performCalculationManagementFeesChange(state);
    }
  } else if (trigger.name === "annualExpenses") {
    state.annualExpenses = trigger.value;
    state.monthlyExpenses = trigger.value / 12;
    performCalculationExpensesChange(state);
  } else if (trigger.name === "monthlyExpenses") {
    state.monthlyExpenses = trigger.value;
    state.annualExpenses = trigger.value * 12;
    performCalculationExpensesChange(state);
  } else if (trigger.name === "capRate") {
    state.capRate = trigger.value;
    performCalculationCapRateChange(state);
  }
  return state;
}

function performCalculationGrossRentChange(state) {
  if (state.annualGrossRent && state.monthlyGrossRent) {
    if (state.vacancyFactorPercent) {
      state.vacancyFactorAmount =
        state.annualGrossRent * (state.vacancyFactorPercent / 100);
    } else {
      state.vacancyFactorAmount = 0;
    }
    if (state.managementFeesPercent) {
      state.managementFeesAmount =
        (state.annualGrossRent - state.vacancyFactorAmount) *
        (state.managementFeesPercent / 100);
    } else {
      state.managementFeesAmount = 0;
    }

    state.firstYearNOI =
      state.annualGrossRent -
      state.vacancyFactorAmount -
      state.managementFeesAmount -
      state.annualExpenses;
    if (state.firstYearNOI && state.capRate) {
      state.value = (state.firstYearNOI / state.capRate) * 100;
    } else {
      state.value = 0;
    }
  } else {
    state.firstYearNOI = 0;
    state.value = 0;
    state.vacancyFactorAmount = 0;
    state.managementFeesAmount = 0;
  }
}

function performCalculationVacancyFactorChange(state) {
  if (state.managementFeesPercent) {
    state.managementFeesAmount =
      (state.annualGrossRent - state.vacancyFactorAmount) *
      (state.managementFeesPercent / 100);
  }
  state.firstYearNOI =
    state.annualGrossRent -
    state.vacancyFactorAmount -
    state.managementFeesAmount -
    state.annualExpenses;
  if (state.firstYearNOI && state.capRate) {
    state.value = (state.firstYearNOI / state.capRate) * 100;
  } else {
    state.value = 0;
  }
}

function performCalculationManagementFeesChange(state) {
  state.firstYearNOI =
    state.annualGrossRent -
    state.vacancyFactorAmount -
    state.managementFeesAmount -
    state.annualExpenses;
  if (state.firstYearNOI && state.capRate) {
    state.value = (state.firstYearNOI / state.capRate) * 100;
  } else {
    state.value = 0;
  }
}

function performCalculationExpensesChange(state) {
  if (state.annualGrossRent) {
    state.firstYearNOI =
      state.annualGrossRent -
      state.vacancyFactorAmount -
      state.managementFeesAmount -
      state.annualExpenses;
    if (state.firstYearNOI && state.capRate) {
      state.value = (state.firstYearNOI / state.capRate) * 100;
    } else {
      state.value = 0;
    }
  }
}

function performCalculationCapRateChange(state) {
  if (state.firstYearNOI && state.capRate) {
    state.value = (state.firstYearNOI / state.capRate) * 100;
  } else {
    state.value = 0;
  }
}
