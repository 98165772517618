import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { parse } from "query-string";
import * as navigationTitleActions from "../../actions/navigationTitleActions";
import * as dealDetailsActions from "../../actions/dealDetailsActions";
import Row from "muicss/lib/react/row";
import getParameterByName from "../common/getParameterByName";

class DealDetailsContainer extends React.Component {
  state = {
    dealDetails: {},
  };

  async componentDidMount() {
    const dealId = +this.props.match.params.dealId;
    let dealDetails = this.props.pwDeals.filter(
      (deal) => deal.deal_id === dealId
    )[0];
    if (!dealDetails) {
      await this.props.dealDetailsActions.getDealById(dealId);
      dealDetails = this.props.deal;
    }
    this.props.navActions.updateNavTitle({
      title: "My Deals",
      subTitle: dealDetails?.deal_name,
    });
    this.setState({ dealDetails });
  }

  // Remove if not needed
  get accessedFrom() {
    const { "accessed-from": from } = parse(this.props.location.search);
    return from;
  }

  render() {
    let violations = getParameterByName("violations");
    let dealType = this.props.match.params.dealType;
    return (
      <div>
        <Row className="sub-header">
          <Link
            className="back-arrow"
            to={
              decodeURIComponent(getParameterByName("previous_page")) ||
              `/broker-deals/${dealType.toLowerCase()}`
            }
          >
            <img src="/images/icons/arrow-left.png" alt="arrow-left" />
          </Link>
          <ul className="deal-details-nav">
            <li className="deal-details-nav-item">
              <NavLink
                to={{
                  pathname: `/broker-deal/${dealType}/${this.props.match.params.dealId}/property-details`,
                  search: window.location.search,
                }}
                activeClassName="active-deal-nav-item"
              >
                Property Details
              </NavLink>
            </li>
            {dealType === "PW" ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/broker-deal/${dealType}/${this.props.match.params.dealId}/bank-quotes`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Bank Quotes
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {dealType === "IP" ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/broker-deal/${dealType}/${this.props.match.params.dealId}/status-summary`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Status Summary
                </NavLink>
              </li>
            ) : (
              ""
            )}
            <li className="deal-details-nav-item">
              <NavLink
                to={{
                  pathname: `/broker-deal/${dealType}/${this.props.match.params.dealId}/notes`,
                  search: window.location.search,
                }}
                activeClassName="active-deal-nav-item"
              >
                Notes
              </NavLink>
            </li>
            {dealType === "PW" ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/broker-deal/${dealType}/${this.props.match.params.dealId}/write-up`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Write Up
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {(dealType === "FPW" || dealType === "PW" || dealType === "IP" || dealType === "UW") && (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/broker-deal/${dealType}/${this.props.match.params.dealId}/property-docs`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Documents
                </NavLink>
              </li>
            )}
            {/* {this.accessedFrom === "my-deals" && ( */}
            <li className="deal-details-nav-item">
              <NavLink
                to={{
                  pathname: `/broker-deal/${dealType}/${this.props.match.params.dealId}/ecalc`,
                  search: window.location.search,
                }}
                activeClassName="active-deal-nav-item"
              >
                eCALC
              </NavLink>
            </li>
            {/* )} */}
            {violations >= 0 ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/broker-deal/${dealType}/${this.props.match.params.dealId}/violations`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Violations
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
        </Row>
        <div className="deal-padding-top">{this.props.children}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { pwDeals: { pwDeals = [] } = {}, dealDetails: { deal } = {} } = state;
  return {
    pwDeals,
    deal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(navigationTitleActions, dispatch),
    dealDetailsActions: bindActionCreators(dealDetailsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealDetailsContainer);
