import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { parse } from "query-string";
import * as navigationTitleActions from "../../actions/navigationTitleActions";
import * as dealDetailsActions from "../../actions/dealDetailsActions";
import Row from "muicss/lib/react/row";
import getParameterByName from "../common/getParameterByName";

class DealDetailsContainer extends React.Component {
  state = {
    dealDetails: {},
  };

  async componentDidMount() {
    const dealId = +this.props.match.params.dealId;
    let dealDetails = this.props.myProperties.filter(
      (deal) => deal.deal_id === dealId
    )[0];
    if (!dealDetails) {
      await this.props.dealDetailsActions.getDealById(dealId);
      dealDetails = this.props.deal;
    }
    this.props.navActions.updateNavTitle({
      title: "My Properties",
      subTitle: dealDetails?.deal_name,
    });
    this.setState({ dealDetails });
  }

  // Remove if not needed
  get accessedFrom() {
    const { "accessed-from": from } = parse(this.props.location.search);
    return from;
  }

  render() {
    let dealType = this.props.match.params.dealType;
    let isEquity = getParameterByName("isEquity");
    let violations = getParameterByName("violations");
    return (
      <div>
        <Row className="sub-header">
          <Link
            className="back-arrow"
            to={decodeURIComponent(getParameterByName("previous_page"))}
          >
            <img src="/images/icons/arrow-left.png" alt="arrow-left" />
          </Link>
          <ul className="deal-details-nav">
            {this.props.match.params.dealId ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/properties/${dealType}/${this.props.match.params.dealId}/property-details`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Property Details
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {isEquity === "true" ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/properties/${dealType}/${this.props.match.params.dealId}/fund-quotes`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Fund Quotes
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {dealType === "pw" && isEquity === "false" ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/properties/${dealType}/${this.props.match.params.dealId}/bank-quotes`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Bank Quotes
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {dealType === "ip" ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/properties/${dealType}/${this.props.match.params.dealId}/status-summary`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Status Summary
                </NavLink>
              </li>
            ) : (
              ""
            )}
            <li className="deal-details-nav-item">
              <NavLink
                to={{
                  pathname: `/properties/${dealType}/${this.props.match.params.dealId}/property-docs`,
                  search: window.location.search,
                }}
                activeClassName="active-deal-nav-item"
              >
                Property Docs
              </NavLink>
            </li>
            {/* {this.accessedFrom === "my-properties" && ( */}
            <li className="deal-details-nav-item">
              <NavLink
                to={{
                  pathname: `/properties/${dealType}/${this.props.match.params.dealId}/ecalc`,
                  search: window.location.search,
                }}
                activeClassName="active-deal-nav-item"
              >
                eCALC
              </NavLink>
            </li>
            {/* )} */}
            {dealType !== "rp" ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/properties/${dealType}/${this.props.match.params.dealId}/deal-contacts`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Deal Contacts
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {violations && violations >= 0 ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/properties/${dealType}/${this.props.match.params.dealId}/violations`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Violations
                </NavLink>
              </li>
            ) : (
              ""
            )}
            <li className="deal-details-nav-item">
              <NavLink
                to={{
                  pathname: `/properties/${dealType}/${this.props.match.params.dealId}/personal-docs`,
                  search: window.location.search,
                }}
                activeClassName="active-deal-nav-item"
              >
                Personal Docs
              </NavLink>
            </li>
            <li className="deal-details-nav-item">
              <NavLink
                to={{
                  pathname: `/properties/${dealType}/${this.props.match.params.dealId}/personal-contacts`,
                  search: window.location.search,
                }}
                activeClassName="active-deal-nav-item"
              >
                Personal Contacts
              </NavLink>
            </li>
          </ul>
        </Row>
        <div className="deal-padding-top">{this.props.children}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    properties: { properties: { mine = [] } = {} } = {},
    dealDetails: { deal } = {},
  } = state;
  return {
    myProperties: mine,
    deal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(navigationTitleActions, dispatch),
    dealDetailsActions: bindActionCreators(dealDetailsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealDetailsContainer);
