import FormattedDisplay from "./FormattedDisplay";

class DollarDisplayWithoutDecimal extends FormattedDisplay {
  formatValue(value) {
    if (isNaN(value)) {
      return "Invalid Entry";
    }
    let roundedValue = value !== 0 ? "$" + parseFloat(value).toFixed(0) : "$0"; //add $ and rounds
    let formattedValue = roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //format with commas
    return formattedValue;
  }
}

export default DollarDisplayWithoutDecimal;
