import FormattedDisplay from "./FormattedDisplay";

class PercentDisplay extends FormattedDisplay {
  formatValue(value) {
    if (isNaN(value)) {
      return "Invalid Entry";
    }
    let roundedValue = value ? parseFloat(value).toFixed(2) + "%" : "0%"; //add % and round
    let formattedValue = roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //format with commas
    return formattedValue;
  }
}

export default PercentDisplay;
