import * as types from "./actionTypes";
import config from "../config";
import { tokenExpired } from "./authenticationActions";
import { ajax } from "../lib";
import { getListByType, handleAPIError } from "../lib";
import { TypesList } from "../lib";

export function loadViolations(dealId) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_VIOLATIONS });
    ajax({
      url: `${config.BASE_URL}deal-violation`,
      applyAuthHeader: true,
      data: { deal_id: dealId },
      success: function (response) {
        if (response.data && response.data.length >= 1) {
          dispatch({ type: types.LOAD_VIOLATIONS, violations: response.data });
        } else {
          dispatch({ type: types.NO_VIOLATIONS });
        }
      },
    });
  };
}

export function loadRPViolations(property_id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_VIOLATIONS });
    ajax({
      url: `${config.BASE_URL}property-violation`,
      applyAuthHeader: true,
      data: { property_id: property_id },
      success: function (response) {
        if (response.data && response.data.length >= 1) {
          dispatch({ type: types.LOAD_VIOLATIONS, violations: response.data });
        } else {
          dispatch({ type: types.NO_VIOLATIONS });
        }
      },
    });
  };
}

export function loadStatusSummary(dealId) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_STATUS_SUMMARY });
    ajax({
      url: `${config.BASE_URL}ip-todos`,
      applyAuthHeader: true,
      data: { id: dealId },
      success: function (response) {
        if (response.data && response.data.length >= 1) {
          dispatch({ type: types.LOAD_STATUS_SUMMARY, status: response.data });
        } else {
          dispatch({ type: types.NO_STATUS_SUMMARY });
        }
      },
    });
  };
}

export function loadBankQuotes(dealId) {
  return (dispatch) => {
    // dispatch({type: types.LOADING_BANK_QUOTES});
    ajax({
      url: `${config.BASE_URL}bank-quotes`,
      applyAuthHeader: true,
      data: { id: dealId },
      success: function (response) {
        if (response.data && response.data.length >= 1) {
          dispatch({ type: types.LOAD_BANK_QUOTES, bankQuotes: response.data });
        } else {
          dispatch({ type: types.NO_BANK_QUOTES });
        }
      },
    });
  };
}

export function loadPropertyDetails(dealId) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_PROPERTY_DETAILS });
    ajax({
      url: `${config.BASE_URL}deal-properties/${dealId}`,
      applyAuthHeader: true,
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          case "200":
            dispatch({
              type: types.LOAD_PROPERTY_DETAILS,
              data: response.data,
            });
            break;
          default:
            dispatch({ type: types.LOAD_PROPERTY_DETAILS, data: [] });
            break;
        }
      },
    });
  };
}

export function loadDealContacts(dealId) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_DEAL_CONTACTS });
    ajax({
      url: `${config.BASE_URL}deal-contacts`,
      applyAuthHeader: true,
      data: { deal_id: dealId },
      success: function (response) {
        if (response.data && response.data.length >= 1) {
          dispatch({ type: types.LOAD_DEAL_CONTACTS, contacts: response.data });
        } else {
          dispatch({ type: types.NO_DEAL_CONTACTS });
        }
      },
    });
  };
}

export function getFullContactInfo(Id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_FULL_CONTACT });
    ajax({
      url: `${config.BASE_URL}contact/${Id}`,
      applyAuthHeader: true,
      success: function (response) {
        if (response.data && response.data.length >= 1) {
          dispatch({
            type: types.LOAD_FULL_CONTACT,
            contact: response.data[0].contacts[0],
          });
        } else {
          dispatch({ type: types.NO_FULL_CONTACT });
        }
      },
    });
  };
}

export function loadDealDocuments(Id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_DEAL_DOCUMENTS });
    ajax({
      url: `${config.BASE_URL}property-document`,
      applyAuthHeader: true,
      data: { id: Id },
      success: function (response) {
        if (response.data && response.data.length >= 1) {
          dispatch({
            type: types.LOAD_DEAL_DOCUMENTS,
            documents: response.data,
          });
        } else {
          dispatch({ type: types.NO_DEAL_DOCUMENTS });
        }
      },
    });
  };
}

export function loadWriteUp(deal_id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_WRITE_UP });
    ajax({
      url: `${config.BASE_URL}deal-writeup`,
      applyAuthHeader: true,
      data: { deal_id },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else if (response.status === "200") {
          if (response.data.deal_write_up != null) {
            dispatch({ type: types.LOAD_WRITE_UP, data: response.data });
          } else {
            dispatch({ type: types.NO_WRITE_UP });
          }
        } else if (response.status === "204" || response.status === "E107") {
          dispatch({ type: types.NO_WRITE_UP });
        }
      },
    });
  };
}

//deal details pw quote pass irrelevant and snooze functionality

export function passDeal(quote_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}missing-quotes/pass-deal`,
      applyAuthHeader: true,
      type: "POST",
      data: { quote_id, note },
      success: function (response) {
        if (response.status === "E408") {
          dispatch({
            type: types.ERROR_IP_DD_QUOTE,
            message: response.message,
          });
        }
        if (response.status === "E107") {
          dispatch({
            type: types.ERROR_IP_DD_QUOTE,
            message: response.message,
          });
        }
        if (response.status === "E404") {
          dispatch(tokenExpired());
        }
        if (response.isResponse) {
          dispatch({
            type: types.PASS_IP_DD_QUOTE_SUCCESS,
            quote_id: quote_id,
            message: response.message,
            note,
          });
        }
      },
    });
  };
}

export function snoozeDeal(quote_id, snooze_date) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}snooze-quote`,
      applyAuthHeader: true,
      type: "POST",
      data: { quote_id, snooze_date },
      success: function (response) {
        if (response.status === "E408") {
          dispatch({
            type: types.ERROR_IP_DD_QUOTE,
            message: response.message,
          });
        }
        if (response.status === "E107") {
          dispatch({
            type: types.ERROR_IP_DD_QUOTE,
            message: response.message,
          });
        }
        if (response.status === "E404") {
          dispatch(tokenExpired());
        }
        if (response.isResponse) {
          dispatch({
            type: types.SNOOZE_IP_DD_QUOTE,
            message: response.message,
            quote_id: quote_id,
            snooze_date: snooze_date,
          });
        }
      },
    });
  };
}

export function irrelevantDeal(quote_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}quote-irrelevant`,
      applyAuthHeader: true,
      type: "POST",
      data: { quote_id, note },
      success: function (response) {
        if (response.status === "E408") {
          dispatch({
            type: types.ERROR_IP_DD_QUOTE,
            message: response.message,
          });
        }
        if (response.status === "E107") {
          dispatch({
            type: types.ERROR_IP_DD_QUOTE,
            message: response.message,
          });
        }
        if (response.status === "E404") {
          dispatch(tokenExpired());
        }
        if (response.isResponse) {
          dispatch({
            type: types.IRRELEVANT_IP_DD_QUOTE,
            message: response.message,
            quote_id: quote_id,
            note,
          });
        }
      },
    });
  };
}

export function closeUpdateIPDD() {
  return { type: types.CLOSE_IP_DD_QUOTE };
}

export function loadAllDealNotes(deal_id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_DEAL_NOTES });
    ajax({
      url: `${config.BASE_URL}all-notes`,
      applyAuthHeader: true,
      data: { deal_id },
      type: "GET",
      success: (response) => {
        switch (response.status) {
          case "200":
            dispatch({ type: types.LOAD_DEAL_NOTES, dealNotes: response.data });
            break;
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({ type: types.NO_DEAL_NOTES });
            break;
        }
      },
    });
  };
}

export function getNoteById(notes_id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_NOTE_BY_ID });
    ajax({
      url: `${config.BASE_URL}single-note`,
      applyAuthHeader: true,
      data: { notes_id },
      success: function (response) {
        if (response.status === `E404`) {
          dispatch(tokenExpired());
        }
        if (response.status === "204") {
          dispatch({ type: types.NO_NOTE_BY_ID });
        } else {
          dispatch({ type: types.LOAD_NOTE_BY_ID, note: response.data });
        }
      },
    });
  };
}

export function getDealById(deal_id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_DEAL_BY_ID });
    return ajax({
      url: `${config.BASE_URL}single-deal`,
      applyAuthHeader: true,
      data: { deal_id },
      success: (response) => {
        switch (response.status) {
          case "200":
            dispatch({ type: types.LOAD_DEAL_BY_ID, deal: response.data });
            break;
          default:
            dispatch({ type: types.NO_DEAL_BY_ID });
            break;
        }
      },
    });
  };
}

export function getBrokersByDeal(deal_id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_DEAL_BY_ID });
    ajax({
      url: `${config.BASE_URL}get-brokers`,
      applyAuthHeader: true,
      data: { deal_id },
      success: (response) => {
        switch (response.status) {
          case "200":
            dispatch({
              type: types.GET_BROKERS_BY_DEAL,
              brokers: response.data,
            });
            break;
          default:
            dispatch({ type: types.GET_BROKERS_BY_DEAL, brokers: [] });
            break;
        }
      },
    });
  };
}

export function assignNote(notes_id, broker_id) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}assign-note`,
      applyAuthHeader: true,
      data: { notes_id, broker_id },
      type: "POST",
      success: (response) => {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          case "206":
            dispatch({
              type: types.ASSIGN_NOTE,
              success: true,
              message: response.message,
            });
            break;
          default:
            dispatch({
              type: types.ASSIGN_NOTE,
              success: false,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function markNoteAsDone(notes_id, deal_id) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}note-done`,
      applyAuthHeader: true,
      data: { notes_id },
      type: "POST",
      success: (response) => {
        dispatch(addOrUpdateNoteSuccess(response));
        dispatch(loadAllDealNotes(deal_id));
      },
    });
  };
}

export function deleteDealNote(notes_id, deal_id) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}delete-note`,
      applyAuthHeader: true,
      data: { notes_id },
      type: "POST",
      success: function (response) {
        dispatch(addOrUpdateNoteSuccess(response));
        dispatch(loadAllDealNotes(deal_id));
      },
    });
  };
}

export function addOrUpdateNoteDueDate(notes_id, due_date) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}note-duedate`,
      applyAuthHeader: true,
      data: { notes_id, due_date },
      type: "POST",
      success: (response) => {
        dispatch(addOrUpdateNoteSuccess(response));
      },
    });
  };
}

export function addOrUpdateNote(deal_id, notes_id, note, due_date, broker_id) {
  let data = { deal_id, notes_id, note, due_date, broker_id };
  if (!due_date) {
    delete data.due_date;
  }
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}deal-note`,
      applyAuthHeader: true,
      type: "POST",
      data,
      success: (response) => {
        dispatch(loadAllDealNotes(deal_id));
        dispatch(addOrUpdateNoteSuccess(response));
      },
    });
  };
}

function addOrUpdateNoteSuccess(response) {
  switch (response.status) {
    case "E403":
      return tokenExpired();

    case "E107":
    case "E409":
      return {
        type: types.ADD_UPDATE_DEAL_NOTE_FAILED,
        message: response.message,
      };

    default:
      return {
        type: types.ADD_UPDATE_DEAL_NOTE_SUCCESS,
        message: response.message,
      };
  }
}

export function closeDealNoteSuccessToast() {
  return { type: types.CLOSE_DEAL_NOTE_SUCCESS_TOAST };
}

export function closeDealNoteErrorToast() {
  return { type: types.CLOSE_DEAL_NOTE_FAILED_TOAST };
}

export function updateNoteReviewed(deal_id) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}update-deal-reviewed`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        deal_id: deal_id,
        notes: "updated",
      },
      success: function (response) {
        //dispatch(updateDealReviewedCompleted(deal_id, token));
      },
    });
  };
}

//Fund Quotes

export function loadFundQuotes(deal_id, page) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_FUND_QUOTES });
    ajax({
      url: `${config.BASE_URL}deals/${deal_id}/equity-quotes`,
      applyAuthHeader: true,
      data: { page },
      success: function (response) {
        dispatch({
          type: types.GET_FUND_QUOTES,
          data: response.data,
          nextPageUrl: response.next_page_url,
        });
      },
      error: handleAPIError(dispatch, types.GET_FUND_QUOTES_FAILED),
    });
  };
}

export function getEquityQuoteStatusList() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.EquityQuoteStatus);
    dispatch({ type: types.GET_EQUITY_QUOTE_STATUS_LIST, data });
  };
}

export function getWaterfallTypesList() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.QuoteWaterfallType);
    dispatch({ type: types.GET_WATERFALL_TYPE_LIST, data });
  };
}

export function clearNote() {
  return { type: types.CLEAR_NOTE };
}

export function showToast(success, message) {
  return { type: types.SHOW_DEAL_DETAILS_TOAST, success, message };
}

export function closeToast() {
  return { type: types.CLOSE_DEAL_DETAILS_TOAST };
}
