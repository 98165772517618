import { makeStyles } from "@material-ui/core";
import { overlayStyles, toolbarActionsStyles } from "../sharedStyles";

export const useStyles = makeStyles((theme) => ({
  ...overlayStyles(theme),
  ...toolbarActionsStyles(theme),
  copyLink: {
    marginTop: (props) => (props.isMobile ? 23 : 46),
  },
  copyLinkMsg: {
    fontSize: (props) => (props.isMobile ? 12 : 11.5),
    lineHeight: (props) => (props.isMobile ? "14px" : "13px"),
    color: "#525252",
    opacity: 0.9,
    marginBottom: 5,
  },
  copyLinkInput: {
    background: "#F4F4F4",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    height: 47,
  },
  link: {
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    width: "100%",
    "& input": {
      color: "#000000",
      fontSize: (props) => (props.isMobile ? 14 : 13),
    },
  },
  linkIcon: {
    margin: "auto 10px",
  },
  copyBtn: {
    marginLeft: 5,
    background: "#00112B",
    borderRadius: "0px 4px 4px 0px",
    color: "#F4F4F4",
  },
  downloadPdf: {
    display: "flex",
    flexDirection: "column",
    marginTop: (props) => (props.isMobile ? 36 : 42),
  },
  downloadPdfMsg: {
    fontSize: 12,
    lineHeight: "14px",
    color: "#525252",
    marginBottom: 5,
  },
  downloadPdfBtn: {
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
    letterSpacing: "0.085em",
    textTransform: "uppercase",
    color: "#FFFFFF",
    background: "#00112B",
    borderRadius: 5,
    padding: "15px 37px",
  },
}));
