import * as types from "../actions/actionTypes";
const initialState = {
  title: "",
};

export default function navigationTitleReducer(state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_NAV_TITLE:
      return Object.assign({}, state, { title: action.title });
    default:
      return state;
  }
}
