import React from "react";
import { connect } from "react-redux";
import * as PIandDSCRActions from "../../../actions/calculatorActions/PIandDSCRActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentInput from "../calculatorCommon/PercentInput";
import NumberInput from "../calculatorCommon/NumberInput";
import DollarDisplay from "../calculatorCommon/DollarDisplay";
import NumberDisplay from "../calculatorCommon/NumberDisplay";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import * as centralStateActions from "../../../actions/calculatorActions/centralActions";
import { bindActionCreators } from "redux";
import formatValue from "../calculatorCommon/FormatValueMethod";
import formatDisplay from "../calculatorCommon/FormatDisplayMethod";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";
//renamed all initialRate -> interestRate so that the centralReducer will have proper name and values...
//PI&DSCR was renamed to DSCR and Balance on all links/routes on navbar, sidebar, and home page

class PIandDSCR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyPaymentsInterestOnlyPeriod: this.props
        .monthlyPaymentsInterestOnlyPeriod,
      monthlyPaymentsAmortizationPeriod: this.props
        .monthlyPaymentsAmortizationPeriod,
      annualPaymentsInterestOnlyPeriod: this.props
        .annualPaymentsInterestOnlyPeriod,
      annualPaymentsAmortizationPeriod: this.props
        .annualPaymentsAmortizationPeriod,
      balanceAtMaturity: this.props.balanceAtMaturity,
      debtServiceCoverageRatio: this.props.debtServiceCoverageRatio,
      loanAmount: this.props.loanAmount,
      interestRate: this.props.interestRate,
      initialTerm: this.props.initialTerm,
      interestOnly: this.props.interestOnly,
      amortizationYears: this.props.amortizationYears,
      amortizationMonths: this.props.amortizationMonths,
      annualUnderwrittenNOI: this.props.annualUnderwrittenNOI,

      modalOpen: false,
      from: "",
      to: "",
      cc: "",
    };

    this.update = this.update.bind(this);
    this.updateCentralState = this.updateCentralState.bind(this);
  }

  update(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      this.props.PIandDSCRActions.calculatePIandDSCR(this.state, {
        name,
        value,
      });
    });
  }

  componentDidMount() {
    this.update("loanAmount", this.state.loanAmount);
  }

  updateCentralState(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    if (name === "amortizationYears") {
      const monthsName = "amortizationMonths";
      const monthsValue = value * 12;
      let stateObj2 = {};
      stateObj2[monthsName] = monthsValue;
      this.setState(stateObj, function () {
        this.props.centralActions.updateCentralValues(monthsName, monthsValue);
      });
    } else if (name === "amortizationMonths") {
      const yearsName = "amortizationYears";
      const yearsValue = value / 12;
      let stateObj2 = {};
      stateObj2[yearsName] = yearsValue;
      this.setState(stateObj, function () {
        this.props.centralActions.updateCentralValues(yearsName, yearsValue);
      });
    }

    this.setState(stateObj, function () {
      this.props.centralActions.updateCentralValues(name, value);
      this.props.PIandDSCRActions.calculatePIandDSCR(this.state, {
        name,
        value,
      });
    });
  }

  openSharingCalculatorModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateCC(value) {
    this.setState({ cc: value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({ modalOpen: false }, function () {
      var balanceAtMaturity =
        this.state.balanceAtMaturity !== 0
          ? formatDisplay(this.state.balanceAtMaturity).toString()
          : " - ";
      var input = [
        { input0: formatValue(this.state.loanAmount).toString() },
        { input1: (this.state.interestRate + "%").toString() },
        { input2: this.state.initialTerm.toString() },
        { input3: this.state.interestOnly.toString() },
        { input4: this.state.amortizationYears.toString() },
        { input5: this.state.amortizationMonths.toString() },
        { input6: formatValue(this.state.annualUnderwrittenNOI).toString() },
      ];

      var output = [
        {
          output0: formatDisplay(
            this.state.monthlyPaymentsInterestOnlyPeriod
          ).toString(),
        },
        {
          output1: formatDisplay(
            this.state.monthlyPaymentsAmortizationPeriod
          ).toString(),
        },
        {
          output2: formatDisplay(
            this.state.annualPaymentsInterestOnlyPeriod
          ).toString(),
        },
        {
          output3: formatDisplay(
            this.state.annualPaymentsAmortizationPeriod
          ).toString(),
        },
        { output4: balanceAtMaturity },
        {
          output5: parseFloat(this.state.debtServiceCoverageRatio)
            .toFixed(3)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            .toString(),
        },
      ];

      this.props.sharingCalculationsActions.calculatorSharing(
        this.state.to,
        this.state.from,
        this.state.cc,
        "DscrAndBalance",
        input,
        output
      );
    });
  }

  render() {
    return (
      <div className="p-and-i">
        <Row>
          <ShareCalcModal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal.bind(this)}
            onAfterOpen={() => {}}
            onSubmit={this.onSubmit.bind(this)}
            from={this.state.from}
            to={this.state.to}
            cc={this.state.cc}
            updateFrom={this.updateFrom.bind(this)}
            updateTo={this.updateTo.bind(this)}
            updateCC={this.updateCC.bind(this)}
          />
          <Panel className="calculator-card">
            <Form className="calculator-form" inline={true}>
              <h2 className="calculator-title">Mortgage Calculator With IO</h2>
              <h4>
                Calculate my monthly mortgage payments and debt-service coverage
                ratio
              </h4>
              <div className="margin-top"></div>
              <DollarInput
                name="loanAmount"
                value={this.state.loanAmount}
                update={this.updateCentralState}
                label="Loan Amount"
              />
              <PercentInput
                name="interestRate"
                value={this.state.interestRate}
                update={this.updateCentralState}
                label="Initial Rate"
              />
              <br />
              <br />
              <NumberInput
                name="initialTerm"
                value={this.state.initialTerm}
                update={this.update}
                label="(Initial Term) Yrs"
              />
              <NumberInput
                name="interestOnly"
                value={this.state.interestOnly}
                update={this.update}
                label="(Interest Only) Yrs"
              />
              <br />
              <br />
              <NumberInput
                name="amortizationYears"
                value={this.state.amortizationYears}
                update={this.updateCentralState}
                label="Amortization Post IO Years"
              />
              <p className="or">or</p>
              <NumberInput
                name="amortizationMonths"
                value={this.state.amortizationMonths}
                update={this.updateCentralState}
                label="Amortization Post IO Months"
              />
              <br />
              <br />
              <DollarInput
                name="annualUnderwrittenNOI"
                value={this.state.annualUnderwrittenNOI}
                update={this.updateCentralState}
                label="First Year NOI"
                className="annual"
              />
            </Form>
            <div id="boxes">
              <p
                className={
                  this.state.loanAmount &&
                  this.state.interestRate &&
                  this.state.amortizationYears &&
                  this.state.amortizationMonths &&
                  this.state.annualUnderwrittenNOI
                    ? "share-calc"
                    : "hidden"
                }
                onClick={this.openSharingCalculatorModal.bind(this)}
              >
                <i className="material-icons">share</i>Share Calculations
              </p>
              <div className="box">
                <p className="box-title">Monthly Payments</p>
                {this.state.interestOnly.length > 0 && (
                  <div>
                    <label className="double">Interest Only Period</label>
                    <p className="amount">
                      <DollarDisplay
                        name="monthlyPaymentsInterestOnlyPeriod"
                        value={this.state.monthlyPaymentsInterestOnlyPeriod}
                      />
                    </p>
                    <label className="double">Amortization Period</label>
                  </div>
                )}
                <p className="amount">
                  <DollarDisplay
                    name="monthlyPaymentsAmortizationPeriod"
                    value={this.state.monthlyPaymentsAmortizationPeriod}
                  />
                </p>
              </div>
              <div className="box">
                <p className="box-title">Annual Payments</p>
                {this.state.interestOnly.length > 0 && (
                  <div>
                    <label className="double">Interest Only Period</label>
                    <p className="amount">
                      <DollarDisplay
                        name="annualPaymentsInterestOnlyPeriod"
                        value={this.state.annualPaymentsInterestOnlyPeriod}
                      />
                    </p>
                    <label className="double">Amortization Period</label>
                  </div>
                )}
                <p className="amount">
                  <DollarDisplay
                    name="annualPaymentsAmortizationPeriod"
                    value={this.state.annualPaymentsAmortizationPeriod}
                  />
                </p>
              </div>
              <div className="box orange">
                <p className="box-title">Balance at Maturity</p>
                <p className="amount">
                  <DollarDisplay
                    name="balanceAtMaturity"
                    value={this.state.balanceAtMaturity}
                  />
                </p>
              </div>
              <div className="box orange">
                <p className="box-title">Debt Service Coverage Ratio</p>
                <p className="amount">
                  <NumberDisplay
                    name="debtServiceCoverageRatio"
                    value={this.state.debtServiceCoverageRatio}
                  />
                </p>
              </div>
            </div>
          </Panel>
        </Row>

        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const state1 = state.PIandDSCR;
  const state2 = state.centralValues;
  return {
    monthlyPaymentsInterestOnlyPeriod:
      state1.monthlyPaymentsInterestOnlyPeriod || "",
    monthlyPaymentsAmortizationPeriod:
      state1.monthlyPaymentsAmortizationPeriod || "",
    annualPaymentsInterestOnlyPeriod:
      state1.annualPaymentsInterestOnlyPeriod || "",
    annualPaymentsAmortizationPeriod:
      state1.annualPaymentsAmortizationPeriod || "",
    balanceAtMaturity: state1.balanceAtMaturity || "",
    debtServiceCoverageRatio: state1.debtServiceCoverageRatio || "",
    loanAmount: state2.loanAmount || "",
    interestRate: state2.interestRate || "",
    initialTerm: state2.initialTerm || "",
    interestOnly: state1.interestOnly | "",
    amortizationYears: state2.amortizationYears || "",
    amortizationMonths: state2.amortizationMonths || "",
    annualUnderwrittenNOI: state2.annualUnderwrittenNOI || "",
  };
}

function mapDispatchToProps(dispatch) {
  return {
    PIandDSCRActions: bindActionCreators(PIandDSCRActions, dispatch),
    centralActions: bindActionCreators(centralStateActions, dispatch),
    sharingCalculationsActions: bindActionCreators(
      sharingCalculationsActions,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PIandDSCR);
