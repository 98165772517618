import React from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./formComponents.style";
import { InfoOutlinedIcon } from "../../../themes";
import { eCalcForms } from "../../../lib";
import { setOpenCalcs } from "../../../actions/eCalcForms";

export function MissingInputsMsg({ calculator, persist }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  function expandAllCalcs() {
    dispatch(setOpenCalcs([...Object.values(eCalcForms)], persist));
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }
  }
  return (
    <div className={classes.missingInputsMsg}>
      <InfoOutlinedIcon className={classes.missingInputsIcon} />
      <span>
        There are fields above that are needed before {calculator} can be
        calculated. Click{" "}
        <span className={classes.expandLink} onClick={() => expandAllCalcs()}>
          here
        </span>{" "}
        to expand all calculators
      </span>
    </div>
  );
}
