import * as types from "../actions/actionTypes";

const initialState = {
  ratesList: {},
  rates: [],
  ecalcRates: [],
};

export default function rateReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_RATES:
      return { ...state, rates: action.rates };
    case types.GET_RATES_PAGE:
      return {
        ...state,
        ratesList: action.payload,
      };
    case types.GET_ECALC_RATES:
      return {
        ...state,
        ecalcRates: action.payload,
      };
    default:
      return state;
  }
}
