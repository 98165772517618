import * as types from "./actionTypes";

export function showNotification({ message, variant, duration }) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_NOTIFICATION,
      message,
      variant,
      duration,
    });
  };
}

export function hideNotification() {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_NOTIFICATION,
    });
  };
}
