import React from "react";
import { Divider } from "@material-ui/core";
import { EcalcCalculatorIcon } from "../../../../themes";
import { useStyles } from "./Property.styles";

export default function Property({
  isMobile,
  property,
  setNotification,
  save,
}) {
  const classes = useStyles({ isMobile });
  const { deal_name, ecalcs } = property;

  return (
    <div>
      <div
        className={classes.property}
        onClick={() => {
          if (ecalcs.length > 0) {
            setNotification({
              show: true,
              message:
                "Saving to this property will override its existing calculators",
              buttonTxt: "Save",
              onClick: () => save(property),
            });
          } else {
            save(property);
          }
        }}
      >
        <span>{deal_name}</span>
        {ecalcs.length > 0 && <EcalcCalculatorIcon />}
      </div>
      <Divider />
    </div>
  );
}
