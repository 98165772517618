import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Col from "muicss/lib/react/col";
import Button from "muicss/lib/react/button";
import * as navigationTitleActions from "../../actions/navigationTitleActions";

class EasternWayContainer extends React.Component {
  componentDidMount() {
    this.props.navActions.updateNavTitle("Eastern Education");
  }

  openLink = (url) => {
    if (window && window.isNativeApp) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          action: "openUrl",
          data: { url },
        })
      );
    } else {
      window.open(url);
    }
  };
  render() {
    return (
      <div className="eastern-way">
        <Col lg="6" md="12" className="eastern-way-img-container">
          <img
            src="/images/eastern-way.png"
            id="eastern-way-img"
            alt="eastern-way-img"
          />
        </Col>
        <Col lg="6" md="12" className="eastern-way-info-container">
          <h1>The Eastern Education</h1>
          <p className="eastern-way-info">
            A 75+ page all-encompassing commercial real estate educational
            handbook composed by Eastern Union's President{" "}
            <span
              onClick={() =>
                this.openLink("https://www.linkedin.com/in/irazlotowitz/")
              }
            >
              Ira Zlotowitz.
            </span>
          </p>
          <Button
            variant="flat"
            onClick={() =>
              this.openLink(
                process.env.REACT_APP_PORTAL_URL + "/images/TheEasternWay.pdf"
              )
            }
          >
            Download the Book
          </Button>
        </Col>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(navigationTitleActions, dispatch),
  };
}

export default connect(() => ({}), mapDispatchToProps)(EasternWayContainer);
