import { parseToNumber } from "../helpers";

export function monthlyAnnualPaymentsAmortizationPeriod({
  loan_amount_dollar,
  interest_rate,
  amortization_years,
}) {
  const loanAmount = parseToNumber(loan_amount_dollar);
  const interestRate = parseToNumber(interest_rate) / 100;
  const amortizationMonths = amortization_years
    ? parseToNumber(amortization_years) * 12
    : 0;
  const value1 =
    loanAmount *
    ((interestRate / 12) * Math.pow(1 + interestRate / 12, amortizationMonths));
  const value2 = Math.pow(1 + interestRate / 12, amortizationMonths) - 1;
  let result = value1 / value2;
  if (value1 === Infinity || value2 === Infinity) {
    result = "Invalid Entry";
  }
  return {
    monthly: result,
    annual: result !== "Invalid Entry" ? (value1 / value2) * 12 : result,
  };
}

export function calcMortgageDcsrPostIO({
  loan_amount_dollar,
  interest_rate,
  amortization_years,
  io_years,
}) {
  const { monthly, annual } = monthlyAnnualPaymentsAmortizationPeriod({
    loan_amount_dollar,
    interest_rate,
    amortization_years,
  });
  return {
    label: io_years ? "Mortgage Post I/O" : "Mortgage",
    results: [
      {
        name: "Monthly",
        value: monthly,
        displayFormat: "dollar",
      },
      {
        name: "Annual",
        value: annual,
        displayFormat: "dollar",
      },
    ],
    showAmortizationSchedule: true,
  };
}

export function calcMortgageDcsrDuringIO({
  loan_amount_dollar,
  interest_rate,
}) {
  const loanAmount = parseToNumber(loan_amount_dollar);
  const interestRate = parseToNumber(interest_rate) / 100;
  const result = loanAmount * interestRate;
  return {
    label: "Mortgage During I/O",
    results: [
      {
        name: "Monthly",
        value: result / 12,
        displayFormat: "dollar",
      },
      {
        name: "Annual",
        value: result,
        displayFormat: "dollar",
      },
    ],
    showAmortizationSchedule: true,
  };
}

export function calcBalanceAtMaturity({
  loan_amount_dollar,
  interest_rate,
  amortization_years,
  init_loan_term_yrs,
  io_years,
  holding_period_yrs,
}) {
  const loanAmount = parseToNumber(loan_amount_dollar);
  const interestRate = parseToNumber(interest_rate) / 100;
  const amortizationMonths = parseToNumber(amortization_years) * 12;
  let balanceAtMaturity = 0;

  if (init_loan_term_yrs || holding_period_yrs) {
    const monthlyInterestRate = interestRate / 12;
    const numPaymentPeriods =
      parseToNumber(init_loan_term_yrs || holding_period_yrs) * 12;
    const IOMult12 = io_years ? parseToNumber(io_years) * 12 : 0;

    const var1 =
      loanAmount *
      Math.pow(1 + monthlyInterestRate, numPaymentPeriods - IOMult12);
    const var2 =
      ((monthlyInterestRate * loanAmount) /
        (1 - Math.pow(1 + monthlyInterestRate, -amortizationMonths)) /
        monthlyInterestRate) *
      (Math.pow(1 + monthlyInterestRate, numPaymentPeriods - IOMult12) - 1);
    balanceAtMaturity = Math.round(var1) - Math.round(var2);
  }

  return {
    label: "Balance at Maturity",
    results: balanceAtMaturity,
    displayFormat: "dollar",
    showAmortScheduleMobile: true,
  };
}

export function calcDcsrPostIo({
  loan_amount_dollar,
  interest_rate,
  first_year_noi,
  amortization_years,
}) {
  const noi = parseToNumber(first_year_noi);
  const {
    monthly: monthlyPaymentsAmortizationPeriod,
  } = monthlyAnnualPaymentsAmortizationPeriod({
    loan_amount_dollar,
    interest_rate,
    amortization_years,
  });
  let debtServiceCoverageRatio = 0;

  if (noi) {
    debtServiceCoverageRatio = noi / (monthlyPaymentsAmortizationPeriod * 12);
  }
  return {
    label: "DSCR",
    results:
      monthlyPaymentsAmortizationPeriod !== "Invalid Entry"
        ? parseToNumber(debtServiceCoverageRatio).toFixed(3)
        : monthlyPaymentsAmortizationPeriod,
    showMaxLoan: true,
  };
}

export function calcDcsrduringIo({
  loan_amount_dollar,
  interest_rate,
  first_year_noi,
  amortization_years,
  io_years,
}) {
  const { results: debtServiceCoverageRatioDuring } = calcDcsrPostIo({
    loan_amount_dollar,
    interest_rate,
    first_year_noi,
    amortization_years,
  });

  const monthlyPaymentsInterestOnlyPeriod =
    parseToNumber(loan_amount_dollar) * (parseToNumber(interest_rate) / 100);

  return {
    showMaxLoan: true,
    label: "DSCR",
    results: [
      {
        name: "During I/O",
        value:
          debtServiceCoverageRatioDuring !== "Invalid Entry"
            ? +(
                parseToNumber(first_year_noi) /
                monthlyPaymentsInterestOnlyPeriod
              ).toFixed(3)
            : debtServiceCoverageRatioDuring,
      },
      {
        name: "Post I/O",
        value:
          debtServiceCoverageRatioDuring !== "Invalid Entry"
            ? +parseToNumber(debtServiceCoverageRatioDuring).toFixed(3)
            : debtServiceCoverageRatioDuring,
      },
    ],
  };
}

export function calcAmortizationSchedule({
  mortgage_during_io,
  mortgage_post_io,
  io_years,
  loan_amount_dollar,
  interest_rate,
  init_loan_term_yrs,
  amortization_years,
}) {
  const years = init_loan_term_yrs || amortization_years;
  const loanAmount = parseToNumber(loan_amount_dollar);
  const monthly_mortgage_during_io =
    mortgage_during_io &&
    mortgage_during_io.results.find((m) => m.name.toLowerCase() === "monthly")
      .value;
  const monthly_mortgage_post_io =
    mortgage_post_io &&
    mortgage_post_io.results.find((m) => m.name.toLowerCase() === "monthly")
      .value;
  const schedule = [];
  if (years > 0) {
    [...new Array(years * 12)].forEach((values, index) => {
      if (monthly_mortgage_during_io && io_years && index < io_years * 12) {
        schedule.push({
          month: index + 1,
          principal: 0,
          interest: parseToNumber(monthly_mortgage_during_io),
          balance: loanAmount,
        });
      } else {
        var interest = "";
        var principal = "";
        var balance = "";
        if (index === 0) {
          interest =
            (parseToNumber(loanAmount) * (parseToNumber(interest_rate) / 100)) /
            12;
          principal = parseToNumber(monthly_mortgage_post_io) - interest;
          balance = parseToNumber(loanAmount) - principal;
        } else {
          const prev = schedule[index - 1];
          interest =
            (parseToNumber(prev.balance) *
              (parseToNumber(interest_rate) / 100)) /
            12;
          principal = monthly_mortgage_post_io - interest;
          balance = prev.balance - principal;
        }
        schedule.push({
          month: index + 1,
          principal: +parseFloat(principal).toFixed(5),
          interest: interest,
          balance: +parseFloat(balance).toFixed(5),
        });
      }
    });
  }
  return schedule;
}
