import * as types from "../actionTypes";

function updateInterestOnly(values) {
  return { type: types.UPDATE_INTEREST_ONLY, values };
}

export function calculateInterestOnly(values) {
  return function (dispatch) {
    values.totalInterestAnnually =
      values.loanAmount * (values.interestRate / 100);
    values.interestPerMonth =
      (values.loanAmount * (values.interestRate / 100)) / 12;
    dispatch(updateInterestOnly(values));
  };
}

export function updateValuesInterestOnly(values) {
  return function (dispatch) {
    if (!values.totalInterestAnnually && !values.interestPerMonth) {
      return;
    }
    values.totalInterestAnnually = 0;
    values.interestPerMonth = 0;
    dispatch(updateInterestOnly(values));
  };
}

export function updateOnUnmount(values) {
  return function (dispatch) {
    dispatch(updateInterestOnly(values));
  };
}
