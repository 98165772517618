import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import clsx from "clsx";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _snakeCase from "lodash/snakeCase";
import _flatMap from "lodash/flatMap";
import _sum from "lodash/sum";
import _max from "lodash/max";
import { Divider, List, ListItem, Grid } from "@material-ui/core";
import { eCalcForms, renameObjKey } from "../../../lib";
import { ItemizationLayout } from "./Itemization";
import { EditableItemizationText } from "../components";
import {
  updateItemization,
  eCalcOnMultiChange,
  setActiveCalculator,
  addUserItemizationEntryMethod,
  deleteUserItemizationEntryMethod,
} from "../../../actions/eCalcForms";
import {
  entryMethods,
  entryMethodNames,
  noDecimal,
  parseToNumber,
  numberToPercent,
  formatPercent,
} from "../helpers";
import { useStyles } from "./closingCostsCapitalEvent.styles";

export function ClosingCostCapitalEventItemized({
  open,
  handleClose,
  storeSliceName,
  persist,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isInitialState = useRef(true);

  const itemizationValues = useSelector(
    (state) =>
      state[storeSliceName].itemizations?.[
        eCalcForms.CLOSING_COST_CAPITAL_EVENT
      ],
    shallowEqual
  );

  const userItemizationEntryMethods = useSelector(
    (state) =>
      state[storeSliceName].userItemizationEntryMethods?.[
        entryMethodNames.CLOSING_COST_CAPITAL_EVENT
      ],
    shallowEqual
  );

  const capitalEventLoanAmount = useSelector(
    (state) =>
      state[storeSliceName][eCalcForms.IRR_CAPITAL_EVENT].capitalEventLoanAmount
  );

  const [initialValues, setInitialValues] = useState();
  const [itemsToHide, setItemsToHide] = useState([]);
  const [totals, setTotals] = useState();
  // we need to track how many fields the user added
  const [totalAddedFields, setTotalAddedFields] = useState(0);

  const newValueRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, []);

  useEffect(() => {
    if (isInitialState.current) {
      // we need to store the initial state so we can reset
      setInitialValues(itemizationValues);
      isInitialState.current = false;
    }
    const addedFieldsArr = Object.keys(itemizationValues).reduce(
      (acc, cur) => {
        if (cur.includes("new_cost")) {
          // get the number at the end of the key
          const split = cur.split("_");
          const keyNumber = split[split.length - 1];
          if (!isNaN(keyNumber)) {
            acc.push(+keyNumber);
          }
        }
        return acc;
      },
      [0]
    );
    setTotalAddedFields(_max(addedFieldsArr));
  }, [itemizationValues]);

  useEffect(() => {
    const {
      [entryMethods.DOLLAR_AMOUNT]: dollar,
      [entryMethods.PERCENT_AMOUNT]: percent,
    } = getTotalAmounts({
      itemizationValues,
      loanAmount: capitalEventLoanAmount,
    });
    if (capitalEventLoanAmount) {
      setTotals([
        { label: "Total Closing Costs $:", value: dollar, display: "dollar" },
        { label: "Total Closing Costs %:", value: percent, display: "percent" },
      ]);
    } else {
      setTotals({ label: "Total Closing Costs $:", value: dollar });
    }
  }, [itemizationValues, capitalEventLoanAmount]);

  const handleKeysBlur = useCallback(
    (e) => {
      const { name, value } = e.target;
      const formatted = value?.indexOf("/") === -1 ? _snakeCase(value) : value;
      const newValues = renameObjKey({
        object: itemizationValues,
        oldKey: name,
        newKey: formatted,
      });
      dispatch(
        updateItemization({
          name: eCalcForms.CLOSING_COST_CAPITAL_EVENT,
          value: newValues,
          persist,
        })
      );
      // We need to update the key in the `userItemizationEntryMethods` object
      dispatch(
        addUserItemizationEntryMethod({
          itemizationName: entryMethodNames.CLOSING_COST_CAPITAL_EVENT,
          item: formatted,
          value: userItemizationEntryMethods[name],
          persist,
        })
      );
      // delete old keys
      dispatch(
        deleteUserItemizationEntryMethod({
          itemizationName: entryMethodNames.CLOSING_COST_CAPITAL_EVENT,
          item: name,
          persist,
        })
      );
    },
    [dispatch, persist, itemizationValues, userItemizationEntryMethods]
  );

  const handleValuesBlur = useCallback(
    (e, nestedKey) => {
      const { name, value } = e.target;
      if (
        parseToNumber(value) ===
        parseToNumber(itemizationValues[name]?.[nestedKey])
      ) {
        return;
      }
      const canBeSynced = itemizationValues[name].hasOwnProperty("percent");
      const syncedFields = syncFields({
        nestedKey,
        value,
        canBeSynced,
        loanAmount: capitalEventLoanAmount,
      });
      const newValues = {
        ...itemizationValues,
        [name]: { ...itemizationValues[name], ...syncedFields },
      };
      const {
        [entryMethods.DOLLAR_AMOUNT]: dollarAmt,
        [entryMethods.PERCENT_AMOUNT]: percentAmt,
      } = getTotalAmounts({
        itemizationValues: newValues,
        loanAmount: capitalEventLoanAmount,
      });
      dispatch(
        eCalcOnMultiChange({
          calculator: eCalcForms.IRR_CAPITAL_EVENT,
          valuesObj: {
            capitalEventClosingCostDollars: dollarAmt,
            capitalEventClosingCostPercent: percentAmt,
          },
          persist,
        })
      );
      dispatch(
        updateItemization({
          name: eCalcForms.CLOSING_COST_CAPITAL_EVENT,
          value: newValues,
          persist,
        })
      );
      if (canBeSynced) {
        dispatch(
          addUserItemizationEntryMethod({
            itemizationName: entryMethodNames.CLOSING_COST_CAPITAL_EVENT,
            item: name,
            value:
              nestedKey === entryMethods.DOLLAR_AMOUNT
                ? entryMethods.DOLLAR_AMOUNT
                : entryMethods.PERCENT_AMOUNT,
            persist,
          })
        );
      }
      // need to know which calculator the user is entering for mobile slider
      dispatch(setActiveCalculator({ name: "irr", persist }));
    },
    [itemizationValues, capitalEventLoanAmount, dispatch, persist]
  );

  const handleAdd = useCallback(() => {
    dispatch(
      updateItemization({
        name: eCalcForms.CLOSING_COST_CAPITAL_EVENT,
        value: {
          ...itemizationValues,
          [`new_cost_${totalAddedFields + 1}`]: { dollar: 0 },
        },
        persist,
      })
    );
  }, [dispatch, persist, itemizationValues, totalAddedFields]);

  const handleReset = useCallback(() => {
    dispatch(
      updateItemization({
        name: eCalcForms.CLOSING_COST_CAPITAL_EVENT,
        value: initialValues,
        persist,
      })
    );
  }, [dispatch, initialValues, persist]);

  const handleUndoableDelete = useCallback((key) => {
    setItemsToHide((list) => [...list, key]);
  }, []);

  const undoDelete = useCallback((key) => {
    setItemsToHide((list) => list.filter((l) => key !== l));
  }, []);

  const handleDelete = useCallback(
    (key) => {
      const { [key]: itemToDelete, ...otherItems } = itemizationValues;
      dispatch(
        updateItemization({
          name: eCalcForms.CLOSING_COST_CAPITAL_EVENT,
          value: otherItems,
        })
      );
      dispatch(
        deleteUserItemizationEntryMethod({
          itemizationName: entryMethodNames.CLOSING_COST_CAPITAL_EVENT,
          item: key,
          persist,
        })
      );
      undoDelete(key);
    },
    [dispatch, persist, itemizationValues, undoDelete]
  );

  if (!itemizationValues) return null;
  return (
    <ItemizationLayout
      open={open}
      handleClose={handleClose}
      handleAdd={handleAdd}
      handleReset={handleReset}
      title="Closing Costs Itemized"
      totals={totals}
    >
      <List>
        {Object.keys(itemizationValues).map((itemName) => {
          const { dollar, percent } = itemizationValues[itemName];
          const isDeleted = itemsToHide.indexOf(itemName) > -1;
          return (
            <Fragment key={itemName}>
              <ListItem
                classes={{ root: classes.listItem }}
                disableGutters
                innerRef={
                  itemName === `new_cost_${totalAddedFields}`
                    ? newValueRef
                    : undefined
                }
              >
                <EditableItemizationText
                  value={itemName}
                  name={itemName}
                  type="key"
                  handleBlur={handleKeysBlur}
                  handleUndoableDelete={handleUndoableDelete}
                  undoDelete={undoDelete}
                  handleDelete={handleDelete}
                  isDeleted={isDeleted}
                />
                <Grid
                  container
                  className={clsx({ [classes.hidden]: isDeleted })}
                >
                  <Grid item xs={4} />
                  <Grid item xs={3} classes={{ root: classes.gridItem }}>
                    <span className={classes.valuesKey}>Amount</span>
                  </Grid>
                  <Grid item xs={4} classes={{ root: classes.gridItem }}>
                    <EditableItemizationText
                      value={dollar}
                      name={itemName}
                      type="value"
                      nestedKey="dollar"
                      handleBlur={handleValuesBlur}
                      startAdornment="$"
                    />
                  </Grid>
                  {itemizationValues[itemName].hasOwnProperty("percent") && (
                    <Fragment>
                      <Grid item xs={3} />
                      <Grid item xs={4} classes={{ root: classes.gridItem }}>
                        <span className={classes.valuesKey}>
                          % of Loan Amount
                        </span>
                      </Grid>
                      <Grid item xs={4} classes={{ root: classes.gridItem }}>
                        <EditableItemizationText
                          value={percent}
                          name={itemName}
                          type="value"
                          nestedKey="percent"
                          handleBlur={handleValuesBlur}
                          endAdornment="%"
                          display="percent"
                        />
                      </Grid>
                    </Fragment>
                  )}
                </Grid>
              </ListItem>
              {!isDeleted && <Divider className={classes.divider} />}
            </Fragment>
          );
        })}
      </List>
    </ItemizationLayout>
  );
}

export function syncFields({ canBeSynced, nestedKey, value, loanAmount }) {
  const syncKey =
    nestedKey === entryMethods.DOLLAR_AMOUNT
      ? entryMethods.PERCENT_AMOUNT
      : entryMethods.DOLLAR_AMOUNT;
  // check if it could have a percent value
  if (!canBeSynced) {
    return {
      [entryMethods.DOLLAR_AMOUNT]: value,
    };
  }
  if (!loanAmount) {
    return {
      [nestedKey]: value,
      [syncKey]: 0,
    };
  }
  const syncValue =
    nestedKey === entryMethods.DOLLAR_AMOUNT
      ? formatPercent(parseToNumber(value) / parseToNumber(loanAmount))
      : value
      ? noDecimal(numberToPercent(value) * parseToNumber(loanAmount))
      : 0;
  return {
    [nestedKey]: value,
    [syncKey]: syncValue,
  };
}

export function getTotalAmounts({ itemizationValues, loanAmount }) {
  const dollarAmounts = _sum(
    _flatMap(itemizationValues, (item) =>
      parseToNumber(item[entryMethods.DOLLAR_AMOUNT] || 0)
    )
  );
  return {
    [entryMethods.DOLLAR_AMOUNT]: dollarAmounts,
    [entryMethods.PERCENT_AMOUNT]: loanAmount
      ? formatPercent(parseToNumber(dollarAmounts) / parseToNumber(loanAmount))
      : 0,
  };
}
