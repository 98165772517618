import React from "react";
import Modal from "react-modal";
import Input from "muicss/lib/react/input";
import Button from "muicss/lib/react/button";
import Select from "muicss/lib/react/select";
import Option from "muicss/lib/react/option";
import NumberInput from "../common/NumberInput";

class GetQuoteForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      propertyType: "",
      loanType: "",
      loanSize: "",
      location: "",
      lookingToClose: "",
      phoneValidationMsg: "",
      emailValidationMsg: "",
    };

    this.firstNameChange = this.firstNameChange.bind(this);
    this.lastNameChange = this.lastNameChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.phoneChange = this.phoneChange.bind(this);
    this.propertyTypeChange = this.propertyTypeChange.bind(this);
    this.loanTypeChange = this.loanTypeChange.bind(this);
    this.loanSizeChange = this.loanSizeChange.bind(this);
    this.locationChange = this.locationChange.bind(this);
    this.lookingToCloseChange = this.lookingToCloseChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    this.setState({ propertyTypesList: this.props.propertyTypes });
  }

  firstNameChange(e) {
    this.setState({ firstName: e.target.value });
  }

  lastNameChange(e) {
    this.setState({ lastName: e.target.value });
  }

  emailChange(e) {
    this.setState({ email: e.target.value }, () => {
      let isValid = this.validateEmail(this.state.email);

      if (!isValid) {
        this.setState({ emailValidationMsg: "Not a valid email" });
      } else {
        this.setState({ emailValidationMsg: "" });
      }
    });
  }

  validatePhone(value) {
    var re = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    return re.test(value);
  }

  validateEmail(value) {
    var re = /[^@]+@[^@]+\.[^@]+/;
    return re.test(value);
  }

  phoneChange(e) {
    this.setState({ phone: e.target.value }, () => {
      let isValid = this.validatePhone(this.state.phone);

      if (!isValid) {
        this.setState({ phoneValidationMsg: "Not a valid phone number" });
      } else {
        this.setState({ phoneValidationMsg: "" });
      }
    });
  }

  propertyTypeChange(e) {
    this.setState({ propertyType: e.target.value });
  }

  loanTypeChange(e) {
    this.setState({ loanType: e.target.value });
  }

  loanSizeChange(e) {
    this.setState({ loanSize: e });
  }

  locationChange(e) {
    this.setState({ location: e.target.value });
  }

  lookingToCloseChange(e) {
    this.setState({ lookingToClose: e.target.value });
  }

  valid() {
    let invalidFields = [];
    if (this.state.firstName === "") {
      invalidFields.push("First Name");
    }
    if (this.state.lastName === "") {
      invalidFields.push("Last Name");
    }
    if (this.state.email === "") {
      invalidFields.push("Email");
    }
    if (this.state.phone === "") {
      invalidFields.push("Phone");
    }

    if (invalidFields.length) {
      //separate each invalid field with comma. last field should have 'and' instead
      let errorMessage = invalidFields
        .join(", ")
        .replace(/,(?!.*,)/gim, " and");
      errorMessage +=
        invalidFields.length >= 2 ? " are required!" : " is required!";
      this.setState({ errorMessage });
      return false;
    } else if (this.state.emailValidationMsg || this.state.phoneValidationMsg) {
      this.setState({ errorMessage: "" });
      return false;
    } else {
      return true;
    }
  }

  submitForm(e) {
    e.preventDefault();
    if (this.valid()) {
      this.props.submitQuoteForm(
        this.state.firstName,
        this.state.lastName,
        this.state.email,
        this.state.phone,
        "finance",
        this.state.propertyType,
        this.state.location,
        this.state.loanSize,
        null,
        this.state.loanType,
        this.state.lookingToClose
      );
      this.props.closeQuoteForm();
    }
  }

  render() {
    const propertyTypes =
      this.props.propertyTypes &&
      this.props.propertyTypes.map((item) => (
        <Option
          key={item.type_id}
          label={item.description}
          value={item.description}
        />
      ));
    const states =
      this.props.states &&
      this.props.states.map((item) => (
        <Option key={item.id} label={item.state} value={item.state} />
      ));
    return (
      <Modal
        className="comps-form-modal form-modal"
        isOpen={this.props.isOpen}
        ariaHideApp={false}
        contentLabel="Modal"
        shouldCloseOnOverlayClick={false}
      >
        <div className="form-modal-header flex-justify-center">
          <p className="bold-text large-text uppercase-text">Get a Quote</p>
          <i
            className="material-icons accent-text"
            onClick={this.props.closeQuoteForm}
          >
            close
          </i>
        </div>
        <form>
          <div className="form-row flex">
            <div className="column-half column-left">
              <Input
                label="First Name *"
                floatingLabel={true}
                onChange={this.firstNameChange}
                required={true}
              />
            </div>
            <div className="column-half column-right">
              <Input
                label="Last Name *"
                floatingLabel={true}
                onChange={this.lastNameChange}
                required={true}
              />
            </div>
          </div>
          <div className="form-row flex">
            <div className="column-half column-left">
              <Input
                label="Email *"
                floatingLabel={true}
                onChange={this.emailChange}
                required={true}
              />
              <span className="help-block">
                {this.state.emailValidationMsg}
              </span>
            </div>
            <div className="column-half column-right">
              <Input
                label="Phone *"
                floatingLabel={true}
                onChange={this.phoneChange}
                required={true}
              />
              <span className="help-block">
                {this.state.phoneValidationMsg}
              </span>
            </div>
          </div>
          <div className="form-row flex">
            <div className="column-half column-left">
              <Select
                className={this.state.propertyType === "" ? "placeholder" : ""}
                value={this.state.propertyType}
                onChange={this.propertyTypeChange}
              >
                <Option className="hidden" label="Property Type" value="" />
                <Option label="Please Select..." value="" />
                {propertyTypes}
              </Select>
            </div>
            <div className="column-half column-right">
              <Select
                className={this.state.loanType === "" ? "placeholder" : ""}
                value={this.state.loanType}
                onChange={this.loanTypeChange}
              >
                <Option className="hidden" label="Loan Type" value="" />
                <Option label="Please Select..." value="" />
                <Option label="Purchase" value="purchase" />
                <Option label="Refinance" value="refinance" />
              </Select>
            </div>
          </div>
          <div className="form-row flex">
            <div className="column-half column-left">
              <NumberInput
                label="Loan Size"
                value={this.state.loanSize}
                floatingLabel={true}
                update={this.loanSizeChange}
              />
            </div>
            <div className="column-half column-right">
              <Select
                className={this.state.location === "" ? "placeholder" : ""}
                value={this.state.location}
                onChange={this.locationChange}
              >
                <Option className="hidden" label="Location" value="" />
                <Option label="Please Select..." value="" />
                {states}
              </Select>
            </div>
          </div>
          <div className="form-row form-modal-textarea">
            <p>What are you looking to close?</p>
            <textarea onChange={this.lookingToCloseChange}></textarea>
          </div>
          <p className="error-block">{this.state.errorMessage}</p>
        </form>
        <div className="form-modal-btns text-right">
          <Button
            className="button btn-accent2"
            variant="flat"
            onClick={this.props.closeQuoteForm}
          >
            Cancel
          </Button>
          <Button
            className="button btn-accent"
            variant="flat"
            onClick={this.submitForm}
          >
            Submit
          </Button>
        </div>
      </Modal>
    );
  }
}

export default GetQuoteForm;
