import React from "react";
import FormattedInput from "./FormattedInput";

class NumberInput extends React.Component {
  formatValue(value) {
    value = value.toString();
    value = value.replace(/[^0-9.]/g, ""); //Strips of non-numeric characters besides decimal
    const decIndex = value.indexOf(".");
    if (decIndex !== -1 && this.props.decimalPlaces) {
      value = value.slice(0, decIndex + (this.props.decimalPlaces + 1)); // only allow certain amount of decimal places
    }
    return value || ""; //Format with % at end
  }

  stripFormat(value) {
    let numberValue = value.replace(/[^0-9.]/g, "");
    const lastChar = numberValue.slice(-1);
    if (lastChar === ".") {
      const numberSliced = numberValue.slice(0, -1);
      if (numberSliced > Math.floor(numberSliced)) {
        numberValue = numberSliced;
      }
    }
    return numberValue;
  }

  render() {
    return (
      <FormattedInput
        {...this.props}
        formatValue={this.formatValue.bind(this)}
        stripFormat={this.stripFormat.bind(this)}
      />
    );
  }
}

export default NumberInput;
