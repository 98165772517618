import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 815,
    maxHeight: 815,
    height: "100%",
    fontFamily: "Karla",
    overflowY: "hidden",
  },
  scrollableContent: {
    overflowY: "auto",
    width: "100%",
    maxHeight: "calc(100% - 285px)",
    [theme.breakpoints.between(375, 500)]: {
      maxHeight: "calc(100% - 255px)",
    },
    [theme.breakpoints.down(374)]: {
      maxHeight: "calc(100% - 240px)",
    },
  },
  content: {
    maxWidth: 449,
    width: 449,
    margin: "0 auto",
    [theme.breakpoints.between(375, 500)]: {
      width: 315,
      maxWidth: 315,
    },
    [theme.breakpoints.down(374)]: {
      width: 264,
      maxWidth: 264,
    },
  },
  closeButton: {
    position: "absolute",
    top: 10,
    left: 10,
    color: "#00112B",
  },
  title: {
    fontWeight: "bold",
    fontSize: 24,
    textAlign: "center",
    color: "#00112B",
    width: "100%",
    paddingTop: 20,
    marginBottom: 15,
    [theme.breakpoints.down(500)]: {
      fontSize: 14,
    },
  },
  dialogActions: {
    width: 449,
    margin: "0 auto",
    maxWidth: 449,
    [theme.breakpoints.between(375, 500)]: {
      width: 315,
      maxWidth: 315,
    },
    [theme.breakpoints.down(374)]: {
      width: 264,
      maxWidth: 264,
    },
  },
  cancelBtn: {
    fontFamily: "Roboto",
    fontSize: 16,
    textDecorationLine: "underline",
    color: "#000000",
    textTransform: "capitalize",
    [theme.breakpoints.down(500)]: {
      fontSize: 12,
    },
  },
  addButton: {
    height: 35,
    width: 97,
    background: "#10B8C7",
    marginLeft: 25,
    borderRadius: 30.0781,
    fontSize: 20.6061,
    padding: "0px 10px",
    "&:hover": {
      background: "#10B8C7",
    },
    [theme.breakpoints.between(375, 500)]: {
      width: 65,
      height: 24,
      borderRadius: 20.4353,
      fontSize: 14,
    },
    [theme.breakpoints.down(374)]: {
      width: 51,
      height: 24,
      borderRadius: 20.4353,
      fontSize: 12,
    },
  },
  addIcon: {
    fontSize: 19.1342,
    [theme.breakpoints.between(375, 500)]: {
      fontSize: 13,
    },
    [theme.breakpoints.down(374)]: {
      fontSize: 12,
    },
  },
  totalContainer: {
    width: 449,
    height: 55,
    margin: "0 auto",
    position: "absolute",
    bottom: 80,
    right: 0,
    left: 0,
    color: "#00112B",
    [theme.breakpoints.between(375, 500)]: {
      width: 317,
    },
    [theme.breakpoints.down(374)]: {
      width: 264,
      height: 47,
    },
  },
  singleTotalContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  multiTotalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  totalLabel: {
    fontFamily: "Karla",
    fontSize: 20,
    marginBottom: 10,
    [theme.breakpoints.between(375, 500)]: {
      fontSize: 14,
      marginBottom: 5,
    },
    [theme.breakpoints.down(374)]: {
      fontSize: 12,
      marginBottom: 5,
    },
  },
  total: {
    background: "#EFEFEF",
    borderRadius: 4,
    boxSizing: "border-box",
    padding: "12px 13px",
    fontFamily: "Roboto Condensed",
    fontSize: 22,
    width: "100%",
    [theme.breakpoints.down(500)]: {
      fontSize: 19,
    },
  },
  shortTotal: {
    flex: 1,
    [theme.breakpoints.between(375, 500)]: {
      width: 144,
    },
    [theme.breakpoints.down(374)]: {
      width: 121,
    },
  },
  totalDivider: {
    height: 1.40379,
    width: 40,
    background: "#EFEFEF",
    marginTop: 35,
    [theme.breakpoints.between(375, 500)]: {
      height: 1,
      width: 29,
      marginTop: 25,
    },
    [theme.breakpoints.down(374)]: {
      height: 1,
      width: 24,
      marginTop: 25,
    },
  },
}));
