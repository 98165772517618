import * as types from "../actions/actionTypes";

const defaultCapRate = {
  purchase_price: "",
  potential_annual_gross_income: "",
  potential_monthly_gross_income: "",
  vacancy_as_dollar: "",
  vacancy_as_percent: "",
  annual_expenses: "",
  monthly_expenses: "",
  first_year_noi: "",
};

const defaultEquityNeeded = {
  closing_costs_dollar: "",
  closing_costs_percent: "",
  loan_amount_dollar: "",
  loan_amount_percent: "",
  loans: [
    { name: "First Mortgage", amount: "", rate: "" },
    { name: "Mezzanine Loan", amount: "", rate: "" },
    { name: "Pref Equity", amount: "", rate: "" },
  ],
  debt_yield: "7",
  total_capitalization: "",
};

const defaultMortgageDscr = {
  loan_amount: "",
  interest_rate: "",
  init_loan_term_yrs: "",
  io_years: "",
  amortization_years: "",
  bank_minimum_DSCR: "1.20",
  bank_max_loan_during_io: "",
  bank_max_loan_post_io: "",
  amortization_schedule: [],
};

const defaultIrr = {
  holding_period_yrs: "",
  projected_gross_income_rate: 3,
  stabilized_vacancy: 5,
  projected_annual_expense: 2,
  projected_cap_rate_at_sale: "",
  projected_closing_costs_at_sale: "",
  year_of_stabilization: "",
};

const defaultIrrCapitalEvent = {
  hasCapitalEvent: false,
  capitalEventType: "supplemental",
  capitalEventYear: "",
  capitalEventCapRate: "",
  capitalEventLoanToValue: "",
  capitalEventDSCR: "",
  capitalEventInterestRate: "",
  capitalEventAmortizationYears: "",
  capitalEventLoanAmount: "",
  capitalEventNetLoan: "",
  capitalEventClosingCostDollars: "",
  capitalEventClosingCostPercent: "",
};

const defaultEquityWaterfall = {
  gpPercent: 10,
  gpDollars: "",
  lpPercent: 90,
  lpDollars: "",
  hurdles: [
    {
      startPercent: "0",
      endPercent: "",
      promotePercent: "",
    },
  ],
};

const defaultCashOnCash = {
  equity_needed: "",
  first_year_cash_flow: "",
};

const defaultIrrSchedule = {
  pgi: [],
  stabilized_vacancies: [],
  effective_gross_income: [],
  expenses: [],
  noi: [],
  pi_payments: [],
  net_cash_flow: [],
  sale_price: 0,
  closing_costs: 0,
  net_sale_price: 0,
  mortgage_balance: 0,
  net_distribution_from_sale: 0,
  total_distribution_to_sale: 0,
  grand_total_distribution: 0,
  irr: 0,
  wasScheduleOpened: false,
};

const defaultItemizations = {
  potential_gross_income: {
    residential_rents: {
      monthly: 0,
      annual: 0,
    },
    commercial_rents: {
      monthly: 0,
      annual: 0,
    },
    parking: {
      monthly: 0,
      annual: 0,
    },
    other_income: {
      monthly: 0,
      annual: 0,
    },
  },
  vacancy: {
    physical_vacancy: {
      dollar: 0,
      percent: 0,
    },
    "collection/Rent Loss": {
      dollar: 0,
      percent: 0,
    },
    underwriting_vacancy: {
      dollar: 0,
      percent: 0,
    },
  },
  annual_expenses: {
    real_estate_taxes: {
      monthly: 0,
      annual: 0,
    },
    management_fees: {
      monthly: 0,
      annual: 0,
    },
    capex: {
      monthly: 0,
      annual: 0,
    },
    insurance: {
      monthly: 0,
      annual: 0,
    },
  },
  closing_costs: {
    appraisal: {
      dollar: 0,
    },
    environmental: {
      dollar: 0,
    },
    engineering: {
      dollar: 0,
    },
    "borrower’s_legal": {
      dollar: 0,
    },
    bank_legal: {
      dollar: 0,
    },
    mortgage_broker: {
      dollar: 0,
      percent: 0,
    },
    bank_fee: {
      dollar: 0,
      percent: 0,
    },
    sales_broker: {
      dollar: 0,
      percent: 0,
    },
    capex_reserve: {
      dollar: 0,
    },
    interest_reserve: {
      dollar: 0,
    },
    title: {
      dollar: 0,
    },
    other: {
      dollar: 0,
    },
  },
  closing_costs_capital_event: {
    appraisal: {
      dollar: 0,
    },
    environmental: {
      dollar: 0,
    },
    engineering: {
      dollar: 0,
    },
    "borrower’s_legal": {
      dollar: 0,
    },
    bank_legal: {
      dollar: 0,
    },
    mortgage_broker: {
      dollar: 0,
      percent: 0,
    },
    bank_fee: {
      dollar: 0,
      percent: 0,
    },
    capex_reserve: {
      dollar: 0,
    },
    interest_reserve: {
      dollar: 0,
    },
    title: {
      dollar: 0,
    },
    other: {
      dollar: 0,
    },
  },
};

const defaultUserItemizationEntryMethods = {
  potential_gross_income: {},
  vacancy: {},
  annual_expenses: {},
  closing_costs: {},
  closing_costs_capital_event: {},
};

const defaultPercentOfFields = {
  closing_costs: {
    mortgage_broker: "loan_amount_dollar",
    bank_fee: "loan_amount_dollar",
    sales_broker: "purchase_price",
  },
};

export const initialState = {
  dirty: false,
  capRate: defaultCapRate,
  equityNeeded: defaultEquityNeeded,
  mortgageDscr: defaultMortgageDscr,
  cashOnCash: defaultCashOnCash,
  irr: defaultIrr,
  irrCapitalEvent: defaultIrrCapitalEvent,
  equityWaterfall: defaultEquityWaterfall,
  resultsValues: {}, // this will display the results
  // store the name of the calculator the user is entering. Needed for the mobile slider.
  activeCalculator: "",
  // this will store the method in which the user entered a value
  // that can be entered multiple ways. ie as dollar or percent
  userEntryMethods: {},
  userItemizationEntryMethods: defaultUserItemizationEntryMethods,
  irrSchedule: defaultIrrSchedule,
  openCalcs: [],
  itemizations: defaultItemizations,
  // Some itemizations allow the user to toggle the percent
  // off of different fields
  itemizationsPercentOf: defaultPercentOfFields,
};

export default function eCalcFormReducer(state = initialState, action) {
  switch (action.type) {
    case types.ECALC_TOGGLE_IS_DIRTY:
      return {
        ...state,
        dirty: true,
      };
    case types.ECALC_ON_CHANGE:
      return {
        ...state,
        [action.calculator]: {
          ...state[action.calculator],
          [action.name]: action.value,
        },
      };
    case types.ECALC_ACTIVE_CALCULATOR:
      return {
        ...state,
        activeCalculator: action.name,
      };
    case types.ECALC_ON_MULTI_CHANGE:
      return {
        ...state,
        [action.calculator]: {
          ...state[action.calculator],
          ...action.valuesObj,
        },
      };
    case types.ECALC_ADD_ENTRY_METHOD:
      return {
        ...state,
        userEntryMethods: {
          ...state.userEntryMethods,
          [action.name]: action.value,
        },
      };
    case types.ECALC_ADD_ITEMIZATION_ENTRY_METHOD:
      return {
        ...state,
        userItemizationEntryMethods: {
          ...state.userItemizationEntryMethods,
          [action.itemizationName]: {
            ...state.userItemizationEntryMethods[action.itemizationName],
            [action.item]: action.value,
          },
        },
      };
    case types.ECALC_DELETE_ITEMIZATION_ENTRY_METHOD:
      const {
        [action.item]: itemToDelete,
        ...otherItems
      } = state.userItemizationEntryMethods[action.itemizationName];
      return {
        ...state,
        userItemizationEntryMethods: {
          ...state.userItemizationEntryMethods,
          [action.itemizationName]: {
            ...otherItems,
          },
        },
      };
    case types.ECALC_ADD_RESULTS:
      return {
        ...state,
        resultsValues: {
          ...state.resultsValues,
          [action.name]: action.value,
        },
      };
    case types.ECALC_REMOVE_RESULTS:
      const { [action.name]: itemToRemove, ...rest } = state.resultsValues;
      return {
        ...state,
        resultsValues: rest,
      };
    case types.UPDATE_ITEMIZATION:
      return {
        ...state,
        itemizations: {
          ...state.itemizations,
          [action.name]: action.value,
        },
      };
    case types.UPDATE_ITEMIZATION_PERCENT_OF:
      return {
        ...state,
        itemizationsPercentOf: {
          ...state.itemizationsPercentOf,
          [action.itemizationName]: {
            ...state.itemizationsPercentOf[action.itemizationName],
            [action.item]: action.value,
          },
        },
      };
    case types.ECALC_DELETE_ITEMIZATION_PERCENT_OF:
      const {
        [action.item]: fieldToDelete,
        ...otherFields
      } = state.itemizationsPercentOf[action.itemizationName];
      return {
        ...state,
        itemizationsPercentOf: {
          ...state.itemizationsPercentOf,
          [action.itemizationName]: {
            ...otherFields,
          },
        },
      };
    case types.IRR_SCHEDULE_CHANGE:
      return {
        ...state,
        irrSchedule: {
          ...state.irrSchedule,
          pgi: action.pgi,
          stabilized_vacancies: action.stabilized_vacancies,
          effective_gross_income: action.effective_gross_income,
          expenses: action.expenses,
          noi: action.noi,
          pi_payments: action.pi_payments,
          net_cash_flow: action.net_cash_flow,
          sale_price: action.sale_price,
          closing_costs: action.closing_costs,
          net_sale_price: action.net_sale_price,
          mortgage_balance: action.mortgage_balance,
          net_distribution_from_sale: action.net_distribution_from_sale,
          total_distribution_to_sale: action.total_distribution_to_sale,
          grand_total_distribution: action.grand_total_distribution,
          irr: action.irr,
          capitalEventPiPayments: action.capitalEventPiPayments,
          capitalEventNetCashFlow: action.capitalEventNetCashFlow,
          capitalEventMortgageBalance: action.capitalEventMortgageBalance,
          capitalEventNetDistFromSale: action.capitalEventNetDistFromSale,
          capitalEventTotalDistToSale: action.capitalEventTotalDistToSale,
          capitalEventGrandTotalDistFromSale:
            action.capitalEventGrandTotalDistFromSale,
          capitalEventIRR: action.capitalEventIRR,
          ROI: action.ROI,
          yieldOnCost: action.yieldOnCost,
          avgCashOnCash: action.avgCashOnCash,
        },
      };
    case types.IRR_SCHEDULE_OPENED:
      return {
        ...state,
        irrSchedule: {
          ...state.irrSchedule,
          wasScheduleOpened: true,
        },
      };
    case types.CLEAR_BLENDED_RATE_ITEMIZATION:
      return {
        ...state,
        equityNeeded: {
          ...state.equityNeeded,
          loan_amount_dollar: "",
          loan_amount_percent: "",
          loans: initialState.equityNeeded.loans,
        },
        mortgageDscr: {
          ...state.mortgageDscr,
          interest_rate: "",
        },
      };
    case types.CLEAR_ECALC:
      return {
        ...initialState,
      };
    case types.CLEAR_IRR_CAPITAL_EVENT:
      return {
        ...state,
        irrCapitalEvent: defaultIrrCapitalEvent,
      };
    case types.SET_OPEN_CALCULATORS:
      return {
        ...state,
        openCalcs: action.payload,
      };
    default:
      return state;
  }
}
