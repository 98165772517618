import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./BlendedRateItemization.style";
// import { calcTotalLoanAndBlendedRate } from "./BlendedRateItemization.logic";
import clsx from "clsx";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { eCalcOnChange, addUserEntryMethod } from "../../../actions/eCalcForms";
import { eCalcForms } from "../../../lib";
import _cloneDeep from "lodash.clonedeep";
import { RatesModal } from "./RatesModal";
import {
  CloseIcon,
  CreateOutlinedIcon,
  SignalCellularAltIcon,
} from "../../../themes";
import {
  parseToNumber,
  noDecimal,
  entryMethodNames,
  entryMethods,
} from "../helpers";

export function BlendedRateItemization(props) {
  const {
    loans = [],
    blendedRate,
    totalLoan,
    // totalLoanPercentage,
    setLoans,
    onClear,
    onClose,
    persist,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function handleInputChange(name, value, index) {
    const newLoans = _cloneDeep(loans);
    newLoans[index][name] = formatInput(name, value);
    setLoans(newLoans);
  }

  function validate() {
    return loans.every((loan) => {
      return (
        (loan.amount.length && loan.rate.length) ||
        (!loan.amount.length && !loan.rate.length)
      );
    });
  }

  function save() {
    const isValid = validate();
    if (isValid) {
      dispatch(
        eCalcOnChange({
          calculator: eCalcForms.EQUITY_NEEDED,
          name: "loans",
          value: loans,
          persist,
        })
      );
      dispatch(
        eCalcOnChange({
          calculator: eCalcForms.EQUITY_NEEDED,
          name: "loan_amount_dollar",
          value: noDecimal(parseToNumber(totalLoan)),
          persist,
        })
      );
      dispatch(
        addUserEntryMethod({
          name: entryMethodNames.LOAN_AMOUNT,
          value: entryMethods.DOLLAR_AMOUNT,
          persist,
        })
      );
      dispatch(
        eCalcOnChange({
          calculator: eCalcForms.MORTGAGE_AND_DSCR,
          name: "interest_rate",
          value: blendedRate,
          persist,
        })
      );
      onClose();
    }
  }

  const [showRatesModal, setShowRatesModal] = useState(false);

  const openRatesModal = useCallback(() => {
    setShowRatesModal(true);
  }, []);

  const closeRatesModal = useCallback(() => {
    setShowRatesModal(false);
  }, []);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      scroll={"body"}
      fullScreen={fullScreen}
    >
      <DialogTitle
        id="simple-dialog-title"
        className={classes.dialogTitle}
        disableTypography={true}
      >
        <CloseIcon className={classes.closeBtn} onClick={onClose} />
        <span>Blended Mortgages</span>
      </DialogTitle>
      <Box className={classes.totalsBox}>
        <div>
          <p className={classes.totalsLbl}>Total Loan Amount:</p>
          <p className={classes.total}>${totalLoan}</p>
        </div>

        <span
          className={classes.inputsDivider}
          style={{ height: 0, flexBasis: 100 }}
        />
        <div>
          <p className={classes.totalsLbl}>Blended Rate:</p>
          <p className={classes.total}>{blendedRate}%</p>
        </div>
      </Box>
      <DialogContent className={classes.dialogContent}>
        {loans.map((loan, index) => {
          return (
            <Loan
              key={index}
              index={index}
              loan={loan}
              handleInputChange={handleInputChange}
              openRatesModal={openRatesModal}
            />
          );
        })}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          classes={{
            label: classes.whiteButtonLabel,
            root: classes.whiteButtonRoot,
          }}
          onClick={onClear}
        >
          Clear
        </Button>
        <Button
          type="submit"
          classes={{
            label: classes.primaryButtonLabel,
            root: classes.primaryButtonRoot,
          }}
          onClick={(e) => save(e)}
        >
          Add
        </Button>
      </DialogActions>
      <RatesModal
        open={showRatesModal}
        handleOpen={openRatesModal}
        handleClose={closeRatesModal}
      />
    </Dialog>
  );
}

function formatInput(name, value) {
  switch (name) {
    case "amount":
      return noDecimal(parseToNumber(value));
    case "rate":
      let regexExp = new RegExp("^\\d*(\\.\\d{0,3})?$");
      if (!value.match(regexExp)) {
        value = value.slice(0, -1);
      }
      return value;
    default:
      return value;
  }
}

export function Loan({ loan, index, handleInputChange, openRatesModal }) {
  const classes = useStyles();
  const [showLoanNameModal, setShowLoanNameModal] = useState(false);
  const [name, setName] = useState(loan.name);

  function save() {
    handleInputChange("name", name, index);
    setShowLoanNameModal(false);
  }

  function cancel() {
    setName(loan.name);
    setShowLoanNameModal(false);
  }
  return (
    <div className={classes.loanContainer}>
      <div className={classes.loanName}>
        {loan.name}
        <CreateOutlinedIcon
          className={classes.blueEditIcon}
          onClick={() => setShowLoanNameModal(true)}
        />
      </div>
      <div
        className={classes.lineInputsContainer}
        style={{ alignItems: "flex-start" }}
      >
        <TextField
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          className={clsx(classes.margin, classes.smallInput)}
          label="Loan Amount:"
          variant="outlined"
          value={loan.amount}
          onChange={(e) =>
            handleInputChange(e.target.name, e.target.value, index)
          }
          name="amount"
          required={!!loan.rate.length && !loan.amount.length}
          error={!!loan.rate.length && !loan.amount.length}
          helperText={!!loan.rate.length && !loan.amount.length && "required"}
          InputProps={{
            classes: { input: classes.input },
            startAdornment: (
              <InputAdornment
                position="start"
                classes={{ root: classes.inputAdornment }}
              >
                $
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            classes: { root: classes.inputLabel, shrink: "shrink" },
          }}
        />
        <span
          className={classes.inputsDivider}
          style={{ height: 0, flexBasis: 24 }}
        />
        <TextField
          inputProps={{ inputMode: "decimal", pattern: "[0-9]*" }}
          className={clsx(classes.margin, classes.smallInput)}
          label={
            <span style={{ display: "flex" }}>
              Interest Rate:
              <SignalCellularAltIcon
                className={classes.rateIcon}
                onClick={openRatesModal}
              />
            </span>
          }
          variant="outlined"
          value={loan.rate}
          onChange={(e) =>
            handleInputChange(e.target.name, e.target.value, index)
          }
          name="rate"
          required={!!loan.amount.length && !loan.rate.length}
          error={!!loan.amount.length && !loan.rate.length}
          helperText={!!loan.amount.length && !loan.rate.length && "required"}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                classes={{ root: classes.inputAdornment }}
              >
                %
              </InputAdornment>
            ),
            classes: { input: classes.inputWithPadding },
          }}
          InputLabelProps={{
            classes: { root: classes.inputLabel, shrink: "shrink" },
            shrink: true,
          }}
        />
      </div>
      {showLoanNameModal && (
        <Dialog open={true}>
          <DialogTitle
            className={classes.innerDialogTitle}
            disableTypography={true}
          >
            <span>Edit</span>
            <CloseIcon
              onClick={() => setShowLoanNameModal(false)}
              className={classes.innerDialogCloseBtn}
            />
          </DialogTitle>
          <DialogContent>
            <TextField
              className={clsx(classes.margin, classes.smallInput)}
              label="Name:"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="name"
              InputProps={{
                classes: { input: classes.inputWithPadding },
              }}
              InputLabelProps={{
                classes: { root: classes.inputLabel, shrink: "shrink" },
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button className={classes.cancelLinkBtn} onClick={() => cancel()}>
              Cancel
            </Button>
            <Button className={classes.saveLinkBtn} onClick={() => save()}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
