import * as types from "../actionTypes";

function updatePIandDSCR(values) {
  return { type: types.UPDATE_PI_AND_DSCR, values };
}

export function calculatePIandDSCR(values, trigger) {
  return function (dispatch) {
    calculate(values, trigger);
    dispatch(updatePIandDSCR(values));
  };
}

function calculate(values, trigger) {
  if (trigger.name === "amortizationYears") {
    values.amortizationYears = trigger.value;
    values.amortizationMonths = trigger.value * 12;
  } else if (trigger.name === "amortizationMonths") {
    values.amortizationMonths = trigger.value;
    values.amortizationYears = trigger.value / 12;
  }

  if (
    values.loanAmount.length &&
    values.interestRate &&
    (values.amortizationMonths || values.amortizationYears)
  ) {
    const var1 =
      values.loanAmount *
      (values.interestRate / 1200) *
      Math.pow(1 + values.interestRate / 1200, values.amortizationMonths);
    const var2 =
      Math.pow(1 + values.interestRate / 1200, values.amortizationMonths) - 1;
    values.monthlyPaymentsAmortizationPeriod = var1 / var2;
    values.annualPaymentsAmortizationPeriod = (var1 / var2) * 12;

    if (values.interestOnly) {
      values.monthlyPaymentsInterestOnlyPeriod =
        (values.loanAmount * (values.interestRate / 100)) / 12;
      values.annualPaymentsInterestOnlyPeriod =
        values.monthlyPaymentsInterestOnlyPeriod * 12;
    } else {
      values.monthlyPaymentsInterestOnlyPeriod = 0;
      values.annualPaymentsInterestOnlyPeriod = 0;
    }

    if (values.initialTerm) {
      const rateDiv12 = values.interestRate / 100 / 12;
      const termMult12 = values.initialTerm * 12;
      const interestMult12 = values.interestOnly * 12;

      const var1 =
        values.loanAmount *
        Math.pow(1 + rateDiv12, termMult12 - interestMult12);
      const var2 =
        ((rateDiv12 * values.loanAmount) /
          (1 - Math.pow(1 + rateDiv12, -values.amortizationMonths)) /
          rateDiv12) *
        (Math.pow(1 + rateDiv12, termMult12 - interestMult12) - 1);
      values.balanceAtMaturity = var1 - var2;
    } else {
      values.balanceAtMaturity = 0;
    }

    if (values.annualUnderwrittenNOI) {
      values.debtServiceCoverageRatio =
        values.annualUnderwrittenNOI /
        (values.monthlyPaymentsAmortizationPeriod * 12);
    } else {
      values.debtServiceCoverageRatio = 0;
    }
  } else {
    values.monthlyPaymentsAmortizationPeriod = 0;
    values.annualPaymentsAmortizationPeriod = 0;
    values.monthlyPaymentsInterestOnlyPeriod = 0;
    values.annualPaymentsInterestOnlyPeriod = 0;
    values.balanceAtMaturity = 0;
    values.debtServiceCoverageRatio = 0;
  }
}
