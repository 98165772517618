import React from "react";
import { Link } from "react-router-dom";
import Form from "muicss/lib/react/form";
import Input from "muicss/lib/react/input";
import Button from "muicss/lib/react/button";
import MultiSelectCheckbox from "../common/MultiSelectCheckbox";

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      company: "",
      user_types: ["client"],
      password: null,
      confirmPassword: null,
      errorMessage: "",
      showUserTypeDropdown: false,
      submitDisabled: false
    };

    this.validatePhone = this.validatePhone.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleSignUpSubmit = this.handleSignUpSubmit.bind(this);
    this.userTypeToggleDropdown = this.userTypeToggleDropdown.bind(this);
  }

  handleSignUpSubmit(e) {
    e.preventDefault();
    this.setState({
      submitDisabled: true,
    });
    if (
      !this.state.phone ||
      !this.state.email ||
      !this.state.first_name ||
      !this.state.last_name ||
      !this.state.password ||
      !this.state.confirmPassword
    ) {
      this.setState({
        errorMessage: "All required fields must be complete",
        submitDisabled: false,
      });
    } else {
      this.setState({ errorMessage: "" });
      if (
        !this.state.invalidPhoneMessage &&
        !this.state.invalidEmailMessage &&
        !this.state.invalidPasswordMessage &&
        !this.state.confirmMessage &&
        !this.props.invalidPasswordMessage.length > 0
      ) {
        this.props.signUp(
          this.state.email,
          this.state.first_name,
          this.state.last_name,
          this.state.phone,
          this.state.company,
          this.state.password,
          this.state.user_types
        );
      }
    }
  }

  handleFirstNameChange(e) {
    this.setState({
      first_name: e.target.value,
    });
  }

  handleLastNameChange(e) {
    this.setState({
      last_name: e.target.value,
    });
  }

  validateEmail(value) {
    var re = /[^@]+@[^@]+\.[^@]+/;
    return re.test(value);
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
    const result = this.validateEmail(e.target.value);
    if (!result && e.target.value !== "") {
      this.setState({
        invalidEmailMessage: "Not a valid email",
      });
    }
    if (result) {
      this.setState({
        invalidEmailMessage: null,
      });
    }
  }

  validatePhone(value) {
    var re = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    return re.test(value);
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
    const result = this.validatePhone(e.target.value);
    if (!result && e.target.value !== "") {
      this.setState({
        invalidPhoneMessage: "Not a valid phone number",
      });
    } else {
      this.setState({
        invalidPhoneMessage: null,
      });
    }
  }

  handleCompanyChange(e) {
    this.setState({
      company: e.target.value,
    });
  }

  userTypeToggleDropdown() {
    this.setState({ showUserTypeDropdown: !this.state.showUserTypeDropdown });
  }

  userTypeCheckboxChange(type, isChecked) {
    let userTypes = this.state.user_types;

    if (userTypes.length === 1 && !isChecked) {
      return;
    }
    isChecked
      ? (userTypes = [...userTypes, type])
      : (userTypes = userTypes.filter((t) => t !== type));

    this.setState({ user_types: userTypes });
  }

  validatePassword(value) {
    var re = /(?=.{8,})/;
    return re.test(value);
  }

  setPassword(event) {
    this.setState({
      password: event.target.value,
    });
    const result = this.validatePassword(event.target.value);
    if (!result) {
      this.setState({
        invalidPasswordMessage: "Password must be 8 characters",
      });
    }
    if (result) {
      this.setState({
        invalidPasswordMessage: null,
      });
    }
  }

  setConfirmPassword(event) {
    this.setState(
      {
        confirmPassword: event.target.value,
      },
      function () {
        if (this.state.password !== this.state.confirmPassword) {
          this.setState({
            confirmMessage: "Passwords do not match",
          });
        } else {
          this.setState({
            confirmMessage: null,
          });
        }
      }
    );
  }

  onPasswordBlur = async () => {
    this.props.validatePassword(this.state.password);
  };

  render() {
    let userTypeArray = [
      { label: "GP/Buyer", value: "client" },
      { label: "Lender", value: "lender" },
      { label: "Sales Broker", value: "sales broker" },
      { label: "LP/Investor", value: "investor" },
    ];

    let userTypeText = "";
    this.state.user_types.forEach((type, index) => {
      let userType = userTypeArray.find((t) => t.value === type);
      if (userType) {
        if (index >= 1) {
          userTypeText += ", ";
        }
        userTypeText += userType.label;
      }
    });

    userTypeArray = userTypeArray.map((type, index) => (
      <MultiSelectCheckbox
        key={index}
        onChange={this.userTypeCheckboxChange.bind(this)}
        isChecked={this.state.user_types.find((t) => t === type.value)}
        id={type.value}
        label={type.label}
      />
    ));

    return (
      <div className="account-activation-page">
        <div className="account-activation-logo">
          <Link to="/">
            <img
              id="logo"
              src={"/images/eastern-union-white-small.png"}
              alt="Eastern Union Logo"
            />
          </Link>
        </div>
        <div className="account-activation-panel panel">
          <p className="account-activation-header">Register</p>
          <Form className="register-form">
            <Input
              autoFocus
              label="First Name"
              required={true}
              floatingLabel={true}
              onChange={this.handleFirstNameChange}
            />
            <Input
              label="Last Name"
              required={true}
              floatingLabel={true}
              onChange={this.handleLastNameChange}
            />
            <Input
              label="Email Address"
              required={true}
              floatingLabel={true}
              onChange={this.handleEmailChange}
            />
            <span className="error-block">
              {this.state.invalidEmailMessage}
            </span>
            <Input
              label="Phone Number"
              required={true}
              floatingLabel={true}
              onChange={this.handlePhoneChange}
            />
            <span className="error-block">
              {this.state.invalidPhoneMessage}
            </span>
            <Input
              label="Company (optional)"
              required={false}
              floatingLabel={true}
              onChange={this.handleCompanyChange}
            />

            <div
              onClick={this.userTypeToggleDropdown}
              className="contact-fake-select input-left"
            >
              <Input
                onChange={() => ({})}
                hint="User Types"
                value={userTypeText}
                disabled
                required={true}
              />
              <i
                onClick={this.userTypeToggleDropdown}
                className="material-icons"
              >
                arrow_drop_down
              </i>
              <div
                className={
                  this.state.showUserTypeDropdown
                    ? "user-dropdown-box mui--z1"
                    : "hidden"
                }
              >
                {userTypeArray}
              </div>
            </div>
            <Input
              type="password"
              label="Password"
              required={true}
              floatingLabel={true}
              onBlur={() => this.onPasswordBlur()}
              onChange={this.setPassword.bind(this)}
            />
            <span className="error-block">
              {this.props.invalidPasswordMessage ||
                this.state.invalidPasswordMessage}
            </span>
            <Input
              type="password"
              label="Confirm Password"
              required={true}
              floatingLabel={true}
              onChange={this.setConfirmPassword.bind(this)}
            />
            <span className="error-block">{this.state.confirmMessage}</span>
          </Form>
          <Button onClick={this.handleSignUpSubmit} disabled={this.state.submitDisabled}>Create Account</Button>
          <p className="error-block">{this.state.errorMessage}</p>
          <div id="message">{this.props.message}</div>
          <div className="account-activation-panel-footer text-center">
            <Link
              to="/login"
              className="xs-text orange-hover accent-text-light"
            >
              Already a member? Login
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
export default RegisterForm;
