import * as types from "../actions/actionTypes";

const initialState = {
  uwDeals: [],
  loading: true,
  contact: {},
  loadingContact: false,
  suggestions: [],
  toast: {
    show: false,
    success: true,
    message: "",
  },
};

export default function uwDealReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_UW_DEALS:
      return Object.assign({}, state, { loading: true });
    case types.LOAD_UW_DEALS:
      return Object.assign({}, state, {
        uwDeals: action.uwDeals,
        suggestions: action.uwDeals,
        loading: false,
      });
    case types.LOADING_FULL_CONTACT:
      return Object.assign({}, state, { contact: {}, loadingContact: true });
    case types.LOAD_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: action.contact,
        loadingContact: false,
      });
    case types.UPDATE_SEARCH_UW_DEALS:
      return Object.assign({}, state, { suggestions: action.suggestions });
    case types.LOADING_UPDATE_STAR:
      return Object.assign({}, state, { loading: true });
    case types.UW_STAR_CHANGE_COMPLETED:
      let suggestionsList = [...state.suggestions];
      suggestionsList.find(
        (i) => i.deal_id === action.deal_id
      ).broker_starred = parseInt(action.broker_starred, 10);
      return Object.assign({}, state, {
        suggestions: suggestionsList,
        loading: false,
      });
    default:
      return state;
  }
}
