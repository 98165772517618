/* The screen that shows the function and value */

import React from "react";

export default class Screen extends React.Component {
  render() {
    return (
      <div className="screen">
        <textarea placeholder="0" value={this.props.calc} />
      </div>
    );
  }
}
