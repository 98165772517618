import React from "react";
// import {connect} from 'react-redux';
import HomePage from "./HomePage";
import { Navigation } from "../../lib";

export default class HomePageContainer extends React.Component {
  state = {
    isLoggedIn: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (
      localStorage.getItem("auth_timestamp") &&
      localStorage.getItem("auth_token")
    ) {
      this.setState({
        isLoggedIn: true,
      });
    } else {
      Navigation.redirect("/rate-sheet");
    }
  }
  render() {
    const { isLoggedIn } = this.state;
    if (!isLoggedIn) {
      return null;
    }
    return <HomePage userData={JSON.parse(localStorage.getItem("data"))} />;
  }
}
