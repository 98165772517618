import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../../actions/authenticationActions";
import Input from "muicss/lib/react/input";
import Button from "muicss/lib/react/button";
import getParameterByName from "../common/getParameterByName";

class AccntActivationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorMessage: "",
    };

    this.resendActivation = this.resendActivation.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  componentDidMount() {
    this.props.actions.activate(getParameterByName("activation_hash"));
  }

  resendActivation(e) {
    e.preventDefault();
    if (!this.state.email || !this.state.password) {
      this.setState({
        errorMessage: "All fields must be complete",
      });
    } else {
      this.props.actions.resendActivation(
        this.state.email,
        this.state.password
      );
      this.setState({ errorMessage: "" });
    }
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  render() {
    return (
      <div className="account-activation-background">
        <div className="aa-logo">
          <img src="/images/gs-logo.png" alt="gs-logo" />
        </div>

        <div className="account-activation-panel small short panel">
          <div>
            {this.props.auth.activating ? (
              <div>
                <p className="bold-text large-text text-center">
                  Activating Account...
                </p>
                <div className="centered-spinner">
                  <img
                    src="/images/icons/spin.svg"
                    className="loading-spinner"
                    alt="loading spinner"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {this.props.auth.activationSuccess === false ? (
              this.props.auth.linkExpired ? (
                <div>
                  <div>
                    <p className="bold-text large-text text-center">
                      Activation Link Expired
                    </p>
                    <p className="accent-text-dark small-text">
                      Request another activation link to complete account setup
                    </p>
                  </div>
                  <div>
                    <Input
                      label="Email"
                      floatingLabel={true}
                      onChange={this.handleEmailChange}
                    />
                    <Input
                      label="Password"
                      type="password"
                      floatingLabel={true}
                      onChange={this.handlePasswordChange}
                    />
                  </div>
                  <p className="error-block">{this.state.errorMessage}</p>
                  {this.props.auth.resendMessage ? (
                    <div id="message">{this.props.auth.resendMessage}</div>
                  ) : (
                    ""
                  )}
                  <Button
                    variant="flat"
                    className="button btn-accent btn-fullWidth mui--z1"
                    onClick={this.resendActivation}
                  >
                    Request Reset Link
                  </Button>
                </div>
              ) : (
                <div>
                  <div>
                    <p className="bold-text large-text text-center">
                      Account Activation Failed!
                    </p>
                  </div>
                  <p className="accent-text-dark small-text margin-bottom">
                    {this.props.auth.message}.
                    <br />
                    <br />
                    Please reach out to
                    <br />
                    <a
                      href="mailto:app@easternunion.com"
                      className="orange-text"
                    >
                      app@easternunion.com
                    </a>{" "}
                    for assistance
                  </p>

                  <p className="accent-text-dark small-text margin-bottom">
                    <a href="/login" className="orange-hover">
                      Login
                    </a>
                    /
                    <a href="/forgot-password" className="orange-hover">
                      Forgot Password
                    </a>
                  </p>
                </div>
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccntActivationPage);
