import React from "react";
import Row from "muicss/lib/react/row";
import { NavLink } from "react-router-dom";

class DocsHeader extends React.Component {
  render() {
    return (
      <div className="docs-page">
        <Row className="sub-header">
          <div className="subheader">
            <ul>
              <li>
                <NavLink
                  to={`/docs/setup`}
                  activeClassName="header-active-link"
                >
                  Setup
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/docs/brokerage`}
                  activeClassName="header-active-link"
                >
                  Brokerage
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/docs/marketing`}
                  activeClassName="header-active-link"
                >
                  Marketing
                </NavLink>
              </li>
              <li>
                <NavLink to={`/docs/misc`} activeClassName="header-active-link">
                  Misc
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/docs/email-templates`}
                  activeClassName="header-active-link"
                >
                  Email Templates
                </NavLink>
              </li>
            </ul>
          </div>
        </Row>
        <div className="docs-content-container">{this.props.children}</div>
      </div>
    );
  }
}

export default DocsHeader;
