import * as types from "../actions/actionTypes";

const initialState = {
  users: [],
};

export default function dealReviewReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_DEAL_REVIEW_USERS:
      return Object.assign({}, state, { users: action.data });
    case types.GET_PROPERTY_TYPES_LIST:
      return Object.assign({}, state, { propertyTypes: action.data });
    case types.GET_DEAL_TYPES_LIST:
      return Object.assign({}, state, { dealTypes: action.data });
    case types.GET_LOAN_TYPES_LIST:
      return Object.assign({}, state, { loanTypes: action.data });
    case types.GET_STATES_LIST:
      return Object.assign({}, state, { states: action.data });
    case types.GET_DEAL_STATUSES_LIST:
      return Object.assign({}, state, { dealStatuses: action.data });
    case types.LOADING_DEAL_REVIEW:
      return Object.assign({}, state, { loading: true });
    case types.GET_DEAL_REVIEW_RESULTS:
      return Object.assign({}, state, { deals: action.data, loading: false });
    case types.GET_DEAL_BANK_QUOTES:
      return Object.assign({}, state, {
        dealDetails: { bankQuotes: action.data },
      });
    case types.UPDATE_DEAL_REVIEW_SUCCESS:
      return Object.assign({}, state, {
        updateToast: { show: true, success: true, message: action.message },
      });
    case types.GET_UPDATED_DEAL_REVIEW:
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          results: state.deals.results.map((deal) =>
            deal.deal_id === action.data[0].deal_id
              ? { ...action.data[0] }
              : deal
          ),
        },
      });
    case types.GET_UPDATED_DEAL_REVIEW_PROPERTY:
      return Object.assign({}, state, {
        deals: {
          ...state.deals,
          results: state.deals.results.map((deal) =>
            deal.deal_id === action.data.dealID
              ? {
                  ...deal,
                  properties: deal.properties.map((property) =>
                    property.property_id === action.data.property[0].property_id
                      ? {
                          ...action.data.property[0],
                        }
                      : property
                  ),
                }
              : deal
          ),
        },
      });
    case types.UPDATE_DEAL_REVIEW_FAILED:
      return Object.assign({}, state, {
        updateToast: { show: true, success: false, message: action.message },
      });
    case types.CLOSE_UPDATE_DEAL_REVIEW_TOAST:
      return Object.assign({}, state, {
        updateToast: { show: false, success: true, message: "" },
      });
    case types.LOADING_FULL_CLIENT_INFO:
      return Object.assign({}, state, {
        client: { loading: true },
      });
    case types.GET_FULL_CLIENT_INFO:
      return Object.assign({}, state, {
        client: { details: action.data, loading: false },
      });
    case types.GET_DEAL_IDS_FROM_CUSTOM_REPORT:
      return Object.assign({}, state, {
        client: { loading: true },
      });
    case types.DEAL_IDS_FROM_CUSTOM_REPORT :
      return Object.assign({}, state, {
        dealIds: action.data,
        client : { loading:false}
      })
    default:
      return state;
  }
}
