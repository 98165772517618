import { KEY_PREFIX, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authentication from "./authenticationReducer";
import contacts from "./contactsReducer";
import comps from "./compsReducer";
import dealDetails from "./dealDetailsReducer";
import inProcessPipeline from "./inProcessPipelineReducer";
import investingCriteria from "./investingCriteriaReducer";
import ipDeals from "./ipDealReducer";
import navTitle from "./navigationTitleReducer";
import lendingCriteria from "./lendingCriteriaReducer";
import loans from "./loansReducer";
import missingQuotes from "./missingQuotesReducer";
import missingQuotesBroker from "./missingQuotesBrokerReducer";
// import newsFeed from "./newsFeedReducer";
import properties from "./propertiesReducer";
import profile from "./profileReducer";
import pwDeals from "./pwDealReducer";
import fpwDeals from "./fpwDealReducer";
import QTS from "./qtsReducer";
import rates from "./rateReducer";
import reports from "./reportsReducer";
import remainingDeals from "./remainingDealsReducer";
import spActiveDeals from "./serviceProviderReducer";
import basicCRE from "./calculatorReducers/basicCREReducer";
import capRate from "./calculatorReducers/capRateReducer";
import cashOnCash from "./calculatorReducers/cashOnCashReducer";
import debtYield from "./calculatorReducers/debtYieldReducer";
import interestOnly from "./calculatorReducers/interestOnlyReducer";
import maxLoan from "./calculatorReducers/maxLoanReducer";
import PIandDSCR from "./calculatorReducers/PIandDSCRReducer";
import simpleUnderwriting from "./calculatorReducers/simpleUnderwritingReducer";
import yieldMaintenance from "./calculatorReducers/yieldMaintenanceReducer";
import yieldMaintenanceLoading from "./calculatorReducers/yieldMaintenanceLoadingReducer";
import yieldMaintenanceRates from "./calculatorReducers/yieldMaintenanceRatesReducer";
import sharedCalculations from "./calculatorReducers/sharingCalculationReducer";
import reverseMortgage from "./calculatorReducers/reverseMortgageReducer";
import centralValues from "./calculatorReducers/centralReducer";
import IRR from "./calculatorReducers/IRRReducer";
import loanSyndication from "./calculatorReducers/loanSyndicationReducer";
import fundPortfolios from "./fundPortfolioReducer";
import docs from "./docsReducer";
import accounting from "./accountingReducer";
import searchDeals from "./searchDealsReducer";
import dealReview from "./dealReviewReducer";
import eCalcForm, { initialState as eCalcInitialState } from "./eCalcForms";
import eCalcFormsNoPersist from "./eCalcFormsNoPersist";
import uwDeals from "./uwDealReducer";
import ui from "./uiReducer";

const slices = {
  authentication,
  properties,
  contacts,
  missingQuotes,
  inProcessPipeline,
  investingCriteria,
  dealDetails,
  remainingDeals,
  spActiveDeals,
  rates,
  loans,
  // newsFeed,
  profile,
  navTitle,
  ipDeals,
  pwDeals,
  fpwDeals,
  comps,
  basicCRE,
  capRate,
  cashOnCash,
  debtYield,
  interestOnly,
  maxLoan,
  PIandDSCR,
  IRR,
  loanSyndication,
  simpleUnderwriting,
  yieldMaintenance,
  yieldMaintenanceLoading,
  yieldMaintenanceRates,
  centralValues,
  reverseMortgage,
  sharedCalculations,
  QTS,
  lendingCriteria,
  missingQuotesBroker,
  reports,
  fundPortfolios,
  docs,
  searchDeals,
  accounting,
  dealReview,
  eCalcForm,
  eCalcFormsNoPersist,
  uwDeals,
  ui,
};

const { NODE_ENV, REACT_APP_PERSIST_KEY = "app" } = process.env;

/** Slice names to prevent from storage. */
const blacklist = Object.keys(slices).filter((key) => key !== "eCalcForm");
// Preload state
const storageObject =
  (function preloadState() {
    // This is only possible since we're using localStorage which we can access
    // synchronously. It's simpler than using `redux-persist/PersistGate`.
    const storageKey = `${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`;
    const storageValue = localStorage.getItem(storageKey);
    return storageValue ? JSON.parse(storageValue) : undefined;
  })() || {};
/** States preloaded from localStorage by slice name. */
export const preloadedState = {
  eCalcForm: storageObject["eCalcForm"]
    ? { ...eCalcInitialState, ...JSON.parse(storageObject["eCalcForm"]) }
    : undefined,
};

export const rootReducer = persistCombineReducers(
  {
    blacklist,
    debug: NODE_ENV === "development",
    key: REACT_APP_PERSIST_KEY,
    storage,
  },
  slices
);
