import React, { useEffect } from "react";
// import clsx from "clsx";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { loadNewRates } from "../../../actions/rateSheetActions";
import { Dialog, IconButton, SwipeableDrawer } from "@material-ui/core";
import { GraphedIndex, PrimaryIndex, OtherIndex } from "../../newRates";
import { useMobile, CloseIcon } from "../../../themes";
import { useStyles } from "./RatesModal.styles";
import mixpanel from "mixpanel-browser";
import debounce from "lodash/debounce";

export function RatesModal({ open, handleOpen, handleClose }) {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const ecalcRates = useSelector(
    (state) => state.rates.ratesList,
    shallowEqual
  );

  useEffect(() => {
    dispatch(loadNewRates());
  }, [dispatch]);

  if (!Object.keys(ecalcRates).length) return null;
  return !isMobile ? (
    <DesktopModal
      open={open}
      handleClose={handleClose}
      ecalcRates={ecalcRates}
    />
  ) : (
    <MobileDrawer
      open={open}
      handleOpen={handleOpen}
      handleClose={handleClose}
      ecalcRates={ecalcRates}
    />
  );
}

function DesktopModal({ open, handleClose, ecalcRates }) {
  const classes = useStyles();

  const debounceTrack = debounce(() => {
    mixpanel.track("Rates Modal Scrolled");
  }, 200);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <IconButton onClick={handleClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <div className={classes.title}>Rates</div>
      <div className={classes.scrollableContent} onScroll={debounceTrack}>
        <GraphedIndex resource={ecalcRates.graphed_index} isRatesModal />
        <PrimaryIndex resource={ecalcRates.primary_index} isRatesModal />
        <div className={classes.moreRates}>More Rates</div>
        <OtherIndex resource={ecalcRates.other_index} isRatesModal />
      </div>
    </Dialog>
  );
}

function MobileDrawer({ open, handleOpen, handleClose, ecalcRates }) {
  const classes = useStyles();

  const debounceTrack = debounce(() => {
    mixpanel.track("Rates Modal Scrolled");
  }, 200);

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      disableSwipeToOpen
      disableDiscovery
      classes={{ paperAnchorBottom: classes.drawer }}
    >
      <div className={classes.mobileClose} />
      <div className={classes.title}>Rates</div>
      <div className={classes.scrollableContent} onScroll={debounceTrack}>
        <GraphedIndex resource={ecalcRates.graphed_index} isRatesModal />
        <PrimaryIndex resource={ecalcRates.primary_index} isRatesModal />
        <div className={classes.moreRates}>More Rates</div>
        <OtherIndex resource={ecalcRates.other_index} isRatesModal />
      </div>
    </SwipeableDrawer>
  );
}
