import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Drawer,
  IconButton,
  SwipeableDrawer,
  Button,
  Divider,
} from "@material-ui/core";
import { useMobile, CloseIcon, AddCircleOutlineIcon } from "../../../../themes";
import { useStyles } from "./SaveEcalcModal.styles";
import clsx from "clsx";
import { Notification } from "../../components";
import Property from "./Property";
import NewPropertyModal from "./NewPropertyModal";
import {
  saveEcalcToNewDeal,
  saveEcalcToExistingDeal,
  loadExistingProperties,
  setErrorMsg,
  clearErrorMsg,
  clearToolbarData,
} from "../../../../actions/eCalcForms";
import {
  getObjectKeyByValue,
  Navigation,
  DealStatusType,
} from "../../../../lib";
import { SiteLoader } from "../../../common/Loader";
import { parse } from "query-string";

export function SaveEcalcModal({ handleOpen, handleClose }) {
  const dispatch = useDispatch();
  const isMobile = useMobile();

  const close = () => {
    dispatch(clearToolbarData());
    handleClose();
  };

  return (
    <div>
      {!isMobile ? (
        <SaveEcalcDesktopModal
          open={true}
          handleClose={close}
          isMobile={isMobile}
        />
      ) : (
        <SaveEcalcMobileDrawer
          open={true}
          handleOpen={handleOpen}
          handleClose={close}
          isMobile={isMobile}
        />
      )}
    </div>
  );
}

function RenderContent({ isMobile, handleClose }) {
  const initialNotificationState = {
    show: false,
    message: "",
    buttonTxt: "",
    onClick: () => {},
  };
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const { hash_id } = parse(window.location.search);
  const [showNewPropertyModal, setShowNewPropertyModal] = useState(false);
  const [autocompleteValue, setAutocompleteValue] = useState(null);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [notification, setNotification] = useState(initialNotificationState);
  const ecalcValues = useSelector(
    (state) => state[hash_id ? "eCalcFormsNoPersist" : "eCalcForm"],
    shallowEqual
  );
  const saveEcalc = useSelector(
    (state) => state.eCalcFormsNoPersist.saveEcalc,
    shallowEqual
  );
  const properties = useSelector(
    (state) => state.eCalcFormsNoPersist.properties,
    shallowEqual
  );
  const saveDeal = useSelector(
    (state) => state.eCalcFormsNoPersist.saveDeal,
    shallowEqual
  );

  const { dealId, dealName, success, message } = saveEcalc || {};

  //load properties
  useEffect(() => {
    dispatch(loadExistingProperties());
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      const dealStatus = getObjectKeyByValue(
        DealStatusType,
        selectedDeal?.deal_status || DealStatusType.PFPW
      ).toLowerCase();

      Navigation.go(
        `/e-calc/save-ecalc-success?deal_id=${dealId}&deal_name=${dealName}&type=${dealStatus}${
          hash_id ? `&hash_id=${hash_id}` : ""
        }`
      );

      handleClose();
    } else if (success === false) {
      setNotification({ show: true, message: message });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    if (showNewPropertyModal && !saveDeal.loading && saveDeal.success) {
      setShowNewPropertyModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveDeal]);

  const saveToExistingDeal = async (selectedDeal) => {
    if (selectedDeal) {
      setSelectedDeal(selectedDeal);
      await dispatch(
        saveEcalcToExistingDeal({ deal: selectedDeal, ecalcValues })
      );
    }
  };

  const saveToNewDeal = async () => {
    await dispatch(
      saveEcalcToNewDeal({
        address: autocompleteValue,
        isMobile,
        ecalcValues,
      })
    );
  };

  return (
    <div style={saveEcalc?.loading ? { pointerEvents: "none" } : {}}>
      {saveEcalc?.loading && (
        <SiteLoader
          style={{
            background: "rgba(255, 255, 255, 0.7)",
            position: "absolute",
          }}
        />
      )}
      <Button
        onClick={(e) => setShowNewPropertyModal(true)}
        className={clsx(classes.saveLinkBtn, classes.addNewPropertyBtn)}
      >
        <AddCircleOutlineIcon style={{ marginRight: 14 }} /> Add new property
      </Button>
      {notification && notification.show && (
        <Notification
          open={true}
          initialNotificationState={initialNotificationState}
          message={notification.message}
          buttonTxt={notification.buttonTxt}
          isMobile={isMobile}
          maxMsgWidth={isMobile ? 228 : 158}
          style={{ position: "absolute" }}
          horizontalAnchor="center"
          onClick={notification.onClick}
          hideNotification={setNotification}
        />
      )}
      {!properties || properties.loading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <img
            src="/images/loading.gif"
            alt="loading spinner"
            style={{ width: "50%" }}
          />
        </div>
      ) : properties?.data?.length > 0 ? (
        properties.data.map((property, index) => (
          <Property
            key={index}
            isMobile={isMobile}
            property={property}
            setNotification={setNotification}
            save={() => saveToExistingDeal(property)}
          />
        ))
      ) : (
        <div className={classes.noProperties}>
          <span className={classes.noPropertiesTag}>No Properties Yet</span>
          <span className={classes.noPropertiesMsg}>
            Create your first property to save your calculator to.
          </span>
        </div>
      )}
      {showNewPropertyModal && (
        <NewPropertyModal
          autocompleteValue={autocompleteValue}
          setAutocompleteValue={(value) => {
            setAutocompleteValue(value);
            dispatch(clearErrorMsg());
          }}
          errorMsg={!saveDeal?.success && saveDeal?.msg}
          clearErrorMsg={() => dispatch(clearErrorMsg())}
          setErrorMsg={(msg) => dispatch(setErrorMsg(msg))}
          handleClose={() => {
            dispatch(clearErrorMsg());
            setAutocompleteValue(null);
            setShowNewPropertyModal(false);
          }}
          save={() => saveToNewDeal()}
        />
      )}
    </div>
  );
}

function SaveEcalcDesktopModal({ open, handleClose, isMobile }) {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialog }}
    >
      <div className={classes.desktopHeader}>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <div className={classes.title}>Save</div>
      </div>
      <div className={classes.scrollableContent}>
        {RenderContent({ isMobile, handleClose })}
      </div>
    </Drawer>
  );
}

function SaveEcalcMobileDrawer({ open, handleOpen, handleClose, isMobile }) {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      disableSwipeToOpen
      disableDiscovery
      classes={{ paperAnchorBottom: classes.drawer }}
    >
      <div className={classes.mobileClose} />
      <div className={classes.mobileHeader}>
        <div className={classes.title}>Save</div>
        <span className={classes.mobileHeaderXsText}>
          Save your calculations to a new or existing property.
        </span>
      </div>
      <Divider />
      <div className={classes.scrollableContent}>
        {RenderContent({ isMobile, handleClose })}
      </div>
    </SwipeableDrawer>
  );
}
