import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as navigationTitleActions from "../../actions/navigationTitleActions";
import * as sharedCalculationsActions from "../../actions/calculatorActions/sharingCalculationsActions";
import Expire from "../common/Expire";
import Button from "muicss/lib/react/button";
import { hotjar } from 'react-hotjar';


class CalculatorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.closeSharedCalculationsToast = this.closeSharedCalculationsToast.bind(
      this
    );
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.props.navActions.updateNavTitle("Other Calculators");
    hotjar.initialize(3012791, 6);
  }

  closeSharedCalculationsToast() {
    this.props.sharedCalculationsActions.closeSharedCalcToast();
  }

  render() {
    let successMessage = null;
    if (this.props.sharedCalculations.errorToast) {
      successMessage = (
        <Expire closeToast={this.closeSharedCalculationsToast} delay={6000}>
          <div className="success-message">
            <p>{this.props.sharedCalculations.errorMessage}</p>
            <Button variant="flat" onClick={this.closeSharedCalculationsToast}>
              Done
            </Button>
          </div>
        </Expire>
      );
    }
    const noSidebar =
      process.env.REACT_APP_HIDE_SIDEBAR === "true" ? true : false;
    return (
      <div id="calculators">
        <div
          className={`calculator-sub-header scrollmenu ${
            noSidebar ? "full-width" : ""
          }`}
        >
          <NavLink
            to="/calculators/DSCRandBalance"
            activeClassName="header-active-link"
          >
            MORTGAGE CALCULATOR WITH IO
          </NavLink>
          <NavLink
            to="/calculators/simpleUnderwriting"
            activeClassName="header-active-link"
          >
            VALUE VIA INCOME APPROACH
          </NavLink>
          <NavLink
            to="/calculators/maxLoan"
            activeClassName="header-active-link"
          >
            MAX LOAN VIA DSCR
          </NavLink>
          <NavLink
            to="/calculators/cashOnCash"
            activeClassName="header-active-link"
          >
            CASH ON CASH
          </NavLink>
          <NavLink to="/calculators/IRR" activeClassName="header-active-link">
            IRR
          </NavLink>
          <NavLink
            to="/calculators/blendedInterestRate"
            activeClassName="header-active-link"
          >
            BLENDED INTEREST RATE
          </NavLink>
          <NavLink
            to="/calculators/capRate"
            activeClassName="header-active-link"
          >
            CAP RATE
          </NavLink>
          <NavLink
            to="/calculators/loanSyndication"
            activeClassName="header-active-link"
          >
            LOAN SYNDICATOR RETURNS
          </NavLink>
          <NavLink
            to="/calculators/debtYield"
            activeClassName="header-active-link"
          >
            MAX LOAN VIA DEBT YIELD
          </NavLink>
          <NavLink
            to="/calculators/interestOnly"
            activeClassName="header-active-link"
          >
            INTEREST ONLY
          </NavLink>
          <NavLink
            to="/calculators/yieldMaintenance"
            activeClassName="header-active-link"
          >
            YIELD MAINTENANCE
          </NavLink>
          <NavLink
            to="/calculators/reverseMortgage"
            activeClassName="header-active-link"
          >
            CALCULATE ORIGINAL BALANCE
          </NavLink>
          <NavLink
            to="/calculators/standardCalculator"
            activeClassName="header-active-link"
          >
            CALCULATOR
          </NavLink>
        </div>
        <div className="calculators">
          {this.props.children}
          {successMessage}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    title: state.navTitle || "",
    sharedCalculations: state.sharedCalculations,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(navigationTitleActions, dispatch),
    sharedCalculationsActions: bindActionCreators(
      sharedCalculationsActions,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatorContainer);
