import React, { useEffect } from "react";
import clsx from "clsx";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getEcalcRates } from "../../../actions/rateSheetActions";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useStyles } from "./EcalcRates.style";
import mixpanel from "mixpanel-browser";
import debounce from "lodash/debounce";

export function EcalcSideRates() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ecalcRates = useSelector(
    (state) => state.rates.ecalcRates,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getEcalcRates());
  }, [dispatch]);

  return (
    <div className={classes.ratesContainer}>
      {Array.isArray(ecalcRates) &&
        !!ecalcRates.length &&
        ecalcRates.map((r, index) => {
          const { name, rate, change } = r;
          return (
            <div key={index} className={classes.rateItem}>
              <div className={classes.rateName}>{name}</div>
              <div className={classes.rateValueContainer}>
                <span className={classes.rateValue}>{rate}</span>
                <span
                  className={clsx(
                    classes.rateChange,
                    classes[getRateChangeClass(change)]
                  )}
                >
                  {change}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function EcalcMobileRatesSlider() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ecalcRates = useSelector(
    (state) => state.rates.ecalcRates,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getEcalcRates());
  }, [dispatch]);

  const debounceTrack = debounce((scrollLeft) => {
    if (scrollLeft > 0) {
      console.log("RAtes scrolled");
      mixpanel.track("Rates Scrolled");
    }
  }, 200);

  return (
    <div
      className={classes.sliderContainer}
      onScroll={(e) => debounceTrack(e.target.scrollLeft)}
    >
      {Array.isArray(ecalcRates) &&
        !!ecalcRates.length &&
        ecalcRates.map((r, index) => {
          const { name, rate, change } = r;
          return (
            <div key={index} className={classes.rateItem}>
              <div className={classes.rateName}>{name}</div>
              <div className={classes.rateValueContainer}>
                <span className={classes.rateValue}>{rate}</span>
                <span
                  className={clsx(
                    classes.rateChange,
                    classes[getRateChangeClass(change)]
                  )}
                >
                  {change}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function EcalcDesktopRatesSlider() {
  const classes = useStyles({ desktopSlider: true });
  const dispatch = useDispatch();
  const ecalcRates = useSelector(
    (state) => state.rates.ecalcRates,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getEcalcRates());
  }, [dispatch]);

  return (
    <div className={classes.desktopSliderContainer}>
      {Array.isArray(ecalcRates) && !!ecalcRates.length && (
        <Carousel responsive={responsive}>
          {ecalcRates.map((r, index) => {
            const { name, rate, change } = r;
            return (
              <div
                key={index}
                className={clsx(
                  classes.rateItem,
                  classes.rateItemDesktopSlider
                )}
              >
                <div className={classes.rateName}>{name}</div>
                <div className={classes.rateValueContainer}>
                  <span className={classes.rateValue}>{rate}</span>
                  <span
                    className={clsx(
                      classes.rateChange,
                      classes[getRateChangeClass(change)]
                    )}
                  >
                    {change}
                  </span>
                </div>
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
}

function getRateChangeClass(rateChange) {
  const isPositive = Math.sign(parseFloat(rateChange)) > -1;
  return isPositive ? "rateChangePositive" : "rateChangeNegative";
}

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
