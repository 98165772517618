import * as types from "../actionTypes";

function updateReverseMortgage(values) {
  return { type: types.UPDATE_REVERSE_MORTGAGE, values };
}

export function calculateReverseMortgage(values, trigger) {
  return function (dispatch) {
    calculate(values, trigger);
    dispatch(updateReverseMortgage(values));
  };
}

function calculate(values, trigger) {
  if (trigger) {
    if (trigger.name === "amortizationYears") {
      values.amortizationYears = trigger.value;
      values.amortizationMonths = trigger.value * 12;
    } else if (trigger.name === "amortizationMonths") {
      values.amortizationMonths = trigger.value;
      values.amortizationYears = trigger.value / 12;
    }
  }

  if (
    values.monthlyPayment &&
    values.interestRate &&
    values.amortizationMonths
  ) {
    const ratePerMonth = values.interestRate / 100 / 12;
    values.loanAmount =
      (values.monthlyPayment / ratePerMonth) *
      (1 - 1 / Math.pow(1 + ratePerMonth, values.amortizationMonths));
  } else {
    values.loanAmount = 0;
  }
}
