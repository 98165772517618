import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  container: {
    fontFamily: "Karla",
    margin: "25px auto",
    width: "90%",
    padding: "25px 40px",
    [theme.breakpoints.down("xs")]: {
      width: "initial",
      padding: "10px 16px 0px 16px",
    },
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listWithPadding: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  mobileGraphed: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  graphedIndexContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  graphedIndexName: {
    fontSize: 22,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  graphedIndexNameModal: {
    fontSize: 19,
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
    },
  },
  graphedIndexRate: {
    fontSize: 19,
    fontWeight: "bold",
    fontFamily: "Roboto Condensed",
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  graphedIndexRateModal: {
    fontSize: 17,
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  graphedIndexChange: {
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: "Roboto Condensed",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  graphedIndexChangeModal: {
    fontSize: 14,
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  graphedBorder: {
    width: 5,
    height: 78,
    borderRadius: 20,
    marginRight: 10,
  },
  graphedBorderModal: {
    height: 65,
  },
  "S&P500-border": {
    background: "#198FF5",
  },
  "DOW-border": {
    background: "#02B464",
  },
  "NASDAQ-border": {
    background: "#C80487",
  },
  "S&P500-change": {
    color: "#198FF5",
  },
  "DOW-change": {
    color: "#02B464",
  },
  "NASDAQ-change": {
    color: "#C80487",
  },
  primaryIndexName: {
    fontWeight: "bold",
    fontSize: 24.9764,
    textTransform: "uppercase",
    color: "#00112B",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  primaryIndexNameModal: {
    fontSize: 17.5169,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  primaryIndexRate: {
    fontWeight: "bold",
    fontSize: 28.0985,
    textAlign: "right",
    color: "#000000",
    fontFamily: "Roboto Condensed",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  primaryIndexRateModal: {
    fontSize: 19.7065,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  primaryIndexChangeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  primaryIndexChange: {
    fontSize: 17.0055,
    color: "#000000",
    borderRadius: 3.92434,
    padding: 5,
    minWidth: 74,
    textAlign: "right",
    fontFamily: "Roboto Condensed",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      minWidth: 48,
      padding: "4px 4px 4px 2px",
    },
  },
  primaryIndexChangeModal: {
    fontSize: 14.2324,
    minWidth: 57,
    padding: "0px 4px 0px 2px",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      minWidth: 52,
      paddingTop: 1,
    },
  },
  rateChangePositive: {
    background: "rgba(35,225,168,0.15)",
  },
  rateChangeNegative: {
    background: "rgba(244,82,82,0.15)",
  },
  moreRates: {
    background: "#EFEFEF",
    borderRadius: 2.75125,
    width: "100%",
    height: 32,
    textAlign: "center",
    fontSize: 22.01,
    textTransform: "uppercase",
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      height: 23,
    },
  },
  otherIndexName: {
    fontWeight: "bold",
    fontSize: 22.01,
    textTransform: "uppercase",
    color: "#00112B",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  otherIndexRate: {
    fontWeight: "bold",
    fontSize: 24.7612,
    textAlign: "right",
    color: "#000000",
    fontFamily: "Roboto Condensed",
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  sectionHeader: {
    background: "#EFEFEF",
    borderRadius: 2.75373,
    height: 32,
    fontSize: 22.0299,
    textTransform: "uppercase",
    color: "#000000",
    textAlign: "center",
    borderBottom: "1.37687px solid #000000",
    marginTop: 15,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      height: 23,
    },
  },
  noBottomRadius: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  name: {
    fontSize: 22.0299,
    textTransform: "uppercase",
    color: "#000000",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
    },
  },
  sectionDivider: {
    background: "#000000",
    height: 1.37687,
  },
  firstTableCell: {
    paddingLeft: 0,
  },
  lastTableCell: {
    paddingRight: 0,
  },
  multiHeaderTitle: {
    fontSize: 19.2761,
    textTransform: "capitalize",
    color: "#000000",
    // maxWidth: "33%",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  fhaNameContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  fhaNameSubName: {
    fontSize: 16.5224,
    color: "#00112B",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
  textLeft: {
    textAlign: "left",
  },
  tableHeadBorder: {
    borderBottom: "1.37687px solid #000000",
  },
  tableBorder: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
  },
  floorMsg: {
    fontSize: 10,
    color: "#868686",
    padding: "0px 0px 9px 0px !important",
  },
}));
