import { makeStyles } from "@material-ui/core";
import { ECALC_LARGE_SCREEN } from "../../../lib";

export const useStyles = makeStyles((theme) => ({
  ratesContainer: {
    width: 280,
    paddingLeft: 52,
    fontFamily: "Karla",
    boxSizing: "border-box",
    [theme.breakpoints.between("md", ECALC_LARGE_SCREEN)]: {
      width: 185,
    },
  },
  rateItem: {
    border: "1.30811px solid #EFEFEF",
    boxSizing: "border-box",
    borderRadius: 3.92434,
    width: (props) => (props.desktopSlider ? "initial" : "100%"),

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    height: 100,
    marginBottom: 25,
    padding: 13,
    [theme.breakpoints.between("md", ECALC_LARGE_SCREEN)]: {
      height: "inherit",
      marginBottom: 20,
      padding: 9,
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      marginRight: 10,
      padding: 10,
      width: "initial",
    },
  },
  rateItemDesktopSlider: {
    marginRight: 15,
  },
  rateName: {
    width: "100%",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 18.3136,
    textTransform: "uppercase",
    color: "#00112B",
    [theme.breakpoints.between("md", ECALC_LARGE_SCREEN)]: {
      fontSize: 12.0632,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
    },
  },
  rateValueContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Roboto Condensed",
  },
  rateValue: {
    fontWeight: "bold",
    fontSize: 23.5461,
    color: "#000000",
    [theme.breakpoints.between("md", ECALC_LARGE_SCREEN)]: {
      fontSize: 15.5098,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      marginRight: 7,
    },
  },
  rateChange: {
    color: "#000000",
    borderRadius: 3.92434,
    textAlign: "right",
    fontSize: 17.0055,
    minWidth: 74,
    padding: 5,
    [theme.breakpoints.between("md", ECALC_LARGE_SCREEN)]: {
      fontSize: 11.201,
      minWidth: 44,
      padding: 3,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
      minWidth: 48,
      padding: "4px 4px 4px 2px",
    },
  },
  rateChangePositive: {
    background: "rgba(35,225,168,0.15)",
  },
  rateChangeNegative: {
    background: "rgba(244,82,82,0.15)",
  },
  sliderContainer: {
    fontFamily: "Karla",
    width: "100%",
    maxWidth: "calc(100% - 15px)",
    margin: "0 auto",
    marginTop: 20,
    overflowX: "auto",
    display: "flex",
    alignItems: "center",
    // hide scroll bar https://stackoverflow.com/a/49278385
    scrollbarWidth: "none" /* Firefox */,
    msOverflowStyle: "none" /* Internet Explorer 10+ */,
    /* WebKit (Chrome) */
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  desktopSliderContainer: {
    paddingRight: 10,
    paddingLeft: 10,
    marginTop: 10,
  },
}));
