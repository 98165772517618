import * as types from "../actions/actionTypes";

const initialState = {
  compsPage: [],
  compsListing: [],
  contactProperties: [],
  brokerProperties: [],
  propertyTypes: [],
  contact: {},
  compsLinkClicked: false,
  loadingContact: false,
  navBarClicked: false,
  defaultSearch: false,
  modalShowing: false,
  errorToast: false,
  noContact: false,
  loadingToast: false,
  loading: false,
  previousPage: "",
  previousScrollPos: [],
  searchBarValue: "",
  compsPosition: "",
  errorMessage: "",
  city: "",
  requestQuoteToast: {
    show: false,
    success: true,
    message: "",
  },
  topBrokersInArea: null,
  topEUBrokers: null,
  qtsRep: null,
};

export default function compsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_COMPS:
      return Object.assign({}, state, action.data, {
        loading: false,
        modalShowing: false,
        loadingToast: false,
      });
    case types.LOAD_PROPERTY_TYPES:
      return Object.assign({}, state, {
        propertyTypes: action.data,
      });
    case types.LOAD_SEARCH_COMPS:
      return Object.assign({}, state, action.data, {
        center: action.center,
        defaultSearch: false,
        loading: false,
      });
    case types.DEFAULT_SEARCH_COMP_LOAD:
      return Object.assign({}, initialState, {
        center: action.center,
        defaultSearch: true,
        propertyTypes: state.propertyTypes,
        topEUBrokers: state.topEUBrokers,
        qtsRep: state.qtsRep,
        searchBarValue: state.searchBarValue,
        previousPage: state.previousPage,
      });
    case types.NAV_BAR_CLICKED:
      return Object.assign({}, state, {
        navBarClicked: true,
      });
    case types.MAP_LOADED:
      return Object.assign({}, state, {
        navBarClicked: false,
      });
    case types.LOADING_COMPS:
      return Object.assign({}, state, {
        loading: true,
        loadingToast: true,
        modalShowing: true,
        errorMessage: "",
        compsPage: [],
        compsListing: [],
      });
    case types.COMPS_LOADED:
      return Object.assign({}, state, {
        loading: false,
        modalShowing: false,
        loadingToast: false,
      });
    case types.COMPS_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: action.message,
        errorToast: true,
        loading: false,
        loadingToast: false,
        modalShowing: false,
        compsPage: [],
        compsListing: [],
        contactProperties: [],
        brokerProperties: [],
      });
    case types.CLOSE_ERROR_TOAST:
      return Object.assign({}, state, {
        errorMessage: "",
        errorToast: false,
      });
    case types.CLOSE_LOADING_TOAST:
      return Object.assign({}, state, {
        loadingToast: false,
      });
    case types.CLOSE_COMPS_MODAL:
      return Object.assign({}, state, {
        errorMessage: "",
        modalShowing: false,
      });
    case types.SET_PAGE_AND_SCROLL_P0S:
      initialState.previousScrollPos.push(action.scrollPosition);
      return Object.assign({}, state, {
        previousPage: action.page,
        previousScrollPos: initialState.previousScrollPos,
      });
    case types.POP_PAGE_AND_SCROLL_P0S:
      initialState.previousScrollPos.pop();
      return Object.assign({}, state, {
        previousPage: action.page,
        previousScrollPos: action.scrollPosition,
      });
    case types.SET_COMPS_PAGE:
      return Object.assign({}, state, {
        compsPosition: action.page,
      });
    case types.RESET_SCROLL_POS:
      initialState.previousScrollPos = [];
      return Object.assign({}, state, {
        previousScrollPos: initialState.previousScrollPos,
      });
    case types.CLICK_COMPS_LINK:
      return Object.assign({}, initialState, {
        compsLinkClicked: true,
        propertyTypes: state.propertyTypes,
        topEUBrokers: state.topEUBrokers,
        qtsRep: state.qtsRep,
        searchBarValue: state.searchBarValue,
        previousPage: state.previousPage,
      });
    case types.UPDATE_COMPS_SEARCH_BAR:
      return Object.assign({}, state, {
        searchBarValue: action.value,
        city: action.city,
        errorMessage: "",
        errorToast: false,
      });
    case types.RESET_COMPS_STATE:
      return Object.assign({}, initialState, {
        previousScrollPos: state.previousScrollPos,
        compsLinkClicked: true,
        propertyTypes: state.propertyTypes,
        topEUBrokers: state.topEUBrokers,
        qtsRep: state.qtsRep,
      });
    case types.LOADING_FULL_CONTACT:
      return Object.assign({}, state, {
        loadingContact: true,
        contact: {},
      });
    case types.LOAD_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: action.contact,
        loadingContact: false,
      });
    case types.NO_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: {},
        noContact: true,
        loadingContact: false,
      });
    case types.UPDATE_ERROR_TOAST:
      return Object.assign({}, state, {
        errorToast: true,
        errorMessage: action.message,
      });
    case types.REQUEST_QUOTE:
      return Object.assign({}, state, {
        requestQuoteToast: {
          show: true,
          success: action.success,
          message: action.message,
        },
      });
    case types.CLOSE_REQUEST_QUOTE_TOAST:
      return Object.assign({}, state, {
        requestQuoteToast: { show: false, success: true, message: "" },
      });
    case types.GET_TOP_BROKERS_IN_AREA:
      return Object.assign({}, state, { topBrokersInArea: action.data });
    case types.GET_TOP_EU_BROKERS:
      return Object.assign({}, state, { topEUBrokers: action.data });
    case types.GET_QTS_BROKER:
      return Object.assign({}, state, { qtsRep: action.data });
    default:
      return state;
  }
}
