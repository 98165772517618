import React from "react";
import PropTypes from "prop-types";

class ErrorMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: this.props.hidden || false,
    };
  }

  UNSAFE_componentWillMount() {
    this._mounted = true;
    var self = this;
    if (this.state.hidden) {
      setTimeout(function () {
        self.show();
      }, 750);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  show() {
    if (this._mounted) {
      this.setState({ hidden: false });
    }
  }

  render() {
    return (
      <span className="calculator-error" hidden={this.state.hidden}>
        {this.props.message}
      </span>
    );
  }
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
};

export default ErrorMessage;
