import React from "react";
import { connect } from "react-redux";
import * as loanSyndicationActions from "../../../actions/calculatorActions/loanSyndicationActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentInput from "../calculatorCommon/PercentInput";
import NumberInputAllowZero from "../calculatorCommon/NumberInputAllowZero";
import NumberInputWithoutDecimal from "../calculatorCommon/NumberInputWithoutDecimal";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import { bindActionCreators } from "redux";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";

class LoanSyndication extends React.Component {
  constructor(props) {
    super(props);

    const {
      purchasePrice,
      appraisedValue,
      closingCosts,
      tiLcCosts,
      interestReserve,
      totalCosts,
      totalCapitalization,
      totalLoan,
      tlPercentOfAppraisedVal,
      tlPercentOfPurchasePrice,
      tlPercentOfTtlCapitalization,
      aLoan,
      aPercentOfAppraisedVal,
      aPercentOfTotalLoan,
      bLoan,
      bPercentOfAppraisedVal,
      bPercentOfRemainingLoan,
      term,
      tlInterestRate,
      tlPoints,
      aInterestRate,
      aPoints,
      bInterestRate,
      bPoints,
      from,
      to,
      cc,
      syndicatorLoan,
      aReturn,
      aEffectiveInterest,
      bReturn,
      bEffectiveInterest,
      syndicatorReturn,
      syndicatorEffectiveInterest,
    } = this.props;

    this.state = {
      modalOpen: false,
      purchasePrice,
      appraisedValue,
      closingCosts,
      tiLcCosts,
      interestReserve,
      totalCosts,
      totalCapitalization,
      totalLoan,
      tlPercentOfAppraisedVal,
      tlPercentOfPurchasePrice,
      tlPercentOfTtlCapitalization,
      aLoan,
      aPercentOfAppraisedVal,
      aPercentOfTotalLoan,
      bLoan,
      bPercentOfAppraisedVal,
      bPercentOfRemainingLoan,
      term,
      tlInterestRate,
      tlPoints,
      aInterestRate,
      aPoints,
      bInterestRate,
      bPoints,
      from,
      to,
      cc,
      syndicatorLoan,
      aReturn,
      aEffectiveInterest,
      bReturn,
      bEffectiveInterest,
      syndicatorReturn,
      syndicatorEffectiveInterest,
    };
  }

  componentWillUnmount() {
    this.props.loanSyndicationActions.updateLoanSyndication({ ...this.state });
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value }, this.updateOutputs);
  };

  handleRecursiveChange = (name, value) => {
    this.setState({ [name]: value }, this.updateInputsOnChange);
  };

  updateInputsOnChange = () => {
    const {
      purchasePrice,
      closingCosts,
      tiLcCosts,
      interestReserve,
    } = this.state;
    const totalCosts = addNums(closingCosts, tiLcCosts, interestReserve);
    this.setState({
      totalCosts,
      totalCapitalization: addNums(purchasePrice, totalCosts),
    });
    this.updateTotalLoanFields();
  };

  updateTotalLoanFields = (name, value) => {
    const totalLoan = !name
      ? this.state.totalLoan
      : name === "totalLoan"
      ? value
      : this.getLoanAmntFromPercentInput(name, value);

    this.setState(
      (prevState) => ({
        totalLoan,
        tlPercentOfAppraisedVal:
          name === "tlPercentOfAppraisedVal"
            ? value
            : this.getPercent(totalLoan, prevState.appraisedValue),
        tlPercentOfPurchasePrice:
          name === "tlPercentOfPurchasePrice"
            ? value
            : this.getPercent(totalLoan, prevState.purchasePrice),
        tlPercentOfTtlCapitalization:
          name === "tlPercentOfTtlCapitalization"
            ? value
            : this.getPercent(totalLoan, prevState.totalCapitalization),
      }),
      () => this.updateALoanFields(null, null, true)
    );
  };

  updateALoanFields = (name, value) => {
    const aLoan = !name
      ? this.state.aLoan
      : name === "aLoan"
      ? value
      : this.getLoanAmntFromPercentInput(name, value);

    this.setState(
      (prevState) => ({
        aLoan,
        aPercentOfAppraisedVal:
          name === "aPercentOfAppraisedVal"
            ? value
            : this.getPercent(aLoan, prevState.appraisedValue),
        aPercentOfTotalLoan:
          name === "aPercentOfTotalLoan"
            ? value
            : this.getPercent(aLoan, prevState.totalLoan),
      }),
      () => this.updateBLoanFields(null, null, true)
    );
  };

  updateBLoanFields = (name, value, fromALoan) => {
    const { bLoan: prevBLoan } = this.state;
    // const bLoan = fromALoan && prevALoan ? +totalLoan - +prevALoan
    //   : fromALoan ? 0
    const bLoan = fromALoan
      ? prevBLoan
      : name === "bLoan"
      ? value
      : this.getLoanAmntFromPercentInput(name, value);
    this.setState(
      (prevState) => ({
        bLoan,
        bPercentOfAppraisedVal:
          name === "bPercentOfAppraisedVal"
            ? value
            : this.getPercent(bLoan, prevState.appraisedValue),
        bPercentOfRemainingLoan:
          name === "bPercentOfRemainingLoan"
            ? value
            : this.getPercent(bLoan, +prevState.totalLoan - +prevState.aLoan),
      }),
      this.updateOutputs
    );
  };

  getPercent = (smallNum, bigNum) => {
    return (+smallNum * 100) / +bigNum;
  };

  updateOutputs = () => {
    const {
      totalLoan,
      aLoan,
      bLoan,
      term,
      tlInterestRate,
      tlPoints,
      aInterestRate,
      aPoints,
      bInterestRate,
      bPoints,
    } = this.state;
    const termYears = +term / 12;
    const syndicatorLoan = (+totalLoan || 0) - ((+aLoan || 0) + (+bLoan || 0));
    const aReturn =
      ((+aInterestRate / 100) * termYears + +aPoints / 100) * +aLoan;
    const bReturn =
      ((+bInterestRate / 100) * termYears + +bPoints / 100) * +bLoan;
    // const aEffectiveInterest = (aReturn / aLoan) * 100;
    // const bEffectiveInterest = (bReturn / bLoan) * 100;
    const aEffectiveInterest =
      (+aInterestRate / 100 + +aPoints / 100 / termYears) * 100;
    const bEffectiveInterest =
      (+bInterestRate / 100 + +bPoints / 100 / termYears) * 100;
    const syndicatorReturn =
      ((+tlInterestRate / 100) * termYears + +tlPoints / 100) * totalLoan -
      aReturn -
      bReturn;
    const syndicatorEffectiveInterest = syndicatorLoan
      ? (syndicatorReturn / termYears / syndicatorLoan) * 100
      : 0;

    this.setState({
      syndicatorLoan,
      aReturn,
      aEffectiveInterest,
      bReturn,
      bEffectiveInterest,
      syndicatorReturn,
      syndicatorEffectiveInterest,
    });
  };

  getLoanAmntFromPercentInput = (name, percent) => {
    const {
      purchasePrice,
      appraisedValue,
      totalLoan,
      aLoan,
      totalCapitalization,
    } = this.state;

    let amnt;
    switch (name) {
      case "tlPercentOfAppraisedVal":
      case "aPercentOfAppraisedVal":
      case "bPercentOfAppraisedVal":
        amnt = appraisedValue;
        break;
      case "tlPercentOfPurchasePrice":
        amnt = purchasePrice;
        break;
      case "tlPercentOfTtlCapitalization":
        amnt = totalCapitalization;
        break;
      case "aPercentOfTotalLoan":
        amnt = totalLoan;
        break;
      case "bPercentOfRemainingLoan":
        amnt = +totalLoan - +aLoan;
        break;
      default:
        break;
    }
    const loan = (+percent / 100) * +amnt;
    return Math.round(loan);
  };

  openSharingCalculatorModal = () => {
    this.setState({ modalOpen: true });
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  updateFrom = (value) => {
    this.setState({ from: value });
  };

  updateTo = (value) => {
    this.setState({ to: value });
  };

  updateCC = (value) => {
    this.setState({ cc: value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ modalOpen: false });
    const {
      purchasePrice,
      appraisedValue,
      closingCosts,
      tiLcCosts,
      interestReserve,
      totalCosts,
      totalCapitalization,
      totalLoan,
      tlPercentOfAppraisedVal,
      tlPercentOfPurchasePrice,
      tlPercentOfTtlCapitalization,
      aLoan,
      aPercentOfAppraisedVal,
      aPercentOfTotalLoan,
      bLoan,
      bPercentOfAppraisedVal,
      bPercentOfRemainingLoan,
      term,
      tlInterestRate,
      tlPoints,
      aInterestRate,
      aPoints,
      bInterestRate,
      bPoints,
      syndicatorLoan,
      aEffectiveInterest,
      bEffectiveInterest,
      syndicatorReturn,
      syndicatorEffectiveInterest,
      to,
      from,
      cc,
    } = this.state;
    this.setState({ modalOpen: false }, function () {
      let input = [
        { input0: formatDollars(purchasePrice) },
        { input1: formatDollars(appraisedValue) },
        { input2: formatDollars(closingCosts) },
        { input3: formatDollars(tiLcCosts) },
        { input4: formatDollars(interestReserve) },
        { input5: formatDollars(totalCosts) },
        { input6: formatDollars(totalCapitalization) },
        { input7: formatDollars(totalLoan) },
        { input8: formatPercent(tlPercentOfAppraisedVal) },
        { input9: formatPercent(tlPercentOfPurchasePrice) },
        { input10: formatPercent(tlPercentOfTtlCapitalization) },
        { input11: term.toString() },
        { input12: formatPercent(tlInterestRate) },
        { input13: tlPoints.toString() },
        { input14: formatDollars(aLoan) },
        { input15: formatPercent(aPercentOfTotalLoan) },
        { input16: formatPercent(aPercentOfAppraisedVal) },
        { input17: formatPercent(aInterestRate) },
        { input18: aPoints.toString() },
        { input19: formatDollars(bLoan) },
        { input20: formatPercent(bPercentOfRemainingLoan) },
        { input21: formatPercent(bPercentOfAppraisedVal) },
        { input22: formatPercent(bInterestRate) },
        { input23: bPoints.toString() },
      ];

      let output = [
        { output0: formatDollars(syndicatorLoan) },
        {
          output1:
            syndicatorLoan < 1
              ? null
              : formatPercent(syndicatorEffectiveInterest),
        },
        {
          output2: syndicatorLoan < 1 ? formatDollars(syndicatorReturn) : null,
        },
        { output3: formatDollars(aLoan) },
        { output4: formatPercent(aEffectiveInterest) },
        // { 'output5': null },
        { output6: formatDollars(bLoan) },
        { output7: formatPercent(bEffectiveInterest) },
        // { 'output8': null },
      ];

      this.props.sharingCalculationsActions.calculatorSharing(
        to,
        from,
        cc,
        "LoanSyndication",
        input,
        output
      );
    });
  };

  render() {
    const {
      purchasePrice,
      appraisedValue,
      closingCosts,
      tiLcCosts,
      interestReserve,
      totalCosts,
      totalCapitalization,
      totalLoan,
      tlPercentOfAppraisedVal,
      tlPercentOfPurchasePrice,
      tlPercentOfTtlCapitalization,
      aLoan,
      aPercentOfAppraisedVal,
      aPercentOfTotalLoan,
      bLoan,
      bPercentOfAppraisedVal,
      bPercentOfRemainingLoan,
      term,
      tlInterestRate,
      tlPoints,
      aInterestRate,
      aPoints,
      bInterestRate,
      bPoints,
      aEffectiveInterest,
      bEffectiveInterest,
      syndicatorLoan,
      syndicatorReturn,
      syndicatorEffectiveInterest,
      modalOpen,
      from,
      to,
      cc,
    } = this.state;

    return (
      <div>
        <Row>
          <ShareCalcModal
            isOpen={modalOpen}
            onRequestClose={this.closeModal}
            onAfterOpen={() => {}}
            onSubmit={this.onSubmit}
            from={from}
            to={to}
            cc={cc}
            updateFrom={this.updateFrom}
            updateTo={this.updateTo}
            updateCC={this.updateCC}
          />
          <Panel className="calculator-card">
            <Form
              className="calculator-form loan-syndication-form"
              inline={true}
            >
              <h2 className="calculator-title">Loan Syndicator Returns</h2>

              <p
                className={"share-calc"}
                onClick={this.openSharingCalculatorModal}
              >
                <i className="material-icons">share</i>
                Share Calculations
              </p>
              <div className="mobile-560-hidden flex">
                <div className="box box-top">
                  <p className="box-title">Loan Syndicator</p>
                  <div>
                    <p className="xxs-text">Amount I Am Participating</p>
                    <div>{formatDollars(syndicatorLoan)}</div>
                  </div>
                  {syndicatorLoan < 1 ? (
                    <div>
                      <p className="xxs-text">My Effective Return</p>
                      <div>{formatDollars(syndicatorReturn || 0)}</div>
                    </div>
                  ) : (
                    <div>
                      <p className="xxs-text">
                        My Effective Annual Interest Rate (w/Points)
                      </p>
                      <div>{formatPercent(syndicatorEffectiveInterest)}</div>
                    </div>
                  )}
                </div>
                <div className="box box-top">
                  <p className="box-title">A Piece (Bank)</p>
                  <div>
                    <p className="xxs-text">Loan Amount</p>
                    <div>{formatDollars(aLoan || 0)}</div>
                  </div>
                  <div>
                    <p className="xxs-text">
                      Effective Annual Interest Rate (w/Points)
                    </p>
                    <div>{formatPercent(aEffectiveInterest)}</div>
                  </div>
                  {/* <div>
                    <p className="xxs-text">Total Return</p>
                    <div>{formatDollars(aReturn || 0)}</div>
                  </div> */}
                </div>
                <div className="box box-top">
                  <p className="box-title">B Piece (Loan Participants)</p>
                  <div>
                    <p className="xxs-text">Loan Amount</p>
                    <div>{formatDollars(bLoan || 0)}</div>
                  </div>
                  <div>
                    <p className="xxs-text">
                      Effective Annual Interest Rate (w/Points)
                    </p>
                    <div>{formatPercent(bEffectiveInterest)}</div>
                  </div>
                  {/* <div>
                    <p className="xxs-text">Total Return</p>
                    <div>{formatDollars(bReturn || 0)}</div>
                  </div> */}
                </div>
              </div>

              <div className="desktop-560-hidden box box-top">
                <p className="box-title">Loan Syndicator</p>
                <div className="mobile-560-flex-space">
                  <div className="mobile-ls-output-col">
                    <p className="xxs-text">Amount I Am Participating</p>
                    <div>{formatDollars(syndicatorLoan)}</div>
                  </div>
                  {syndicatorLoan < 1 ? (
                    <div className="mobile-ls-output-col">
                      <p className="xxs-text">My Effective Return</p>
                      <div>{formatDollars(syndicatorReturn || 0)}</div>
                    </div>
                  ) : (
                    <div className="mobile-ls-output-col">
                      <p className="xxs-text">
                        My Effective Annual Interest Rate (w/Points)
                      </p>
                      <div>{formatPercent(syndicatorEffectiveInterest)}</div>
                    </div>
                  )}
                  {/* <div className="mobile-ls-output-col">
                    <p className="xxs-text">My Effective Annual Interest Rate (w/Points)</p>
                    <div>{formatPercent(syndicatorEffectiveInterest)}</div>
                  </div>
                  <div className="mobile-ls-output-col">
                    <p className="xxs-text">My Effective Return</p>
                    <div>{formatDollars(syndicatorReturn || 0)}</div>
                  </div> */}
                </div>
              </div>

              <div className="margin-top"></div>
              <DollarInput
                name="purchasePrice"
                value={purchasePrice}
                update={this.handleRecursiveChange}
                label="Purchase Price"
              />
              <DollarInput
                name="appraisedValue"
                value={appraisedValue}
                update={this.handleRecursiveChange}
                label="Appraised Value"
              />
              <br />
              <h5>Costs</h5>
              <div className="flex flex-center flex-wrap">
                <DollarInput
                  customClass="narrow-input"
                  name="closingCosts"
                  value={closingCosts}
                  update={this.handleRecursiveChange}
                  label="Closing Costs"
                />
                <DollarInput
                  customClass="narrow-input"
                  name="tiLcCosts"
                  value={tiLcCosts}
                  update={this.handleRecursiveChange}
                  label="TI/LC Costs"
                />
                <DollarInput
                  customClass="narrow-input"
                  name="interestReserve"
                  value={interestReserve}
                  update={this.handleRecursiveChange}
                  label="Interest Reserve"
                />
                <div className="ls-totals">
                  <div className="flex-space-justify">
                    <p>Total Costs</p>
                    <strong>{formatDollars(totalCosts)}</strong>
                  </div>
                  <div className="flex-space-justify">
                    <p>Total Capitalization</p>
                    <strong>{formatDollars(totalCapitalization)}</strong>
                  </div>
                </div>
              </div>
              <br />
              <h5>Loan to Borrower</h5>
              <div className="flex flex-center flex-wrap">
                <DollarInput
                  customClass="narrow-or-input"
                  name="totalLoan"
                  value={totalLoan}
                  update={this.updateTotalLoanFields}
                  label="Loan Proceeds Amount"
                />
                <div className="ls-or-wraper">
                  <p className="ls-or">OR</p>
                  <PercentInput
                    customClass="narrow-or-input"
                    name="tlPercentOfAppraisedVal"
                    value={tlPercentOfAppraisedVal}
                    update={this.updateTotalLoanFields}
                    label="% of Appraised Value"
                    decimalPlaces={3}
                  />
                </div>
                <div className="ls-or-wraper">
                  <p className="ls-or">OR</p>
                  <PercentInput
                    customClass="narrow-or-input"
                    name="tlPercentOfPurchasePrice"
                    value={tlPercentOfPurchasePrice}
                    update={this.updateTotalLoanFields}
                    label="% of Purchase Price"
                    decimalPlaces={3}
                  />
                </div>
                <div className="ls-or-wraper">
                  <p className="ls-or">OR</p>
                  <PercentInput
                    customClass="narrow-or-input"
                    name="tlPercentOfTtlCapitalization"
                    value={tlPercentOfTtlCapitalization}
                    update={this.updateTotalLoanFields}
                    label="% of Total Capitalization"
                    decimalPlaces={3}
                  />
                </div>
              </div>
              {/* <br /> */}
              <div className="flex">
                <NumberInputWithoutDecimal
                  name="term"
                  value={term}
                  update={this.handleChange}
                  label="Term (Months)"
                />
                <PercentInput
                  name="tlInterestRate"
                  value={tlInterestRate}
                  update={this.handleChange}
                  label="Interest Rate"
                  decimalPlaces={3}
                />
                <NumberInputAllowZero
                  name="tlPoints"
                  value={tlPoints}
                  update={this.handleChange}
                  label="Points"
                  decimalPlaces={3}
                />
              </div>
              <br />

              <h5>A Piece (Bank)</h5>
              <div className="flex flex-center flex-wrap">
                <DollarInput
                  customClass="narrow-or-input"
                  name="aLoan"
                  value={aLoan}
                  update={this.updateALoanFields}
                  label="Loan Proceeds Amount"
                />
                <div className="ls-or-wraper">
                  <p className="ls-or">OR</p>
                  <PercentInput
                    customClass="narrow-or-input"
                    name="aPercentOfTotalLoan"
                    value={aPercentOfTotalLoan}
                    update={this.updateALoanFields}
                    label="% of Loan to Borrower"
                    decimalPlaces={3}
                  />
                </div>
                <div className="ls-or-wraper">
                  <p className="ls-or">OR</p>
                  <PercentInput
                    customClass="narrow-or-input"
                    name="aPercentOfAppraisedVal"
                    value={aPercentOfAppraisedVal}
                    update={this.updateALoanFields}
                    label="% of Appraised Value"
                    decimalPlaces={3}
                  />
                </div>
              </div>
              {/* <br /> */}
              <div className="flex">
                <PercentInput
                  name="aInterestRate"
                  value={aInterestRate}
                  update={this.handleChange}
                  label="Interest Rate (I/O Only)"
                  decimalPlaces={3}
                />
                <NumberInputAllowZero
                  name="aPoints"
                  value={aPoints}
                  update={this.handleChange}
                  label="Points"
                  decimalPlaces={3}
                />
              </div>
              <br />

              <h5>B Piece (Loan Participants)</h5>
              <div className="flex flex-center flex-wrap">
                <DollarInput
                  customClass="narrow-or-input"
                  name="bLoan"
                  value={bLoan}
                  update={this.updateBLoanFields}
                  label="Loan Proceeds Amount"
                />
                <div className="ls-or-wraper">
                  <p className="ls-or">OR</p>
                  <PercentInput
                    customClass="narrow-or-input"
                    name="bPercentOfRemainingLoan"
                    value={bPercentOfRemainingLoan}
                    update={this.updateBLoanFields}
                    label="% of Remaining after A Piece"
                    decimalPlaces={3}
                  />
                </div>
                <div className="ls-or-wraper">
                  <p className="ls-or">OR</p>
                  <PercentInput
                    customClass="narrow-or-input"
                    name="bPercentOfAppraisedVal"
                    value={bPercentOfAppraisedVal}
                    update={this.updateBLoanFields}
                    label="% of Appraised Value"
                    decimalPlaces={3}
                  />
                </div>
              </div>
              {/* <br /> */}
              <div className="flex">
                <PercentInput
                  name="bInterestRate"
                  value={bInterestRate}
                  update={this.handleChange}
                  label="Interest Rate (I/O Only)"
                  decimalPlaces={3}
                />
                <NumberInputAllowZero
                  name="bPoints"
                  value={bPoints}
                  update={this.handleChange}
                  label="Points"
                  decimalPlaces={3}
                />
              </div>
              <br />
              <br className="mobile-560-hidden" />

              <div className="desktop-560-hidden box box-top">
                <p className="box-title">A Piece (Bank)</p>
                <div className="mobile-560-flex-space">
                  <div className="mobile-ls-output-col">
                    <p className="xxs-text">Loan Amount</p>
                    <div>{formatDollars(aLoan || 0)}</div>
                  </div>
                  <div className="mobile-ls-output-col">
                    <p className="xxs-text">
                      Effective Annual Interest Rate (w/Points)
                    </p>
                    <div>{formatPercent(aEffectiveInterest)}</div>
                  </div>
                  {/* <div className="mobile-ls-output-col">
                    <p className="xxs-text">Total Return</p>
                    <div>{formatDollars(aReturn || 0)}</div>
                  </div> */}
                </div>
              </div>

              <div className="desktop-560-hidden box box-top">
                <p className="box-title">B Piece (Loan Participants)</p>
                <div className="mobile-560-flex-space">
                  <div className="mobile-ls-output-col">
                    <p className="xxs-text">Loan Amount</p>
                    <div>{formatDollars(bLoan || 0)}</div>
                  </div>
                  <div className="mobile-ls-output-col">
                    <p className="xxs-text">
                      Effective Annual Interest Rate (w/Points)
                    </p>
                    <div>{formatPercent(bEffectiveInterest)}</div>
                  </div>
                  {/* <div className="mobile-ls-output-col">
                    <p className="xxs-text">Total Return</p>
                    <div>{formatDollars(bReturn || 0)}</div>
                  </div> */}
                </div>
              </div>
            </Form>
          </Panel>
        </Row>

        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>
        </div>
      </div>
    );
  }
}

function addNums(a = "", b = "", c = "", d = " ") {
  const total = +a + +b + +c + +d;
  return total.toString();
}

function formatDollars(amnt) {
  if (!amnt) return "$0";
  const rounded = Math.round(+amnt);
  return `$${rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  //return `$${Number.parseFloat(formatted).toFixed(0)}`;
}

function formatPercent(num) {
  if (!num) return "N/A";
  return `${Number.parseFloat(num).toFixed(3)}%`;
}

function mapStateToProps(state) {
  return {
    ...state.loanSyndication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loanSyndicationActions: bindActionCreators(
      loanSyndicationActions,
      dispatch
    ),
    sharingCalculationsActions: bindActionCreators(
      sharingCalculationsActions,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanSyndication);
