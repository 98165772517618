import * as types from "../actions/actionTypes";

const initialState = {
  data: [],
  nextPageUrl: null,
  currentPage: null,
  loading: true,
  toast: {
    show: false,
    success: true,
    message: "",
  },
};

export default function searchDealsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SEARCHING_DEALS: {
      return Object.assign({}, state, { data: [], loading: true });
    }
    case types.LOAD_SEARCH_DEALS: {
      return Object.assign({}, state, {
        data: action.data,
        nextPageUrl: action.next_page_url,
        currentPage: action.current_page,
        loading: false,
      });
    }
    case types.LOADING_UPDATE_STAR:
      return Object.assign({}, state, { loading: true });
    case types.SEARCH_STAR_CHANGE_COMPLETED: {
      let data = [...state.data];
      data.find((i) => i.deal_id === action.deal_id).broker_starred = parseInt(
        action.broker_starred,
        10
      );
      return Object.assign({}, state, { data, loading: false });
    }
    case types.UPDATE_SEARCH_RATING_SUCCESS:
      let searchDeals = [...state.data];
      searchDeals.find(
        (i) => i.deal_id === action.deal_id
      ).deal_rating = parseInt(action.deal_rating, 10);
      return Object.assign({}, state, {
        data: searchDeals,
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.UPDATE_SEARCH_DEAL_IP_DATE:
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.CLOSE_SEARCH_DEALS_TOAST:
      return Object.assign({}, state, { toast: { ...initialState.toast } });
    default:
      return state;
  }
}
