import * as types from "../actions/actionTypes";

const initialState = {};

export default function serviceProviderReducer(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_NOTIFICATION:
      const { message, variant, duration } = action;
      return {
        ...state,
        notification: {
          message,
          variant,
          duration,
        },
      };
    case types.HIDE_NOTIFICATION:
      return {
        ...state,
        notification: undefined,
      };
    default:
      return state;
  }
}
