import {
  monthlyAnnualPaymentsAmortizationPeriod,
  calcBalanceAtMaturity,
} from "../calculators/MortgageDscr.logic";
import { numberToPercent, parseToNumber } from "../helpers";
const Finance = require("financejs");
const finance = new Finance();

export function calculateIRRSchedule({
  holding_period_yrs,
  projected_gross_income_rate,
  stabilized_vacancy,
  projected_annual_expense,
  projected_cap_rate_at_sale,
  projected_closing_costs_at_sale,
  potential_annual_gross_income,
  vacancy_as_percent,
  annual_expenses,
  io_years,
  amortization_years,
  mortgage_balance_amount,
  annual_mortgage_post_io,
  annual_mortgage_during_io,
  equity_needed,
  pgi,
  stabilized_vacancies,
  expenses,
  year_of_stabilization,
  total_capitalization,
  hasCapitalEvent,
  capitalEventType,
  capitalEventYear,
  capitalEventInterestRate,
  capitalEventAmortizationYears,
  capitalEventLoanAmount,
  capitalEventNetLoan,
}) {
  const equityNeeded = parseFloat(`${equity_needed}`.replace(/,/g, ""));
  const pgiYears = calculatePGI({
    holding_period_yrs,
    potential_annual_gross_income,
    projected_gross_income_rate,
    pgi,
  });
  const stabilizedVacancies = calculateVacancies({
    holding_period_yrs,
    pgiYears,
    stabilized_vacancies,
    year_of_stabilization,
    vacancy_as_percent,
    stabilized_vacancy,
  });
  const expensesArray = calculateExpenses({
    holding_period_yrs,
    annual_expenses,
    projected_annual_expense,
    expenses,
  });
  const effective_gross_income = calculateEffectiveGrossIncome({
    holding_period_yrs,
    pgiYears,
    stabilizedVacancies,
  });
  const noi = calculateNOI({
    holding_period_yrs,
    effective_gross_income,
    expensesArray,
  });
  const pi_payments = calculatePIPayments({
    holding_period_yrs,
    io_years,
    amortization_years,
    annual_mortgage_post_io,
    annual_mortgage_during_io,
  });
  const sale_price = noi[noi.length - 1] / (projected_cap_rate_at_sale / 100);
  const closing_costs = sale_price * (projected_closing_costs_at_sale / 100);
  const net_sale_price = sale_price - closing_costs;
  const net_distribution_from_sale = net_sale_price - mortgage_balance_amount;
  const net_cash_flow = calculateNetCashFlow({
    holding_period_yrs,
    noi,
    pi_payments,
    net_distribution_from_sale,
  });
  const total_distribution_to_sale =
    net_cash_flow.length >= 2
      ? net_cash_flow[net_cash_flow.length - 2].totalBeforeNDFS
      : 0;
  const grand_total_distribution =
    net_distribution_from_sale + total_distribution_to_sale;
  const irr = calculateIRR({ equityNeeded, net_cash_flow });
  const {
    capitalEventPiPayments,
    capitalEventNetCashFlow,
    capitalEventMortgageBalance,
    capitalEventNetDistFromSale,
    capitalEventTotalDistToSale,
    capitalEventGrandTotalDistFromSale,
    capitalEventIRR,
  } = calculateCapitalEventFigures({
    hasCapitalEvent,
    capitalEventType,
    capitalEventYear,
    capitalEventInterestRate,
    capitalEventAmortizationYears,
    capitalEventLoanAmount,
    capitalEventNetLoan,
    seniorPIPayments: pi_payments,
    seniorNCF: net_cash_flow,
    noi,
    net_sale_price,
    mortgage_balance_amount,
    holding_period_yrs,
    equityNeeded,
  });
  const { ROI, yieldOnCost, avgCashOnCash } = calculateReturnMetrics({
    year_of_stabilization,
    holding_period_yrs,
    noi,
    total_capitalization,
    equityNeeded,
    net_cash_flow,
    capitalEventNetCashFlow,
  });

  return {
    pgi: pgiYears,
    stabilized_vacancies: stabilizedVacancies,
    expenses: expensesArray,
    effective_gross_income,
    noi,
    pi_payments,
    net_cash_flow,
    sale_price,
    closing_costs,
    net_sale_price,
    mortgage_balance: mortgage_balance_amount,
    net_distribution_from_sale,
    total_distribution_to_sale,
    grand_total_distribution,
    irr,
    capitalEventPiPayments,
    capitalEventNetCashFlow,
    capitalEventMortgageBalance,
    capitalEventNetDistFromSale,
    capitalEventTotalDistToSale,
    capitalEventGrandTotalDistFromSale,
    capitalEventIRR,
    ROI,
    yieldOnCost,
    avgCashOnCash,
  };
}

function calculatePGI({
  holding_period_yrs,
  potential_annual_gross_income,
  projected_gross_income_rate,
  pgi,
}) {
  pgi =
    pgi && pgi.length === holding_period_yrs + 1
      ? pgi.map((p) => p.value)
      : [
          ...new Array(holding_period_yrs + 1).fill(
            projected_gross_income_rate / 100
          ),
        ];
  const pgiYears = [];
  pgi.forEach((value, index) => {
    pgiYears.push(
      index === 0
        ? {
            amount: parseFloat(
              `${potential_annual_gross_income}`.replace(/,/g, "")
            ),
          }
        : {
            amount: pgiYears[pgiYears.length - 1].amount * (1 + value),
            value: value,
          }
    );
  });
  return pgiYears;
}

function calculateVacancies({
  holding_period_yrs,
  pgiYears,
  stabilized_vacancies,
  year_of_stabilization,
  vacancy_as_percent,
  stabilized_vacancy,
}) {
  const calcVacancyAmnt = (index, value) => {
    return pgiYears[index].amount * value;
  };
  if (
    stabilized_vacancies &&
    stabilized_vacancies.length === holding_period_yrs + 1
  ) {
    return stabilized_vacancies.map((p, index) => {
      return index === 0
        ? {
            amount: calcVacancyAmnt(index, vacancy_as_percent / 100),
            value: vacancy_as_percent,
          }
        : { amount: calcVacancyAmnt(index, p.value), value: p.value };
    });
  } else {
    const vacancyPercentage =
      (numberToPercent(vacancy_as_percent) -
        numberToPercent(stabilized_vacancy || 5)) /
      (parseToNumber(year_of_stabilization || 2) - 1);
    return [...new Array(holding_period_yrs + 1)].map((val, index) => {
      const value =
        numberToPercent(vacancy_as_percent) - vacancyPercentage * index;
      return index === 0
        ? {
            amount: calcVacancyAmnt(index, vacancy_as_percent / 100),
            value: vacancy_as_percent,
          }
        : {
            amount: calcVacancyAmnt(index, value),
            value:
              index + 1 > year_of_stabilization
                ? numberToPercent(stabilized_vacancy)
                : value,
          };
    });
  }
}

function calculateExpenses({
  holding_period_yrs,
  annual_expenses,
  projected_annual_expense,
  expenses,
}) {
  expenses =
    expenses && expenses.length === holding_period_yrs + 1
      ? expenses.map((p) => p.value)
      : [
          ...new Array(holding_period_yrs + 1).fill(
            projected_annual_expense / 100
          ),
        ];
  annual_expenses = parseFloat(`${annual_expenses}`.replace(/,/g, ""));
  const expensesArray = [];
  expenses.forEach((value, index) => {
    expensesArray.push(
      index === 0
        ? { amount: annual_expenses, value: projected_annual_expense }
        : {
            amount:
              expensesArray[expensesArray.length - 1].amount * (1 + value),
            value: value,
          }
    );
  });
  return expensesArray;
}

function calculateEffectiveGrossIncome({
  holding_period_yrs,
  pgiYears,
  stabilizedVacancies,
}) {
  return [...new Array(holding_period_yrs + 1)].map((_, index) => {
    return pgiYears[index].amount - stabilizedVacancies[index].amount;
  });
}

function calculateNOI({
  holding_period_yrs,
  effective_gross_income,
  expensesArray,
}) {
  return [...new Array(holding_period_yrs + 1)].map((_, index) => {
    return effective_gross_income[index] - expensesArray[index].amount;
  });
}

function calculatePIPayments({
  holding_period_yrs,
  io_years,
  amortization_years,
  annual_mortgage_post_io,
  annual_mortgage_during_io,
}) {
  return [...new Array(holding_period_yrs + 1)].map((_, index) => {
    //last year should not be calculated since represents the year after building is sold
    if (index === holding_period_yrs) {
      return "";
    } else {
      return amortization_years <= index
        ? 0
        : io_years > index && annual_mortgage_during_io
        ? annual_mortgage_during_io
        : annual_mortgage_post_io;
    }
  });
}

function calculateNetCashFlow({
  holding_period_yrs,
  noi,
  pi_payments,
  net_distribution_from_sale,
}) {
  const NCF = [];
  const NDFS = net_distribution_from_sale ? net_distribution_from_sale : 0;
  [...new Array(holding_period_yrs + 1)].forEach((_, i) => {
    //last year should not be calculated since represents the year after building is sold
    if (i === holding_period_yrs) {
      NCF.push("");
    } else {
      const amount = noi[i] - pi_payments[i];
      const total = i === 0 ? amount : NCF[NCF.length - 1].total + amount;
      if (i === holding_period_yrs - 1) {
        NCF.push({
          totalBeforeNDFS: total,
          amountBeforeNDFS: amount,
          amount: amount + NDFS,
          total: total + NDFS,
        });
      } else {
        NCF.push({ amount, total });
      }
    }
  });
  return NCF;
}

function calculateIRR({ equityNeeded, net_cash_flow }) {
  try {
    return finance.IRR(
      -equityNeeded,
      ...net_cash_flow.slice(0, -1).map((v) => v.amount)
    );
  } catch (e) {
    // if (e.message) {
    //   console.log("IRR cannot be calculated with the inputted values.");
    // }
  }
}

function calculatePIPaymentsWithCapitalEvent({
  seniorPIPayments,
  hasCapitalEvent,
  capitalEventType,
  capitalEventYear,
  capitalEventInterestRate,
  capitalEventAmortizationYears,
  capitalEventLoanAmount,
}) {
  return seniorPIPayments.map((value, i, arr) => {
    if (i === arr.length - 1 || i < +capitalEventYear) {
      return value;
    } else {
      const { annual } = monthlyAnnualPaymentsAmortizationPeriod({
        loan_amount_dollar: capitalEventLoanAmount,
        interest_rate: capitalEventInterestRate,
        amortization_years: capitalEventAmortizationYears,
      });
      const capitalEventPI = capitalEventAmortizationYears <= i ? 0 : annual;
      return capitalEventType === "refi"
        ? capitalEventPI
        : capitalEventPI + value;
    }
  });
}

function calculateNetCashFlowWithCapitalEvent({
  seniorNCF,
  capitalEventYear,
  capitalEventPiPayments,
  capitalEventNetLoan,
  noi,
  capitalEventNetDistFromSale,
}) {
  const NCF = [];
  const NDFS = capitalEventNetDistFromSale ? capitalEventNetDistFromSale : 0;
  seniorNCF.forEach((value, i, arr) => {
    if (i === arr.length - 1 || i < capitalEventYear - 1) {
      NCF.push(value);
    } else {
      let amount = noi[i] - capitalEventPiPayments[i];
      if (i === capitalEventYear - 1) {
        amount = amount + capitalEventNetLoan;
      }
      const total = i === 0 ? amount : NCF[NCF.length - 1].total + amount;
      if (i === arr.length - 2) {
        NCF.push({
          totalBeforeNDFS: total,
          amountBeforeNDFS: amount,
          amount: amount + NDFS,
          total: total + NDFS,
        });
      } else {
        NCF.push({ amount, total });
      }
    }
  });
  return NCF;
}

function calculateCapitalEventFigures({
  hasCapitalEvent,
  capitalEventType,
  capitalEventYear,
  capitalEventInterestRate,
  capitalEventAmortizationYears,
  capitalEventLoanAmount,
  capitalEventNetLoan,
  seniorPIPayments,
  seniorNCF,
  noi,
  net_sale_price,
  mortgage_balance_amount,
  holding_period_yrs,
  equityNeeded,
}) {
  if (
    !hasCapitalEvent ||
    !capitalEventYear ||
    !capitalEventInterestRate ||
    !capitalEventAmortizationYears ||
    !capitalEventLoanAmount ||
    capitalEventLoanAmount < 1 ||
    !capitalEventNetLoan
  ) {
    return {};
  }

  const capitalEventPiPayments = calculatePIPaymentsWithCapitalEvent({
    seniorPIPayments,
    hasCapitalEvent,
    capitalEventType,
    capitalEventYear,
    capitalEventInterestRate,
    capitalEventAmortizationYears,
    capitalEventLoanAmount,
  });
  const { results: eventMortgageBalance } = calcBalanceAtMaturity({
    loan_amount_dollar: capitalEventLoanAmount,
    interest_rate: capitalEventInterestRate,
    amortization_years: capitalEventAmortizationYears,
    holding_period_yrs: holding_period_yrs - capitalEventYear,
  });
  const capitalEventMortgageBalance =
    capitalEventType === "refi"
      ? eventMortgageBalance
      : eventMortgageBalance + mortgage_balance_amount;
  const capitalEventNetDistFromSale =
    net_sale_price - capitalEventMortgageBalance;
  const capitalEventNetCashFlow = calculateNetCashFlowWithCapitalEvent({
    seniorNCF,
    capitalEventYear,
    capitalEventPiPayments,
    capitalEventNetLoan,
    noi,
    capitalEventNetDistFromSale,
  });
  const capitalEventTotalDistToSale =
    capitalEventNetCashFlow.length >= 2
      ? capitalEventNetCashFlow[capitalEventNetCashFlow.length - 2]
          .totalBeforeNDFS
      : 0;
  const capitalEventGrandTotalDistFromSale =
    capitalEventNetDistFromSale + capitalEventTotalDistToSale;

  const capitalEventIRR = calculateIRR({
    equityNeeded,
    net_cash_flow: capitalEventNetCashFlow,
  });

  return {
    capitalEventPiPayments,
    capitalEventMortgageBalance,
    capitalEventNetDistFromSale,
    capitalEventNetCashFlow,
    capitalEventTotalDistToSale,
    capitalEventGrandTotalDistFromSale,
    capitalEventIRR,
  };
}

function calculateReturnMetrics({
  year_of_stabilization,
  holding_period_yrs,
  noi = [],
  total_capitalization,
  equityNeeded,
  net_cash_flow = [],
  capitalEventNetCashFlow,
}) {
  if (
    !year_of_stabilization ||
    !total_capitalization ||
    !equityNeeded ||
    holding_period_yrs < year_of_stabilization
  ) {
    return {};
  }
  const NCF = capitalEventNetCashFlow ? capitalEventNetCashFlow : net_cash_flow;
  const YOS_NOI = noi[year_of_stabilization - 1];
  let YOS_NCF = NCF[year_of_stabilization - 1];
  const lastNCF = NCF[holding_period_yrs - 1];
  if (!YOS_NCF || !lastNCF) return {};
  YOS_NCF = YOS_NCF.amount;
  const ROI = YOS_NCF / equityNeeded;
  const yieldOnCost = YOS_NOI / total_capitalization;
  const avgNCF = lastNCF.total / (NCF.length - 1);
  const avgCashOnCash = avgNCF / equityNeeded;
  return { ROI, yieldOnCost, avgCashOnCash };
}
