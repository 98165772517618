import React from "react";

class Expire extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // reset the timer if children are changed
    if (nextProps.children !== this.props.children) {
      this.setTimer();
      this.setState({ visible: true });
    }
  }
  closeToast() {
    this.props.closeToast();
  }
  componentDidMount() {
    this.setTimer();
  }
  setTimer() {
    // clear any existing timer
    if (this._timer) {
      clearTimeout(this._timer);
    }

    // hide after `delay` milliseconds
    this._timer = setTimeout(
      function () {
        this.setState({ visible: false });
        this.closeToast();
        this._timer = null;
      }.bind(this),
      this.props.delay
    );
  }
  componentWillUnmount() {
    clearTimeout(this._timer);
  }
  render() {
    return <div className={this.props.className}>{this.props.children}</div>;
    // return this.state.visible ? (
    // <div className={this.props.className}>{this.props.children}</div>
    // ) : (
    //   <span />
    // );
  }
}
export default Expire;
