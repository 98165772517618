import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import clsx from "clsx";
import { useStyles } from "./CalculatorToolbar.style";
import { Toolbar, IconButton, Tooltip, useMediaQuery } from "@material-ui/core";
import { ClearIcon, SaveIcon, ShareIcon, ExportIcon } from "../../../themes";
import {
  ClearConfirmationModal,
  SaveEcalcModal,
  ShareEcalcModal,
  Notification,
} from "../components";
import {
  ECALC_SHOW_SIDE_RESULTS,
  ECALC_TABLET_VIEW,
  Navigation,
} from "../../../lib";
import { ExportEcalcModal } from "./ExportEcalcModal";
import {
  createPublicEcalc,
  clearToolbarData,
} from "../../../actions/eCalcForms";
import Loader from "../../common/Loader";

const desktopSize = ECALC_SHOW_SIDE_RESULTS.match(/[0-9]+/g);
const tabletSize = ECALC_TABLET_VIEW.match(/[0-9]+/g);

export function CalculatorToolbar({
  storeSliceName,
  isDealDetails,
  persist,
  isMobile,
  handleSave,
}) {
  const dispatch = useDispatch();
  const classes = useStyles({ isDealDetails });

  const showToolBar = useMediaQuery((theme) =>
    theme.breakpoints.up(tabletSize)
  );
  const tabletToolBar = useMediaQuery((theme) =>
    theme.breakpoints.down(desktopSize)
  );

  const initialNotificationState = {
    show: false,
    message: null,
    buttonTxt: null,
    allowAutoHide: false,
    onClick: () => {},
  };
  const [notification, setNotification] = useState(initialNotificationState);
  const ecalcData = useSelector((state) => state[storeSliceName], shallowEqual);
  const dirty = useSelector((state) => state[storeSliceName].dirty);
  const ecalc_id = useSelector((state) => state[storeSliceName].ecalc_id);
  const loading = useSelector((state) => state.eCalcFormsNoPersist.loading);
  const publicEcalc = useSelector(
    (state) => state.eCalcFormsNoPersist.publicEcalc,
    shallowEqual
  );
  const [showSaveEcalcModal, setShowSaveEcalcModal] = useState(false);
  const [showExportEcalcModal, setShowExportEcalcModal] = useState(false);
  const [showShareEcalcModal, setShowShareEcalcModal] = useState(false);
  const [showClearConfirmationModal, setShowClearConfirmationModal] = useState(
    false
  );

  const { action, success, message, link } = publicEcalc || {};

  useEffect(() => {
    if (action === "share") {
      if (success && link) {
        if (window && window.isNativeApp && window.canShareUrl) {
          setShowShareEcalcModal(true);
        } else {
          const a = document.createElement("a");
          a.target = "_blank";
          a.href = `mailto:?subject=Shared%20Underwriting%20Document:%20Link%20Enclosed&body=Please see the confidential underwriting file I just created using the updated Eastern Union eCalc app. Simply click on the link below to download and view.%0D%0A%0D%0A${encodeURIComponent(
            link
          )}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          dispatch(clearToolbarData());
        }
      } else if (!success && message) {
        setNotification({ show: true, message, allowAutoHide: true });
      }
    }
  }, [action, success, message, link, dispatch]);

  const Save = () => {
    if (!JSON.parse(localStorage.getItem("data"))) {
      setNotification({
        show: true,
        message: "You must be logged in to save a calculation",
        buttonTxt: "Log In",
        allowAutoHide: false,
        onClick: () => Navigation.redirect("/login"),
      });
    } else {
      !isDealDetails ? setShowSaveEcalcModal(true) : handleSave();
    }
  };

  const Share = async () => {
    await dispatch(createPublicEcalc({ data: ecalcData, action: "share" }));
  };

  const isDirty = dirty || ecalc_id;
  return (
    <Fragment>
      {loading && <Loader />}
      {showToolBar && (
        <div className={classes.toolbarContainer}>
          <Toolbar className={classes.toolbar}>
            <Tooltip
              title="Save"
              placement="bottom"
              disableFocusListener={!isDirty}
              disableHoverListener={!isDirty}
              disableTouchListener={!isDirty}
            >
              <div
                className={clsx(
                  { [classes.iconContainer]: tabletToolBar },
                  { [classes.disabled]: tabletToolBar && !isDirty }
                )}
              >
                <IconButton
                  onClick={() => Save()}
                  className={classes.iconBtn}
                  disabled={!isDirty}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  <SaveIcon
                    className={isDirty || tabletToolBar ? classes.icon : null}
                  />
                </IconButton>
                {tabletToolBar && (
                  <span className={classes.iconText}>Save</span>
                )}
              </div>
            </Tooltip>

            {!isDealDetails && (
              <Tooltip
                title="Clear"
                placement="bottom"
                disableFocusListener={!isDirty}
                disableHoverListener={!isDirty}
                disableTouchListener={!isDirty}
              >
                <div
                  className={clsx(
                    { [classes.iconContainer]: tabletToolBar },
                    { [classes.disabled]: tabletToolBar && !isDirty }
                  )}
                >
                  <IconButton
                    onClick={() => setShowClearConfirmationModal(true)}
                    className={classes.iconBtn}
                    disabled={!isDirty}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                  >
                    <ClearIcon
                      className={isDirty || tabletToolBar ? classes.icon : null}
                    />
                  </IconButton>
                  {tabletToolBar && (
                    <span className={classes.iconText}>Clear</span>
                  )}
                </div>
              </Tooltip>
            )}

            <Tooltip
              title="Share"
              placement="bottom"
              disableFocusListener={!isDirty}
              disableHoverListener={!isDirty}
              disableTouchListener={!isDirty}
            >
              <div
                className={clsx(
                  { [classes.iconContainer]: tabletToolBar },
                  { [classes.disabled]: tabletToolBar && !isDirty }
                )}
              >
                <IconButton
                  onClick={() => Share()}
                  className={classes.iconBtn}
                  disabled={!isDirty}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  <ShareIcon
                    className={isDirty || tabletToolBar ? classes.icon : null}
                  />
                </IconButton>
                {tabletToolBar && (
                  <span className={classes.iconText}>Share</span>
                )}
              </div>
            </Tooltip>

            <Tooltip
              title="Export"
              placement="bottom"
              disableFocusListener={!isDirty}
              disableHoverListener={!isDirty}
              disableTouchListener={!isDirty}
            >
              <div
                className={clsx(
                  { [classes.iconContainer]: tabletToolBar },
                  { [classes.disabled]: tabletToolBar && !isDirty }
                )}
              >
                <IconButton
                  onClick={() => setShowExportEcalcModal(true)}
                  className={classes.iconBtn}
                  disabled={!isDirty}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  <ExportIcon
                    className={isDirty || tabletToolBar ? classes.icon : null}
                  />
                </IconButton>
                {tabletToolBar && (
                  <span className={classes.iconText}>Export</span>
                )}
              </div>
            </Tooltip>
          </Toolbar>

          {showClearConfirmationModal && (
            <ClearConfirmationModal
              handleClose={() => setShowClearConfirmationModal(false)}
              persist={persist}
            />
          )}

          {showSaveEcalcModal && (
            <SaveEcalcModal
              storeSliceName={storeSliceName}
              handleOpen={() => setShowSaveEcalcModal(true)}
              handleClose={() => setShowSaveEcalcModal(false)}
            />
          )}

          {showExportEcalcModal && (
            <ExportEcalcModal
              storeSliceName={storeSliceName}
              handleOpen={() => setShowExportEcalcModal(true)}
              handleClose={() => setShowExportEcalcModal(false)}
            />
          )}

          {showShareEcalcModal && (
            <ShareEcalcModal
              storeSliceName={storeSliceName}
              handleOpen={() => setShowShareEcalcModal(true)}
              handleClose={() => setShowShareEcalcModal(false)}
            />
          )}

          {notification && notification.show && (
            <Notification
              open={notification.show}
              initialNotificationState={initialNotificationState}
              message={notification.message}
              buttonTxt={notification.buttonTxt}
              isMobile={isMobile}
              maxMsgWidth={isMobile ? 352 : 563}
              horizontalAnchor="left"
              style={{
                top: 55,
                position: "fixed",
                left: isMobile ? 8 : 250,
              }}
              onClick={notification.onClick}
              hideNotification={setNotification}
              allowAutoHide={notification.allowAutoHide}
            />
          )}
        </div>
      )}
    </Fragment>
  );
}
