import * as types from "../../actions/actionTypes";

export default function (state = {}, action) {
  switch (action.type) {
    case types.UPDATE_SIMPLE_UNDERWRITING:
      return action.values;
    default:
      return state;
  }
}
