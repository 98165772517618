import React, { useEffect } from "react";
import clsx from "clsx";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { eCalcForms } from "../../../lib";
import { eCalcOnChange, addUserEntryMethod } from "../../../actions/eCalcForms";
import {
  parseToNumber,
  formatDollar,
  entryMethodNames,
  entryMethods,
} from "../helpers";
import { useStyles } from "./ResultModal.styles";
import _cloneDeep from "lodash.clonedeep";
import mixpanel from "mixpanel-browser";
import { CloseIcon } from "../../../themes";

export function MaxBankWillLend({
  open,
  handleClose,
  storeSliceName,
  persist,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mortgageAndDscrValues = useSelector(
    (state) => state[storeSliceName][eCalcForms.MORTGAGE_AND_DSCR],
    shallowEqual
  );
  const loans = useSelector(
    (state) => state[storeSliceName][eCalcForms.EQUITY_NEEDED].loans
  );
  const loan_amount_dollar = useSelector(
    (state) =>
      state[storeSliceName][eCalcForms.EQUITY_NEEDED].loan_amount_dollar
  );
  const first_year_noi = useSelector(
    (state) => state[storeSliceName][eCalcForms.CAP_RATE].first_year_noi
  );

  const {
    bank_minimum_DSCR,
    amortization_years,
    io_years,
    interest_rate,
    bank_max_loan_during_io,
    bank_max_loan_post_io,
  } = mortgageAndDscrValues || {};

  useEffect(() => {
    calculateMaxLoan({
      state: {
        first_year_noi: parseToNumber(first_year_noi),
        amortization_years: parseToNumber(amortization_years),
        io_years: parseToNumber(io_years),
        interest_rate: parseToNumber(interest_rate),
        bank_minimum_DSCR: parseToNumber(bank_minimum_DSCR),
      },
      dispatch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const intValue = value.replace(/[^0-9.]/g, ""); //Strips of non-numeric characters besides decimal
    dispatch(
      eCalcOnChange({
        calculator: eCalcForms.MORTGAGE_AND_DSCR,
        name,
        value: intValue,
        persist,
      })
    );
    if (name === "bank_minimum_DSCR") {
      calculateMaxLoan({
        state: {
          first_year_noi: parseToNumber(first_year_noi),
          amortization_years: parseToNumber(amortization_years),
          io_years: parseToNumber(io_years),
          interest_rate: parseToNumber(interest_rate),
          bank_minimum_DSCR: intValue,
        },
        dispatch,
      });
    }
  }

  function saveLoanAmnt() {
    const newLoans = _cloneDeep(loans);
    let loansWithAmount = newLoans.filter((loan) => loan.amount.length);
    if (loansWithAmount.length) {
      let difference =
        bank_max_loan_post_io - parseToNumber(loan_amount_dollar);
      for (let index = 1; index <= loansWithAmount.length; index++) {
        const loanIndex = newLoans.findIndex(
          (loan) =>
            loan.name === loansWithAmount[loansWithAmount.length - index].name
        );
        const loanAmount = parseToNumber(newLoans[loanIndex].amount);
        if (difference < 0 && loanAmount < Math.abs(difference)) {
          newLoans[loanIndex].amount = "0";
          difference = difference + loanAmount;
        } else {
          newLoans[loanIndex].amount = formatDollar(loanAmount + difference);
          difference = 0;
        }
      }
      dispatch(
        eCalcOnChange({
          calculator: eCalcForms.EQUITY_NEEDED,
          name: "loans",
          value: newLoans,
          persist,
        })
      );
    }
    dispatch(
      eCalcOnChange({
        calculator: eCalcForms.EQUITY_NEEDED,
        name: "loan_amount_dollar",
        value: formatDollar(bank_max_loan_post_io),
        persist,
      })
    );
    dispatch(
      addUserEntryMethod({
        name: entryMethodNames.LOAN_AMOUNT,
        value: entryMethods.DOLLAR_AMOUNT,
        persist,
      })
    );
    mixpanel.track("Dscr Modal - added as Loan Amount");
    handleClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogContent className={classes.dialogContent}>
        <div>
          <span className={classes.resultModalTitle}>Max from DSCR</span>
          <CloseIcon className={classes.closeBtn} onClick={handleClose} />
        </div>
        <DialogContentText classes={{ root: classes.resultModalMsg }}>
          If bank’s mininum DSCR is{" "}
          <input
            name="bank_minimum_DSCR"
            value={`${bank_minimum_DSCR}`}
            onChange={handleInputChange}
            className={classes.resultModalInput}
          />
          <br />
          {!!bank_max_loan_during_io ? (
            <span>
              During I/O, the bank will lend you{" "}
              <span className={classes.resultModalCalcAmount}>
                ${formatDollar(bank_max_loan_during_io)}
              </span>{" "}
              Based on first year after I/O, the bank will lend you{" "}
              <span className={classes.resultModalCalcAmount}>
                ${formatDollar(bank_max_loan_post_io)}
              </span>
            </span>
          ) : (
            <span>
              the bank will lend you{" "}
              <span className={classes.resultModalCalcAmount}>
                ${formatDollar(bank_max_loan_post_io)}
              </span>
            </span>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ paddingRight: 20 }}>
        <button
          onClick={handleClose}
          className={clsx(classes.resultModalBtn, classes.resultModalCancel)}
        >
          Cancel
        </button>
        <button
          onClick={saveLoanAmnt}
          className={clsx(classes.resultModalBtn, classes.resultModalSave)}
          style={{ fontWeight: "bold" }}
        >
          Add as Loan Amount
        </button>
      </DialogActions>

      {loans.find((loan) => loan.amount.length) && (
        <p className={classes.blendedRateNote}>
          Note: Differences to Loan Amount will be added/subtracted to the last
          mortgage added.
        </p>
      )}
    </Dialog>
  );
}

function calculateMaxLoan({ state, dispatch }) {
  const {
    first_year_noi,
    bank_minimum_DSCR,
    amortization_years,
    io_years,
    interest_rate,
  } = state;
  let maxLoan = 0;
  if (
    first_year_noi &&
    bank_minimum_DSCR &&
    interest_rate &&
    amortization_years
  ) {
    const amortization_months = amortization_years
      ? amortization_years * 12
      : 0;
    //const io_months = io_years ? io_years * 12 : 0;
    const interestRate = interest_rate / 100;

    const var1 = first_year_noi / 12 / bank_minimum_DSCR;
    const var2 = Math.pow(1 + interestRate / 12, amortization_months) - 1;
    const var3 =
      (interestRate / 12) *
      Math.pow(1 + interestRate / 12, amortization_months);
    maxLoan = var1 * (var2 / var3);

    // calculate during IO years
    if (io_years) {
      //const value1 = Math.pow(1 + interestRate / 12, io_months) - 1;
      //const value2 = (interestRate / 12) * Math.pow(1 + interestRate / 12, io_months);
      // const results = var1 * (value1 / value2);
      // Per DB: The code for I/O only is incorrect.
      // The calculation should simply read "const results = first_year_noi / bank_minimum_DSCR / interestRate".
      const results = first_year_noi / bank_minimum_DSCR / interestRate;
      dispatch(
        eCalcOnChange({
          calculator: eCalcForms.MORTGAGE_AND_DSCR,
          name: "bank_max_loan_during_io",
          value: results,
        })
      );
    }
  }
  dispatch(
    eCalcOnChange({
      calculator: eCalcForms.MORTGAGE_AND_DSCR,
      name: "bank_max_loan_post_io",
      value: maxLoan,
    })
  );
}
