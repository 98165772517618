import React from "react";
import DatePicker from "react-date-picker";
import moment from "moment";

class FormattedDatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null,
    };
  }

  UNSAFE_componentWillMount() {
    let date = this.props.dateValue;

    if (date && moment(date).isValid()) {
      const fulldate = date.includes(":")
        ? moment(date).toISOString()
        : moment(date + " 00:00").toISOString();
      this.setState({ date: fulldate });
    }
  }

  onChange(value) {
    let formattedDate = value ? moment(value).format("YYYY-MM-DD") : null;
    this.props.update(formattedDate);
    this.setState({ date: value });
  }

  render() {
    const { date } = this.state;
    const { className, placeholderClassName, allowClear = true } = this.props;
    return (
      <DatePicker
        {...this.props}
        className={`${className} ${
          date === null && placeholderClassName ? placeholderClassName : ""
        }`}
        calendarIcon={
          <i className="material-icons large-text">calendar_today</i>
        }
        clearIcon={
          allowClear && date ? (
            <i className="material-icons large-text">close</i>
          ) : null
        }
        value={date ? new Date(date) : date}
        onChange={(value) => this.onChange(value)}
      />
    );
  }
}

export default FormattedDatePicker;
