import React from "react";
import { connect } from "react-redux";
import * as cashOnCashActions from "../../../actions/calculatorActions/cashOnCashActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentInput from "../calculatorCommon/PercentInput";
import NumberInput from "../calculatorCommon/NumberInput";
import DollarDisplay from "../calculatorCommon/DollarDisplay";
import PercentDisplay from "../calculatorCommon/PercentDisplay";
import DollarDisplayWithoutDecimal from "../calculatorCommon/DollarDisplayWithoutDecimal";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import Option from "muicss/lib/react/option";
import Select from "muicss/lib/react/select";
import * as centralStateActions from "../../../actions/calculatorActions/centralActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import formatValue from "../calculatorCommon/FormatValueMethod";
import formatDisplay from "../calculatorCommon/FormatDisplayMethod";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";

class CashOnCash extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cashOnCashReturn: this.props.cashOnCashReturn,
      totalCashNeeded: this.props.totalCashNeeded,
      purchasePrice: this.props.purchasePrice,
      closingCostAmount: this.props.closingCostAmount,
      closingCostPercent: this.props.closingCostPercent,
      allInCosts: this.props.allInCosts,
      loanToValuePercent: this.props.loanToValuePercent,
      loanToValueAmount: this.props.loanToValueAmount,
      percentageOf: this.props.percentageOf,
      interestRate: this.props.interestRate,
      amortizationYears: this.props.amortizationYears,
      annualPayment: this.props.annualPayment,
      year1NOIBeforeDebtService: this.props.year1NOIBeforeDebtService,
      freeCashFlow: this.props.freeCashFlow,

      modalOpen: false,
      from: "",
      to: "",
      cc: "",
    };

    this.update = this.update.bind(this);
    this.updateSelect = this.updateSelect.bind(this);
    this.updateCentralState = this.updateCentralState.bind(this);
  }

  updateSelect(event) {
    let stateObj = {};
    stateObj.percentageOf = event.target.value;
    this.setState(stateObj, function () {
      this.props.dispatch(
        centralStateActions.updateCentralValues(
          "percentageOf",
          stateObj.percentageOf
        )
      );
      this.props.dispatch(
        cashOnCashActions.calculateCashOnCash(this.state, stateObj)
      );
    });
  }

  update(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      this.props.dispatch(
        cashOnCashActions.calculateCashOnCash(this.state, { name, value })
      );
    });
  }

  updateCentralState(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    if (name === "amortizationYears") {
      const monthsName = "amortizationMonths";
      const monthsValue = value * 12;
      let stateObj2 = {};
      stateObj2[monthsName] = monthsValue;
      this.setState(stateObj, function () {
        this.props.dispatch(
          centralStateActions.updateCentralValues(monthsName, monthsValue)
        );
      });
    }
    this.setState(stateObj, function () {
      this.props.dispatch(centralStateActions.updateCentralValues(name, value));
      this.props.dispatch(
        cashOnCashActions.calculateCashOnCash(this.state, { name, value })
      );
    });
  }

  componentDidMount() {
    this.update("interestRate", this.props.interestRate);
  }

  openSharingCalculatorModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  afterOpenModal() {}

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateCC(value) {
    this.setState({ cc: value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ modalOpen: false }, function () {
      var input = [
        { input0: formatValue(this.state.purchasePrice).toString() },
        { input1: formatValue(this.state.closingCostAmount).toString() },
        { input2: (this.state.closingCostPercent + "%").toString() },
        { input3: formatValue(this.state.allInCosts).toString() },
        { input4: (this.state.loanToValuePercent + "%").toString() },
        { input5: formatValue(this.state.loanToValueAmount).toString() },
        { input6: this.state.percentageOf.toString() },
        { input7: (this.state.interestRate + "%").toString() },
        { input8: this.state.amortizationYears.toString() },
        { input9: formatValue(this.state.annualPayment).toString() },
        {
          input10: formatValue(this.state.year1NOIBeforeDebtService).toString(),
        },
        { input11: formatValue(this.state.freeCashFlow).toString() },
      ];

      var output = [
        { output0: (this.state.cashOnCashReturn.toFixed(2) + "%").toString() },
        { output1: formatDisplay(this.state.totalCashNeeded).toString() },
      ];
      this.props.dispatch(
        sharingCalculationsActions.calculatorSharing(
          this.state.to,
          this.state.from,
          this.state.cc,
          "CashOnCash",
          input,
          output
        )
      );
    });
  }

  render() {
    return (
      <div>
        <Row>
          <ShareCalcModal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal.bind(this)}
            onAfterOpen={this.afterOpenModal.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            from={this.state.from}
            to={this.state.to}
            cc={this.state.cc}
            updateFrom={this.updateFrom.bind(this)}
            updateTo={this.updateTo.bind(this)}
            updateCC={this.updateCC.bind(this)}
          />
          <Panel className="calculator-card">
            <Form className="calculator-form" inline={true}>
              <h2 className="calculator-title">Cash on Cash</h2>
              <div className="margin-top"></div>
              <DollarInput
                name="purchasePrice"
                value={this.state.purchasePrice}
                update={this.update}
                label="Purchase Price"
              />
              <br />
              <br />
              <DollarInput
                name="closingCostAmount"
                value={this.state.closingCostAmount}
                update={this.update}
                label="Closing Cost $"
              />
              <PercentInput
                name="closingCostPercent"
                value={this.state.closingCostPercent}
                update={this.update}
                label="Closing Cost %"
              />
              <br />
              <br />
              <br />
              <div className="mui-textfield calculator-display">
                <label>All in Costs</label>
                <DollarDisplayWithoutDecimal
                  name="allInCosts"
                  value={this.state.allInCosts}
                  label="All in Costs"
                />
              </div>
              <br />
              <br />
              <PercentInput
                name="loanToValuePercent"
                value={this.state.loanToValuePercent}
                update={this.updateCentralState}
                label="Loan to Value %"
              />
              <div className="mui-textfield calculator-display">
                <label>Loan to Value </label>
                <DollarDisplayWithoutDecimal
                  name="loanToValueAmount"
                  value={this.state.loanToValueAmount}
                  label="Loan to Value $"
                />
              </div>
              <br />
              <br />
              <Select
                name="percentageOf"
                value={this.state.percentageOf}
                onChange={this.updateSelect}
                label="Percentage of"
              >
                <Option value="Purchase Price" label="Purchase Price" />
                <Option value="All in Costs" label="All in Costs" />
              </Select>
              <br />
              <PercentInput
                name="interestRate"
                value={this.state.interestRate}
                update={this.updateCentralState}
                label="Interest Rate"
              />
              <NumberInput
                name="amortizationYears"
                value={this.state.amortizationYears}
                update={this.updateCentralState}
                label="Amortization"
              />
              <br />
              <br />
              <br />
              <div className="mui-textfield calculator-display">
                <label>Annual Payment</label>
                <DollarDisplay
                  name="annualPayment"
                  value={this.state.annualPayment}
                />
              </div>
              <br />
              <br />
              <DollarInput
                name="year1NOIBeforeDebtService"
                value={this.state.year1NOIBeforeDebtService}
                update={this.updateCentralState}
                label="First Year NOI (Before Debt Service)"
              />
              <div className="mui-textfield calculator-display">
                <label>Free Cash Flow (After Debt Service)</label>
                <DollarDisplay
                  name="freeCashFlow"
                  value={this.state.freeCashFlow}
                />
              </div>
            </Form>
            <div id="boxes">
              <p
                className={
                  this.state.purchasePrice &&
                  this.state.closingCostAmount &&
                  this.state.loanToValuePercent &&
                  this.state.interestRate &&
                  this.state.amortizationYears &&
                  this.state.year1NOIBeforeDebtService
                    ? "share-calc"
                    : "hidden"
                }
                onClick={this.openSharingCalculatorModal.bind(this)}
              >
                <i className="material-icons">share</i>Share Calculations
              </p>
              <div className="box orange">
                <p className="box-title">Equity Needed</p>
                <p className="amount">
                  <DollarDisplay
                    name="totalCashNeeded"
                    value={this.state.totalCashNeeded}
                  />
                </p>
              </div>
              <div className="box">
                <p className="box-title">First Year Cash on Cash Return</p>
                <p className="amount">
                  <PercentDisplay
                    name="cashOnCashReturn"
                    value={this.state.cashOnCashReturn}
                  />
                </p>
              </div>
            </div>
          </Panel>
        </Row>

        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const state1 = state.cashOnCash;
  const state2 = state.centralValues;
  return {
    cashOnCashReturn: state1.cashOnCashReturn || "",
    totalCashNeeded: state1.totalCashNeeded || "",
    purchasePrice: state2.purchasePrice || "",
    closingCostAmount: state2.closingCostAmount || "",
    closingCostPercent: state2.closingCostPercent || "",
    allInCosts: state1.allInCosts || "",
    loanToValuePercent: state2.loanToValuePercent || "",
    loanToValueAmount: state1.loanToValueAmount || "",
    percentageOf: state2.percentageOf || "Purchase Price",
    interestRate: state2.interestRate | "",
    amortizationYears: state2.amortizationYears || "",
    annualPayment: state1.annualPayment || "",
    year1NOIBeforeDebtService: state2.year1NOIBeforeDebtService || "",
    freeCashFlow: state1.freeCashFlow || "",
  };
}

export default connect(mapStateToProps)(CashOnCash);
