import React from "react";
import { connect } from "react-redux";
import * as debtYieldActions from "../../../actions/calculatorActions/debtYieldActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentInput from "../calculatorCommon/PercentInput";
import DollarDisplay from "../calculatorCommon/DollarDisplay";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import * as centralStoreActions from "../../../actions/calculatorActions/centralActions";
import formatValue from "../calculatorCommon/FormatValueMethod";
import displayValue from "../calculatorCommon/FormatDisplayMethod";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";

class DebtYield extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      annualUnderwrittenNOI: this.props.annualUnderwrittenNOI,
      minimumDebtYield: this.props.minimumDebtYield,
      maximumLoan: this.props.maximumLoan,

      modalOpen: false,
      from: "",
      to: "",
      cc: "",
    };

    this.update = this.update.bind(this);
    this.updateCentralState = this.updateCentralState.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(debtYieldActions.updateOnUnmount(this.state));
  }

  update(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      if (this.state.annualUnderwrittenNOI && this.state.minimumDebtYield) {
        this.props.dispatch(debtYieldActions.calculateDebtYield(this.state));
      } else {
        this.props.dispatch(debtYieldActions.updateValuesDebtYield(this.state));
      }
    });
  }

  updateCentralState(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      this.props.dispatch(centralStoreActions.updateCentralValues(name, value));
      if (this.state.annualUnderwrittenNOI && this.state.minimumDebtYield) {
        this.props.dispatch(debtYieldActions.calculateDebtYield(this.state));
      } else {
        this.props.dispatch(debtYieldActions.updateValuesDebtYield(this.state));
      }
    });
  }

  componentDidMount() {
    this.update("annualUnderwrittenNOI", this.state.annualUnderwrittenNOI);
  }

  openSharingCalculatorModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateCC(value) {
    this.setState({ cc: value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ modalOpen: false }, function () {
      var input = [
        { input0: formatValue(this.state.annualUnderwrittenNOI).toString() },
        { input1: (this.state.minimumDebtYield + "%").toString() },
      ];

      var output = [
        { output0: displayValue(this.state.maximumLoan).toString() },
      ];

      this.props.dispatch(
        sharingCalculationsActions.calculatorSharing(
          this.state.to,
          this.state.from,
          this.state.cc,
          "DebtYield",
          input,
          output
        )
      );
    });
  }

  render() {
    return (
      <div>
        <Row>
          <ShareCalcModal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal.bind(this)}
            onAfterOpen={() => {}}
            onSubmit={this.onSubmit.bind(this)}
            from={this.state.from}
            to={this.state.to}
            cc={this.state.cc}
            updateFrom={this.updateFrom.bind(this)}
            updateTo={this.updateTo.bind(this)}
            updateCC={this.updateCC.bind(this)}
          />
          <Panel className="calculator-card">
            <Form className="calculator-form" inline={true}>
              <h2 className="calculator-title">Max Loan Via Debt Yield</h2>
              <h4>Calculate my maximum loan based on debt yield</h4>
              <div className="margin-top"></div>
              <DollarInput
                name="annualUnderwrittenNOI"
                value={this.state.annualUnderwrittenNOI}
                update={this.updateCentralState}
                label="First Year NOI"
              />
              <PercentInput
                name="minimumDebtYield"
                value={this.state.minimumDebtYield}
                update={this.update}
                label="Minimum Debt Yield"
              />
            </Form>
            <div id="boxes">
              <p
                className={
                  this.state.annualUnderwrittenNOI &&
                  this.state.minimumDebtYield
                    ? "share-calc"
                    : "hidden"
                }
                onClick={this.openSharingCalculatorModal.bind(this)}
              >
                <i className="material-icons">share</i>Share Calculations
              </p>
              <div className="box">
                <p className="box-title">Debt Yield</p>
                <p className="amount">
                  <DollarDisplay
                    name="maximumLoan"
                    value={this.state.maximumLoan}
                  />
                </p>
              </div>
            </div>
          </Panel>
        </Row>

        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>{" "}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const state1 = state.debtYield;
  const state2 = state.centralValues;
  return {
    annualUnderwrittenNOI: state2.annualUnderwrittenNOI || "",
    minimumDebtYield: state1.minimumDebtYield || "",
    maximumLoan: state1.maximumLoan || 0,
  };
}

export default connect(mapStateToProps)(DebtYield);
