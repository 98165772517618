import React, { useState, useRef } from "react";
import {
  TextField,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { CloseIcon } from "../../../../themes";
import { useStyles } from "./NewPropertyModal.styles";
import { Autocomplete } from "@material-ui/lab";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import clsx from "clsx";
import useAddressPredictions from "../../../../lib/useAddressPredictions";
import { clearErrorMsg } from "../../../../actions/eCalcForms";
const google = window.google;

function GoogleMapsAutocomplete({ value, setValue, errorMsg, setErrorMsg }) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");

  const predictions = useAddressPredictions(inputValue);
  const predictionsRef = useRef();

  const handleAutoCompletePlaceSelected = (placeId) => {
    if (window.google) {
      try {
        const PlacesService = new window.google.maps.places.PlacesService(
          predictionsRef.current
        );
        PlacesService.getDetails(
          {
            placeId: placeId,
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              if (!place.address_components) return place;
              const valid =
                place.types.includes("street_address") ||
                place.types.includes("premise");
              if (!valid) {
                return setErrorMsg("Address is not valid");
              }
              const address = {
                place_id: place.place_id,
                formatted_address: place.formatted_address,
              };

              place.address_components.forEach(function (c) {
                switch (c.types[0]) {
                  case "street_number":
                    address.property_street_number = c.short_name;
                    break;
                  case "route":
                    address.property_street_name = c.short_name;
                    break;
                  case "neighborhood":
                  case "locality":
                  case "administrative_area_level_3": // North Hollywood or Los Angeles?
                  case "postal_town":
                    address.property_city = c.short_name;
                    break;
                  case "administrative_area_level_1": //  Note some countries don't have states
                    address.property_state = c.short_name;
                    break;
                  case "postal_code":
                    address.property_zip = c.short_name;
                    break;
                  default:
                }
              });

              setValue(address);
              if (errorMsg && errorMsg.length) {
                clearErrorMsg();
              }
            }
          }
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <form>
      <Autocomplete
        getOptionLabel={(option) => {
          return value
            ? option.formatted_address
            : typeof option === "string"
            ? option
            : option.description;
        }}
        filterOptions={(x) => x}
        options={predictions}
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          if (newValue?.place_id) {
            handleAutoCompletePlaceSelected(newValue.place_id);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
          if (!newInputValue) {
            setValue(null);
          }
        }}
        getOptionSelected={(option, value) => {
          return value.place_id === option.place_id;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className={clsx(classes.margin, classes.fullWidthInput)}
            label="Enter Property Address:"
            variant="outlined"
            fullWidth
            InputLabelProps={{
              shrink: true,
              classes: {
                root: clsx(classes.inputLabel, classes.fullWidthLabel),
                shrink: "fullWidthLabel",
              },
            }}
          />
        )}
        renderOption={(option) => {
          const matches =
            option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center" ref={predictionsRef}>
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </form>
  );
}

export default function NewPropertyModal({
  autocompleteValue,
  setAutocompleteValue,
  errorMsg,
  setErrorMsg,
  handleClose,
  save,
}) {
  const classes = useStyles();

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth="xs"
      PaperProps={{ className: classes.dialog }}
    >
      <DialogTitle
        className={classes.innerDialogTitle}
        disableTypography={true}
      >
        <span>Add New Property</span>
        <CloseIcon
          onClick={() => handleClose()}
          className={classes.innerDialogCloseBtn}
        />
      </DialogTitle>
      <DialogContent>
        <GoogleMapsAutocomplete
          value={autocompleteValue}
          setValue={setAutocompleteValue}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
        />
        <span style={{ color: "red" }}>{errorMsg}</span>
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelLinkBtn} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className={classes.saveLinkBtn}
          disabled={!autocompleteValue}
          onClick={() => save()}
        >
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  );
}
