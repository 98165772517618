import React, { useEffect, Fragment } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useStyles } from "./irrSchedule.style";
import clsx from "clsx";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Container,
  useMediaQuery,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { eCalcForms, formatCurrency, formatPercentage } from "../../../lib";
import EditableTableCell from "./EditableTableCell";
import _cloneDeep from "lodash.clonedeep";
import Loader from "../../common/Loader";
import { CloseIcon } from "../../../themes";

export function IRRSchedule(props) {
  const { onClose, calculate, storeSliceName } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const holding_period_yrs = useSelector(
    (state) => state[storeSliceName][eCalcForms.IRR].holding_period_yrs,
    shallowEqual
  );
  const irrSchedule = useSelector(
    (state) => state[storeSliceName].irrSchedule,
    shallowEqual
  );
  const {
    pgi,
    stabilized_vacancies,
    expenses,
    effective_gross_income,
    noi,
    pi_payments,
    capitalEventPiPayments,
    net_cash_flow,
    capitalEventNetCashFlow,
    sale_price,
    closing_costs,
    net_sale_price,
    mortgage_balance,
    capitalEventMortgageBalance,
    net_distribution_from_sale,
    capitalEventNetDistFromSale,
    total_distribution_to_sale,
    capitalEventTotalDistToSale,
    grand_total_distribution,
    capitalEventGrandTotalDistFromSale,
    irr,
    capitalEventIRR,
    yieldOnCost,
    avgCashOnCash,
    ROI,
  } = irrSchedule || {};

  const {
    hasCapitalEvent,
    capitalEventNetLoan,
    capitalEventYear,
    capitalEventLoanAmount,
  } = useSelector(
    (state) => state[storeSliceName].irrCapitalEvent,
    shallowEqual
  );

  useEffect(() => {
    calculate({ pgi, stabilized_vacancies, expenses });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function save(name, index, value, amount) {
    const irrScheduleCloned = _cloneDeep(irrSchedule);
    irrScheduleCloned[name][index] = { amount, value };
    calculate({ ...irrScheduleCloned });
  }

  const hasMetrics = Boolean(yieldOnCost || avgCashOnCash || ROI);
  const colSpan1 = holding_period_yrs > 2 ? holding_period_yrs - 1 : 1;
  const colSpan2 = holding_period_yrs > 2 ? holding_period_yrs : 2;
  const PI = capitalEventPiPayments ? capitalEventPiPayments : pi_payments;
  const NCF = capitalEventNetCashFlow ? capitalEventNetCashFlow : net_cash_flow;
  const mortgageBalance = capitalEventMortgageBalance
    ? capitalEventMortgageBalance
    : mortgage_balance;
  const netDistFromSale = capitalEventNetDistFromSale
    ? capitalEventNetDistFromSale
    : net_distribution_from_sale;
  const totalDistPriorToSale = capitalEventTotalDistToSale
    ? capitalEventTotalDistToSale
    : total_distribution_to_sale;
  const grandTotalDist = capitalEventGrandTotalDistFromSale
    ? capitalEventGrandTotalDistFromSale
    : grand_total_distribution;

  const EmptyTotalsCell = () => (
    <TableCell
      className={classes.totalsRowValue}
      style={{ borderRight: "2px solid #00112B" }}
      colSpan={colSpan2}
    />
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      scroll={"body"}
      fullScreen={fullScreen}
      maxWidth="lg"
    >
      <DialogTitle id="simple-dialog-title">
        <div>
          <span className={classes.dialogTitle}>IRR Schedule</span>
          <CloseIcon className={classes.closeBtn} onClick={onClose} />
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {pgi.length && stabilized_vacancies.length && expenses.length ? (
          <Container className={classes.container}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.header}>
                <TableRow className={classes.row}>
                  <TableCell />
                  <TableCell
                    className={classes.currentUnderwritingCell}
                    align="center"
                  >
                    Year 1 Proforma
                  </TableCell>
                  {[...new Array(holding_period_yrs)].map((e, i) => (
                    <TableCell key={i} align="center">
                      Year {i + 2}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.row}>
                  <TableCell className={classes.headerText}>
                    Potential Gross Income <br />
                    <span className={classes.subHeaderText}>
                      % Growth over Year Before
                    </span>
                  </TableCell>
                  {pgi.map((pgiYear, index) => {
                    const prevAmount = index === 0 ? 0 : pgi[index - 1].amount;
                    return index === 0 ? (
                      <TableCell
                        className={classes.currentUnderwritingCell}
                        key={index}
                        align="center"
                      >
                        {formatCurrency(pgiYear.amount, 0, "$0")}
                      </TableCell>
                    ) : (
                      <EditableTableCell
                        key={index}
                        index={index}
                        name="pgi"
                        amount={pgiYear.amount || 0}
                        value={+parseFloat(pgiYear.value * 100).toFixed(3)}
                        save={save}
                        updateValue={(value) =>
                          ((value || 0) / prevAmount - 1) * 100
                        }
                        updateAmount={(value) => prevAmount * (1 + value / 100)}
                      />
                    );
                  })}
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.headerText}>
                    Stabilized Vacancy <br />
                    <span className={classes.subHeaderText}>
                      % Potential Gross Income
                    </span>
                  </TableCell>
                  {stabilized_vacancies.map((vacancy, index) => {
                    const pgiAmount = pgi[index].amount;
                    return index === 0 ? (
                      <TableCell
                        key={index}
                        className={classes.currentUnderwritingCell}
                        align="center"
                      >
                        {formatCurrency(vacancy.amount, 0, "$0")}
                        <br />
                        {vacancy.value + "%"}
                      </TableCell>
                    ) : (
                      <EditableTableCell
                        key={index}
                        index={index}
                        name="stabilized_vacancies"
                        amount={vacancy.amount || 0}
                        value={+parseFloat(vacancy.value * 100).toFixed(3)}
                        save={save}
                        updateValue={(value) => (value / pgiAmount) * 100}
                        updateAmount={(value) => pgiAmount * value}
                      />
                    );
                  })}
                </TableRow>
                <TableRow className={clsx(classes.row, classes.disabledRow)}>
                  <TableCell className={classes.headerText}>
                    Effective Gross Income
                  </TableCell>
                  {effective_gross_income.map((value, index) => (
                    <TableCell
                      key={index}
                      index={index}
                      style={{ fontFamily: "Roboto Condensed" }}
                      className={clsx({
                        [classes.currentUnderwritingCell]: index === 0,
                      })}
                      name="effective_gross_income"
                      align="center"
                    >
                      {formatCurrency(value, 0, "$0")}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow className={classes.row}>
                  <TableCell className={classes.headerText}>
                    Expenses <br />
                    <span className={classes.subHeaderText}>
                      % Growth over Year Before
                    </span>
                  </TableCell>
                  {expenses.map((expense, index) => {
                    const prevAmount =
                      index === 0 ? 0 : expenses[index - 1].amount;
                    return index === 0 ? (
                      <TableCell
                        className={classes.currentUnderwritingCell}
                        key={index}
                        align="center"
                      >
                        {formatCurrency(expense.amount, 0, "$0")}
                        <br />
                        {expense.value + "%"}
                      </TableCell>
                    ) : (
                      <EditableTableCell
                        key={index}
                        index={index}
                        name="expenses"
                        amount={expense.amount || 0}
                        value={+parseFloat(expense.value * 100).toFixed(3)}
                        save={save}
                        updateValue={(value) => (value / prevAmount - 1) * 100}
                        updateAmount={(value) => prevAmount * (1 + value / 100)}
                      />
                    );
                  })}
                </TableRow>
                <TableRow className={clsx(classes.row, classes.disabledRow)}>
                  <TableCell className={classes.headerText}>NOI</TableCell>
                  {noi.map((value, index) => (
                    <TableCell
                      key={index}
                      index={index}
                      className={clsx(
                        classes.numbersFontFamily,
                        index === 0
                          ? classes.currentUnderwritingCell
                          : classes.tableValueCell
                      )}
                      style={{ fontWeight: "bold" }}
                      name="noi"
                      align="center"
                    >
                      {formatCurrency(value, 0, "$0")}
                    </TableCell>
                  ))}
                </TableRow>

                {/* CAPITAL EVENT ROW */}
                {hasCapitalEvent && capitalEventLoanAmount > 0 && (
                  <TableRow className={clsx(classes.row, classes.disabledRow)}>
                    <TableCell className={classes.headerText}>
                      Net Capital Event
                    </TableCell>
                    {[...Array(noi.length)].map((_, index) => (
                      <TableCell
                        key={index}
                        index={index}
                        className={clsx(
                          classes.numbersFontFamily,
                          index === 0
                            ? classes.currentUnderwritingCell
                            : classes.tableValueCell
                        )}
                        style={{ fontWeight: "bold" }}
                        name="capitalEventNetLoan"
                        align="center"
                      >
                        {index === capitalEventYear - 1 && capitalEventNetLoan
                          ? formatCurrency(capitalEventNetLoan, 0, "")
                          : index === noi.length - 2
                          ? formatCurrency(netDistFromSale, 0, "")
                          : ""}
                      </TableCell>
                    ))}
                  </TableRow>
                )}
                {/* END CAPITAL EVENT ROW */}

                <TableRow className={clsx(classes.row, classes.disabledRow)}>
                  <TableCell className={classes.headerText}>
                    {"P & I Payment"}
                  </TableCell>
                  {PI.map((value, index) =>
                    value === "" ? (
                      <TableCell key={index} align="center"></TableCell>
                    ) : (
                      <TableCell
                        key={index}
                        index={index}
                        className={clsx(
                          classes.numbersFontFamily,
                          index === 0
                            ? classes.currentUnderwritingCell
                            : classes.tableValueCell
                        )}
                        style={{ fontWeight: "bold" }}
                        name="pi_payments"
                        align="center"
                      >
                        {formatCurrency(value, 0, "$0")}
                      </TableCell>
                    )
                  )}
                </TableRow>
                <TableRow className={clsx(classes.row, classes.disabledRow)}>
                  <TableCell className={classes.headerText}>
                    Net Cash Flow <br />
                    <span className={classes.subHeaderText}>
                      Cumulative Distribution
                    </span>
                  </TableCell>
                  {NCF.map((value, index) =>
                    !value ? (
                      <TableCell key={index} align="center"></TableCell>
                    ) : (
                      <TableCell
                        key={index}
                        index={index}
                        className={clsx(
                          classes.numbersFontFamily,
                          index === 0
                            ? classes.currentUnderwritingCell
                            : classes.tableValueCell
                        )}
                        style={{ fontWeight: "bold" }}
                        name="net_cash_flow"
                        align="center"
                      >
                        {formatCurrency(value.amount, 0, "$0")}
                        <br />{" "}
                        <span style={{ fontSize: 12 }}>
                          {formatCurrency(value.total, 0, "$0")}
                        </span>
                      </TableCell>
                    )
                  )}
                </TableRow>
                <TableRow
                  className={classes.totalsRow}
                  style={{ borderTop: "2px solid #00112B" }}
                >
                  <TableCell className={classes.headerText}>
                    Sale Price
                  </TableCell>
                  <TableCell
                    className={classes.totalsRowValue}
                    style={{ borderRight: "2px solid #00112B" }}
                    align="center"
                  >
                    {formatCurrency(sale_price, 0, "$0")}
                  </TableCell>
                  <TableCell
                    className={classes.headerText}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {capitalEventIRR ? "IRR without Capital Event" : "IRR"}
                  </TableCell>
                  <TableCell
                    className={classes.totalsRowValue}
                    colSpan={
                      holding_period_yrs > 2 ? holding_period_yrs - 1 : 1
                    }
                  >
                    {`${irr || 0}%`}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.totalsRow}>
                  <TableCell className={classes.headerText}>
                    Closing Costs
                  </TableCell>
                  <TableCell
                    className={classes.totalsRowValue}
                    style={{ borderRight: "2px solid #00112B" }}
                    align="center"
                  >
                    {formatCurrency(closing_costs, 0, "$0")}
                  </TableCell>
                  {capitalEventIRR ? (
                    <Fragment>
                      <TableCell
                        className={classes.headerText}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        IRR with Capital Event
                      </TableCell>
                      <TableCell
                        className={classes.totalsRowValue}
                        colSpan={colSpan1}
                      >
                        {`${capitalEventIRR || 0}%`}
                      </TableCell>
                    </Fragment>
                  ) : (
                    <EmptyTotalsCell />
                  )}
                </TableRow>
                <TableRow
                  className={classes.totalsRow}
                  style={{ borderRight: "2px solid #00112B !important" }}
                >
                  <TableCell className={classes.headerText}>
                    Net Sale Price
                  </TableCell>
                  <TableCell
                    className={classes.totalsRowValue}
                    style={{ borderRight: "2px solid #00112B" }}
                    align="center"
                  >
                    {formatCurrency(net_sale_price, 0, "$0")}
                  </TableCell>
                  <EmptyTotalsCell />
                </TableRow>
                <TableRow className={classes.totalsRow}>
                  <TableCell className={classes.headerText}>
                    Mortgage Balance
                  </TableCell>
                  <TableCell
                    className={classes.totalsRowValue}
                    style={{ borderRight: "2px solid #00112B" }}
                    align="center"
                  >
                    {formatCurrency(mortgageBalance, 0, "$0")}
                  </TableCell>
                  {hasMetrics ? (
                    <TableCell
                      className={classes.metrics}
                      style={{ borderRight: "2px solid #00112B" }}
                      colSpan={colSpan2}
                    >
                      <div className={classes.metricsTitle}>Return Metrics</div>
                      <div className={classes.metricsSubTitle}>
                        Calculated with Stabilized NOI,
                      </div>
                      <div className={classes.metricsSubTitle}>
                        Total Net Cash Flow and Initial Equity.
                      </div>
                    </TableCell>
                  ) : (
                    <EmptyTotalsCell />
                  )}
                </TableRow>
                <TableRow className={classes.totalsRow}>
                  <TableCell className={classes.headerText}>
                    Net Distribution from Sale
                  </TableCell>
                  <TableCell
                    className={classes.totalsRowValue}
                    style={{ borderRight: "2px solid #00112B" }}
                    align="center"
                  >
                    {formatCurrency(netDistFromSale, 0, "$0")}
                  </TableCell>
                  {hasMetrics ? (
                    <Fragment>
                      <TableCell className={classes.headerText}>ROI</TableCell>
                      <TableCell
                        className={classes.totalsRowValue}
                        colSpan={colSpan1}
                      >
                        {formatPercentage(ROI, 0, true)}
                      </TableCell>
                    </Fragment>
                  ) : (
                    <EmptyTotalsCell />
                  )}
                </TableRow>
                <TableRow className={classes.totalsRow}>
                  <TableCell className={classes.headerText}>
                    Total Distribution Prior to Sale
                  </TableCell>
                  <TableCell
                    className={classes.totalsRowValue}
                    style={{ borderRight: "2px solid #00112B" }}
                    align="center"
                  >
                    {formatCurrency(totalDistPriorToSale, 0, "$0")}
                  </TableCell>
                  {hasMetrics ? (
                    <Fragment>
                      <TableCell className={classes.headerText}>
                        Yield on Cost
                      </TableCell>
                      <TableCell
                        className={classes.totalsRowValue}
                        colSpan={colSpan1}
                      >
                        {formatPercentage(yieldOnCost, 0, true)}
                      </TableCell>
                    </Fragment>
                  ) : (
                    <EmptyTotalsCell />
                  )}
                </TableRow>
                <TableRow className={classes.totalsRow}>
                  <TableCell className={classes.headerText}>
                    Grand Total Distribution
                  </TableCell>
                  <TableCell
                    className={classes.totalsRowValue}
                    style={{ borderRight: "2px solid #00112B" }}
                    align="center"
                  >
                    {formatCurrency(grandTotalDist, 0, "$0")}
                  </TableCell>
                  {hasMetrics ? (
                    <Fragment>
                      <TableCell
                        className={classes.headerText}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Average Cash on Cash
                      </TableCell>
                      <TableCell
                        className={classes.totalsRowValue}
                        colSpan={colSpan1}
                      >
                        {formatPercentage(avgCashOnCash, 0, true)}
                      </TableCell>
                    </Fragment>
                  ) : (
                    <EmptyTotalsCell />
                  )}
                </TableRow>
              </TableBody>
            </Table>
            {/* <Table className={classes.table} aria-label="simple table">
                            <TableBody>
                                <TableRow className={classes.totalsRow} style={{ borderTop: "2px solid #00112B" }}>
                                    <TableCell className={classes.headerText}>Sale Price</TableCell>
                                    <TableCell className={classes.totalsRowValue} style={{ borderRight: "2px solid #00112B" }}>{formatCurrency(sale_price, 0, '$0')}</TableCell>
                                    <TableCell className={classes.headerText}>Grand Total Distribution</TableCell>
                                    <TableCell className={classes.totalsRowValue} colSpan={4}>{formatCurrency(grand_total_distribution, 0, '$0')}</TableCell>
                                </TableRow>
                                <TableRow className={classes.totalsRow}>
                                    <TableCell className={classes.headerText}>Closing Costs</TableCell>
                                    <TableCell className={classes.totalsRowValue} style={{ borderRight: "2px solid #00112B" }}>{formatCurrency(closing_costs, 0, '$0')}</TableCell>
                                    <TableCell className={classes.headerText}>IRR</TableCell>
                                    <TableCell className={classes.totalsRowValue}>{irr + '%'}</TableCell>
                                </TableRow>
                                <TableRow className={classes.totalsRow} style={{ borderRight: "2px solid #00112B !important" }}>
                                    <TableCell className={classes.headerText}>Net Sale Price</TableCell>
                                    <TableCell className={classes.totalsRowValue} style={{ borderRight: "2px solid #00112B" }}>{formatCurrency(net_sale_price, 0, '$0')}</TableCell>
                                </TableRow>
                                <TableRow className={classes.totalsRow}>
                                    <TableCell className={classes.headerText}>Mortgage Balance</TableCell>
                                    <TableCell className={classes.totalsRowValue} style={{ borderRight: "2px solid #00112B" }}>{formatCurrency(mortgage_balance, 0, '$0')}</TableCell>
                                </TableRow>
                                <TableRow className={classes.totalsRow}>
                                    <TableCell className={classes.headerText}>Net Distribution from Sale</TableCell>
                                    <TableCell className={classes.totalsRowValue} style={{ borderRight: "2px solid #00112B" }}>{formatCurrency(net_distribution_from_sale, 0, '$0')}</TableCell>
                                </TableRow>
                                <TableRow className={classes.totalsRow}>
                                    <TableCell className={classes.headerText}>Total Distribution Prior to Sale</TableCell>
                                    <TableCell className={classes.totalsRowValue} style={{ borderRight: "2px solid #00112B" }}>{formatCurrency(total_distribution_to_sale, 0, '$0')}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table> */}
          </Container>
        ) : (
          <Loader />
        )}
      </DialogContent>
    </Dialog>
  );
}
