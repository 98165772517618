import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 280,
  },
  dialogContent: {
    padding: "8px 19px",
  },
  closeBtn: {
    position: "absolute",
    width: 18,
    height: 18,
    right: 0,
    margin: "2px 16px",
  },
  resultModalTitle: {
    fontWeight: "bold",
    fontSize: 18,
    color: "#00112B",
  },
  resultModalMsg: {
    fontSize: 13,
    color: "#000000",
    lineHeight: 2.5,
    marginTop: 7,
  },
  resultModalInput: {
    border: "1px solid rgba(0,17,43,0.5)",
    boxSizing: "border-box",
    borderRadius: 4,
    padding: 6,
    width: 52,
    fontFamily: "Roboto Condensed",
    lineHeight: "normal",
  },
  resultModalBtn: {
    background: "none",
    border: "none",
    textAlign: "center",
    fontSize: 16,
  },
  resultModalCancel: {
    color: "#A0A0A0",
    cursor: "pointer",
  },
  resultModalSave: {
    color: "#10B8C7",
    cursor: "pointer",
  },
  resultModalCalcAmount: {
    fontFamily: "Roboto Condensed",
    fontWeight: "bold",
    marginRight: 3,
  },
  blendedRateNote: {
    fontFamily: "Karla",
    fontWeight: "normal",
    fontSize: 9,
    lineHeight: "11px",
    letterSpacing: "-0.045em",
    color: "#A0A0A0",
    padding: "3px 20px 12px 20px",
  },
}));
