import * as types from "./actionTypes";
import config from "../config";
import { predictiveContactsSearch } from "./contactsActions";
import {
  ajax,
  mixpanelAliasPerson,
  mixpanelRegisterPerson,
  addOrUpdateMixpanelValue,
  formatRequestBodyParams,
  handleAPIError,
  Navigation,
} from "../lib";

function loginSuccess(timestamp, token, data) {
  return { type: types.LOGIN_SUCCESS, timestamp, token, data };
}

export function login(username, password) {
  return (dispatch) => {
    dispatch({ type: types.SET_LOGGING_IN_MESSAGE });
    ajax({
      url: `${config.BASE_URL}authentication/${config.SECRET_ID}/${config.API_KEY}`,
      type: "POST",
      data: { username, password },
      success: function (response) {
        if (response.isResponse) {
          if (response.status === "223") {
            Navigation.go({
              pathname: `/verify-email`,
              state: { email: username },
            });
          } else {
            localStorage.setItem("auth_token", response.token);
            localStorage.setItem("auth_timestamp", Date.now());
            localStorage.setItem("data", JSON.stringify(response.data));
            mixpanelRegisterPerson(response.data);
            addOrUpdateMixpanelValue("is_logged_in", true);
            dispatch(
              loginSuccess(
                localStorage.getItem("auth_timestamp"),
                response.token,
                response.data
              )
            );
            if (localStorage.getItem("current_path_redirect")) {
              Navigation.go(localStorage.getItem("current_path_redirect"));
              localStorage.removeItem("current_path_redirect");
            } else {
              Navigation.go(`/`);
            }
            if (JSON.parse(localStorage.getItem("data")).is_broker) {
              dispatch(predictiveContactsSearch());
            }
          }
        } else {
          dispatch({ type: types.LOGIN_ERROR, message: response.message });
        }
      },
    });
  };
}

export function forgotPassword(username) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}password/${config.SECRET_ID}/${config.API_KEY}`,
      method: "PUT",
      data: {
        username: username,
      },
      success: function (response) {
        dispatch({
          type: types.FORGOT_PASSWORD,
          message: response.message,
          success: true,
        });
      },
      error: handleAPIError(dispatch, types.FORGOT_PASSWORD),
    });
  };
}

export function submitResetPassword(username, temp, password) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}password`,
      type: "POST",
      data: { username, temp, new: password },
      success: function (response) {
        dispatch({
          type: types.RESET_PASSWORD,
          success: response.isResponse,
          message: response.message,
        });
        if (response.isResponse) {
          Navigation.go(`reset-password-success?name=${username}`);
        }
      },
      error: handleAPIError(dispatch, types.RESET_PASSWORD),
    });
  };
}

export function signUp(
  email,
  first_name,
  last_name,
  phone,
  company,
  password,
  user_types
) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_SIGN_UP_REQUEST });
    ajax({
      url: `${config.BASE_URL}sign-up`,
      method: "POST",
      data: formatRequestBodyParams({
        email,
        first_name,
        last_name,
        phone,
        company,
        password,
        is_client: user_types.indexOf("client") >= 0,
        is_lender: user_types.indexOf("lender") >= 0,
        is_sales_broker: user_types.indexOf("sales broker") >= 0,
      }),
      success: function (response) {
        if (response.isResponse) {
          dispatch({ type: types.SIGN_UP_SUCCESS, message: response.message });
          Navigation.go({ pathname: `/verify-email`, state: { email } });
        } else {
          dispatch({ type: types.SIGN_UP_FAILED, message: response.message });
        }
      },
    });
  };
}

export function closeErrorToast() {
  return { type: types.CLOSE_ERROR_TOAST };
}

export function storeAuthenticationInfo() {
  let timestamp = localStorage.getItem("auth_timestamp");
  let token = localStorage.getItem("auth_token");
  let data = JSON.parse(localStorage.getItem("data"));
  return { type: types.STORE_AUTHENTICATION_INFO, timestamp, token, data };
}

function logoutSuccess() {
  return (dispatch) => {
    dispatch({ type: types.LOGOUT_SUCCESS });
    Navigation.go("/");
  };
}

export function logout(token) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}authentication/${token}`,
      type: "POST",
      success: function () {
        localStorage.removeItem("auth_token");
        localStorage.removeItem("auth_timestatmp");
        localStorage.removeItem("data");
        sessionStorage.clear();
        addOrUpdateMixpanelValue("is_logged_in", false);
        dispatch(logoutSuccess());
      },
    });
  };
}

export function storeNewTimestamp() {
  localStorage.setItem("auth_timestamp", Date.now());
  return {
    type: types.STORE_NEW_TIMESTAMP,
    timestamp: localStorage.getItem("auth_timestamp"),
  };
}

export function tokenExpired() {
  return (dispatch) => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("auth_timestatmp");
    localStorage.removeItem("data");
    dispatch(logoutSuccess());
  };
}

export function activateAccount(username, temp, password) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_ACTIVATE_ACCOUNT_REQUEST });
    ajax({
      url: `${config.BASE_URL}password`,
      type: "POST",
      data: { username, temp, new: password },
      success: function (response) {
        if (!response.isResponse) {
          dispatch({
            type: types.FAILED_ACTIVATE_ACCOUNT_REQUEST,
            message: response.message,
          });
        } else {
          dispatch({
            type: types.SUCCESS_ACTIVATE_ACCOUNT_REQUEST,
            message: response.message,
          });
        }
      },
      error: handleAPIError(dispatch, types.FAILED_ACTIVATE_ACCOUNT_REQUEST),
    });
  };
}

export function activate(activation_hash) {
  return async (dispatch) => {
    dispatch({ type: types.ACTIVATING_ACCOUNT });
    ajax({
      url: `${config.BASE_URL}activation/${config.SECRET_ID}/${config.API_KEY}`,
      type: "POST",
      data: { activation_hash },
      success: function (response) {
        if (response.isResponse) {
          localStorage.setItem("auth_token", response.token);
          localStorage.setItem("auth_timestamp", Date.now());
          localStorage.setItem("data", JSON.stringify(response.data));
          mixpanelAliasPerson(response.data);
          dispatch({
            type: types.ACCNT_ACTIVATION_SUCCESS,
            timestamp: Date.now(),
            token: response.token,
            data: response.data,
          });
          Navigation.go("/activation-success");
        } else {
          if (response.status === "E411") {
            dispatch({
              type: types.ACTIVATION_LINK_EXPIRED,
              message: response.message,
            });
          } else {
            dispatch({
              type: types.ACCNT_ACTIVATION_FAILED,
              message: response.message,
            });
          }
        }
      },
      error: handleAPIError(dispatch, types.ACCNT_ACTIVATION_FAILED),
    });
  };
}

export function resendActivation(username, password) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}resend-activation`,
      type: "POST",
      data: { username, password },
      success: function (response) {
        if (response.isResponse) {
          dispatch({
            type: types.RESEND_ACTIVATION_SUCCESS,
            message: response.message,
          });
        } else {
          dispatch({
            type: types.RESEND_ACTIVATION_FAILED,
            message: response.message,
          });
        }
      },
    });
  };
}

export function validatePassword(password) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}password/validate`,
      data: { password },
      success: function (response) {
        dispatch({
          type: types.VALIDATE_PASSWORD,
          success: response.success,
          message: response.message,
        });
      },
      error: handleAPIError(dispatch, types.VALIDATE_PASSWORD),
    });
  };
}
