import FormattedDisplay from "./FormattedDisplay";

class NumberDisplay extends FormattedDisplay {
  formatValue(value) {
    if (isNaN(value)) {
      return "Invalid Entry";
    }
    let roundedValue = value ? parseFloat(value).toFixed(3) : "0";
    return roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //format with commas
  }
}

export default NumberDisplay;
