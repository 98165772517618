import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "muicss/lib/react/form";
import Input from "muicss/lib/react/input";
import { Link } from "react-router-dom";
import Button from "muicss/lib/react/button";
import { findDOMNode } from "react-dom";
import getParameterByName from "../common/getParameterByName";
import { submitResetPassword } from "../../actions/authenticationActions";

export default function ResetPassword() {
  const dispatch = useDispatch();

  var passwordField = useRef(null);
  var confirmPasswordField = useRef(null);

  const msg = useSelector(
    (state) => state.authentication.resetPassword.message
  );

  const [newPassword, setNewPassword] = useState("");
  const [username, setUsername] = useState("");
  const [newConfirm, setNewConfirm] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmMessage, setConfirmMessage] = useState("");

  useEffect(() => {
    setUsername(getParameterByName("username"));
  }, []);

  useEffect(() => {
    setMessage(msg);
  }, [msg]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      errorMessage.length ||
      confirmMessage.length ||
      !newPassword ||
      !newConfirm
    ) {
      setMessage("all fields in the form must be complete");
    } else {
      setMessage("working on reset...");
      dispatch(
        submitResetPassword(
          username.trim(),
          getParameterByName("temp"),
          newPassword
        )
      );
    }
  };

  const passwordChange = (event) => {
    setNewPassword(event.target.value);
    const result = validatePassword(event.target.value);
    if (!result) {
      setErrorMessage("Password must be 5 characters");
    } else {
      setErrorMessage("");
    }
  };

  const setConfirmPassword = (event) => {
    setNewConfirm(event.target.value);
    if (newPassword !== event.target.value) {
      setConfirmMessage("Passwords do not match");
    } else {
      setConfirmMessage("");
    }
  };

  const validatePassword = (value) => {
    var re = /(?=.{5,})/;
    return re.test(value);
  };

  const onKeyPress = (evt) => {
    let x = findDOMNode(passwordField.current);
    let foo = x.getElementsByTagName("input")[0];
    if (evt.keyCode === 13) {
      foo.focus();
    }
  };

  const onKeyPressNewPassword = (evt) => {
    let x = findDOMNode(confirmPasswordField.current);
    let foo1 = x.getElementsByTagName("input")[0];
    if (evt.keyCode === 13) {
      foo1.focus();
    }
  };

  const onKeyPressConfirmPassword = (evt) => {
    if (evt.keyCode === 13) {
      let x = findDOMNode(confirmPasswordField.current);
      let foo = x.getElementsByTagName("input")[0];
      foo.blur();
      handleSubmit(evt);
    }
  };

  return (
    <div className="account-activation-page">
      <div className="account-activation-logo">
        <Link to="/">
          <img
            id="logo"
            src={"/images/eastern-union-white-small.png"}
            alt="Eastern Union Logo"
          />
        </Link>
      </div>
      <div className="account-activation-panel panel">
        <p className="account-activation-header">Reset Password</p>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <Input
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={(e) => onKeyPress(e)}
            value={username}
            label="User Name or E-mail"
            floatingLabel={true}
          />

          <Input
            type="password"
            ref={passwordField}
            onKeyDown={(e) => onKeyPressNewPassword(e)}
            onChange={(e) => passwordChange(e)}
            label="New Password"
            floatingLabel={true}
          />
          <span className="help-block">{errorMessage}</span>

          <Input
            type="password"
            ref={confirmPasswordField}
            onKeyDown={(e) => onKeyPressConfirmPassword(e)}
            onChange={(e) => setConfirmPassword(e)}
            label="Confirm New Password"
            floatingLabel={true}
          />
          <span className="help-block">{confirmMessage}</span>

          <Button type="submit" value="submit">
            Reset
          </Button>
          <div id="message">{message}</div>
          <div className="account-activation-panel-footer text-center">
            <Link
              to="/login"
              className="xs-text orange-hover accent-text-light"
            >
              Login via our online portal
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
}
