import * as types from "../actions/actionTypes";

const initialState = {
  remainingDeals: [],
  linkedRemainingDeals: [],
  loading: true,
  noRemainingDeals: false,
};

export default function remainingDealsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_DEALS:
      return Object.assign({}, initialState, {
        loading: true,
        remainingDeals: [],
        linkedRemainingDeals: [],
      });
    case types.LOAD_REMAINING_DEALS_SUCCESS:
      return {
        remainingDeals: action.remainingDeals,
        linkedRemainingDeals: action.linkedRemainingDeals,
      };
    case types.NO_REMAINING_DEALS:
      return Object.assign({}, state, {
        loading: false,
        noRemainingDeals: true,
      });
    default:
      return state;
  }
}
