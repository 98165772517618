import FormattedDisplay from "./FormattedDisplay";

class DollarDisplay extends FormattedDisplay {
  formatValue(value) {
    if (isNaN(value)) {
      return "Invalid Entry";
    } else if (value === Infinity || value === "Infinity") {
      value = 0;
    }
    let roundedValue = value ? "$" + parseFloat(value).toFixed(2) : "$0"; //add $ and rounds
    let formattedValue = roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //format with commas
    return formattedValue;
  }
}

export default DollarDisplay;
