import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as navigationTitleActions from "../../actions/navigationTitleActions";
import DealReviewFilterForm from "./DealReviewFilterForm";
import DealReviewList from "./DealReviewList";

class DealReviewContainer extends React.PureComponent {
  componentDidMount() {
    this.props.navActions.updateNavTitle("Deal Review");
  }

  render() {
    const {
      location: { pathname },
    } = this.props;
    return (
      <div className="deal-review-page">
        {pathname.includes("deal-review-filter") ? (
          <DealReviewFilterForm />
        ) : pathname.includes("deal-review-list") ? (
          <DealReviewList location={this.props.location} />
        ) : null}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(navigationTitleActions, dispatch),
  };
}

export default connect(() => ({}), mapDispatchToProps)(DealReviewContainer);
