import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  mobileTitleContainer: {
    ...flex,
    flexDirection: "column",
    justifyContent: "center",
    position: "fixed",
    width: (props) =>
      props.isTabletSlider && !props.isTablet ? "calc(100% - 224px)" : "100%",
    height: 120,
    left: (props) => (props.isTabletSlider ? undefined : 0),
    right: 0,
    top: (props) =>
      props.isTabletSlider && !props.isTablet
        ? props.hasToolbar
          ? 101
          : props.isDealDetails
          ? 0
          : 44
        : props.hasToolbar
        ? 107
        : 49,
    background: "#00112B",
    borderRadius: "0px 0px 5px 5px",
    zIndex: 10,
    fontFamily: "Karla",
  },
  tabletSliderContainer: {
    paddingRight: 10,
    paddingLeft: 10,
    background: "#00112B",
    // marginTop: 10
  },
  ecalcTitle: {
    fontWeight: "bold",
    fontSize: 15,
    color: "#FFFFFF",
    opacity: 0.8,
    marginBottom: 10,
    // [theme.breakpoints.down("xs")]: {
    //   // fontSize: 15,
    //   color: "#FFFFFF",
    //   opacity: 0.8
    // }
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    // fontSize: 37,
    letterSpacing: "-0.04em",
    // color: "#00112B",
    fontSize: 18,
    color: "#FFFFFF",
    // [theme.breakpoints.down("xs")]: {
    //   fontSize: 18,
    //   color: "#FFFFFF"
    // }
  },
  noResults: {
    color: "#FFFFFF",
    background: "rgba(255, 255, 255, 0.2)",
    borderRadius: 5,
    padding: "3px 15px",
    marginBottom: 5,
    marginTop: 10,
  },
  threeDotsContainer: {
    ...flex,
    justifyContent: "space-between",
    width: "100%",
    padding: "0px 10px 0px 10px",
  },
  threeDots: {
    color: "#FFFFFF",
    fontSize: 20,
  },
  sliderContainer: {
    width: "100%",
    maxWidth: "calc(100% - 15px)",
    overflowX: "auto",
    height: 111,
    ...flex,
    // hide scroll bar https://stackoverflow.com/a/49278385
    scrollbarWidth: "none" /* Firefox */,
    msOverflowStyle: "none" /* Internet Explorer 10+ */,
    /* WebKit (Chrome) */
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  sliderElement: {
    height: "100%",
    padding: 10,
    color: "#FFFFFF",
    fontSize: 9.36,
    border: "1px solid rgba(255,255,255,0.2)",
    boxSizing: "border-box",
    borderRadius: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0px 5px 0px 5px",
    position: "relative",
  },
  sliderElementLabel: {
    fontSize: 9.36,
    marginBottom: 10,
  },
  sliderElementMultipleSection: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  sliderElementMultipleCondensed: {
    marginBottom: 6,
  },
  sliderElementMultipleLabel: {
    fontSize: 12,
  },
  sliderElementMultipleKey: {
    marginRight: 15,
    fontSize: 14,
    opacity: 0.6,
    marginBottom: 0,
    whiteSpace: "nowrap",
  },
  sliderElementValue: {
    color: "#10B8C7",
    fontSize: 19.4999,
    fontFamily: "Roboto Condensed",
  },
  sliderElementMultipleValue: {
    fontSize: 16,
  },
  resultsSettingsIcon: {
    fontWeight: 300,
    fontSize: 14.3713,
    color: "#FFFFFF",
    opacity: 0.7,
    cursor: "pointer",
    position: "absolute",
    top: 20,
    right: 10,
  },
  // we need class names for all mobile slider elements
  purchase_price: {
    // minWidth: 130
    display: "none",
  },
  cap_rate: {
    minWidth: 130,
  },
  equity_needed: {
    minWidth: 130,
  },
  balance_at_maturity: {
    minWidth: 130,
  },
  dscr_post_io: {
    minWidth: 130,
  },
  dscr_during_post_io: {
    minWidth: 140,
  },
  first_year_cash_flow: {
    // minWidth: 130
    display: "none",
  },
  cash_on_cash_return: {
    minWidth: 130,
  },
  mortgage_post_io: {
    minWidth: 200,
  },
  mortgage_during_io: {
    minWidth: 200,
  },
  lp_stats: {
    width: "100%",
    // minWidth: 245
  },
  gp_stats: {
    width: "100%",
    // minWidth: 245
  },
  irr: {
    minWidth: (props) => (props.capitalEventIRR ? 235 : 130),
  },
  equity_multiple: {
    minWidth: 130,
  },
  debt_yield: {
    minWidth: 130,
  },
}));

const flex = {
  display: "flex",
  alignItems: "center",
};
