import * as types from "../actions/actionTypes";

const initialState = {
  awaitingQuote: [],
  awaitingSheet: [],
  googleDoc: "",
  quoteAmount: 0,
  editQuoteDetail: {},
  rateSpreadInt: {},
  rateSpreadStr: {},
  awaitingSheetCount: null,
  awaitingQuoteCount: null,
  noQuotes: false,
  loading: true,
  index_datetime: "",
  toast: {
    show: false,
    message: "",
    success: true,
  },
};

export default function missingQuotesReducer(state = initialState, action) {
  switch (action.type) {
    case types.INCREMENT_QUOTE_FORM_AMOUNT:
      return Object.assign({}, state, { quoteAmount: state.quoteAmount + 1 });
    case types.DECREMENT_QUOTE_FORM_AMOUNT:
      return Object.assign({}, state, { quoteAmount: state.quoteAmount - 1 });
    case types.REMOVE_QUOTE_FORM_AMOUNT:
      return Object.assign({}, state, { quoteAmount: 0 });
    case types.LOAD_MISSING_QUOTES_SUCCESS:
      return Object.assign({}, state, {
        awaitingQuoteCount: action.count,
        awaitingQuote: action.quotes,
        loading: false,
        noQuotes: false,
      });
    case types.LOAD_WAITING_TERM_SHEETS_SUCCESS:
      return Object.assign({}, state, {
        awaitingSheetCount: action.count,
        awaitingSheet: action.quotes,
        loading: false,
        noQuotes: false,
      });
    case types.NO_QUOTES:
      return Object.assign({}, state, { loading: false, noQuotes: true });
    case types.LOADING_QUOTES:
      return Object.assign({}, state, { loading: true, editQuoteDetail: {} });
    case types.GET_GOOGLE_DOC_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });
    case types.PASS_MQ_DEAL_SUCCESS:
      let newAwaitingQuoteList = state.awaitingQuote;
      let dealIndex = newAwaitingQuoteList.findIndex(
        (quote) => quote.quote_id === action.quote_id
      );
      newAwaitingQuoteList.splice(dealIndex, 1);
      return Object.assign({}, state, {
        awaitingQuote: newAwaitingQuoteList,
        loading: false,
        toast: {
          show: true,
          success: true,
          message: "The deal has successfully been passed.",
        },
      });
    case types.PASS_MQ_DEAL_FAILED:
      return Object.assign({}, state, {
        toast: { show: false, success: true, message: action.message },
        loading: false,
      });
    case types.DEAL_QUOTE_DETAIL_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        editQuoteDetail: action.data,
      });
    case types.LOAD_DEAL_RATE_SPREAD:
      return Object.assign({}, state, {
        rateSpreadInt: action.rates,
        rateSpreadStr: action.rateStrings,
        index_datetime: action.index_datetime,
      });
    case types.DEAL_QUOTE_UPDATE:
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.CLOSE_MISSING_QUOTE_TOAST:
      return Object.assign({}, state, {
        toast: { show: false, success: true, message: "" },
      });
    default:
      return state;
  }
}
