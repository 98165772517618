import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Input from "muicss/lib/react/input";
import Button from "muicss/lib/react/button";
import Divider from "muicss/lib/react/divider";
import * as authActions from "../../actions/authenticationActions";
import config from "../../config";
import getParameterByName from "../common/getParameterByName";

class AccountActivationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      new: "",
      newConfirm: "",
      validForm: false,
      apiResponse: false,
      error: "",
    };
  }

  scrollDownScreen() {
    document.getElementById("aa-container").scrollIntoView({
      behavior: "smooth",
    });
  }

  onChangePassword(e) {
    this.setState({ new: e.target.value });
  }

  onBlurPassword(e) {
    this.setState({ new: e.target.value });
    const result = this.validatePassword(e.target.value);
    if (!result) {
      this.setState({ error: "Password must be 5 characters" });
    }
    if (result) {
      this.setState({ error: "", validForm: true });
    }
  }

  onChangeConfirmPassword(e) {
    this.setState({ newConfirm: e.target.value }, function () {
      if (this.state.new !== this.state.newConfirm) {
        this.setState({ validForm: false });
      } else {
        this.setState({ validForm: true });
      }
    });
  }

  onBlurConfirmPassword(e) {
    this.setState({ newConfirm: e.target.value }, function () {
      if (this.state.new !== this.state.newConfirm) {
        this.setState({ error: "Passwords do not match" });
      } else {
        this.setState({ error: "", validForm: true });
      }
    });
  }

  validatePassword(value) {
    var re = /(?=.{5,})/;
    return re.test(value);
  }

  handleSubmit() {
    this.props.actions.activateAccount(
      getParameterByName("username"),
      getParameterByName("temp"),
      this.state.new
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.apiResponse !== nextProps.auth.apiResponse) {
      this.setState({ apiResponse: nextProps.auth.apiResponse });
    }
  }

  showForm() {
    this.setState({ apiResponse: false });
  }

  render() {
    let username = getParameterByName("username");
    return (
      <div className="account-activation" id="account-activation">
        <div className="bg-img">
          <div className="aa-logo">
            <img src="/images/gs-logo.png" alt="gs-logo" />
          </div>
          <div className="gs-app-icons">
            <a
              href="https://play.google.com/store/apps/details?id=com.LoanTracker&hl=en"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="gs-google-play" src="/images/gs-google-play.png" />
            </a>
            <a
              href="https://itunes.apple.com/us/app/eastern-union/id1125123079?mt=8"
              className="login-apps-apple"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="gs-app-store" src="/images/gs-app-store.png" />
            </a>
          </div>
          <div className="gs-banner">
            <div className="gs-banner-text">
              <p>
                Welcome to the <br />
                <span>Eastern Union</span>
                App!
                <Button
                  onClick={this.scrollDownScreen.bind(this)}
                  variant="flat"
                >
                  Activate Account
                </Button>
              </p>
            </div>
            <div className="gs-banner-img">
              <img alt="hands-with-phone" src="/images/hand-with-phone.png" />
            </div>
          </div>
        </div>

        <div className="aa-container" id="aa-container">
          <div className="getting-started">
            <p className="gs-title">Activate Account</p>
            <p className="gs-subtitle">
              Please create and confirm your password to activate your account.
            </p>
          </div>
          <div className="step1">
            <div className="create-psswd-box">
              <div className="create-psswd-left">
                <img alt="aa-imag" src="/images/icons/aa-image.png" />
                <p className="username">
                  Your Username
                  <span>{username ? "  " + username : ""}</span>
                </p>
              </div>

              <div
                className={
                  !this.state.apiResponse ? "create-psswd-right" : "hidden"
                }
              >
                <p className="create-psswd">Create A Password</p>

                <Input
                  type="password"
                  onChange={this.onChangePassword.bind(this)}
                  onBlur={this.onBlurPassword.bind(this)}
                  label="New password"
                  floatingLabel={true}
                />

                <Input
                  type="password"
                  onChange={this.onChangeConfirmPassword.bind(this)}
                  onBlur={this.onBlurConfirmPassword.bind(this)}
                  label="Reenter your password"
                  floatingLabel={true}
                />

                <span className="help-block">{this.state.error}</span>
                {this.state.new &&
                this.state.newConfirm &&
                this.state.validForm ? (
                  <Button
                    className="activate-account-btn loader"
                    onClick={this.handleSubmit.bind(this)}
                  >
                    Activate Account
                  </Button>
                ) : (
                  <Button className="activate-account-btn" disabled={true}>
                    Activate Account
                  </Button>
                )}
              </div>

              <div
                className={
                  this.state.apiResponse ? "message-container" : "hidden"
                }
              >
                <div className="aa-success-circle-container">
                  {this.props.auth.activationSuccess ? (
                    <i className="material-icons aa-success-circle">
                      check_circle
                    </i>
                  ) : (
                    <i className="material-icons aa-error-circle">
                      error_outline
                    </i>
                  )}
                </div>
                <div className="lb-body">
                  <div className="form-group fg-float primary-color">
                    {this.props.auth.activationSuccess ? (
                      <div>
                        <h3 className="aa-congratulations">Congratulations!</h3>
                        <p className="aa-reset-successful">
                          Your password has been reset successfully.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <h3 className="aa-congratulations">Sorry!</h3>
                        <p className="aa-reset-successful">
                          There has been an error in attempt to reset your
                          password.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                {this.props.auth.activationSuccess ? (
                  <div className="aa-portal-link">
                    <a href="/login">Login via our online portal</a>
                  </div>
                ) : (
                  <div
                    className="aa-portal-link orange-hover"
                    onClick={this.showForm.bind(this)}
                  >
                    Try Again
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="white-background">
          <div className="gs-container" id="gs-container">
            <div className="getting-started">
              <p className="gs-title">Getting Started</p>
              <p className="gs-subtitle">
                Please follow the following steps to get started
              </p>
            </div>
            <div className="step2">
              <div className="step2-left">
                <div className="gs-step-title">Download the app</div>
                <div className="gs-step-subtitle">
                  Click on one of these links:
                </div>
                <div className="gs-step-app-icons">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.LoanTracker&hl=en"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      id="android2"
                      alt="Get-it-on-Google-Play"
                      src="/images/gs-google-play.png"
                    />
                  </a>

                  <a
                    href="https://itunes.apple.com/us/app/eastern-union/id1125123079?mt=8"
                    className="login-apps-apple"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      id="ios2"
                      alt="Get it on the App Store"
                      src="/images/gs-app-store.png"
                    />
                  </a>
                </div>
              </div>
              <div className="divider-or">
                <div className="or-circle">OR</div>
              </div>
              <div className="step2-right">
                <div className="gs-step-title">Access our online portal</div>
                <div className="gs-step-subtitle">
                  at{" "}
                  <a href={config.PORTAL_URL}>
                    <span className="orange-text">{config.PORTAL_URL}</span>
                  </a>
                </div>
                <img src="/images/login.png" alt="login" />
              </div>
            </div>
            <Divider />
            <div className="step3">
              <div className="gs-step-title">Login</div>
              <div className="gs-step-subtitle">
                Enter your username and password on the homepage of the app.
                Your Schedule of Real Estate will appear with loan quotes,
                violations, & complaints for NYC properties, property documents,
                and comps & listings surrounding your portfolio.
              </div>
            </div>
            <Divider />
            <div className="gs-subfooter">
              <p className="view-on-portal">
                You may also view your account and all the app features on our
                online portal at <a href="/">{config.PORTAL_URL}</a>
              </p>
              <p className="gs-questions">
                If you have any questions or would like to make changes to your
                Schedule of Real Estate, please email them to{" "}
                <a href="mailto:app@easternunion.com">app@easternunion.com</a>{" "}
                or call
                <span> 732-301-3960</span>
              </p>
            </div>
            <div className="gs-footer">
              <p>
                <i className="material-icons">copyright</i>All Rights Reserved
                Eastern Union App 2017
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountActivationPage);
