import * as types from "./actionTypes";
import config from "../config";
import { tokenExpired } from "./authenticationActions";
import { ajax } from "../lib";
import { handleAPIError } from "../lib";
import { stripTrailingQuery } from "../lib/routing";

export function loadMissingQuotes() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_QUOTES });
    ajax({
      url: `${config.BASE_URL}lender/missing-quotes`,
      applyAuthHeader: true,
      success: async function (response) {
        if (response.data && response.data[0].awaitingQuote.length > 0) {
          const quotes = response.data[0].awaitingQuote;
          const newQuotesArray = [];
          const firstBatchArray = [];
          if (quotes.length > 8) {
            const firstBatchQuotes = quotes.splice(0, 8);

            await Promise.all(
              firstBatchQuotes.map(async (quote) => {
                const subresponse = await ajax({
                  url: `${config.BASE_URL}property-document`,
                  applyAuthHeader: true,
                  data: { id: quote.deal_id },
                });
                quote.documents = subresponse.data ? subresponse.data : [];
                firstBatchArray.push(quote);
              })
            );
            dispatch({
              type: types.LOAD_MISSING_QUOTES_SUCCESS,
              quotes: firstBatchArray,
              count: response.data[0].awaitingQuoteCount,
            });
          }

          await Promise.all(
            quotes.map(async (quote) => {
              const subresponse = await ajax({
                url: `${config.BASE_URL}property-document`,
                applyAuthHeader: true,
                data: { id: quote.deal_id },
              });
              quote.documents = subresponse.data ? subresponse.data : [];
              newQuotesArray.push(quote);
            })
          );
          dispatch({
            type: types.LOAD_MISSING_QUOTES_SUCCESS,
            quotes: [...firstBatchArray, ...newQuotesArray],
            count: response.data[0].awaitingQuoteCount,
          });
        } else {
          dispatch({ type: types.NO_QUOTES });
        }
      },
      error: handleAPIError(dispatch, types.NO_QUOTES),
    });
  };
}

export function loadAwaitingTermSheets() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_QUOTES });
    ajax({
      url: `${config.BASE_URL}lender/missing-quotes`,
      applyAuthHeader: true,
      success: async function (response) {
        if (response.data && response.data[0].awaitingSheet.length > 0) {
          let quotes = response.data[0].awaitingSheet;
          let newQuotesArray = [];

          for (const quote of quotes) {
            const response = await ajax({
              url: `${config.BASE_URL}property-document`,
              applyAuthHeader: true,
              data: { id: quote.deal_id },
            });
            quote.documents = response.data ? response.data : [];
            newQuotesArray.push(quote);
          }
          dispatch({
            type: types.LOAD_WAITING_TERM_SHEETS_SUCCESS,
            quotes: newQuotesArray,
            count: response.data[0].awaitingSheetCount,
          });
        } else {
          dispatch({ type: types.NO_QUOTES });
        }
      },
      error: handleAPIError(dispatch, types.NO_QUOTES),
    });
  };
}

export async function getQuoteDocuments(quotes) {
  let newQuotesArray = [];
  for (const quote of quotes) {
    const response = await ajax({
      url: `${config.BASE_URL}property-document`,
      applyAuthHeader: true,
      data: { id: quote.quote_id },
    });
    quote.documents = response.data ? response.data : [];
    newQuotesArray.push(quote);
  }
  return newQuotesArray;
}

export function getGoogleDoc(id) {
  return async (dispatch) => {
    const response = await ajax({
      url: `${config.BASE_URL}get-google-doc`,
      applyAuthHeader: true,
      data: { id },
    });

    switch (response.status) {
      case "200":
        const { data: _data } = response;
        // data can sometimes be returned with a trailing '?'
        const data = stripTrailingQuery(_data);
        const signedResponse = await ajax({
          url: `${data}?getSignedUrl=1`,
          applyAuthHeader: true,
        });
        const url = signedResponse?.data?.url || "";
        if (window && window.isNativeApp) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              action: "openUrl",
              data: { url },
            })
          );
        } else {
          window.open(url);
        }
        break;
      default:
        dispatch({
          type: types.GET_GOOGLE_DOC_FAILED,
          message: response.message,
        });
        break;
    }
  };
}

export function passDeal(quote_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}missing-quotes/pass-deal`,
      applyAuthHeader: true,
      type: "POST",
      data: { quote_id, note },
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          case "206":
            dispatch({
              type: types.PASS_MQ_DEAL_SUCCESS,
              data: response.message,
              quote_id: quote_id,
            });
            break;
          default:
            dispatch({
              type: types.PASS_MQ_DEAL_FAILED,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function getQuoteDetails(quote_id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_QUOTES });
    ajax({
      url: `${config.BASE_URL}quote-details`,
      applyAuthHeader: true,
      data: { quote_id },
      success: function (response) {
        if (!response.isResponse) {
          dispatch(tokenExpired());
        }
        if (response.data) {
          dispatch({
            type: types.DEAL_QUOTE_DETAIL_SUCCESS,
            data: response.data[0],
          });
        }
      },
    }).catch((err) => {});
  };
}

export function loadRateSpread() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}rate-spread`,
      success: function (response) {
        if (response.data) {
          dispatch({
            type: types.LOAD_DEAL_RATE_SPREAD,
            rates: response.data.rates,
            rateStrings: response.data.rateStrings,
            index_datetime: response.data.rateDateTime,
          });
        }
      },
    });
  };
}

export function closeToast() {
  return { type: types.CLOSE_MISSING_QUOTE_TOAST };
}

export function submitForm(
  previousPage,
  quote_id,
  quoted_amount,
  rate_type,
  spread_type,
  spread,
  manual_index_rate,
  lock_rate,
  rate_floor,
  quote_index,
  rate,
  term,
  amortization,
  ltv,
  prepay_type,
  recourse,
  bank_fee,
  prepay_penalties,
  number_of_option,
  option,
  notes_array,
  index_datetime,
  term_sheet,
  is_new
) {
  let data = {
    quote_id,
    quoted_amount,
    rate_type,
    spread_type,
    spread,
    manual_index_rate,
    lock_rate,
    rate_floor,
    quote_index,
    rate,
    term,
    amortization,
    ltv,
    prepay_type,
    recourse,
    bank_fee,
    prepay_penalties,
    number_of_option,
    option,
    notes_array,
    index_datetime,
    term_sheet,
    is_new,
  };
  Object.keys(data).forEach((key) => data[key] === null && delete data[key]);
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}save-quote`,
      applyAuthHeader: true,
      type: "POST",
      data,
      success: function (response) {
        switch (response.status) {
          case "206":
          case "E408":
            dispatch({
              type: types.DEAL_QUOTE_UPDATE,
              success: true,
              message: "Updated Successfully",
            });
            if (previousPage.includes("loans")) {
              dispatch({ type: types.QUOTE_LOAN_SUCCESS, quote_id: quote_id });
            }
            break;
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.DEAL_QUOTE_UPDATE,
              success: false,
              message: response.message,
            });
        }
      },
      error: handleAPIError(dispatch, {
        default: types.DEAL_QUOTE_UPDATE,
        E408: {
          type: types.DEAL_QUOTE_UPDATE,
          success: true,
          message: "Updated Successfully",
        },
      }),
    });
  };
}

export function addQuoteFormAmount() {
  return { type: types.INCREMENT_QUOTE_FORM_AMOUNT };
}

export function removeQuoteFormAmount() {
  return { type: types.DECREMENT_QUOTE_FORM_AMOUNT };
}

export function removeAllQuoteFormAmount() {
  return { type: types.REMOVE_QUOTE_FORM_AMOUNT };
}
