import * as types from "../actions/actionTypes";

const initialState = {
  investingCriteriaList: {},
  investingCriteria: {},
  loading: false,
  fundTypesList: [],
  returnTypesList: [],
  investmentTypesList: [],
  constructionTypesList: [],
  toast: {
    show: false,
    message: "",
    success: true,
  },
};

export default function investingCriteriaReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_INVESTING_CRITERIA:
      return Object.assign({}, state, { loading: true });
    case types.GET_INVESTING_CRITERIA_LIST:
      return Object.assign({}, state, {
        investingCriteriaList: action.data,
        loading: false,
      });
    case types.GET_INVESTING_CRITERIA:
      return Object.assign({}, state, {
        investingCriteria: action.data,
        loading: false,
      });
    case types.GET_FUND_TYPES:
      return Object.assign({}, state, { fundTypesList: action.data });
    case types.GET_INVESTING_CRITERIA_RETURN_TYPES:
      return Object.assign({}, state, { returnTypesList: action.data });
    case types.GET_INVESTMENT_TYPES:
      return Object.assign({}, state, { investmentTypesList: action.data });
    case types.GET_CONSTRUCTION_TYPES:
      return Object.assign({}, state, { constructionTypesList: action.data });
    case types.ADD_INVESTING_CRITERIA:
      return Object.assign({}, state, {
        toast: { show: true, message: action.message, success: action.success },
      });
    case types.EDIT_INVESTING_CRITERIA:
      return Object.assign({}, state, {
        toast: { show: true, message: action.message, success: action.success },
      });
    case types.DELETE_INVESTING_CRITERIA:
      return Object.assign({}, state, {
        toast: { show: true, message: action.message, success: action.success },
      });
    case types.CLOSE_IC_TOAST:
      return Object.assign({}, state, { toast: { ...initialState.toast } });
    default:
      return state;
  }
}
