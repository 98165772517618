import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  dialog: {
    maxWidth: 474,
    padding: "25px 0px",
    overflowY: "hidden",
  },
  drawer: {
    maxHeight: "calc(100% - 64px)",
    paddingTop: 20,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    overflowY: "hidden",
  },
  scrollableContent: {
    maxHeight: "calc(100% - 10px)",
    overflowY: "hidden",
    padding: "0px 60px",
    "&:hover": {
      overflowY: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      overflowY: "auto",
      paddingRight: 26,
      paddingLeft: 26,
      // hide scroll bar https://stackoverflow.com/a/49278385
      scrollbarWidth: "none" /* Firefox */,
      msOverflowStyle: "none" /* Internet Explorer 10+ */,
      /* WebKit (Chrome) */
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
  },
  closeButton: {
    position: "absolute",
    top: 5,
    right: 10,
    color: "#00112B",
  },
  title: {
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
    color: "#000000",
    marginBottom: 20,
    marginTop: 15,
    [theme.breakpoints.down("xs")]: {
      marginTop: 5,
    },
  },
  mobileClose: {
    minHeight: 4,
    maxWidth: 60,
    background: "#C1C1C1",
    textAlign: "center",
    width: 60,
    margin: "0 auto",
  },
  moreRates: {
    background: "#EFEFEF",
    borderRadius: 2.75125,
    width: "100%",
    height: 32,
    textAlign: "center",
    fontSize: 17.5169,
    textTransform: "uppercase",
    color: "#000000",
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      height: 23,
    },
  },
}));
