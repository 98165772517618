import { makeStyles } from "@material-ui/core";
import { overlayStyles, toolbarActionsStyles } from "../../sharedStyles";

export const useStyles = makeStyles((theme) => ({
  ...overlayStyles(theme),
  ...toolbarActionsStyles(theme),
  addNewPropertyBtn: {
    fontFamily: "Karla",
    fontWeight: "bold",
    fontSize: "17.9958px",
    marginBottom: (props) => (props.isMobile ? 25 : 50),
    marginTop: (props) => (props.isMobile ? 15 : 50),
  },
  noProperties: {
    fontFamily: "Karla",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 25,
  },
  noPropertiesTag: {
    background: "#00112B",
    opacity: 0.8,
    borderRadius: 5,
    fontSize: (props) =>
      props.isMobile ? "16px !important" : "19.6363px !important",
    color: "#FFFFFF",
    padding: "5px 15px",
    maxWidth: (props) => (props.isMobile ? 177 : 205),
    marginBottom: 10,
  },
  noPropertiesMsg: {
    fontSize: (props) => (props.isMobile ? 12 : "14.7273px"),
    maxWidth: (props) => (props.isMobile ? 237 : 213),
    textAlign: "center",
    color: "#00112B",
  },
  overrideSnackbar: {
    fontFamily: "Karla",
    fontSize: 13,
    background: "#EFEFEF",
    borderRadius: "5.68493px",
    color: "#00112B",
  },
  overrideMsg: {
    maxWidth: (props) => (props.isMobile ? 228 : 158),
    marginLeft: 10,
  },
  overrideSaveBtn: {
    marginRight: "unset",
    paddingLeft: "unset",
  },
}));
