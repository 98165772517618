import * as types from "./actionTypes";
import config from "../config";
import { ajax } from "../lib";
import { tokenExpired } from "./authenticationActions";
import { getListByType, handleAPIError } from "../lib";
import { TypesList } from "../lib";

export function defaultContactsLoad(page) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_DEFAULT_CONTACTS });
    ajax({
      url: `${config.BASE_URL}contacts/today`,
      applyAuthHeader: true,
      data: { page, paginate: true },
      success: function (response) {
        if (!response.isResponse) {
          dispatch(tokenExpired());
        } else {
          response.data
            ? dispatch({
                type: types.LOAD_DEFAULT_CONTACTS,
                data: response.data[0].contacts.data,
                today_call_total: response.data[0].today_call_total,
                next_page_url: response.data[0].contacts.next_page_url,
                current_page: response.data[0].contacts.current_page,
              })
            : dispatch({
                type: types.LOAD_DEFAULT_CONTACTS,
                data: [],
                today_call_total: null,
                next_page_url: null,
                current_page: null,
              });
        }
      },
    });
  };
}

export function updateSearch(search) {
  return { type: types.UPDATE_SEARCH, search: search };
}

export function predictiveContactsSearch() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_PREDICTIVE_CONTACTS });
    ajax({
      url: `${config.BASE_URL}contacts/list`,
      applyAuthHeader: true,
      success: function (response) {
        if (!response.isResponse) {
          dispatch(tokenExpired());
        }
        if (response.data && response.data.length >= 1) {
          dispatch({
            type: types.LOAD_PREDICTIVE_CONTACTS_SUCCESS,
            predictiveContacts: response.data,
          });
        } else {
          dispatch({ type: types.NO_PREDICTIVE_CONTACTS });
        }
      },
    });
  };
}

export function newSearch(search, phone) {
  return async (dispatch) => {
    dispatch({ type: types.LOADING_PREDICTIVE_CONTACTS_SEARCH });
    let response = await ajax({
      url: `${config.BASE_URL}contacts/today`,
      applyAuthHeader: true,
      data: { search, phone },
    });

    switch (response.status) {
      case "200":
        let contacts = response.data ? response.data[0] : [];
        dispatch({
          type: types.LOAD_PREDICTIVE_CONTACTS_SEARCH,
          contactsSearch: contacts,
        });
        break;
      case "E107":
        dispatch({
          type: types.CONTACTS_SEARCH_FAIL,
          message: response.message,
        });
        break;
      default:
        dispatch({ type: types.NO_PREDICTIVE_CONTACTS_SEARCH });
        break;
    }
  };
}

export function pwIpContactLoad() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_PW_IP_CONTACTS });
    ajax({
      url: `${config.BASE_URL}pw-ip-contacts`,
      applyAuthHeader: true,
      success: function (response) {
        if (!response.isResponse) {
          dispatch(tokenExpired());
        }
        if (response.data && response.data.length >= 1) {
          dispatch({
            type: types.LOAD_PW_IP_CONTACTS_SUCCESS,
            pwipContacts: response.data[0],
          });
        } else {
          dispatch({ type: types.NO_PW_IP_CONTACTS });
        }
      },
    });
  };
}

export function starredContactLoad() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_STARRED_CONTACTS });
    ajax({
      url: `${config.BASE_URL}starred-contacts`,
      applyAuthHeader: true,
      success: function (response) {
        if (!response.isResponse) {
          dispatch(tokenExpired());
        }
        if (response.data && response.data.length >= 1) {
          dispatch({
            type: types.LOAD_STARRED_CONTACTS_SUCCESS,
            starredContacts: response.data[0],
          });
        } else {
          dispatch({ type: types.NO_STARRED_CONTACTS });
        }
      },
    });
  };
}

export function closingClientsContactLoad() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_CLOSING_CLIENTS_CONTACTS });
    ajax({
      url: `${config.BASE_URL}closing-clients-contacts`,
      applyAuthHeader: true,
      success: function (response) {
        if (!response.isResponse) {
          dispatch(tokenExpired());
        }
        if (response.data && response.data.length >= 1) {
          dispatch({
            type: types.LOAD_CLOSING_CLIENTS_CONTACTS_SUCCESS,
            closingClientContacts: response.data[0],
          });
        } else {
          dispatch({ type: types.NO_CLOSING_CLIENTS_CONTACTS });
        }
      },
    });
  };
}

export function updateDate(contactId, date) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}contact/${contactId}`,
      applyAuthHeader: true,
      type: "POST",
      data: { date },
      success: function (response) {
        dispatch({
          type: types.UPDATE_DATE_SUCCESS,
          message: response.message,
        });
      },
    });
  };
}

export function updateNote(contactId, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}contact/${contactId}`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        note: note,
      },
      success: function (response) {
        // dispatch(updateStarCompleted(deal_id));
      },
    });
  };
}

export function newQuickNote(contactId, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}contact/${contactId}`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        quick_note: note,
      },
      success: function (response) {
        if (response.status === "205") {
          dispatch({
            type: types.UPDATE_NOTE_SUCCESS_TOAST,
            message: response.message,
          });
        }
        if (response.status === "217") {
          dispatch({
            type: types.UPDATE_NOTE_SUCCESS_DATE_AND_TOAST,
            message: response.message,
            data: response.data,
          });
        }
        if (response.status === "E408" || response.status === "E107") {
          dispatch({
            type: types.UPDATE_NOTE_FAILED_TOAST,
            message: response.message,
          });
        }
        if (response.status === "E101") {
          dispatch(tokenExpired());
        }
      },
    });
  };
}

export function updateNoteDefaultContacts(contact_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}contact/${contact_id}`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        note: note,
      },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else {
          dispatch({
            type: types.UPDATE_DEFAULT_CONTACT_COMPLETED,
            noteInfo: { contact_id: contact_id, note: note },
            message: response.message,
          });
        }
      },
    });
  };
}

export function updateNotePwIpContacts(contact_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}contact/${contact_id}`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        note: note,
      },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else {
          dispatch({
            type: types.UPDATE_PW_IP_CONTACT_COMPLETED,
            noteInfo: { contact_id: contact_id, note: note },
            message: response.message,
          });
        }
      },
    });
  };
}

export function updateNoteStarredContacts(contact_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}contact/${contact_id}`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        note: note,
      },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else {
          dispatch({
            type: types.UPDATE_STARRED_CONTACT_COMPLETED,
            noteInfo: { contact_id: contact_id, note: note },
            message: response.message,
          });
        }
      },
    });
  };
}

export function updateClosingClientNote(contact_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}contact/${contact_id}`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        note: note,
      },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else {
          dispatch({
            type: types.UPDATE_CLOSING_CLIENT_CONTACT_COMPLETED,
            noteInfo: { contact_id: contact_id, note: note },
            message: response.message,
          });
        }
      },
    });
  };
}

export function updateResultContactsNote(contact_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}contact/${contact_id}`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        note: note,
      },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else {
          dispatch({
            type: types.UPDATE_RESULT_CONTACT_COMPLETED,
            noteInfo: { contact_id: contact_id, note: note },
            message: response.message,
          });
        }
      },
    });
  };
}

export function createClientLogin(contactId, userTypes) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}client-login`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        contact_id: contactId,
        is_client: userTypes.indexOf("client") >= 0,
        is_lender: userTypes.indexOf("lender") >= 0,
        is_sales_broker: userTypes.indexOf("sales broker") >= 0,
        is_investor: userTypes.indexOf("investor") >= 0,
      },
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          case "211":
            dispatch(sendWelcomeEmail(contactId));
            break;
          default:
            dispatch({
              type: types.CREATE_CLIENT_LOGIN_FAILED,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function sendWelcomeEmail(contact_id) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}welcome-email`,
      applyAuthHeader: true,
      type: "POST",
      data: { contact_id },
      success: function (response) {
        dispatch({
          type: types.SEND_WELCOME_EMAIL,
          message: response.message,
          success: true,
        });
      },
      error: handleAPIError(dispatch, types.SEND_WELCOME_EMAIL),
    });
  };
}

export function updateStarDefaultContact(contact_id, owner_starred) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_UPDATE_STAR });
    ajax({
      url: `${config.BASE_URL}star-contact`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        contact_id: contact_id,
        contact_starred: owner_starred,
      },
      success: function (response) {
        dispatch({
          type: types.UPDATE_DEFAULT_CONTACT_STARRED_SUCCESS,
          contact_id: contact_id,
          contact_starred: owner_starred,
          message: response.message,
        });
      },
    });
  };
}

export function updateGeneralStar(contact_id, owner_starred) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_UPDATE_STAR });
    ajax({
      url: `${config.BASE_URL}star-contact`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        contact_id: contact_id,
        contact_starred: owner_starred,
      },
      success: function (response) {
        dispatch({
          type: types.UPDATE_GENERAL_STARRED_SUCCESS,
          message: response.message,
        });
      },
    });
  };
}

export function updateStarStarredContacts(contact_id, owner_starred) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_UPDATE_STAR });
    ajax({
      url: `${config.BASE_URL}star-contact`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        contact_id: contact_id,
        contact_starred: owner_starred,
      },
      success: function (response) {
        dispatch({
          type: types.UPDATE_STARRED_CONTACT_STARRED_SUCCESS,
          contact_id: contact_id,
          contact_starred: owner_starred,
          message: response.message,
        });
      },
    });
  };
}

export function updateStarPWIP(contact_id, owner_starred) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_UPDATE_STAR });
    ajax({
      url: `${config.BASE_URL}star-contact`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        contact_id: contact_id,
        contact_starred: owner_starred,
      },
      success: function (response) {
        dispatch({
          type: types.UPDATE_PWIP_CONTACT_STARRED_SUCCESS,
          contact_id: contact_id,
          contact_starred: owner_starred,
          message: response.message,
        });
      },
    });
  };
}

export function updateStarClosingClient(contact_id, owner_starred) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_UPDATE_STAR });
    ajax({
      url: `${config.BASE_URL}star-contact`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        contact_id: contact_id,
        contact_starred: owner_starred,
      },
      success: function (response) {
        dispatch({
          type: types.UPDATE_CLOSING_CONTACT_STARRED_SUCCESS,
          contact_id: contact_id,
          contact_starred: owner_starred,
          message: response.message,
        });
      },
    });
  };
}

export function updateStarResultContact(contact_id, owner_starred) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_UPDATE_STAR });
    ajax({
      url: `${config.BASE_URL}star-contact`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        contact_id: contact_id,
        contact_starred: owner_starred,
      },
      success: function (response) {
        dispatch({
          type: types.UPDATE_RESULT_CONTACT_STARRED_SUCCESS,
          contact_id: contact_id,
          contact_starred: owner_starred,
          message: response.message,
        });
      },
    });
  };
}

export function updateGeneralNote(contact_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}contact/${contact_id}`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        note: note,
      },
      success: function (response) {
        dispatch({
          type: types.UPDATE_NOTE_SUCCESS_TOAST,
          message: response.message,
        });
      },
    });
  };
}

export function closeToast() {
  return { type: types.CLOSE_TOAST };
}

export function getContactInfo(Id, previousPage) {
  return async (dispatch) => {
    dispatch({ type: types.LOADING_CONTACT_DETAILS });
    let response = await ajax({
      url: `${config.BASE_URL}contact/${Id}`,
      applyAuthHeader: true,
    });

    if (!response.isResponse) {
      dispatch(tokenExpired());
    }
    if (response.data) {
      dispatch({
        type: types.LOAD_CONTACT_DETAILS,
        contactsSearch: response.data[0],
      });
    } else {
      dispatch({ type: types.NO_CONTACT_DETAILS });
    }
  };
}

export function addContact(contactId, type, value) {
  return async (dispatch) => {
    let response = await ajax({
      url: `${config.BASE_URL}add-contact-detail`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        contact_type: type,
        description: value,
        contact_id: contactId,
      },
    });

    switch (response.status) {
      case "200":
        dispatch(getContactInfo(contactId));
        dispatch({
          type: types.ADD_CONTACT_DETAILS_SUCCESS,
          message: response.message,
        });
        break;
      case "E403":
        dispatch(tokenExpired());
        break;
      default:
        dispatch({
          type: types.ADD_CONTACT_DETAILS_FAILED,
          message: response.message,
        });
        break;
    }
  };
}

export function editContactDetails(
  contact_id,
  phone_contact_info,
  email_contact_info,
  fax_contact_info,
  website_contact_info
) {
  phone_contact_info.forEach((c) => delete c.contact_type_name);
  email_contact_info.forEach((c) => delete c.contact_type_name);
  fax_contact_info.forEach((c) => delete c.contact_type_name);
  website_contact_info.forEach((c) => delete c.contact_type_name);
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}update-contact/${contact_id}`,
      applyAuthHeader: true,
      data: JSON.stringify({
        phone_contact_info,
        fax_contact_info,
        email_contact_info,
        website_contact_info,
      }),
      contentType: "application/json",
      type: "PUT",
      success: function (response) {
        switch (response.status) {
          case "202":
            dispatch({
              type: types.ADD_CONTACT_DETAILS_SUCCESS,
              message: response.message,
            });
            dispatch(getContactInfo(contact_id));
            break;
          case "E101":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.ADD_CONTACT_DETAILS_FAILED,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function blockThisNumber(contact_id, contact_type, value) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}dnc`,
      applyAuthHeader: true,
      type: "POST",
      data: { contact_id, contact_type, reason_code: 2, value },
      success: function (response) {
        dispatch(getContactInfo(contact_id));
        dispatch({
          type: types.ADD_CONTACT_DETAILS_SUCCESS,
          message: response.message,
        });
      },
    });
  };
}

export function deleteProfileContact(cpId, contactId) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}my-profile/contact-info`,
      applyAuthHeader: true,
      type: "PUT",
      data: { cp_id: cpId, contact_id: contactId },
      success: function (response) {
        dispatch(getContactInfo(contactId));
        dispatch({
          type: types.ADD_CONTACT_DETAILS_SUCCESS,
          message: response.message,
        });
      },
    });
  };
}

export function setPageAndScrollPos(position) {
  return { type: types.SET_CONTACT_SCROLL_P0S, position };
}

// export function checkIfRecentNote(contact_id) {
//   return dispatch => {
//     ajax({
//       url: `${config.BASE_URL}saved-note-today`,
//       applyAuthHeader: true,
//       data: {contact_id},
//       success: function (response) {
//         dispatch({type: types.CONTACT_GIVEBACK_CHECK_NOTE, status: response.status});
//       }
//     });
//   }
// }

export function getGivebackReasonCodes() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}lists/giveback`,
      success: function (response) {
        dispatch({
          type: types.GET_GIVEBACK_REASON_CODES,
          data: response.data || [],
        });
      },
    });
  };
}

export function givebackContact(contact_id, note, reason_code, currentArray) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}giveback`,
      applyAuthHeader: true,
      data: { note, contact_id, reason_code: parseInt(reason_code, 10) },
      type: "POST",
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          case "E107":
            dispatch({
              type: types.CONTACT_GIVEBACK_FAILED,
              message: response.message,
            });
            break;
          case "E408":
            dispatch({
              type: types.CONTACT_GIVEBACK_FAILED,
              message: response.message,
            });
            break;
          default:
            dispatch({
              type: types.CONTACT_GIVEBACK_SUCCESS,
              status: response.status,
              currentArray: currentArray,
              contact_id: contact_id,
            });
        }
      },
    });
  };
}

export function getContactActiveDeals(contact_id) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}active-deals`,
      applyAuthHeader: true,
      data: { contact_id },
      success: function (response) {
        if (!response.data) {
          dispatch({ type: types.NO_ACTIVE_DEALS });
        } else {
          dispatch({
            type: types.LOAD_ACTIVE_DEALS_SUCCESS,
            activeDeals: response.data.activeDeals,
            linkedActiveDeals: response.data.linkedActiveDeals,
          });
        }
      },
    });
  };
}

export function getContactMyPortfolio(contact_id) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}remaining-portfolio`,
      applyAuthHeader: true,
      data: { contact_id },
      success: function (response) {
        if (!response.data) {
          dispatch({ type: types.NO_REMAINING_DEALS });
        } else {
          dispatch({
            type: types.LOAD_REMAINING_DEALS_SUCCESS,
            remainingDeals: response.data.remainingDeals,
            linkedRemainingDeals: response.data.linkedRemainingDeals,
          });
        }
      },
    });
  };
}

export function getPreviousPage(contact_section, searchType, contact_id) {
  var data = {};
  if (contact_section.includes("search")) {
    if (searchType === "phone") {
      data = { phone: contact_section.split("/")[1] };
    } else {
      data = { search: contact_section.split("/")[1] };
    }
    contact_section = "contacts/today";
  }
  return (dispatch) => {
    dispatch({ type: types.LOADING_CONTACT_PAGE_INFO });
    ajax({
      url: `${config.BASE_URL}${contact_section}`,
      applyAuthHeader: true,
      data: { paginate: true, ...data },
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          case "200":
            if (response.data && response.data[0].contacts) {
              const contacts =
                response.data[0].contacts.length > 0
                  ? response.data[0].contacts
                  : response.data[0].contacts.data;
              var item = contacts.find(
                (x) => x.contact_id === parseInt(contact_id, 10)
              );
              var index = contacts.indexOf(item);

              if (index >= 0 && index <= contacts.length - 1) {
                var previousItem = contacts[index - 1];
                if (previousItem) {
                  dispatch({
                    type: types.CONTACT_PREVIOUS_PAGE,
                    data: { contact_id: previousItem.contact_id },
                  });
                } else {
                  dispatch({
                    type: types.NO_CONTACT_PREVIOUS_PAGE,
                    data: { contact_id: null },
                  });
                }
              }

              if (index >= 0 && index < contacts.length - 1) {
                var nextItem = contacts[index + 1];
                if (nextItem) {
                  dispatch({
                    type: types.CONTACT_NEXT_PAGE,
                    data: { contact_id: nextItem.contact_id },
                  });
                } else {
                  dispatch({
                    type: types.NO_CONTACT_NEXT_PAGE,
                    data: { contact_id: null },
                  });
                }
              }
            }
            break;
          default:
            dispatch({ type: types.NO_CONTACT_DETAILS });
            break;
        }
      },
    });
  };
}

export function changeClientRating(
  contact_id,
  client_rating,
  currentArray = null
) {
  return async (dispatch) => {
    let response = await ajax({
      url: `${config.BASE_URL}client/update`,
      applyAuthHeader: true,
      data: { contact_id, client_rating },
      type: "POST",
    });

    switch (response.status) {
      case "E403":
        dispatch(tokenExpired());
        break;
      case "E107":
      case "E408":
        dispatch({
          type: types.CHANGE_CLIENT_RATING_FAILED,
          contact_id: contact_id,
          message: response.message,
        });
        break;
      default:
        dispatch({
          type: types.CHANGE_CLIENT_RATING_SUCCESS,
          currentArray: currentArray,
          contact_id: contact_id,
          rating: client_rating,
          message: response.message,
        });
        break;
    }
  };
}

export function loadClientStatus() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}status-list`,
      applyAuthHeader: true,
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else {
          dispatch({ type: types.LOAD_CLIENT_STATUS, data: response.data });
        }
      },
    });
  };
}

export function submitStatusModal(contact_id, status) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}client/update`,
      applyAuthHeader: true,
      type: "POST",
      data: { contact_id, status },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        }
        if (response.status === "E107" || response.status === "E408") {
          dispatch({
            type: types.SUBMIT_CLIENT_STATUS_FAILED,
            message: response.message,
          });
        } else {
          dispatch(getContactInfo(contact_id));
          dispatch({
            type: types.SUBMIT_CLIENT_STATUS_SUCCESS,
            message: response.message,
          });
        }
      },
    });
  };
}

export function loadLPServiceStatus() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}lpservice-types`,
      success: function (response) {
        dispatch({ type: types.LOAD_LP_SERVICE_STATUS, data: response.data });
      },
    });
  };
}

export function submitLPServiceModal(contact_id, portal_maintenance) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}client/update`,
      applyAuthHeader: true,
      type: "POST",
      data: { contact_id, portal_maintenance },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        }
        if (response.status === "E107" || response.status === "E408") {
          dispatch({
            type: types.SUBMIT_LP_STATUS_FAILED,
            message: response.message,
          });
        } else {
          dispatch(getContactInfo(contact_id));
          dispatch({
            type: types.SUBMIT_LP_STATUS_SUCCESS,
            message: response.message,
          });
        }
      },
    });
  };
}

export function closeLPClientStatusToast() {
  return { type: types.CLOSE_LP_CLIENT_STATUS_TOAST };
}

export function showNoDataToast() {
  return {
    type: types.UPDATE_NOTE_FAILED_TOAST,
    message: "No street view available for this property.",
  };
}

export function getContactRatingList() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.ClientRating);
    dispatch({ type: types.GET_CONTACT_RATING_LIST, data });
  };
}

export function getJobDescriptionTypesList() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.ContactJobDescription);
    dispatch({ type: types.GET_JOB_DESCRIPTION_LIST, data });
  };
}

export function getContactTypeList() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}lists/contact-types`,
      success: function (response) {
        if (response.status === "200") {
          dispatch({ type: types.GET_CONTACT_TYPE_LIST, data: response.data });
        }
      },
    });
  };
}

export function getContactInfoTypeList() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.ContactInfo);
    dispatch({ type: types.GET_CONTACT_INFO_TYPE_LIST, data });
  };
}

export function getPrefixTypeList() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.Prefix);
    dispatch({ type: types.GET_PREFIX_TYPE_LIST, data });
  };
}

export function getHolidayGreetingTypeList() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.Holiday);
    dispatch({ type: types.GET_HOLIDAY_GREETING_TYPE_LIST, data });
  };
}

export function getGiftTypeList() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.GaveGift);
    dispatch({ type: types.GET_GAVE_GIFT_TYPE_LIST, data });
  };
}

export function getStateList() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}states`,
      success: function (response) {
        if (response.status === "200") {
          dispatch({ type: types.GET_STATE_LIST, data: response.data });
        }
      },
    });
  };
}

export function createNewContact(
  first_name,
  last_name,
  nick_name,
  salutation,
  company_name,
  job_description,
  corporate_title,
  contact_birthday,
  address,
  phone_contact_info,
  fax_contact_info,
  email_contact_info,
  website_contact_info,
  contact_types,
  source,
  holiday,
  gave_gift,
  contact_notes
) {
  return (dispatch) => {
    dispatch({ type: types.START_SUBMIT_CONTACT });
    ajax({
      url: `${config.BASE_URL}new-contact`,
      applyAuthHeader: true,
      data: {
        first_name,
        last_name,
        nick_name,
        salutation,
        company_name,
        job_description,
        corporate_title,
        contact_birthday,
        address,
        phone_contact_info,
        fax_contact_info,
        email_contact_info,
        website_contact_info,
        contact_types,
        source,
        holiday,
        gave_gift,
        contact_notes,
      },
      type: "POST",
      success: function (response) {
        switch (response.status) {
          case "201":
            dispatch({
              type: types.CREATE_CONTACT_SUCCESS,
              message: response.message,
              data: response.data.id,
            });
            dispatch(getContactById(response.data.id));
            break;
          case "E101":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.CREATE_CONTACT_FAILED,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function getContactById(id) {
  return (dispatch) => {
    dispatch({ type: types.START_GET_CONTACT });
    ajax({
      url: `${config.BASE_URL}get-contact/${id}`,
      applyAuthHeader: true,
      success: function (response) {
        if (response.isResponse) {
          dispatch({ type: types.GET_CONTACT_BY_ID, contact: response.data });
        } else {
          dispatch(tokenExpired());
        }
      },
    });
  };
}

export function updateContact(
  first_name,
  last_name,
  nick_name,
  salutation,
  company_name,
  job_description,
  corporate_title,
  contact_birthday,
  address,
  phone_contact_info,
  fax_contact_info,
  email_contact_info,
  website_contact_info,
  contact_types,
  source,
  holiday,
  gave_gift,
  contact_notes,
  contact_id
) {
  return (dispatch) => {
    dispatch({ type: types.START_SUBMIT_CONTACT });
    ajax({
      url: `${config.BASE_URL}update-contact/${contact_id}`,
      applyAuthHeader: true,
      data: JSON.stringify({
        first_name,
        last_name,
        nick_name,
        salutation,
        company_name,
        job_description,
        corporate_title,
        contact_birthday,
        address,
        phone_contact_info,
        fax_contact_info,
        email_contact_info,
        website_contact_info,
        contact_types,
        source,
        holiday,
        gave_gift,
        contact_notes,
      }),
      contentType: "application/json",
      type: "PUT",
      success: function (response) {
        switch (response.status) {
          case "202":
            dispatch({
              type: types.UPDATE_CONTACT_SUCCESS,
              message: response.message,
              data: response.data.id,
            });
            dispatch(getContactById(response.data.id));
            break;
          case "E101":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.UPDATE_CONTACT_FAILED,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function deleteContact(id) {
  return (dispatch) => {
    dispatch({ type: types.START_DELETE_CONTACT });
    ajax({
      url: `${config.BASE_URL}contact/${id}`,
      applyAuthHeader: true,
      type: "DELETE",
      success: function (response) {
        switch (response.status) {
          case "E403":
            //Todo: redirect to unauthorize screen
            break;
          case "E107":
          case "E408":
            dispatch({
              type: types.DELETE_CONTACT,
              success: false,
              message: response.message,
            });
            break;
          default:
            dispatch({
              type: types.DELETE_CONTACT,
              success: true,
              message: "Contact deleted successfully!",
            });
            break;
        }
      },
    });
  };
}

export function clearContact() {
  return { type: types.CLEAR_CONTACT };
}
