import { createMuiTheme, useMediaQuery } from "@material-ui/core";
// import { palette } from "@material-ui/system";

// To copy things from the default:
// const defaults = createMuiTheme();

/**
 * Override box shadows. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */

// const boxShadow = "0px 0px 23.4055px rgba(0, 0, 0, 0.11)";
// const shadows = [];
// for (let i = 0; i < 25; i++) {
//     shadows[i] = "none";
// }
// shadows[3] = boxShadow;

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with overrides to match design **exactly**.
 */
export const defaultTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: { main: "#122035" },
    secondary: { main: "#10B8C7" },
    error: { main: "#FF5630" },
    text: {
      default: "#00112B !important",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "Karla",
    fontWeightRegular: "normal",
  },
  props: {
    MuiInputAdornment: {
      disableTypography: true,
    },
  },
  overrides: {
    MuiInputAdornment: {
      root: {
        fontSize: 19,
        lineHeight: 22,
      },
      positionEnd: {
        "& svg": {
          color: "#10B8C7",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        "label + &": {
          marginTop: 20,
        },
        boxSizing: "border-box",
        borderRadius: 4,
        border: "1px solid #00112B",
        width: "100%",
        "&$focused": {
          border: "1px solid #10B8C7",
        },
        "&$error": {
          borderColor: "#FF5630",
        },
        "&$disabled": {
          backgroundColor: "#EFEFEF",
          border: "none",
          color: "#00112B",
        },
      },
      input: {
        fontFamily: "Roboto Condensed",
        fontSize: 19,
        // lineHeight: 22,
      },
      focused: {},
      notchedOutline: { border: "none", opacity: "0.5" },
      error: {},
    },
    MuiInputLabel: {
      root: {
        color: "#00112B",
        fontSize: 14,
        lineHeight: 16,
        "&$focused": {
          color: "#10B8C7",
        },
        "&$error": {
          color: "#FF5630",
        },
        "&$disabled": {
          color: "#00112B",
        },
      },
      focused: {},
    },
    MuiTableCell: {
      root: {
        borderBottom: "unset",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "unset",
        },
      },
    },
  },
  // shadows,
});
window.theme = defaultTheme;
/** Returns `true` if the screen is sized for mobile. */
export function useMobile() {
  return window.innerWidth < defaultTheme.breakpoints.values.sm;
}
/** Returns `true` if the PWA is installed and running in standalone mode. */
export function useInstalledPWA() {
  return useMediaQuery("(display-mode: standalone)");
}
