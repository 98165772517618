import { makeStyles } from "@material-ui/core";
import { calculatorInputs, overlayStyles } from "../sharedStyles";
export const useStyles = makeStyles(theme => ({
  ...calculatorInputs(theme),
  ...overlayStyles(theme),
  container: {
    fontFamily: "Karla",
    fontStyle: "normal"
  },
  totalsBox: {
    display: "flex",
    fontSize: 13,
    width: "100%",
    boxShadow: "0px 15px 15px -15px rgba(0, 0, 0, 0.14)",
    height: 76,
    top: 95,
    padding: "0px 20px",
    alignItems: "center"
  },
  totalsLbl: {
    fontFamily: "Karla",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 13
  },
  total: {
    color: "#10B8C7",
    fontFamily: "Roboto Condensed",
    fontSize: 20
  },
  loanContainer: {
    paddingTop: 41
  },
  loanName: {
    fontFamily: "Karla",
    fontWeight: "bold",
    fontSize: 18,
    textTransform: "uppercase",
    color: "#000000"
  },
  rateIcon: {
    fontWeight: 300,
    fontSize: 20,
    color: "#10B8C7",
    marginLeft: 5,
    cursor: "pointer",
    pointerEvents: "all",
  },
}));
