import * as types from "../actionTypes";
import { ajax } from "../../lib";
import moment from "moment";
import config from "../../config";

function startYieldMaintenanceLoading(loading) {
  return { type: types.START_YIELD_MAINTENANCE_LOADING, loading };
}

function stopYieldMaintenanceLoading(loading) {
  return { type: types.STOP_YIELD_MAINTENANCE_LOADING, loading };
}

function updateYieldMaintenance(values) {
  return { type: types.UPDATE_YIELD_MAINTENANCE, values };
}

function loadRatesSuccess(treasuryRates) {
  return { type: types.LOAD_TREASURY_RATES, treasuryRates };
}

export function loadTreasuryRates() {
  return (dispatch) => {
    dispatch(startYieldMaintenanceLoading(true));
    ajax({
      url: `${config.BASE_URL}yield-maintenance`,
      success: function (response) {
        if (response.data.length > 0) {
          const treasuryRates = response.data[0];
          getRemainingTreasuryRates(treasuryRates);
          dispatch(loadRatesSuccess(treasuryRates));
          dispatch(stopYieldMaintenanceLoading(false));
        }
      },
    });
  };
}

function getRemainingTreasuryRates(treasuryRates) {
  let i;
  for (i = 4; i < 6; i++) {
    treasuryRates[
      i + "_month_us_treasury_rate"
    ] = calculateRemainingRatesMonths(3, 6, i, treasuryRates);
  }
  for (i = 7; i < 12; i++) {
    treasuryRates[
      i + "_month_us_treasury_rate"
    ] = calculateRemainingRatesSixMonthsToYear(i, treasuryRates);
  }
  for (i = 4; i < 7; i += 2) {
    treasuryRates[i + "_year_us_treasury_rate"] = calculateRemainingRatesYears(
      i - 1,
      i + 1,
      i,
      treasuryRates
    );
  }
  for (i = 8; i < 10; i++) {
    treasuryRates[i + "_year_us_treasury_rate"] = calculateRemainingRatesYears(
      7,
      10,
      i,
      treasuryRates
    );
  }
  for (i = 11; i < 20; i++) {
    treasuryRates[i + "_year_us_treasury_rate"] = calculateRemainingRatesYears(
      10,
      20,
      i,
      treasuryRates
    );
  }
  for (i = 21; i < 30; i++) {
    treasuryRates[i + "_year_us_treasury_rate"] = calculateRemainingRatesYears(
      20,
      30,
      i,
      treasuryRates
    );
  }
}

function calculateRemainingRatesYears(
  availableYearBelow,
  availableYearAbove,
  currentYear,
  treasuryRates
) {
  let availRateBelow = parseFloat(
    treasuryRates[availableYearBelow + "_year_us_treasury_rate"]
  );
  let availRateAbove = parseFloat(
    treasuryRates[availableYearAbove + "_year_us_treasury_rate"]
  );
  return (
    ((currentYear - availableYearBelow) * (availRateAbove - availRateBelow)) /
      (availableYearAbove - availableYearBelow) +
    availRateBelow
  );
}

function calculateRemainingRatesMonths(
  availableMonthBelow,
  availableMonthAbove,
  currentMonth,
  treasuryRates
) {
  const availRateBelow = parseFloat(
    treasuryRates[availableMonthBelow + "_month_us_treasury_rate"]
  );
  const availRateAbove = parseFloat(
    treasuryRates[availableMonthAbove + "_month_us_treasury_rate"]
  );
  return (
    ((currentMonth - availableMonthBelow) * (availRateAbove - availRateBelow)) /
      (availableMonthAbove - availableMonthBelow) +
    availRateBelow
  );
}
function calculateRemainingRatesSixMonthsToYear(currentMonth, treasuryRates) {
  const availRateBelow = parseFloat(treasuryRates["6_month_us_treasury_rate"]);
  const availRateAbove = parseFloat(treasuryRates["1_year_us_treasury_rate"]);
  return (
    ((currentMonth - 6) * (availRateAbove - availRateBelow)) / (12 - 6) +
    availRateBelow
  );
}

export function calculateYieldMaintenance(values, trigger) {
  return function (dispatch) {
    values.errors = {};
    handleErrors(values, trigger);
    if (isEmptyObject(values.errors)) {
      getInterpolatedRate(values, dispatch);
      if (
        values.loanAmount.length > 0 &&
        values.term.length > 0 &&
        values.amortization.length > 0 &&
        values.interestRate.length > 0 &&
        values.closingDate &&
        values.payoffDate &&
        values.interpolatedRate
      ) {
        calculatePrePaymentPenalty(values);
      }
    } else {
      values.prePaymentPenalty = 0;
    }
    dispatch(updateYieldMaintenance(values));
  };
}

function isEmptyObject(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

function handleErrors(values, trigger) {
  if (parseFloat(values.amortization) > 30) {
    values.errors.amortization = "The Amortization value cannot be above 30";
  }
  if (parseFloat(values.amortization) <= parseFloat(values.term)) {
    values.errors.amortizationTerm =
      "The Amortization value must be greater than the Term value";
  }
  if (parseFloat(values.term) > 30) {
    values.errors.term = "The term value cannot be above 30";
  }
  if (values.payoffDate) {
    var closingDate = moment(values.closingDate).clone();
    var payoffDate = moment(values.payoffDate).clone();

    var years = payoffDate.diff(closingDate, "year");
    closingDate.add(years, "years");

    var months = payoffDate.diff(closingDate, "months");
    closingDate.add(months, "months");

    var days = payoffDate.diff(closingDate, "days");

    if (days > 0) {
      if (days >= 16) {
        months += 1; //round months up if 16 days or more
      }
    }
    if (months > 0) {
      if (months >= 6) {
        years += 1; //round years up if 6 or more months
      }
    }

    if (years < 1) {
      if (months < 3) {
        values.errors.payoffDate =
          "The Payoff Date must be more than 3 months and less than 30 years away";
      }
    } else if (years > 30) {
      values.errors.payoffDate =
        "The Payoff Date must be more than 3 months and less than 30 years away";
    }
  }
  if (values.closingDate && values.payoffDate && values.term) {
    let year = moment(values.payoffDate).diff(
      moment(values.closingDate),
      "year"
    );
    if (year > values.term) {
      values.errors.payoffDateClosingDate =
        "The time difference between the closing and payoff dates cannot be greater than the term years";
    }
  }
  if (
    values.loanAmount ||
    values.interestRate ||
    values.term ||
    values.amortization
  ) {
    if (isEmptyObject(values.treasuryRates)) {
      values.errors.noRates =
        "Unable to calculate due to error loading treasury rates. ";
    }
  }
}

function getInterpolatedRate(values, dispatch) {
  var closingDate = moment(values.closingDate).clone();
  var today = moment();

  var years = today.diff(closingDate, "year");
  closingDate.add(years, "years");

  var months = today.diff(closingDate, "months");
  closingDate.add(months, "months");

  var days = today.diff(closingDate, "days");

  years = values.term - years;

  if (days > 0) {
    if (days >= 16) {
      months += 1; //round months up if 16 days or more
    }
  }
  if (months > 0) {
    if (months >= 6) {
      years += 1; //round years up if 6 or more months
    }
  }

  let timeUntilPayoff = 0;
  if (years > 0) {
    timeUntilPayoff = years + "_year_us_treasury_rate";
  } else if (months >= 3) {
    timeUntilPayoff = months + "_month_us_treasury_rate";
  }

  if (timeUntilPayoff) {
    if (!isEmptyObject(values.treasuryRates)) {
      values.interpolatedRate = parseFloat(
        values.treasuryRates[timeUntilPayoff]
      );
    }
  }
}

function calculateRemainingPayments(values) {
  let monthsDif = moment(values.payoffDate).diff(
    values.closingDate,
    "months",
    true
  );
  return values.term * 12 - Math.floor(monthsDif);
}

function calculateMonthlyPayment(values) {
  const intRateDiv12 = values.interestRate / 100 / 12;
  const amortMult12 = values.amortization * 12;
  return (
    (intRateDiv12 *
      values.loanAmount *
      Math.pow(1 + intRateDiv12, amortMult12)) /
    (Math.pow(1 + intRateDiv12, amortMult12) - 1)
  );
}

function calculateCurrentAmountOwe(values) {
  const intRateDiv100 = values.interestRate / 100;
  const remainingPayments = calculateRemainingPayments(values);
  // console.log(values.loanAmount * Math.pow((1 + (intRateDiv100 / 12)), ((values.term * 12) - remainingPayments)) - (((intRateDiv100 / 12) * values.loanAmount / (1 - Math.pow((1 + (intRateDiv100 / 12)), (-values.amortization * 12))) / (intRateDiv100 / 12)) * (Math.pow((1 + (intRateDiv100 / 12)), ((values.term * 12) - remainingPayments)) - 1)));
  return (
    values.loanAmount *
      Math.pow(1 + intRateDiv100 / 12, values.term * 12 - remainingPayments) -
    (((intRateDiv100 / 12) * values.loanAmount) /
      (1 - Math.pow(1 + intRateDiv100 / 12, -values.amortization * 12)) /
      (intRateDiv100 / 12)) *
      (Math.pow(1 + intRateDiv100 / 12, values.term * 12 - remainingPayments) -
        1)
  );
}

function calculateAmountOwingEndAmortization(values) {
  const intRateDiv100 = values.interestRate / 100;
  // console.log(values.loanAmount*Math.pow((1+((values.interestRate/100)/12)),(values.term*12))-((((values.interestRate/100)/12)*values.loanAmount/(1-Math.pow((1+((values.interestRate/100)/12)),(-values.amortization*12)))/((values.interestRate/100)/12))*(Math.pow((1+((values.interestRate/100)/12)),(values.term*12))-1)));
  return (
    values.loanAmount * Math.pow(1 + intRateDiv100 / 12, values.term * 12) -
    (((intRateDiv100 / 12) * values.loanAmount) /
      (1 - Math.pow(1 + intRateDiv100 / 12, -values.amortization * 12)) /
      (intRateDiv100 / 12)) *
      (Math.pow(1 + intRateDiv100 / 12, values.term * 12) - 1)
  );
}

function calculateInvestmentAmount(values) {
  const interpolatedRate = values.interpolatedRate / 100;
  const monthlyPayment = calculateMonthlyPayment(values);
  const amountOwingEndAmort = calculateAmountOwingEndAmortization(values);
  const remainingPayments = calculateRemainingPayments(values);
  // console.log('interest ' + (amountOwingEndAmort/(1/Math.pow((1+interpolatedRate/12),-(remainingPayments)))+(monthlyPayment*((1-Math.pow((1+interpolatedRate/12),(-remainingPayments)))/(interpolatedRate/12)))));
  return (
    amountOwingEndAmort /
      (1 / Math.pow(1 + interpolatedRate / 12, -remainingPayments)) +
    monthlyPayment *
      ((1 - Math.pow(1 + interpolatedRate / 12, -remainingPayments)) /
        (interpolatedRate / 12))
  );
}

function calculatePrePaymentPenalty(values) {
  const investment = calculateInvestmentAmount(values);
  const currentAmountOwing = calculateCurrentAmountOwe(values);
  values.prePaymentPenalty = investment - currentAmountOwing;
}
