/* These are the special buttons */

import React from "react";
import Button from "./Button";

export default class ButtonsExtensions extends React.Component {
  render() {
    // Create a variable
    var _clickHandler = this.props._clickHandler;
    // We need to reference this inside our .map
    // Other option is to render = () => {}, but this will make cause scope creep
    // so we'll not force it this way.

    return (
      <section className="buttons-wrapper specials">
        {this.props.funcs.map(function (b) {
          return (
            <Button
              key={b}
              text={b}
              className="special"
              _clickHandler={_clickHandler}
            />
          );
        })}
      </section>
    );
  }
}

// We know which numbers we're going ot need, so just create a simple array.
ButtonsExtensions.defaultProps = {
  funcs: ["AC", "+/-", "%"],
};
