import React, { useState, useEffect, useCallback, Fragment } from "react";
import clsx from "clsx";
import {
  Input,
  InputAdornment,
  Snackbar,
  Button,
  IconButton,
} from "@material-ui/core";
import { DeleteOutlineIcon, CloseIcon } from "../../../themes";
import { useStyles } from "./editableItemization.styls";
import { parseToNumber, noDecimal, formatDollar } from "../helpers";

export function EditableItemizationText({
  type,
  name,
  nestedKey,
  value,
  handleBlur,
  startAdornment,
  endAdornment,
  handleDelete,
  handleUndoableDelete,
  undoDelete,
  isDeleted,
  display = "dollar",
}) {
  const classes = useStyles();
  const [isEditing, setEditing] = useState(false);
  const [itemValue, setItemValue] = useState(
    formatText({ type, display, value })
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const setIsEditing = useCallback(() => {
    setEditing(true);
  }, []);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      const formatted = formatText({ type, display, value });
      setItemValue(formatted);
    },
    [type, display]
  );

  const onBlur = useCallback(
    (e) => {
      handleBlur(e, nestedKey);
      setEditing(false);
    },
    [handleBlur, nestedKey]
  );

  const onDelete = useCallback(
    (e) => {
      e.preventDefault();
      setSnackbarOpen(true);
      handleUndoableDelete(name);
    },
    [handleUndoableDelete, name]
  );

  useEffect(() => {
    if (
      type === "key" &&
      newFieldNames.some((field) => name?.includes(field))
    ) {
      setIsEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === "value") {
      // Need to make sure values are synced with reux
      setItemValue(value);
    }
  }, [value, type]);

  const handleUndoDelete = useCallback(() => {
    setSnackbarOpen(false);
    undoDelete(name);
  }, [name, undoDelete]);

  const handleCloseSnackbar = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackbarOpen(false);
      handleDelete(name);
    },
    [handleDelete, name]
  );

  return (
    <Fragment>
      <div className={clsx({ [classes.hidden]: isDeleted })}>
        {isEditing ? (
          <div className={classes.inputContainer} onBlur={onBlur}>
            <Input
              autoFocus
              classes={{
                root: clsx(
                  classes.input,
                  { [classes.valuesInput]: type === "value" },
                  { [classes.keyInput]: type === "key" }
                ),
                input: clsx(classes.paddingLeft, classes.capitalize),
              }}
              name={name}
              value={itemValue}
              onChange={handleChange}
              disableUnderline
              inputProps={{
                inputMode:
                  type === "value"
                    ? display === "decimal"
                      ? "decimal"
                      : "numeric"
                    : undefined,
                pattern: type === "value" ? "[0-9]*" : undefined,
              }}
              startAdornment={
                startAdornment ? (
                  <InputAdornment
                    position="start"
                    classes={{
                      root: clsx(
                        { [classes.valuesAdornment]: type === "value" },
                        { [classes.keyInput]: type === "key" }
                      ),
                    }}
                  >
                    {startAdornment}
                  </InputAdornment>
                ) : undefined
              }
              endAdornment={
                endAdornment ? (
                  <InputAdornment
                    position="start"
                    classes={{ root: classes.inputAdornment }}
                  >
                    {endAdornment}
                  </InputAdornment>
                ) : undefined
              }
            />
            {type === "key" && (
              <DeleteOutlineIcon
                className={classes.deleteIcon}
                onMouseDown={onDelete}
              />
            )}
          </div>
        ) : (
          <span
            className={clsx(
              { [classes.valuesKey]: type === "key" },
              { [classes.values]: type === "value" }
            )}
            // we need onClick on the full width for values but not key
            onClick={type === "value" ? setIsEditing : undefined}
          >
            <span
              onClick={type === "key" ? setIsEditing : undefined}
              className={classes.capitalize}
            >
              {type === "value"
                ? display === "percent"
                  ? `${parseToNumber(value || 0)}%`
                  : `$${formatDollar(parseToNumber(value))}`
                : value
                ? (value + "").split("_").join(" ")
                : value}
            </span>
          </span>
        )}
      </div>
      <DeleteSnackbar
        open={snackbarOpen}
        handleClose={handleCloseSnackbar}
        undoDelete={handleUndoDelete}
      />
    </Fragment>
  );
}

function DeleteSnackbar({ open, handleClose, undoDelete }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message="Line item deleted"
      action={
        <Fragment>
          <Button color="secondary" size="small" onClick={undoDelete}>
            UNDO
          </Button>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Fragment>
      }
    />
  );
}

function formatText({ type, display, value }) {
  if (type === "key" && value) {
    return (value + "").split("_").join(" ");
  } else if (type === "value" && display !== "percent") {
    return noDecimal(parseToNumber(value));
  }
  return value;
}

const newFieldNames = ["new_income", "new_cost", "new_expense", "new_vacancy"];
