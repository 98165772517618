import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as yieldMaintenanceActions from "../../../actions/calculatorActions/yieldMaintenanceActions";
import * as centralStateActions from "../../../actions/calculatorActions/centralActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentInput from "../calculatorCommon/PercentInput";
import DollarDisplay from "../calculatorCommon/DollarDisplay";
import NumberInput from "../calculatorCommon/NumberInput";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import DateInput from "../calculatorCommon/DateInput";
import moment from "moment";
import formatValue from "../calculatorCommon/FormatValueMethod";
import formatDisplay from "../calculatorCommon/FormatDisplayMethod";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";

class YieldMaintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prePaymentPenalty: this.props.prePaymentPenalty,
      loanAmount: this.props.loanAmount,
      term: this.props.term,
      amortization: this.props.amortization,
      interestRate: this.props.interestRate,
      closingDate: this.props.closingDate,
      payoffDate: this.props.payoffDate,
      interpolatedRate: this.props.interpolatedRate,
      treasuryRates: this.props.treasuryRates,

      modalOpen: false,
      from: "",
      to: "",
      cc: "",
    };

    this.update = this.update.bind(this);
    this.updateCentralState = this.updateCentralState.bind(this);
    this.openSharingCalculatorModal = this.openSharingCalculatorModal.bind(
      this
    );
  }

  UNSAFE_componentWillMount() {
    this.props.actions.loadTreasuryRates();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.treasuryRates !== this.state.treasuryRates) {
      this.setState({ treasuryRates: nextProps.treasuryRates });
    }
  }

  update(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      this.props.actions.calculateYieldMaintenance(this.state, { name, value });
    });
  }

  updateCentralState(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      this.props.centralStateActions.updateCentralValues(name, value);
      this.props.actions.calculateYieldMaintenance(this.state, { name, value });
    });
  }

  componentDidMount() {
    this.update("loanAmount", this.props.loanAmount);
  }

  openSharingCalculatorModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateCC(value) {
    this.setState({ cc: value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState(
      {
        modalOpen: false,
      },
      function () {
        var input = [
          { input0: formatValue(this.state.loanAmount).toString() },
          { input1: this.state.term.toString() },
          { input2: this.state.amortization.toString() },
          { input3: (this.state.interestRate + "%").toString() },
          { input4: moment(this.state.closingDate).format("l") },
          { input5: moment(this.state.payoffDate).format("l") },
        ];

        var output = [
          { output0: formatDisplay(this.state.prePaymentPenalty).toString() },
        ];

        this.props.sharingCalculationsActions.calculatorSharing(
          this.state.to,
          this.state.from,
          this.state.cc,
          "YieldMaintenance",
          input,
          output
        );
      }
    );
  }

  render() {
    return (
      <div>
        <Row>
          <ShareCalcModal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal.bind(this)}
            onAfterOpen={() => {}}
            onSubmit={this.onSubmit.bind(this)}
            from={this.state.from}
            to={this.state.to}
            cc={this.state.cc}
            updateFrom={this.updateFrom.bind(this)}
            updateTo={this.updateTo.bind(this)}
            updateCC={this.updateCC.bind(this)}
          />
          <Panel className="calculator-card">
            <Form className="calculator-form" inline={true}>
              <h2 className="calculator-title">Yield Maintenance</h2>
              {this.props.errors.noRates && (
                <span className="calculator-error">
                  {this.props.errors.noRates}
                </span>
              )}
              <div className="margin-top"></div>
              <DollarInput
                name="loanAmount"
                value={this.state.loanAmount}
                update={this.updateCentralState}
                label="Original Loan Amount"
              />
              <NumberInput
                name="term"
                value={this.state.term}
                update={this.update}
                label="Original Term"
                errorMessage={this.props.errors.term}
              />
              <br />
              <br />
              <NumberInput
                name="amortization"
                value={this.state.amortization}
                update={this.update}
                label="Original Amortization"
                errorMessage={
                  this.props.errors.amortization ||
                  this.props.errors.amortizationTerm
                }
                delayError={this.props.errors.hasOwnProperty(
                  "amortizationTerm"
                )}
              />
              {/*Error message must be delayed for a second if error is amortizationTerm*/}
              <PercentInput
                name="interestRate"
                value={this.state.interestRate}
                update={this.updateCentralState}
                label="Current Interest Rate"
              />
              <br />
              <br />
              <DateInput
                name="closingDate"
                value={this.state.closingDate}
                update={this.update}
                label="Start Date"
              />
              <DateInput
                name="payoffDate"
                value={this.state.payoffDate}
                update={this.update}
                label="Maturity Date"
                errorMessage={
                  this.props.errors.payoffDate ||
                  this.props.errors.payoffDateClosingDate
                }
              />
            </Form>
            <div id="boxes">
              <p
                className={
                  this.state.loanAmount &&
                  this.state.term &&
                  this.state.amortization &&
                  this.state.interestRate
                    ? "share-calc"
                    : "hidden"
                }
                onClick={this.openSharingCalculatorModal}
              >
                <i className="material-icons">share</i>Share Calculations
              </p>
              <div className="box">
                <p>Pre-Payment Penalty</p>
                <p className="amount">
                  <DollarDisplay
                    name="prePaymentPenalty"
                    value={this.state.prePaymentPenalty}
                  />
                </p>
              </div>
              {this.props.loading && <span>Loading treasury rates...</span>}
            </div>
          </Panel>
        </Row>

        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const valuesState = state.yieldMaintenance;
  const loadingState = state.yieldMaintenanceLoading;
  const treasuryState = state.yieldMaintenanceRates;
  const centralState = state.centralValues;
  return {
    prePaymentPenalty: valuesState.prePaymentPenalty || "",
    loanAmount: centralState.loanAmount || "",
    term: centralState.initialTerm || "",
    amortization: centralState.amortizationYears || "",
    interestRate: centralState.interestRate || "",
    closingDate: valuesState.closingDate || moment().format("YYYY-MM-DD"),
    payoffDate:
      valuesState.payoffDate ||
      moment(new Date().setFullYear(new Date().getFullYear() + 1)).format(
        "YYYY-MM-DD"
      ),
    interpolatedRate: valuesState.interpolatedRate || "",
    errors: valuesState.errors || {},
    loading: loadingState.loading,
    treasuryRates: treasuryState,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(yieldMaintenanceActions, dispatch),
    centralStateActions: bindActionCreators(centralStateActions, dispatch),
    sharingCalculationsActions: bindActionCreators(
      sharingCalculationsActions,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(YieldMaintenance);
