import React from "react";
import { Link, NavLink } from "react-router-dom";
import Row from "muicss/lib/react/row";
import getParameterByName from "../common/getParameterByName";

export default class AllLoansDealDetailsContainer extends React.Component {
  render() {
    let in_nyc = getParameterByName("in_nyc");
    let violations = getParameterByName("violations");
    let additionalProperties = getParameterByName("additional_properties");
    return (
      <div>
        <Row className="sub-header">
          <Link
            className="back-arrow"
            to={getParameterByName("previous_page") || `/loans/all-loans`}
          >
            <img src="/images/icons/arrow-left.png" alt="arrow-left" />
          </Link>
          <ul className="deal-details-nav">
            {additionalProperties > 0 ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/all-loans-dd/property-details`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Property Details
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {in_nyc === "true" && violations !== null ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/all-loans-dd/violations`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Violations
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
        </Row>
        <div className="deal-padding-top">{this.props.children}</div>
      </div>
    );
  }
}
