import React from "react";
// import { useDispatch } from "react-redux";
import { useStyles } from "./ErrorPage.style";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
// import { clearEcalc } from "../../actions/eCalcForms";
import { CloseIcon } from "../../themes";

export default function ClearConfirmationModal({ handleClose, confirm }) {
  const classes = useStyles();
  //   const dispatch = useDispatch();

  //   function confirm(e) {
  //     dispatch(clearEcalc());
  //     handleClose(e);
  //   }

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="xs">
      <DialogTitle
        className={classes.innerDialogTitle}
        disableTypography={true}
      >
        <span className={classes.errorDialogTitle}>Clear All Data</span>
        <CloseIcon
          onClick={() => handleClose}
          className={classes.innerDialogCloseBtn}
        />
      </DialogTitle>
      <DialogContent>
        All saved data on the calculators will be lost. Are you sure you would
        like to continue?
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelLinkBtn} onClick={handleClose}>
          Cancel
        </Button>
        <Button className={classes.saveLinkBtn} onClick={() => confirm()}>
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
}
