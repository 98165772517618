import React from "react";
import Modal from "react-modal";

const Loader = () => {
  return (
    <Modal
      className="loading-modal"
      ariaHideApp={false}
      contentLabel="Modal"
      isOpen={true}
      shouldCloseOnOverlayClick={false}
    >
      <div className="centered-spinner">
        <img
          src="/images/icons/spin.svg"
          className="loading-spinner"
          alt="loading spinner"
        />
      </div>
    </Modal>
  );
};

export const SiteLoader = ({ style }) => {
  return (
    <div className="site-spinner" style={style}>
      <img src="/images/loading.gif" alt="loading spinner" />
    </div>
  );
};

export default Loader;
