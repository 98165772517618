import * as types from "../actions/actionTypes";

const initialState = {
  fpwDeals: [],
  unconfirmedDeals: {},
  unconfDealsNoMoney: {},
  loading: true,
  noFPWDeals: false,
  contact: {},
  loadingContact: false,
  suggestions: [],
  toast: {
    show: false,
    success: true,
    message: "",
  },
};

export default function fpwDealReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_UPDATE_STAR:
      return Object.assign({}, state, { loading: true });
    case types.LOADING_FPW_DEALS:
      return Object.assign({}, state, { noFPWDeals: false });
    case types.LOAD_FPW_DEALS_SUCCESS:
      return Object.assign({}, state, {
        fpwDeals: action.fpwDeals,
        suggestions: action.fpwDeals,
        loading: false,
      });
    case types.NO_FPW_DEALS:
      return Object.assign({}, state, {
        fpwDeals: [],
        noFPWDeals: true,
        loading: false,
      });
    case types.LOADING_FULL_CONTACT:
      return Object.assign({}, state, { contact: {}, loadingContact: true });
    case types.LOAD_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: action.contact,
        loadingContact: false,
      });
    case types.UPDATE_SEARCH_FPW_DEALS:
      return Object.assign({}, state, { suggestions: action.suggestions });
    case types.FPW_STAR_CHANGE_COMPLETED:
      let suggestionsList = [...state.suggestions];
      suggestionsList.find(
        (i) => i.deal_id === action.deal_id
      ).broker_starred = parseInt(action.broker_starred, 10);
      return Object.assign({}, state, {
        suggestions: suggestionsList,
        loading: false,
      });
    case types.UPDATE_FPW_DEAL_IP_DATE:
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.CLOSE_FPW_DEAL_TOAST:
      return Object.assign({}, state, {
        toast: { show: false, success: true, message: "" },
      });
    case types.UPDATE_FPW_RATING_SUCCESS:
      let suggestions = [...state.suggestions];
      suggestions.find(
        (i) => i.deal_id === action.deal_id
      ).deal_rating = parseInt(action.deal_rating, 10);
      return Object.assign({}, state, {
        suggestions,
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.DEAL_REVIEWED_COMPLETED:
      let dealReviewedIndex = state.suggestions.findIndex(
        (i) => i.deal_id === action.deal_id
      );
      let updatedFPWDeal = {
        ...state.suggestions[dealReviewedIndex],
        forecast_reviewed: action.forecast_reviewed,
        ip_date_reviewed: action.ip_date,
        notes_reviewed: action.notes,
      };
      return Object.assign({}, state, {
        suggestions: [
          ...state.suggestions.slice(0, dealReviewedIndex),
          updatedFPWDeal,
          ...state.suggestions.slice(dealReviewedIndex + 1),
        ],
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.GET_UNCONFIRMED_DEALS_OVERDUE:
      return Object.assign({}, state, {
        unconfirmed_deal_overdue: action.unconfirmed_deal_overdue,
      });
    case types.LOGOUT_SUCCESS:
      // we need to clear the state to prevent the next logged in user from getting the link-lock message
      return Object.assign({}, state, { unconfirmed_deal_overdue: false });
    case types.GET_UNCONFIRMED_DEALS_COUNT:
      return Object.assign({}, state, {
        unconfirmed_deal_count: action.unconfirmed_deal_count,
      });
    case types.GET_UNCONFIRMED_DEALS:
      return Object.assign({}, state, {
        unconfirmedDeals: {
          deals: action.unconfirmedDeals,
          loading: action.loading,
        },
      });
    case types.GET_UNCONF_DEALS_NO_MONEY:
      return Object.assign({}, state, {
        unconfDealsNoMoney: {
          deals: action.unconfDealsNoMoney,
          loading: action.loading,
        },
      });
    case types.COMMENT_ON_COMMISSION:
      return Object.assign({}, state, {
        ...{
          toast: {
            show: true,
            success: action.success,
            message: action.message,
          },
        },
        ...updateUnconfirmedStatus(state, action.commissionId, action.dealType),
      });
    case types.CONFIRM_COMMISSION:
      const { commissionId, message, success } = action;
      const { unconfirmedDeals: { deals = [] } = {} } = state;
      return Object.assign({}, state, {
        toast: { show: true, success, message },
        unconfirmedDeals: {
          deals: deals.filter((deal) => deal.commissionid !== commissionId),
        },
      });
    case types.CONFIRM_COMMISSION_NO_MONEY:
      const {
        commissionId: _commissionId,
        message: _message,
        success: _success,
      } = action;
      const { unconfDealsNoMoney: { deals: _deals = [] } = {} } = state;
      return Object.assign({}, state, {
        toast: { show: true, success: _success, message: _message },
        unconfDealsNoMoney: {
          deals: _deals.filter((deal) => deal.commissionid !== _commissionId),
        },
      });
    default:
      return state;
  }
}

function updateUnconfirmedStatus(state, commissionId, dealType) {
  const stateKey =
    dealType === "noMoney" ? "unconfDealsNoMoney" : "unconfirmedDeals";
  const dealsArray = state[stateKey].deals.map((deal) => {
    if (deal.commissionid === commissionId) {
      return {
        ...deal,
        confirmation_status: "pending",
      };
    } else {
      return deal;
    }
  });
  return { [stateKey]: { deals: dealsArray, loading: false } };
}
