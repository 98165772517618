import * as types from "./actionTypes";
import config from "../config";
import { tokenExpired } from "./authenticationActions";
import { ajax } from "../lib";

export function loadMyLoans(id, search, filter, page) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_MY_LOAN_DATA });
    ajax({
      url: `${config.BASE_URL}my-loans`,
      applyAuthHeader: true,
      data: { id, search, filter, page },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else if (response.status === "204") {
          dispatch({
            type: types.NO_MY_LOAN_DATA,
            message: response.message,
          });
        } else if (response.status === "200") {
          dispatch({
            type: types.LOAD_MY_LOAN_DATA,
            myLoans: response.data.loans,
            pages: response.data.pages,
            filter,
          });
        }
      },
    });
  };
}

export function getMyLoansById(id, search, filter) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}my-loans`,
      applyAuthHeader: true,
      data: { id, search, filter },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else if (response.status === "204") {
          dispatch({
            type: types.NO_MY_LOAN_DATA,
            message: response.message,
          });
        } else if (response.status === "200") {
          dispatch({
            type: types.LOAD_MY_LOAN_DATA,
            myLoans: response.data.loans,
            pages: response.data.pages,
            id: id,
            filter: filter,
          });
        }
      },
    });
  };
}

export function loadAllLoans(search, other, page) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_ALL_LOAN_DATA });
    ajax({
      url: `${config.BASE_URL}all-loans`,
      applyAuthHeader: true,
      data: { search, other, page },
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        } else if (response.status === "200") {
          dispatch({
            type: types.LOAD_ALL_LOAN_DATA,
            allLoans: response.data.loans,
            pages: response.data.pages,
            search,
            other,
          });
        } else if (response.status === "204" || "E413") {
          // record is not found
          dispatch({
            type: types.NO_ALL_LOAN_DATA,
            message: response.message,
          });
        }
      },
    });
  };
}

export function updateLoanSearchValue(search) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_LOAN_SEARCH_VALUE, search });
  };
}

export function showNoDataToast(message) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_LOAN_NO_DATA_TOAST,
      message: message,
    });
  };
}

export function closeNoDataToast() {
  return (dispatch) => {
    dispatch({ type: types.CLOSE_LOAN_NO_DATA_TOAST });
  };
}

export function getFullContactInfo(contact_id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_LOAN_CONTACT_DETAILS });
    ajax({
      url: `${config.BASE_URL}contact/${contact_id}`,
      applyAuthHeader: true,
      success: function (response) {
        if (response.status === "E403") {
          dispatch(tokenExpired());
        }
        if (response.status === "200") {
          dispatch({
            type: types.LOAD_LOAN_CONTACT_DETAILS,
            contact: response.data[0].contacts[0],
          });
        } else {
          dispatch({ type: types.NO_LOAN_CONTACT_DETAILS });
        }
      },
    });
  };
}

export function passDeal(quote_id, note) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}missing-quotes/pass-deal`,
      applyAuthHeader: true,
      type: "POST",
      data: { quote_id, note },
      success: function (response) {
        if (response.status === "E408" || response.status === "E107") {
          dispatch({
            type: types.PASS_LOAN_ERROR,
            message: response.message,
          });
        } else if (response.status === "206") {
          dispatch({
            type: types.PASS_LOAN_SUCCESS,
            message: response.message,
            quote_id: quote_id,
          });
        } else if (response.status === "E404") {
          dispatch(tokenExpired());
        }
      },
    });
  };
}

export function closeLoanToast() {
  return { type: types.CLOSE_LOAN_TOAST };
}

export function getBankPresidentEmployee() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}get-employees`,
      applyAuthHeader: true,
      success: function (response) {
        if (response.status === "200") {
          dispatch({
            type: types.GET_BANK_PRESIDENT_EMPLOYEES_SUCCESS,
            data: response.data,
          });
        } else if (response.status === "204") {
          dispatch({
            type: types.GET_BANK_PRESIDENT_EMPLOYEES_FAILED,
            message: response.message,
          });
        } else if (response.status === "E403") {
          dispatch(tokenExpired());
        }
      },
    });
  };
}

export function addBankerToAllLoan(closing_date, claim_id, in_nyc) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}add-banker`,
      applyAuthHeader: true,
      type: "POST",
      data: { closing_date, claim_id, in_nyc },
      success: function (response) {
        if (response.status === "206") {
          dispatch({
            type: types.ADD_BANKER_TO_ALL_LOAN_SUCCESS,
            data: response.data,
            claim_id: claim_id,
          });
        }
        if (response.status === "E409" || response.status === "E403") {
          dispatch({
            type: types.ADD_BANKER_TO_ALL_LOAN_FAILED,
            message: response.message,
          });
        }
      },
    });
  };
}

export function removeBankerFromAllLoan(rb_id, claim_id) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}remove-banker`,
      applyAuthHeader: true,
      type: "POST",
      data: { rb_id },
      success: function (response) {
        if (response.status === "206") {
          dispatch({
            type: types.REMOVE_BANKER_FROM_ALL_LOAN_SUCCESS,
            data: response.data,
            rb_id: rb_id,
            claim_id: claim_id,
          });
        }
        if (response.status === "E409" || response.status === "E403") {
          dispatch({
            type: types.REMOVE_BANKER_FROM_ALL_LOAN_FAILED,
            message: response.message,
          });
        }
      },
    });
  };
}

export function removeBankerFromMyLoans(rb_id, claim_id) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}remove-banker`,
      applyAuthHeader: true,
      type: "POST",
      data: { rb_id },
      success: function (response) {
        if (response.status === "206") {
          dispatch({
            type: types.REMOVE_BANKER_FROM_MY_LOAN_SUCCESS,
            data: response.data,
            message: response.message,
            rb_id: rb_id,
            claim_id: claim_id,
          });
        }
        if (response.status === "E409" || response.status === "E403") {
          dispatch({
            type: types.REMOVE_BANKER_FROM_MY_LOAN_FAILED,
            message: response.message,
          });
        }
      },
    });
  };
}
