import React, { useEffect, useState, useCallback, Fragment } from "react";
import clsx from "clsx";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import _flatMap from "lodash/flatMap";
import _sum from "lodash/sum";
import _debounce from "lodash/debounce";
import { MenuItem, TextField, InputAdornment } from "@material-ui/core";
import { eCalcForms } from "../../../lib";
import { calcBalanceAtMaturity } from "../calculators/MortgageDscr.logic";
import {
  eCalcOnChange,
  setActiveCalculator,
  eCalcOnMultiChange,
  addResults,
  addUserEntryMethod,
  removeResults,
  clearIrrCapitalEvent,
  updateItemization,
} from "../../../actions/eCalcForms";
import {
  ClosingCostCapitalEventItemized,
  syncFields as syncClosingCostItemizationFields,
  getTotalAmounts as getClosingCostItemizationAmounts,
} from "../itemizations/ClosingCostCapitalEventItemized";
import { RatesModal } from "./RatesModal";
import { DisabledInputsMsg } from "./DisabledInputsMsg";
import {
  formatDollar,
  entryMethodNames,
  entryMethods,
  parseToNumber,
} from "../helpers";
import { SignalCellularAltIcon, ListIcon } from "../../../themes";
import { useStyles } from "./IRRCapitalEvent.styles";

export function IRRCapitalEvent({ storeSliceName, persist }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    holdingYears,
    hasCapitalEvent,
    capitalEventType,
    capitalEventYear,
    capitalEventCapRate,
    capitalEventLoanToValue,
    capitalEventDSCR,
    capitalEventInterestRate,
    capitalEventAmortizationYears,
    capitalEventLoanAmount,
    capitalEventClosingCostDollars,
    capitalEventClosingCostPercent,
    seniorLoanAmount,
    seniorInterestRate,
    seniorAmortizationYears,
    seniorIoYears,
    noi,
    CapitalEventClosingCostEntry,
  } = useSelector(
    (state) => getStateSelector(state[storeSliceName]),
    shallowEqual
  );
  const closingCostItemizationValues = useSelector(
    (state) =>
      state[storeSliceName].itemizations?.[
        eCalcForms.CLOSING_COST_CAPITAL_EVENT
      ],
    shallowEqual
  );
  const closingCostUserItemizationEntryMethods = useSelector(
    (state) =>
      state[storeSliceName].userItemizationEntryMethods?.[
        eCalcForms.CLOSING_COST_CAPITAL_EVENT
      ],
    shallowEqual
  );

  const eventYearNoi = noi[capitalEventYear - 1];
  const propertyValue = getPropertyValue(eventYearNoi, capitalEventCapRate);
  const maxLoan = calculateMaxLoan(
    capitalEventLoanToValue,
    capitalEventDSCR,
    propertyValue,
    eventYearNoi,
    capitalEventInterestRate,
    capitalEventAmortizationYears
  );
  const { results: _seniorLoanBalanceAtEvent } = calcBalanceAtMaturity({
    loan_amount_dollar: seniorLoanAmount,
    interest_rate: seniorInterestRate,
    amortization_years: seniorAmortizationYears,
    io_years: seniorIoYears,
    init_loan_term_yrs: capitalEventYear,
  });
  const seniorLoanBalanceAtEvent = _seniorLoanBalanceAtEvent
    ? _seniorLoanBalanceAtEvent
    : "";

  const [showRatesModal, setShowRatesModal] = useState(false);
  const [showClosingCostItemization, setShowClosingCostItemization] = useState(
    false
  );
  const [isClosingCostItemized, setIsClosingCostItemized] = useState(false);
  const [showClosingCostDisabledMsg, setShowClosingCostDisabledMsg] = useState(
    false
  );

  useEffect(() => {
    setIsClosingCostItemized(
      checkIfItemizedDollarPercent(closingCostItemizationValues)
    );
    setShowClosingCostDisabledMsg(false);
  }, [closingCostItemizationValues]);

  useEffect(() => {
    dispatch(
      eCalcOnChange({
        calculator: eCalcForms.IRR_CAPITAL_EVENT,
        name: "capitalEventLoanAmount",
        value: !maxLoan
          ? ""
          : capitalEventType === "refi"
          ? maxLoan
          : maxLoan - seniorLoanBalanceAtEvent,
        persist,
      })
    );
  }, [maxLoan, seniorLoanBalanceAtEvent, capitalEventType, dispatch, persist]);

  useEffect(() => {
    const CCD = capitalEventClosingCostDollars;
    const CCP = capitalEventClosingCostPercent;
    const CCE = CapitalEventClosingCostEntry;

    if (
      capitalEventLoanAmount &&
      (capitalEventClosingCostDollars || capitalEventClosingCostPercent)
    ) {
      let name, value;
      if (isClosingCostItemized) {
        syncClosingCostItemization({
          itemizationValues: closingCostItemizationValues,
          userItemizationEntryMethods: closingCostUserItemizationEntryMethods,
          loanAmount: capitalEventLoanAmount,
          dispatch,
          persist,
        });
      } else {
        if (CCD && (CCE === entryMethods.DOLLAR_AMOUNT || !CCP)) {
          name = "capitalEventClosingCostPercent";
          value = calcPercent(capitalEventClosingCostDollars);
        } else {
          name = "capitalEventClosingCostDollars";
          value = calcDollars(capitalEventClosingCostPercent);
        }

        dispatch(
          eCalcOnChange({
            calculator: eCalcForms.IRR_CAPITAL_EVENT,
            name,
            value,
            persist,
          })
        );
      }
    }
    if (!capitalEventLoanAmount) {
      let nextCCD, nextCCP;
      if (CCD && CCE === entryMethods.DOLLAR_AMOUNT) {
        nextCCD = capitalEventClosingCostDollars;
        nextCCP = "";
      } else {
        nextCCD = "";
        nextCCP = capitalEventClosingCostPercent;
      }
      dispatch(
        eCalcOnMultiChange({
          calculator: eCalcForms.IRR_CAPITAL_EVENT,
          valuesObj: {
            capitalEventClosingCostDollars: nextCCD,
            capitalEventClosingCostPercent: nextCCP,
          },
          persist,
        })
      );
    }

    if (!capitalEventLoanAmount || !capitalEventLoanAmount < 1) {
      dispatch(
        removeResults({
          name: "irr_capital_event_loan",
          persist,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capitalEventLoanAmount, isClosingCostItemized, persist]);

  useEffect(() => {
    if (capitalEventLoanAmount && capitalEventLoanAmount > 0) {
      const CET = capitalEventType;
      const label =
        CET === "refi"
          ? "Net Proceeds/(Shortfall) from Refi"
          : "Net Supplemental Loan";
      let netLoan = capitalEventLoanAmount - capitalEventClosingCostDollars;
      if (CET === "refi") netLoan = netLoan - seniorLoanBalanceAtEvent;
      dispatch(
        addResults({
          name: "irr_capital_event_loan",
          value: {
            label,
            results: netLoan,
            displayFormat: "dollar",
          },
          persist,
        })
      );
      dispatch(
        eCalcOnChange({
          calculator: eCalcForms.IRR_CAPITAL_EVENT,
          name: "capitalEventNetLoan",
          value: netLoan,
          persist,
        })
      );
    }
  }, [
    capitalEventLoanAmount,
    capitalEventClosingCostDollars,
    seniorLoanBalanceAtEvent,
    capitalEventType,
    dispatch,
    persist,
  ]);

  useEffect(() => {
    if (+holdingYears === 1) {
      dispatch(clearIrrCapitalEvent(persist));
    } else if (capitalEventYear && holdingYears <= capitalEventYear) {
      dispatch(
        eCalcOnChange({
          calculator: eCalcForms.IRR_CAPITAL_EVENT,
          name: "capitalEventYear",
          value: "",
          persist,
        })
      );
    }
  }, [capitalEventYear, holdingYears, dispatch, persist]);

  const openRatesModal = useCallback(() => {
    setShowRatesModal(true);
  }, []);

  const closeRatesModal = useCallback(() => {
    setShowRatesModal(false);
  }, []);

  function toggleEventType(type) {
    dispatch(
      eCalcOnChange({
        calculator: eCalcForms.IRR_CAPITAL_EVENT,
        name: "capitalEventType",
        value: type,
        persist,
      })
    );
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    dispatch(
      eCalcOnChange({
        calculator: eCalcForms.IRR_CAPITAL_EVENT,
        name,
        value: formatInput(name, value),
        persist,
      })
    );
    // need to know which calculator the user is entering for mobile slider
    dispatch(setActiveCalculator({ name: "irr", persist }));
  }

  function handleClosingCostChange(e) {
    const { name, value: _value } = e.target;
    const value = formatInput(name, _value);
    const isDollars = name.includes("Dollar");
    let valuesObj = {
      capitalEventClosingCostDollars: "",
      capitalEventClosingCostPercent: "",
    };
    if (value) {
      valuesObj = {
        capitalEventClosingCostDollars: isDollars
          ? value
          : capitalEventLoanAmount
          ? calcDollars(value)
          : "",
        capitalEventClosingCostPercent: !isDollars
          ? value
          : capitalEventLoanAmount
          ? calcPercent(value)
          : "",
      };
    }

    dispatch(
      eCalcOnMultiChange({
        calculator: eCalcForms.IRR_CAPITAL_EVENT,
        valuesObj,
        persist,
      })
    );
    dispatch(
      addUserEntryMethod({
        name: entryMethodNames.CAPITAL_EVENT_CLOSING_COST,
        value: isDollars
          ? entryMethods.DOLLAR_AMOUNT
          : entryMethods.PERCENT_AMOUNT,
        persist,
      })
    );
    // need to know which calculator the user is entering for mobile slider
    dispatch(setActiveCalculator({ name: "irr", persist }));
  }

  function calcPercent(dollars) {
    const val = `${((+dollars * 100) / +capitalEventLoanAmount).toFixed(3)}`;
    return val > 0 ? val : "0";
  }

  function calcDollars(percent) {
    const val = `${((+percent / 100) * +capitalEventLoanAmount).toFixed(2)}`;
    return val > 0 ? val : "0";
  }

  const openClosingCostItemization = useCallback((e) => {
    e.stopPropagation();
    setShowClosingCostItemization(true);
  }, []);

  const closeClosingCostItemization = useCallback(() => {
    setShowClosingCostItemization(false);
  }, []);

  return (
    <Fragment>
      {hasCapitalEvent && (
        <Fragment>
          <div className={classes.typeToggle}>
            <div
              onClick={() => toggleEventType("supplemental")}
              className={clsx(
                classes.toggleSection,
                classes.leftToggle,
                capitalEventType === "refi"
                  ? classes.toggleInactive
                  : classes.toggleActive
              )}
            >
              Supplemental
            </div>
            <div
              onClick={() => toggleEventType("refi")}
              className={clsx(
                classes.toggleSection,
                classes.rightToggle,
                capitalEventType === "refi"
                  ? classes.toggleActive
                  : classes.toggleInactive
              )}
            >
              Refi
            </div>
          </div>

          <TextField
            select
            className={clsx(classes.margin, classes.fullWidthInput)}
            style={{ width: "100%" }}
            label={`${
              capitalEventType === "refi" ? "Refi" : "Supplemental"
            } at end of Year:`}
            variant="outlined"
            value={capitalEventYear}
            onChange={handleInputChange}
            name="capitalEventYear"
            fullWidth
            InputProps={{
              classes: {
                input: clsx(
                  classes.inputOverride,
                  classes.mobileInputWithPadding
                ),
              },
            }}
            InputLabelProps={{
              classes: {
                root: clsx(
                  classes.inputLabel,
                  classes.extraLongLabel,
                  classes.fullWidthLabel
                ),
                shrink: "fullInputShrink",
              },
              shrink: true,
            }}
          >
            {[...Array(holdingYears - 1)].map((_, i) => (
              <MenuItem key={i} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            inputProps={{ inputMode: "decimal" }}
            className={clsx(classes.margin, classes.fullWidthInput)}
            style={{ width: "100%" }}
            label="Cap Rate Used for Value at Capital Event:"
            variant="outlined"
            value={capitalEventCapRate}
            onChange={handleInputChange}
            name="capitalEventCapRate"
            InputProps={{
              classes: { input: classes.mobileInputWithPadding },
              endAdornment: (
                <InputAdornment
                  position="end"
                  classes={{ root: classes.inputAdornment }}
                >
                  %
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: clsx(
                  classes.inputLabel,
                  classes.oneLinerLongLabel,
                  classes.fullWidthLabel
                ),
                shrink: "fullInputShrink",
              },
              shrink: true,
            }}
          />

          <div className={classes.doubleInputTitle}>
            The combined loan will be the lower of -
          </div>
          <div
            className={clsx(
              classes.lineInputsContainer,
              classes.doubleInputContainer
            )}
          >
            <TextField
              inputProps={{ inputMode: "decimal" }}
              className={clsx(classes.margin, classes.smallInput)}
              label="Loan to Value:"
              variant="outlined"
              value={capitalEventLoanToValue}
              onChange={handleInputChange}
              name="capitalEventLoanToValue"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    classes={{ root: classes.inputAdornment }}
                  >
                    %
                  </InputAdornment>
                ),
                classes: { input: classes.mobileInputWithPadding },
              }}
              InputLabelProps={{
                classes: { root: classes.inputLabel, shrink: "shrink" },
                shrink: true,
              }}
            />
            <span className={classes.inputsDivider} style={{ height: 0 }} />
            <TextField
              inputProps={{ inputMode: "decimal" }}
              className={clsx(classes.margin, classes.smallInput)}
              label="DSCR:"
              variant="outlined"
              value={capitalEventDSCR}
              onChange={handleInputChange}
              name="capitalEventDSCR"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    classes={{ root: classes.inputAdornment }}
                  >
                    %
                  </InputAdornment>
                ),
                classes: { input: classes.mobileInputWithPadding },
              }}
              InputLabelProps={{
                classes: { root: classes.inputLabel, shrink: "shrink" },
                shrink: true,
              }}
            />
          </div>

          <TextField
            inputProps={{ inputMode: "decimal" }}
            className={clsx(classes.margin, classes.fullWidthInput)}
            style={{ width: "100%" }}
            label={
              <span style={{ display: "flex" }}>
                Interest Rate until end of Holding Period:
                <SignalCellularAltIcon
                  className={classes.rateIcon}
                  onClick={openRatesModal}
                />
              </span>
            }
            variant="outlined"
            value={capitalEventInterestRate}
            onChange={handleInputChange}
            name="capitalEventInterestRate"
            InputProps={{
              classes: { input: classes.mobileInputWithPadding },
              endAdornment: (
                <InputAdornment
                  position="end"
                  classes={{ root: classes.inputAdornment }}
                >
                  %
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: clsx(
                  classes.inputLabel,
                  classes.oneLinerLongLabel,
                  classes.fullWidthLabel
                ),
                shrink: "fullInputShrink",
              },
              shrink: true,
            }}
          />

          <TextField
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            className={clsx(classes.margin, classes.fullWidthInput)}
            label="Amortization in Years:"
            variant="outlined"
            value={capitalEventAmortizationYears}
            onChange={handleInputChange}
            name="capitalEventAmortizationYears"
            fullWidth
            InputProps={{
              classes: { input: classes.mobileInputWithPadding },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: clsx(classes.inputLabel, classes.fullWidthLabel),
                shrink: "fullInputShrink",
              },
            }}
          />

          <TextField
            className={clsx(classes.margin, classes.fullWidthInput)}
            label="Max Loan:"
            variant="outlined"
            value={formatDollar(maxLoan)}
            onChange={() => {}}
            name="maxLoan"
            fullWidth
            disabled
            InputProps={{
              classes: { input: classes.mobileInput },
              startAdornment: (
                <InputAdornment
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                >
                  $
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: clsx(classes.inputLabel, classes.fullWidthLabel),
                shrink: "fullInputShrink",
              },
            }}
          />

          <TextField
            className={clsx(classes.margin, classes.fullWidthInput)}
            label="Balance of Senior Loan at time of Capital Event:"
            variant="outlined"
            value={formatDollar(seniorLoanBalanceAtEvent)}
            onChange={() => {}}
            name="seniorLoanBalanceAtEvent"
            fullWidth
            disabled
            InputProps={{
              classes: { input: classes.mobileInput },
              startAdornment: (
                <InputAdornment
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                >
                  $
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: clsx(
                  classes.inputLabel,
                  classes.oneLinerLongLabel,
                  classes.fullWidthLabel
                ),
                shrink: "fullInputShrink",
              },
            }}
          />

          <TextField
            className={clsx(classes.margin, classes.fullWidthInput)}
            label={`${
              capitalEventType === "refi" ? "Refi" : "Supplemental"
            } Loan:`}
            variant="outlined"
            value={
              !capitalEventLoanAmount || capitalEventLoanAmount > 0
                ? formatDollar(capitalEventLoanAmount)
                : 0
            }
            onChange={() => {}}
            name="capitalEventLoanAmount"
            fullWidth
            disabled
            helperText={
              capitalEventLoanAmount && capitalEventLoanAmount < 1
                ? "The Maximum Loan is lower than the Senior Loan Balance"
                : ""
            }
            InputProps={{
              classes: { input: classes.mobileInput },
              startAdornment: (
                <InputAdornment
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                >
                  $
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: clsx(classes.inputLabel, classes.fullWidthLabel),
                shrink: "fullInputShrink",
              },
            }}
            FormHelperTextProps={{
              classes: { root: classes.helperTextWarning },
            }}
          />
          {showClosingCostDisabledMsg && (
            <DisabledInputsMsg field={"Closing Cost"} />
          )}
          <div className={classes.lineInputsContainer}>
            <TextField
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              className={clsx(classes.margin, classes.smallInput)}
              label="Closing Costs for Capital Event as $:"
              variant="outlined"
              value={numberWithCommas(capitalEventClosingCostDollars)}
              disabled={isClosingCostItemized}
              onClick={
                isClosingCostItemized
                  ? () => setShowClosingCostDisabledMsg(true)
                  : undefined
              }
              onChange={handleClosingCostChange}
              name="capitalEventClosingCostDollars"
              InputProps={{
                classes: {
                  input: classes.mobileInput,
                  root: classes.multilineLabelInput,
                },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    classes={{ root: classes.inputAdornment }}
                  >
                    $
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <ListIcon
                      className={classes.listIcon}
                      onClick={openClosingCostItemization}
                    />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                classes: {
                  root: clsx(
                    classes.inputLabel,
                    classes.closingCostsInputLabel
                  ),
                  shrink: "shrink",
                },
              }}
            />
            <span
              className={clsx(
                classes.inputsDivider,
                classes.multilineLabelInputsDivider
              )}
            />
            <TextField
              inputProps={{ inputMode: "decimal" }}
              className={clsx(classes.margin, classes.smallInput)}
              label="Closing Costs for Capital Event as %:"
              variant="outlined"
              value={capitalEventClosingCostPercent}
              onChange={handleClosingCostChange}
              name="capitalEventClosingCostPercent"
              disabled={isClosingCostItemized}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    classes={{ root: classes.inputAdornment }}
                  >
                    %
                  </InputAdornment>
                ),
                classes: {
                  input: classes.mobileInputWithPadding,
                  root: classes.multilineLabelInput,
                },
              }}
              InputLabelProps={{
                classes: {
                  root: clsx(
                    classes.inputLabel,
                    classes.closingCostsInputLabel
                  ),
                  shrink: "shrink",
                },
                shrink: true,
              }}
            />
          </div>

          <TextField
            className={clsx(classes.margin, classes.fullWidthInput)}
            label="Property Value at Capital Event:"
            variant="outlined"
            value={formatDollar(propertyValue)}
            onChange={() => {}}
            name="propertyValue"
            fullWidth
            disabled
            InputProps={{
              classes: { input: classes.mobileInput },
              startAdornment: (
                <InputAdornment
                  position="start"
                  classes={{ root: classes.inputAdornment }}
                >
                  $
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              classes: {
                root: clsx(
                  classes.inputLabel,
                  classes.oneLinerLongLabel,
                  classes.fullWidthLabel
                ),
                shrink: "fullInputShrink",
              },
            }}
          />
          <RatesModal
            open={showRatesModal}
            handleOpen={openRatesModal}
            handleClose={closeRatesModal}
          />
        </Fragment>
      )}
      {showClosingCostItemization && (
        <ClosingCostCapitalEventItemized
          open={showClosingCostItemization}
          handleClose={closeClosingCostItemization}
          storeSliceName={storeSliceName}
          persist={persist}
        />
      )}
    </Fragment>
  );
}

function getStateSelector(state) {
  const {
    irr: { holding_period_yrs: holdingYears },
    irrCapitalEvent,
    irrSchedule: { noi = [] },
    equityNeeded: { loan_amount_dollar: seniorLoanAmount },
    mortgageDscr: {
      interest_rate: seniorInterestRate,
      amortization_years: seniorAmortizationYears,
      io_years: seniorIoYears,
    },
    userEntryMethods: { capitalEventClosingCost: CapitalEventClosingCostEntry },
  } = state;
  return {
    ...irrCapitalEvent,
    noi,
    seniorLoanAmount,
    seniorInterestRate,
    seniorAmortizationYears,
    seniorIoYears,
    holdingYears,
    CapitalEventClosingCostEntry: CapitalEventClosingCostEntry,
  };
}

function formatInput(name, _value) {
  const value = `${_value}`.replace(/[^0-9.]/g, "");
  const validPercent = new RegExp("^\\d*(\\.\\d{0,3})?$");
  const validMoney = new RegExp("^\\d*(\\.\\d{0,2})?$");
  const integersOnly = /^\d+$/;
  switch (name) {
    case "capitalEventCapRate":
    case "capitalEventLoanToValue":
    case "capitalEventDSCR":
    case "capitalEventInterestRate":
    case "capitalEventClosingCostPercent":
      return !value.match(validPercent) ? value.slice(0, -1) : value;
    case "capitalEventAmortizationYears":
      return !value.match(integersOnly) ? value.slice(0, -1) : value;
    case "capitalEventClosingCostDollars":
      return !value.match(validMoney) ? value.slice(0, -1) : value;
    default:
      return value;
  }
}

function calculateMaxLoan(
  LTV,
  DSCR,
  propertyValue,
  noi,
  interestRate,
  amortizationYears
) {
  const lLtvMaxLoan = getLtvMaxLoan(LTV, propertyValue);
  const dscrMaxLoan = getDscrMaxLoan(
    DSCR,
    interestRate,
    noi,
    amortizationYears
  );

  if (lLtvMaxLoan && !dscrMaxLoan) return lLtvMaxLoan;
  if (dscrMaxLoan && !lLtvMaxLoan) return dscrMaxLoan;
  if (lLtvMaxLoan && dscrMaxLoan) return Math.min(lLtvMaxLoan, dscrMaxLoan);
  return "";
}

function getLtvMaxLoan(LTV, propertyVal) {
  if (!LTV || !propertyVal) return "";
  const ltvDec = +LTV / 100;
  return +propertyVal * ltvDec;
}

function getDscrMaxLoan(DSCR, interestRate, noi, amortizationYears) {
  if (!DSCR || !interestRate || !noi || !amortizationYears) return "";

  const amortizationMonths = +amortizationYears * 12;
  const interestRateDec = +interestRate / 100;
  const var1 = +noi / 12 / +DSCR;
  const var2 = Math.pow(1 + interestRateDec / 12, amortizationMonths) - 1;
  const var3 =
    (interestRateDec / 12) *
    Math.pow(1 + interestRateDec / 12, amortizationMonths);
  return var1 * (var2 / var3);
}

function getPropertyValue(noi, capeRate) {
  if (!noi || !capeRate) return "";
  return +noi / (+capeRate / 100);
}

function numberWithCommas(num) {
  return `${num}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const syncClosingCostItemization = _debounce(
  ({
    itemizationValues = {},
    userItemizationEntryMethods = {},
    loanAmount,
    dispatch,
    persist,
  }) => {
    const syncedValues = Object.keys(itemizationValues).reduce((acc, cur) => {
      const entryMethod =
        userItemizationEntryMethods[cur] || entryMethods.DOLLAR_AMOUNT;
      const syncedFields = syncClosingCostItemizationFields({
        nestedKey: entryMethod,
        value: itemizationValues[cur][entryMethod],
        canBeSynced: itemizationValues[cur].hasOwnProperty("percent"),
        loanAmount,
      });
      acc[cur] = { ...syncedFields };
      return acc;
    }, {});

    const {
      [entryMethods.DOLLAR_AMOUNT]: dollarAmt,
      [entryMethods.PERCENT_AMOUNT]: percentAmt,
    } = getClosingCostItemizationAmounts({
      itemizationValues: syncedValues,
      loanAmount,
    });
    dispatch(
      eCalcOnMultiChange({
        calculator: eCalcForms.IRR_CAPITAL_EVENT,
        valuesObj: {
          capitalEventClosingCostDollars: dollarAmt,
          capitalEventClosingCostPercent: percentAmt,
        },
        persist,
      })
    );
    dispatch(
      updateItemization({
        name: eCalcForms.CLOSING_COST_CAPITAL_EVENT,
        value: syncedValues,
        persist,
      })
    );
  },
  300
);

function checkIfItemizedDollarPercent(itemizationValues) {
  const sum = _sum(
    _flatMap(itemizationValues, (item) =>
      parseToNumber(item[entryMethods.DOLLAR_AMOUNT] || 0)
    )
  );
  return sum > 0;
}
