import React from "react";
import { useSelector } from "react-redux";
import { useStyles } from "./AmortizationScheduleModal.style";
// import { calcTotalLoanAndBlendedRate } from "./BlendedRateItemization.logic";
import clsx from "clsx";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  SwipeableDrawer,
} from "@material-ui/core";
import { CloseIcon } from "../../../themes";
import { eCalcForms } from "../../../lib";
import { formatDollar } from "../helpers";
import { isMobile } from "react-device-detect";
import mixpanel from "mixpanel-browser";
import debounce from "lodash/debounce";

export function AmortizationScheduleModal(props) {
  const { onClose, onOpen, storeSliceName } = props;
  const amortization_schedule = useSelector(
    (state) =>
      state[storeSliceName][eCalcForms.MORTGAGE_AND_DSCR].amortization_schedule
  );

  return !isMobile ? (
    <DesktopModal
      open={true}
      onClose={onClose}
      amortization_schedule={amortization_schedule}
    />
  ) : (
    <MobileDrawer
      open={true}
      onClose={onClose}
      onOpen={onOpen}
      amortization_schedule={amortization_schedule}
    />
  );
}

function AmortizationContent({ amortization_schedule }) {
  const classes = useStyles();

  const debounceTrack = debounce(() => {
    mixpanel.track("Amortization Modal Scrolled");
  }, 200);

  return (
    <Table onScroll={debounceTrack}>
      <TableHead className={classes.header}>
        <TableRow className={classes.row}>
          <TableCell>Date</TableCell>
          <TableCell className={classes.centerHeader}>Principal</TableCell>
          <TableCell className={classes.centerHeader}>Interest</TableCell>
          <TableCell className={classes.centerHeader}>Balance</TableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.amortizationTblBody}>
        {amortization_schedule &&
          amortization_schedule.map((values, index) => {
            if (!values) return null;
            return (
              <TableRow className={classes.row} key={index}>
                <TableCell>Month {values.month}</TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  $
                  {values.principal === 0
                    ? "0.00"
                    : formatDollar(values.principal)}
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                  $
                  {values.interest === 0
                    ? "0.00"
                    : formatDollar(values.interest)}
                </TableCell>
                <TableCell
                  style={{
                    color: "#10B8C7",
                    textAlign: "right",
                    paddingRight: 0,
                  }}
                >
                  $
                  {values.balance === 0 ? "0.00" : formatDollar(values.balance)}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
}

function DesktopModal({ open, onClose, amortization_schedule }) {
  const classes = useStyles();

  const debounceTrack = debounce(() => {
    mixpanel.track("Amortization Modal Scrolled");
  }, 200);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      // fullScreen
      maxWidth="md"
      PaperProps={{ className: classes.amortizationDialog }}
      onScroll={debounceTrack}
    >
      <DialogTitle
        id="simple-dialog-title"
        className={clsx(classes.dialogTitle, classes.amortizationTitle)}
        disableTypography={true}
      >
        <CloseIcon className={classes.innerDialogCloseBtn} onClick={onClose} />
        <span>Amortization Schedule</span>
      </DialogTitle>
      <DialogContent
        className={clsx(classes.dialogContent, classes.amortizationContent)}
      >
        <AmortizationContent amortization_schedule={amortization_schedule} />
      </DialogContent>
    </Dialog>
  );
}

function MobileDrawer({ open, onOpen, onClose, amortization_schedule }) {
  const classes = useStyles();
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      disableSwipeToOpen
      disableDiscovery
      classes={{ paperAnchorBottom: classes.drawer }}
    >
      <div className={classes.mobileClose} />
      <div className={classes.amortizationTitle}>Amortization Schedule</div>
      <div className={clsx(classes.dialogContent, classes.amortizationContent)}>
        <AmortizationContent amortization_schedule={amortization_schedule} />
      </div>
    </SwipeableDrawer>
  );
}
