import React from "react";
import { Dialog, IconButton } from "@material-ui/core";
import { CloseIcon } from "../../themes";
import { useStyles } from "./underwritingModal.styles";

export default function UnderwritingModal({ open, onClose }) {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialog }}
    >
      <IconButton onClick={onClose} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <div className={classes.layout}>
        <div className={classes.titleContainer}>
          <img
            className={classes.euLogo}
            src="/images/logo-curves.png"
            alt=""
          />
          <span className={classes.title}>Just Launched:</span>
        </div>
        <div className={classes.bodyContainer}>
          <div className={classes.bodyTitle}>Free Underwriting</div>
          <div className={classes.bodySubTitle}>no strings attached</div>
          <div className={classes.bodyMsg}>
            Now offering basic underwriting services for free during these
            challenging times
          </div>
        </div>
        <div className={classes.bottomText}>
          Interested? <br /> Email your OM to{" "}
          <a className={classes.linkText} href="mailto:UW@easternunion.com">
            UW@easternunion.com
          </a>{" "}
          today!
        </div>
      </div>
    </Dialog>
  );
}
