import React, { useState, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import useDeepCompareEffect from "use-deep-compare-effect";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";
import {
  // MoreHoriz,
  // ArrowBackIcon,
  SettingsOutlinedIcon,
} from "../../../themes";
import { formatDollar } from "../helpers";
import { useStyles } from "./MobileResults.styles";
import { eCalcForms, ECALC_TABLET_VIEW } from "../../../lib";
import { irrScheduleChange } from "../../../actions/eCalcForms";
import { calculateIRRSchedule } from "./IRRSchedule.logic";
import { calcBalanceAtMaturity } from "../calculators/MortgageDscr.logic";
import {
  useEcalcResults,
  MaxBankWillLend,
  IRRSchedule,
  AmortizationScheduleModal,
} from "../components";
import { DebtYieldModal } from "../components/DebtYieldModal";
import mixpanel from "mixpanel-browser";

export function MobileResultsTop({
  setShowMobileResultsPage,
  isTabletSlider,
  storeSliceName,
  isDealDetails,
  persist,
  hasToolbar,
}) {
  // "isTabletSlider" is where the desktop starts to show the results on the top.
  // This happens on small screen sizes.
  // "isTablet" is the site breakoint for tablet
  const isTablet = useMediaQuery(ECALC_TABLET_VIEW);
  const dispatch = useDispatch();
  const { activeCalculator, resultsValues } = useEcalcResults(storeSliceName);
  const [showMaxBank, setShowMaxBank] = useState(false);
  const [showIRRScheduleModal, setShowIRRScheduleModal] = useState(false);
  const [showDebtYieldModal, setShowDebtYieldModal] = useState(false);
  const [
    showAmortizationScheduleModal,
    setShowAmortizationScheduleModal,
  ] = useState(false);

  const irrValues = useSelector(
    (state) => state[storeSliceName][eCalcForms.IRR],
    shallowEqual
  );
  const annual_expenses = useSelector(
    (state) => state[storeSliceName][eCalcForms.CAP_RATE].annual_expenses
  );
  const potential_annual_gross_income = useSelector(
    (state) =>
      state[storeSliceName][eCalcForms.CAP_RATE].potential_annual_gross_income
  );
  const vacancy_as_percent = useSelector(
    (state) => state[storeSliceName][eCalcForms.CAP_RATE].vacancy_as_percent
  );
  const equity_needed = useSelector(
    (state) => state[storeSliceName][eCalcForms.CASH_ON_CASH].equity_needed
  );
  const { loan_amount_dollar, total_capitalization } = useSelector(
    (state) => state[storeSliceName][eCalcForms.EQUITY_NEEDED]
  );
  const mortgageDscrValues = useSelector(
    (state) => state[storeSliceName][eCalcForms.MORTGAGE_AND_DSCR],
    shallowEqual
  );
  const mortgage_post_io = useSelector(
    (state) => state[storeSliceName].resultsValues.mortgage_post_io,
    shallowEqual
  );
  const mortgage_during_io = useSelector(
    (state) => state[storeSliceName].resultsValues.mortgage_during_io,
    shallowEqual
  );

  const { interest_rate, io_years, amortization_years } =
    mortgageDscrValues || {};

  const IrrScheduleValues = useSelector(
    (state) => state[storeSliceName].irrSchedule,
    shallowEqual
  );

  const {
    hasCapitalEvent,
    capitalEventType,
    capitalEventYear,
    capitalEventInterestRate,
    capitalEventAmortizationYears,
    capitalEventLoanAmount,
    capitalEventNetLoan,
  } = useSelector(
    (state) => state[storeSliceName].irrCapitalEvent,
    shallowEqual
  );

  const {
    holding_period_yrs,
    projected_gross_income_rate,
    stabilized_vacancy,
    projected_annual_expense,
    projected_cap_rate_at_sale,
    projected_closing_costs_at_sale,
    year_of_stabilization,
  } = irrValues || {};

  const { pgi, stabilized_vacancies, expenses, capitalEventIRR } =
    IrrScheduleValues || {};

  const classes = useStyles({
    isTabletSlider,
    isTablet,
    capitalEventIRR,
    hasToolbar,
    isDealDetails,
  });

  function calculate() {
    const mortgageBalance = calcBalanceAtMaturity({
      loan_amount_dollar,
      interest_rate,
      amortization_years,
      holding_period_yrs,
      io_years,
    });

    const annual_mortgage_post_io =
      mortgage_post_io &&
      mortgage_post_io.results.find((m) => m.name === "Annual").value;
    const annual_mortgage_during_io =
      mortgage_during_io &&
      mortgage_during_io.results.find((m) => m.name === "Annual").value;
    const mortgage_balance_amount = mortgageBalance && mortgageBalance.results;

    const result = calculateIRRSchedule({
      holding_period_yrs,
      projected_gross_income_rate,
      stabilized_vacancy,
      projected_annual_expense,
      projected_cap_rate_at_sale,
      projected_closing_costs_at_sale,
      potential_annual_gross_income,
      vacancy_as_percent,
      annual_expenses,
      io_years,
      amortization_years,
      mortgage_balance_amount,
      annual_mortgage_post_io,
      annual_mortgage_during_io,
      equity_needed,
      pgi,
      stabilized_vacancies,
      expenses,
      year_of_stabilization,
      total_capitalization,
      hasCapitalEvent,
      capitalEventType,
      capitalEventYear,
      capitalEventInterestRate,
      capitalEventAmortizationYears,
      capitalEventLoanAmount,
      capitalEventNetLoan,
    });
    dispatch(irrScheduleChange(result, persist));
  }

  // `resultsValues` are causing the useEffect to render too many times.
  // See https://github.com/kentcdodds/use-deep-compare-effect
  useDeepCompareEffect(() => {
    const scrollToId = getFirstFieldInSection(activeCalculator);
    const element = document.getElementById(scrollToId);
    if (!element) return;
    setTimeout(() => {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }, 1000);
  }, [activeCalculator, resultsValues]);

  const openMaxBank = (e) => {
    e.stopPropagation();
    mixpanel.track("Max frm Dscr Modal Opened");
    setShowMaxBank(true);
  };

  const toggleIRRSchedule = useCallback((e) => {
    e.stopPropagation();
    setShowIRRScheduleModal((irrSchedule) => !irrSchedule);
  }, []);

  const openDebtYield = (e) => {
    e.stopPropagation();
    mixpanel.track("Debt Yield Modal Opened");
    setShowDebtYieldModal(true);
  };

  const openAmortSchedule = (e) => {
    e.stopPropagation();
    mixpanel.track("amortization modal opened");
    setShowAmortizationScheduleModal(true);
  };

  return (
    <div>
      <div
        className={classes.mobileTitleContainer}
        style={{ height: 128 }}
        onClick={(e) => {
          e.stopPropagation();
          setShowMobileResultsPage(true);
        }}
      >
        {/* <div className={classes.ecalcTitle} style={{ margin: 0 }}>
        eCALC
      </div> */}
        {/* <div className={classes.threeDotsContainer}>
        <ArrowBackIcon className={classes.threeDots} />
        <MoreHoriz className={classes.threeDots} />
      </div> */}
        <div className={classes.sliderContainer}>
          {Object.keys(resultsValues).map((key, index) => {
            if (!resultsValues[key].results) return null;
            const {
              label,
              results,
              displayFormat,
              showMaxLoan,
              showAmortScheduleMobile,
              showIRRSchedule,
              showDebtYield,
            } = resultsValues[key];
            return Array.isArray(results) ? (
              <div
                className={clsx(classes.sliderElement, classes[key])}
                key={key}
                id={key}
              >
                {!!showMaxLoan && (
                  <SettingsOutlinedIcon
                    className={classes.resultsSettingsIcon}
                    style={{ top: 20 }}
                    onClick={openMaxBank}
                  />
                )}
                {!!showIRRSchedule && (
                  <SettingsOutlinedIcon
                    className={classes.resultsSettingsIcon}
                    style={{ top: 20 }}
                    onClick={toggleIRRSchedule}
                  />
                )}
                {!!showDebtYield && (
                  <SettingsOutlinedIcon
                    className={classes.resultsSettingsIcon}
                    style={{ top: 20 }}
                    onClick={openDebtYield}
                  />
                )}
                {!!showAmortScheduleMobile && (
                  <SettingsOutlinedIcon
                    className={classes.resultsSettingsIcon}
                    style={{ top: 20 }}
                    onClick={openAmortSchedule}
                  />
                )}
                <div
                  className={clsx(classes.sliderElementMultipleSection, {
                    [classes.sliderElementMultipleCondensed]:
                      results.length > 2,
                  })}
                >
                  <span className={classes.sliderElementMultipleLabel}>
                    {label}
                  </span>
                </div>
                {results.map((result, index) => {
                  const { name, value, displayFormat: resultsDisplayFormat } =
                    result || {};
                  return (
                    <div
                      className={clsx(classes.sliderElementMultipleSection, {
                        [classes.sliderElementMultipleCondensed]:
                          results.length > 2,
                      })}
                      key={index}
                    >
                      <div
                        className={clsx(
                          classes.sliderElementLabel,
                          classes.sliderElementMultipleKey
                        )}
                      >
                        {name}
                      </div>
                      <div
                        className={clsx(
                          classes.sliderElementValue,
                          classes.sliderElementMultipleValue
                        )}
                      >
                        {formatDisplay(value, resultsDisplayFormat)}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                className={clsx(classes.sliderElement, classes[key])}
                key={key}
                id={key}
              >
                {!!showMaxLoan && (
                  <SettingsOutlinedIcon
                    className={classes.resultsSettingsIcon}
                    onClick={openMaxBank}
                  />
                )}
                {!!showIRRSchedule && (
                  <SettingsOutlinedIcon
                    className={classes.resultsSettingsIcon}
                    onClick={toggleIRRSchedule}
                  />
                )}
                {!!showDebtYield && (
                  <SettingsOutlinedIcon
                    className={classes.resultsSettingsIcon}
                    onClick={openDebtYield}
                  />
                )}
                {!!showAmortScheduleMobile && (
                  <SettingsOutlinedIcon
                    className={classes.resultsSettingsIcon}
                    onClick={openAmortSchedule}
                  />
                )}
                <div className={classes.sliderElementLabel}>{label}:</div>
                <div
                  className={classes.sliderElementValue}
                  style={{ width: "100%" }}
                >
                  {formatDisplay(results, displayFormat)}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {showMaxBank && (
        <MaxBankWillLend
          open={showMaxBank}
          handleClose={() => setShowMaxBank(false)}
          storeSliceName={storeSliceName}
          persist={persist}
        />
      )}

      {showIRRScheduleModal && (
        <IRRSchedule
          onClose={toggleIRRSchedule}
          calculate={calculate}
          storeSliceName={storeSliceName}
        />
      )}

      {showDebtYieldModal && (
        <DebtYieldModal
          open={showDebtYieldModal}
          handleClose={() => setShowDebtYieldModal(false)}
          storeSliceName={storeSliceName}
          persist={persist}
        />
      )}
      {showAmortizationScheduleModal && (
        <AmortizationScheduleModal
          open={showAmortizationScheduleModal}
          onClose={(e) => {
            setShowAmortizationScheduleModal(false);
          }}
          onOpen={openAmortSchedule}
          storeSliceName={storeSliceName}
        />
      )}
    </div>
  );
}

function formatDisplay(value, displayFormat) {
  if (
    !displayFormat ||
    !value ||
    value.toString().toLowerCase() === "invalid entry"
  ) {
    return value;
  }
  switch (displayFormat) {
    case "dollar":
      return `$${formatDollar(value)}`;
    case "percent":
      return `${value}%`;
    default:
      return value;
  }
}

function getFirstFieldInSection(activeCalculator) {
  switch (activeCalculator) {
    case "capRate":
      return "cap_rate";
    case "equityNeeded":
      return "equity_needed";
    case "mortgage":
      return "mortgage_post_io";
    case "irr":
      return "irr";
    case "equityWaterfall":
      return "gp_stats";
    default:
      return "";
  }
}
