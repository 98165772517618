export function formatDollar(value) {
  if (value === "0" || value === 0) return 0;
  if (!value) return "";
  const onlyNumbers = parseFloat((value + "").replace(/[^0-9.+-]/g, ""));
  if (!onlyNumbers || isNaN(onlyNumbers)) return "";
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(onlyNumbers);
}

export function formatPercent(value, maximumFractionDigits) {
  if (value === "0" || value === 0) return 0;
  if (!value) return "";
  const onlyNumbers = parseFloat((value + "").replace(/[^0-9.+-]/g, ""));
  if (!onlyNumbers || isNaN(onlyNumbers)) return "";
  const formatted = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: maximumFractionDigits || 2,
    style: "percent",
  }).format(onlyNumbers);
  return (formatted + "").slice(0, -1); //Strips the percent sign
}

export function noDecimal(value) {
  if (value === "0" || value === 0) return 0;
  if (!value) return "";
  const onlyNumbers = parseFloat((value + "").replace(/[^0-9.+-]/g, ""));
  if (!onlyNumbers || isNaN(onlyNumbers)) return "";
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
  }).format(onlyNumbers);
}

export function numberToPercent(value) {
  if (value === "0" || value === 0) return 0;
  if (!value) return "";
  const onlyNumbers = parseFloat((value + "").replace(/[^0-9.+-]/g, ""));
  return onlyNumbers && !isNaN(onlyNumbers) ? onlyNumbers / 100 : "";
}

export function parseToNumber(value) {
  if (value === "0" || value === 0) return 0;
  if (!value) return "";
  const onlyNumbers = (value + "").replace(/[^0-9.+-]/g, "");
  return parseFloat(onlyNumbers);
}

export const entryMethodNames = {
  POTENTIAL_GROSS_INCOME: "potentialGrossIncome",
  VACANCY: "vacancy",
  EXPENSES: "expenses",
  CLOSING_COST: "closingCost",
  CLOSING_COST_ITEMIZED: "closing_costs",
  CLOSING_COST_CAPITAL_EVENT: "closing_costs_capital_event",
  CAPITAL_EVENT_CLOSING_COST: "capitalEventClosingCost",
  LOAN_AMOUNT: "loanAmount",
  LOAN_AMOUNT_OF: "loanAmountOf",
  SPONSOR_CONTRIBUTION: "sponsorContribution",
  INVESTOR_CONTRIBUTION: "investorContribution",
};

export const entryMethods = {
  ANNUAL: "annual",
  MONTHLY: "monthly",
  DOLLAR_AMOUNT: "dollar",
  PERCENT_AMOUNT: "percent",
  PURCHASE_PRICE: "purchasePrice",
  TOTAL_CAPITALIZATION: "totalCapitalization",
};
