import React from "react";
import { connect } from "react-redux";
import * as simpleUnderwritingActions from "../../../actions/calculatorActions/simpleUnderwritingActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentInput from "../calculatorCommon/PercentInput";
import DollarDisplay from "../calculatorCommon/DollarDisplay";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import formatValue from "../calculatorCommon/FormatValueMethod";
import formatDisplay from "../calculatorCommon/FormatDisplayMethod";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";

class SimpleUnderwriting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      annualGrossRent: this.props.annualGrossRent,
      monthlyGrossRent: this.props.monthlyGrossRent,
      vacancyFactorPercent: this.props.vacancyFactorPercent,
      vacancyFactorAmount: this.props.vacancyFactorAmount,
      managementFeesPercent: this.props.managementFeesPercent,
      managementFeesAmount: this.props.managementFeesAmount,
      annualExpenses: this.props.annualExpenses,
      monthlyExpenses: this.props.monthlyExpenses,
      firstYearNOI: this.props.firstYearNOI,
      capRate: this.props.capRate,
      value: this.props.value,

      modalOpen: false,
      from: "",
      to: "",
      cc: "",
    };

    this.update = this.update.bind(this);
  }

  update(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      this.props.dispatch(
        simpleUnderwritingActions.calculateSimpleUnderwriting(this.state, {
          name,
          value,
        })
      );
    });
  }

  openSharingCalculatorModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateCC(value) {
    this.setState({ cc: value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ modalOpen: false }, function () {
      var input = [
        { input0: formatValue(this.state.annualGrossRent).toString() },
        { input1: formatValue(this.state.monthlyGrossRent).toString() },
        { input2: (this.state.vacancyFactorPercent + "%").toString() },
        { input3: formatValue(this.state.vacancyFactorAmount).toString() },
        { input4: (this.state.managementFeesPercent + "%").toString() },
        { input5: formatValue(this.state.managementFeesAmount).toString() },
        { input6: formatValue(this.state.annualExpenses).toString() },
        { input7: formatValue(this.state.monthlyExpenses).toString() },
        { input8: formatDisplay(this.state.firstYearNOI).toString() },
        { input9: (this.state.capRate + "%").toString() },
      ];

      var output = [{ output0: formatDisplay(this.state.value).toString() }];

      this.props.dispatch(
        sharingCalculationsActions.calculatorSharing(
          this.state.to,
          this.state.from,
          this.state.cc,
          "SimpleUnderwriting",
          input,
          output
        )
      );
    });
  }

  render() {
    return (
      <div>
        <Row>
          <ShareCalcModal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal.bind(this)}
            onAfterOpen={() => {}}
            onSubmit={this.onSubmit.bind(this)}
            from={this.state.from}
            to={this.state.to}
            cc={this.state.cc}
            updateFrom={this.updateFrom.bind(this)}
            updateTo={this.updateTo.bind(this)}
            updateCC={this.updateCC.bind(this)}
          />
          <Panel className="calculator-card">
            <Form className="calculator-form" inline={true}>
              <h2 className="calculator-title">Value Via Income Approach</h2>
              <div className="margin-top"></div>
              <DollarInput
                name="annualGrossRent"
                value={this.state.annualGrossRent}
                update={this.update}
                label="Annual Gross Rent"
              />
              <DollarInput
                name="monthlyGrossRent"
                value={this.state.monthlyGrossRent}
                update={this.update}
                label="Monthly Gross Rent"
              />
              <br />
              <br />
              <PercentInput
                name="vacancyFactorPercent"
                value={this.state.vacancyFactorPercent}
                update={this.update}
                label="Vacancy Factor %"
              />
              <DollarInput
                name="vacancyFactorAmount"
                value={this.state.vacancyFactorAmount}
                update={this.update}
                label="Vacancy Factor $"
              />
              <br />
              <br />
              <PercentInput
                name="managementFeesPercent"
                value={this.state.managementFeesPercent}
                update={this.update}
                label="Management Fees %"
              />
              <DollarInput
                name="managementFeesAmount"
                value={this.state.managementFeesAmount}
                update={this.update}
                label="Management Fees $"
              />
              <br />
              <br />
              <DollarInput
                name="annualExpenses"
                value={this.state.annualExpenses}
                update={this.update}
                label="Annual Remaining Expenses"
              />
              <DollarInput
                name="monthlyExpenses"
                value={this.state.monthlyExpenses}
                update={this.update}
                label="Monthly Remaining Expenses"
              />
              <br />
              <br />
              <div className="mui-textfield calculator-display">
                <label className="display">First Year NOI: </label>
                <DollarDisplay
                  name="firstYearNOI"
                  value={this.state.firstYearNOI}
                  label="First Year NOI"
                />
              </div>
              <PercentInput
                name="capRate"
                value={this.state.capRate}
                update={this.update}
                label="Cap Rate"
              />
            </Form>
            <div id="boxes">
              <p
                className={
                  this.state.annualGrossRent &&
                  this.state.monthlyGrossRent &&
                  this.state.vacancyFactorPercent &&
                  this.state.vacancyFactorAmount &&
                  this.state.managementFeesPercent &&
                  this.state.managementFeesAmount &&
                  this.state.annualExpenses &&
                  this.state.monthlyExpenses &&
                  this.state.capRate
                    ? "share-calc"
                    : "hidden"
                }
                onClick={this.openSharingCalculatorModal.bind(this)}
              >
                <i className="material-icons">share</i>Share Calculations
              </p>
              <div className="box">
                <p className="box-title">Value</p>
                <p className="amount">
                  <DollarDisplay name="value" value={this.props.value} />
                </p>
              </div>
            </div>
          </Panel>
        </Row>

        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>{" "}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  state = state.simpleUnderwriting;
  return {
    annualGrossRent: state.annualGrossRent || "",
    monthlyGrossRent: state.monthlyGrossRent || "",
    vacancyFactorPercent: state.vacancyFactorPercent || "",
    vacancyFactorAmount: state.vacancyFactorAmount || "",
    managementFeesPercent: state.managementFeesPercent || "",
    managementFeesAmount: state.managementFeesAmount || "",
    annualExpenses: state.annualExpenses || "",
    monthlyExpenses: state.monthlyExpenses || "",
    firstYearNOI: state.firstYearNOI || "",
    capRate: state.capRate || "",
    value: state.value || "",
  };
}

export default connect(mapStateToProps)(SimpleUnderwriting);
