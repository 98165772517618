import * as types from "./actionTypes";
import config from "../config";
import { tokenExpired } from "./authenticationActions";
import { handleAPIError, isEmpty, ajax } from "../lib";
import encodeQueryData from "../components/common/encodeQueryData";

export function getReportList() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_REPORTS_LIST });
    ajax({
      url: `${config.BASE_URL}report-list`,
      applyAuthHeader: true,
      type: "GET",
      success: function (response) {
        switch (response.status) {
          case "204":
            dispatch({
              type: types.LOAD_REPORT_LIST_FAILED,
              message: response.message,
            });
            break;
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.LOAD_REPORT_LIST_SUCCESS,
              data: response.data,
            });
            break;
        }
      },
    });
  };
}

export function getMembersList() {
  return async (dispatch) => {
    await ajax({
      url: `${config.BASE_URL}reports/mtm/members`,
      applyAuthHeader: true,
      success: function (response) {
        dispatch({ type: types.GET_MEMBERS_LIST, data: response.data || [] });
      },
    }).catch(() => handleAPIError(dispatch, types.GET_MEMBERS_LIST_FAILED));
  };
}

export function getBanksList(bank = null) {
  return async (dispatch) => {
    if(bank){
      dispatch({
        type: types.GET_BANKS_LIST_SUCCESS,
        data: [{bank_id: bank.bank_id, bank_name: bank.bank_name}],
      });
    }else {
      await ajax({
        url: `${config.BASE_URL}lists/banks`,
        applyAuthHeader: true,
        success: function (response) {
          dispatch({
            type: types.GET_BANKS_LIST_SUCCESS,
            data: response.data || [],
          });
        },
      }).catch(() => handleAPIError(dispatch, types.GET_BANKS_LIST_FAILED));
    }
  };
}

export function getAllInOneReport() {
  let url = `${config.BASE_URL}reports/all-in-one?pdf=1`;
  return fetchReport({ url, fileName: "all-in-one-report.pdf" });
}

export function getReportPDF(sr_id, reportName) {
  let url = `${config.BASE_URL}get-report?sr_id=${sr_id}&pdf=1`;
  return fetchReport({ url, fileName: reportName || "custom-report.pdf" });
}

export function getMTMReport(members, combined) {
  let membersArray = [];
  if (members) {
    typeof members === "number" || typeof members === "string"
      ? (membersArray[0] = members)
      : (membersArray = members);
  }

  let queryData = {};
  if (membersArray.length) {
    membersArray.map((m, index) => (queryData[`members[${index}]`] = m));
  }
  if (combined >= 0) {
    queryData["combined"] = combined;
  }
  queryData["pdf"] = 1;
  let url = `${config.BASE_URL}reports/mtm-burn-down${
    !isEmpty(queryData) ? `?${encodeQueryData(queryData)}` : ""
  }`;
  return fetchReport({ url, fileName: "mtm_report.pdf" });
}

export function getFullBlownMTMReport() {
  let url = `${config.BASE_URL}reports/mtm/?pdf=1`;
  return fetchReport({ url, fileName: "mtm_report.pdf" });
}

export function getBankPipelineReport(bank_id) {
  const timestamp = Date.now();
  let url = `${config.BASE_URL}reports/bank-pipeline?bank_id=${bank_id}&pdf=1`;
  return fetchReport({ url, fileName: `bank_report-${timestamp}.pdf` });
}

export function openPDF(dispatch, url, fileName) {
  dispatch({ type: types.LOAD_REPORT_SUCCESS });
  if (window && window.isNativeApp) {
    return window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: window.canShareUrl ? "shareUrl" : "openUrl",
        data: { url, fileName },
      })
    );
  } else {
    return window.open(url);
  }
}

export function closeToast() {
  return { type: types.CLOSE_REPORT_TOAST };
}

function fetchReport({ url, fileName }) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_REPORTS });
    ajax({
      url: `${url}&getSignedUrl=1`,
      applyAuthHeader: true,
    })
      .then((signedResponse) => {
        const signedUrl = signedResponse?.data?.url;
        openPDF(dispatch, signedUrl, fileName);
      })
      .catch((error) => {
        handleError(error);
        handleAPIError(dispatch, types.LOAD_REPORT_FAILED);
      });
  };
}

function handleError(error) {
  const err = error || new Error("Unknown error.");
  const {
    response = {
      status: -500,
      statusText: "Unknown",
      data: { message: "Unknown error." },
    },
  } = err;
  if (response.status === 500) {
    console.log("API GOT 500 Error");
  } else {
    console.log(JSON.stringify(response));
  }
}
