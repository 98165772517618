import React from "react";
import { NavLink } from "react-router-dom";
import Row from "muicss/lib/react/row";
import Col from "muicss/lib/react/col";

class MissingQuotesContainer extends React.Component {
  render() {
    return (
      <div className="mq-page">
        <Row className="mq-sub-header">
          <Col lg="12" md="12" sm="12">
            <ul className="missing-quotes-ul">
              <li className="mq-list-item">
                <NavLink
                  to={`/missingQuotes/quote`}
                  activeClassName="header-active-link"
                >
                  Missing Quotes
                </NavLink>
              </li>
              <li className="mq-list-item">
                <NavLink
                  to={`/missingQuotes/termSheet`}
                  activeClassName="header-active-link"
                >
                  Waiting for Term Sheets
                </NavLink>
              </li>
            </ul>
          </Col>
        </Row>
        <div className="contacts-container">{this.props.children}</div>
      </div>
    );
  }
}

export default MissingQuotesContainer;
