import React from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { stringify } from "query-string";
import {
  // Collapse,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  CalculatorIcon,
  HomeIcon,
  HeadsetIcon,
  UnconfirmDealsIcon,
  DealReviewIcon,
  ServiceProviderIcon,
  DocsIcon,
  MissingQuotesIcon,
  // NewsFeedIcon,
  LendingCriteriaIcon,
  MyFundPortfolioIcon,
  GroupIcon,
  DescriptionIcon,
  DonutLargeIcon,
  AccountBalanceIcon,
  //AccountBalanceWalletIcon,
  BusinessIcon,
  AssignmentIcon,
  //LoopIcon,
  WorkIcon,
  TrendingUpIcon,
  PersonIcon,
  CompsIcon,
  MoneyIcon,
  //CalculateIcon,
  EcalcIcon
} from "../../themes";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: 224,
    },
  },
  drawerPaper: {
    width: 224,
    padding: 24,
    // overflow: "hidden",
    // [theme.breakpoints.down("xs")]: {
    overflowY: "auto",
    paddingRight: 26,
    paddingLeft: 26,
    // hide scroll bar https://stackoverflow.com/a/49278385
    scrollbarWidth: "none" /* Firefox */,
    msOverflowStyle: "none" /* Internet Explorer 10+ */,
    /* WebKit (Chrome) */
    "&::-webkit-scrollbar": {
      display: "none",
    },
    // },
    // "&:hover": {
    //   overflowY: "auto",
    // },
    [theme.breakpoints.up(1700)]: {
      width: 320,
    },
  },
  drawerTitle: {
    fontFamily: "Karla",
    fontSize: 28,
    fontWeight: "bold",
    marginBottom: 24,
  },
  drawerDivider: {
    margin: "0 -24px",
  },
  listItem: {
    cursor: "pointer",
    color: "#898989",
    fontSize: 15,
    fontFamily: "Karla",
    width: 174,
    "& svg": {
      color: "#898989",
      // fontSize: 15,
    },
    "&:hover": {
      color: "#000000",
      "& svg": {
        color: "#000000",
      },
    },
    [theme.breakpoints.up(1700)]: {
      fontSize: 18,
      width: 250,
      "& svg": {
        // fontSize: 18,
      },
    },
  },
  bottomLinkList: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 24,
    marginTop: 24,
    position: "relative",
    top: "auto",
    bottom: 0,
  },
  bottomLinkItems: {
    cursor: "pointer",
    fontFamily: "Karla",
    fontWeight: "normal",
    fontSize: 15,
    textAlign: "center",
    color: "#000000",
    position: "relative",
    width: "50%",
    [theme.breakpoints.up(1700)]: {
      fontSize: 18,
    },
  },
  activeItem: {
    cursor: "pointer",
    color: "#10B8C7 !important",
    "& svg": {
      color: "#10B8C7 !important",
    },
  },
}));

function ListItemLink(props) {
  const classes = useStyles();
  return (
    <ListItem
      component={NavLink}
      {...props}
      className={clsx(props.className, classes.listItem)}
      activeClassName={classes.activeItem}
    />
  );
}

function ResponsiveDrawer(props) {
  const {
    container,
    handleDrawerToggle,
    mobileOpen,
    userData,
    unconfirmed_deal_count,
    openLink,
    logout,
  } = props;
  const classes = useStyles();
  const theme = useTheme();

  // const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  // const [eCalcHovered, setEcalcHovered] = useState(false);

  const drawer = (
    <React.Fragment>
      <div
        onClick={() => handleDrawerToggle()}
      >
        <p className={classes.drawerTitle}>Eastern</p>
        <div
          className="sidebar-menu"
          style={{flex : "1" }}
        >
          <List>
            <ListItemLink
              to="/"
              className={userData ? "" : "hidden"}
              isActive={(location) => window.location.pathname === "/"}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText disableTypography={true} primary="Home" />
            </ListItemLink>
            <ListItemLink
              to="/e-calc/calculator"
              // onMouseOver={() => setEcalcHovered(true)}
              // onMouseLeave={() => setEcalcHovered(false)}
            >
              <ListItemIcon>
                <EcalcIcon/>
                {/* <img
                  src={
                    window.location.pathname.includes("/e-calc/")
                      ? "/images/EU-Logo-Active.svg"
                      : eCalcHovered
                      ? "/images/EU-Logo-Hover.svg"
                      : "/images/EU-Logo.svg"
                  }
                  alt="eCALC"
                  style={{ maxHeight: 24, width: 20 }}
                /> */}
              </ListItemIcon>
              <ListItemText disableTypography={true} primary="eCALC" />
            </ListItemLink>
            <ListItemLink to="/calculators">
              <ListItemIcon>
                <CalculatorIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                primary="Other Calculators"
              />
            </ListItemLink>
            <ListItemLink to="/rate-sheet">
              <ListItemIcon>
                <TrendingUpIcon />
              </ListItemIcon>
              <ListItemText disableTypography={true} primary="Rates" />
            </ListItemLink>
            <ListItemLink to="/eastern-education">
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                disableTypography={true}
                primary="Eastern Education"
              />
            </ListItemLink>
            <ListItem
              onClick={() =>
                openLink(
                  process.env.REACT_APP_MAIN_SITE + "/peakingyourinterest/"
                )
              }
              className={classes.listItem}
            >
              <ListItemIcon>
                <HeadsetIcon />
              </ListItemIcon>
              <ListItemText disableTypography={true} primary="Ira's Podcast" />
            </ListItem>
            {/* <Divider className={classes.drawerDivider} />
          <List className="sidebar-nested-list" component="div" disablePadding>
            <ListItem>
              <ListItemText
                primary="The Eastern Calculator"
                secondary="A full underwriting experience"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="My Saved Underwriting"
                secondary="167 Rubing, 1098 Howell, 65 Kearny St"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Mortgage Calculator"
                secondary="Monthly payments, IO and blended loans"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="New Name"
                secondary="Equity needed, cash on cash & IRR"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Building Value Calculator"
                secondary="Building worth, NOI & cap rate"
              />
            </ListItem>
            <ListItem
              onClick={handleClick}
              style={{ color: "rgba(0, 0, 0, .5)" }}
            >
              <p
                style={{
                  fontFamily: "Karla",
                  fontSize: "16px",
                  marginRight: "8px"
                }}
              >
                More Calculators
              </p>
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <ListItem>
                <ListItemText secondary="Additional Calculator" />
              </ListItem>
            </Collapse>
          </List>
          <ListItemLink to="/rate-sheet">
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary="Rates" />
          </ListItemLink> */}
          </List>
          {userData && <Divider className={classes.drawerDivider} />}
          <List>
            <div>
              <ListItemLink
                className={userData && userData.is_broker ? "" : "hidden"}
                to="/contacts/contacts/today"
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true} primary="Contacts" />
              </ListItemLink>
              <ListItemLink
                className={userData && userData.is_broker ? "" : "hidden"}
                to={{
                  pathname: "/broker-deals/pw",
                  search: stringify({ "accessed-from": "my-deals" }),
                }}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true} primary="My Deals" />
              </ListItemLink>
              <ListItemLink
                className={userData && userData.is_broker ? "" : "hidden"}
                to="/unconfirmed-deals"
              >
                <ListItemIcon>
                  <React.Fragment>
                    <UnconfirmDealsIcon />
                    {!!unconfirmed_deal_count && unconfirmed_deal_count > 0 && (
                      <span className="badge-container">
                        <span className="badge badge-sidebar">
                          <strong>{unconfirmed_deal_count}</strong>
                        </span>
                      </span>
                    )}
                  </React.Fragment>
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="Unconfirmed Deals"
                />
              </ListItemLink>
              <ListItemLink
                className={
                  userData &&
                  userData.is_broker &&
                  (userData.member_role === 2 ||
                    userData.member_role === 3 ||
                    userData.addons.includes(26) ||
                    userData.addons.includes(32))
                    ? ""
                    : "hidden"
                }
                to="/deal-review-filter"
              >
                <ListItemIcon>
                  <DealReviewIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true} primary="Deal Review" />
              </ListItemLink>
              <ListItemLink
                className={userData ? "" : "hidden"}
                to={{
                  pathname: "/properties/deals",
                  search: stringify({ "accessed-from": "my-properties" }),
                }}
              >
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="My Properties"
                />
              </ListItemLink>
              <ListItemLink
                className={true ? "" : "hidden"}
                to="/inProcessPipeline"
              >
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="In Process Pipeline"
                />
              </ListItemLink>
              <ListItemLink
                to="/service-provider"
                className={
                  userData && userData.is_service_provider ? "" : "hidden"
                }
              >
                <ListItemIcon>
                  <ServiceProviderIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="Service Provider"
                />
              </ListItemLink>
              <ListItemLink
                to="/lending-criteria"
                className={userData && (userData.is_lender || userData.is_qts)? "" : "hidden"}
              >
                <ListItemIcon>
                  <LendingCriteriaIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="Lending Criteria"
                />
              </ListItemLink>
              <ListItemLink
                to="/investing-criteria"
                className={userData && userData.is_investor ? "" : "hidden"}
              >
                <ListItemIcon>
                  <MoneyIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="Investing Criteria"
                />
              </ListItemLink>
              <ListItemLink
                to="/fund-portfolio/my-deals"
                className={userData && userData.is_investor ? "" : "hidden"}
              >
                <ListItemIcon>
                  <MyFundPortfolioIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="My Fund Portfolio"
                />
              </ListItemLink>
              <ListItemLink
                to="/loans/my-loans"
                className={userData && userData.is_lender  ? "" : "hidden"}
              >
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="My Bank Portfolio"
                />
              </ListItemLink>
              <ListItemLink
                to="/missingQuotes/quote"
                className={userData && userData.is_lender ? "" : "hidden"}
              >
                <ListItemIcon>
                  <MissingQuotesIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="Missing Quotes"
                />
              </ListItemLink>
              <ListItemLink
                to="/qts-search"
                className={
                  userData && (userData.is_broker || userData.exec_banker)
                    ? ""
                    : "hidden"
                }
              >
                <ListItemIcon>
                  <AccountBalanceIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true} primary="Ask QTS" />
              </ListItemLink>
              <ListItemLink
                to="/docs/setup"
                className={userData && userData.is_broker ? "" : "hidden"}
              >
                <ListItemIcon>
                  <DocsIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true} primary="Docs" />
              </ListItemLink>
              <ListItemLink
                to="/reports"
                className={userData && userData.is_broker ? "" : "hidden"}
              >
                <ListItemIcon>
                  <DonutLargeIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true} primary="Reports" />
              </ListItemLink>
              <ListItemLink
                to="/the-ira-group"
                className={
                  userData && !userData.is_broker && userData.company_id === 2
                    ? ""
                    : "hidden"
                }
              >
                <ListItemIcon>
                  <TrendingUpIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="The Ira Group"
                />
              </ListItemLink>
              {/* <ListItemLink
                to="/accounting/summary"
                className={userData && userData.is_broker ? "" : "hidden"}
              >
                <ListItemIcon>
                  <CalculateIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true} primary="Accounting" />
              </ListItemLink> */}
              <ListItemLink
                to="/missingQuotesBroker"
                className={userData && userData.is_broker ? "" : "hidden"}
              >
                <ListItemIcon>
                  <MissingQuotesIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  primary="Missing Quotes"
                />
              </ListItemLink>
              <ListItemLink to="/comps" className={userData ? "" : "hidden"}>
                <ListItemIcon>
                  <CompsIcon />
                </ListItemIcon>
                <ListItemText disableTypography={true} primary="Comps" />
              </ListItemLink>
              {/*<ListItemLink*/}
                {/*to="/news-feed"*/}
                {/*className={userData ? "" : "hidden"}*/}
              {/*>*/}
                {/*<ListItemIcon>*/}
                  {/*<NewsFeedIcon />*/}
                {/*</ListItemIcon>*/}
                {/*<ListItemText disableTypography={true} primary="News Feed" />*/}
              {/*</ListItemLink>*/}
            </div>
          </List>
        </div>
        <div className={classes.bottomLinkList}>
          {userData ? (
            <NavLink
              to="#"
              className={classes.bottomLinkItems}
              onClick={() => logout()}
            >
              Logout
            </NavLink>
          ) : (
            <NavLink to="/login" className={classes.bottomLinkItems}>
              Login
            </NavLink>
          )}
          {userData && (
            <NavLink to="/profile" className={classes.bottomLinkItems}>
              Account
            </NavLink>
          )}
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <nav className="sidebar">
      <Hidden mdUp>
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

export default ResponsiveDrawer;
