import React from "react";
import "react-date-picker/dist/DatePicker.css";
import FormattedDatePicker from "../../common/FormattedDatePicker";
import ErrorMessage from "./ErrorMessage";

class DateInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      value: this.props.value,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(date) {
    this.setState(
      {
        value: date,
      },
      function () {
        this.props.update(this.state.name, date);
      }
    );
  }

  render() {
    return (
      <div>
        <div className="date-inputs">
          <label className="calendar-label">{this.props.label}</label>
          <FormattedDatePicker
            allowClear={false}
            dateValue={this.state.value}
            update={this.onChange}
          />
        </div>
        {this.props.errorMessage && (
          <ErrorMessage message={this.props.errorMessage} />
        )}
      </div>
    );
  }
}

export default DateInput;
