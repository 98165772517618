import React from "react";
import Modal from "react-modal";
import Form from "muicss/lib/react/form";
import Row from "muicss/lib/react/row";

class ClientModal extends React.Component {
  render() {
    const customStyles = {
      content: {
        margin: "auto",
      },
    };
    let email = null;
    let phone = null;
    let fax = null;
    let bio = null;
    if (this.props.contact && this.props.contact.contact_id) {
      phone =
        this.props.contact.contact_info.phone &&
        this.props.contact.contact_info.phone.map(function (item, index) {
          return (
            <div className="contact-modal-info" key={index}>
              <div className="contact-modal-info-left">
                <i className="material-icons">phone</i>
                <p>
                  <a href={"tel:" + item.value} className="orange-hover">
                    {item.value}
                  </a>
                </p>
              </div>
              <div className="contact-modal-info-right">
                <label className="phone-label">({item.label})</label>
              </div>
            </div>
          );
        });

      fax =
        this.props.contact.contact_info.fax &&
        this.props.contact.contact_info.fax.map(function (item, index) {
          return (
            <div className="contact-modal-info" key={index}>
              <div className="contact-modal-info-left">
                <i className="material-icons">phone</i>
                <p>{item.value}</p>
              </div>
              <div className="contact-modal-info-right">
                <label className="phone-label">({item.label})</label>
              </div>
            </div>
          );
        });

      email =
        this.props.contact.contact_info.email &&
        this.props.contact.contact_info.email.map(function (item, index) {
          return (
            <div className="contact-modal-info" key={index}>
              <div className="contact-modal-info-left">
                <i className="material-icons">email</i>
                <p>
                  <a href={"mailto:" + item.value} className="orange-hover">
                    {item.value}
                  </a>
                </p>
              </div>
              <div className="contact-modal-info-right">
                <label className="phone-label">({item.label})</label>
              </div>
            </div>
          );
        });
      bio = this.props.contact.bio ? (
        <div className="contact-modal-info-bio">
          <label className="accent-text-light xs-text block">Bio</label>
          <p className="xs-text">
            {this.props.contact.bio ? (
              <span
                dangerouslySetInnerHTML={{ __html: this.props.contact.bio }}
              ></span>
            ) : (
              "There is no bio for this contact"
            )}
          </p>
        </div>
      ) : null;
    }
    return (
      <Modal
        className="contact-modal"
        ariaHideApp={false}
        contentLabel="Modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        onAfterOpen={this.props.onAfterOpen}
        style={customStyles}
        shouldCloseOnOverlayClick={true}
      >
        <Row className="contact-modal-header">
          {!this.props.loading && (
            <p className="large-text inline-block bold-text">
              {this.props.name ||
                `${this.props.contact.contact_first_name} ${this.props.contact.contact_last_name}`}
            </p>
          )}
          <i
            className="material-icons accent-text-dark"
            onClick={this.props.onRequestClose}
          >
            close
          </i>
        </Row>

        <Form className="contact-modal-form">
          {this.props.loading ? (
            <Row>
              <div className="loading-dialog">
                <img
                  src="/images/icons/spin.svg"
                  className="cm-loading-spinner"
                  alt="loading-spinner"
                />
              </div>
            </Row>
          ) : !this.props.noContact ? (
            <div>
              {phone}
              {fax}
              {email}
              {bio}
            </div>
          ) : (
            <Row className={this.props.noContact ? "" : "hidden"}>
              <div className="loading-dialog">
                <div className="grey-circle">
                  <i className="material-icons">phone</i>
                </div>
                <h2 className="no-results">No Contact Details Available</h2>
              </div>
            </Row>
          )}
        </Form>
      </Modal>
    );
  }
}

export default ClientModal;
