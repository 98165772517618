import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import { preloadedState, rootReducer } from "../reducers";
import thunk from "redux-thunk";

export default function configureStore() {
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(thunk),
      // redux devtools configuration
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
    )
  );
  persistStore(store);
  return store;
}
