import React from "react";
import Screen from "./PocketCalc_Screen";
import ButtonsNumbers from "./PocketCalc_ButtonsNumbers";
import ButtonsFuncs from "./PocketCalc_ButtonsFunctions";
import ButtonsExtensions from "./PocketCalc_ButtonsExtensions";

export default class PocketCalc extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calc: "",
      allowDecimal: true,
      setToClear: false,
    };

    this.setInput = this.setInput.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener("keydown", this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, false);
  }

  handleKeyDown(event) {
    event.preventDefault(); //prevents most recent keypress from being automatically re'pressed' when user presses enter key
    let key = event.key;
    this.setInput(key);
  }

  setInput(inp) {
    var allowFutureDecimals = this.state.allowDecimal;
    var readyToClear = this.state.setToClear;
    var out = "";
    var st = "";
    const isOperator =
      inp === "+" ||
      inp === "-" ||
      inp === "/" ||
      inp === "*" ||
      inp === "=" ||
      inp === "%" ||
      inp === "AC" ||
      inp === "BCK" ||
      inp === "Clear" ||
      inp === "+/-" ||
      inp === "." ||
      inp === "Enter" ||
      inp === "Backspace";
    if (isNaN(inp) === false || isOperator) {
      //to ensure that non-numeric/operator keypresses are ignored
      switch (inp) {
        case "BCK":
        case "Backspace":
        case "Delete":
        case "Clear":
          st = this.state.calc.toString();
          out = st.substr(0, st.length - 1);
          readyToClear = false;
          break;

        // Eval when we need to calculate
        case "=":
        case "Enter":
          if (this.state.calc.toString().includes("--")) {
            //Because of +/- button, need to convert -- to +
            out = eval(this.state.calc.replace("--", "+")); // eslint-disable-line
          } else {
            out = eval(this.state.calc); // eslint-disable-line
          }
          allowFutureDecimals = true;
          readyToClear = true;
          break;

        // Make sure there aren't any other decimals!
        case ".":
          if (allowFutureDecimals) {
            if (readyToClear) {
              out = inp; //this causes that after calculation result is displayed, if user types decimal will clear (like reg number keypress)
            } else {
              out = this.state.calc + ".";
            }
          } else {
            out = this.state.calc;
          }
          readyToClear = false;
          allowFutureDecimals = false;
          break;

        // Clear all
        case "AC":
          allowFutureDecimals = true;
          readyToClear = false;
          out = "";
          break;

        // +/- convert
        case "+/-":
          const mostRecentKey = this.state.calc.toString().slice(-1);
          if (
            mostRecentKey !== "+" &&
            mostRecentKey !== "-" &&
            mostRecentKey !== "*" &&
            mostRecentKey !== "/" &&
            this.state.calc.toString().length > 0
          ) {
            var values = this.state.calc.toString().split(/[^0-9.]/);
            var recent = values[values.length - 1];
            st = this.state.calc
              .toString()
              .substring(0, this.state.calc.toString().length - recent.length);

            if (
              st.slice(-2) === "--" ||
              st.slice(-2) === "+-" ||
              st.slice(-2) === "*-" ||
              st.slice(-2) === "/-"
            ) {
              out = st.substring(0, st.length - 1) + "" + recent;
            } else if (st === "-") {
              out = recent;
            } else {
              out = st + "" + recent * -1;
            }
          } else {
            out = this.state.calc;
          }
          break;

        // %
        case "%":
          out = eval(this.state.calc) / 100; // eslint-disable-line
          allowFutureDecimals = false;
          break;

        // We need to concat the numbers, quick way to convert to string
        default:
          if (
            inp === "+" ||
            inp === "-" ||
            inp === "*" ||
            inp === "/" ||
            inp === 0
          ) {
            allowFutureDecimals = true;
            readyToClear = false; //if type in operator after get result, will cont building on the string
            const mostRecentKey = this.state.calc.toString().slice(-1);
            if (
              mostRecentKey === "+" ||
              mostRecentKey === "-" ||
              mostRecentKey === "*" ||
              mostRecentKey === "/" ||
              this.state.calc.length < 1
            ) {
              out = this.state.calc;
            } else {
              out = this.state.calc + "" + inp;
            }
          } else {
            if (readyToClear) {
              out = inp;
              readyToClear = false;
            } else {
              out = this.state.calc + "" + inp;
            }
          }
      }

      this.setState({
        calc: out,
        allowDecimal: allowFutureDecimals,
        setToClear: readyToClear,
      });
    }
  }

  render() {
    return (
      <div className="calc-container">
        <Screen calc={this.state.calc} />
        <ButtonsNumbers _clickHandler={this.setInput} />
        <ButtonsFuncs _clickHandler={this.setInput} />
        <ButtonsExtensions _clickHandler={this.setInput} />
      </div>
    );
  }
}
