import * as types from "./actionTypes";
import { tokenExpired } from "./authenticationActions";
import config from "../config";
import { ajax, getListByType, TypesList, getDealIdsFromCustomReport } from "../lib";

export function getDealReviewUsers() {
  return (dispatch) => {
    let data = [];
    ajax({
      url: `${config.BASE_URL}get-brokers?active=1`,
      applyAuthHeader: true,
      success: (response) => {
        switch (response.status) {
          case "200":
            data = data.concat(response.data);
            ajax({
              url: `${config.BASE_URL}lists/user-groups`,
              success: (response) => {
                switch (response.status) {
                  case 200:
                    data = data.concat(response.data);
                    break;
                  default:
                    data = data.concat([]);
                    break;
                }
                dispatch({
                  type: types.GET_DEAL_REVIEW_USERS,
                  data: data,
                });
              },
            });
            break;
          case "E107":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.GET_DEAL_REVIEW_USERS,
              data: data,
            });
            break;
        }
      },
    });
  };
}

export function getPropertyTypes() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.PropertyType);
    dispatch({ type: types.GET_PROPERTY_TYPES_LIST, data });
  };
}

export function getDealTypes() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.PropertyCategory);
    dispatch({ type: types.GET_DEAL_TYPES_LIST, data });
  };
}

export function getLoanTypes() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.LoanType1);
    dispatch({ type: types.GET_LOAN_TYPES_LIST, data });
  };
}

export function getStates() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}states`,
      success: (response) => {
        switch (response.status) {
          case "200":
            dispatch({
              type: types.GET_STATES_LIST,
              data: response.data,
            });
            break;
          default:
            dispatch({
              type: types.GET_STATES_LIST,
              data: [],
            });
            break;
        }
      },
    });
  };
}

export function getDealStatuses() {
  return async (dispatch) => {
    let data = await getListByType(TypesList.PropertyStatus);
    dispatch({ type: types.GET_DEAL_STATUSES_LIST, data });
  };
}

export function getDealReviewResults(searchParams) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_DEAL_REVIEW });
    ajax({
      url: `${config.BASE_URL}deal`,
      applyAuthHeader: true,
      data: { ...searchParams },
      success: function (response) {
        let data = {
          results: response.data,
          total: response.total,
          perPage: response.per_page,
          currentPage: response.current_page,
        };
        response.data &&
          dispatch({
            type: types.GET_DEAL_REVIEW_RESULTS,
            data: data,
          });
      },
    });
  };
}

export function getDealBankQuotes(dealID) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}bank-quotes?id=${dealID}`,
      applyAuthHeader: true,
      success: function (response) {
        dispatch({
          type: types.GET_DEAL_BANK_QUOTES,
          data: response.data || [],
        });
      },
    });
  };
}

export function updateDealReview(dealID, update) {
  if(Object.keys(update).includes("qts_deal_rating")){
    ajax({
      url: `${config.BASE_URL}deal/brokerage/${dealID}`,
      applyAuthHeader: true,
      type: "PUT",
      data: {qts_last_review_date : new Date() },
    });
  }
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}deal/brokerage/${dealID}`,
      applyAuthHeader: true,
      type: "PUT",
      data: { ...update },
      success: function (response) {
        switch (response.status) {
          case "206":
            dispatch({
              type: types.UPDATE_DEAL_REVIEW_SUCCESS,
              message: response.message,
              data: response.data.id,
            });
            ajax({
              url: `${config.BASE_URL}deal?deal_id=${dealID}`,
              applyAuthHeader: true,
              success: (response) => {
                response &&
                  response.data &&
                  dispatch({
                    type: types.GET_UPDATED_DEAL_REVIEW,
                    data: response.data,
                  });
              },
            });
            break;
          case "E101":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.UPDATE_DEAL_REVIEW_FAILED,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function updateDealReviewProperty(dealID, propertyID, update) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}property/${propertyID}`,
      applyAuthHeader: true,
      type: "PUT",
      data: { ...update },
      success: function (response) {
        switch (response.status) {
          case "226":
            dispatch({
              type: types.UPDATE_DEAL_REVIEW_SUCCESS,
              message: response.message,
              data: response.data.id,
            });
            ajax({
              url: `${config.BASE_URL}deal-properties/${dealID}`,
              applyAuthHeader: true,
              success: (response) => {
                response &&
                  response.data &&
                  dispatch({
                    type: types.GET_UPDATED_DEAL_REVIEW_PROPERTY,
                    data: { dealID, property: response.data },
                  });
              },
            });
            break;
          case "E101":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.UPDATE_DEAL_REVIEW_FAILED,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function addDealReviewNote(dealID, note) {
  let data = { deal_id: dealID, note };
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}deal-note`,
      applyAuthHeader: true,
      type: "POST",
      data,
      success: (response) => {
        switch (response.status) {
          case "206":
            dispatch({
              type: types.UPDATE_DEAL_REVIEW_SUCCESS,
              message: response.message,
              data: response.data.id,
            });
            ajax({
              url: `${config.BASE_URL}deal?deal_id=${dealID}`,
              applyAuthHeader: true,
              success: (response) => {
                response &&
                  response.data &&
                  dispatch({
                    type: types.GET_UPDATED_DEAL_REVIEW,
                    data: response.data,
                  });
              },
            });
            break;
          case "E101":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.UPDATE_DEAL_REVIEW_FAILED,
              message: response.message,
            });
            break;
        }
        // dispatch(loadAllDealNotes(deal_id));
        // dispatch(addOrUpdateNoteSuccess(response));
      },
    });
  };
}

export function closeUpdateDealReviewToast() {
  return { type: types.CLOSE_UPDATE_DEAL_REVIEW_TOAST };
}

export function getFullClientInfo(id) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_FULL_CLIENT_INFO });
    ajax({
      url: `${config.BASE_URL}contact/${id}`,
      applyAuthHeader: true,
      success: function (response) {
        response &&
          response.data &&
          dispatch({
            type: types.GET_FULL_CLIENT_INFO,
            data: response.data[0].contacts[0],
          });
      },
    });
  };
}

export function getDealIds(report) {
  return async (dispatch) => {
    dispatch({type: types.GET_DEAL_IDS_FROM_CUSTOM_REPORT});
    const data  = await getDealIdsFromCustomReport(report.value);
    dispatch({type: types.DEAL_IDS_FROM_CUSTOM_REPORT, data});
  }
}
