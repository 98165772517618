import * as types from "../../actions/actionTypes";

const initialState = {
  loanAmount: "",
  interestRate: "",
  initialTerm: "",
  amortizationYears: "",
  amortizationMonths: "",
  annualUnderwrittenNOI: "",
  purchasePrice: "",
  closingCostPercent: "",
  closingCostAmount: "",
  loanToValuePercent: "",
  percentageOf: "",
  year1NOIBeforeDebtService: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_CENTRAL_STATE:
      const obj = {};
      obj[action.name] = action.value;
      return Object.assign({}, state, obj);
    default:
      return state;
  }
}
