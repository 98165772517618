import React from "react";
import Col from "muicss/lib/react/col";

class MultiSelectCheckbox extends React.Component {
  render() {
    return (
      <div>
        <Col md="12">
          <div className="rkmd-checkbox checkbox-ripple">
            <label className="input-checkbox checkbox-lightBlue">
              <input
                type="checkbox"
                id="checkbox-1"
                checked={this.props.isChecked}
                onChange={this.props.onChange.bind(
                  null,
                  this.props.id,
                  !this.props.isChecked
                )}
              />
              <span className="checkbox"></span>
              <span className="ripple"></span>
            </label>
            <p
              htmlFor="checkbox-1"
              className="label"
              onClick={this.props.onChange.bind(
                null,
                this.props.id,
                !this.props.isChecked
              )}
            >
              {this.props.label}
            </p>
          </div>
        </Col>
      </div>
    );
  }
}
export default MultiSelectCheckbox;
