import axios from "axios";
import querySerializer from "qs";
import config from "../config";
import { Navigation } from "../lib/routing";

export function getDeviceType() {
  if (window && window.isNativeApp) {
    return `Native`; //| ${window.nativeInfo ? window.nativeInfo.osName : ''}`
  } else if (
    window &&
    window.matchMedia("(display-mode: standalone)").matches
  ) {
    return `PWA`;
  } else {
    return `Unknown`;
  }
}

const client = axios.create();

/** jquery ajax compatibility for axios.
 * @param {AjaxRequestOptions} opt
 */
export async function ajax(opt) {
  const {
    url,
    type: requestType,
    method: requestMethod = requestType || "GET",
    data: requestData,
    parseXML: shouldParseXMLResponse,
    success: successCallback,
    error: errorCallback,
    applyAuthHeader,
  } = opt;

  const method = requestMethod.trim().toLowerCase();
  let args;
  switch (method) {
    case "delete":
      args = [url];
      break;
    case "get":
      if (requestData) {
        args = [url + "?" + querySerializer.stringify(requestData)];
      } else {
        args = [url];
      }
      break;
    case "post":
    case "put":
      args = [url, requestData];
      break;
    default:
      throw new Error("Unknown AJAX type: " + method);
  }
  if (url.includes(config.BASE_URL)) {
    const headers = {
      "X-Device-Type": getDeviceType(),
    };
    if (applyAuthHeader) {
      headers.Authorization = `Bearer ${localStorage.getItem("auth_token")}`;
    }
    args = [
      ...args,
      {
        headers,
      },
    ];
  }

  try {
    const response = await client[method](...args);
    if (shouldParseXMLResponse) {
      const xmlDoc = parseXMLResponse(response);
      if (successCallback) {
        successCallback(xmlDoc);
      }
      return xmlDoc;
    }
    const { data } = response;
    if (successCallback) {
      successCallback(data);
    }
    return data;
  } catch (error) {
    const err = error || new Error("Unknown error.");
    const {
      response = {
        status: -500,
        statusText: "Unknown",
        data: { message: "Unknown error." },
      },
    } = err;
    if (errorCallback) {
      errorCallback(response, response.statusText, err.toString());
    }
    if (response.status === 500) {
      console.log("API GOT 500 Error");
      Navigation.redirect("/error");
      return Promise.resolve({ response: { data: {} } });
    }
    return Promise.reject(err);
  }
}
/** @param {import('axios').AxiosResponse} response */
function parseXMLResponse(response) {
  const { data } = response;
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(data, "application/xml");
  return xmlDoc;
}

// #region Typedefs
/** @typedef {object} AjaxRequestOptions
 * @property {string} url
 * @property {string} [type]
 * @property {string} [method]
 * @property {object} [data]
 * @property {(data:object)=>void}
 * [success]
 * @property {(import('axios').AxiosResponse)=>void} [error]
 * @property {boolean} [applyAuthHeader]
 */
// #endregion
