import * as types from "../actionTypes";
import { ajax } from "../../lib";
import config from "../../config";

export function calculatorSharing(
  to,
  from,
  cc,
  calculator_name,
  inputs,
  outputs
) {
  inputs = JSON.stringify(inputs);
  outputs = JSON.stringify(outputs);
  return (dispatch) => {
    dispatch({ type: types.SET_LOGGING_IN_MESSAGE });
    ajax({
      url: `${config.BASE_URL}send-calculator`,
      type: "POST",
      data: { to, from, cc, inputs, outputs, calculator_name },
      success: function (response) {
        if (response.isResponse) {
          dispatch({
            type: types.SHARED_CALCULATOR_SUCCESS,
            data: "Email with current calculation sent to " + to,
          });
        } else {
          dispatch({
            type: types.SHARED_CALCULATOR_ERROR,
            data: "Error in sending email to share current calculation",
          });
        }
      },
    });
  };
}

export function closeSharedCalcToast() {
  return { type: types.CLOSE_SHARED_CALCULATOR_TOAST };
}
