import React from "react";
import { useDispatch } from "react-redux";
import { useStyles } from "./ClearConfirmationModal.style";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { clearEcalc } from "../../../actions/eCalcForms";
import { CloseIcon } from "../../../themes";

export function ClearConfirmationModal({ handleClose, persist }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  function confirm() {
    dispatch(clearEcalc(persist));
    handleClose();
  }

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="xs">
      <DialogTitle
        className={classes.innerDialogTitle}
        disableTypography={true}
      >
        <span className={classes.errorDialogTitle}>Clear All Results</span>
        <CloseIcon
          onClick={() => handleClose}
          className={classes.innerDialogCloseBtn}
        />
      </DialogTitle>
      <DialogContent>
        When clearing the calculator, all data will be lost.
      </DialogContent>
      <DialogActions>
        <Button className={classes.cancelLinkBtn} onClick={handleClose}>
          Cancel
        </Button>
        <Button className={classes.saveLinkBtn} onClick={() => confirm()}>
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
}
