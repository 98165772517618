import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as profileActions from "../../actions/profileActions";
import GetParameterByName from "../common/getParameterByName";
import Unsubscribe from "./Unsubscribe";

class UnsubscribeContainer extends React.Component {
  render() {
    return (
      <Unsubscribe
        contactId={GetParameterByName("id")}
        emailType={GetParameterByName("type")}
        emailAddress={GetParameterByName("email")}
        emailName={GetParameterByName("name")}
        message={this.props.profile.unsubscribe.message}
        success={this.props.profile.unsubscribe.success}
        submit={this.props.actions.unsubscribe}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(profileActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribeContainer);
