import React from "react";
import { connect } from "react-redux";
import * as IRRActions from "../../../actions/calculatorActions/IRRActions";
import * as centralStateActions from "../../../actions/calculatorActions/centralActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentInput from "../calculatorCommon/PercentInput";
import NumberInput from "../calculatorCommon/NumberInput";
import DollarDisplay from "../calculatorCommon/DollarDisplay";
import PercentDisplay from "../calculatorCommon/PercentDisplay";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import Option from "muicss/lib/react/option";
import Select from "muicss/lib/react/select";
import ErrorMessage from "../calculatorCommon/ErrorMessage";
import formatValue from "../calculatorCommon/FormatValueMethod";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";

class IRR extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //all these are inputs:
      purchasePrice: this.props.purchasePrice || "",
      closingCostPercent: this.props.closingCostPercent || "",
      closingCostAmount: this.props.closingCostAmount || "",
      loanToValuePercent: this.props.loanToValuePercent || "",
      percentageOf: this.props.percentageOf || "", //either all in costs or purchase price
      interestRate: this.props.interestRate || "",
      IOToggle: this.props.IOToggle || "",
      amortizationYears: this.props.amortizationYears || "",
      amortizationStartsAfter: this.props.amortizationStartsAfter || "",
      year1NOIBeforeDebtService: this.props.year1NOIBeforeDebtService || "",
      annualizedNOIGrowthRate: this.props.annualizedNOIGrowthRate || "",
      projectedSaleYear: this.props.projectedSaleYear || "",
      projectedCapRateAtSale: this.props.projectedCapRateAtSale || "",
      projectedClosingCostsAtSale: this.props.projectedClosingCostsAtSale || "",
      capex: this.props.capex || "",
      //all these are 'calculated inputs'
      freeCashFlow: this.props.freeCashFlow || "",
      annualPayment: this.props.annualPayment || "",
      IOPayment: this.props.IOPayment || "",
      loanAmount: this.props.loanAmount || "",
      cashOnCashRequired: this.props.cashOnCashRequired || "",
      allInCosts: this.props.allInCosts || "",
      salePrice: this.props.salePrice || "",
      closingCost: this.props.closingCost || "",
      projectedLoanBalanceAtSaleDate:
        this.props.projectedLoanBalanceAtSaleDate || "",
      availableToBeDistributed: this.props.availableToBeDistributed || "",
      totalDistributedPriorToSale: this.props.totalDistributedPriorToSale || "",
      totalDistributedLifeOfInvestment:
        this.props.totalDistributedLifeOfInvestment || "",
      amountOwed: this.props.amountOwed || "",
      arrayForTable: this.props.arrayForTable || [],
      finalArrayValues: this.props.finalArrayValues || {},
      IRRPercentage: this.props.IRRPercentage || "",
      error: this.props.error || "",
      amortizationError: this.props.amortizationError || "",

      modalOpen: false,
      from: "",
      to: "",
      cc: "",
    };

    this.update = this.update.bind(this);
    this.updateCentralState = this.updateCentralState.bind(this);
    this.updateSelect = this.updateSelect.bind(this);
  }

  componentDidMount() {
    this.update("purchasePrice", this.props.purchasePrice);
  }

  updateSelect(event) {
    if (event.target.name !== "percentageOf") {
      this.update(event.target.name, event.target.value);
    } else {
      this.updateCentralState(event.target.name, event.target.value);
    }
  }

  update(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      this.props.dispatch(IRRActions.calculateIRR(this.state, { name, value }));
    });
  }

  updateCentralState(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    if (name === "amortizationYears") {
      const monthsName = "amortizationMonths";
      const monthsValue = value * 12;
      let stateObj2 = {};
      stateObj2[monthsName] = monthsValue;
      this.setState(stateObj, function () {
        this.props.dispatch(
          centralStateActions.updateCentralValues(monthsName, monthsValue)
        );
      });
    }
    this.setState(stateObj, function () {
      this.props.dispatch(centralStateActions.updateCentralValues(name, value));
      this.props.dispatch(IRRActions.calculateIRR(this.state, { name, value }));
    });
  }

  openSharingCalculatorModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateCC(value) {
    this.setState({ cc: value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ modalOpen: false }, function () {
      var IOPayment =
        this.state.IOPayment !== 0
          ? this.state.IOToggle === "FullIO"
            ? formatValue(this.state.IOPayment).toString()
            : "  - "
          : "   - ";
      var amortizationStartsAfter =
        this.state.amortizationStartsAfter !== 0
          ? this.state.amortizationStartsAfter.toString()
          : "  - ";
      var amortizationYears =
        this.state.amortizationYears !== 0
          ? this.state.amortizationYears.toString()
          : "  - ";
      var annualPayment =
        this.state.annualPayment !== 0
          ? formatValue(this.state.annualPayment).toString()
          : "  - ";
      var input = [
        { input0: formatValue(this.state.purchasePrice).toString() },
        { input1: formatValue(this.state.closingCostAmount).toString() },
        { input2: (this.state.closingCostPercent + "%").toString() },
        { input3: formatValue(this.state.capex).toString() },
        { input4: formatValue(this.state.allInCosts).toString() },
        { input5: (this.state.loanToValuePercent + "%").toString() },
        { input6: this.state.percentageOf.toString() },
        { input7: (this.state.interestRate + "%").toString() },
        { input8: this.state.IOToggle.toString() },
        { input9: IOPayment },
        { input10: amortizationStartsAfter },
        { input11: amortizationYears },
        { input12: annualPayment },
        {
          input13: formatValue(this.state.year1NOIBeforeDebtService).toString(),
        },
        { input14: formatValue(this.state.freeCashFlow).toString() },
        { input15: (this.state.annualizedNOIGrowthRate + "%").toString() },
        { input16: this.state.projectedSaleYear.toString() },
        { input17: (this.state.projectedCapRateAtSale + "%").toString() },
        { input18: (this.state.projectedClosingCostsAtSale + "%").toString() },
        { input19: formatValue(this.state.capex).toString() },
      ];

      var output = [
        { output0: (this.state.IRRPercentage + "%").toString() },
        { output1: formatValue(this.state.salePrice.toFixed(2)).toString() },
        { output2: formatValue(this.state.closingCost.toFixed(2)).toString() },
        {
          output3: formatValue(
            this.state.projectedLoanBalanceAtSaleDate.toFixed(2)
          ).toString(),
        },
        {
          output4: formatValue(
            this.state.availableToBeDistributed.toFixed(2)
          ).toString(),
        },
        {
          output5: formatValue(
            this.state.totalDistributedPriorToSale.toFixed(2)
          ).toString(),
        },
        {
          output6: formatValue(
            this.state.totalDistributedLifeOfInvestment.toFixed(2)
          ).toString(),
        },
      ];

      this.props.dispatch(
        sharingCalculationsActions.calculatorSharing(
          this.state.to,
          this.state.from,
          this.state.cc,
          "Irr",
          input,
          output
        )
      );
    });
  }

  render() {
    let years = [];
    let todaysYear = new Date().getFullYear();
    todaysYear = todaysYear + 1;
    for (let i = todaysYear; i <= todaysYear + 15; i++) {
      years.push(i);
    }

    return (
      <div>
        <Row>
          <ShareCalcModal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal.bind(this)}
            onAfterOpen={() => {}}
            onSubmit={this.onSubmit.bind(this)}
            from={this.state.from}
            to={this.state.to}
            cc={this.state.cc}
            updateFrom={this.updateFrom.bind(this)}
            updateTo={this.updateTo.bind(this)}
            updateCC={this.updateCC.bind(this)}
          />
          <Panel className="calculator-card">
            <Form className="calculator-form" inline={true}>
              <h2 className="calculator-title">
                Internal Rate of Return (IRR)
              </h2>
              <div className="error-container">
                {this.state.error && (
                  <ErrorMessage message={this.state.error} />
                )}
              </div>
              <br />
              <DollarInput
                name="purchasePrice"
                value={this.state.purchasePrice}
                update={this.update}
                label="Purchase Price"
              />
              <br />
              <br />
              <DollarInput
                name="closingCostAmount"
                value={this.state.closingCostAmount}
                update={this.update}
                label="Closing Cost $"
              />
              <PercentInput
                name="closingCostPercent"
                value={this.state.closingCostPercent}
                update={this.update}
                label="Closing Cost %"
              />
              <br />
              <br />
              <DollarInput
                name="capex"
                value={this.state.capex}
                update={this.update}
                label="Capex"
              />
              <br />
              <br />
              <div className="mui-textfield calculator-display">
                <label>All in Costs</label>
                <DollarDisplay
                  name="allInCosts"
                  value={this.state.allInCosts}
                  label="All in Costs"
                />
              </div>
              <br />
              <br />
              <PercentInput
                name="loanToValuePercent"
                value={this.state.loanToValuePercent}
                update={this.updateCentralState}
                label="Loan to Value %"
              />
              <div className="percentage-of-select">
                <Select
                  name="percentageOf"
                  value={this.state.percentageOf.toString()}
                  onChange={this.updateSelect}
                  label="Percentage of"
                >
                  <Option value="Purchase Price" label="Purchase Price" />
                  <Option value="All in Costs" label="All in Costs" />
                </Select>
              </div>
              <PercentInput
                name="interestRate"
                value={this.state.interestRate}
                update={this.updateCentralState}
                label="Interest Rate"
              />
              <br />
              <br />
              <div className="amortization-select">
                <Select
                  value={this.state.IOToggle.toString()}
                  name="IOToggle"
                  onChange={this.updateSelect}
                  label="Amortization"
                >
                  <Option value="No IO" label="No IO" />
                  <Option value="Full IO" label="Full IO" />
                  <Option value="Blended IO" label="Blended IO" />
                </Select>
              </div>
              {this.state.IOToggle === "Blended IO" && (
                <NumberInput
                  name="amortizationStartsAfter"
                  value={this.state.amortizationStartsAfter}
                  update={this.update}
                  label="Years of IO"
                  errorMessage={this.state.amortizationError}
                />
              )}
              <br />
              <br />
              {this.state.IOToggle !== "Full IO" && (
                <NumberInput
                  name="amortizationYears"
                  value={this.state.amortizationYears}
                  update={this.updateCentralState}
                  label="Amortization (Yrs)"
                />
              )}
              {this.state.IOToggle !== "Full IO" && (
                <div className="mui-textfield calculator-display">
                  <label>Annual Payment</label>
                  <DollarDisplay
                    name="annualPayment"
                    value={this.state.annualPayment}
                  />
                </div>
              )}
              {this.state.IOToggle === "Full IO" && (
                <div className="mui-textfield calculator-display">
                  <label>Interest Only Payment</label>
                  <DollarDisplay
                    name="IOPayment"
                    value={this.state.IOPayment}
                  />
                </div>
              )}
              {this.state.IOToggle === "Blended IO" && (
                <div className="mui-textfield calculator-display">
                  <label>Interest Only Payment</label>
                  <DollarDisplay
                    name="IOPayment"
                    value={this.state.IOPayment}
                  />
                </div>
              )}
              <br />
              <br />
              <DollarInput
                name="year1NOIBeforeDebtService"
                value={this.state.year1NOIBeforeDebtService}
                update={this.updateCentralState}
                label="First Year NOI (Before Debt Service)"
              />
              <div className="mui-textfield calculator-display">
                <label>Free Cash Flow (After Debt Service)</label>
                <DollarDisplay
                  name="freeCashFlow"
                  value={this.state.freeCashFlow}
                />
              </div>
              <br /> <br />
              <PercentInput
                name="annualizedNOIGrowthRate"
                value={this.state.annualizedNOIGrowthRate}
                update={this.update}
                label="Annualized NOI Growth Rate"
              />
              <div className="amortization-select">
                <Select
                  value={this.state.projectedSaleYear.toString()}
                  name="projectedSaleYear"
                  onChange={this.updateSelect}
                  label="Projected Sale Year"
                >
                  {years.map((year) => {
                    return (
                      <Option
                        key={year.toString()}
                        value={year.toString()}
                        label={year.toString()}
                      />
                    );
                  })}
                </Select>
              </div>
              <br /> <br />
              <PercentInput
                name="projectedCapRateAtSale"
                value={this.state.projectedCapRateAtSale}
                update={this.update}
                label="Projected Cap Rate at Sale"
              />
              <PercentInput
                name="projectedClosingCostsAtSale"
                value={this.state.projectedClosingCostsAtSale}
                update={this.update}
                label="Projected Closing Costs At Sale"
              />
              <br />
              <br />
            </Form>
            <div id="boxes">
              <p
                className={
                  this.state.purchasePrice &&
                  this.state.closingCostAmount &&
                  this.state.loanToValuePercent &&
                  this.state.interestRate &&
                  this.state.year1NOIBeforeDebtService &&
                  this.state.freeCashFlow &&
                  this.state.annualizedNOIGrowthRate &&
                  this.state.projectedSaleYear &&
                  this.state.projectedCapRateAtSale &&
                  this.state.projectedClosingCostsAtSale &&
                  this.state.salePrice &&
                  this.state.closingCost &&
                  this.state.projectedLoanBalanceAtSaleDate &&
                  this.state.availableToBeDistributed &&
                  this.state.totalDistributedPriorToSale &&
                  this.state.totalDistributedLifeOfInvestment
                    ? "share-calc"
                    : "hidden"
                }
                onClick={this.openSharingCalculatorModal.bind(this)}
              >
                <i className="material-icons">share</i>Share Calculations
              </p>
              <div className="irr-boxes">
                <div className="box">
                  <p className="box-title">Internal Rate of Return</p>
                  <p className="amount">
                    <PercentDisplay
                      name="IRRPercentage"
                      value={this.state.IRRPercentage}
                    />
                  </p>
                </div>
                <div className="box orange">
                  <p className="box-title">Sale Price</p>
                  <p className="amount">
                    <DollarDisplay
                      name="salePrice"
                      value={this.state.salePrice}
                    />
                  </p>
                </div>
                <div className="box orange">
                  <p className="box-title">Closing Cost</p>
                  <p className="amount">
                    <DollarDisplay
                      name="closingCost"
                      value={this.state.closingCost}
                    />
                  </p>
                </div>
                <div className="box orange">
                  <p className="box-title">
                    Projected Loan Balance at Sale Date
                  </p>
                  <p className="amount">
                    <DollarDisplay
                      name="projectedLoanBalanceAtSaleDate"
                      value={this.state.projectedLoanBalanceAtSaleDate}
                    />
                  </p>
                </div>
                <div className="box orange">
                  <p className="box-title">
                    Available to be Distributed at Sale
                  </p>
                  <p className="amount">
                    <DollarDisplay
                      name="availableToBeDistributed"
                      value={this.state.availableToBeDistributed}
                    />
                  </p>
                </div>
                <div className="box orange">
                  <p className="box-title">Total Distributed Prior to Sale</p>
                  <p className="amount">
                    <DollarDisplay
                      name="totalDistributedPriorToSale"
                      value={this.state.totalDistributedPriorToSale}
                    />
                  </p>
                </div>
                <div className="box orange">
                  <p className="box-title">
                    Total Distributed Life of Investment
                  </p>
                  <p className="amount">
                    <DollarDisplay
                      name="totalDistributedLifeOfInvestment"
                      value={this.state.totalDistributedLifeOfInvestment}
                    />
                  </p>
                </div>
              </div>
            </div>
          </Panel>
        </Row>
        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const state1 = state.IRR;
  const state2 = state.centralValues;
  let todaysYear = new Date().getFullYear() + 5;
  return {
    //all these are inputs:
    purchasePrice: state2.purchasePrice || "",
    closingCostPercent: state2.closingCostPercent || "",
    closingCostAmount: state2.closingCostAmount || "",
    loanToValuePercent: state2.loanToValuePercent || "",
    percentageOf: state2.percentageOf || "Purchase Price", //either all in costs or purchase price
    interestRate: state2.interestRate || "",
    IOToggle: state1.IOToggle || "No IO",
    amortizationYears: state2.amortizationYears || "",
    amortizationStartsAfter: state1.amortizationStartsAfter || "",
    year1NOIBeforeDebtService: state2.year1NOIBeforeDebtService || "",
    annualizedNOIGrowthRate: state1.annualizedNOIGrowthRate || "",
    projectedSaleYear: state1.projectedSaleYear || todaysYear,
    projectedCapRateAtSale: state1.projectedCapRateAtSale || "",
    projectedClosingCostsAtSale: state1.projectedClosingCostsAtSale || "",
    capex: state1.capex || "",
    //all these are 'calculated inputs'/needed for the calculations and possibly may need to be exported
    freeCashFlow: state1.freeCashFlow || "",
    annualPayment: state1.annualPayment || "",
    IOPayment: state1.IOPayment || "",
    loanAmount: state1.loanAmount || "",
    cashOnCashRequired: state1.cashOnCashRequired || "",
    allInCosts: state1.allInCosts || "",
    salePrice: state1.salePrice || "",
    closingCost: state1.closingCost || "",
    projectedLoanBalanceAtSaleDate: state1.projectedLoanBalanceAtSaleDate || "",
    availableToBeDistributed: state1.availableToBeDistributed || "",
    totalDistributedPriorToSale: state1.totalDistributedPriorToSale || "",
    totalDistributedLifeOfInvestment:
      state1.totalDistributedLifeOfInvestment || "",
    amountOwed: state1.amountOwed || "",
    arrayForTable: state1.arrayForTable || [],
    finalArrayValues: state1.finalArrayValues || {},
    IRRPercentage: state1.IRRPercentage || "",
    error: state1.error || "",
    amortizationError: state1.amortizationError || "",
  };
}

export default connect(mapStateToProps)(IRR);
