import * as types from "./actionTypes";
import config from "../config";
import { ajax } from "../lib";

export function loadRates() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}public-rate-sheet`,
      success: function (data) {
        dispatch({ type: types.GET_RATES, rates: data.data });
      },
    });
  };
}

export function loadNewRates() {
  return async (dispatch) => {
    ajax({
      url: `${config.BASE_URL}public-rate-sheet/separate-indices`,
      success: function (response) {
        if (response.data) {
          dispatch({ type: types.GET_RATES_PAGE, payload: response.data });
        } else {
        }
      },
    });
  };
}

export function getEcalcRates() {
  return async (dispatch) => {
    ajax({
      url: `${config.BASE_URL}simple-rate-sheet`,
      success: function (response) {
        if (response.data) {
          dispatch({ type: types.GET_ECALC_RATES, payload: response.data });
        }
      },
    });
  };
}
