import * as types from "../actionTypes";

function updateCapRate(values) {
  return { type: types.UPDATE_CAP_RATE, values };
}

export function calculateCapRate(values) {
  return function (dispatch) {
    values.capRate = (values.NOI / values.salesPrice) * 100;
    dispatch(updateCapRate(values));
  };
}

export function updateValuesCapRate(values) {
  return function (dispatch) {
    if (!values.capRate) {
      return;
    }
    values.capRate = 0;
    dispatch(updateCapRate(values));
  };
}

export function updateOnUnmount(values) {
  return function (dispatch) {
    dispatch(updateCapRate(values));
  };
}
