import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { eCalcForms } from "../../../lib";
import { eCalcOnChange, addUserEntryMethod } from "../../../actions/eCalcForms";
import {
  parseToNumber,
  formatDollar,
  entryMethodNames,
  entryMethods,
} from "../helpers";
import { useStyles } from "./ResultModal.styles";
import _cloneDeep from "lodash.clonedeep";
import mixpanel from "mixpanel-browser";
import { CloseIcon } from "../../../themes";

export function DebtYieldModal({ open, handleClose, storeSliceName, persist }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const debt_yield = useSelector(
    (state) => state[storeSliceName][eCalcForms.EQUITY_NEEDED].debt_yield
  );
  const loans = useSelector(
    (state) => state[storeSliceName][eCalcForms.EQUITY_NEEDED].loans
  );
  const loan_amount_dollar = useSelector(
    (state) =>
      state[storeSliceName][eCalcForms.EQUITY_NEEDED].loan_amount_dollar
  );
  const first_year_noi = useSelector(
    (state) => state[storeSliceName][eCalcForms.CAP_RATE].first_year_noi
  );

  const [debtYield, setDebtYield] = useState(
    debt_yield === undefined ? "7" : debt_yield
  );
  const [maxLoan, setMaxLoan] = useState(0);

  useEffect(() => {
    setMaxLoan(
      calculateMaxLoan({
        state: {
          first_year_noi: parseToNumber(first_year_noi),
          debt_yield: parseToNumber(debtYield),
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debtYield]);

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    const intValue = value.replace(/[^0-9.]/g, ""); //Strips of non-numeric characters besides decimal
    setDebtYield(intValue);
    dispatch(
      eCalcOnChange({
        calculator: eCalcForms.EQUITY_NEEDED,
        name,
        value: intValue,
        persist,
      })
    );

    setTimeout(() => {
      target.focus();
      let position = intValue.length;
      target.setSelectionRange(position, position);
    }, 0);
  }

  function saveLoanAmnt() {
    const newLoans = _cloneDeep(loans);
    var loansWithAmount = newLoans.filter((loan) => loan.amount.length);
    if (loansWithAmount.length) {
      var difference = maxLoan - parseToNumber(loan_amount_dollar);
      for (let index = 1; difference !== 0 && index <= loansWithAmount.length; index++) {
        const loanIndex = newLoans.findIndex(
          (loan) =>
            loan.name === loansWithAmount[loansWithAmount.length - index].name
        );
        const loanAmount = parseToNumber(newLoans[loanIndex].amount);
        if (difference < 0 && loanAmount < Math.abs(difference)) {
          newLoans[loanIndex].amount = "0";
          difference = difference + loanAmount;
        } else {
          newLoans[loanIndex].amount = formatDollar(loanAmount + difference);
          difference = 0;
        }
      }
      dispatch(
        eCalcOnChange({
          calculator: eCalcForms.EQUITY_NEEDED,
          name: "loans",
          value: newLoans,
          persist,
        })
      );
    }
    dispatch(
      eCalcOnChange({
        calculator: eCalcForms.EQUITY_NEEDED,
        name: "loan_amount_dollar",
        value: formatDollar(maxLoan),
        persist,
      })
    );
    dispatch(
      addUserEntryMethod({
        name: entryMethodNames.LOAN_AMOUNT,
        value: entryMethods.DOLLAR_AMOUNT,
        persist,
      })
    );
    mixpanel.track("Debt Yield Modal - added as Loan Amount");
    handleClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{ className: classes.dialog }}
    >
      <DialogContent className={classes.dialogContent}>
        <div>
          <span className={classes.resultModalTitle}>Debt Yield</span>
          <CloseIcon className={classes.closeBtn} onClick={handleClose} />
        </div>
        <DialogContentText classes={{ root: classes.resultModalMsg }}>
          If bank's required Debt Yield is{" "}
          <input
            name="debt_yield"
            value={`${debtYield}%`}
            onChange={handleInputChange}
            className={classes.resultModalInput}
          />
          <br />
          <span>
            the bank will lend you{" "}
            <span className={classes.resultModalCalcAmount}>
              ${formatDollar(maxLoan)}
            </span>
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ paddingRight: 20 }}>
        <button
          onClick={handleClose}
          className={clsx(classes.resultModalBtn, classes.resultModalCancel)}
        >
          Cancel
        </button>
        <button
          onClick={saveLoanAmnt}
          className={clsx(classes.resultModalBtn, classes.resultModalSave)}
          style={{ fontWeight: "bold" }}
        >
          Add as Loan Amount
        </button>
      </DialogActions>

      {loans.find((loan) => loan.amount.length) && (
        <p className={classes.blendedRateNote}>
          Note: Differences to Loan Amount will be added/subtracted to the last
          mortgage added.
        </p>
      )}
    </Dialog>
  );
}

function calculateMaxLoan({ state }) {
  const { first_year_noi, debt_yield } = state;
  let maxLoan = 0;
  if (first_year_noi && debt_yield) {
    maxLoan = first_year_noi / (debt_yield / 100);
  }
  return maxLoan;
}
