import * as types from "../actions/actionTypes";

const initialState = {
  myLoans: [],
  allLoans: [],
  employees: [],
  contact: {},
  pages: "",
  message: "",
  noLoans: false,
  noContact: false,
  other: "",
  employeeMessage: "",
  loading: false,
  noDataToast: false,
  loadingContact: false,
  successToast: false,
  page: "",
  employeeId: "",
  search: "",
  filter: "",
};

export default function loansReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_MY_LOAN_DATA:
      return Object.assign({}, state, {
        myLoans: [],
        noLoans: false,
        loading: true,
      });

    case types.LOAD_MY_LOAN_DATA:
      return Object.assign({}, state, {
        page: "my-loans",
        myLoans: action.myLoans,
        pages: action.pages,
        noLoans: false,
        loading: false,
        filter: action.filter,
        employeeId: action.id,
      });

    case types.NO_MY_LOAN_DATA:
      return Object.assign({}, state, {
        myLoans: [],
        noLoans: true,
        loading: false,
        message: action.message,
      });

    case types.LOADING_ALL_LOAN_DATA:
      return Object.assign({}, state, {
        allLoans: [],
        noLoans: false,
        loading: true,
      });

    case types.LOAD_ALL_LOAN_DATA:
      return Object.assign({}, state, {
        other: action.other,
        page: "all-loans",
        allLoans: action.allLoans,
        pages: action.pages,
        noLoans: false,
        loading: false,
      });

    case types.NO_ALL_LOAN_DATA:
      return Object.assign({}, state, {
        allLoans: [],
        noLoans: true,
        loading: false,
        message: action.message,
      });

    case types.UPDATE_LOAN_SEARCH_VALUE:
      return Object.assign({}, state, { search: action.search });

    case types.SHOW_LOAN_NO_DATA_TOAST:
      return Object.assign({}, state, {
        noDataToast: true,
        message: action.message,
      });

    case types.CLOSE_LOAN_NO_DATA_TOAST:
      return Object.assign({}, state, {
        noDataToast: false,
        message: "",
      });

    case types.LOADING_LOAN_CONTACT_DETAILS:
      return Object.assign({}, state, {
        loadingContact: true,
        contact: {},
        noContact: false,
      });

    case types.LOAD_LOAN_CONTACT_DETAILS:
      return Object.assign({}, state, {
        loadingContact: false,
        contact: action.contact,
        noContact: false,
      });
    case types.NO_LOAN_CONTACT_DETAILS:
      return Object.assign({}, state, {
        loadingContact: false,
        contact: {},
        noContact: true,
      });

    case types.PASS_LOAN_SUCCESS:
      let myLoansList = state.myLoans;
      let loanIndex = myLoansList.findIndex(
        (quote) => quote.quote_id === action.quote_id
      );
      myLoansList.splice(loanIndex, 1);
      return Object.assign({}, state, {
        myLoans: myLoansList,
        successToast: true,
        message: action.message,
      });

    case types.QUOTE_LOAN_SUCCESS:
      let myLoansList2 = state.myLoans;
      let loanIndex2 = myLoansList2.findIndex(
        (quote) => quote.quote_id === action.quote_id
      );
      myLoansList2[loanIndex2].status = "Quoted";
      return Object.assign({}, state, {
        myLoans: myLoansList2,
      });

    case types.PASS_LOAN_ERROR:
      return Object.assign({}, state, {
        successToast: true,
        message: action.message,
      });

    case types.CLOSE_LOAN_TOAST:
      return Object.assign({}, state, {
        successToast: false,
        message: "",
      });

    case types.GET_BANK_PRESIDENT_EMPLOYEES_SUCCESS:
      return Object.assign({}, state, {
        employees: action.data,
        employeeMessage: "",
      });
    case types.GET_BANK_PRESIDENT_EMPLOYEES_FAILED:
      return Object.assign({}, state, {
        employees: [],
        employeeMessage: action.message,
      });
    case types.ADD_BANKER_TO_ALL_LOAN_SUCCESS:
      let addAllLoansList = state.allLoans;
      let addAllLoanIndex = addAllLoansList.findIndex(
        (loan) => loan.claim_id === action.claim_id
      );
      if (addAllLoansList[addAllLoanIndex].bankers) {
        addAllLoansList[addAllLoanIndex].bankers.push({
          banker_name: JSON.parse(localStorage.getItem("data")).user_name,
          rb_id: action.data.rb_id,
        });
      } else {
        addAllLoansList[addAllLoanIndex].bankers = [
          {
            banker_name: JSON.parse(localStorage.getItem("data")).user_name,
            rb_id: action.data.rb_id,
          },
        ];
      }
      addAllLoansList[addAllLoanIndex].show_add_button = 0;
      return Object.assign({}, state, {
        allLoans: addAllLoansList,
      });
    case types.ADD_BANKER_TO_ALL_LOAN_FAILED:
      return Object.assign({}, state, {
        noDataToast: true,
        message: action.message,
      });

    case types.REMOVE_BANKER_FROM_ALL_LOAN_SUCCESS:
      let removeAllLoansList = state.allLoans;
      let removeAllLoansIndex = removeAllLoansList.findIndex(
        (loan) => loan.claim_id === action.claim_id
      );
      if (removeAllLoansList[removeAllLoansIndex].bankers) {
        let bankerIndex = removeAllLoansList[
          removeAllLoansIndex
        ].bankers.findIndex((banker) => banker.rb_id === action.rb_id);
        removeAllLoansList[removeAllLoansIndex].bankers.splice(bankerIndex, 1);
        removeAllLoansList[removeAllLoansIndex].show_add_button = 1;
      }
      return Object.assign({}, state, {
        allLoans: removeAllLoansList,
      });

    case types.REMOVE_BANKER_FROM_ALL_LOAN_FAILED:
      return Object.assign({}, state, {
        noDataToast: true,
        message: action.message,
      });

    case types.REMOVE_BANKER_FROM_MY_LOAN_SUCCESS:
      let removeMyLoansList = state.myLoans;
      let removeMyLoanIndex = removeMyLoansList.findIndex(
        (loan) => loan.claim_id === action.claim_id
      );
      removeMyLoansList.splice(removeMyLoanIndex, 1);
      return Object.assign({}, state, {
        myLoans: removeMyLoansList,
        successToast: true,
        message: action.message,
      });

    case types.REMOVE_BANKER_FROM_MY_LOAN_FAILED:
      return Object.assign({}, state, {
        noDataToast: true,
        message: action.message,
      });
    default:
      return state;
  }
}
