import React from "react";
import config from "../../config";

class ActivationSuccessful extends React.Component {
  render() {
    return (
      <div className="account-activation-background">
        <div className="aa-logo">
          <img alt="gs-logo" src="/images/gs-logo.png" />
        </div>
        <div className="account-activation-panel panel">
          <div className="text-center">
            <p className="bold-text large-text">
              Account activated successfully
            </p>
            <p className="accent-text-dark small-text">
              Please choose one of the following options to get started
            </p>
          </div>
          <div className="directions">
            <div className="download-app directions-left">
              <div className="bold-text large-text directions-title">
                Download the app
              </div>
              <div className="gs-step-app-icons">
                <a
                  href="https://play.google.com/store/apps/details?id=com.LoanTracker&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    id="android2"
                    alt="Get it on Google Play"
                    src="/images/gs-google-play.png"
                  />
                </a>

                <a
                  href="https://itunes.apple.com/us/app/eastern-union/id1125123079?mt=8"
                  className="login-apps-apple"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    id="ios2"
                    alt="Get it on the App Store"
                    src="/images/gs-app-store.png"
                  />
                </a>
              </div>
            </div>
            <div className="divider-or">
              <div className="or-circle">OR</div>
            </div>
            <div className="online-portal directions-right">
              <div className="bold-text large-text directions-title">
                Access our online portal
              </div>
              <a href={config.PORTAL_URL}>
                <img alt="login" src="/images/login.png" />
              </a>
            </div>
          </div>
          <p className="small-text">
            If you have any questions or would like to make changes to your
            Schedule of Real Estate, please email them to{" "}
            <a href="mailto:app@easternunion.com" className="orange-text">
              app@easternunion.com
            </a>{" "}
            or call{" "}
            <a href="tel:732-301-3960" className="orange-text">
              732-301-3960
            </a>
          </p>
        </div>
      </div>
    );
  }
}

export default ActivationSuccessful;
