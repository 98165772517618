import * as types from "../actionTypes";

function updateDebtYield(values) {
  return { type: types.UPDATE_DEBT_YIELD, values };
}

export function calculateDebtYield(values) {
  return function (dispatch) {
    values.maximumLoan =
      (100 * values.annualUnderwrittenNOI) / values.minimumDebtYield;
    dispatch(updateDebtYield(values));
  };
}

export function updateValuesDebtYield(values) {
  return function (dispatch) {
    if (!values.maximumLoan) {
      return;
    }
    values.maximumLoan = 0;
    dispatch(updateDebtYield(values));
  };
}

export function updateOnUnmount(values) {
  return function (dispatch) {
    dispatch(updateDebtYield(values));
  };
}
