import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigation } from "../../lib";
import * as navigationTitleActions from "../../actions/navigationTitleActions";
import {
  CalculatorIcon,
  //HomeIcon,
  //HeadsetIcon,
  UnconfirmDealsIcon,
  DealReviewIcon,
  ServiceProviderIcon,
  DocsIcon,
  MissingQuotesIcon,
  // NewsFeedIcon,
  LendingCriteriaIcon,
  MyFundPortfolioIcon,
  GroupIcon,
  DescriptionIcon,
  DonutLargeIcon,
  AccountBalanceIcon,
  //AccountBalanceWalletIcon,
  BusinessIcon,
  AssignmentIcon,
  //LoopIcon,
  WorkIcon,
  TrendingUpIcon,
  //PersonIcon,
  CompsIcon,
  MoneyIcon,
  //CalculateIcon,
  EcalcIcon,
  StoryBookIcon
} from "../../themes";

class PublicLoginPage extends React.Component {
  componentDidMount() {
    this.props.navActions.updateNavTitle("Home");
  }

  render() {
    const { unconfirmed_deal_count, userData } = this.props;
    return (
      <div className="home-page">
        <div className="home-page-grid grid">
          <div
            className={clsx(
              userData && userData.is_broker ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/contacts/contacts/today`)}
          >
            <p className="home-page-title">Contacts</p>
            <GroupIcon />
          </div>
          <div
            className={clsx(
              userData && userData.is_broker  ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/broker-deals/pw`)}
          >
            <p className="home-page-title">My Deals</p>
            <DescriptionIcon />
          </div>
          <div
            className={clsx(
              userData && userData.is_broker ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/unconfirmed-deals`)}
          >
            <p className="home-page-title">Unconfirmed Deals</p>
            <span
              className="badge-container"
              style={{ height: "56px", marginLeft: "-48px" }}
            >
             <UnconfirmDealsIcon />
              {!!unconfirmed_deal_count && unconfirmed_deal_count > 0 && (
                <span className="badge">
                  <b>{this.props.unconfirmed_deal_count}</b>
                </span>
              )}
            </span>
          </div>
          <div
            className={clsx(
              userData &&
                userData.is_broker &&
                (userData.member_role === 2 ||
                  userData.member_role === 3 ||
                  userData.addons.includes(26) ||
                  userData.addons.includes(32))
                ? "home-page-card"
                : "hidden",
              " home-page-card-container"
            )}
            onClick={() => Navigation.go(`/deal-review-filter`)}
          >
            <p className="home-page-title">Deal Review</p>
            {/* TODO: switch to image in home-icons folder */}
            <DealReviewIcon />
          </div>
          <div
            className={clsx(
              userData ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/properties/deals`)}
          >
            <p className="home-page-title">My Properties</p>
            <BusinessIcon />
          </div>
          <div
            className={clsx(
              userData && userData.is_lender ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/inProcessPipeline`)}
          >
            <p className="home-page-title">In Process Pipeline</p>
            <AssignmentIcon />
          </div>
          <div
            className={clsx(
              userData && userData.is_service_provider
                ? "home-page-card"
                : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/service-provider`)}
          >
            <p className="home-page-title">Service Provider</p>
            <ServiceProviderIcon />
          </div>
          <div
            className={clsx(
              userData && (userData.is_lender || userData.is_qts)  ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/lending-criteria`)}
          >
            <p className="home-page-title">Lending Criteria</p>
            <LendingCriteriaIcon />
          </div>
          <div
            className={
              userData && userData.is_investor  ? "home-page-card" : "hidden"
            }
            onClick={() => Navigation.go(`/investing-criteria`)}
          >
            <p className="home-page-title">Investing Criteria</p>
            <MoneyIcon />
          </div>
          <div
            className={
              userData && userData.is_investor ? "home-page-card" : "hidden"
            }
            onClick={() => Navigation.go(`/fund-portfolio/my-deals`)}
          >
            <p className="home-page-title">My Fund Portfolio</p>
            <MyFundPortfolioIcon />
          </div>
          <div
            className={clsx(
              userData && userData.is_lender ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/loans/my-loans`)}
          >
            <p className="home-page-title">My Bank Portfolio</p>
            <WorkIcon />
          </div>
          <div
            className={clsx(
              userData && (userData.is_lender || userData.is_broker)
                ? "home-page-card"
                : "hidden",
              "home-page-card-container"
            )}
            onClick={() =>
              Navigation.go(
                userData.is_lender
                  ? `/missingQuotes/quote`
                  : `/missingQuotesBroker`
              )
            }
          >
            <p className="home-page-title">Missing Quotes</p>
            <MissingQuotesIcon />
          </div>
          <div
            className={clsx(
              userData && (userData.is_broker || userData.exec_banker)
                ? "home-page-card"
                : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/qts-search`)}
          >
            <p className="home-page-title">Ask QTS</p>
            <AccountBalanceIcon />
          </div>
          <div
            className={clsx(
              userData && userData.is_broker  ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/docs/setup`)}
          >
            <p className="home-page-title">Docs</p>
            <DocsIcon />
          </div>
          <div
            className={clsx(
              userData && userData.is_broker ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/reports`)}
          >
            <p className="home-page-title">Reports</p>
            <DonutLargeIcon />
          </div>
          <div
            className={
              userData && !userData.is_broker && userData.company_id === 2
                ? "home-page-card"
                : "hidden"
            }
            onClick={() => Navigation.go(`/the-ira-group`)}
          >
            <p className="home-page-title">The Ira Group</p>
            <TrendingUpIcon />
          </div>
          {/* <div
            className={clsx(
              userData && userData.is_broker  ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/accounting/summary`)}
          >
            <p className="home-page-title">Accounting</p>
            <CalculateIcon/>
          </div> */}
          <div
            className={clsx(
              userData ? "home-page-card" : "hidden",
              "home-page-card-container"
            )}
            onClick={() => Navigation.go(`/comps`)}
          >
            <p className="home-page-title">Comps</p>
            <CompsIcon />
          </div>
          <div
            className={clsx("home-page-card", "home-page-card-container")}
            onClick={() => Navigation.go(`/e-calc/calculator`)}
          >
            <p className="home-page-title">eCALC</p>
            <EcalcIcon/>
          </div>
          <div
            className={clsx("home-page-card", "home-page-card-container")}
            onClick={() => Navigation.go(`/calculators/DSCRandBalance`)}
          >
            <p className="home-page-title">Other Calculators</p>
            <CalculatorIcon />
          </div>
          <div
            className={clsx("home-page-card", "home-page-card-container")}
            onClick={() => Navigation.go(`/rate-sheet`)}
          >
            <p className="home-page-title">Rates</p>
            <TrendingUpIcon />
          </div>
          {/*<div*/}
            {/*className={clsx(*/}
              {/*userData ? "home-page-card" : "hidden",*/}
              {/*"home-page-card-container"*/}
            {/*)}*/}
            {/*onClick={() => Navigation.go(`/news-feed`)}*/}
          {/*>*/}
            {/*<p className="home-page-title">News Feed</p>*/}
            {/*<img*/}
              {/*src="/images/home-icons/news-feed.png"*/}
              {/*className="inactive-img"*/}
              {/*alt="news-feed-icon"*/}
            {/*/>*/}
            {/*<img*/}
              {/*src="/images/home-icons/news-feed-active.png"*/}
              {/*className="active-img"*/}
              {/*alt="news-feed-icon"*/}
            {/*/>*/}
          {/*</div>*/}
          <div
            className={clsx("home-page-card", "home-page-card-container")}
            onClick={() => Navigation.go(`/eastern-education`)}
          >
            <p className="home-page-title">Eastern Education</p>
           <StoryBookIcon/>
          </div>
        </div>
      </div>
    );
  }
}

// export default PublicLoginPage;

function mapStateToProps(state) {
  const { pwDeals: { unconfirmed_deal_count } = {} } = state;
  return {
    unconfirmed_deal_count,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(navigationTitleActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicLoginPage);
