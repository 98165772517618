import React from "react";
import { connect } from "react-redux";
import * as basicCREActions from "../../../actions/calculatorActions/basicCREActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentageInput from "../calculatorCommon/PercentInput";
import NumberInput from "../calculatorCommon/NumberInput";
import NumberInputWithoutDecimal from "../calculatorCommon/NumberInputWithoutDecimal";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import * as centralStateActions from "../../../actions/calculatorActions/centralActions";
import { bindActionCreators } from "redux";
import { formatCurrency, formatPercentage, isEmpty } from "../../../lib";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";

class BlendedInterestRate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      term: this.props.term || 0,
      firstMortgage: {
        loanAmount: this.props.firstMortgage.loanAmount || 0,
        interestRate: this.props.firstMortgage.interestRate || 0,
        points: this.props.firstMortgage.points || 0,
      },
      mezzanineLoan: {
        loanAmount: this.props.mezzanineLoan.loanAmount || 0,
        interestRate: this.props.mezzanineLoan.interestRate || 0,
        points: this.props.mezzanineLoan.points || 0,
      },
      prefEquity: {
        loanAmount: this.props.prefEquity.loanAmount || 0,
        interestRate: this.props.prefEquity.interestRate || 0,
        points: this.props.prefEquity.points || 0,
      },
      modalOpen: false,
      from: "",
      to: "",
      cc: "",
    };

    this.calculate = this.calculate.bind(this);
    this.firstMortgageChange = this.firstMortgageChange.bind(this);
    this.mezzanineLoanChange = this.mezzanineLoanChange.bind(this);
    this.prefEquityChange = this.prefEquityChange.bind(this);
    this.calculateEffectiveRate = this.calculateEffectiveRate.bind(this);
    this.updateCentralState = this.updateCentralState.bind(this);
  }

  componentDidMount() {
    this.calculate();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.term !== nextProps.term ||
      this.state.firstMortgage !== nextProps.firstMortgage ||
      this.state.mezzanineLoan !== nextProps.mezzanineLoan ||
      this.state.prefEquity !== nextProps.prefEquity
    ) {
      this.setState(
        {
          term: nextProps.term,
          firstMortgage: isEmpty(nextProps.firstMortgage)
            ? this.state.firstMortgage
            : nextProps.firstMortgage,
          mezzanineLoan: isEmpty(nextProps.mezzanineLoan)
            ? this.state.mezzanineLoan
            : nextProps.mezzanineLoan,
          prefEquity: isEmpty(nextProps.prefEquity)
            ? this.state.prefEquity
            : nextProps.prefEquity,
        },
        () => {
          this.calculate();
        }
      );
    }
  }

  calculate() {
    let firstMortgage = { ...this.state.firstMortgage };
    let mezzanineLoan = { ...this.state.mezzanineLoan };
    let prefEquity = { ...this.state.prefEquity };
    Object.keys(firstMortgage).map((d) =>
      d ? (firstMortgage[d] = parseFloat(firstMortgage[d])) : 0
    );
    Object.keys(mezzanineLoan).map((d) =>
      d ? (mezzanineLoan[d] = parseFloat(mezzanineLoan[d])) : 0
    );
    Object.keys(prefEquity).map((d) =>
      d ? (prefEquity[d] = parseFloat(prefEquity[d])) : 0
    );
    let firstMortgageEffectiveRate = this.calculateEffectiveRate(
      firstMortgage.interestRate,
      firstMortgage.points
    );
    let mezzanineLoanEffectiveRate = this.calculateEffectiveRate(
      mezzanineLoan.interestRate,
      mezzanineLoan.points
    );
    let prefEquityEffectiveRate = this.calculateEffectiveRate(
      prefEquity.interestRate,
      prefEquity.points
    );
    let totalLoanAmount =
      firstMortgage.loanAmount +
      mezzanineLoan.loanAmount +
      prefEquity.loanAmount;
    let blendedInterestRate =
      (firstMortgage.loanAmount * firstMortgage.interestRate +
        mezzanineLoan.loanAmount * mezzanineLoan.interestRate +
        prefEquity.loanAmount * prefEquity.interestRate) /
      totalLoanAmount;
    let effectiveInterestRate =
      (firstMortgage.loanAmount * firstMortgageEffectiveRate +
        mezzanineLoan.loanAmount * mezzanineLoanEffectiveRate +
        prefEquity.loanAmount * prefEquityEffectiveRate) /
      totalLoanAmount;
    this.setState({
      firstMortgageEffectiveRate,
      mezzanineLoanEffectiveRate,
      prefEquityEffectiveRate,
      totalLoanAmount,
      blendedInterestRate,
      effectiveInterestRate,
    });
  }

  calculateEffectiveRate(interestRate, points) {
    let rate = parseFloat(
      interestRate + (points ? points : 0) / (this.state.term / 12)
    );
    return rate === Infinity ? null : rate;
  }

  updateCentralState(name, value) {
    this.props.centralActions.updateCentralValues(name, value);
  }

  firstMortgageChange(name, value) {
    let firstMortgage = { ...this.state.firstMortgage };
    firstMortgage[name] = value || 0;
    this.setState(firstMortgage, function () {
      this.props.centralActions.updateCentralValues(
        "firstMortgage",
        firstMortgage
      );
    });
  }

  mezzanineLoanChange(name, value) {
    let mezzanineLoan = { ...this.state.mezzanineLoan };
    mezzanineLoan[name] = value || 0;
    this.setState(mezzanineLoan, function () {
      this.props.centralActions.updateCentralValues(
        "mezzanineLoan",
        mezzanineLoan
      );
    });
  }

  prefEquityChange(name, value) {
    let prefEquity = { ...this.state.prefEquity };
    prefEquity[name] = value || 0;
    this.setState(prefEquity, function () {
      this.props.centralActions.updateCentralValues("prefEquity", prefEquity);
    });
  }

  openSharingCalculatorModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateCC(value) {
    this.setState({ cc: value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ modalOpen: false }, function () {
      let {
        term,
        firstMortgage,
        mezzanineLoan,
        prefEquity,
        firstMortgageEffectiveRate,
        mezzanineLoanEffectiveRate,
        prefEquityEffectiveRate,
        totalLoanAmount,
        blendedInterestRate,
        effectiveInterestRate,
      } = this.state;
      var input = [
        { input0: term.toString() },
        { input1: formatCurrency(firstMortgage.loanAmount) },
        { input2: formatPercent(firstMortgage.interestRate) },
        { input3: firstMortgage.points },
        { input4: formatPercent(firstMortgageEffectiveRate) },
        { input5: formatCurrency(mezzanineLoan.loanAmount) },
        { input6: formatPercent(mezzanineLoan.interestRate) },
        { input7: mezzanineLoan.points },
        { input8: formatPercent(mezzanineLoanEffectiveRate) },
        { input9: formatCurrency(prefEquity.loanAmount) },
        { input10: formatPercent(prefEquity.interestRate) },
        { input11: prefEquity.points },
        { input12: formatPercent(prefEquityEffectiveRate) },
      ];
      var output = [
        { output0: formatCurrency(totalLoanAmount) },
        { output1: formatPercent(blendedInterestRate) },
        { output2: formatPercent(effectiveInterestRate) },
      ];
      this.props.sharingCalculationsActions.calculatorSharing(
        this.state.to,
        this.state.from,
        this.state.cc,
        "BlendedInterestRate",
        input,
        output
      );
    });
  }

  render() {
    let {
      term,
      firstMortgage,
      mezzanineLoan,
      prefEquity,
      firstMortgageEffectiveRate,
      mezzanineLoanEffectiveRate,
      prefEquityEffectiveRate,
      totalLoanAmount,
      blendedInterestRate,
      effectiveInterestRate,
    } = this.state;
    return (
      <div>
        <Row>
          <ShareCalcModal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal.bind(this)}
            onAfterOpen={() => {}}
            onSubmit={this.onSubmit.bind(this)}
            from={this.state.from}
            to={this.state.to}
            cc={this.state.cc}
            updateFrom={this.updateFrom.bind(this)}
            updateTo={this.updateTo.bind(this)}
            updateCC={this.updateCC.bind(this)}
          />
          <Panel className="calculator-card">
            <Form
              className="calculator-form calculator-form-full-width"
              inline={true}
            >
              <h2 className="calculator-title">Blended Interest Rate</h2>

              <p
                className="share-calc"
                onClick={this.openSharingCalculatorModal.bind(this)}
              >
                <i className="material-icons">share</i>Share Calculations
              </p>
              <div className="flex mobile-560-block">
                <div className="box box-top mobile-560-flex-col-center mobile-560-mr-10 max-225-width">
                  <p className="box-title ">Total Loan Amount</p>
                  <p className="amount">
                    <span className="display-value">
                      {formatCurrency(totalLoanAmount, 0, 0)}
                    </span>
                  </p>
                </div>
                <div className="box box-top mobile-560-flex-col-center mobile-560-mr-10 max-225-width">
                  <p className="box-title">Blended Interest Rate</p>
                  <p className="amount">
                    <span className="display-value">
                      {formatPercent(blendedInterestRate || 0, 0, true)}
                    </span>
                  </p>
                </div>
                <div className="box box-top mobile-560-flex-col-center mobile-560-mr-10 max-225-width">
                  <p className="box-title">Effective Rate W/ Points</p>
                  <p className="amount">
                    <span className="display-value">
                      {formatPercent(effectiveInterestRate || 0, 0, true)}
                    </span>
                  </p>
                </div>
              </div>
              <div className="margin-top"></div>
              <div className="">
                <NumberInputWithoutDecimal
                  className="mobile-blended-term"
                  name="term"
                  value={term}
                  update={this.updateCentralState}
                  label="Term (Months)"
                />
              </div>
              <br />
              <h5>1st Mortgage</h5>
              <div className="flex flex-center flex-wrap">
                <DollarInput
                  name="loanAmount"
                  className="narrow-input"
                  value={firstMortgage.loanAmount}
                  update={this.firstMortgageChange}
                  label="Loan Amount"
                />
                <PercentageInput
                  name="interestRate"
                  className="narrow-input"
                  value={firstMortgage.interestRate}
                  update={this.firstMortgageChange}
                  label="Interest Rate"
                />
                <NumberInput
                  name="points"
                  className="narrow-input"
                  value={firstMortgage.points}
                  decimalPlaces={2}
                  update={this.firstMortgageChange}
                  label="Points"
                />
                <div style={{ width: "190px" }} className="flex-space-justify">
                  <p>Effective Rate W/ Points</p>
                  <strong>{formatPercent(firstMortgageEffectiveRate)}</strong>
                </div>
              </div>
              <br />
              <h5>Mezzanine Loan</h5>
              <div className="flex flex-center flex-wrap">
                <DollarInput
                  name="loanAmount"
                  className="narrow-input"
                  value={mezzanineLoan.loanAmount}
                  update={this.mezzanineLoanChange}
                  label="Loan Amount"
                />
                <PercentageInput
                  name="interestRate"
                  className="narrow-input"
                  value={mezzanineLoan.interestRate}
                  update={this.mezzanineLoanChange}
                  label="Interest Rate"
                />
                <NumberInput
                  name="points"
                  className="narrow-input"
                  value={mezzanineLoan.points}
                  decimalPlaces={2}
                  update={this.mezzanineLoanChange}
                  label="Points"
                />
                <div style={{ width: "190px" }} className="flex-space-justify">
                  <p>Effective Rate W/ Points</p>
                  <strong>{formatPercent(mezzanineLoanEffectiveRate)}</strong>
                </div>
              </div>
              <br />
              <h5>Pref Equity</h5>
              <div className="flex flex-center flex-wrap">
                <DollarInput
                  name="loanAmount"
                  className="narrow-input"
                  value={prefEquity.loanAmount}
                  update={this.prefEquityChange}
                  label="Loan Amount"
                />
                <PercentageInput
                  name="interestRate"
                  className="narrow-input"
                  value={prefEquity.interestRate}
                  update={this.prefEquityChange}
                  label="Interest Rate"
                />
                <NumberInput
                  name="points"
                  className="narrow-input"
                  value={prefEquity.points}
                  decimalPlaces={2}
                  update={this.prefEquityChange}
                  label="Points"
                />
                <div style={{ width: "190px" }} className="flex-space-justify">
                  <p>Effective Rate W/ Points</p>
                  <strong>{formatPercent(prefEquityEffectiveRate)}</strong>
                </div>
              </div>
              <br />
              <br />
            </Form>
          </Panel>
        </Row>

        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>{" "}
        </div>
      </div>
    );
  }
}

function formatPercent(value, emptyValue, showTrailingZeros) {
  return formatPercentage(value / 100, emptyValue, showTrailingZeros);
}

function mapStateToProps(state) {
  const state1 = state.centralValues;
  return {
    term: state1.term || "",
    firstMortgage: state1.firstMortgage || {},
    mezzanineLoan: state1.mezzanineLoan || {},
    prefEquity: state1.prefEquity || {},
  };
}

function mapDispatchToProps(dispatch) {
  return {
    basicCreActions: bindActionCreators(basicCREActions, dispatch),
    centralActions: bindActionCreators(centralStateActions, dispatch),
    sharingCalculationsActions: bindActionCreators(
      sharingCalculationsActions,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlendedInterestRate);
