import React from "react";
import { Link, NavLink } from "react-router-dom";
import Row from "muicss/lib/react/row";
import getParameterByName from "../common/getParameterByName";

class DealDetailsContainer extends React.Component {
  render() {
    let violations = getParameterByName("violations");
    let additionalProperties = getParameterByName("additional_properties");
    return (
      <div>
        <Row className="sub-header">
          <Link
            className="back-arrow"
            alt="arrow-left"
            to={decodeURIComponent(getParameterByName("previous_page"))}
          >
            <img src="/images/icons/arrow-left.png" alt="arrow-left" />
          </Link>
          <ul className="deal-details-nav">
            {additionalProperties > 0 ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/MQdeal-details/${this.props.match.params.dealId}/property-details`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Property Details
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {violations >= 0 ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={{
                    pathname: `/MQdeal-details/${this.props.match.params.dealId}/violations`,
                    search: window.location.search,
                  }}
                  activeClassName="active-deal-nav-item"
                >
                  Violations
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
        </Row>
        <div className="deal-padding-top">{this.props.children}</div>
      </div>
    );
  }
}

export default DealDetailsContainer;
