import React from "react";
import Row from "muicss/lib/react/row";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as dealsActions from "../../actions/dealsActions";
import * as navigationTitleActions from "../../actions/navigationTitleActions";
import getParameterByName from "../common/getParameterByName";
import { Navigation } from "../../lib";
import { getObjectKeyByValue } from "../../lib/utils";
import { parse, stringify } from "query-string";
import { DealStatusType } from "../../lib/constants";

class PWIPContainer extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.state = {
      input: "",
      showSearchbar: false,
    };
  }

  componentDidMount() {
    this.props.navActions.updateNavTitle("My Deals");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (this.props.pwDeals !== nextProps.pwDeals &&
        nextProps.pwDeals.length >= 1) ||
      (this.props.ipDeals !== nextProps.ipDeals &&
        nextProps.ipDeals.length >= 1)
    ) {
      this.handleSearch();
    }
  }

  handleSearchChange(e) {
    this.setState(
      {
        input: e.target.value,
      },
      function () {
        this.handleSearch();
      }
    );
  }

  handleSearch() {
    const searchWords = this.state.input.toLowerCase();
    if (searchWords.length > 0) {
      let suggestions = [];
      if (
        window.location.pathname.includes("pw") ||
        window.location.pathname.includes("PW")
      ) {
        for (var i = 0; i < this.props.pwDeals.length; i++) {
          if (
            this.props.pwDeals[i].deal_name &&
            this.props.pwDeals[i].deal_name.toLowerCase().indexOf(searchWords) >
              -1
          ) {
            suggestions.push(this.props.pwDeals[i]);
          } else if (
            this.props.pwDeals[i].full_name &&
            this.props.pwDeals[i].full_name.toLowerCase().includes(searchWords)
          ) {
            suggestions.push(this.props.pwDeals[i]);
          } else if (
            this.props.pwDeals[i].property_address &&
            this.props.pwDeals[i].property_address
              .toLowerCase()
              .includes(searchWords)
          ) {
            suggestions.push(this.props.pwDeals[i]);
          } else if (
            this.props.pwDeals[i].deal_id &&
            this.props.pwDeals[i].deal_id.toString().includes(searchWords)
          ) {
            suggestions.push(this.props.pwDeals[i]);
          } else if (
            this.props.pwDeals[i].brokers.length > 0 &&
            this.props.pwDeals[i].brokers.filter(
              (b) =>
                b.broker_name &&
                b.broker_name.toLowerCase().includes(searchWords)
            ).length > 0
          ) {
            suggestions.push(this.props.pwDeals[i]);
          }
        }
        this.props.dealsActions.updateSearch(
          suggestions,
          getObjectKeyByValue(DealStatusType, DealStatusType.PW)
        );
      }

      if (
        window.location.pathname.includes("ip") ||
        window.location.pathname.includes("IP")
      ) {
        for (var j = 0; j < this.props.ipDeals.length; j++) {
          if (
            this.props.ipDeals[j].deal_name &&
            this.props.ipDeals[j].deal_name.toLowerCase().indexOf(searchWords) >
              -1
          ) {
            suggestions.push(this.props.ipDeals[j]);
          } else if (
            this.props.ipDeals[j].full_name &&
            this.props.ipDeals[j].full_name.toLowerCase().indexOf(searchWords) >
              -1
          ) {
            suggestions.push(this.props.ipDeals[j]);
          } else if (
            this.props.ipDeals[j].property_address &&
            this.props.ipDeals[j].property_address
              .toLowerCase()
              .includes(searchWords)
          ) {
            suggestions.push(this.props.ipDeals[j]);
          } else if (
            this.props.ipDeals[j].deal_id &&
            this.props.ipDeals[j].deal_id.toString().includes(searchWords)
          ) {
            suggestions.push(this.props.ipDeals[j]);
          } else if (
            this.props.ipDeals[j].brokers.length > 0 &&
            this.props.ipDeals[j].brokers.filter(
              (b) =>
                b.broker_name &&
                b.broker_name.toLowerCase().includes(searchWords)
            ).length > 0
          ) {
            suggestions.push(this.props.ipDeals[j]);
          }
        }
        this.props.dealsActions.updateSearch(
          suggestions,
          getObjectKeyByValue(DealStatusType, DealStatusType.IP)
        );
      }
    }
  }

  render() {
    const isMobile =
      window.isNativeApp ||
      window.matchMedia("(display-mode: standalone)").matches ||
      window.innerWidth < 901;
    return (
      <div className="deals-page">
        <Row
          className={`sub-header ${
            this.state.showSearchbar ? "mobile-header-searchbar" : ""
          }`}
        >
          <div
            className={
              getParameterByName("deal_name") ? "back-arrow" : "hidden"
            }
            onClick={() => Navigation.goBack()}
          >
            <img src="/images/icons/arrow-left.png" alt="arrow-left" />
          </div>
          <div className="nav-search-sub-header">
            <div>
              <div>
                <ul>
                  <li className="fixed-width-li">
                    <NavLink
                      to={`/broker-deals/fpw`}
                      activeClassName="header-active-link"
                    >
                      FPW
                    </NavLink>
                  </li>
                  <li className="fixed-width-li">
                    <NavLink
                      to={`/broker-deals/pw`}
                      activeClassName="header-active-link"
                    >
                      PW
                    </NavLink>
                  </li>
                  <li className="fixed-width-li">
                    <NavLink
                      to={`/broker-deals/ip`}
                      activeClassName="header-active-link"
                    >
                      IP
                    </NavLink>
                  </li>
                  <li className="fixed-width-li">
                    <NavLink
                      to={`/broker-deals/uw`}
                      activeClassName="header-active-link"
                    >
                      UW
                    </NavLink>
                  </li>
                </ul>
              </div>
              {!isMobile ? (
                <div className="flex flex-center">
                  <div className="search-container">
                    <div className="search-form">
                      <img
                        id="search-icon-small"
                        src="/images/icons/search.png"
                        alt="search"
                      />
                      <input
                        onChange={this.handleSearchChange.bind(this)}
                        value={this.state.input}
                        className="input"
                        type="text"
                        placeholder="Search by Deal Name, Deal ID, Client Name, Broker..."
                        ref="searchBox"
                      />
                    </div>
                  </div>
                  <p
                    onClick={() => {
                      Navigation.go(
                        `/search-deals?${stringify({
                          ...parse(window.location.search),
                          search: this.state.input,
                          previous_page: window.location.pathname,
                        })}`
                      );
                    }}
                    className="orange-text"
                    style={{
                      marginLeft: "16px",
                      textAlign: "center",
                      minWidth: "63px",
                    }}
                  >
                    Search All
                  </p>
                </div>
              ) : (
                <div>
                  <img
                    id="search-icon-small"
                    src="/images/icons/search.png"
                    alt="search"
                    style={{ float: "right" }}
                    onClick={() =>
                      this.setState({
                        showSearchbar: !this.state.showSearchbar,
                      })
                    }
                  />
                </div>
              )}
            </div>
          </div>

          <Row style={{ width: "96%" }}>
            <div className="flex flex-center">
              <div className="search-container search-container-mobile">
                <div className="search-form">
                  <img
                    id="search-icon-small"
                    src="/images/icons/search.png"
                    alt="search"
                  />
                  <input
                    onChange={this.handleSearchChange.bind(this)}
                    value={this.state.input}
                    className="input"
                    type="text"
                    placeholder="Search Deals..."
                    ref="searchBox"
                  />
                </div>
              </div>
              <div className="info-icon-container">
                <i
                  className="material-icons"
                  style={{ color: "#fff", fontSize: "20px" }}
                >
                  info
                </i>
                <span className="info-tooltip">
                  Search by Deal Name, Deal ID, Client Name, Broker...
                </span>
              </div>
              <p
                onClick={() => {
                  Navigation.go(
                    `/search-deals?${stringify({
                      ...parse(window.location.search),
                      search: this.state.input,
                      previous_page: window.location.pathname,
                    })}`
                  );
                }}
                className="orange-text"
                style={{
                  marginTop: "13px",
                  marginLeft: "16px",
                  textAlign: "center",
                  minWidth: "63px",
                }}
              >
                Search All
              </p>
            </div>
          </Row>
        </Row>
        <div className="contacts-container">{this.props.children}</div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    pwDeals: state.pwDeals.pwDeals,
    ipDeals: state.ipDeals.ipDeals,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dealsActions: bindActionCreators(dealsActions, dispatch),
    navActions: bindActionCreators(navigationTitleActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PWIPContainer);
