import mixpanel from "mixpanel-browser";

//dont track for development
mixpanel.init("246052a24c96a9a16f32a3955e161221", {
  opt_out_tracking_by_default:
    process.env.REACT_APP_TURNOFF_MIX_TRACKING === "true",
});

export function mixpanelAliasPerson(userData) {
  mixpanel.alias(userData.app_user_id.toString());
  mixpanelRegisterPerson(userData);
}

export function mixpanelRegisterPerson(userData) {
  const person = {
    $email: userData.email || "",
    $distinct_id: userData.app_user_id.toString(),
    $first_name: userData.first_name || "",
    $last_name: userData.last_name || "",
    $name: userData.username,
  };

  const dataToSendUnclean = { ...userData, ...person };
  const {
    email,
    first_name,
    last_name,
    username,
    ...dataToSend
  } = dataToSendUnclean;
  // Should we use set_once() instead of set()?
  mixpanel.people.set(dataToSend);

  mixpanel.identify(userData.app_user_id.toString());
}

export function mixpanelTrackLoc(location) {
  mixpanel.track("Location Change", { location });
}

/**
 * Add a value or update an exising value on the mixpanel object.
 * @param {string} property
 * @param {*} value
 */
export function addOrUpdateMixpanelValue(property, value) {
  mixpanel.register({ [property]: value });
}

export function registerGlobalData() {
  const is_logged_in = !!(
    localStorage.getItem("auth_timestamp") && localStorage.getItem("auth_token")
  );
  mixpanel.register({ isNative: !!window.isNativeApp, is_logged_in });
}
