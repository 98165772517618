import * as types from "../actions/actionTypes";

const initialState = {
  spActiveDeals: [],
  loading: true,
  toastShowing: false,
};

export default function serviceProviderReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_SERVICE_PROVIDER_SUCCESS:
      return Object.assign({}, initialState, { loading: true });
    case types.LOAD_SERVICE_PROVIDER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        spActiveDeals: action.data,
      });
    case types.NO_VIOLATIONS_FEEDBACK_TOAST:
      return Object.assign({}, state, { toastShowing: true });
    case types.CLOSE_VIOLATIONS_FEEDBACK_TOAST:
      return Object.assign({}, state, { toastShowing: false });
    default:
      return state;
  }
}
