import React from "react";
import Row from "muicss/lib/react/row";
import { NavLink } from "react-router-dom";

class fundPortfolioHeader extends React.Component {
  render() {
    return (
      <div className="salesbroker-deals-page">
        <Row className="sub-header">
          <div className="salesbroker-deals-subheader">
            <ul>
              <li className="mq-list-item">
                <NavLink
                  to={`/fund-portfolio/my-deals`}
                  activeClassName="header-active-link"
                >
                  My Deals
                </NavLink>
              </li>
              <li className="mq-list-item">
                <NavLink
                  to={`/fund-portfolio/closed-deals`}
                  activeClassName="header-active-link"
                >
                  Closed Deals
                </NavLink>
              </li>
            </ul>
          </div>
        </Row>
        <div className="salesbroker-content-container">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default fundPortfolioHeader;
