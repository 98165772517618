import { makeStyles } from "@material-ui/core";
import { ECALC_SHOW_SIDE_RESULTS } from "../../../lib";

const desktopSize = ECALC_SHOW_SIDE_RESULTS.match(/[0-9]+/g);

export const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: (props) => (props.isDealDetails ? 57 : 0),
    zIndex: 100,
  },
  toolbar: {
    position: "fixed !important",
    justifyContent: "flex-end",
    background: "#00112B",
    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
    height: 44,
    minHeight: 44,
    width: "calc(100% - 224px)",
    [theme.breakpoints.up(desktopSize)]: {
      justifyContent: "flex-start",
      background: "rgba(244, 244, 244,0.7)",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.12)",
      height: 61,
      minHeight: 61,
    },
  },
  iconContainer: {
    display: "flex",
    // alignItems: "center",
    flexDirection: "column",
    height: 34,
  },
  iconBtn: {
    marginRight: 20,
    borderRadius: 4,
    background: "rgba(255, 255, 255, 0.9)",
    width: 35,
    height: 35,
    "&:hover": {
      background: "rgba(255, 255, 255, 0.9)",
    },
    [theme.breakpoints.down(desktopSize)]: {
      width: 24,
      height: 24,
      background: "inherit",
      "&:hover": {
        background: "inherit",
      },
    },
  },
  icon: {
    color: "#00112B",
    [theme.breakpoints.down(desktopSize)]: {
      color: "#FFFFFF !important",
    },
  },
  iconText: {
    color: "#FFFFFF",
    fontSize: 10,
  },
  disabled: {
    opacity: 0.3,
  },
}));
