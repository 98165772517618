export const styles = (theme) => ({
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  listItem: {
    flexWrap: "wrap",
    paddingBottom: 1,
    paddingTop: 14,
  },
  valuesKey: {
    fontSize: 16.9198,
    color: "#00112B",
    opacity: 0.85,
    fontStyle: "italic",
    [theme.breakpoints.down(500)]: {
      fontSize: 10,
    },
  },
  divider: {
    background: "#EFEFEF",
  },
  fabWrapper: {
    width: "100%",
    textAlign: "right",
  },
  hidden: {
    display: "none",
  },
});
