import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";

export default function useAddressPredictions(input) {
  const [predictions, setPredictions] = useState([]);

  const autocomplete = useRef();

  if (!autocomplete.current && window.google) {
    autocomplete.current = new window.google.maps.places.AutocompleteService();
  }

  function getPlacePredictions(input) {
    input
      ? autocomplete.current.getPlacePredictions(
          {
            input,
            componentRestrictions: { country: "us" },
            types: ["address"],
          },
          (predictions) => {
            setPredictions(predictions || []);
          }
        )
      : setPredictions([]);
  }

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 200),
    []
  );

  useEffect(() => {
    debouncedGetPlacePredictions(input);
  }, [debouncedGetPlacePredictions,input]);

  return predictions || [];
}
