import { ajax } from "./ajax";
import config from "../config";
import moment from "moment";
import mapKeys from "lodash/mapKeys";
import { tokenExpired } from "../actions/authenticationActions";

export function formatRequestBodyParams(params) {
  for (var propertyName in params) {
    if (params.hasOwnProperty(propertyName)) {
      let property = params[propertyName];
      if (typeof property === "object") {
        params[propertyName] = JSON.stringify(property);
      } else if (typeof property === "boolean") {
        params[propertyName] = property ? 1 : 0;
      }
    }
  }
  return params;
}

export function formatTotalComps(totalComps) {
  if (totalComps != null) {
    if (totalComps > 500) {
      return "500+";
    }
    return totalComps;
  }

  return "--";
}

export function formatAddress(address1, address2, city, state, zip) {
  let formattedAddress = "";
  if (address1) {
    formattedAddress += address1.replace(/\s*$/, "") + " ";
  }
  if (address2) {
    formattedAddress += address2.replace(/\s*$/, "") + " ";
  }
  if (city) {
    formattedAddress += city.replace(/\s*$/, "") + " ";
  }
  if (state) {
    if (formattedAddress.trim().length > 0) {
      formattedAddress = formattedAddress.trim() + ", ";
    }
    formattedAddress += state.replace(/\s*$/, "") + " ";
  }
  if (zip) {
    formattedAddress += zip.replace(/\s*$/, "");
  }
  return formattedAddress;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0 ? true : false;
}

export function formatDate(dateString, emptyValue) {
  let formattedDate = moment(dateString).isValid()
    ? moment(dateString).format("MM/DD/YYYY")
    : emptyValue || "";
  return formattedDate;
}

export function formatWithCommas(value, emptyValue = "") {
  if (!value) {
    return emptyValue;
  } else {
    const splitByDecimal = `${value}`.split(".");
    const beforeDecimal = splitByDecimal[0].replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    );
    const afterDecimal =
      splitByDecimal.length > 1 ? "." + splitByDecimal[1] : "";
    return beforeDecimal + afterDecimal;
  }
}

export function formatCurrency(amnt, decimalPlaces, emptyValue, round) {
  if (isNaN(amnt) || amnt === null || amnt === "") {
    return emptyValue || "";
  } else if (amnt === Infinity || amnt === "Infinity") {
    amnt = 0;
  }

  let roundedValue =
    amnt !== 0
      ? "$" + +parseFloat(amnt).toFixed(decimalPlaces >= 0 ? decimalPlaces : 2)
      : "$0"; //add $ and rounds
  if (round && amnt !== 0) {
    roundedValue = "$" + +Math.round(parseFloat(amnt));
  }
  let formattedValue = roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //format with commas
  return formattedValue;
}

export function formatPercentage(value, emptyValue, showTrailingZeros = false) {
  if (isNaN(value) || value === null) {
    return emptyValue || "";
  }
  value = value * 100;
  let roundedValue =
    value !== 0
      ? (showTrailingZeros
          ? parseFloat(value).toFixed(2)
          : +parseFloat(value).toFixed(2)) + "%"
      : "0%"; //round and add %
  let formattedValue = roundedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ","); //format with commas
  return formattedValue;
}
/** Standard API error handler.
 * @param {(action: object)=> void} dispatch Redux dispatch method.
 * @param {{[code: number]: object}} [errorMap] Map of error codes to actions.
 */
export function handleAPIError(dispatch, errorMap = {}) {
  /** @param {JQuery.jqXHR<any>} response Error response from jQuery. */
  return function handlingAPIError(response) {
    if (typeof errorMap === "string") {
      errorMap = {
        default: errorMap,
      };
    }
    const httpStatus = response.status;
    // Find ACTION_TYPE or object to use for this error.
    let mapping = errorMap[httpStatus];
    if (!mapping) {
      // No ACTION_TYPE/object found, see if its a standard error.
      switch (httpStatus) {
        case 401:
        case 403:
          dispatch(tokenExpired());
          return;
        default:
          break;
      }
      // Otherwise, use the default mapping.
      mapping = errorMap.default;
      if (!mapping) {
        console.log("NO DEFAULT MAPPING FOR API ERROR: " + httpStatus);
        return;
      }
    }
    // Now just dispatch the ACTION_TYPE/object based on the response.

    handleError(response);
    const isString = typeof mapping === "string";
    const type = isString ? mapping : mapping.type;
    const props = isString ? {} : mapping;
    const data = response.data;
    dispatch({
      type,
      success: false,
      message: data.errors
        ? convertErrorObjToString(data.errors)
        : data.message,
      ...props,
    });
  };
}

function handleError(error) {
  const err = error || new Error("Unknown error.");
  const {
    status = -500,
    statusText = "Unknown",
    data = { message: "Unknown error." },
  } = err;
  if (status === 500) {
    console.log("API GOT 500 Error");
  } else {
    console.log(
      JSON.stringify({
        status,
        statusText,
        data,
      })
    );
  }
}

export function convertErrorObjToString(obj) {
  let error = [];
  if (typeof obj === "object") {
    Object.keys(obj).map((err) => {
      let value = obj[err];
      if (Array.isArray(value)) {
        value.map((e) => error.push(e));
      } else {
        error.push(value);
      }
      return error;
    });
  }
  return error.join(", ");
}

export async function getListByType(type) {
  let response = await ajax({
    url: `${config.BASE_URL}lists/${type}`,
  });

  return response.data ? response.data : [];
}

export function getObjectKeyByValue(obj, value) {
  return obj ? Object.keys(obj).find((i) => obj[i] === value) : null;
}

export function renameObjKey({ object, oldKey, newKey }) {
  return mapKeys(object, (value, key) => {
    return key === oldKey ? newKey : key;
  });
}

export async function getDealIdsFromCustomReport(customeReportId) {
  let response = await ajax({
    url: `${config.BASE_URL}get-report?sr_id=${customeReportId}`,
    applyAuthHeader: true,
  });
  return (response.data && response.data.results[0][0][0].field_name === 'deal_id') ?
    response.data.results[0].map(deal => deal[0].value) : [];

}
