import React from "react";
import moment from "moment";
import Select from "react-select";

import { formatWithCommas } from "../../lib";

import ClientModal from "../common/ClientModal";
import NoteModal from "../common/NoteModal";

class DealReviewSidebar extends React.PureComponent {
  state = {
    contactDetailsModalOpen: false,
    deal: {},
    bankQuotes: {},
    noteModalOpen: false,
  };

  componentDidMount() {
    this.setState({ deal: this.props.deal });
    this.getDealBankQuotes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.deal !== this.props.deal) {
      this.setState({ deal: this.props.deal });
    }
    if (prevProps.dealDetails !== this.props.dealDetails) {
      if (this.props.dealDetails.bankQuotes) {
        this.getDealBankQuotes();
      }
    }
  }

  getDealBankQuotes = () => {
    const { dealDetails } = this.props;
    if (
      dealDetails &&
      dealDetails.bankQuotes &&
      dealDetails.bankQuotes.length > 0
    ) {
      this.setState({
        bankQuotes: {
          quoted:
            dealDetails &&
            dealDetails.bankQuotes.filter((quote) => quote.quoteStatus === 2),
          waiting:
            dealDetails &&
            dealDetails.bankQuotes.filter(
              (quote) => quote.quoteStatus === 1 || quote.quoteStatus === 7
            ),
          passed:
            dealDetails &&
            dealDetails.bankQuotes.filter(
              (quote) => quote.quoteStatus === 3 || quote.quoteStatus === 4
            ),
        },
      });
    } else {
      this.setState({ bankQuotes: {} });
    }
  };

  updateDealReviewCity = (dealID, property, updateProperty, updateValue) => {
    let index = this.state.deal.properties.indexOf(property);
    let properties = [...this.state.deal.properties];
    properties[index] = {
      ...properties[index],
      [updateProperty]: updateValue,
    };
    let updatedDeal = {
      ...this.state.deal,
      properties,
    };
    this.setState({ deal: updatedDeal });
    this.props.updateDealReviewPropertyDebounce(
      dealID,
      property,
      updateProperty,
      updateValue
    );
  };

  getPrimaryContactEmail = (contact) => {
    let contactEmails =
      (contact &&
        contact.contact_info.filter(
          (contactInfo) => contactInfo.contact_category === "email"
        )) ||
      [];
    let primaryEmail =
      contactEmails.filter((email) => email.is_primary === 1) || [];
    if (primaryEmail[0] || contactEmails[0]) {
      return (
        <span className="orange-text">
          {primaryEmail.length > 0 ? (
            <a
              className="link-text orange-text"
              href={`mailto:${primaryEmail[0].description}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {primaryEmail[0].description}
            </a>
          ) : (
            contactEmails[0] && (
              <a
                className="link-text orange-text"
                href={`mailto:${contactEmails[0].description}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {contactEmails[0].description}
              </a>
            )
          )}{" "}
          |
        </span>
      );
    }
  };

  getPrimaryContactPhone = (contact) => {
    let contactPhones =
      (contact &&
        contact.contact_info.filter(
          (contactInfo) => contactInfo.contact_category === "phone"
        )) ||
      [];
    let primaryPhone =
      contactPhones.filter((phone) => phone.is_primary === 1) || [];
    if (primaryPhone[0] || contactPhones[0]) {
      return primaryPhone.length > 0 ? (
        <a
          className="link-text orange-text"
          href={`tel:${primaryPhone[0].description}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {primaryPhone[0].description}
        </a>
      ) : (
        contactPhones[0] && (
          <a
            className="link-text orange-text"
            href={`tel:${contactPhones[0].description}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {contactPhones[0].description}
          </a>
        )
      );
    }
  };

  openContactDetailsModal = async (id, name) => {
    await this.props.getFullClientInfo(id);
    this.setState({
      contactDetailsModalOpen: true,
      contactDetailsModalName: name,
    });
  };

  openNoteModal = () => {
    this.setState({ noteModalOpen: true });
  };

  handleAddNoteSubmit = (e, note) => {
    e.preventDefault();
    this.setState({ noteModalOpen: false });
    this.props.addDealReviewNote(this.state.deal.deal_id, note);
  };

  render() {
    const {
      onClose,
      open,
      propertyTypes,
      states,
      updateDealReview,
      updateDealReviewDebounce,
      updateDealReviewProperty,
      updateDealReviewPropertyDebounce,
    } = this.props;
    const { deal, bankQuotes } = this.state;
    //deal client info
    const primaryContact =
      deal.contacts &&
      deal.contacts.filter((contact) => contact.is_primary === 1)[0];
    return (
      <React.Fragment>
        <div className={`deal-review-sidebar ${open ? "open" : ""}`}>
          <div className="deal-review-sidebar-subheader flex flex-center">
            <i
              className="material-icons white link-text"
              onClick={() => onClose()}
            >
              arrow_back
            </i>

            <p className="medium-text ml-16">Deal Details</p>
          </div>
          <div className="flex flex-space-justify mb-24">
            <p className="small-text bold-text">ID: {deal.deal_id}</p>
            <i
              className="material-icons accent-text-dark link-text deal-review-sidebar-close-icon"
              onClick={() => onClose()}
            >
              close
            </i>
          </div>
          <div>
            <div className="mb-24">
              <label className="small-text accent-text block">Deal Name</label>
              <input
                type="text"
                className="deal-review-input full-width"
                defaultValue={deal.deal_name || ""}
                onChange={(e) =>
                  updateDealReviewDebounce(
                    deal.deal_id,
                    "deal_name",
                    e.target.value,
                    true
                  )
                }
              />
            </div>
            <div className="flex">
              {deal.deal_status === "2" && (
                <div className="mr-16 column-half">
                  <label className="small-text accent-text block">
                    Forecast
                  </label>
                  <Select
                    className="deal-review-filter-form-select"
                    classNamePrefix="deal-review-filter-form-select"
                    onChange={(option) =>
                      updateDealReview(
                        deal.deal_id,
                        "inprocess_line",
                        option.value,
                        true
                      )
                    }
                    options={[
                      {
                        label: "Above the Line",
                        value: "above",
                      },
                      {
                        label: "Below the Line",
                        value: "below",
                      },
                    ]}
                    defaultValue={{
                      label:
                        deal.forecast === "above"
                          ? "Above the Line"
                          : "Below the Line",
                      value: deal.forecast,
                    }}
                  />
                </div>
              )}
              {(deal.deal_status === "1" || deal.deal_status === "2") &&
                deal.deal_type === "Acquisition Loan" && (
                  <div className="column-half">
                    <label className="small-text accent-text block">
                      In Contract
                    </label>
                    <Select
                      className="deal-review-filter-form-select"
                      classNamePrefix="deal-review-filter-form-select"
                      onChange={(option) =>
                        updateDealReview(
                          deal.deal_id,
                          "in_contract",
                          option.value,
                          true
                        )
                      }
                      options={[
                        {
                          label: "Yes",
                          value: 1,
                        },
                        {
                          label: "No",
                          value: 2,
                        },
                        {
                          label: "Blank",
                          value: 0,
                        },
                      ]}
                      defaultValue={{
                        label: deal.in_contract_value,
                        value: deal.in_contract,
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
          <hr className="deal-details-sidebar-divider" />
          {deal.deal_status === "1" &&
            bankQuotes &&
            Object.keys(bankQuotes).length > 0 && (
              <React.Fragment>
                <div>
                  {bankQuotes.quoted && bankQuotes.quoted.length > 0 && (
                    <div className="mb-24">
                      <p className="medium-text bold-text mb-8">Quoted</p>
                      {bankQuotes.quoted.map((quote) => (
                        <div key={quote.quoteId} className="mb-8">
                          <label className="small-text accent-text block">
                            {quote.bankName}
                          </label>
                          <p>
                            {quote.quotedAmount} | {quote.rate} | {quote.term}/
                            {quote.amortization}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                  {bankQuotes.waiting && bankQuotes.waiting.length > 0 && (
                    <div className="mb-24">
                      <p className="medium-text bold-text mb-8">
                        Waiting/Waiting on EU
                      </p>

                      {bankQuotes.waiting.map((quote) => (
                        <p
                          key={quote.quoteId}
                          className="small-text accent-text block"
                        >
                          {quote.bankName}
                        </p>
                      ))}
                    </div>
                  )}
                  {bankQuotes.passed && bankQuotes.passed.length > 0 && (
                    <div className="mb-24">
                      <p className="medium-text bold-text mb-8">Passed</p>
                      <p>
                        {bankQuotes.passed.map((quote) => (
                          <span
                            key={quote.quoteId}
                            className="small-text accent-text deal-review-details-bank-quote mr-4"
                          >
                            {quote.bankName}
                          </span>
                        ))}
                      </p>
                    </div>
                  )}
                </div>
                <hr className="deal-details-sidebar-divider" />
              </React.Fragment>
            )}

          {deal.properties &&
            deal.properties.map((property, i) => (
              <React.Fragment key={property.property_id}>
                <div>
                  <p className="medium-text bold-text mb-24">
                    Property Address{" "}
                    {deal.properties.length > 1 && <span>{i + 1}</span>}
                  </p>
                  <div className="flex mb-24">
                    <div className="column-half mr-8">
                      <label className="small-text accent-text block">
                        Street No
                      </label>
                      <input
                        type="text"
                        className="deal-review-input full-width"
                        defaultValue={property.property_street_number}
                        onChange={(e) =>
                          updateDealReviewPropertyDebounce(
                            deal.deal_id,
                            property,
                            "property_street_number",
                            e.target.value,
                            true
                          )
                        }
                      />
                    </div>
                    <div className="column-half ml-8">
                      <label className="small-text accent-text block">
                        Street Name
                      </label>
                      <input
                        type="text"
                        className="deal-review-input full-width"
                        defaultValue={property.property_street_name}
                        onChange={(e) =>
                          updateDealReviewPropertyDebounce(
                            deal.deal_id,
                            property,
                            "property_street_name",
                            e.target.value,
                            true
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="flex mb-24">
                    <div className="column-half mr-8">
                      <label className="small-text accent-text block">
                        City
                      </label>
                      <input
                        type="text"
                        className="deal-review-input full-width"
                        value={property.property_city}
                        onChange={(e) =>
                          this.updateDealReviewCity(
                            deal.deal_id,
                            property,
                            "property_city",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="column-half ml-8">
                      <label className="small-text accent-text block">
                        State
                      </label>
                      <Select
                        className="deal-review-filter-form-select"
                        classNamePrefix="deal-review-filter-form-select"
                        onChange={(option) =>
                          updateDealReviewProperty(
                            deal.deal_id,
                            property,
                            "property_state",
                            option.data.abb
                          )
                        }
                        options={
                          states &&
                          states.map((state) => ({
                            key: state.state,
                            value: state.state,
                            label: state.state,
                            data: state,
                          }))
                        }
                        value={{
                          label: property.property_state,
                          value: property.property_state,
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex mb-24">
                    <div className="column-half mr-8">
                      <label className="small-text accent-text block">
                        Zip
                      </label>
                      <input
                        type="text"
                        className="deal-review-input full-width"
                        defaultValue={property.property_zip}
                        onChange={(e) =>
                          updateDealReviewPropertyDebounce(
                            deal.deal_id,
                            property,
                            "property_zip",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="column-half ml-8">
                      <label className="small-text accent-text block">
                        Property Type
                      </label>
                      <Select
                        className="deal-review-filter-form-select"
                        classNamePrefix="deal-review-filter-form-select"
                        onChange={(option) =>
                          updateDealReviewProperty(
                            deal.deal_id,
                            property,
                            "property_type_id",
                            option.data.list_type_id
                          )
                        }
                        options={
                          propertyTypes &&
                          propertyTypes.map((propertyType) => ({
                            key: propertyType.value,
                            value: propertyType.value,
                            label: propertyType.value,
                            data: propertyType,
                          }))
                        }
                        defaultValue={{
                          label: property.property_type_value,
                          value: property.property_type_value,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <hr className="deal-details-sidebar-divider" />
              </React.Fragment>
            ))}

          <div>
            <div className="mb-24">
              <label className="small-text accent-text block">
                Client Info
              </label>
              <p>{primaryContact ? primaryContact.full_name : "--"}</p>
              <p>
                {this.getPrimaryContactEmail(primaryContact)}
                {this.getPrimaryContactPhone(primaryContact)}
              </p>
            </div>
            <div className="mb-24">
              <label className="small-text accent-text block">Brokers</label>
              <p>
                {deal &&
                  deal.deals_brokers &&
                  deal.deals_brokers.map((broker) => (
                    <span
                      className="deal-review-details-broker mr-8"
                      key={broker.broker_id}
                    >
                      {broker.first_name + " " + broker.last_name}
                    </span>
                  ))}
              </p>
            </div>
          </div>
          <hr className="deal-details-sidebar-divider" />
          {deal.deal_status === "2" && deal.rewarded_quote_bank_id && (
            <React.Fragment>
              <div>
                <p className="medium-text bold-text mb-24">
                  Rewarded Quote Info
                </p>
                <div className="rewarded-quote-info-grid mb-24">
                  {deal.rewarded_quote_bank_name && (
                    <div>
                      <label className="small-text accent-text block">
                        Bank
                      </label>
                      <p>{deal.rewarded_quote_bank_name}</p>
                    </div>
                  )}
                  {deal.rewarded_quote_banker_name && (
                    <div>
                      <label className="small-text accent-text block">
                        Banker
                      </label>
                      <p
                        onClick={() =>
                          this.openContactDetailsModal(
                            deal.rewarded_quote_banker_id,
                            deal.rewarded_quote_banker_name
                          )
                        }
                        className="orange-hover"
                      >
                        {deal.rewarded_quote_banker_name}
                      </p>
                    </div>
                  )}
                  {deal.rewarded_quote_underwriter_name && (
                    <div>
                      <label className="small-text accent-text block">
                        Underwriter
                      </label>
                      <p
                        onClick={() =>
                          this.openContactDetailsModal(
                            deal.rewarded_quote_underwriter_id,
                            deal.rewarded_quote_underwriter_name
                          )
                        }
                        className="orange-hover"
                      >
                        {deal.rewarded_quote_underwriter_name}
                      </p>
                    </div>
                  )}
                  {deal.rewarded_quote_quoted_amount && (
                    <div>
                      <label className="small-text accent-text block">
                        Quoted Amount
                      </label>
                      <p>{`$${formatWithCommas(
                        deal.rewarded_quote_quoted_amount
                      )}`}</p>
                    </div>
                  )}
                  {deal.rewarded_quote_rate && (
                    <div>
                      <label className="small-text accent-text block">
                        Rate
                      </label>
                      <p>{deal.rewarded_quote_rate}%</p>
                    </div>
                  )}
                  {deal.rewarded_quote_term && (
                    <div>
                      <label className="small-text accent-text block">
                        Term
                      </label>
                      <p>{deal.rewarded_quote_term}</p>
                    </div>
                  )}
                  {deal.rewarded_quote_amortization ? (
                    <div>
                      <label className="small-text accent-text block">
                        Amortization
                      </label>
                      <p>{deal.rewarded_quote_amortization}</p>
                    </div>
                  ) : null}
                </div>
              </div>
              <hr className="deal-details-sidebar-divider" />
            </React.Fragment>
          )}
          <div>
            {deal.last_note && (
              <div>
                <label className="small-text accent-text block">
                  Last Note
                </label>
                <p className="mb-4">{deal.last_note}</p>
                <i className="xxs-text accent-text">
                  Entered on{" "}
                  {moment(deal.last_note_entered_at).format("MM/DD/YYYY")} by{" "}
                  {deal.last_note_entered_by_first_name}{" "}
                  {deal.last_note_entered_by_last_name}
                </i>
              </div>
            )}
            <p
              className="orange-text uppercase-text bold-text flex flex-center mt-24 link-text"
              onClick={() => this.openNoteModal()}
            >
              <i className="material-icons icon-left medium-text middle">
                add_circle
              </i>
              Add Note
            </p>
          </div>
        </div>
        {this.state.contactDetailsModalOpen && (
          <ClientModal
            isOpen={this.state.contactDetailsModalOpen}
            onRequestClose={() =>
              this.setState({ contactDetailsModalOpen: false })
            }
            onAfterOpen={() => ({})}
            name={this.state.contactDetailsModalName}
            loading={this.props.client.loading}
            contact={this.props.client.details}
            noContact={this.props.noContact}
          />
        )}
        {this.state.noteModalOpen && (
          <NoteModal
            isOpen={this.state.noteModalOpen}
            onRequestClose={this.closeAddNoteModal}
            onAfterOpen={this.afterOpenModal}
            require={true}
            text={{ title: "Add note", button: "Save" }}
            onSubmit={this.handleAddNoteSubmit}
          />
        )}
      </React.Fragment>
    );
  }
}

export default DealReviewSidebar;
