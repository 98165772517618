import React, { memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, Snackbar, IconButton } from "@material-ui/core";
import { hideNotification } from "../../actions/uiActions";
import { CloseIcon } from "../../themes";

const useStyles = makeStyles((theme) => ({
  loadingBackdrop: {
    backgroundColor: "transparent",
    zIndex: theme.zIndex.modal + 100,
  },
  error: {
    background: theme.palette.error.light,
  },
  warning: {
    background: theme.palette.warning.light,
  },
}));

export const Notification = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.ui.notification);
  const { message, variant, duration = 6000 } = notification || {};

  const onClose = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      dispatch(hideNotification());
    },
    [dispatch]
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={duration}
      open={!!notification}
      onClose={onClose}
      ContentProps={{
        "aria-describedby": "notification-message",
        classes: { root: classes[variant] },
      }}
      message={<span id="notification-message">{message}</span>}
      action={
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  );
});
