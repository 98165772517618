import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  overrideSnackbar: {
    fontFamily: "Karla",
    fontSize: 13,
    background: "#EFEFEF",
    borderRadius: "5.68493px",
    color: "#00112B",
  },
  overrideMsg: {
    marginLeft: 10,
  },
  overrideSaveBtn: {
    marginRight: "unset",
    paddingLeft: "unset",
  },
}));
