import { makeStyles } from "@material-ui/core";
import { overlayStyles, calculatorInputs } from "../../sharedStyles";
export const useStyles = makeStyles((theme) => ({
  ...overlayStyles(theme),
  ...calculatorInputs(theme),
  dialog: {
    width: "100%",
    maxWidth: (props) => (props.isMobile ? 280 : 400),
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));
