import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles(theme => ({
  formHeaderContainer: {
    height: 139.89,
    width: "100%",
    background: "#00112B",
    borderRadius: "0px 0px 15px 15px",
    marginBottom: 94,
    ...flex,
    flexDirection: "column",
    justifyContent: "center"
  },
  headerTitleText: {
    fontSize: 34.3829,
    color: "#FFFFFF",
    opacity: 0.8,
    fontWeight: "bold"
  },
  formFooterContainer: {
    height: 129.89,
    width: "100%",
    background: "#FFFFFF",
    boxShadow: "0px 0px 22.9348px rgba(0, 0, 0, 0.11)",
    borderRadius: 7.64494,
    ...flex,
    justifyContent: "space-between",
    marginTop: 25,
    padding: 20
  },
  footerTextContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center"
  },
  footerTitleText: {
    fontWeight: "bold",
    fontSize: 25,
    letterSpacing: "-0.01em",
    color: "#00112B"
  },
  footerSubText: {
    fontWeight: "normal",
    fontSize: 18.3478,
    color: "#2E2E2E"
  },
  footerArrowIcon: {
    fontWeight: 300,
    fontSize: 27,
    color: "#00112B"
  },
  missingInputsMsg: {
    fontSize: 14,
    color: "#00112B",
    background: "#EFEFEF",
    borderRadius: 5,
    display: "flex",
    padding: 15,
    marginBottom: 15
  },
  expandLink: {
    color: "#10B8C7",
    textDecorationLine: "underline",
    cursor: "pointer"
  },
  missingInputsIcon: {
    fontSize: 18,
    color: "#000000",
    marginRight: 15
  }
}));

const flex = {
  display: "flex",
  alignItems: "center"
};
