/* These are the number buttos */

import React from "react";
import Button from "./Button";

export default class ButtonsNumbers extends React.Component {
  render() {
    // Create a variable
    var _clickHandler = this.props._clickHandler;
    // We need to reference this inside our .map
    // Other option is to render = () => {}, but this will make cause scope creep
    // so we'll not force it this way.

    return (
      <section className="buttons-wrapper numbers">
        {this.props.numbers.map(function (b) {
          return <Button key={b} text={b} _clickHandler={_clickHandler} />;
        })}
      </section>
    );
  }
}

// We know which numbers we're going ot need, so just create a simple array.
ButtonsNumbers.defaultProps = {
  numbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, ".", "BCK"],
};
