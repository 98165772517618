import * as types from "../actions/actionTypes";
import mixpanel from "mixpanel-browser";

const initialState = {
  data: {},
  message: "",
  resendMessage: "",
  activationSuccess: null,
  loadingRequest: false,
  loadingSignUp: false,
  activating: false,
  linkExpired: false,
  apiResponse: true,
  success: true,
  showToast: false,
  forgotPassword: {
    success: true,
    message: "",
  },
  resetPassword: {
    success: true,
    message: "",
  },
  passwordValidation: {
    success: true,
    message: "",
  },
};

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_LOGGING_IN_MESSAGE:
      return Object.assign({}, state, { message: "Logging In..." });
    case types.LOGIN_SUCCESS:
      mixpanel.register({ ...action.data });
      return {
        timestamp: action.timestamp,
        token: action.token,
        data: action.data,
      };
    case types.LOGIN_ERROR:
      return Object.assign({}, state, { message: action.message });
    case types.FORGOT_PASSWORD:
      return Object.assign({}, state, {
        forgotPassword: { success: action.success, message: action.message },
      });
    case types.RESET_PASSWORD:
      return Object.assign({}, state, {
        resetPassword: { success: action.success, message: action.message },
      });
    case types.LOADING_SIGN_UP_REQUEST:
      return Object.assign({}, state, { loadingSignUp: true, message: "" });
    case types.SIGN_UP_SUCCESS:
      return Object.assign({}, state, {
        message: action.message,
        loadingSignUp: false,
      });
    case types.SIGN_UP_FAILED:
      return Object.assign({}, state, {
        showToast: true,
        message: action.message,
        loadingSignUp: false,
      });
    case types.CLOSE_ERROR_TOAST:
      return Object.assign({}, state, { showToast: false, message: "" });
    case types.STORE_AUTHENTICATION_INFO:
      return Object.assign({}, state, {
        timestamp: action.timestamp,
        token: action.token,
        data: action.data,
      });
    case types.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        data: {},
        message: "",
        token: "",
        timestamp: "",
        loadingSignUp: false,
      });
    case types.STORE_NEW_TIMESTAMP:
      return Object.assign({}, state, { timestamp: action.timestamp });
    case types.LOADING_ACTIVATE_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        loadingRequest: true,
        apiResponse: false,
      });
    case types.FAILED_ACTIVATE_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        loadingRequest: false,
        message: action.message,
        activationSuccess: false,
        apiResponse: true,
      });
    case types.SUCCESS_ACTIVATE_ACCOUNT_REQUEST:
      return Object.assign({}, state, {
        loadingRequest: false,
        message: action.message,
        activationSuccess: true,
        apiResponse: true,
      });
    case types.ACTIVATING_ACCOUNT:
      return Object.assign({}, state, {
        activating: true,
        activationSuccess: null,
      });
    case types.ACCNT_ACTIVATION_SUCCESS:
      return Object.assign({}, state, {
        timestamp: action.timestamp,
        token: action.token,
        data: action.data,
        activationSuccess: true,
        activating: false,
      });
    case types.ACCNT_ACTIVATION_FAILED:
      console.log("account failed reducer");
      return Object.assign({}, state, {
        activationSuccess: false,
        linkExpired: false,
        message: action.message,
        activating: false,
      });
    case types.ACTIVATION_LINK_EXPIRED:
      return Object.assign({}, state, {
        activationSuccess: false,
        linkExpired: true,
        message: action.message,
        activating: false,
      });
    case types.RESEND_ACTIVATION_SUCCESS:
      return Object.assign({}, state, {
        success: true,
        resendMessage: action.message,
      });
    case types.RESEND_ACTIVATION_FAILED:
      return Object.assign({}, state, {
        success: false,
        resendMessage: action.message,
      });
    case types.VALIDATE_PASSWORD:
      return Object.assign({}, state, {
        passwordValidation: {
          success: action.success,
          message: action.message,
        },
      });
    case types.CLOSE_RESEND_ACTIVATION_TOAST:
      return Object.assign({}, state, { showToast: false });
    default:
      return state;
  }
}
