import React, { memo, useCallback, useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { makeStyles } from "@material-ui/core";
import {
  saveEcalc,
  editEcalcById,
  createPublicEcalc,
  clearToolbarData,
} from "../../../actions/eCalcForms";
import {
  SaveIcon,
  ClearIcon,
  useMobile,
  ShareIcon,
  ExportIcon,
} from "../../../themes";
import {
  ClearConfirmationModal,
  SaveEcalcModal,
  ExportEcalcModal,
  ShareEcalcModal,
  Notification,
} from "../../eCalc/components";
import clsx from "clsx";
import { Navigation } from "../../../lib";
import { parse } from "query-string";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: 20,
  },
  icon: {
    color: "White",
  },
  disabled: {
    opacity: 0.3,
  },
  text: {
    color: "#FFFFFF",
    fontSize: 10,
  },
}));

export const MyEcalc = memo(({ pathname = "", isDealDetails = false }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useMobile();
  const [storeSliceName, setStoreSliceName] = useState();
  const { hash_id } = parse(window.location.search);
  const isPublicEcalc = !!hash_id;

  useEffect(() => {
    setStoreSliceName(
      isDealDetails || isPublicEcalc ? "eCalcFormsNoPersist" : "eCalcForm"
    );
  }, [isDealDetails, isPublicEcalc]);

  const ecalcValues = useSelector(
    (state) => state[storeSliceName],
    shallowEqual
  );

  const initialNotificationState = {
    show: false,
    message: null,
    buttonTxt: null,
  };
  const [notfication, setNotification] = useState(initialNotificationState);
  const [dealId, setDealId] = useState();
  const [showSaveEcalcModal, setShowSaveEcalcModal] = useState(false);
  const [showExportEcalcModal, setShowExportEcalcModal] = useState(false);
  const [showShareEcalcModal, setShowShareEcalcModal] = useState(false);
  const [showClearConfirmationModal, setShowClearConfirmationModal] = useState(
    false
  );
  const publicEcalc = useSelector(
    (state) => state.eCalcFormsNoPersist.publicEcalc,
    shallowEqual
  );

  const { action, success, message, link } = publicEcalc || {};
  const { ecalc_id, dirty, ...otherValues } = ecalcValues || {};

  useEffect(() => {
    if (action === "share") {
      if (success && link) {
        if (window && window.isNativeApp && window.canShareUrl) {
          setShowShareEcalcModal(true);
        } else {
          const a = document.createElement("a");
          a.target = "_blank";
          a.action = `mailto:?subject=Shared%20Underwriting%20Document:%20Link%20Enclosed&body=Please see the confidential underwriting file I just created using the updated Eastern Union eCALC app. Simply click on the link below to download and view.%0D%0A%0D%0A${encodeURIComponent(
            link
          )}`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          dispatch(clearToolbarData());
        }
      } else if (!success && message) {
        setNotification({ show: true, message, allowAutoHide: true });
      }
    }
  }, [action, success, message, link, dispatch]);

  useEffect(() => {
    const routeParts = pathname.split("/");
    setDealId(routeParts[3]);
  }, [pathname]);

  const Save = () => {
    if (!JSON.parse(localStorage.getItem("data"))) {
      setNotification({
        show: true,
        message: "You must be logged in to save a calculation",
        buttonTxt: "Log In",
      });
    } else {
      return dirty
        ? !isDealDetails
          ? setShowSaveEcalcModal(true)
          : handleSave()
        : () => {};
    }
  };

  const handleSave = useCallback(() => {
    if (ecalc_id) {
      dispatch(
        editEcalcById({
          values: otherValues,
          ecalcId: ecalc_id,
          dealId,
          persist: false,
        })
      );
    } else {
      dispatch(saveEcalc({ values: otherValues, dealId, persist: false }));
    }
  }, [dealId, dispatch, ecalc_id, otherValues]);

  const Share = async () => {
    await dispatch(createPublicEcalc({ data: ecalcValues, action: "share" }));
  };

  const isDirty = dirty || ecalc_id;
  return (
    <Fragment>
      <div className={classes.container} onClick={Share}>
        <ShareIcon
          className={clsx(classes.icon, !isDirty ? classes.disabled : null)}
        />
        <span
          className={clsx(classes.text, !isDirty ? classes.disabled : null)}
        >
          Share
        </span>
      </div>

      <div
        className={classes.container}
        onClick={() => setShowExportEcalcModal(true)}
      >
        <ExportIcon
          className={clsx(classes.icon, !isDirty ? classes.disabled : null)}
        />
        <span
          className={clsx(classes.text, !isDirty ? classes.disabled : null)}
        >
          Export
        </span>
      </div>

      <div className={classes.container} onClick={() => Save()}>
        <SaveIcon
          className={clsx(classes.icon, !isDirty ? classes.disabled : null)}
        />
        <span
          className={clsx(classes.text, !isDirty ? classes.disabled : null)}
        >
          Save
        </span>
      </div>

      {!isDealDetails && (
        <div
          className={classes.container}
          onClick={() => setShowClearConfirmationModal(true)}
        >
          <ClearIcon
            className={clsx(classes.icon, !isDirty ? classes.disabled : null)}
          />
          <span
            className={clsx(classes.text, !isDirty ? classes.disabled : null)}
          >
            Clear
          </span>
        </div>
      )}

      {showClearConfirmationModal && (
        <ClearConfirmationModal
          handleClose={() => setShowClearConfirmationModal(false)}
          persist={!isDealDetails && !isPublicEcalc}
        />
      )}

      {showSaveEcalcModal && (
        <SaveEcalcModal
          storeSliceName={storeSliceName}
          handleOpen={() => setShowSaveEcalcModal(true)}
          handleClose={() => setShowSaveEcalcModal(false)}
        />
      )}

      {showExportEcalcModal && (
        <ExportEcalcModal
          storeSliceName={storeSliceName}
          handleOpen={() => setShowExportEcalcModal(true)}
          handleClose={() => setShowExportEcalcModal(false)}
        />
      )}

      {showShareEcalcModal && (
        <ShareEcalcModal
          storeSliceName={storeSliceName}
          handleOpen={() => setShowShareEcalcModal(true)}
          handleClose={() => setShowShareEcalcModal(false)}
        />
      )}

      {notfication.show && (
        <Notification
          open={notfication.show}
          initialNotificationState={initialNotificationState}
          message={notfication.message}
          buttonTxt={notfication.buttonTxt}
          isMobile={isMobile}
          maxMsgWidth={isMobile ? 352 : 563}
          horizontalAnchor="left"
          style={{ top: 55, position: "fixed", left: isMobile ? 8 : 200 }}
          onClick={() => Navigation.redirect("/login")}
          hideNotification={setNotification}
        />
      )}
    </Fragment>
  );
});
