import * as types from "../actions/actionTypes";
import { initialState } from "./eCalcForms";

export default function eCalcFormReducer(state = initialState, action) {
  switch (action.type) {
    case types.ECALC_TOGGLE_IS_DIRTY_NP:
      return {
        ...state,
        dirty: action.value,
      };
    case types.ECALC_ON_CHANGE_NP:
      return {
        ...state,
        [action.calculator]: {
          ...state[action.calculator],
          [action.name]: action.value,
        },
      };
    case types.ECALC_ACTIVE_CALCULATOR_NP:
      return {
        ...state,
        activeCalculator: action.name,
      };
    case types.ECALC_ON_MULTI_CHANGE_NP:
      return {
        ...state,
        [action.calculator]: {
          ...state[action.calculator],
          ...action.valuesObj,
        },
      };
    case types.ECALC_ADD_ENTRY_METHOD_NP:
      return {
        ...state,
        userEntryMethods: {
          ...state.userEntryMethods,
          [action.name]: action.value,
        },
      };
    case types.ECALC_ADD_ITEMIZATION_ENTRY_METHOD_NP:
      return {
        ...state,
        userItemizationEntryMethods: {
          ...state.userItemizationEntryMethods,
          [action.itemizationName]: {
            ...state.userItemizationEntryMethods[action.itemizationName],
            [action.item]: action.value,
          },
        },
      };
    case types.ECALC_DELETE_ITEMIZATION_ENTRY_METHOD_NP:
      const {
        [action.item]: itemToDelete,
        ...otherItems
      } = state.userItemizationEntryMethods[action.itemizationName];
      return {
        ...state,
        userItemizationEntryMethods: {
          ...state.userItemizationEntryMethods,
          [action.itemizationName]: {
            ...otherItems,
          },
        },
      };
    case types.ECALC_ADD_RESULTS_NP:
      return {
        ...state,
        resultsValues: {
          ...state.resultsValues,
          [action.name]: action.value,
        },
      };
    case types.ECALC_REMOVE_RESULTS_NP:
      const { [action.name]: itemToRemove, ...rest } = state.resultsValues;
      return {
        ...state,
        resultsValues: rest,
      };
    case types.UPDATE_ITEMIZATION_NP:
      return {
        ...state,
        itemizations: {
          ...state.itemizations,
          [action.name]: action.value,
        },
      };
    case types.UPDATE_ITEMIZATION_PERCENT_OF_NP:
      return {
        ...state,
        itemizationsPercentOf: {
          ...state.itemizationsPercentOf,
          [action.itemizationName]: {
            ...state.itemizationsPercentOf[action.itemizationName],
            [action.item]: action.value,
          },
        },
      };
    case types.ECALC_DELETE_ITEMIZATION_PERCENT_OF_NP:
      const {
        [action.item]: fieldToDelete,
        ...otherFields
      } = state.itemizationsPercentOf[action.itemizationName];
      return {
        ...state,
        itemizationsPercentOf: {
          ...state.itemizationsPercentOf,
          [action.itemizationName]: {
            ...otherFields,
          },
        },
      };
    case types.IRR_SCHEDULE_CHANGE_NP:
      return {
        ...state,
        irrSchedule: {
          ...state.irrSchedule,
          pgi: action.pgi,
          stabilized_vacancies: action.stabilized_vacancies,
          effective_gross_income: action.effective_gross_income,
          expenses: action.expenses,
          noi: action.noi,
          pi_payments: action.pi_payments,
          net_cash_flow: action.net_cash_flow,
          sale_price: action.sale_price,
          closing_costs: action.closing_costs,
          net_sale_price: action.net_sale_price,
          mortgage_balance: action.mortgage_balance,
          net_distribution_from_sale: action.net_distribution_from_sale,
          total_distribution_to_sale: action.total_distribution_to_sale,
          grand_total_distribution: action.grand_total_distribution,
          irr: action.irr,
          capitalEventPiPayments: action.capitalEventPiPayments,
          capitalEventNetCashFlow: action.capitalEventNetCashFlow,
          capitalEventMortgageBalance: action.capitalEventMortgageBalance,
          capitalEventNetDistFromSale: action.capitalEventNetDistFromSale,
          capitalEventTotalDistToSale: action.capitalEventTotalDistToSale,
          capitalEventGrandTotalDistFromSale:
            action.capitalEventGrandTotalDistFromSale,
          capitalEventIRR: action.capitalEventIRR,
          ROI: action.ROI,
          yieldOnCost: action.yieldOnCost,
          avgCashOnCash: action.avgCashOnCash,
        },
      };
    case types.IRR_SCHEDULE_OPENED_NP:
      return {
        ...state,
        irrSchedule: {
          ...state.irrSchedule,
          wasScheduleOpened: true,
        },
      };
    case types.CLEAR_BLENDED_RATE_ITEMIZATION_NP:
      return {
        ...state,
        equityNeeded: {
          ...state.equityNeeded,
          loan_amount_dollar: "",
          loan_amount_percent: "",
          loans: initialState.equityNeeded.loans,
        },
        mortgageDscr: {
          ...state.mortgageDscr,
          interest_rate: "",
        },
      };
    case types.CLEAR_ECALC_NP:
      return {
        ...initialState,
      };
    case types.CLEAR_IRR_CAPITAL_EVENT_NP:
      return {
        ...state,
        irrCapitalEvent: initialState.irrCapitalEvent,
      };
    case types.SET_OPEN_CALCULATORS_NP:
      return {
        ...state,
        openCalcs: action.payload,
      };
    case types.ECALC_GET_SAVED_ECALC_NP:
      return {
        ...state,
        ...action.payload,
      };
    case types.SAVE_ECALC_NP:
      return {
        ...state,
        ecalc_id: action.ecalc_id,
      };
    case types.START_SAVING_ECALC_NP: {
      return {
        ...state,
        saveEcalc: {
          loading: true,
        },
      };
    }
    case types.SAVE_ECALC_SUCCESS_NP:
      return {
        ...state,
        saveEcalc: {
          loading: false,
          dealId: action.dealId,
          dealName: action.dealName,
          success: true,
        },
      };
    case types.START_SAVING_NEW_DEAL_NP:
      return {
        ...state,
        saveDeal: {
          loading: true,
        },
      };
    case types.SAVE_NEW_DEAL_SUCCESS_NP:
      return {
        ...state,
        saveDeal: {
          loading: false,
          success: true,
          msg: null,
        },
      };
    case types.SAVE_NEW_DEAL_FAILED_NP:
      return {
        ...state,
        saveDeal: {
          loading: false,
          success: false,
          msg: action.errorMsg,
        },
        saveEcalc: {
          loading: false,
          success: null,
        },
      };
    case types.CLEAR_NEW_DEAL_ERR:
      return {
        ...state,
        saveDeal: {
          ...state.saveDeal,
          success: null,
          msg: null,
        },
      };
    case types.SAVE_ECALC_FAILED_NP:
      return {
        ...state,
        saveEcalc: {
          loading: false,
          message: action.message,
          success: false,
        },
      };
    case types.LOADING_EXISTING_PROPERTIES_NP:
      return {
        ...state,
        properties: { loading: true, data: action.data },
      };
    case types.LOAD_EXISTING_PROPERTIES_NP:
      return {
        ...state,
        properties: { loading: false, data: action.data },
      };
    case types.CREATE_PUBLIC_ECALC_NP:
      return {
        ...state,
        loading: false,
        publicEcalc: {
          success: action.success,
          message: action.message,
          action: action.action,
          ...action.data,
        },
      };
    case types.DOWNLOAD_PDF_FAILED:
      return {
        ...state,
        loading: false,
        publicEcalc: {
          ...state.publicEcalc,
          success: action.success,
          message: action.message,
          action: action.action,
        },
      };
    case types.DOWNLOAD_PDF_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.SET_ECALC_LOADING_NP:
      return {
        ...state,
        loading: true,
      };
    case types.CLEAR_TOOLBAR_DATA: {
      return {
        ...state,
        publicEcalc: {},
        properties: {},
        saveEcalc: {},
        saveDeal: {},
      };
    }
    default:
      return state;
  }
}
