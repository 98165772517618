import * as types from "../actions/actionTypes";

const initialState = {
  loading: false,
  deals: {
    data: [],
    nextPageUrl: null,
  },
  toast: {
    show: false,
    success: true,
    message: "",
  },
  loadingContact: false,
  contact: {},
  quote: {},
  note_success: null,
  new_note_id: null,
};

export default function fundPortfolioReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_FUND_PORTFOLIOS:
      return Object.assign({}, state, {
        loading: true,
        deals: { ...state.deals },
      });
    case types.GET_FUND_PORTFOLIO_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        deals: { data: action.data, nextPageUrl: action.nextPageUrl },
      });
    case types.GET_FUND_PORTFOLIO_FAILED:
      return Object.assign({}, state, {
        loading: false,
        toast: { show: true, success: false, message: action.message },
      });
    case types.LOADING_FULL_CONTACT:
      return Object.assign({}, state, { loadingContact: true });
    case types.LOAD_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: action.contact,
        loadingContact: false,
      });
    case types.NO_FULL_CONTACT:
      return Object.assign({}, state, { contact: {}, loadingContact: false });
    case types.LOADING_EQUITY_QUOTE:
      return Object.assign({}, state, { loading: true });
    case types.GET_EQUITY_QUOTE_SUCCESS:
      return Object.assign({}, state, { quote: action.data });
    case types.GET_EQUITY_QUOTE_FAILED:
      return Object.assign({}, state, {
        loading: false,
        toast: { show: true, success: false, message: action.message },
      });
    case types.GET_QUOTE_NOTE_SUCCESS:
      let notes = action.data.map((n) => ({
        note_id: n.note_id,
        note: n.note,
        updated_at: n.updated_at,
        updated_by: `${n.updated_by_first_name} ${n.updated_by_last_name}`,
        is_editable: n.is_editable,
      }));
      return Object.assign({}, state, {
        loading: false,
        quote: { ...state.quote, notes },
      });
    case types.GET_QUOTE_NOTE_FAILED:
      return Object.assign({}, state, {
        loading: false,
        toast: { show: true, success: false, message: action.message },
      });
    case types.SAVE_EQUITY_QUOTE:
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.CREATE_QUOTE_NOTE_SUCCESS:
      let note = {
        note_id: action.data.note_id,
        note: action.data.note,
        updated_at: action.data.updated_at,
        updated_by: `${action.data.created_by_first_name} ${action.data.created_by_last_name}`,
        is_editable: true,
      };
      state.quote.notes.unshift({ ...note });
      return Object.assign({}, state, {
        quote: { ...state.quote },
        toast: { show: true, success: true, message: action.message },
      });
    case types.EDIT_QUOTE_NOTE_SUCCESS:
      let noteToEdit = state.quote.notes;
      let updatedNote = noteToEdit.find(
        (note) => note.note_id === action.data.note_id
      );
      updatedNote.note = action.data.note;
      updatedNote.updated_by = `${action.data.updated_by_first_name} ${action.data.updated_by_last_name}`;
      updatedNote.updated_at = action.data.updated_at;

      return Object.assign({}, state, {
        quote: { ...state.quote, notes: noteToEdit },
        toast: { show: true, success: true, message: action.message },
      });
    case types.DELETE_QUOTE_NOTE_SUCCESS:
      return Object.assign({}, state, {
        quote: {
          ...state.quote,
          notes: state.quote.notes.filter(
            (note) => note.note_id !== action.note_id
          ),
        },
        toast: { show: true, success: true, message: action.message },
      });
    case types.UPDATE_QUOTE_NOTE_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });
    case types.CLOSE_FUND_PORTFOLIO_TOAST:
      return Object.assign({}, state, {
        toast: { show: false, success: true, message: "" },
      });
    default:
      return state;
  }
}
