import { makeStyles } from "@material-ui/core";
import { light } from "@material-ui/core/styles/createPalette";
export const useStyles = makeStyles((theme) => ({
  toolbar: (props) => ({
    background: "#FFFFFF",
    position: "fixed !important",
    bottom: 0,
    right: 0,
    width: "100%",
    height: 65,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    zIndex: 19,
    padding: 10,
    color: props.type === light ? "#898989" : "#FFFFFF",
    fontFamily: "Karla",
    fontSize: 12,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.14)",
  }),
  toolbarDivider: {
    border: "1px solid #D7D7D7",
  },
  toolbarItem: (props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    color: props.type === light ? "#898989" : "#FFFFFF",
    margin: "5px auto",
  }),
  toolbarECalcImg: {
    width: "19.48px",
    height: "22.04px",
    marginBottom: "3px !important",
  },
  toolbarItemActive: {
    color: "#10B8C7 !important",
  },
  toolbarIcon: (props) => ({
    color: props.type === light ? "#898989" : "#FFFFFF",
  }),
  activeIcon: (props) => ({
    color: props.type === light ? "#898989" : "#FFFFFF",
  }),
}));
