import React from "react";
import FormattedInput from "./FormattedInput";

class NumberInputWithoutDecimal extends React.Component {
  formatValue(value) {
    value = value.toString();
    return value.replace(/[^0-9]/g, ""); //Strips of non-numeric characters, and decimal (only allow whole numbers)
  }

  stripFormat(value) {
    return value;
  }

  render() {
    return (
      <FormattedInput
        {...this.props}
        formatValue={this.formatValue.bind(this)}
        stripFormat={this.stripFormat.bind(this)}
      />
    );
  }
}

export default NumberInputWithoutDecimal;
