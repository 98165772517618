import * as types from "../../actions/actionTypes";

const initialState = {
  monthlyPayment: "",
  rate: "",
  amortizationYears: "",
  amortizationMonths: "",
  loanAmount: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.UPDATE_REVERSE_MORTGAGE:
      return action.values;
    default:
      return state;
  }
}
