import * as types from "./actionTypes";
import { ajax, fileFormat } from "../lib";
import config from "../config";
import { tokenExpired } from "./authenticationActions";
import { showNotification } from "./uiActions";
import moment from "moment";

export function setIsDirty(persist = true) {
  return async (dispatch) => {
    dispatch({
      type: getDispatchType("ECALC_TOGGLE_IS_DIRTY", persist),
      value: true,
    });
  };
}

export function eCalcOnChange({ calculator, name, value, persist = true }) {
  return async (dispatch) => {
    if (!calculator || value === undefined) return;
    dispatch({
      type: getDispatchType("ECALC_ON_CHANGE", persist),
      calculator,
      name,
      value,
    });
  };
}

export function setActiveCalculator({ name, persist = true }) {
  return async (dispatch) => {
    dispatch({
      type: getDispatchType("ECALC_ACTIVE_CALCULATOR", persist),
      name,
    });
  };
}

export function eCalcOnMultiChange({ calculator, valuesObj, persist = true }) {
  return async (dispatch) => {
    if (!calculator || valuesObj === undefined) return;
    dispatch({
      type: getDispatchType("ECALC_ON_MULTI_CHANGE", persist),
      calculator,
      valuesObj,
    });
  };
}

export function addResults({ name, value, persist = true }) {
  return async (dispatch) => {
    if (!name || value === undefined) return;
    dispatch({
      type: getDispatchType("ECALC_ADD_RESULTS", persist),
      name,
      value,
    });
  };
}

export function removeResults({ name, persist = true }) {
  return async (dispatch) => {
    if (!name) return;
    dispatch({
      type: getDispatchType("ECALC_REMOVE_RESULTS", persist),
      name,
    });
  };
}

export function addUserEntryMethod({ name, value, persist = true }) {
  return async (dispatch) => {
    if (!name) return;
    dispatch({
      type: getDispatchType("ECALC_ADD_ENTRY_METHOD", persist),
      name,
      value,
    });
  };
}

export function addUserItemizationEntryMethod({
  itemizationName,
  item,
  value,
  persist = true,
}) {
  return async (dispatch) => {
    if (!itemizationName) return;
    dispatch({
      type: getDispatchType("ECALC_ADD_ITEMIZATION_ENTRY_METHOD", persist),
      itemizationName,
      item,
      value,
    });
  };
}

export function deleteUserItemizationEntryMethod({
  itemizationName,
  item,
  persist = true,
}) {
  return async (dispatch) => {
    if (!itemizationName) return;
    dispatch({
      type: getDispatchType("ECALC_DELETE_ITEMIZATION_ENTRY_METHOD", persist),
      itemizationName,
      item,
    });
  };
}

export function irrScheduleChange(irrSchedule, persist = true) {
  return async (dispatch) => {
    dispatch({
      type: getDispatchType("IRR_SCHEDULE_CHANGE", persist),
      ...irrSchedule,
    });
  };
}

export function scheduleOpened(persist = true) {
  return { type: getDispatchType("IRR_SCHEDULE_OPENED", persist) };
}

export function clearBlendedRateItemization(persist = true) {
  return { type: getDispatchType("CLEAR_BLENDED_RATE_ITEMIZATION", persist) };
}

export function clearEcalc(persist = true) {
  return { type: getDispatchType("CLEAR_ECALC", persist) };
}

export function clearIrrCapitalEvent(persist = true) {
  return { type: getDispatchType("CLEAR_IRR_CAPITAL_EVENT", persist) };
}

export function setOpenCalcs(calculators, persist = true) {
  return {
    type: getDispatchType("SET_OPEN_CALCULATORS", persist),
    payload: calculators,
  };
}

export function updateItemization({ name, value, persist = true }) {
  return async (dispatch) => {
    if (!name) return;
    dispatch({
      type: getDispatchType("UPDATE_ITEMIZATION", persist),
      name,
      value,
    });
  };
}

export function updateItemizationPercentOf({
  itemizationName,
  item,
  value,
  persist = true,
}) {
  return async (dispatch) => {
    if (!itemizationName) return;
    dispatch({
      type: getDispatchType("UPDATE_ITEMIZATION_PERCENT_OF", persist),
      itemizationName,
      item,
      value,
    });
  };
}

export function deleteClosingCostPercentOf({
  itemizationName,
  item,
  persist = true,
}) {
  return async (dispatch) => {
    if (!itemizationName) return;
    dispatch({
      type: getDispatchType("ECALC_DELETE_ITEMIZATION_PERCENT_OF", persist),
      itemizationName,
      item,
    });
  };
}

export function saveEcalc({ values, dealId, persist = true }) {
  return async (dispatch) => {
    return ajax({
      url: `${config.BASE_URL}ecalc`,
      applyAuthHeader: true,
      method: "POST",
      data: {
        deal_id: dealId,
        values,
      },
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            dispatch(showNotification({ message: "Changes saved" }));
            dispatch({
              type: getDispatchType("ECALC_TOGGLE_IS_DIRTY", persist),
              value: false,
            });
            dispatch({
              type: getDispatchType("SAVE_ECALC", persist),
              ecalc_id: response.data.ecalc_id,
            });
            break;
        }
      },
    });
  };
}

export function editEcalcById({ values, ecalcId, dealId, persist = true }) {
  return async (dispatch) => {
    return ajax({
      url: `${config.BASE_URL}ecalc/${ecalcId}`,
      applyAuthHeader: true,
      method: "PUT",
      data: {
        deal_id: dealId,
        values,
      },
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            dispatch(showNotification({ message: "Changes saved" }));
            dispatch({
              type: getDispatchType("ECALC_TOGGLE_IS_DIRTY", persist),
              value: false,
            });
            break;
        }
      },
    });
  };
}

export function getEcalcByDealId({ dealId, persist = false }) {
  return async (dispatch) => {
    return ajax({
      url: `${config.BASE_URL}deal/${dealId}/ecalc`,
      applyAuthHeader: true,
      method: "GET",
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            const responseValues = response.data[0];
            if (responseValues) {
              const {
                ecalc_id,
                data: { values },
              } = responseValues;
              dispatch({
                type: getDispatchType("ECALC_GET_SAVED_ECALC", persist),
                payload: { ...values, ecalc_id },
              });
            } else {
              dispatch(clearEcalc(persist));
            }
        }
      },
    });
  };
}

export function loadExistingProperties() {
  return async (dispatch) => {
    dispatch({
      type: types.LOADING_EXISTING_PROPERTIES_NP,
    });
    return ajax({
      url: `${config.BASE_URL}client-portfolio`,
      applyAuthHeader: true,
      method: "GET",
      data: {
        deal_statuses: [1, 2, 21, 22],
        fields: ["ecalcs"],
      },
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.LOAD_EXISTING_PROPERTIES_NP,
              data: response.data || [],
            });
        }
      },
      error: function (err) {
        dispatch({
          type: types.LOAD_EXISTING_PROPERTIES_NP,
          data: [],
        });
      },
    });
  };
}

export function saveEcalcToExistingDeal({ deal, ecalcValues }) {
  return async (dispatch) => {
    dispatch({ type: types.START_SAVING_ECALC_NP });
    try {
      if (deal?.ecalcs.length > 0) {
        //remove the first ecalc
        await removeEcalcDealId({
          ecalc_id: deal.ecalcs[0].ecalc_id,
          dispatch,
        });
      }
      await saveDealEcalc({
        values: ecalcValues,
        dealId: deal.deal_id,
        dealName: deal.deal_name,
        dispatch,
      });
    } catch (e) {
      console.log(e);
    }
  };
}

export function saveEcalcToNewDeal({ address, isMobile, ecalcValues }) {
  return async (dispatch) => {
    dispatch({ type: types.START_SAVING_NEW_DEAL_NP });
    dispatch({ type: types.START_SAVING_ECALC_NP });
    try {
      const dealName = `${address.property_street_number} ${address.property_street_name}`;
      await createProperty({ address, dispatch }).then(
        async (response) =>
          !!response.id &&
          (await createDeal({
            propertyId: response.id,
            dealName,
            source: `${isMobile ? "App" : "Toolkit"} ${moment().format(
              "MM-DD-YYYY"
            )}`,
            loan_amount: parseFloat(
              (ecalcValues.equityNeeded.loan_amount_dollar + "").replace(
                /[^0-9.+-]/g,
                ""
              )
            ),
            dispatch,
          }).then(async (response) => {
            dispatch({ type: types.SAVE_NEW_DEAL_SUCCESS_NP });
            !!response.id &&
              (await saveDealEcalc({
                values: ecalcValues,
                dealId: response.id,
                dealName,
                dispatch,
              }));
          }))
      );
    } catch (err) {
      console.log(err);
    }
  };
}

export async function createProperty({ address, dispatch }) {
  return await ajax({
    url: `${config.BASE_URL}property`,
    applyAuthHeader: true,
    method: "POST",
    data: { ...address, dont_duplicate: 1 },
  })
    .then((response) => {
      switch (response.status) {
        case "E403":
          return dispatch(tokenExpired());
        case "E107":
          return dispatch({
            type: types.SAVE_NEW_DEAL_FAILED_NP,
            errorMsg: response.message,
          });
        default:
          return response.data;
      }
    })
    .catch((err) => {
      return dispatch({
        type: types.SAVE_NEW_DEAL_FAILED_NP,
        errorMsg: err,
      });
    });
}

export async function createDeal({
  propertyId,
  dealName,
  source,
  loan_amount,
  dispatch,
}) {
  return await ajax({
    url: `${config.BASE_URL}deal/brokerage`,
    applyAuthHeader: true,
    method: "POST",
    data: {
      property_ids: [propertyId],
      deal_type: "Acquisition Loan",
      loan_type: "Permanent Debt",
      deal_status: 22,
      deal_broker_id: 3079,
      deal_name: dealName,
      source,
      source_id: 9,
      deal_description: "eCalc Deal",
      loan_amount,
    },
  })
    .then((response) => {
      switch (response.status) {
        case "E403":
          return dispatch(tokenExpired());
        case "E107":
          return dispatch({
            type: types.SAVE_NEW_DEAL_FAILED_NP,
            errorMsg: response.message,
          });
        default:
          return response.data;
      }
    })
    .catch((err) => {
      return dispatch({
        type: types.SAVE_NEW_DEAL_FAILED_NP,
        errorMsg: err,
      });
    });
}

export function setErrorMsg(msg) {
  return { type: types.SAVE_NEW_DEAL_FAILED_NP, errorMsg: msg };
}

export function clearErrorMsg() {
  return { type: types.CLEAR_NEW_DEAL_ERR };
}

export async function removeEcalcDealId({ ecalc_id, dispatch }) {
  return await ajax({
    url: `${config.BASE_URL}ecalc/${ecalc_id}`,
    applyAuthHeader: true,
    method: "PUT",
    data: { deal_id: null },
  })
    .then((response) => {
      switch (response.status) {
        case "E403":
          return dispatch(tokenExpired());
        case "E107":
          return dispatch({
            type: types.SAVE_ECALC_FAILED_NP,
            message: response.message,
          });
        default:
          return response.data;
      }
    })
    .catch((err) => {
      return dispatch({
        type: types.SAVE_ECALC_FAILED_NP,
        message: err.response.data.message,
      });
    });
}

export async function saveDealEcalc({ values, dealId, dealName, dispatch }) {
  const {
    activeCalculator,
    capRate,
    cashOnCash,
    equityNeeded,
    equityWaterfall,
    irr,
    irrCapitalEvent,
    irrSchedule,
    itemizations,
    itemizationsPercentOf,
    mortgageDscr,
    openCalcs,
    resultsValues,
    userEntryMethods,
    userItemizationEntryMethods,
  } = values || {};
  return await ajax({
    url: `${config.BASE_URL}ecalc`,
    applyAuthHeader: true,
    method: "POST",
    data: {
      deal_id: dealId,
      values: {
        activeCalculator,
        capRate,
        cashOnCash,
        equityNeeded,
        equityWaterfall,
        irr,
        irrCapitalEvent,
        irrSchedule,
        itemizations,
        itemizationsPercentOf,
        mortgageDscr,
        openCalcs,
        resultsValues,
        userEntryMethods,
        userItemizationEntryMethods,
      },
    },
  })
    .then((response) => {
      switch (response.status) {
        case "E403":
          dispatch(tokenExpired());
          break;
        case "E107":
          return dispatch({
            type: types.SAVE_ECALC_FAILED_NP,
            message: response.message,
          });
        default:
          return dispatch({
            type: types.SAVE_ECALC_SUCCESS_NP,
            dealId,
            dealName,
          });
      }
    })
    .catch((err) => {
      return dispatch({
        type: types.SAVE_ECALC_FAILED_NP,
        message: err.response.data.message,
      });
    });
}

export function createPublicEcalc({ data, action }) {
  return async (dispatch) => {
    dispatch({ type: types.SET_ECALC_LOADING_NP });
    return await ajax({
      url: `${config.BASE_URL}ecalc-public`,
      applyAuthHeader: !!localStorage.getItem("auth_token"),
      data: { data },
      method: "POST",
    })
      .then((response) => {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            response.success
              ? dispatch({
                  type: types.CREATE_PUBLIC_ECALC_NP,
                  success: true,
                  message: null,
                  data: {
                    hash_id: response.data.hash_id,
                    link: response.data.link,
                  },
                  action,
                })
              : dispatch({
                  type: types.CREATE_PUBLIC_ECALC_NP,
                  success: false,
                  message: response.message,
                  action,
                });
        }
      })
      .catch((err) => {
        return dispatch({
          type: types.CREATE_PUBLIC_ECALC_NP,
          success: false,
          message: err.response.data.message,
          action,
        });
      });
  };
}

export function getPublicEcalcPDF({
  hash_id,
  action,
  format = fileFormat.pdf,
}) {
  return (dispatch) => {
    const url = `${config.BASE_URL}ecalc/${hash_id}/pdf`;
    dispatch({ type: types.SET_ECALC_LOADING_NP });
    ajax({
      url,
      method: "GET",
    })
      .then((response) => {
        action === "share"
          ? share(
              dispatch,
              url,
              "Underwriting Report - Eastern Union App - eCALC.pdf",
              format
            )
          : openPDF(
              dispatch,
              url,
              "Underwriting Report - Eastern Union App - eCALC.pdf"
            );
      })
      .catch((error) => {
        handleError(error);
        return dispatch({
          type: types.DOWNLOAD_PDF_FAILED,
          success: false,
          message: error.response.data.message,
          action,
        });
      });
  };
}

function handleError(error) {
  const err = error || new Error("Unknown error.");
  const {
    response = {
      status: -500,
      statusText: "Unknown",
      data: { message: "Unknown error." },
    },
  } = err;
  console.log(response);
}

export function share(dispatch, url, fileName, format) {
  dispatch({ type: types.DOWNLOAD_PDF_SUCCESS });
  if (window && window.isNativeApp) {
    return window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "shareUrl",
        data: { url, fileName, format },
      })
    );
  } else {
    return window.open(url, "_blank");
  }
}

export function openPDF(dispatch, url, fileName) {
  dispatch({ type: types.DOWNLOAD_PDF_SUCCESS });
  if (window && window.isNativeApp) {
    return window.ReactNativeWebView.postMessage(
      JSON.stringify({
        action: "openUrl",
        data: { url },
      })
    );
  } else {
    return window.open(url, "_blank");
  }
}

export function getPublicEcalcById({ hash_id }) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}ecalc/${hash_id}`,
      method: "GET",
      success: function (response) {
        switch (response.status) {
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            const responseValues = response.data;
            if (responseValues) {
              const { ecalc_id, data } = responseValues;
              dispatch({
                type: types.ECALC_GET_SAVED_ECALC_NP,
                payload: { ...data, ecalc_id },
              });
            } else {
              dispatch(clearEcalc(false));
            }
        }
      },
    });
  };
}

export function clearToolbarData() {
  return { type: types.CLEAR_TOOLBAR_DATA };
}

function getDispatchType(type, persist) {
  return !persist ? types[`${type}_NP`] : types[type];
}
