import * as types from "../actions/actionTypes";

const initialState = {
  closingClientContacts: [],
  defaultContacts: {
    data: [],
    today_call_total: null,
    next_page_url: null,
    current_page: 1,
  },
  starredContacts: [],
  searchContacts: [],
  pwipContacts: [],
  clientStatus: [],
  suggestions: [],
  ratingList: [],
  list: [],
  reonomyProperties: {},
  contact: {},
  previousScrollPos: 0,
  noRenomyProperties: false,
  loadingPageInfo: false,
  updateRatingSuccess: true,
  noContacts: false,
  loading: false,
  givebackNoteStatus: "",
  contact_section: "",
  updated_date: "",
  previousPage: "",
  nextPage: "",
  search: "",
  jobDescriptionTypes: [],
  contactTypes: [],
  contactInfoTypes: [],
  prefixTypes: [],
  holidayGreetingTypes: [],
  gaveGiftTypes: [],
  stateList: [],
  updateContactDetails: {
    success: true,
    message: "",
  },
  submitContact: {
    success: true,
    message: "",
  },
  contactId: "",
  toast: {
    show: false,
    success: true,
    message: "",
  },
  contactDeleted: false,
  reasonCodes: [],
};

export default function ipDealReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_UPDATE_STAR:
      return Object.assign({}, state, { loading: true });

    case types.LOADING_DEFAULT_CONTACTS:
      return Object.assign({}, state, {
        loading: true,
        givebackNoteStatus: "",
      });
    case types.LOAD_DEFAULT_CONTACTS:
      return Object.assign({}, state, {
        defaultContacts: {
          data: action.data,
          today_call_total: action.today_call_total,
          next_page_url: action.next_page_url,
          current_page: action.current_page,
        },
        loading: false,
      });

    case types.LOADING_PW_IP_CONTACTS:
      return Object.assign({}, state, {
        loading: true,
        noContacts: false,
        givebackNoteStatus: "",
      });
    case types.LOAD_PW_IP_CONTACTS_SUCCESS:
      return Object.assign({}, state, {
        pwipContacts: action.pwipContacts,
        loading: false,
        noContacts: false,
      });
    case types.NO_PW_IP_CONTACTS:
      return Object.assign({}, state, {
        pwipContacts: [],
        noContacts: true,
        loading: false,
      });

    case types.LOADING_STARRED_CONTACTS:
      return Object.assign({}, state, {
        loading: true,
        noContacts: false,
        givebackNoteStatus: "",
      });
    case types.LOAD_STARRED_CONTACTS_SUCCESS:
      return Object.assign({}, state, {
        starredContacts: action.starredContacts,
        loading: false,
        noContacts: false,
      });
    case types.NO_STARRED_CONTACTS:
      return Object.assign({}, state, {
        starredContacts: [],
        noContacts: true,
        loading: false,
      });

    case types.LOADING_CLOSING_CLIENTS_CONTACTS:
      return Object.assign({}, state, {
        loading: true,
        noContacts: false,
        givebackNoteStatus: "",
      });
    case types.LOAD_CLOSING_CLIENTS_CONTACTS_SUCCESS:
      return Object.assign({}, state, {
        closingClientContacts: action.closingClientContacts,
        loading: false,
        noContacts: false,
      });
    case types.NO_CLOSING_CLIENTS_CONTACTS:
      return Object.assign({}, state, {
        closingClientContacts: [],
        noContacts: true,
        loading: false,
      });

    case types.LOAD_PREDICTIVE_CONTACTS_SUCCESS:
      return Object.assign({}, state, {
        list: action.predictiveContacts,
        suggestions: action.predictiveContacts.slice(1, 50),
      });
    case types.NO_PREDICTIVE_CONTACTS:
      return Object.assign({}, state, {
        list: [],
        noContacts: true,
        loading: false,
      });

    case types.LOADING_PREDICTIVE_CONTACTS_SEARCH:
      return Object.assign({}, state, {
        searchContacts: [],
        noContacts: false,
        loading: true,
        givebackNoteStatus: "",
      });
    case types.LOAD_PREDICTIVE_CONTACTS_SEARCH:
      return Object.assign({}, state, {
        searchContacts: action.contactsSearch,
        noContacts: false,
        loading: false,
        toast: { show: false, success: true, message: "" },
      });
    case types.CONTACTS_SEARCH_FAIL:
      return Object.assign({}, state, {
        searchContacts: [],
        noContacts: false,
        loading: false,
        toast: { show: true, success: false, message: action.message },
      });
    case types.NO_PREDICTIVE_CONTACTS_SEARCH:
      return Object.assign({}, state, {
        searchContacts: [],
        noContacts: true,
        loading: false,
        toast: { show: false, success: true, message: "" },
      });

    case types.LOADING_CONTACT_DETAILS:
      return Object.assign({}, state, {
        loading: true,
        noContacts: false,
        searchContacts: [],
        contactDeleted: false,
      });
    case types.LOAD_CONTACT_DETAILS:
      return Object.assign({}, state, {
        searchContacts: action.contactsSearch, //[0].contacts[0],
        noContacts: false,
        loading: false,
      });
    case types.NO_CONTACT_DETAILS:
      return Object.assign({}, state, {
        searchContacts: [],
        noContacts: true,
        loading: false,
      });

    case types.CREATE_CLIENT_LOGIN_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });

    case types.SEND_WELCOME_EMAIL:
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
      });

    case types.ADD_CONTACT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        updateContactDetails: initialState.updateContactDetails,
        toast: { show: true, success: true, message: action.message },
      });

    case types.ADD_CONTACT_DETAILS_FAILED:
      return Object.assign({}, state, {
        updateContactDetails: { success: false, message: action.message },
      });

    case types.UPDATE_DATE_SUCCESS:
      return Object.assign({}, state, {
        toast: { show: true, success: true, message: action.message },
      });

    case types.UPDATE_NOTE_SUCCESS_TOAST:
      return Object.assign({}, state, {
        toast: { show: true, success: true, message: action.message },
      });

    case types.UPDATE_NOTE_FAILED_TOAST:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });

    case types.UPDATE_NOTE_SUCCESS_DATE_AND_TOAST:
      return Object.assign({}, state, {
        toast: { show: true, success: true, message: action.message },
        updated_date: action.data,
      });

    case types.UPDATE_SEARCH:
      const searchWords = action.search.trim().split(" ");
      let myList = [];
      state.list.forEach(function (elem) {
        let match = true;
        for (let j = 0; j < searchWords.length; j++) {
          if (elem.toLowerCase().indexOf(searchWords[j].toLowerCase()) === -1) {
            match = false;
            break;
          }
        }
        if (match) myList.push(elem);
      });
      return Object.assign({}, state, { suggestions: myList.slice(0, 50) });

    case types.UPDATE_DEFAULT_CONTACT_COMPLETED:
      let contact = state.defaultContacts.data.find(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      contact = Object.assign({}, contact, {
        activity_notes: action.noteInfo.note,
      });
      let index = state.defaultContacts.data.findIndex(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      let newDefaultContact = [
        ...state.defaultContacts.data.slice(0, index),
        contact,
        ...state.defaultContacts.data.slice(index + 1),
      ];
      return Object.assign({}, state, {
        defaultContacts: { ...state.defaultContacts, data: newDefaultContact },
        toast: { show: true, success: true, message: action.message },
        givebackNoteStatus: "",
      });

    case types.UPDATE_PW_IP_CONTACT_COMPLETED:
      let pwContact = state.pwipContacts.contacts.find(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      pwContact = Object.assign({}, pwContact, {
        activity_notes: action.noteInfo.note,
      });
      let pwIndex = state.pwipContacts.contacts.findIndex(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      let newPWContact = [
        ...state.pwipContacts.contacts.slice(0, pwIndex),
        pwContact,
        ...state.pwipContacts.contacts.slice(pwIndex + 1),
      ];
      let newCompletePWContact = {
        contacts: newPWContact,
        today_call_total: state.pwipContacts.today_call_total,
      };
      return Object.assign({}, state, {
        pwipContacts: newCompletePWContact,
        toast: { show: true, success: true, message: action.message },
        givebackNoteStatus: "",
      });

    case types.UPDATE_STARRED_CONTACT_COMPLETED:
      let starredContact = state.starredContacts.contacts.find(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      starredContact = Object.assign({}, starredContact, {
        activity_notes: action.noteInfo.note,
      });
      let starredIndex = state.starredContacts.contacts.findIndex(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      let newStarredContact = [
        ...state.starredContacts.contacts.slice(0, starredIndex),
        starredContact,
        ...state.starredContacts.contacts.slice(starredIndex + 1),
      ];
      let newCompleteStarredContact = {
        contacts: newStarredContact,
        today_call_total: state.starredContacts.today_call_total,
      };
      return Object.assign({}, state, {
        starredContacts: newCompleteStarredContact,
        toast: { show: true, success: true, message: action.message },
        givebackNoteStatus: "",
      });

    case types.UPDATE_CLOSING_CLIENT_CONTACT_COMPLETED:
      let ccContact = state.closingClientContacts.contacts.find(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      ccContact = Object.assign({}, ccContact, {
        activity_notes: action.noteInfo.note,
      });
      let ccIndex = state.closingClientContacts.contacts.findIndex(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      let newCCContact = [
        ...state.closingClientContacts.contacts.slice(0, ccIndex),
        ccContact,
        ...state.closingClientContacts.contacts.slice(ccIndex + 1),
      ];
      let newCompleteCCContact = {
        contacts: newCCContact,
        today_call_total: state.closingClientContacts.today_call_total,
      };
      return Object.assign({}, state, {
        closingClientContacts: newCompleteCCContact,
        toast: { show: true, success: true, message: action.message },
        givebackNoteStatus: "",
      });

    case types.UPDATE_RESULT_CONTACT_COMPLETED:
      let resultContact = state.searchContacts.find(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      resultContact = Object.assign({}, resultContact, {
        activity_notes: action.noteInfo.note,
      });
      let resultIndex = state.searchContacts.findIndex(
        (i) => i.contact_id === action.noteInfo.contact_id
      );
      let newResultContact = [
        ...state.searchContacts.slice(0, resultIndex),
        resultContact,
        ...state.searchContacts.slice(resultIndex + 1),
      ];
      return Object.assign({}, state, {
        searchContacts: newResultContact,
        toast: { show: true, success: true, message: action },
        givebackNoteStatus: "",
      });

    //update starring in each contact to refresh that contact component with correct star onClick
    case types.UPDATE_DEFAULT_CONTACT_STARRED_SUCCESS:
      let contactUpdate = state.defaultContacts.data.find(
        (i) => i.contact_id === action.contact_id
      );
      if (action.contact_starred === 0) {
        contactUpdate = Object.assign({}, contactUpdate, { owner_starred: 1 });
      } else {
        contactUpdate = Object.assign({}, contactUpdate, { owner_starred: 0 });
      }
      let indexUpdate = state.defaultContacts.data.findIndex(
        (i) => i.contact_id === action.contact_id
      );
      let newDefaultContactUpdate = [
        ...state.defaultContacts.data.slice(0, indexUpdate),
        contactUpdate,
        ...state.defaultContacts.data.slice(indexUpdate + 1),
      ];
      let newCompleteDefaultContactUpdate = {
        contacts: newDefaultContactUpdate,
        today_call_total: state.defaultContacts.today_call_total,
      };
      return Object.assign({}, state, {
        defaultContacts: newCompleteDefaultContactUpdate,
        toast: { show: true, success: true, message: action.message },
        loading: false,
      });

    case types.UPDATE_GENERAL_STARRED_SUCCESS:
      return Object.assign({}, state, {
        toast: { show: true, success: true, message: action.message },
        loading: false,
      });

    case types.UPDATE_STARRED_CONTACT_STARRED_SUCCESS:
      let starredContactUpdate = state.starredContacts.contacts.find(
        (i) => i.contact_id === action.contact_id
      );
      if (action.contact_starred === 0) {
        starredContactUpdate = Object.assign({}, starredContactUpdate, {
          owner_starred: 1,
        });
      } else {
        starredContactUpdate = Object.assign({}, starredContactUpdate, {
          owner_starred: 0,
        });
      }
      let starredIndexUpdate = state.starredContacts.contacts.findIndex(
        (i) => i.contact_id === action.contact_id
      );
      let newStarredContactUpdate = [
        ...state.starredContacts.contacts.slice(0, starredIndexUpdate),
        starredContactUpdate,
        ...state.starredContacts.contacts.slice(starredIndexUpdate + 1),
      ];
      let newCompleteStarredContactUpdate = {
        contacts: newStarredContactUpdate,
        today_call_total: state.starredContacts.today_call_total,
      };
      return Object.assign({}, state, {
        starredContacts: newCompleteStarredContactUpdate,
        toast: { show: true, success: true, message: action.message },
        loading: false,
      });

    case types.UPDATE_PWIP_CONTACT_STARRED_SUCCESS:
      let pwIPContactUpdate = state.pwipContacts.contacts.find(
        (i) => i.contact_id === action.contact_id
      );
      if (action.contact_starred === 0) {
        pwIPContactUpdate = Object.assign({}, pwIPContactUpdate, {
          owner_starred: 1,
        });
      } else {
        pwIPContactUpdate = Object.assign({}, pwIPContactUpdate, {
          owner_starred: 0,
        });
      }
      let pwIPIndexUpdate = state.pwipContacts.contacts.findIndex(
        (i) => i.contact_id === action.contact_id
      );
      let newpwIPContactUpdate = [
        ...state.pwipContacts.contacts.slice(0, pwIPIndexUpdate),
        pwIPContactUpdate,
        ...state.pwipContacts.contacts.slice(pwIPIndexUpdate + 1),
      ];
      let newCompletepwIPContactUpdate = {
        contacts: newpwIPContactUpdate,
        today_call_total: state.pwipContacts.today_call_total,
      };
      return Object.assign({}, state, {
        pwipContacts: newCompletepwIPContactUpdate,
        toast: { show: true, success: true, message: action.message },
        loading: false,
      });

    case types.UPDATE_CLOSING_CONTACT_STARRED_SUCCESS:
      let ccContactUpdate = state.closingClientContacts.contacts.find(
        (i) => i.contact_id === action.contact_id
      );
      if (action.contact_starred === 0) {
        ccContactUpdate = Object.assign({}, ccContactUpdate, {
          owner_starred: 1,
        });
      } else {
        ccContactUpdate = Object.assign({}, ccContactUpdate, {
          owner_starred: 0,
        });
      }
      let ccIndexUpdate = state.closingClientContacts.contacts.findIndex(
        (i) => i.contact_id === action.contact_id
      );
      let newCCContactUpdate = [
        ...state.closingClientContacts.contacts.slice(0, ccIndexUpdate),
        ccContactUpdate,
        ...state.closingClientContacts.contacts.slice(ccIndexUpdate + 1),
      ];
      let newCompleteCCContactUpdate = {
        contacts: newCCContactUpdate,
        today_call_total: state.closingClientContacts.today_call_total,
      };
      return Object.assign({}, state, {
        closingClientContacts: newCompleteCCContactUpdate,
        toast: { show: true, success: true, message: action.message },
        loading: false,
      });

    case types.UPDATE_RESULT_CONTACT_STARRED_SUCCESS:
      let resultContactUpdate = state.searchContacts.find(
        (i) => i.contact_id === action.contact_id
      );
      if (action.contact_starred === 0) {
        resultContactUpdate = Object.assign({}, resultContactUpdate, {
          owner_starred: 1,
        });
      } else {
        resultContactUpdate = Object.assign({}, resultContactUpdate, {
          owner_starred: 0,
        });
      }
      let resultIndexUpdate = state.searchContacts.findIndex(
        (i) => i.contact_id === action.contact_id
      );
      let newResultContactUpdate = [
        ...state.searchContacts.slice(0, resultIndexUpdate),
        resultContactUpdate,
        ...state.searchContacts.slice(resultIndexUpdate + 1),
      ];
      return Object.assign({}, state, {
        searchContacts: newResultContactUpdate,
        toast: { show: true, success: true, message: action.message },
        loading: false,
      });

    case types.CONTACT_GIVEBACK_CHECK_NOTE:
      return Object.assign({}, state, { givebackNoteStatus: action.status });

    case types.GET_GIVEBACK_REASON_CODES:
      return Object.assign({}, state, { reasonCodes: action.data });
    case types.CONTACT_GIVEBACK_SUCCESS:
      let currentContactIndex = state[action.currentArray].contacts;
      let givebackIndex = currentContactIndex.findIndex(
        (contact) => contact.contact_id === action.contact_id
      );
      let currentContactList = state[action.currentArray].contacts;
      currentContactList.splice(givebackIndex, 1);
      let newCompleteArray = {
        contacts: currentContactList,
        today_call_total: state[action.currentArray].contacts.today_call_total,
      };
      let newContactsObj = {};
      newContactsObj[action.currentArray] = newCompleteArray;
      return Object.assign({}, state, {
        newContactsObj,
        toast: {
          show: true,
          success: true,
          message: "Contact Giveback Success",
        },
        givebackNoteStatus: "",
      });

    case types.CONTACT_GIVEBACK_FAILED:
      return Object.assign({}, state, {
        success: false,
        message: action.message,
      });

    case types.SET_CONTACT_SCROLL_P0S:
      return Object.assign({}, state, { previousScrollPos: action.position });
    case types.LOADING_REONOMY_PROPERTIES:
      return Object.assign({}, state, {
        noRenomyProperties: false,
        loading: true,
        reonomyProperties: {},
      });
    case types.NO_REONOMY_PROPERTIES:
      return Object.assign({}, state, {
        noRenomyProperties: true,
        loading: false,
        reonomyProperties: {},
      });
    case types.LOAD_REONOMY_PROPERTIES:
      return Object.assign({}, state, {
        noRenomyProperties: false,
        loading: false,
        reonomyProperties: action.data,
      });
    case types.LOADING_CONTACT_PAGE_INFO:
      return Object.assign({}, state, {
        previousPage: "",
        nextPage: "",
        loadingPageInfo: true,
      });
    case types.CONTACT_PREVIOUS_PAGE:
      return Object.assign({}, state, {
        previousPage: action.data.contact_id,
        contact_section: action.data.contact_section,
        loadingPageInfo: false,
      });
    case types.NO_CONTACT_PREVIOUS_PAGE:
      return Object.assign({}, state, {
        previousPage: action.data.contact_id,
        loadingPageInfo: false,
      });
    case types.CONTACT_NEXT_PAGE:
      return Object.assign({}, state, {
        nextPage: action.data.contact_id,
        contact_section: action.data.contact_section,
        loadingPageInfo: false,
      });
    case types.NO_CONTACT_NEXT_PAGE:
      return Object.assign({}, state, {
        nextPage: action.data.contact_id,
        loadingPageInfo: false,
      });

    case types.CHANGE_CLIENT_RATING_SUCCESS:
      if (action.currentArray) {
        let localContact = state[action.currentArray].contacts;
        let localContactIndex = localContact.findIndex(
          (contact) => contact.contact_id === action.contact_id
        );
        localContact[localContactIndex].client_rating = state.ratingList.find(
          (r) => r.list_type_id === parseInt(action.rating, 10)
        ).value;
      } else {
        let searchedContacts = state.searchContacts.contacts;
        let updatedContactIndex = searchedContacts.findIndex(
          (contact) => contact.contact_id === action.contact_id
        );
        searchedContacts[
          updatedContactIndex
        ].client_rating = state.ratingList.find(
          (r) => r.list_type_id === parseInt(action.rating, 10)
        ).value;
        Object.assign({}, state, { searchedContacts: searchedContacts });
      }
      return Object.assign({}, state, {
        toast: { show: true, success: true, message: action.message },
        updateRatingSuccess: true,
      });
    case types.CHANGE_CLIENT_RATING_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
        updateRatingSuccess: false,
      });
    case types.LOAD_CLIENT_STATUS:
      return Object.assign({}, state, { clientStatus: action.data });
    case types.SUBMIT_CLIENT_STATUS_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });
    case types.SUBMIT_CLIENT_STATUS_SUCCESS:
      return Object.assign({}, state, {
        toast: { show: true, success: true, message: action.message },
      });
    case types.LOAD_LP_SERVICE_STATUS:
      return Object.assign({}, state, { lpServiceStatus: action.data });
    case types.SUBMIT_LP_STATUS_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: false, message: action.message },
      });
    case types.SUBMIT_LP_STATUS_SUCCESS:
      return Object.assign({}, state, {
        toast: { show: true, success: true, message: action.message },
      });
    case types.GET_CONTACT_RATING_LIST:
      return Object.assign({}, state, { ratingList: action.data });

    case types.START_GET_CONTACT:
      return Object.assign({}, state, { contact: {}, loading: true });
    case types.GET_CONTACT_BY_ID:
      return Object.assign({}, state, {
        contact: action.contact,
        loading: false,
      });
    case types.GET_JOB_DESCRIPTION_LIST:
      return Object.assign({}, state, { jobDescriptionTypes: action.data });
    case types.GET_CONTACT_TYPE_LIST:
      return Object.assign({}, state, { contactTypes: action.data });
    case types.GET_CONTACT_INFO_TYPE_LIST:
      return Object.assign({}, state, { contactInfoTypes: action.data });
    case types.GET_PREFIX_TYPE_LIST:
      return Object.assign({}, state, { prefixTypes: action.data });
    case types.GET_HOLIDAY_GREETING_TYPE_LIST:
      return Object.assign({}, state, { holidayGreetingTypes: action.data });
    case types.GET_GAVE_GIFT_TYPE_LIST:
      return Object.assign({}, state, { gaveGiftTypes: action.data });
    case types.GET_STATE_LIST:
      return Object.assign({}, state, { stateList: action.data });
    case types.START_SUBMIT_CONTACT:
      return Object.assign({}, state, { loading: true });
    case types.CREATE_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        submitContact: initialState.submitContact,
        toast: { show: true, success: true, message: action.message },
        contactId: action.data,
        loading: false,
      });
    case types.CREATE_CONTACT_FAILED:
      return Object.assign({}, state, {
        submitContact: { success: false, message: action.message },
        loading: false,
      });
    case types.UPDATE_CONTACT_SUCCESS:
      return Object.assign({}, state, {
        submitContact: initialState.submitContact,
        toast: { show: true, success: true, message: action.message },
        contactId: action.data,
        loading: false,
      });
    case types.UPDATE_CONTACT_FAILED:
      return Object.assign({}, state, {
        submitContact: { success: false, message: action.message },
        loading: false,
      });
    case types.CLEAR_CONTACT:
      return Object.assign({}, state, { contact: initialState.contact });
    case types.START_DELETE_CONTACT:
      return Object.assign({}, state, { contactDeleted: false });
    case types.DELETE_CONTACT:
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
        contactDeleted: true,
      });
    case types.CLOSE_TOAST:
      return Object.assign({}, state, {
        toast: { show: false, success: true, message: "" },
      });
    default:
      return state;
  }
}
