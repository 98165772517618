import React from "react";
import { connect } from "react-redux";
import * as maxLoanActions from "../../../actions/calculatorActions/maxLoanActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentInput from "../calculatorCommon/PercentInput";
import NumberInput from "../calculatorCommon/NumberInput";
import DollarDisplay from "../calculatorCommon/DollarDisplay";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import * as centralStateActions from "../../../actions/calculatorActions/centralActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import formatValue from "../calculatorCommon/FormatValueMethod";
import formatDisplay from "../calculatorCommon/FormatDisplayMethod";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";

//renamed initialInterestRate to interestRate so that the Central State Reducer will have matching names.
class MaxLoan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maximumLoan: this.props.maximumLoan,
      annualUnderwrittenNOI: this.props.annualUnderwrittenNOI,
      minimumDSCRRequired: this.props.minimumDSCRRequired,
      interestRate: this.props.interestRate,
      amortizationYears: this.props.amortizationYears,
      amortizationMonths: this.props.amortizationMonths,

      modalOpen: false,
      from: "",
      to: "",
      cc: "",
    };

    this.update = this.update.bind(this);
    this.updateCentralState = this.updateCentralState.bind(this);
  }

  update(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      this.props.dispatch(
        maxLoanActions.calculateMaxLoan(this.state, { name, value })
      );
    });
  }

  updateCentralState(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    if (name === "amortizationYears") {
      const monthsName = "amortizationMonths";
      const monthsValue = value * 12;
      let stateObj2 = {};
      stateObj2[monthsName] = monthsValue;
      this.setState(stateObj, function () {
        this.props.dispatch(
          centralStateActions.updateCentralValues(monthsName, monthsValue)
        );
      });
    } else if (name === "amortizationMonths") {
      const yearsName = "amortizationYears";
      const yearsValue = value / 12;
      let stateObj2 = {};
      stateObj2[yearsName] = yearsValue;
      this.setState(stateObj, function () {
        this.props.dispatch(
          centralStateActions.updateCentralValues(yearsName, yearsValue)
        );
      });
    }

    this.setState(stateObj, function () {
      this.props.dispatch(centralStateActions.updateCentralValues(name, value));
      this.props.dispatch(
        maxLoanActions.calculateMaxLoan(this.state, { name, value })
      );
    });
  }

  componentDidMount() {
    this.update("annualUnderwrittenNOI", this.state.annualUnderwrittenNOI);
  }

  openSharingCalculatorModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateCC(value) {
    this.setState({ cc: value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ modalOpen: false }, function () {
      var input = [
        { input0: formatValue(this.state.annualUnderwrittenNOI).toString() },
        { input1: (this.state.minimumDSCRRequired + "%").toString() },
        { input2: (this.state.interestRate + "%").toString() },
        { input3: this.state.amortizationYears.toString() },
        { input4: this.state.amortizationMonths.toString() },
      ];

      var output = [
        { output0: formatDisplay(this.state.maximumLoan).toString() },
      ];

      this.props.dispatch(
        sharingCalculationsActions.calculatorSharing(
          this.state.to,
          this.state.from,
          this.state.cc,
          "MaxLoan",
          input,
          output
        )
      );
    });
  }

  render() {
    return (
      <div>
        <Row>
          <ShareCalcModal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal.bind(this)}
            onAfterOpen={() => {}}
            onSubmit={this.onSubmit.bind(this)}
            from={this.state.from}
            to={this.state.to}
            cc={this.state.cc}
            updateFrom={this.updateFrom.bind(this)}
            updateTo={this.updateTo.bind(this)}
            updateCC={this.updateCC.bind(this)}
          />
          <Panel className="calculator-card">
            <Form className="calculator-form" inline={true}>
              <h2 className="calculator-title">Max Loan Via DSCR</h2>
              <h4>Calculate my maximum loan based on DSCR</h4>
              <div className="margin-top"></div>
              <DollarInput
                name="annualUnderwrittenNOI"
                value={this.state.annualUnderwrittenNOI}
                update={this.updateCentralState}
                label="First Year NOI"
              />
              <br />
              <br />
              <PercentInput
                name="minimumDSCRRequired"
                value={this.state.minimumDSCRRequired}
                update={this.update}
                label="Minimum DSCR Required"
              />
              <PercentInput
                name="interestRate"
                value={this.state.interestRate}
                update={this.updateCentralState}
                label="Initial Interest Rate"
              />
              <br />
              <br />
              <NumberInput
                name="amortizationYears"
                value={this.state.amortizationYears}
                update={this.updateCentralState}
                label="Amortization Years"
              />
              <NumberInput
                name="amortizationMonths"
                value={this.state.amortizationMonths}
                update={this.updateCentralState}
                label="Amortization Months"
              />
            </Form>
            <div id="boxes">
              <p
                className={
                  this.state.annualUnderwrittenNOI &&
                  this.state.minimumDSCRRequired &&
                  this.state.interestRate &&
                  this.state.amortizationYears
                    ? "share-calc"
                    : "hidden"
                }
                onClick={this.openSharingCalculatorModal.bind(this)}
              >
                <i className="material-icons">share</i>Share Calculations
              </p>
              <div className="box">
                <p className="box-title">Maximum Loan</p>
                <p className="amount">
                  <DollarDisplay
                    name="maximumLoan"
                    value={this.state.maximumLoan}
                  />
                </p>
              </div>
            </div>
          </Panel>
        </Row>

        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>{" "}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const state1 = state.maxLoan;
  const state2 = state.centralValues;
  return {
    maximumLoan: state1.maximumLoan || "",
    annualUnderwrittenNOI: state2.annualUnderwrittenNOI || "",
    minimumDSCRRequired: state1.minimumDSCRRequired || 1.25,
    interestRate: state2.interestRate || "",
    amortizationYears: state2.amortizationYears || "",
    amortizationMonths: state2.amortizationMonths || "",
  };
}

export default connect(mapStateToProps)(MaxLoan);
