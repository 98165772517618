/* Extensible Button Component */

import React from "react";

export default class Button extends React.Component {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
  }

  // We're not binding the _clickhanlder and will instead .call it passing the value back
  _handleClick() {
    var val = this.props.text;
    this.props._clickHandler.call(null, val);
  }

  render() {
    // We can destructure to pass the classes if they are available
    var { text } = this.props;
    return (
      <button
        onClick={this._handleClick}
        className={this.props.className}
        data-val={text}
      >
        {text}
      </button>
    );
  }
}
