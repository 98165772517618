import * as types from "../actions/actionTypes";

const initialState = {
  inProcessDeals: [],
  loading: true,
  noQuotes: false,
  toastShowing: false,
};

export default function inProcessPipelineReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_IN_PROCESS_PIPELINE:
      return { inProcessDeals: action.data };
    case types.NO_IN_PROCESS_PIPELINE:
      return Object.assign({}, initialState, {
        loading: false,
        noQuotes: true,
      });
    case types.NO_VIOLATIONS_FEEDBACK_TOAST:
      return Object.assign({}, state, { toastShowing: true });
    case types.CLOSE_VIOLATIONS_FEEDBACK_TOAST:
      return Object.assign({}, state, { toastShowing: false });
    default:
      return state;
  }
}
