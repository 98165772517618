import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Row from "muicss/lib/react/row";
import Col from "muicss/lib/react/col";
import Button from "muicss/lib/react/button";
import { NavLink } from "react-router-dom";
import Modal from "react-modal";
import * as navigationTitleActions from "../../actions/navigationTitleActions";
import * as myLoansActions from "../../actions/loansActions";
import { Navigation } from "../../lib";

class BankerPortfolioContainer extends React.Component {
  state = {
    search: "",
    modalSearch: false,
  };

  componentDidMount() {
    this.props.navActions.updateNavTitle("My Bank Portfolio");
  }

  onChange = (e) => {
    this.setState({ search: e.target.value });
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      let search = e.target.value;
      this.doSearch(search);
    }
  };

  clearSearch = () => {
    this.setState({ search: "" }, function () {
      this.props.actions.updateLoanSearchValue(this.state.search);
      Navigation.go(window.location.pathname);
    });
  };

  doSearch = (searchTerm) => {
    let search = searchTerm.match(/\w+/g);
    let formattedSearch = "";
    if (!search) {
      this.clearSearch();
    } else {
      search.forEach((elem, i) => {
        if (search.length - 1 === i) {
          formattedSearch += elem;
        } else {
          formattedSearch += elem + "+";
        }
      });
      this.props.actions.updateLoanSearchValue(formattedSearch);
      Navigation.go(window.location.pathname);
    }
  };

  openModalSearch = () => {
    this.setState({ modalSearch: true });
  };

  closeModalSearch = () => {
    this.setState({ modalSearch: false });
  };

  render() {
    return (
      <Row>
        <Row className="sub-header">
          <div className="inner-subheader-container">
            <Col className="loan-links">
              <ul>
                <li>
                  <NavLink
                    to={`/loans/my-loans`}
                    activeClassName="header-active-link"
                  >
                    My Loans
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={`/loans/all-loans`}
                    activeClassName="header-active-link"
                  >
                    All Loans
                  </NavLink>
                </li>
              </ul>
            </Col>
            <div className="loan-search-button">
              <i
                className="material-icons search-icon"
                onClick={this.openModalSearch}
              >
                search
              </i>
            </div>
            <Col className="loan-search-filter">
              <div className="loans-search-container">
                <div className="search-form">
                  <i className="material-icons white search-icon">search</i>
                  <input
                    className="input contact-input"
                    type="text"
                    placeholder="Search Address"
                    ref="searchBox"
                    value={this.state.search}
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                  />
                  <i
                    className="material-icons white close-search"
                    onClick={this.clearSearch}
                  >
                    close
                  </i>
                </div>
              </div>
            </Col>
          </div>
        </Row>
        <Row>
          <div className="qts-container">{this.props.children}</div>
        </Row>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalSearch}
          onRequestClose={this.closeModalSearch}
        >
          <PopupSearchForm
            search={this.state.search}
            onChange={this.onChange}
            onClose={this.closeModalSearch}
            onSearch={this.doSearch}
          />
        </Modal>
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    navTitle: state.navTitle,
    loans: state.loans,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    navActions: bindActionCreators(navigationTitleActions, dispatch),
    actions: bindActionCreators(myLoansActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankerPortfolioContainer);

function _PopupSearchForm({ onChange, onClose, onSearch, search }) {
  const onSubmit = React.useCallback(
    (e) => {
      e.preventDefault();
      onClose();
      onSearch(search);
    },
    [search, onClose, onSearch]
  );

  React.useEffect(() => {
    // On iPhone putting `autoFocus` property on the search input causes
    // a visual bug where the form is not visible. This is due to the fact
    // that the modal animation is still happening when focus is applied.
    // Therefore, we delay focus until after animation is completed.
    const timer = setTimeout(() => {
      const input = document.getElementById("loan-search-input-box");
      if (input) {
        input.focus();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, []);
  return (
    <form className="loan-search-popup" onSubmit={onSubmit}>
      <div className="loan-search-header">
        <div>&nbsp;</div>
        <i className="material-icons close-search" onClick={onClose}>
          close
        </i>
      </div>
      <div className="loan-search-inputs">
        <input
          id="loan-search-input-box"
          // autoFocus // Cannot use autoFocus AND animated modal with iPhone.
          type="text"
          placeholder="Search Address"
          value={search}
          onChange={onChange}
        />
      </div>
      <div className="loan-search-footer">
        <Button type="submit">Search</Button>
      </div>
    </form>
  );
}
const PopupSearchForm = React.memo(_PopupSearchForm);
