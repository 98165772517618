import React from "react";
import { connect } from "react-redux";
import * as capRateActions from "../../../actions/calculatorActions/capRateActions";
import * as sharingCalculationsActions from "../../../actions/calculatorActions/sharingCalculationsActions";
import DollarInput from "../calculatorCommon/DollarInput";
import PercentDisplay from "../calculatorCommon/PercentDisplay";
import Row from "muicss/lib/react/row";
import Panel from "muicss/lib/react/panel";
import Form from "muicss/lib/react/form";
import formatValue from "../calculatorCommon/FormatValueMethod";
import ShareCalcModal from "../calculatorCommon/shareCalculationsModal";

class CapRate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salesPrice: this.props.salesPrice,
      NOI: this.props.NOI,
      capRate: this.props.capRate,

      modalOpen: false,
      from: "",
      to: "",
      cc: "",
    };

    this.update = this.update.bind(this);
  }

  componentWillUnmount() {
    this.props.dispatch(capRateActions.updateOnUnmount(this.state));
  }

  update(name, value) {
    let stateObj = {};
    stateObj[name] = value;
    this.setState(stateObj, function () {
      if (this.state.salesPrice && this.state.NOI) {
        this.props.dispatch(capRateActions.calculateCapRate(this.state));
      } else {
        this.props.dispatch(capRateActions.updateValuesCapRate(this.state));
      }
    });
  }
  openSharingCalculatorModal() {
    this.setState({
      modalOpen: true,
    });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  updateFrom(value) {
    this.setState({ from: value });
  }

  updateTo(value) {
    this.setState({ to: value });
  }

  updateCC(value) {
    this.setState({ cc: value });
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({ modalOpen: false }, function () {
      var input = [
        { input0: formatValue(this.state.salesPrice).toString() },
        { input1: formatValue(this.state.NOI).toString() },
      ];

      var output = [
        {
          output0: (parseFloat(this.state.capRate).toFixed(2) + "%").toString(),
        },
      ];

      this.props.dispatch(
        sharingCalculationsActions.calculatorSharing(
          this.state.to,
          this.state.from,
          this.state.cc,
          "CapRate",
          input,
          output
        )
      );
    });
  }

  render() {
    return (
      <div>
        <Row>
          <ShareCalcModal
            isOpen={this.state.modalOpen}
            onRequestClose={this.closeModal.bind(this)}
            onAfterOpen={() => {}}
            onSubmit={this.onSubmit.bind(this)}
            from={this.state.from}
            to={this.state.to}
            cc={this.state.cc}
            updateFrom={this.updateFrom.bind(this)}
            updateTo={this.updateTo.bind(this)}
            updateCC={this.updateCC.bind(this)}
          />
          <Panel className="calculator-card">
            <Form className="calculator-form" inline={true}>
              <h2 className="calculator-title">Cap Rate</h2>
              <div className="margin-top"></div>
              <DollarInput
                name="salesPrice"
                value={this.state.salesPrice}
                update={this.update}
                label="Sales Price"
              />
              <DollarInput
                name="NOI"
                value={this.state.NOI}
                update={this.update}
                label="NOI"
              />
            </Form>
            <div id="boxes">
              <p
                className={
                  this.state.salesPrice && this.state.NOI
                    ? "share-calc"
                    : "hidden"
                }
                onClick={this.openSharingCalculatorModal.bind(this)}
              >
                <i className="material-icons">share</i>Share Calculations
              </p>
              <div className="box">
                <p className="box-title">Cap Rate</p>
                <p className="amount">
                  <PercentDisplay name="capRate" value={this.state.capRate} />
                </p>
              </div>
            </div>
          </Panel>
        </Row>

        <div className="info">
          <p>
            <i className="material-icons">email</i>For a detailed discussion of
            these numbers or to discuss any of your commercial real estate
            financing needs, please contact an Eastern Union broker by clicking{" "}
            <a href="mailto:qts@easternunion.com">here</a>
          </p>
          <p>
            <i className="material-icons">info</i>Calculator results shall not
            be relied on for making financial decisions. Actual calculators may
            rely on estimates and rounding. Before any final decisions are made,
            an expert should be consulted.
          </p>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  state = state.capRate;
  return {
    salesPrice: state.salesPrice || "",
    NOI: state.NOI || "",
    capRate: state.capRate || "",
  };
}

export default connect(mapStateToProps)(CapRate);
