import FormattedInput from "./FormattedInput";
import { formatWithCommas } from "../../lib";

class NumberInput extends FormattedInput {
  constructor(props) {
    super(props);

    this.state = {
      formattedValue: "",
    };
  }

  componentDidMount() {
    let formattedValue = this.props.value
      ? this.formatValue(this.props.value)
      : "";
    this.setState({ formattedValue: formattedValue });
    this.validate(
      this.props.value,
      this.props.minValue,
      this.props.maxValue,
      this.props.required
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.value !== nextProps.value ||
      this.props.minValue !== nextProps.minValue ||
      this.props.maxValue !== nextProps.maxValue
    ) {
      this.validate(
        nextProps.value,
        nextProps.minValue,
        nextProps.maxValue,
        nextProps.required
      );
    }
  }

  formatValue(value) {
    value = value.toString();
    let regexExp =
      this.props.decimalPlaces && this.props.decimalPlaces > 0
        ? new RegExp("^\\d*(\\.\\d{0," + this.props.decimalPlaces + "})?$")
        : new RegExp("^\\d*?$");
    if (!value.match(regexExp)) {
      value = value.slice(0, -1);
    }
    return formatWithCommas(value);
  }

  stripFormat(value) {
    return value ? value.replace(/[^0-9.]/g, "") : null;
  }
}

export default NumberInput;
