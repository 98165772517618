import React from "react";
import { Link } from "react-router-dom";
import Form from "muicss/lib/react/form";
import Input from "muicss/lib/react/input";
import Button from "muicss/lib/react/button";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
    };

    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUsernameChange(e) {
    this.setState({
      username: e.target.value,
    });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  }

  render() {
    return (
      <div className="account-activation-page">
        <div className="account-activation-logo">
          <Link to="/">
            <img
              id="logo"
              src={"/images/eastern-union-white-small.png"}
              alt="Eastern Union Logo"
            />
          </Link>
        </div>
        <div className="account-activation-panel panel">
          <p className="account-activation-header">Login to your account</p>
          <Form onSubmit={this.handleSubmit}>
            <Input
              autoFocus
              onChange={this.handleUsernameChange}
              label="User Name or E-mail"
              required={true}
              floatingLabel={true}
            />
            <Input
              type="password"
              onChange={this.handlePasswordChange}
              label="Password"
              required={true}
              floatingLabel={true}
            />
            <Button type="submit" value="submit">
              Login
            </Button>
          </Form>
          <div id="message">{this.props.message}</div>
          <div className="account-activation-panel-footer flex-justify-center">
            <Link
              to="/register"
              className="xs-text orange-hover accent-text-light"
            >
              Not a member? Register
            </Link>
            <Link
              to="/forgot-password"
              className="xs-text accent-text-light orange-hover"
            >
              Forgot Password
            </Link>
          </div>
        </div>
        {/*<div className="account-activation-panel panel">*/}
        {/*<p className="account-activation-header">Account already exists</p>*/}
        {/*<p>For support please contact</p>*/}
        {/*<p><a href="mailto:app@easternunion.com" className="orange-text orange-text-hover pointer">app@easternunion.com</a>*/}
        {/*</p>*/}
        {/*<div className="account-activation-panel-footer account-activation-panel-footer-margin-top-extra text-center">*/}
        {/*<a href="/login" className="xs-text orange-hover accent-text-light">Back to Login</a>*/}
        {/*</div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default LoginPage;
