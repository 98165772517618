import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import Select from "react-select";
import {Button} from "@material-ui/core"
import RateReviewIcon from '@material-ui/icons/RateReview';
import NumberFormat from "react-number-format";

import * as dealReviewActions from "../../actions/dealReviewActions";

import ClientModal from "../common/ClientModal";
import FormattedDatePicker from "../common/FormattedDatePicker";

class DealReviewCard extends React.PureComponent {
  state = {
    clientModalOpen: false,
  };

  openClientModal = async (clientID) => {
    await this.props.dealReviewActions.getFullClientInfo(clientID);
    this.setState({ clientModalOpen: true });
  };

  render() {
    const {
      deal,
      dealStatuses,
      dealTypes,
      getOriginatingBroker,
      index,
      loanTypes,
      openDetailsSidebar,
      updateDealReview,
      updateDealReviewDebounce,
    } = this.props;
    const { clientModalOpen } = this.state;
    return (
      <React.Fragment>
        <div className="card deal-review-card">
          <div className="deal-review-card-header flex flex-space-justify">
            <div>
              <p
                className={`bold-text medium-text mb-4 ${
                  deal.deal_status === "2" && deal.forecast === "below"
                    ? "red-text"
                    : ""
                }`}
              >
                {this.props.dealReview.deals.currentPage > 1
                  ? (this.props.dealReview.deals.currentPage - 1) * 200 +
                    (index + 1)
                  : index + 1}{" "}
                | {deal.deal_name}
              </p>
              <p className="xs-text">
                {deal.properties[0] && deal.properties[0].property_type_value}
              </p>
            </div>
            <Select
              className="deal-review-filter-form-select"
              classNamePrefix="deal-review-filter-form-select"
              options={
                dealStatuses &&
                dealStatuses.map((dealStatus) => ({
                  key: dealStatus.value,
                  value: dealStatus.value,
                  label: dealStatus.value,
                  data: dealStatus,
                }))
              }
              onChange={(option) =>
                updateDealReview(
                  deal.deal_id,
                  "deal_status",
                  option.data.list_type_id
                )
              }
              defaultValue={{
                label: deal.deal_status_value,
                value: deal.deal_status_value,
              }}
            />
          </div>
          <div className="deal-review-card-grid">
            <div>
              <p className="xs-text accent-text-light mb-4">Client</p>
              <div className="deal-review-filter-form-input">
                {deal.primary_contact_id ? (
                  <p
                    className="orange-hover"
                    onClick={() => this.openClientModal(deal.primary_contact_id)}
                  >
                    {deal.primary_contact_first_name}{" "}
                    {deal.primary_contact_last_name}
                  </p>
                ) : (
                  <p>--</p>
                )}
              </div>
            </div>
            <div>
              <p className="xs-text accent-text-light mb-4">
                Originating Broker
              </p>
              <div className="deal-review-filter-form-input">
                {getOriginatingBroker(deal.deals_brokers)}
              </div>
            </div>
            <div>
              <p className="xs-text accent-text-light mb-4">Loan Amount</p>
              <div className="deal-review-dollar-input">
                <NumberFormat
                  className="deal-review-input"
                  thousandSeparator={true}
                  defaultValue={deal.loan_amount}
                  onValueChange={(val) =>
                    updateDealReviewDebounce(
                      deal.deal_id,
                      "loan_amount",
                      val.value
                    )
                  }
                />
              </div>
            </div>
            <div>
              <p className="xs-text accent-text-light mb-4">Deal Type</p>
              <Select
                className="deal-review-filter-form-select"
                classNamePrefix="deal-review-filter-form-select"
                isSearchable={true}
                onChange={(option) =>
                  updateDealReview(deal.deal_id, "deal_type", option.value)
                }
                options={
                  dealTypes &&
                  dealTypes.map((dealType) => ({
                    key: dealType.value,
                    value: dealType.value,
                    label: dealType.value,
                    data: dealType,
                  }))
                }
                placeholder="Select"
                defaultValue={{ label: deal.deal_type, value: deal.deal_type }}
              />
            </div>
            <div>
              <p className="xs-text accent-text-light mb-4">Loan Type</p>
              <Select
                className="deal-review-filter-form-select"
                classNamePrefix="deal-review-filter-form-select"
                isSearchable={true}
                onChange={(option) =>
                  updateDealReview(deal.deal_id, "loan_type", option.value)
                }
                options={
                  loanTypes &&
                  loanTypes.map((loanType) => ({
                    key: loanType.value,
                    value: loanType.value,
                    label: loanType.value,
                    data: loanType,
                  }))
                }
                placeholder="Select"
                defaultValue={{ label: deal.loan_type, value: deal.loan_type }}
              />
            </div>
            {(deal.deal_status === "1" || deal.deal_status === "10") && (
              <React.Fragment>
                <div>
                  <p className="xs-text accent-text-light mb-4">Rating</p>
                  <Select
                    className="deal-review-filter-form-select"
                    classNamePrefix="deal-review-filter-form-select"
                    isSearchable={true}
                    onChange={(option) =>
                      updateDealReview(
                        deal.deal_id,
                        "deal_rating",
                        option.value
                      )
                    }
                    options={[
                      { label: 0, value: 0 },
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                    ]}
                    placeholder="Select"
                    defaultValue={{
                      label: deal.deal_rating,
                      value: deal.deal_rating,
                    }}
                  />
                </div>
                <div>
                  <p className="xs-text accent-text-light mb-4">QTS Rating</p>
                  <Select
                    className="deal-review-filter-form-select"
                    classNamePrefix="deal-review-filter-form-select"
                    isSearchable={true}
                    onChange={(option) =>
                      updateDealReview(
                        deal.deal_id,
                        "qts_deal_rating",
                        option.value
                      )
                    }
                    options={[
                      { label: 0, value: 0 },
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                    ]}
                    placeholder="Select"
                    defaultValue={{
                      label: deal.qts_deal_rating,
                      value: deal.qts_deal_rating,
                    }}
                  />
                </div>
                <div>
                  <p className="xs-text accent-text-light mb-4">
                    QTS Last Reviewed
                  </p>
                  <div className="deal-review-filter-form-input">
                    { moment(deal.qts_last_review_date).isValid() ?  moment(deal.qts_last_review_date).format("MM/DD/YYYY") : null}
                  </div>
                </div>
                <div>
                  <p className="xs-text accent-text-light mb-4">
                    Expected IP Date
                  </p>
                  <FormattedDatePicker
                    dateValue={deal.expected_inprocess_date}
                    update={(value) =>
                      updateDealReview(
                        deal.deal_id,
                        "expected_inprocess_date",
                        value
                      )
                    }
                  />
                </div>
              </React.Fragment>
            )}
            {deal.deal_status === "2" ? (
              <React.Fragment>
                <div>
                  <p className="xs-text accent-text-light mb-4">
                    Processor Expected CL Date
                  </p>
                  <FormattedDatePicker
                    dateValue={deal.processor_ecl_date}
                    update={(value) =>
                      updateDealReview(
                        deal.deal_id,
                        "processor_ecl_date",
                        value
                      )
                    }
                  />
                </div>
                <div>
                  <p className="xs-text accent-text-light mb-4">
                    Expected CL Date
                  </p>
                  <FormattedDatePicker
                    dateValue={deal.expected_closing_date}
                    update={(value) =>
                      updateDealReview(
                        deal.deal_id,
                        "expected_closing_date",
                        value
                      )
                    }
                  />
                </div>
              </React.Fragment>
            ) : null}
            <div>
              <p className="xs-text accent-text-light mb-4">
                {deal.deal_status_value} Date
              </p>
              <div className="deal-review-filter-form-input">
                {moment(deal.status_change_date).format("MM/DD/YYYY")}
              </div>
            </div>
          </div>
          <div className="deal-review-card-footer">
            <div className="flex">
              <button
                className="btn btn-accent btn-large uppercase-text mr-24"
                onClick={() =>
                  updateDealReview(deal.deal_id, "last_review_date", new Date())
                }
              >
                Reviewed
              </button>
              <Button variant="contained" color="primary"
                className="btn btn-large uppercase-text mr-24"
                onClick={() => openDetailsSidebar(deal)}
              >
                Details
              </Button>
              { (deal.deal_status === "1" || deal.deal_status === "10") && (
                <Button variant="contained" color="secondary"
                  onClick={() => updateDealReview( deal.deal_id,"qts_last_review_date",new Date())}>
                  QTS <RateReviewIcon/>
                </Button>
              )}
            </div>
            {moment(deal.last_review_date).isValid() === true ? (
              <p className="xs-text accent-text-light mt-8">
                <i>
                  last reviewed on{" "}
                  {moment(deal.last_review_date).format("M/D/YYYY")}
                </i>
              </p>
            ) : null}
          </div>
        </div>
        {clientModalOpen && (
          <ClientModal
            isOpen={clientModalOpen}
            onRequestClose={() => this.setState({ clientModalOpen: false })}
            onAfterOpen={() => ({})}
            name={
              deal.primary_contact_first_name +
              " " +
              deal.primary_contact_last_name
            }
            loading={this.props.dealReview.client.loading}
            contact={this.props.dealReview.client.details}
            noContact={this.props.noContact}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    dealReview: state.dealReview,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dealReviewActions: bindActionCreators(dealReviewActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DealReviewCard);
