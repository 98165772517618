import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authenticationActions from "../../actions/authenticationActions";
import LoginPage from "./LoginPage";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
  }

  login(username, password) {
    this.props.actions.login(username, password);
  }

  render() {
    return (
      <LoginPage
        login={this.login}
        message={this.props.authentication.message}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authenticationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
