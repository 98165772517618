import * as types from "./actionTypes";
import config from "../config";
import { tokenExpired } from "./authenticationActions";
import { Navigation } from "../lib";
import { ajax } from "../lib";
import encodeQueryData from "../components/common/encodeQueryData";

export function loadSearchCompsPage(
  latitude,
  longitude,
  address,
  city,
  propType,
  radius,
  compRange,
  min_loan,
  max_loan,
  limitToCity
) {
  return async (dispatch) => {
    if (latitude && longitude) {
      if (!address) {
        address = await getAddressByLocation(latitude, longitude);
      }
      dispatch(
        loadSearchComps(
          latitude,
          longitude,
          address,
          city,
          propType,
          radius,
          compRange,
          min_loan,
          max_loan,
          limitToCity
        )
      );
    } else {
      latitude = 39.8282;
      longitude = -98.5795;
      address = await getAddressByLocation(latitude, longitude);
      dispatch({
        type: types.DEFAULT_SEARCH_COMP_LOAD,
        center: { latitude, longitude, property_address: address },
      });
    }
  };
}

export function loadSearchComps(
  lat,
  long,
  propertyAddress,
  city,
  prop_types,
  radius,
  compRange,
  min_loan,
  max_loan,
  limitToCity
) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_COMPS });

    let data = { lat, long, prop_types, radius, compRange, min_loan, max_loan };
    if (limitToCity) {
      data.city = city;
    }
    Object.keys(data).forEach(
      (key) => (data[key] === null || data[key] === "") && delete data[key]
    );

    ajax({
      url: `${config.BASE_URL}comps-page`,
      applyAuthHeader: true,
      data: data,
      success: (response) => {
        switch (response.status) {
          case "213":
            dispatch({
              type: types.COMPS_ERROR_MESSAGE,
              message: "no results",
            });
            break;
          case "212":
            dispatch({
              type: types.LOAD_SEARCH_COMPS,
              center: {
                latitude: lat,
                longitude: long,
                property_address: propertyAddress,
              },
            });
            dispatch({
              type: types.COMPS_ERROR_MESSAGE,
              message: response.message,
            });
            break;
          case "E107":
            dispatch({
              type: types.COMPS_ERROR_MESSAGE,
              message: response.message,
            });
            break;
          case "E403":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.LOAD_SEARCH_COMPS,
              data: response.data,
              center: {
                latitude: lat,
                longitude: long,
                property_address: propertyAddress,
                city: city,
              },
            });
            dispatch({ type: types.COMPS_LOADED });
            break;
        }
      },
    });

    if (long && lat) {
      dispatch(
        getTopBrokersInArea(
          lat,
          long,
          city,
          null,
          prop_types,
          radius,
          compRange
        )
      );
      dispatch(getQtsRep());
    }
  };
}

export async function getAddressByLocation(lat, lng) {
  let response = await ajax({
    url: "https://maps.googleapis.com/maps/api/geocode/json",
    data: {
      latlng: `${lat}, ${lng}`,
      key: "AIzaSyB5m-TTcU5bbzPdkNSZ9YplUYTodPc4ze4",
    },
  });

  return response.results.length ? response.results[0].formatted_address : null;
}

export function submitCompsSearch(searchString, prop_types, radius, compRange) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_COMPS });
    var address = searchString.replace(/^\s|\s$/g, "").replace(/\s/g, "+");
    ajax({
      url: "https://maps.googleapis.com/maps/api/geocode/json",
      data: { address, key: "AIzaSyB5m-TTcU5bbzPdkNSZ9YplUYTodPc4ze4" },
      success: function (response) {
        var latitudeAndLongitude = response.results[0].geometry.location;

        var fullCityObject = response.results[0].address_components.find(
          (obj) => obj.types.includes("locality")
        );

        if (!fullCityObject) {
          fullCityObject = response.results[0].address_components.find((obj) =>
            obj.types.includes("sublocality")
          );
        }

        var city = fullCityObject ? fullCityObject.short_name : "";

        if (city === "Lakewood Township") {
          city = "Lakewood";
        }

        let data = {
          latitude: latitudeAndLongitude.lat,
          longitude: latitudeAndLongitude.lng,
          address: encodeURIComponent(response.results[0].formatted_address),
          city,
          prop_types: prop_types ? encodeURIComponent(prop_types) : null,
          radius,
          compRange,
          limitToCity: false,
          previous_page: window.location.pathname + window.location.search,
        };

        Object.keys(data).forEach(
          (key) =>
            (data[key] === null ||
              data[key] === "" ||
              data[key] === undefined) &&
            delete data[key]
        );
        Navigation.go(`/comps?${encodeQueryData(data)}`);

        dispatch(
          updateSearchBarValue(response.results[0].formatted_address, city)
        );
      },
    });
  };
}

export function setPageAndScrollPos(page, scrollPosition) {
  return { type: types.SET_PAGE_AND_SCROLL_P0S, page, scrollPosition };
}

export function popPageAndScrollPos() {
  return { type: types.POP_PAGE_AND_SCROLL_P0S };
}

export function setCompsPage(page) {
  return { type: types.SET_COMPS_PAGE, page };
}

export function resetScrollPosition() {
  return { type: types.RESET_SCROLL_POS };
}

export function navBarClicked() {
  return { type: types.NAV_BAR_CLICKED };
}

export function mapLoaded() {
  return { type: types.MAP_LOADED };
}

export function clickCompsLink() {
  return { type: types.CLICK_COMPS_LINK };
}

export function updateSearchBarValue(value, city) {
  return {
    type: types.UPDATE_COMPS_SEARCH_BAR,
    value: value === null ? "" : value,
    city,
  };
}

export function closeCompsModal() {
  return { type: types.CLOSE_COMPS_MODAL };
}

export function closeErrorToast() {
  return { type: types.CLOSE_ERROR_TOAST };
}

export function closeLoadingToast() {
  return { type: types.CLOSE_LOADING_TOAST };
}

export function resetCompsState() {
  return { type: types.RESET_COMPS_STATE };
}

export function getFullContactInfo(contact_id) {
  const token = localStorage.getItem("auth_token");
  if (token) {
    return (dispatch) => {
      dispatch({ type: types.LOADING_FULL_CONTACT });
      ajax({
        url: `${config.BASE_URL}contact/${contact_id}`,
        applyAuthHeader: true,
        success: function (response) {
          if (response.data && response.data.length >= 1) {
            dispatch({
              type: types.LOAD_FULL_CONTACT,
              contact: response.data[0].contacts[0],
            });
          } else {
            dispatch({ type: types.NO_FULL_CONTACT });
          }
        },
      });
    };
  } else {
    return (dispatch) => {
      dispatch({ type: types.LOADING_FULL_CONTACT });
      ajax({
        url: `${config.BASE_URL}contact/${contact_id}`,
        success: function (response) {
          if (response.data && response.data.length >= 1) {
            dispatch({
              type: types.LOAD_FULL_CONTACT,
              contact: response.data[0].contacts[0],
            });
          } else {
            dispatch({ type: types.NO_FULL_CONTACT });
          }
        },
      });
    };
  }
}

export function getPropertyTypes() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}property-types`,
      success: function (response) {
        if (response.data) {
          dispatch({ type: types.LOAD_PROPERTY_TYPES, data: response.data });
        }
      },
    });
  };
}

export function requestQuote(
  first_name,
  last_name,
  email,
  phone,
  type,
  property_type,
  state,
  amount,
  year_built,
  loan_type
) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}request-quote/${type}`,
      data: {
        first_name,
        last_name,
        email,
        phone,
        type,
        property_type,
        state,
        amount,
        year_built,
        loan_type,
      },
      type: "POST",
      success: function (response) {
        switch (response.status) {
          case "200":
            dispatch({
              type: types.REQUEST_QUOTE,
              success: true,
              message: response.message,
            });
            break;
          default:
            dispatch({
              type: types.REQUEST_QUOTE,
              success: false,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function closeRequestQuoteToast() {
  return { type: types.CLOSE_REQUEST_QUOTE_TOAST };
}

export function getTopBrokersInArea(
  latitude,
  longitude,
  city,
  state,
  property_types,
  radius,
  date_range
) {
  let data = {
    latitude,
    longitude,
    city,
    state,
    property_types,
    radius,
    date_range,
  };
  Object.keys(data).forEach(
    (key) => (data[key] === null || data[key] === "") && delete data[key]
  );

  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}brokers/qts/location`,
      data: data,
      type: "GET",
      success: function (response) {
        switch (response.status) {
          case "200":
            dispatch({
              type: types.GET_TOP_BROKERS_IN_AREA,
              data: response.data.slice(0, 3),
            });
            break;
          default:
            dispatch({ type: types.GET_TOP_BROKERS_IN_AREA, data: [] });
            break;
        }
      },
    });
  };
}

export function getTopTenEUBrokers() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}brokers/active/10`,
      type: "GET",
      success: function (response) {
        switch (response.status) {
          case "200":
            dispatch({ type: types.GET_TOP_EU_BROKERS, data: response.data });
            break;
          default:
            dispatch({ type: types.GET_TOP_EU_BROKERS, data: [] });
            break;
        }
      },
    });
  };
}

export function getQtsRep() {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}brokers/qts`,
      type: "GET",
      success: function (response) {
        switch (response.status) {
          case "200":
            dispatch({
              type: types.GET_QTS_BROKER,
              data:
                response.data[Math.floor(Math.random() * response.data.length)],
            });
            break;
          default:
            dispatch({ type: types.GET_QTS_BROKER, data: {} });
            break;
        }
      },
    });
  };
}
