import React from "react";

class FormattedDisplay extends React.Component {
  render() {
    return (
      <span className="display-value" name={this.props.name}>
        {this.formatValue(this.props.value)}
      </span>
    );
  }
}

export default FormattedDisplay;
