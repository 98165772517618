import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../../actions/authenticationActions";
import Input from "muicss/lib/react/input";
import Button from "muicss/lib/react/button";
import Form from "muicss/lib/react/form";

class ResendActivationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errorMessage: "",
    };

    this.resendActivation = this.resendActivation.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  resendActivation(e) {
    e.preventDefault();
    if (!this.state.email || !this.state.password) {
      this.setState({
        errorMessage: "All fields must be complete",
      });
    } else {
      this.props.actions.resendActivation(
        this.state.email,
        this.state.password
      );
      this.setState({ errorMessage: "" });
    }
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  render() {
    return (
      <div className="account-activation-background">
        <div className="aa-logo">
          <img src="/images/gs-logo.png" alt="gs-logo" />
        </div>

        <div className="account-activation-panel small panel">
          <div>
            <div>
              <p className="bold-text large-text text-center">
                Request Activation Link
              </p>
            </div>
            <Form onSubmit={this.resendActivation}>
              <Input
                label="Email"
                floatingLabel={true}
                onChange={this.handleEmailChange}
              />
              <Input
                label="Password"
                type="password"
                floatingLabel={true}
                onChange={this.handlePasswordChange}
              />
              <p className="error-block">{this.state.errorMessage}</p>
              {this.props.auth.resendMessage ? (
                <div id="message">{this.props.auth.resendMessage}</div>
              ) : (
                ""
              )}
              <Button
                type="submit"
                variant="flat"
                className="button btn-accent btn-fullWidth mui--z1"
              >
                Resend Activation Link
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendActivationPage);
