import * as types from "../../actions/actionTypes";

export default function (state = { loading: false }, action) {
  switch (action.type) {
    case types.START_YIELD_MAINTENANCE_LOADING:
      return { loading: action.loading };
    case types.STOP_YIELD_MAINTENANCE_LOADING:
      return { loading: action.loading };
    default:
      return state;
  }
}
