import * as types from "../actionTypes";
import { updateCentralValues } from "../../actions/calculatorActions/centralActions";

function updateCashOnCash(values) {
  return { type: types.UPDATE_CASH_ON_CASH, values };
}

export function calculateCashOnCash(values, trigger) {
  return function (dispatch) {
    dispatch(calculate(values, trigger));
    dispatch(updateCashOnCash(values));
  };
}

function calculate(values, trigger) {
  return function (dispatch) {
    if (trigger.name === "closingCostAmount") {
      dispatch(updateCentralValues(trigger.name, trigger.value));
      values.closingCostAmount = trigger.value;
      if (values.purchasePrice && values.closingCostAmount) {
        values.closingCostPercent =
          (values.closingCostAmount / values.purchasePrice) * 100;
        values.closingCostPercent = parseFloat(
          values.closingCostPercent.toFixed(3)
        );
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      } else {
        values.closingCostPercent = 0;
        values.closingCostAmount = 0;
        dispatch(
          updateCentralValues("closingCostAmount", values.closingCostAmount)
        );
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      }
    } else if (trigger.name === "closingCostPercent") {
      values.closingCostPercent = trigger.value;
      dispatch(updateCentralValues(trigger.name, trigger.value));
      if (values.purchasePrice.length && values.closingCostPercent) {
        values.closingCostAmount =
          (values.closingCostPercent * values.purchasePrice) / 100;
        dispatch(
          updateCentralValues("closingCostAmount", values.closingCostAmount)
        );
      } else {
        values.closingCostAmount = 0;
        values.closingCostPercent = 0;
        dispatch(
          updateCentralValues("closingCostAmount", values.closingCostAmount)
        );
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      }
    } else if (trigger.name === "purchasePrice") {
      dispatch(updateCentralValues(trigger.name, trigger.value));
      if (values.purchasePrice && values.closingCostAmount) {
        values.closingCostPercent =
          (values.closingCostAmount / values.purchasePrice) * 100;
        values.closingCostPercent = parseFloat(
          values.closingCostPercent.toFixed(3)
        );
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      } else {
        values.closingCostPercent = 0;
        dispatch(
          updateCentralValues("closingCostPercent", values.closingCostPercent)
        );
      }
    }

    calculateAllInCosts(values);
    calculateLoanToValueAmount(values);
    calculateAnnualPayment(values);
    calculateFreeCashFlow(values);
    calculateCashOnCashReturn(values);
    calculateTotalCashNeeded(values);
  };
}

function calculateAllInCosts(values) {
  if (values.purchasePrice) {
    if (values.closingCostAmount) {
      values.allInCosts =
        parseFloat(values.purchasePrice) + parseFloat(values.closingCostAmount); //work on way to parse all properties and avoid this.
    } else {
      values.allInCosts = values.purchasePrice;
    }
  } else {
    values.allInCosts = 0;
  }
}

function calculateLoanToValueAmount(values) {
  if (values.loanToValuePercent) {
    if (values.purchasePrice) {
      if (values.percentageOf === "Purchase Price") {
        values.loanToValueAmount =
          values.purchasePrice * (values.loanToValuePercent / 100);
      } else if (values.percentageOf === "All in Costs") {
        values.loanToValueAmount =
          values.allInCosts * (values.loanToValuePercent / 100);
      }
    } else {
      values.loanToValueAmount = 0;
    }
  }
}

function calculateAnnualPayment(values) {
  if (
    values.loanToValueAmount &&
    values.amortizationYears &&
    values.interestRate
  ) {
    const amortizationYearsMonths = parseFloat(values.amortizationYears) * -12;
    const interestRateDiv12 = parseFloat(values.interestRate) / 1200;
    const loanAmount = values.loanToValueAmount;
    values.annualPayment =
      ((loanAmount * interestRateDiv12) /
        (1 - Math.pow(1 + interestRateDiv12, amortizationYearsMonths))) *
      12;
  } else {
    values.annualPayment = 0;
  }
}

function calculateFreeCashFlow(values) {
  //On the app this value shows up positive when it is negative by us according to the calculation.
  if (values.year1NOIBeforeDebtService && values.annualPayment) {
    values.freeCashFlow =
      values.year1NOIBeforeDebtService - values.annualPayment;
  } else {
    values.freeCashFlow = 0;
  }
}

function calculateCashOnCashReturn(values) {
  if (values.allInCosts && values.loanToValueAmount && values.freeCashFlow) {
    values.cashOnCashReturn =
      (values.freeCashFlow / (values.allInCosts - values.loanToValueAmount)) *
      100;
  } else {
    values.cashOnCashReturn = 0;
  }
}

function calculateTotalCashNeeded(values) {
  if (values.loanToValueAmount && values.allInCosts) {
    values.totalCashNeeded = values.allInCosts - values.loanToValueAmount;
  } else {
    values.totalCashNeeded = 0;
  }
}
