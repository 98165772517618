import React from "react";
import StandardCalc from "../simpleCalculatorComponents/StandardCalc";

class SimpleCalculator extends React.Component {
  render() {
    return (
      <div>
        <StandardCalc />
      </div>
    );
  }
}

export default SimpleCalculator;
