import React from "react";
import { useStyles } from "./formComponents.style";
import { InfoOutlinedIcon } from "../../../themes";

export function DisabledInputsMsg({ field }) {
  const classes = useStyles();
  return (
    <div className={classes.missingInputsMsg}>
      <InfoOutlinedIcon className={classes.missingInputsIcon} />
      <span>{field} can only be edited inside popup</span>
    </div>
  );
}
