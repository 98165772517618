import React from "react";
import Button from "muicss/lib/react/button";
import { Navigation } from "../../lib";
import encodeQueryData from "./encodeQueryData";

class PageNavigation extends React.Component {
  navigateClick(page) {
    let queryString = window.location.search || "";

    if (queryString.includes("page=")) {
      let index = queryString.indexOf("page=") - 1;
      queryString = queryString.slice(0, index);
    }

    if (page > 1) {
      queryString += queryString === "" ? "?" : "&";
      queryString += `${encodeQueryData({ page: page })}`;
    }

    Navigation.go(window.location.pathname + queryString);
  }

  render() {
    let currentPage = this.props.currentPage
      ? parseInt(this.props.currentPage, 10)
      : null;
    let totalPages = this.props.totalPages
      ? parseInt(this.props.totalPages, 10)
      : null;
    return (
      <div className="flex flex-center-center page-navigation">
        <Button
          onClick={() => this.navigateClick(currentPage - 1)}
          className={
            currentPage <= 1
              ? "hidden"
              : "mui-btn mui-btn--raised load-more text-center"
          }
        >
          {"<< PREVIOUS PAGE"}
        </Button>
        <Button
          onClick={() => this.navigateClick(currentPage + 1)}
          className={
            (totalPages && currentPage >= totalPages) ||
            (!this.props.nextPageUrl && !totalPages)
              ? "hidden"
              : "mui-btn mui-btn--raised load-more text-center"
          }
        >
          {"NEXT PAGE >>"}
        </Button>
      </div>
    );
  }
}

export default PageNavigation;
