import * as types from "./actionTypes";
import config from "../config";
import { ajax } from "../lib";
import { tokenExpired } from "./authenticationActions";
// import {formatRequestBodyParams} from '../lib';

export function getProfile() {
  return (dispatch) => {
    dispatch({ type: types.LOADING_PROFILE });
    ajax({
      url: `${config.BASE_URL}my-profile`,
      applyAuthHeader: true,
      success: function (response) {
        if (!response.isResponse) {
          dispatch(tokenExpired());
        }
        if (response.isResponse) {
          dispatch({ type: types.GET_PROFILE_SUCCESS, data: response.data });
        }
      },
    });
  };
}

export function toggleDirectoryHide(status) {
  return (dispatch) => {
    dispatch({ type: types.TOGGLE_DIRECTORY_HIDE });
    ajax({
      url: `${config.BASE_URL}my-profile`,
      applyAuthHeader: true,
      type: "PUT",
      data: { status },
      success: function (response) {
        if (response.isResponse) {
          dispatch(getProfile());
        }
      },
    });
  };
}

export function updateContact(
  contact_id,
  phone_contact_info,
  email_contact_info,
  fax_contact_info
) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_PROFILE });

    ajax({
      url: `${config.BASE_URL}update-contact/${contact_id}`,
      applyAuthHeader: true,
      data: JSON.stringify({
        phone_contact_info,
        fax_contact_info,
        email_contact_info,
      }),
      contentType: "application/json",
      type: "PUT",
      success: function (response) {
        switch (response.status) {
          case "202":
            dispatch({
              type: types.UPDATE_PROFILE,
              success: true,
              message: response.message,
              data: response.data.id,
            });
            dispatch(getProfile());
            break;
          case "E101":
            dispatch(tokenExpired());
            break;
          default:
            dispatch({
              type: types.UPDATE_PROFILE,
              success: false,
              message: response.message,
            });
            break;
        }
      },
    });
  };
}

export function deleteContact(cpId) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_PROFILE });
    ajax({
      url: `${config.BASE_URL}my-profile/contact-info`,
      applyAuthHeader: true,
      type: "PUT",
      data: { cp_id: cpId },
      success: function () {
        dispatch(getProfile());
      },
    });
  };
}

export function addContact(type, value, hide) {
  return (dispatch) => {
    dispatch({ type: types.LOADING_PROFILE });
    ajax({
      url: `${config.BASE_URL}add-contact-detail`,
      applyAuthHeader: true,
      type: "POST",
      data: {
        contact_type: type,
        description: value,
        hide_from_directory: hide ? 1 : 0,
      },
      success: function (response) {
        dispatch(getProfile());
      },
    });
  };
}

export function unsubscribe(
  contact_id,
  email_type,
  email_address,
  comments,
  name
) {
  return (dispatch) => {
    ajax({
      url: `${config.BASE_URL}unsubscribe`,
      data: { contact_id, email_type, email_address, comments },
      type: "POST",
      success: (response) => {
        switch (response.status) {
          case "206":
            dispatch({
              type: types.UNSUBSCRIBE,
              message:
                "You've successfully unsubscribed from the " +
                name +
                " emails. We're sorry to see you go!",
              success: true,
            });
            break;
          case "230":
            dispatch({
              type: types.UNSUBSCRIBE,
              message: "You have already unsubscribed from this email",
              success: false,
            });
            break;
          default:
            dispatch({
              type: types.UNSUBSCRIBE,
              message: response.message,
              success: false,
            });
            break;
        }
      },
    });
  };
}

export function closeToast() {
  return { type: types.CLOSE_PROFILE_TOAST };
}
