import * as types from "../actions/actionTypes";
import moment from "moment";

const initialState = {
  noProperties: false,
  errorToast: false,
  loading: true,
  properties: {},
  searchedProperties: {},
  count: "",
  message: "",
  searchValue: "",
};

export default function activeDealsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_PROPERTIES:
      return Object.assign({}, state, {
        loading: true,
        properties: {},
        count: "",
        noProperties: false,
      });
    case types.NO_PROPERTIES:
      return Object.assign({}, state, {
        loading: false,
        properties: {},
        searchedProperties: {},
        count: "",
        noProperties: true,
      });
    case types.LOAD_PROPERTIES_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        properties: action.data,
        searchedProperties: action.data,
        count: action.count,
        noProperties: false,
      });
    case types.UPDATE_PROPERTY_SEARCH:
      let { mine = [], linked = [] } = state.properties;
      let searchValue = action.searchValue.toLowerCase();
      let myPropertiesMatched = [];
      let linkedPropertiesMatched = [];

      //search my properties
      for (var p = 0; p < mine.length; p++) {
        if (
          mine[p].deal_name &&
          mine[p].deal_name.toLowerCase().indexOf(searchValue) > -1
        ) {
          myPropertiesMatched.push(mine[p]);
        } else if (
          mine[p].property_address &&
          mine[p].property_address.toLowerCase().includes(searchValue)
        ) {
          myPropertiesMatched.push(mine[p]);
        }
      }
      //search linked properties
      for (var o = 0; o < linked.length; o++) {
        if (
          linked[o].deal_name &&
          linked[o].deal_name.toLowerCase().indexOf(searchValue) > -1
        ) {
          linkedPropertiesMatched.push(linked[o]);
        } else if (
          linked[o].property_address &&
          linked[o].property_address.toLowerCase().includes(searchValue)
        ) {
          linkedPropertiesMatched.push(linked[o]);
        }
      }

      return Object.assign({}, state, {
        searchedProperties: {
          count: myPropertiesMatched.length + linkedPropertiesMatched.length,
          mine: myPropertiesMatched,
          linked: linkedPropertiesMatched,
        },
        searchValue: action.searchValue,
      });

    case types.UPDATE_ERROR_TOAST:
      return Object.assign({}, state, {
        errorToast: true,
        message: action.message,
      });
    case types.CLOSE_ERROR_TOAST:
      return Object.assign({}, state, {
        message: "",
        errorToast: false,
      });
    case types.REMOVE_PROPERTY_SUCCESS:
      let indexToRemove = state.properties.mine.findIndex(
        (i) =>
          i.removal_id === action.removal_id &&
          i.removal_type === action.removal_type
      );
      let propertyList = state.properties.mine;
      propertyList.splice(indexToRemove, 1);
      return Object.assign({}, state, {
        count: state.count - 1,
        errorToast: true,
        message: "Property has been successfully removed",
      });
    case types.REMOVE_PROPERTY_FAILED:
      return Object.assign({}, state, {
        errorToast: true,
        message: action.message,
      });
    case types.UPDATE_PROPERTY_NOTE_FAILED:
      return Object.assign({}, state, {
        errorToast: true,
        message: action.message,
      });
    case types.UPDATE_PROPERTY_NOTE_SUCCESS:
      let propertiesList = state.properties;
      let propertyIndex = propertiesList.mine.findIndex(
        (i) =>
          i.removal_id === action.removal_id &&
          i.removal_type === action.removal_type
      );
      if (propertyIndex >= 0) {
        propertiesList.mine[propertyIndex].notes = action.notes;
        propertiesList.mine[propertyIndex].notes_edited_on = moment(
          new Date(new Date())
        ).format("MM/DD/YYYY h:mm ");
      } else {
        propertyIndex = propertiesList.linked.findIndex(
          (i) =>
            i.removal_id === action.removal_id &&
            i.removal_type === action.removal_type
        );
        if (propertyIndex >= 0) {
          propertiesList.linked[propertyIndex].notes = action.notes;
          propertiesList.linked[propertyIndex].notes_edited_on = moment(
            new Date(new Date())
          ).format("MM/DD/YYYY h:mm ");
        }
      }
      return Object.assign({}, state, {
        errorToast: true,
        message: "The note has been updated",
        properties: propertiesList,
      });
    default:
      return state;
  }
}
