import * as types from "../actions/actionTypes";

const initialState = {
  listReports: [],
  reports: [],
  members: [],
  banks: [],
  banksBankers: [],
  loadingReportsList: false,
  loadingReport: null,
  noListReports: false,
  noReports: false,
  message: "",
  title: "",
  toast: {
    show: false,
    success: true,
    message: "",
  },
};

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_REPORTS_LIST:
      return Object.assign({}, state, {
        loadingReportsList: true,
        listReports: [],
      });
    case types.LOAD_REPORT_LIST_SUCCESS:
      return Object.assign({}, state, {
        loadingReportsList: false,
        listReports: action.data,
      });
    case types.LOAD_REPORT_LIST_FAILED:
      return Object.assign({}, state, {
        loadingReportsList: false,
        listReports: [],
      });
    case types.LOADING_REPORTS:
      return Object.assign({}, state, { loadingReport: true });
    case types.LOAD_REPORT_SUCCESS:
      return Object.assign({}, state, { loadingReport: false });
    case types.LOAD_REPORT_FAILED:
      return Object.assign({}, state, {
        loadingReport: false,
        toast: { show: true, success: false, message: action.message },
      });
    // case types.NO_REPORT:
    //   return Object.assign({}, state, {loadingReport: false, reports: [], title: action.title});
    case types.GET_MEMBERS_LIST:
      return Object.assign({}, state, { members: action.data });
    case types.GET_MEMBERS_LIST_FAILED:
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.GET_BANKS_LIST_SUCCESS:
      return Object.assign({}, state, { banks: action.data });
    case types.GET_BANKS_BANKERS_SUCCESS:
      return Object.assign({}, state, { banksBankers: action.data });
    case types.GET_BANKS_LIST_FAILED:
    case types.GET_BANKS_BANKERS_FAILED:
      console.log("GET_BANKS_BANKERS_FAILED",action);
      return Object.assign({}, state, {
        toast: { show: true, success: action.success, message: action.message },
      });
    case types.CLOSE_REPORT_TOAST:
      return Object.assign({}, state, {
        toast: { show: false, success: true, message: "" },
      });
    default:
      return state;
  }
}
