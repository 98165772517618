import React from "react";
import FormattedInput from "./FormattedInput";
import { formatWithCommas } from "../../../lib";

class PercentInput extends React.Component {
  formatValue(value) {
    value = value.toString();
    let regexExp = new RegExp(
      "^\\d*(\\.\\d" +
        (this.props.decimalPlaces
          ? "{0," + this.props.decimalPlaces + "}"
          : "*") +
        ")?$"
    );
    if (!value.match(regexExp)) {
      value = value.slice(0, -1);
    }
    return value ? formatWithCommas(value) + "%" : "";
  }

  stripFormat(value) {
    let numberValue = value.replace(/[^0-9.]/g, "");
    const lastChar = numberValue.slice(-1);
    if (lastChar === ".") {
      const numberSliced = numberValue.slice(0, -1);
      if (
        numberSliced > Math.floor(numberSliced) ||
        numberSliced.slice(-1) === "."
      ) {
        numberValue = numberSliced;
      }
    }
    return numberValue;
  }

  render() {
    return (
      <FormattedInput
        {...this.props}
        formatValue={this.formatValue.bind(this)}
        stripFormat={this.stripFormat.bind(this)}
      />
    );
  }
}

export default PercentInput;
