import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authenticationActions from "../actions/authenticationActions";
import * as compsActions from "../actions/compsActions";
import Layout from "./common/Layout";
import mixpanel from "mixpanel-browser";
import { isMobile } from "react-device-detect";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usageDetected: false,
    };
  }

  componentDidMount() {
    // validate session if it exists
    if (
      localStorage.getItem("auth_timestamp") &&
      localStorage.getItem("auth_token") &&
      localStorage.getItem("data")
    ) {
      // if session is expired, logout
      if (
        // NOTE `isMobile` returns true if device type is mobile or tablet
        !isMobile &&
        Date.now() - localStorage.getItem("auth_timestamp") >  1000 * 60 * 60 * 24
      ) {
        this.props.authenticationActions.logout(
          localStorage.getItem("auth_token")
        );
      }
      // else store auth info in state, set mouse and keypress listeners, and start session timer
      else {
        this.props.authenticationActions.storeAuthenticationInfo();
        window.onmousemove = this.registerUsage;
        window.onkeypress = this.registerUsage;
        this.sessionHandler();
      }
    }
  }

  componentWillUnmount() {
    window.onmousemove = function () {
      return true;
    };
    window.onkeypress = function () {
      return true;
    };
    clearInterval(this.sessionTimer);
  }

  registerUsage = () => {
    if (!this.state.usageDetected) {
      this.setState({ usageDetected: true });
    }
  };

  // store new timestamp every minute if mouse was moved or key was pressed
  // else check if session is expired
  sessionHandler = () => {
    this.sessionTimer = setInterval(
      function () {
        if (this.state.usageDetected) {
          this.props.authenticationActions.storeNewTimestamp();
          this.setState({ usageDetected: false });
        } else {
          this.checkSessionExpired();
        }
      }.bind(this),
      1000 * 60
    );
  };

  // check if session is more than thirty minutes old, and logout if it is
  checkSessionExpired = () => {
    if (
      // NOTE `isMobile` returns true if device type is mobile or tablet
      !isMobile &&
      Date.now() - localStorage.getItem("auth_timestamp") >  1000 * 60 * 60 * 24
    ) {
      this.logout();
    }
  };

  logout = () => {
    this.props.authenticationActions.logout(localStorage.getItem("auth_token"));
  };

  clickCompsLink = () => {
    this.props.compsActions.resetCompsState();
  };

  somethingclicked = (e) => {
    const { name, id, type, innerText, localName, className, value } = e.target;
    const itemsToSend = ["button", "i"];
    if (
      itemsToSend.includes(localName) ||
      (className && className.toString().indexOf("track") > -1)
    ) {
      mixpanel.track("Clicked", {
        name: name,
        id: id,
        type: type,
        innerText: innerText,
        value: value,
      });
    }
  };

  somethingBlured = (e) => {
    const { name, id, type, innerText, localName, className, value } = e.target;
    if (localName === "input" && value) {
      const isIrrPopup =
        className && className.toString().indexOf("irrPopup") > -1;
      mixpanel.track("Blured", {
        name: name,
        id: id,
        type: type,
        innerText: innerText,
        value: value,
        isIrrPopup: isIrrPopup,
      });
    }
  };

  render() {
    return (
      <div onClick={this.somethingclicked} onBlur={this.somethingBlured}>
        <Layout
          clickCompsLink={this.clickCompsLink}
          userData={JSON.parse(localStorage.getItem("data"))}
          logout={this.logout}
          pathname={this.props.location.pathname}
          compsNavBarClicked={this.props.compsActions.navBarClicked}
          match={this.props.match}
          history={this.props.history}
          location={this.props.location}
        >
          {this.props.children}
        </Layout>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch),
    compsActions: bindActionCreators(compsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
