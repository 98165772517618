import { makeStyles } from "@material-ui/core";
// import { overlayStyles } from "../../sharedStyles";

export const useStyles = makeStyles((theme) => ({
  property: {
    fontFamily: "Karla !important",
    fontWeight: "bold",
    fontSize: (props) => (props.isMobile ? 15 : "13.7299px"),
    lineHeight: (props) => (props.isMobile ? "18px" : "16px"),
    color: "#00112B",
    marginTop: 16,
    marginBottom: (props) => (props.isMobile ? 10 : 16),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
      // opacity: 0.5,
      color: "#10B8C7",
    },
  },
}));
