import * as types from "../actionTypes";

function updateMaxLoan(values) {
  return { type: types.UPDATE_MAX_LOAN, values }; //will pass in all values to update (in case when calculate was not performed?
}

export function calculateMaxLoan(values, trigger) {
  return function (dispatch) {
    calculate(values, trigger);
    dispatch(updateMaxLoan(values));
  };
}

function calculate(values, trigger) {
  if (trigger.name === "amortizationYears") {
    values.amortizationYears = trigger.value;
    values.amortizationMonths = trigger.value * 12;
  } else if (trigger.name === "amortizationMonths") {
    values.amortizationMonths = trigger.value;
    values.amortizationYears = trigger.value / 12;
  }

  if (
    values.annualUnderwrittenNOI &&
    values.minimumDSCRRequired &&
    values.interestRate &&
    (values.amortizationYears || values.amortizationMonths)
  ) {
    const interestRate = values.interestRate / 100;
    const minimumDSCRRequired = values.minimumDSCRRequired;

    const var1 = values.annualUnderwrittenNOI / 12 / minimumDSCRRequired;
    const var2 = Math.pow(1 + interestRate / 12, values.amortizationMonths) - 1;
    const var3 =
      (interestRate / 12) *
      Math.pow(1 + interestRate / 12, values.amortizationMonths);
    values.maximumLoan = var1 * (var2 / var3);
  } else {
    values.maximumLoan = 0;
  }
}
