import React, { Fragment } from "react";
import { Button, Snackbar } from "@material-ui/core";
import { ErrorOutlineIcon } from "../../../themes";
import { useStyles } from "./Notification.styles";

export function Notification({
  open,
  initialNotificationState,
  message,
  buttonTxt,
  isMobile,
  maxMsgWidth,
  style,
  horizontalAnchor,
  onClick,
  hideNotification,
  allowAutoHide = false,
}) {
  const classes = useStyles({ isMobile });
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: horizontalAnchor,
      }}
      open={open}
      style={{
        ...style,
      }}
      ContentProps={{
        classes: {
          root: classes.overrideSnackbar,
          action: classes.overrideSaveBtn,
        },
      }}
      message={
        <div style={{ display: "flex" }}>
          <ErrorOutlineIcon />
          <span
            className={classes.overrideMsg}
            style={{ maxWidth: maxMsgWidth }}
          >
            {message}
          </span>
        </div>
      }
      ClickAwayListenerProps={{
        onClickAway: () => hideNotification(initialNotificationState),
      }}
      autoHideDuration={allowAutoHide ? 3000 : null}
      onClose={() =>
        allowAutoHide ? hideNotification(initialNotificationState) : {}
      }
      action={
        <Fragment>
          <Button
            color="secondary"
            size="small"
            onClick={() => onClick()}
            style={{ fontWeight: "bold" }}
          >
            {buttonTxt}
          </Button>
        </Fragment>
      }
    />
  );
}
