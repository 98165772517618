import React from "react";
import Button from "muicss/lib/react/button";
import { Navigation } from "../../lib";

class Unsubscribe extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: "",
      unsubscribed: false,
    };

    this.commentChangeHandler = this.commentChangeHandler.bind(this);
    this.submit = this.submit.bind(this);
  }

  commentChangeHandler(e) {
    this.setState({ comment: e.target.value });
  }

  submit() {
    this.props.submit(
      this.props.contactId,
      this.props.emailType,
      this.props.emailAddress,
      this.state.comment,
      this.props.emailName
    );
    this.setState({ unsubscribed: true });
  }

  render() {
    return (
      <div className="primary-background flex-center-center">
        <div className="panel unsubscribe-panel">
          {!this.state.unsubscribed ? (
            <div>
              <h2>Are you sure you want to unsubscribe?</h2>
              <p className="accent-text">
                We value your honest feedback. Please tell us why you want to
                unsubscribe. (optional)
              </p>
              <textarea
                value={this.state.comment}
                onChange={this.commentChangeHandler}
              ></textarea>
              <div className="unsubscribe-btns text-right">
                <Button
                  variant="flat"
                  className="button btn-cancel"
                  onClick={() => Navigation.go("/")}
                >
                  Cancel
                </Button>
                <Button
                  variant="flat"
                  className="button btn-accent mui--z1"
                  onClick={this.submit}
                >
                  Submit and Unsubscribe
                </Button>
              </div>
            </div>
          ) : (
            <div className="unsubscribed-success">
              <h2>{this.props.message}</h2>
              <div className="unsubscribed-success-footer">
                <img src="/images/unsubscribe-emoji.png" alt="" />
                {this.props.success ? (
                  <p className="bold-text">
                    Did this by mistake? You can email <br />
                    <a
                      href="mailto:app@easternunion.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="orange-text orange-text-hover"
                    >
                      app@easternunion.com
                    </a>{" "}
                    to re-subscribe.
                  </p>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Unsubscribe;
