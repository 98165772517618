import React from "react";
import FormattedInput from "./FormattedInput";

class NumberInputAllowZero extends React.Component {
  formatValue(value) {
    value = value.toString();
    value = value.replace(/[^0-9.]/g, ""); //Strips of non-numeric characters besides decimal
    const decIndex = value.indexOf(".");
    if (decIndex !== -1 && this.props.decimalPlaces) {
      value = value.slice(0, decIndex + (this.props.decimalPlaces + 1)); // only allow certain amount of decimal places
    }
    return value || "";
  }

  stripFormat(value) {
    let numberValue = value.replace(/[^0-9.]/g, "");
    if (numberValue.startsWith("0")) {
      let s = numberValue.replace(/^0+/, "");
      if (
        numberValue === "0" ||
        numberValue === "00" ||
        numberValue.startsWith("0.")
      ) {
        s = "0" + s;
      }
      numberValue = s;
    }

    const lastChar = numberValue.slice(-1);
    if (lastChar === ".") {
      const numberSliced = numberValue.slice(0, -1);
      if (
        numberSliced > Math.floor(numberSliced) ||
        numberSliced.slice(-1) === "."
      ) {
        numberValue = numberSliced;
      }
    }
    return numberValue;
  }

  render() {
    return (
      <FormattedInput
        {...this.props}
        allowZero={true}
        formatValue={this.formatValue.bind(this)}
        stripFormat={this.stripFormat.bind(this)}
      />
    );
  }
}

export default NumberInputAllowZero;
