import React from "react";
import { Link, NavLink } from "react-router-dom";
import Row from "muicss/lib/react/row";
import getParameterByName from "../common/getParameterByName";

class FundPortfolioDetailsContainer extends React.Component {
  render() {
    let violations = getParameterByName("violations");
    let additionalProperties = getParameterByName("additional_properties");
    return (
      <div>
        <Row className="sub-header">
          <Link
            className="back-arrow"
            to={{
              pathname: `/fund-portfolio/${this.props.match.params.type}`,
              search: window.location.search,
            }}
          >
            <img alt="arrow-left" src="/images/icons/arrow-left.png" />
          </Link>
          <ul className="deal-details-nav">
            {additionalProperties > 0 ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={`/fund-portfolio/${this.props.match.params.type}/${this.props.match.params.dealId}/property-details${window.location.search}`}
                  activeClassName="active-deal-nav-item"
                >
                  Property Details
                </NavLink>
              </li>
            ) : (
              ""
            )}
            {violations > 0 ? (
              <li className="deal-details-nav-item">
                <NavLink
                  to={`/fund-portfolio/${this.props.match.params.type}/${this.props.match.params.dealId}/violations${window.location.search}`}
                  activeClassName="active-deal-nav-item"
                >
                  Violations
                </NavLink>
              </li>
            ) : (
              ""
            )}
          </ul>
        </Row>
        <div className="deal-padding-top">{this.props.children}</div>
      </div>
    );
  }
}

export default FundPortfolioDetailsContainer;
