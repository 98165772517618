import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Form from "muicss/lib/react/form";
import Button from "muicss/lib/react/button";
import Input from "muicss/lib/react/input";
import { findDOMNode } from "react-dom";

class ShareCalculationsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validForm: false,
      toError: "",
      ccError: "",
      from: localStorage.getItem("data")
        ? JSON.parse(localStorage.getItem("data")).user_name
        : "",
      to: "",
      cc: "",
    };
  }

  componentDidMount() {
    if (localStorage.getItem("data")) {
      this.props.updateFrom(JSON.parse(localStorage.getItem("data")).user_name);
    }
  }

  updateFrom(e) {
    this.props.updateFrom(e.target.value);
    let input = findDOMNode(e.target);
    let index = input.selectionStart;

    this.setState(
      {
        from: e.target.value,
      },
      () => {
        input.setSelectionRange(index, index);
      }
    );
  }

  updateTo(e) {
    this.props.updateTo(e.target.value);
    let input = findDOMNode(e.target);
    let index = input.selectionStart;

    this.setState(
      {
        to: e.target.value,
      },
      () => {
        input.setSelectionRange(index, index);
      }
    );

    this.validateEmailChange(e.target.value);
  }

  updateCC(e) {
    this.props.updateCC(e.target.value);
    let input = findDOMNode(e.target);
    let index = input.selectionStart;

    this.setState(
      {
        cc: e.target.value,
      },
      () => {
        input.setSelectionRange(index, index);
      }
    );

    this.validateEmailChange(e.target.value);
  }

  validateEmailChange(value) {
    var re = /[^@]+@[^@]+\.[^@]+/;
    var result = re.test(value);
    if (!result) {
      this.setState({ validForm: false });
    } else {
      this.setState({ validForm: true });
    }
  }

  validateEmailOnBlur(value) {
    var re = /[^@]+@[^@]+\.[^@]+/;
    return re.test(value);
  }

  blurTo(e) {
    const result = this.validateEmailOnBlur(e.target.value);
    if (!result) {
      this.setState({
        toError: "Enter a valid email",
      });
    } else {
      this.setState({
        toError: "",
      });
    }
  }

  blurCC(e) {
    const result = this.validateEmailOnBlur(e.target.value);

    if (!result && this.state.cc.length) {
      this.setState({
        validForm: false,
        ccError: "Enter a valid email",
      });
    } else {
      this.setState({
        validForm: true,
        ccError: "",
      });
    }
  }

  render() {
    return (
      <Modal
        className="share-calc-modal"
        ariaHideApp={false}
        isOpen={this.props.isOpen}
        contentLabel="Model"
        onRequestClose={this.props.onRequestClose}
        onAfterOpen={this.props.onAfterOpen}
        shouldCloseOnOverlayClick={true}
      >
        <i
          className="material-icons modal-exit"
          onClick={this.props.onRequestClose}
        >
          close
        </i>
        <Form className="modal-form">
          <p className="share-calculations-title">Share Calculations</p>
          <p className="share-calc-subtitle">
            Fill in the form to send an email with current calculations
          </p>
          <Input
            label="Your Name"
            floatingLabel={true}
            value={this.state.from}
            onChange={this.updateFrom.bind(this)}
          />

          <Input
            label="Send To"
            floatingLabel={true}
            value={this.state.to}
            onChange={this.updateTo.bind(this)}
            onBlur={this.blurTo.bind(this)}
            className="margin-top"
          />
          <span className="help-block">{this.state.toError}</span>

          <Input
            label="CC yourself to receive a copy"
            floatingLabel={true}
            value={this.state.cc}
            onChange={this.updateCC.bind(this)}
            onBlur={this.blurCC.bind(this)}
            className="margin-top"
          />
          <span className="help-block">{this.state.ccError}</span>

          <div className="share-calc-button">
            {this.state.validForm && this.state.to && this.state.from ? (
              <Button
                variant="flat"
                onClick={this.props.onSubmit}
                type="submit"
                value="submit"
              >
                SEND
              </Button>
            ) : (
              <Button
                variant="flat"
                onClick={this.props.onSubmit}
                disabled={true}
                type="submit"
                value="submit"
              >
                SEND
              </Button>
            )}
          </div>
        </Form>
      </Modal>
    );
  }
}

ShareCalculationsModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  onAfterOpen: PropTypes.func,
};

export default ShareCalculationsModal;
