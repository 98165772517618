import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authenticationActions from "../../actions/authenticationActions";
import RegisterForm from "./RegisterForm";

class RegisterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.signUp = this.signUp.bind(this);
  }

  signUp(email, first_name, last_name, phone, company, password, user_types) {
    this.props.actions.signUp(
      email,
      first_name,
      last_name,
      phone,
      company,
      password,
      user_types
    );
  }

  render() {
    const { message, passwordValidation } = this.props.authentication;
    return (
      <RegisterForm
        signUp={this.signUp}
        message={message}
        invalidPasswordMessage={
          !passwordValidation?.success ? passwordValidation?.message : ""
        }
        validatePassword={this.props.actions.validatePassword}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authenticationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterContainer);
