import * as types from "../actions/actionTypes";

const initialState = {
  loading: true,
  data: {},
  toast: {
    show: false,
    success: true,
    message: "",
  },
};

export default function accountingReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_ACCOUNTING_REPORT:
      return Object.assign({}, state, { loading: true, data: {} });
    case types.GET_ACCOUNTING_REPORT_SUCCESS:
      return Object.assign({}, state, { loading: false, data: action.data });
    case types.GET_ACCOUNTING_REPORT_FAILED:
      return Object.assign({}, state, {
        loading: false,
        data: action.data,
        message: { show: true, success: false, message: action.message },
      });
    case types.CLOSE_ACCOUNTING_TOAST:
      return Object.assign({}, state, {
        toast: { show: false, success: false, message: "" },
      });
    default:
      return state;
  }
}
