import * as types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: [],
};

export default function docsReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOADING_DOCS_BY_TYPE:
      return Object.assign({}, state, { loading: true, data: [] });
    case types.GET_DOCS_BY_TYPE:
      return Object.assign({}, state, { loading: false, data: action.data });
    default:
      return state;
  }
}
