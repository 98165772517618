import * as types from "../actions/actionTypes";
import moment from "moment";

const initialState = {
  missingQuotes: [],
  loading: false,
  noQuotes: false,
  updateQTSStarModal: false,
  quotePassSuccessToast: false,
  quoteSnoozeSuccessToast: false,
  quoteIrrelevantSuccessToast: false,
  updateNoteModalSuccess: false,
  noAdminViewData: false,
  loadingAdminData: false,
  isSearchDeal: false,
  errorMQBToast: false,
  bank_id: "",
  errorMBQToast: false,
  loadingContact: false,
  contact: {},
  noContact: false,
  message: "",
};

export default function missingQuotesBrokerReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.LOADING_BROKER_QUOTES:
      return Object.assign({}, state, {
        missingQuotes: {},
        loading: true,
        noQuotes: false,
        isSearchDeal: false,
      });
    case types.LOAD_BROKER_MISSING_QUOTES_SUCCESS:
      return Object.assign({}, state, {
        missingQuotes: action.data,
        loading: false,
        noQuotes: false,
      });
    case types.NO_BROKER_QUOTES:
      return Object.assign({}, state, {
        missingQuotes: {},
        loading: false,
        noQuotes: true,
      });

    case types.PASS_MQ_BROKER_DEAL_SUCCESS:
      let missingQuoteList1 = state.missingQuotes;
      let deal1 = state.missingQuotes[action.index];
      let dealIndex1 = deal1.my_quotes.findIndex(
        (quote) => quote.quote_id === action.quote_id
      );
      if (!dealIndex1 < 0) {
        dealIndex1 = deal1.other_quotes.findIndex(
          (quote) => quote.quote_id === action.quote_id
        );
      }
      deal1.my_quotes.splice(dealIndex1, 1);
      return Object.assign({}, initialState, {
        missingQuotes: missingQuoteList1,
        quotePassSuccessToast: true,
      });

    case types.CLOSE_MQ_PASS_DEAL_SUCCESS:
      return Object.assign({}, state, { quotePassSuccessToast: false });

    case types.IRRELEVANT_MQ_BROKER_DEAL_SUCCESS:
      let missingQuoteList4 = state.missingQuotes;
      let deal4 = state.missingQuotes[action.index];
      let dealIndex4 = deal4.my_quotes.findIndex(
        (quote) => quote.quote_id === action.quote_id
      );
      if (!dealIndex4 < 0) {
        dealIndex4 = deal4.other_quotes.findIndex(
          (quote) => quote.quote_id === action.quote_id
        );
      }
      deal4.my_quotes.splice(dealIndex4, 1);
      return Object.assign({}, initialState, {
        missingQuotes: missingQuoteList4,
        quoteIrrelevantSuccessToast: true,
      });

    case types.CLOSE_MQ_IRRELEVANT_DEAL_SUCCESS:
      return Object.assign({}, state, { quoteIrrelevantSuccessToast: false });

    case types.SNOOZE_MQ_BROKER_DEAL_SUCCESS:
      let missingQuoteList = state.missingQuotes;
      let deal2 = state.missingQuotes[action.index];
      let dealIndex2 = deal2.my_quotes.findIndex(
        (quote) => quote.quote_id === action.quote_id
      );
      if (dealIndex2 < 0) {
        dealIndex2 = deal2.other_quotes.findIndex(
          (quote) => quote.quote_id === action.quote_id
        );
      }
      var today = new Date();
      if (action.snooze_date > moment(new Date(today)).format("MM/DD/YYYY")) {
        deal2.my_quotes[action.individualIndex].missing_quotes_snooze = "1";
      } else {
        deal2.my_quotes[action.individualIndex].missing_quotes_snooze = "0";
      }
      return Object.assign({}, initialState, {
        missingQuotes: missingQuoteList,
        quoteSnoozeSuccessToast: true,
        message: action.message,
      });

    case types.CLOSE_MQ_SNOOZE_DEAL_SUCCESS:
      return Object.assign({}, state, {
        quoteSnoozeSuccessToast: false,
        message: "",
      });
    case types.NO_ADMIN_VIEW_DATA:
      return Object.assign({}, state, {
        noAdminViewData: true,
        loadingAdminData: false,
        bank_id: action.bank_id,
      });
    case types.LOADING_ADMIN_VIEW_DATA:
      return Object.assign({}, state, {
        bank_id: action.bank_id,
        loadingAdminData: true,
      });

    case types.GET_ADMIN_VIEW_DATA:
      let missingQuoteList2 = state.missingQuotes;
      let bankIndex1 = state.missingQuotes.findIndex(
        (bank) => bank.banker_id === action.banker_id
      );
      state.missingQuotes[bankIndex1].other_quotes = action.data;
      return Object.assign({}, state, {
        missingQuotes: missingQuoteList2,
        loadingAdminData: false,
        noAdminViewData: false,
        bank_id: action.bank_id,
      });

    case types.REMOVE_ADMIN_VIEW_DATA:
      let missingQuoteList3 = state.missingQuotes;
      let bankIndex2 = state.missingQuotes.findIndex(
        (bank) => bank.bank_id === action.bank_id
      );
      state.missingQuotes[bankIndex2].other_quotes = {};
      return Object.assign({}, state, {
        missingQuotes: missingQuoteList3,
        loadingAdminData: false,
        noAdminViewData: false,
      });
    case types.NO_MQ_BROKER_SEARCH_RESULTS:
      return Object.assign({}, state, {
        loading: false,
        noQuotes: true,
        missingQuotes: {},
        isSearchDeal: false,
      });
    case types.LOADING_SEARCH_BROKER_QUOTES:
      return Object.assign({}, state, {
        loading: true,
        noQuotes: false,
        missingQuotes: {},
        isSearchDeal: false,
      });
    case types.LOAD_SEARCH_MQ_BROKER_DATA:
      return Object.assign({}, state, {
        missingQuotes: action.data,
        loading: false,
        noQuotes: false,
        isSearchDeal: true,
      });

    case types.ERROR_UPDATING_MQ_BROKER:
      return Object.assign({}, state, {
        loading: false,
        errorMQBToast: true,
        message: action.message,
        noQuotes: true,
      });
    case types.CLOSE_ERROR_UPDATING_MQ_BROKER:
      return Object.assign({}, state, { errorMQBToast: false, message: "" });

    case types.LOADING_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: {},
        loadingContact: true,
        noContact: false,
      });
    case types.LOAD_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: action.contact,
        loadingContact: false,
      });
    case types.NO_FULL_CONTACT:
      return Object.assign({}, state, {
        contact: {},
        noContact: true,
        loadingContact: false,
      });

    case types.UPDATE_MQ_NOTE:
      let bankIndex3 = state.missingQuotes.findIndex(
        (bank) => bank.banker_id === action.banker_id
      );
      state.missingQuotes[bankIndex3].my_quotes[action.index].notes =
        action.notes;
      return Object.assign({}, state, {
        message: action.message,
        updateNoteModalSuccess: true,
      });
    case types.UPDATE_MQ_DEAL_NOTE:
      let bankIndex4 = state.missingQuotes.findIndex(
        (bank) => bank.deal_id === action.deal_id
      );
      state.missingQuotes[bankIndex4].my_quotes[action.index].notes =
        action.notes;
      return Object.assign({}, state, {
        message: action.message,
        updateNoteModalSuccess: true,
      });
    case types.ERROR_UPDATE_MQ_NOTE:
      return Object.assign({}, state, {
        message: action.message,
        updateNoteModalSuccess: true,
      });
    case types.CLOSE_UPDATE_NOTE_SUCCESS_MQ_TOAST:
      return Object.assign({}, state, {
        message: "",
        updateNoteModalSuccess: false,
      });

    case types.SUCCESS_BANK_UPDATE_MQ_QTS_STAR:
      let indexBankQTSStar = state.missingQuotes.findIndex(
        (bank) => bank.banker_id === action.banker_id
      );
      let quoteIndex = "";
      quoteIndex = state.missingQuotes[indexBankQTSStar].my_quotes.findIndex(
        (quote) => quote.quote_id === action.quote_id
      );
      if (quoteIndex < 0) {
        try {
          quoteIndex = state.missingQuotes[
            indexBankQTSStar
          ].other_quotes.findIndex(
            (quote) => quote.quote_id === action.quote_id
          );
          state.missingQuotes[indexBankQTSStar].other_quotes[
            quoteIndex
          ].qts_starred = action.starred ? 0 : 1;
        } catch (e) {}
      } else {
        state.missingQuotes[indexBankQTSStar].my_quotes[
          quoteIndex
        ].qts_starred = action.starred ? 0 : 1;
      }
      return Object.assign({}, state, {
        message: action.message,
        updateQTSStarModal: true,
      });
    case types.SUCCESS_DEAL_UPDATE_MQ_QTS_STAR:
      let indexDealQTSStar = state.missingQuotes.findIndex(
        (deal) => deal.deal_id === action.deal_id
      );
      state.missingQuotes[indexDealQTSStar].qts_starred = action.starred
        ? 0
        : 1;
      return Object.assign({}, state, {
        message: action.message,
        updateQTSStarModal: true,
      });
    case types.ERROR_UPDATE_MQ_QTS_STAR:
      return Object.assign({}, state, {
        message: action.message,
        updateQTSStarModal: true,
      });
    case types.CLOSE_UPDATE_MQ_QTS_STAR:
      return Object.assign({}, state, {
        message: "",
        updateQTSStarModal: false,
      });

    default:
      return state;
  }
}
