import { makeStyles } from "@material-ui/core";
import { calculatorInputs } from "../sharedStyles";

export const useStyles = makeStyles((theme) => ({
  ...calculatorInputs(theme),
  typeToggle: {
    display: "flex",
    width: "216px",
    height: "40px",
    border: "1px solid #00112B",
    borderRadius: "10px",
    margin: '12px 0 18px 0',
  },
  toggleSection: {
    width: "107px",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Karla",
    fontSize: "15px",
    cursor: "pointer"
  },
  leftToggle: {
    borderRadius: "8px 0 0 8px"
  },
  rightToggle: {
    borderRadius: "0 8px 8px 0"
  },
  toggleActive: {
    backgroundColor: '#00112B',
    color: "#ffffff",
    opacity: 0.8,
  },
  toggleInactive: {
    color: '#00112B',
    backgroundColor: "#ffffff"
  },
  doubleInputTitle: {
    marginTop: "15px"
  },
  doubleInputContainer: {
    marginTop: "0 !important"
  },
  closingCostsInputLabel: {
    width: "220px !important"
  }
}));
