import React from "react";
import { Link, NavLink } from "react-router-dom";
import Row from "muicss/lib/react/row";

class MQBDealDetailsContainer extends React.Component {
  render() {
    return (
      <div>
        <Row className="sub-header">
          <Link className="back-arrow" to="/missingQuotesbroker">
            <img alt="arrow-left" src="/images/icons/arrow-left.png" />
          </Link>
          <ul className="deal-details-nav">
            <li className="deal-details-nav-item">
              <NavLink
                to={`/MQB-deal-details/${this.props.match.params.dealId}/bank-quotes`}
                activeClassName="active-deal-nav-item"
              >
                Bank Quotes
              </NavLink>
            </li>
            <li className="deal-details-nav-item">
              <NavLink
                to={`/MQB-deal-details/${this.props.match.params.dealId}/write-up`}
                activeClassName="active-deal-nav-item"
              >
                Write Up
              </NavLink>
            </li>
            <li className="deal-details-nav-item">
              <NavLink
                to={`/MQB-deal-details/${this.props.match.params.dealId}/property-docs`}
                activeClassName="active-deal-nav-item"
              >
                Documents
              </NavLink>
            </li>
            <li className="deal-details-nav-item">
              <NavLink
                to={`/MQB-deal-details/${this.props.match.params.dealId}/violations`}
                activeClassName="active-deal-nav-item"
              >
                Violations
              </NavLink>
            </li>
          </ul>
        </Row>
        <div className="deal-padding-top">{this.props.children}</div>
      </div>
    );
  }
}

export default MQBDealDetailsContainer;
