import React from "react";
import Row from "muicss/lib/react/row";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Navigation } from "../../lib";
import { bindActionCreators } from "redux";
import Expire from "../common/Expire";
import Button from "muicss/lib/react/button";
import * as missingQuotesActions from "../../actions/missingQuotesActions";

class QuoteFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteAmount: "",
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({ quoteAmount: this.props.missingQuotes.quoteAmount });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.quoteAmount !== nextProps.missingQuotes.quoteAmount) {
      this.setState({ quoteAmount: nextProps.missingQuotes.quoteAmount });
    }
  }

  exitQuoteContainer() {
    this.props.missingQuotesActions.removeAllQuoteFormAmount();
    Navigation.go(decodeURIComponent(this.props.match.params.previousPage));
  }

  render() {
    var links = [];
    for (var i = 0; i < this.state.quoteAmount; i++) {
      links.push(
        <li key={i} className="qts-nav-list-item list-item2">
          <NavLink
            to={`/quote-form/${i + 2}/${
              this.props.match.params.quote_id
            }/${encodeURIComponent(this.props.match.params.previousPage)}`}
            className="qts-nav-item"
            activeClassName="active-qts-nav-item"
          >
            Quote {i + 2}
          </NavLink>
        </li>
      );
    }

    let toast = null;
    if (this.props.missingQuotes.toast && this.props.missingQuotes.toast.show) {
      toast = (
        <Expire
          closeToast={this.props.missingQuotesActions.closeToast}
          delay={6000}
        >
          <div className="success-message">
            <p>{this.props.missingQuotes.toast.message}</p>
            <Button
              variant="flat"
              onClick={this.props.missingQuotesActions.closeToast}
            >
              {this.props.missingQuotes.toast.success ? "Done" : "Dismiss"}
            </Button>
          </div>
        </Expire>
      );
    }
    return (
      <div>
        {toast}
        <Row className="sub-header">
          <div
            className="back-arrow"
            onClick={this.exitQuoteContainer.bind(this)}
          >
            <img src="/images/icons/arrow-left.png" alt="arrow-left" />
          </div>
          <ul className="deal-details-nav">
            <li className="qts-nav-list-item list-item2">
              <NavLink
                to={`/quote-form/1/${
                  this.props.match.params.quote_id
                }/${encodeURIComponent(this.props.match.params.previousPage)}`}
                className="qts-nav-item"
                activeClassName="active-qts-nav-item"
              >
                Quote 1
              </NavLink>
            </li>
            {links}
          </ul>
        </Row>
        {this.props.children}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    missingQuotes: state.missingQuotes,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    missingQuotesActions: bindActionCreators(missingQuotesActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteFormContainer);
